import React, { useState } from 'react';
import {
  Alert,
  AlertIcon,
  Box,
  Divider,
  Icon,
  IconButton,
  Image,
  Link,
  PseudoBox,
  Tooltip,
  useDisclosure,
  useToast,
} from '@chakra-ui/core';
import { Button, Select, ToastBox } from 'app/components';
import { useSelector } from 'react-redux';
import { selectColumnsData, selectListMeta } from 'app/authenticated-app/lists/lists.selectors';
import { Column } from 'app/authenticated-app/lists/lists.types';
import { calculateEnrichmentCost } from 'app/authenticated-app/lists/lists.utils';
import { formatCurrency } from 'utils';
import { selectCreditBalance } from 'app/authenticated-app/payments/selectors';
import { EnrichConfirmationModal } from '../../../../../../../components/enrich-confirmation-modal';
import { CreditTopupCard } from 'app/authenticated-app/marketing/campaigns';

interface EnrichmentCategoryButtonProps {
  onClick: () => void;
  isDisabled?: boolean;
  disabledMessage?: string;
  selectedAttributes: string[];
  label: string;
  description: string;
  image: any;
}

const EnrichmentCategoryButton = (props: EnrichmentCategoryButtonProps) => {
  const { selectedAttributes } = props;

  return (
    <Tooltip
      aria-label="disabled message"
      label={props.disabledMessage}
      isOpen={!props.isDisabled ? false : undefined}
    >
      <PseudoBox
        display="flex"
        flexDirection="row"
        alignItems="center"
        padding="0.5rem 1rem"
        width="100%"
        height="73px"
        backgroundColor="#FFFFFF"
        border="1px solid #DADEE3"
        borderRadius="5px"
        marginY="0.5rem"
        opacity={props.isDisabled ? 0.35 : 1}
        cursor={props.isDisabled ? 'not-allowed' : 'pointer'}
        onClick={() => {
          if (!props.isDisabled) {
            props.onClick();
          }
        }}
        _hover={{
          backgroundColor: 'rgba(61,67,223,0.015)',
        }}
      >
        <Image src={props.image} height="45px" marginRight="20px" />
        <Box width="100%">
          <Box fontWeight="500" fontSize="14px" color="#2B3A4B">
            {props.label} -
            <span style={{ marginLeft: '0.5rem', color: '#858C94' }}>
              {selectedAttributes.length} attribute
              {selectedAttributes.length !== 1 && 's'}
            </span>
          </Box>

          {selectedAttributes.length > 0 ? (
            <Box display="flex" flexDirection="row" alignItems="center">
              {selectedAttributes.slice(0, 3).map((i: string, index: number) => (
                <Box
                  key={index}
                  mr="0.3rem"
                  fontSize="12px"
                  paddingX="4px"
                  backgroundColor="#E3FCEF"
                  borderRadius="4px"
                  color="#006644"
                >
                  {i}
                </Box>
              ))}
              {selectedAttributes.length > 3 && <Box fontSize="12px">See more...</Box>}
            </Box>
          ) : (
            <Box fontSize="12px" color="#858C94">
              {props.description}
            </Box>
          )}
        </Box>
        <Icon name="chevron-right" />
      </PseudoBox>
    </Tooltip>
  );
};

interface Props {
  setActiveSection: Function;
  setActiveCategory: Function;
  enrichmentSettings: { [key: string]: any };
  updateEnrichmentSettings: Function;
  queueEnrichment: Function;
}

export const EnrichmentSummary = (props: Props) => {
  const [enrichmentLoading, setEnrichmentLoading] = useState<boolean>(false);
  const columnsState = useSelector(selectColumnsData);
  // @ts-ignore
  const columns: Column[] = columnsState.columns_by_id.map((i: string) => columnsState.columns[i]);

  const phoneColumns = columns.filter((i: Column) => i.type === 'PHONE NUMBER');
  const emailColumns = columns.filter((i: Column) => i.type === 'EMAIL');

  const handleEnrichmentColumnChange = async (name: string, value: string) => {
    await props.updateEnrichmentSettings({
      [name]: value,
    });
  };

  const { enrichmentSettings } = props;

  const creditBalance = useSelector(selectCreditBalance);
  const meta = useSelector(selectListMeta);

  const unitCost = calculateEnrichmentCost(
    enrichmentSettings.phone.length > 0,
    enrichmentSettings.company.length > 0,
    enrichmentSettings.person.length > 0,
  );

  const totalCost = unitCost * meta.count_total;

  const {
    isOpen: isInfoDialogOpen,
    onOpen: openInfoDialog,
    onClose: closeInfoDialog,
  } = useDisclosure();

  const toast = useToast();

  const handleQueueEnrichment = async () => {
    setEnrichmentLoading(true);
    try {
      await props.queueEnrichment();

      toast({
        position: 'bottom-left',
        render: ({ onClose }) => (
          <ToastBox status="success" onClose={onClose} message="Enrichment queued successfully" />
        ),
      });
    } catch (e) {
      toast({
        position: 'bottom-left',
        render: ({ onClose }) => (
          <ToastBox
            status="error"
            onClose={onClose}
            message="Unable to queue enrichment, please try again"
          />
        ),
      });
    }
    setEnrichmentLoading(false);
  };

  return (
    <>
      <EnrichConfirmationModal isOpen={isInfoDialogOpen} onClose={closeInfoDialog} />

      <Box
        paddingTop="0.2rem"
        paddingBottom="1rem"
        display="flex"
        flexDirection="row"
        alignItems="center"
        borderBottom=" 1px solid #EBEEF2"
        width="100%"
        justifyContent="space-between"
      >
        <Box display="flex" alignItems="center">
          <IconButton
            variant="ghost"
            aria-label="button"
            icon="chevron-left"
            onClick={() => props.setActiveSection(null)}
            fontSize="18px"
            mr="1rem"
            size="xs"
            isRound
          />
          <Box fontWeight="500">Data Enrichment</Box>
        </Box>
      </Box>

      <Box display="flex" flexDirection="column" mt="1rem">
        <Box fontSize="14px" marginBottom="1rem">
          Select your enrichment specifications
        </Box>

        <Box>
          <Box mb="1.5rem">
            <Select
              label="Phone Column"
              size="sm"
              placeholder="Select a phone column"
              value={props.enrichmentSettings.phone_column_id || undefined}
              onChange={event => {
                handleEnrichmentColumnChange('phone_column_id', event.target.value);
              }}
            >
              {phoneColumns.map((i: Column) => (
                <option key={i.id} value={i.id}>
                  {i.label}
                </option>
              ))}
            </Select>
            {!props.enrichmentSettings.phone_column_id && (
              <Box fontSize="12px" color="#BF2600" mt="0.25rem">
                This is required to enrich phone based data
              </Box>
            )}
          </Box>

          <Box mb="1.5rem">
            <Select
              label="Email Column"
              size="sm"
              placeholder="Select an email column"
              value={props.enrichmentSettings.email_column_id || undefined}
              onChange={event => {
                handleEnrichmentColumnChange('email_column_id', event.target.value);
              }}
            >
              {emailColumns.map((i: Column) => (
                <option key={i.id} value={i.id}>
                  {i.label}
                </option>
              ))}
            </Select>

            {!props.enrichmentSettings.email_column_id && (
              <Box fontSize="12px" color="#BF2600" mt="0.25rem">
                This is required to enrich company or personal information
              </Box>
            )}
          </Box>
        </Box>

        <Box>
          <EnrichmentCategoryButton
            onClick={() => props.setActiveCategory('phone')}
            label="Phone"
            image={require('app/authenticated-app/lists/assets/enrich-phone.svg')}
            description="Information about a mobile number e.g DND status, country"
            isDisabled={!props.enrichmentSettings.phone_column_id}
            disabledMessage="Can only enrich if a phone number column is selected"
            selectedAttributes={enrichmentSettings.phone}
          />
          <EnrichmentCategoryButton
            onClick={() => props.setActiveCategory('person')}
            label="Person"
            image={require('app/authenticated-app/lists/assets/enrich-person.svg')}
            description="Social information linked to an email address, e.g a Twitter handle, location"
            isDisabled={!props.enrichmentSettings.email_column_id}
            disabledMessage="Can only enrich if an email column is selected"
            selectedAttributes={enrichmentSettings.person}
          />
          <EnrichmentCategoryButton
            onClick={() => props.setActiveCategory('company')}
            label="Company"
            image={require('app/authenticated-app/lists/assets/enrich-company.svg')}
            description="Information about a company linked to a domain name, e.g Legal name, location"
            isDisabled={!props.enrichmentSettings.email_column_id}
            disabledMessage="Can only enrich if an email column is selected"
            selectedAttributes={enrichmentSettings.company}
          />
        </Box>

        <Box color="#858C94" fontSize="12px" mt="1rem">
          <Box mb="0.5rem">
            NB: Extra charges are accrued for this service, which would be billed from your
            available campaign credits.
            <Link
              style={{
                marginLeft: '5px',
                color: 'blue',
              }}
              onClick={openInfoDialog}
            >
              Learn more
            </Link>
          </Box>
          <Box>
            Estimated cost per row:
            <span
              style={{
                fontWeight: 'bold',
                marginLeft: '5px',
                color: '#333333',
              }}
            >
              NGN {formatCurrency(unitCost)}
            </span>
          </Box>
          <Box mb="0.2rem">
            Estimated enrichment cost:
            <span
              style={{
                fontWeight: 'bold',
                marginLeft: '5px',
                color: '#333333',
              }}
            >
              NGN {formatCurrency(totalCost)}
            </span>
          </Box>
        </Box>

        <Divider width="100%" my="1rem" />

        {totalCost > creditBalance / 100 ? (
          <>
            <Box>
              <Alert
                status="error"
                borderRadius="10px"
                textAlign="left"
                fontSize="12px"
                marginBottom="1rem"
              >
                <AlertIcon />
                Looks like you don't have enough credits in your balance to carry out enrichment on
                this list
              </Alert>
              <CreditTopupCard content="This is the credit you have for sending out campaign messages and data enrichment" />
            </Box>
          </>
        ) : (
          <>
            <Alert
              status="info"
              borderRadius="10px"
              textAlign="left"
              fontSize="12px"
              marginBottom="1rem"
            >
              <AlertIcon />
              Because enrichment is asynchronous, completion is not immediate, so depending on the
              size of the list, you may need to refresh this list after a short while
            </Alert>
            <Box display="flex" flexDirection="row" alignItems="center" justifyContent="flex-end">
              <Button size="sm" fontWeight="500" onClick={() => props.setActiveSection(null)}>
                Back
              </Button>
              <Button
                size="sm"
                ml="10px"
                variantColor="green"
                variant="solid"
                fontWeight="500"
                onClick={handleQueueEnrichment}
                isLoading={enrichmentLoading}
              >
                Enrich Now
              </Button>
            </Box>
          </>
        )}
      </Box>
    </>
  );
};
