import { ButtonProps, ModalBody } from '@chakra-ui/core';
import React from 'react';
import { Button } from '../Button';
import { EmptyState } from '../EmptyState';
import { ModalContainer, ModalContainerOptions } from '../ModalContainer';

export const InfoModal = (
  props: ModalContainerOptions & { heading: string; caption: string; buttonProps: ButtonProps },
) => {
  const { heading, caption, buttonProps, isOpen, onClose } = props;

  return (
    <ModalContainer isOpen={isOpen} onClose={onClose} size="lg" showCloseButton>
      <ModalBody py="2rem">
        <EmptyState width="400px" m="auto" heading={heading} subheading={caption}>
          <Button variantColor="blue" {...buttonProps} />
        </EmptyState>
      </ModalBody>
    </ModalContainer>
  );
};
