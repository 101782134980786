import React from 'react';
import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputLeftAddon,
  ModalBody,
  Stack,
} from '@chakra-ui/core';
import { FormikConfig, useFormik } from 'formik';
import { useEffect, useRef } from 'react';
import * as yup from 'yup';
import { ModalContainer, ModalContainerOptions } from 'app/components';

const INPUT_WIDTH = '48%';

const validationSchema = yup.object().shape({
  'sms-non-dnd': yup.string().required('Amount is required'),
  'sms-dnd': yup.string().required('Amount is required'),
  email: yup.string().required('Amount is required'),
  'lookup-dnd': yup.string().required('Amount is required'),
  'lookup-phone': yup.string().required('Amount is required'),
  'lookup-email': yup.string().required('Amount is required'),
  //   'whatsapp-web': yup.string().required('Amount is required'),
  //   'whatsapp-business': yup.string().required('Amount is required'),
});

export type UpdatePricingModalFormValues = {
  email?: string;
  'sms-dnd'?: string;
  'lookup-dnd'?: string;
  'sms-non-dnd'?: string;
  'lookup-phone'?: string;
  'lookup-email'?: string;
};

export const UpdatePricingModal = (
  props: ModalContainerOptions & {
    isLoading: boolean;
    organisationName: string;
    currency?: string;
    initialValues?: UpdatePricingModalFormValues;
    onSubmit: FormikConfig<UpdatePricingModalFormValues>['onSubmit'];
  },
) => {
  const {
    isOpen,
    onClose,
    onSubmit,
    isLoading,
    initialValues,
    currency = '₦',
    organisationName,
  } = props;

  const initialFocusRef = useRef<HTMLInputElement>(null);

  const { values, errors, resetForm, touched, handleChange, handleSubmit } =
    useFormik<UpdatePricingModalFormValues>({
      onSubmit,
      validationSchema,
      enableReinitialize: true,
      initialValues: initialValues ?? {
        email: undefined,
        'sms-dnd': undefined,
        'lookup-dnd': undefined,
        'sms-non-dnd': undefined,
        'lookup-phone': undefined,
        'lookup-email': undefined,
        // 'whatsapp-web': undefined,
        // 'whatsapp-business': undefined,
      },
    });

  const handleCloseModal = () => {
    resetForm();
    onClose?.();
  };

  useEffect(() => {
    if (initialFocusRef.current) {
      initialFocusRef.current.focus();
    }
  }, []);

  return (
    <ModalContainer
      showCloseButton
      isOpen={isOpen}
      onClose={handleCloseModal}
      initialFocusRef={initialFocusRef}
      titleStyleProps={{
        textTransform: 'capitalize',
      }}
      title={`Update ${organisationName} pricing`}
    >
      <ModalBody>
        <form style={{ width: '100%' }} onSubmit={handleSubmit}>
          <Flex mb="1rem" alignItems="center" justifyContent="space-between">
            <FormControl
              width={INPUT_WIDTH}
              isInvalid={!!(touched['sms-non-dnd'] && errors['sms-non-dnd'])}
            >
              <FormLabel fontSize="0.875rem" htmlFor="sms-non-dnd">
                SMS Promotional
              </FormLabel>
              <InputGroup size="sm">
                <InputLeftAddon>{currency}</InputLeftAddon>
                <Input
                  type="string"
                  id="sms-non-dnd"
                  name="sms-non-dnd"
                  ref={initialFocusRef}
                  onChange={handleChange}
                  placeholder="Enter amount"
                  value={values['sms-non-dnd']}
                />
              </InputGroup>
              <FormErrorMessage>{errors['sms-non-dnd']}</FormErrorMessage>
            </FormControl>
            <FormControl
              width={INPUT_WIDTH}
              isInvalid={!!(touched['sms-dnd'] && errors['sms-dnd'])}
            >
              <FormLabel fontSize="0.875rem" htmlFor="sms-dnd">
                SMS Transactional
              </FormLabel>
              <InputGroup size="sm">
                <InputLeftAddon>{currency}</InputLeftAddon>
                <Input
                  type="string"
                  id="sms-dnd"
                  name="sms-dnd"
                  onChange={handleChange}
                  placeholder="Enter amount"
                  value={values['sms-dnd']}
                />
              </InputGroup>
              <FormErrorMessage>{errors['sms-dnd']}</FormErrorMessage>
            </FormControl>
          </Flex>
          <Flex mb="1rem" alignItems="center" justifyContent="space-between">
            <FormControl width={INPUT_WIDTH} isInvalid={!!(touched['email'] && errors['email'])}>
              <FormLabel fontSize="0.875rem" htmlFor="email">
                Email
              </FormLabel>
              <InputGroup size="sm">
                <InputLeftAddon>{currency}</InputLeftAddon>
                <Input
                  type="string"
                  id="email"
                  name="email"
                  onChange={handleChange}
                  placeholder="Enter amount"
                  value={values['email']}
                />
              </InputGroup>
              <FormErrorMessage>{errors['email']}</FormErrorMessage>
            </FormControl>
            <FormControl
              width={INPUT_WIDTH}
              isInvalid={!!(touched['lookup-dnd'] && errors['lookup-dnd'])}
            >
              <FormLabel fontSize="0.875rem" htmlFor="lookup-dnd">
                DND Lookup
              </FormLabel>
              <InputGroup size="sm">
                <InputLeftAddon>{currency}</InputLeftAddon>
                <Input
                  type="string"
                  id="lookup-dnd"
                  name="lookup-dnd"
                  onChange={handleChange}
                  placeholder="Enter amount"
                  value={values['lookup-dnd']}
                />
              </InputGroup>
              <FormErrorMessage>{errors['lookup-dnd']}</FormErrorMessage>
            </FormControl>
          </Flex>
          <Flex mb="1rem" alignItems="center" justifyContent="space-between">
            <FormControl
              width={INPUT_WIDTH}
              isInvalid={!!(touched['lookup-phone'] && errors['lookup-phone'])}
            >
              <FormLabel fontSize="0.875rem" htmlFor="lookup-phone">
                Phone Lookup
              </FormLabel>
              <InputGroup size="sm">
                <InputLeftAddon>{currency}</InputLeftAddon>
                <Input
                  type="string"
                  id="lookup-phone"
                  name="lookup-phone"
                  onChange={handleChange}
                  placeholder="Enter amount"
                  value={values['lookup-phone']}
                />
              </InputGroup>
              <FormErrorMessage>{errors['lookup-phone']}</FormErrorMessage>
            </FormControl>
            <FormControl
              width={INPUT_WIDTH}
              isInvalid={!!(touched['lookup-email'] && errors['lookup-email'])}
            >
              <FormLabel fontSize="0.875rem" htmlFor="lookup-email">
                Email Lookup
              </FormLabel>
              <InputGroup size="sm">
                <InputLeftAddon>{currency}</InputLeftAddon>
                <Input
                  type="string"
                  id="lookup-email"
                  name="lookup-email"
                  onChange={handleChange}
                  placeholder="Enter amount"
                  value={values['lookup-email']}
                />
              </InputGroup>
              <FormErrorMessage>{errors['lookup-email']}</FormErrorMessage>
            </FormControl>
          </Flex>
          {/* <Flex mb="1rem" alignItems="center" justifyContent="space-between">
            <FormControl
              width={INPUT_WIDTH}
              isInvalid={!!(touched['whatsapp-web'] && errors['whatsapp-web'])}
            >
              <FormLabel fontSize="0.875rem" htmlFor="whatsapp-web">
                WhatsApp Web
              </FormLabel>
              <InputGroup size="sm">
                <InputLeftAddon>{currency}</InputLeftAddon>
                <Input
                  type="string"
                  id="whatsapp-web"
                  name="whatsapp-web"
                  onChange={handleChange}
                  placeholder="Enter amount"
                  value={values['whatsapp-web']}
                />
              </InputGroup>
              <FormErrorMessage>{errors['whatsapp-web']}</FormErrorMessage>
            </FormControl>
            <FormControl
              width={INPUT_WIDTH}
              isInvalid={!!(touched['whatsapp-business'] && errors['whatsapp-business'])}
            >
              <FormLabel fontSize="0.875rem" htmlFor="whatsapp-business">
                WhatsApp Business
              </FormLabel>
              <InputGroup size="sm">
                <InputLeftAddon>{currency}</InputLeftAddon>
                <Input
                  type="string"
                  id="whatsapp-business"
                  name="whatsapp-business"
                  onChange={handleChange}
                  placeholder="Enter amount"
                  value={values['whatsapp-business']}
                />
              </InputGroup>
              <FormErrorMessage>{errors['whatsapp-business']}</FormErrorMessage>
            </FormControl>
          </Flex> */}
          <Stack mb="1rem" isInline alignItems="center" spacing="1rem">
            <Button size="sm" width="100%" type="button" onClick={handleCloseModal}>
              Cancel
            </Button>
            <Button size="sm" width="100%" type="submit" variantColor="blue" isLoading={isLoading}>
              Update
            </Button>
          </Stack>
        </form>
      </ModalBody>
    </ModalContainer>
  );
};
