import { Box, ModalBody, ModalCloseButton, Stack } from '@chakra-ui/core';
import {
  BodyText,
  Button,
  ModalContainer,
  ModalContainerOptions,
  OptionsMenu,
  SmallText,
} from 'app/components';
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectLists, selectListsById } from '../../lists.selectors';

export const MergeModal: React.FC<
  ModalContainerOptions & { base_list?: string; onSubmit(list_id: string): void }
> = props => {
  const { isOpen, onClose, onSubmit, base_list } = props;
  const all_lists = useSelector(selectLists) as any;
  const lists_by_id = useSelector(selectListsById);

  const [listSearch, setListSearch] = useState('');
  const [selectedList, setSelectedList] = useState<string | undefined>();

  const lists = useMemo(() => {
    return lists_by_id
      .map((list_id: string) => all_lists[list_id])
      .filter(list => list.id !== base_list)
      ?.filter(list => list?.name?.toLocaleLowerCase().includes(listSearch.toLocaleLowerCase()));
  }, [all_lists, listSearch, lists_by_id, base_list]);

  const handleClose = () => {
    setSelectedList(undefined);
    onClose?.();
  };

  return (
    <ModalContainer
      isOpen={isOpen}
      titleStyleProps={{
        pb: '0',
      }}
      onClose={handleClose}
      title="Select list to merge"
    >
      <ModalCloseButton size="sm" />
      <ModalBody pb="2rem">
        <Stack spacing={4}>
          <Box>
            <OptionsMenu<any>
              title="Lists"
              options={lists}
              popoverContentProps={{
                w: '100%',
              }}
              onSearch={query => setListSearch(query)}
              onSelectItem={list => setSelectedList(list.id)}
              isItemSelected={list => selectedList === list.id}
              renderItem={(list: any) => <SmallText color="gray.900">{list.name}</SmallText>}
              triggerButtonProps={{
                w: '100%',
                size: 'md',
                type: 'button',
                rightIcon: 'chevron-down',
                justifyContent: 'space-between',
                children: !selectedList ? (
                  <BodyText color="gray.900">Select a list</BodyText>
                ) : (
                  <BodyText color="gray.900">
                    {lists.find(list => list.id === selectedList)?.name}
                  </BodyText>
                ),
              }}
            />
          </Box>
          <Button
            size="sm"
            variantColor="blue"
            alignSelf="flex-end"
            rightIcon="chevron-right"
            onClick={() => selectedList && onSubmit(selectedList)}
          >
            Next
          </Button>
        </Stack>
      </ModalBody>
    </ModalContainer>
  );
};
