import {
  Box,
  ButtonProps,
  Checkbox,
  CheckboxGroup,
  Icon,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverContentProps,
  PopoverProps,
  PopoverTrigger,
  PseudoBox,
  Stack,
} from '@chakra-ui/core/dist';
import { Button, SmallText } from 'app/components';
import React, { useEffect, useState } from 'react';
import { Permission } from '../../settings.types';

export type PermissionDropdownProps = {
  permission?: Permission;
  permissions: Permission[];
  buttonProps?: Omit<ButtonProps, 'children'>;
  onChange?: (permission?: Permission) => void;
  popoverProps?: Omit<PopoverProps, 'children'>;
  invite?: boolean;
  updateData?: any;
  userName?: any;
  handleUpdatedAccess?: any;
  teamInvite?: boolean;
  popoverContentProps?: Omit<PopoverContentProps, 'children'>;
};

export const PermissionDropdown = (props: PermissionDropdownProps) => {
  const {
    invite,
    onChange,
    buttonProps,
    popoverProps,
    permissions,
    updateData,
    teamInvite,
    handleUpdatedAccess,
    popoverContentProps,
    permission: permissionProp,
  } = props;

  const permission = permissionProp ?? permissions[0];

  const [selectedPermission, setSelectedPermission] = useState<Permission | undefined>();
  const [selectedPageAccess, setSelectedPageAccess] = useState<string[]>(
    permission?.page_access.includes('*')
      ? ['dashboard', 'data', 'inbox', 'marketing', 'payments', 'developers']
      : permission?.page_access,
  );

  const handleSelectPageAccess = (value: any) => {
    const page_access = value.filter((item: string) =>
      ['marketing', 'data', 'developers'].includes(item),
    );

    setSelectedPageAccess(page_access);
    if (selectedPermission) {
      onChange?.({ ...selectedPermission, page_access });
    }
  };

  const handleSelectPermission = (value: Permission) => {
    setSelectedPermission(value);
    onChange?.({ ...value, page_access: selectedPageAccess });
  };

  const handleClick = (value: any) => {
    const { id } = selectedPermission ?? {};
    const data = {
      id,
      page_access: selectedPageAccess,
    };
    handleUpdatedAccess(data);
    updateData(data);
  };

  useEffect(() => {
    if (permissionProp) {
      setSelectedPermission(permissionProp);
      if (permissionProp.page_access.includes('*')) {
        setSelectedPageAccess([
          'dashboard',
          'data',
          'inbox',
          'marketing',
          'payments',
          'developers',
        ]);
      } else {
        setSelectedPageAccess(permissionProp.page_access);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permissionProp]);

  return (
    <Popover {...popoverProps}>
      <PopoverTrigger>
        <Button
          size="sm"
          px="0.25rem"
          borderRadius="0"
          borderLeftWidth="0"
          borderTopWidth="1px"
          borderColor="inherit"
          borderBottomWidth="1px"
          backgroundColor="transparent"
          justifyContent="space-between"
          _hover={{
            borderColor: 'gray.300',
          }}
          style={teamInvite ? teamInviteStyle : {}}
          {...buttonProps}
        >
          <Stack isInline alignItems="center">
            <SmallText fontWeight="normal">{selectedPermission?.name ?? 'Select role'}</SmallText>
            <Icon size="1rem" color="#333333" marginLeft=".75rem" name="chevron-down" />
          </Stack>
        </Button>
      </PopoverTrigger>
      <PopoverContent
        zIndex={4}
        outline="none"
        borderWidth="0"
        {...popoverContentProps}
        boxShadow="0px 10px 15px rgba(0, 0, 0, 0.06)"
        _focus={{
          boxShadow: '0px 10px 15px rgba(0, 0, 0, 0.06)',
        }}
      >
        <PopoverBody pl="0">
          <Stack isInline>
            <Box width="50%">
              <SmallText pl="0.75rem" pb="0.5rem" fontWeight="bold">
                Role
              </SmallText>
              <Stack>
                {permissions?.map(permission => (
                  <PseudoBox
                    px="0.75rem"
                    width="100%"
                    display="flex"
                    cursor="pointer"
                    color="gray.900"
                    alignItems="center"
                    key={permission.id}
                    _hover={{
                      bg: 'gray.200',
                    }}
                    justifyContent="space-between"
                    onClick={() => handleSelectPermission(permission)}
                  >
                    <SmallText>{permission.name}</SmallText>
                    {selectedPermission?.name === permission.name && (
                      <Icon name="check" size="0.8rem" color="#5ACA75" />
                    )}
                  </PseudoBox>
                ))}
              </Stack>
              {!invite && (
                <>
                  <Button
                    size="xs"
                    variantColor="blue"
                    ml="0.75rem"
                    mt="3.6rem"
                    p=".35rem"
                    color="white"
                    background="#026ae8"
                    fontSize=".75rem"
                    fontWeight={600}
                    height="auto"
                    onClick={handleClick}
                  >
                    Update
                  </Button>
                </>
              )}
            </Box>
            <Box width="50%">
              <SmallText pb="0.5rem" fontWeight="bold">
                Apps
              </SmallText>
              <CheckboxGroup value={selectedPageAccess} onChange={handleSelectPageAccess}>
                <Checkbox value="data">
                  <SmallText>Data</SmallText>
                </Checkbox>
                <Checkbox value="marketing">
                  <SmallText>Marketing</SmallText>
                </Checkbox>
                <Checkbox value="developers">
                  <SmallText>Developers</SmallText>
                </Checkbox>
              </CheckboxGroup>
            </Box>
          </Stack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

const teamInviteStyle = {
  width: '100%',
  borderRadius: '8px',
  borderLeftWidth: '1px',
  borderRightWidth: '1px',
  borderColor: 'inherit',
  '&:hover': {
    borderColor: '#DADEE3',
  },
  '&:focus': {
    zIndex: 1,
    borderColor: '#026ae8',
    boxShadow: '0px 10px 15px rgba(0, 0, 0, 0.06)',
  },
};
