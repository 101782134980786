import {
  Alert,
  AlertDescription,
  AlertIcon,
  Avatar,
  Box,
  Button,
  CloseButton,
  Flex,
  Heading,
  IconButton,
  PseudoBox,
  Skeleton,
  Stack,
  useDisclosure,
  useToast,
} from '@chakra-ui/core';
import {
  fundResellerSubAccount,
  getResellerSubaccountInvites,
  getResellerSubaccountMembers,
  getResellerSubaccountPricing,
  updateResellerSubaccountPricing,
} from 'app/authenticated-app/settings/service';
import { BodyText, Container, FullPageSpinner, Tab, Tabs, ToastBox } from 'app/components';
import React from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  NavLink,
  Route,
  Switch,
  useHistory,
  useLocation,
  useParams,
  useRouteMatch,
} from 'react-router-dom';
import { FundAccountModal } from './fund-account-modal';
import { SubAccountInvites } from './invites';
import { SubAccountMembers } from './members';
import { HeaderLoader } from './ui-loaders';
import { UpdatePricingModal } from './update-pricing-modal';

export const ResellerSubaccount = () => {
  const toast = useToast();
  const { push } = useHistory();
  const { pathname } = useLocation();
  const queryClient = useQueryClient();
  const { url, path } = useRouteMatch();
  const { id } = useParams<{ id: string }>();
  const {
    isOpen: isPricingModalOpen,
    onOpen: onOpenPricingModal,
    onClose: onClosePricingModal,
  } = useDisclosure();
  const {
    isOpen: isFundAccountModalOpen,
    onOpen: onOpenFundAccountModal,
    onClose: onCloseFundAccountModal,
  } = useDisclosure();

  const getTabsDefaultIndex = () => {
    if (pathname.includes('members')) {
      return 0;
    }
    if (pathname.includes('invites')) {
      return 1;
    }
  };

  const { data: subAccountMembers, isLoading: isLoadingSubAccountMembers } = useQuery(
    ['sub-account-members', id],
    () => getResellerSubaccountMembers(id),
    {
      enabled: !!id,
    },
  );

  const { data: subAccountInvites, isLoading: isLoadingSubAccountInvites } = useQuery(
    ['sub-account-invites', id],
    () => getResellerSubaccountInvites(id),
    {
      enabled: !!id,
    },
  );

  const { data: subAccountPricing, isLoading: isLoadingSubAccountPricing } = useQuery(
    ['sub-account-pricing', id],
    () => getResellerSubaccountPricing(id),
    {
      enabled: !!id && !!subAccountMembers?.members?.length,
    },
  );

  const { isLoading: isUpdatingPricing, mutateAsync: updatePricingMutate } = useMutation(
    (payload: {
      email: string;
      'sms.dnd': string;
      'lookup.dnd': string;
      'sms.non-dnd': string;
      'lookup.phone': string;
      'lookup.email': string;
      'whatsapp-web': string;
      'whatsapp-business': string;
    }) => updateResellerSubaccountPricing(id, { pricing: payload }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['sub-account-pricing', id]);
        onClosePricingModal();
        toast({
          position: 'bottom-left',
          render: ({ onClose }) => (
            <ToastBox onClose={onClose} status="success" message="Pricing updated successfully" />
          ),
        });
      },
      onError: (error: any) => {
        toast({
          position: 'bottom-left',
          render: ({ onClose }) => <ToastBox onClose={onClose} message={error.message} />,
        });
      },
    },
  );

  const { isLoading: isFundingAccount, mutateAsync: fundAccountMutate } = useMutation(
    (payload: { amount: number; organisation_id: string; callback: () => void }) => {
      const { amount, organisation_id } = payload;
      return fundResellerSubAccount({ amount: amount * 100, organisation_id });
    },
    {
      onSuccess: (_, variables) => {
        variables.callback();
        onCloseFundAccountModal();
        toast({
          position: 'bottom-left',
          render: ({ onClose }) => (
            <ToastBox onClose={onClose} status="success" message="Pricing updated successfully" />
          ),
        });
      },
      onError: (error: any) => {
        toast({
          position: 'bottom',
          render: ({ onClose }) => <ToastBox onClose={onClose} message={error?.message ?? error} />,
        });
      },
    },
  );

  const { members, organisations } = subAccountMembers ?? {};

  const handleUpdatePricing = async (values: {
    'sms-non-dnd'?: string;
    'sms-dnd'?: string;
    email?: string;
    'lookup-dnd'?: string;
    'lookup-phone'?: string;
    'lookup-email'?: string;
  }) => {
    const payload = {
      email: values.email,
      'sms.dnd': values['sms-dnd'],
      'lookup.dnd': values['lookup-dnd'],
      'sms.non-dnd': values['sms-non-dnd'],
      'lookup.phone': values['lookup-phone'],
      'lookup.email': values['lookup-email'],
    };

    await updatePricingMutate({
      ...subAccountPricing,
      NG: { ...subAccountPricing?.['NG'], ...payload },
    });
  };

  const handleFundAccount = async (amount: number, callback: () => void) => {
    await fundAccountMutate({ amount, callback, organisation_id: id });
  };

  const handleOpenFundAccountModal = () => {
    if (!members?.length) {
      toast({
        duration: null,
        position: 'top',
        render: ({ onClose }) => (
          <Alert rounded="8px" alignItems="flex-start" status="warning">
            <AlertIcon />
            <AlertDescription fontSize="0.875rem" textAlign="left">
              Sub-account needs to have at least one member who has accepted the invite, to allow
              for account funding.
            </AlertDescription>
            <CloseButton onClick={onClose} size="sm" position="absolute" right="8px" top="0" />
          </Alert>
        ),
      });
    } else {
      onOpenFundAccountModal();
    }
  };

  return (
    <Container maxW="1200px">
      <Flex
        mb="2rem"
        pb="1rem"
        borderBottomWidth="1px"
        alignItems="center"
        justifyContent="space-between"
      >
        <Stack isInline alignItems="center">
          <IconButton
            size="sm"
            rounded="50%"
            variant="ghost"
            icon="arrow-back"
            aria-label="Back"
            onClick={() => push('/s/settings/reseller/sub-accounts')}
          />
          {isLoadingSubAccountMembers || isLoadingSubAccountPricing ? (
            <HeaderLoader />
          ) : (
            <Stack isInline alignItems="center">
              <Avatar color="white" src={organisations?.image} name={organisations?.name} />
              <Box>
                <Heading fontWeight="semibold" size="md">
                  {organisations?.name}
                </Heading>
                {!!subAccountPricing && (
                  <Stack isInline alignItems="center">
                    <BodyText>
                      <PseudoBox fontWeight="500" as="span">
                        Promotional SMS Rate:
                      </PseudoBox>{' '}
                      &#8358;
                      {subAccountPricing?.['NG']['sms.non-dnd']},
                    </BodyText>
                    <BodyText>
                      <PseudoBox fontWeight="500" as="span">
                        Transactional SMS Rate:
                      </PseudoBox>{' '}
                      &#8358;
                      {subAccountPricing?.['NG']['sms.dnd']},
                    </BodyText>
                    <BodyText>
                      <PseudoBox fontWeight="500" as="span">
                        Email Rate:
                      </PseudoBox>{' '}
                      &#8358;
                      {subAccountPricing?.['NG']['email']}
                    </BodyText>
                  </Stack>
                )}
              </Box>
            </Stack>
          )}
        </Stack>
        <Stack isInline alignItems="center">
          <Skeleton isLoaded={!isLoadingSubAccountPricing}>
            <Button size="sm" variantColor="blue" onClick={handleOpenFundAccountModal}>
              Fund account
            </Button>
          </Skeleton>
          <Skeleton isLoaded={!isLoadingSubAccountPricing}>
            <Button size="sm" variant="outline" variantColor="blue" onClick={onOpenPricingModal}>
              Change Pricing
            </Button>
          </Skeleton>
        </Stack>
      </Flex>
      <Tabs px="1rem" mb="1.5rem" defaultIndex={getTabsDefaultIndex()}>
        <Tab>
          <NavLink to={`${url}/members`} activeStyle={{ color: '#026ae8' }}>
            <BodyText color="inherit">Members</BodyText>
          </NavLink>
        </Tab>
        <Tab>
          <NavLink to={`${url}/invites`} activeStyle={{ color: '#026ae8' }}>
            <BodyText color="inherit">Invites</BodyText>
          </NavLink>
        </Tab>
      </Tabs>
      {isLoadingSubAccountMembers || isLoadingSubAccountInvites ? (
        <FullPageSpinner height="50vh" />
      ) : (
        <Switch>
          <Route path={`${path}/members`}>
            <SubAccountMembers people={members} sub_account_id={id} />
          </Route>
          <Route path={`${path}/invites`}>
            <SubAccountInvites invites={subAccountInvites} sub_account_id={id} />
          </Route>
        </Switch>
      )}
      {isPricingModalOpen && (
        <UpdatePricingModal
          isOpen={isPricingModalOpen}
          isLoading={isUpdatingPricing}
          onClose={onClosePricingModal}
          onSubmit={handleUpdatePricing}
          organisationName={organisations?.name}
          initialValues={{
            email: subAccountPricing?.['NG']?.email,
            'sms-dnd': subAccountPricing?.['NG']['sms.dnd'],
            'lookup-dnd': subAccountPricing?.['NG']['lookup.dnd'],
            'sms-non-dnd': subAccountPricing?.['NG']['sms.non-dnd'],
            'lookup-phone': subAccountPricing?.['NG']['lookup.phone'],
            'lookup-email': subAccountPricing?.['NG']['lookup.email'],
            // 'whatsapp-web': subAccountPricing?.['NG']['whatsapp-web'],
            // 'whatsapp-business': subAccountPricing?.['NG']['whatsapp-business'],
          }}
        />
      )}
      {isFundAccountModalOpen && (
        <FundAccountModal
          onSubmit={handleFundAccount}
          isLoading={isFundingAccount}
          isOpen={isFundAccountModalOpen}
          onClose={onCloseFundAccountModal}
          organisationName={organisations?.name}
        />
      )}
    </Container>
  );
};
