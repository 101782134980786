import { Avatar, Box, Heading, Stack, useDisclosure, useToast } from '@chakra-ui/core/dist';
import { fetchWallet } from 'app/authenticated-app/payments';
import { selectWalletData } from 'app/authenticated-app/payments/selectors';
import {
  BodyText,
  Button,
  Container,
  FileUploader,
  Input,
  ToastBox,
  XSmallText,
} from 'app/components';
import { AxiosError } from 'axios';
import { FormikHelpers, useFormik } from 'formik';
import isEmpty from 'lodash/isEmpty';
import React, { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { loadState, saveState } from 'utils';
import * as yup from 'yup';
import {
  cancelDeleteOrganisationRequest,
  deleteOrganisationRequest,
  getDeleteOrganisationRequest,
  logout,
  selectOrganisationID,
  selectProfile,
  selectUserDevice,
  updateOrganisation,
} from '../../../../unauthenticated-app/authentication';
import { updateCampaignCreditThreshold } from '../../service';
import { Organization } from '../../settings.types';
import { selectOrganisations } from '../../slices';
import { DeleteAccountPayload } from '../profile/DeleteAccountModal';
import { DeleteOrganisationModal } from '../profile/DeleteOrganisationModal';
import { OrganizationPageProps } from './container';

export const OrganizationComponent = (props: OrganizationPageProps) => {
  const toast = useToast();
  const history = useHistory();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const profile = useSelector(selectProfile);
  const device = useSelector(selectUserDevice);
  const walletData = useSelector(selectWalletData);
  const organizations = useSelector(selectOrganisations);
  const organizationID = useSelector(selectOrganisationID);
  const currentOrganization = organizations.find((i: any) => i.id === organizationID);

  const { saveOrganisation, organisationImage, logUserOut } = props;

  const [isDeletingOrganisation, setIsDeletingOrganisation] = useState(false);
  const [isUpdatingOrganisation, setIsUpdatingOrganisation] = useState(false);
  const [isUploadingProfileImage, setIsUploadingProfileImage] = useState(false);
  const [preview, setPreview] = useState<string | ArrayBuffer | null | undefined>('');
  const [isCancellingDeleteOrganisationRequest, setIsCancellingDeleteOrganisationRequest] =
    useState(false);

  const {
    isOpen: isDeleteOrganisationModalOpen,
    onClose: onCloseDeleteOrganisationModal,
    onOpen: onOpenDeleteOrganisationModal,
  } = useDisclosure();

  const { data: hasDeleteOrganisationRequest } = useQuery(
    'delete-organisation-request',
    getDeleteOrganisationRequest,
  );

  const handleSaveLocalStorageOrganisations = (organisation: Organization) => {
    const organisations = (loadState() ?? {}).organisations;
    const newOrganisations = organisations.map((item: Organization) => {
      if (item.id === organisation.id) {
        return organisation;
      }
      return item;
    });
    saveState({ ...(loadState() ?? {}), organisations: newOrganisations });
  };

  const handleOrganisationUpdate = async (values: Pick<Organization, 'name'>) => {
    try {
      setIsUpdatingOrganisation(true);
      const organisation = await updateOrganisation(values);
      saveOrganisation({ organisation });
      handleSaveLocalStorageOrganisations(organisation);
      setIsUpdatingOrganisation(false);
      toast({
        position: 'bottom-left',
        render: ({ onClose }) => (
          <ToastBox
            status="success"
            onClose={onClose}
            message="Organisation updated successfully"
          />
        ),
      });
    } catch (error) {
      setIsUpdatingOrganisation(false);
      toast({
        position: 'bottom-left',
        render: ({ onClose }) => <ToastBox onClose={onClose} message={error} />,
      });
    }
  };

  const handleUploadPreview = (file: File) => {
    const reader = new FileReader();
    reader.onload = e => {
      setPreview(e?.target?.result);
    };
    reader.readAsDataURL(file);
  };

  const handleDropRejectedProfileImage = () => {
    toast({
      description: 'Uploaded image size cannot be more than 2MB',
      status: 'error',
      duration: 9000,
      isClosable: true,
      position: 'top',
    });
  };

  const handleDropProfileImage = async (files: File[]) => {
    const image = files[0];
    if (image) {
      handleUploadPreview(image);
      try {
        setIsUploadingProfileImage(true);
        await organisationImage(image);
        setIsUploadingProfileImage(false);
        toast({
          position: 'bottom-left',
          render: ({ onClose }) => (
            <ToastBox status="success" onClose={onClose} message="Profile image uploaded" />
          ),
        });
      } catch (error) {
        setIsUploadingProfileImage(false);
        toast({
          position: 'bottom-left',
          render: ({ onClose }) => <ToastBox onClose={onClose} message={error.message} />,
        });
      }
    } else {
      return;
    }
  };

  const handleLogout = async () => {
    try {
      await logout(device?.id ?? '');
      logUserOut();
      history.push('/login');
    } catch (error) {
      toast({
        position: 'bottom-left',
        render: ({ onClose }) => (
          <ToastBox
            status="error"
            onClose={onClose}
            message={error?.message ?? 'Error logging out.'}
          />
        ),
      });
    }
  };

  const handleCancelDeleteOrganisationRequest = async () => {
    try {
      setIsCancellingDeleteOrganisationRequest(true);
      await cancelDeleteOrganisationRequest();
      queryClient.invalidateQueries('delete-organisation-request');
      setIsCancellingDeleteOrganisationRequest(false);
    } catch (error) {
      setIsCancellingDeleteOrganisationRequest(false);
      toast({
        position: 'bottom-left',
        render: ({ onClose }) => <ToastBox onClose={onClose} message={error} />,
      });
    }
  };

  const handleDeleteOrganisationRequest = async (
    values: DeleteAccountPayload,
    formikHelpers: FormikHelpers<DeleteAccountPayload>,
  ) => {
    const { reason } = values;

    try {
      setIsDeletingOrganisation(true);
      await deleteOrganisationRequest({ reason });
      formikHelpers.resetForm();
      onCloseDeleteOrganisationModal();
      queryClient.invalidateQueries('delete-account-request');
      await handleLogout();
      setIsDeletingOrganisation(false);
    } catch (error) {
      setIsDeletingOrganisation(false);
      toast({
        position: 'bottom-left',
        render: ({ onClose }) => <ToastBox onClose={onClose} message={error} />,
      });
    }
  };

  const { isLoading: isUpdatingCreditThreshold, mutate: updateCreditThresholdMutate } = useMutation<
    any,
    AxiosError,
    any,
    any
  >((data: any) => updateCampaignCreditThreshold(data), {
    onSuccess: () => {
      organizationID && dispatch(fetchWallet(organizationID));
      toast({
        position: 'bottom-left',
        render: ({ onClose }) => (
          <ToastBox
            status="success"
            onClose={onClose}
            message="Campaign credit threshold updated successfully"
          />
        ),
      });
    },
    onError: error => {
      toast({
        position: 'bottom-left',
        render: ({ onClose }) => <ToastBox onClose={onClose} message={error.message} />,
      });
    },
  });

  const { values, errors, touched, handleChange, handleSubmit } = useFormik({
    enableReinitialize: true,
    onSubmit: handleOrganisationUpdate,
    initialValues: { name: currentOrganization?.name ?? '' },
    validationSchema: yup.object().shape({
      name: yup.string().required('Name is required'),
    }),
  });

  const creditThresholdForm = useFormik({
    enableReinitialize: true,
    onSubmit: values =>
      updateCreditThresholdMutate({
        ...values,
        credit_threshold_amount: values.credit_threshold_amount * 100,
        credit_threshold_alert_enabled: !walletData.credit_threshold_alert_enabled
          ? true
          : walletData.credit_threshold_alert_enabled,
      }),
    initialValues: { credit_threshold_amount: walletData.credit_threshold_amount / 100 },
    validationSchema: yup.object().shape({
      credit_threshold_amount: yup.number().required('Amount is required'),
    }),
  });

  return (
    <Container maxW="640px">
      <Stack marginBottom="3rem">
        <Heading size="md" fontWeight="semibold">
          Organisation Settings
        </Heading>
        <BodyText>Manage your general organisation settings.</BodyText>
      </Stack>

      <Stack mb="3rem">
        <Heading size="md" fontWeight="semibold">
          Logo
        </Heading>
        <BodyText>Add your organisation logo</BodyText>
        <Stack isInline alignItems="center">
          <Avatar
            size="lg"
            color="white"
            name={values?.name}
            src={
              preview && typeof preview === 'string' ? preview : currentOrganization?.image ?? ''
            }
          />
          {profile?.is_owner && (
            <Box>
              <FileUploader
                maxSize={2000000}
                onUpload={handleDropProfileImage}
                onDropRejected={handleDropRejectedProfileImage}
              >
                <Button isLoading={isUploadingProfileImage} size="sm">
                  {!(preview || currentOrganization?.image) ? 'Upload' : 'Change'} logo
                </Button>
              </FileUploader>
              <XSmallText pt="0.25rem" color="gray.500">
                Max. image size of 2MB
              </XSmallText>
            </Box>
          )}
        </Stack>
      </Stack>
      <form style={{ width: '100%' }} onSubmit={handleSubmit}>
        <Heading mb="1rem" size="md" fontWeight="semibold">
          General
        </Heading>
        <Stack alignItems="flex-start" marginBottom="3rem">
          <Box width="100%">
            <Input
              mb="1rem"
              name="name"
              value={values.name}
              onChange={handleChange}
              label="Organisation name"
              errorMessage={errors.name}
              isDisabled={!profile?.is_owner}
              isInvalid={!!touched.name && !!errors.name}
            />
          </Box>
          {profile?.is_owner && (
            <Button size="sm" type="submit" variantColor="blue" isLoading={isUpdatingOrganisation}>
              Update
            </Button>
          )}
        </Stack>
      </form>

      <Box mb="2rem">
        <Stack mb="2rem">
          <Heading fontWeight="semibold" size="md" color="#333333">
            Campaign credit threshold
          </Heading>
          <BodyText>
            Set a threshold to start receiving alerts for your campaign credit exhaustion.
          </BodyText>
        </Stack>
        <Box width="100%">
          <Input
            mb="1rem"
            type="number"
            label="Amount"
            name="credit_threshold_amount"
            leftIcon={<BodyText>&#8358;</BodyText>}
            onChange={creditThresholdForm.handleChange}
            value={creditThresholdForm.values.credit_threshold_amount}
            errorMessage={creditThresholdForm.errors.credit_threshold_amount}
            isInvalid={
              !!creditThresholdForm.touched.credit_threshold_amount &&
              !!creditThresholdForm.errors.credit_threshold_amount
            }
          />
          <Button
            size="sm"
            variantColor="blue"
            isLoading={isUpdatingCreditThreshold}
            onClick={creditThresholdForm.submitForm}
          >
            Update
          </Button>
        </Box>
      </Box>

      {profile?.is_owner && (
        <Box rounded="8px" bg="red.50" mb="1.5rem" p="1rem">
          <Stack alignItems="flex-start">
            <Heading fontWeight="semibold" size="md" color="red.500">
              Danger zone
            </Heading>
            <BodyText color="red.400">
              If you want to permanently delete this organisation and all of its data, you can do so
              below.
            </BodyText>
            {!isEmpty(hasDeleteOrganisationRequest) ? (
              <Button
                size="sm"
                variantColor="blue"
                onClick={handleCancelDeleteOrganisationRequest}
                isLoading={isCancellingDeleteOrganisationRequest}
              >
                Cancel delete organisation request
              </Button>
            ) : (
              <Button size="sm" variantColor="red" onClick={onOpenDeleteOrganisationModal}>
                Delete this organisation
              </Button>
            )}
          </Stack>
        </Box>
      )}
      <DeleteOrganisationModal
        isDeleting={isDeletingOrganisation}
        isOpen={isDeleteOrganisationModalOpen}
        onClose={onCloseDeleteOrganisationModal}
        onDeleteAccount={handleDeleteOrganisationRequest}
      />
    </Container>
  );
};
