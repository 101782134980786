import React, { useContext } from 'react';
import { Box } from '@chakra-ui/core';
import { Button, Input } from 'app/components';
import Titles from '../sections/app-sections/titles';
import Params from '../sections/app-sections/params';
import Authorization from '../sections/app-sections/authorization';
import Headers from '../sections/app-sections/headers';
import Pagination from '../sections/app-sections/pagination';
import Settings from '../sections/app-sections/settings';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { AppParamsContext } from '../../contexts/app-contexts/params.context';

interface Props {
  setSection: Function;
}

const Apps = (props: Props) => {
  const initialValues = {
    name: '',
    account_name: '',
  };

  const CustomAppValidationSchema = yup.object().shape({
    name: yup.string().required('App name is required'),
    account_name: yup.string().required('Requests base URL is required'),
  });

  const submit = async () => {};

  const formik = useFormik({
    validationSchema: CustomAppValidationSchema,
    initialValues,
    onSubmit: submit,
  });

  const { errors, handleBlur, handleReset, handleChange, touched } = formik;

  const {
    urlText,
    setUrlText,
    setRowId,
    rowId,
    userText,
    setUserText,
    paramsState,
    setParamsState,
    createNewList,
    urlParser,
    setAppName,
    appName,
    nextPageState,
    setNextPage,
    selectedTitle,
    setSelectedTitle,
  } = useContext(AppParamsContext);

  const changeHandler = (e: any) => {
    handleChange(e);

    if (!e.target.value) {
      nextPageState[1] = false;
      setNextPage([...nextPageState]);
    } else {
      nextPageState[1] = true;
      setNextPage([...nextPageState]);
    }

    if (e.target.value.includes(urlText) && e.target.value.indexOf('/{request_url}?') === -1) {
      setUserText(e.target.value.replace(urlText, ''));
    } else if (e.target.value.indexOf('/{request_url}?') !== -1) {
      let discardIndex = e.target.value.indexOf('/{request_url}?');
      let discardString = e.target.value.substr(discardIndex);

      setUserText(e.target.value.replace(discardString, ''));
      const urlFieldString = e.target.value.split('?')[1];

      if (
        (typeof urlFieldString === 'string' || urlFieldString instanceof String) &&
        urlFieldString
      ) {
        urlFieldParser(urlFieldString as string);
        urlParser();
      } else {
        setParamsState([
          {
            id: 0,
            isWritten: false,
            isDisabled: false,
            key_text: '',
            value_text: '',
            description_text: '',
          },
        ]);
        setUrlText('/{request_url}?');
      }
    } else if (e.target.value.indexOf('/{request_url}') !== -1) {
      setParamsState([
        {
          id: 0,
          isWritten: false,
          isDisabled: false,
          key_text: '',
          value_text: '',
          description_text: '',
        },
      ]);
      setUrlText('');
    }
  };

  const urlFieldParser = (urlFieldString: string) => {
    const urlArray = urlFieldString.split('&');

    urlArray.forEach((item, index, array) => {
      if (item === '') {
        paramsState.splice(index, 1);
        paramsState.forEach((item, innerIndex, array) => {
          if (innerIndex >= index) {
            array[innerIndex] = { ...item, id: item.id - 1 };
          }
        });
        setParamsState([...paramsState]);
      }
    });

    urlArray
      .filter(item => !!item)
      .forEach((item, index, array) => {
        let subArray = item.split('=');

        if (
          subArray[0] !== '' &&
          (typeof subArray[1] === 'string' || (subArray[1] as any) instanceof String)
        ) {
          paramsState[index].key_text = subArray[0];
          paramsState[index].value_text = subArray[1];
          paramsState[index].isDisabled = false;
        } else if (subArray[0] !== '') {
          paramsState[index].key_text = subArray[0];
          paramsState[index].value_text = subArray[1];
          paramsState[index].isDisabled = false;
        } else if (subArray[0] === '') {
          // if (paramsState[paramsState.length-1].isWritten === false) {
          //     createNewList(paramsState[paramsState.length-1])
          // }
          createNewList(paramsState[paramsState.length - 1]);
          paramsState[paramsState.length - 1].key_text = subArray[0];
          paramsState[paramsState.length - 1].value_text = subArray[1];
        }
      });

    if (urlArray.length < paramsState.length) {
      paramsState.splice(urlArray.length, paramsState.length - urlArray.length - 1);
      paramsState[urlArray.length].id = urlArray.length;
      setParamsState(paramsState);
      setRowId(rowId - (paramsState.length - urlArray.length - 1));
    } else if (urlArray.length > paramsState.length) {
      createNewList(paramsState[paramsState.length - 1]);
    }
  };
  const appNameHandler = (e: any) => {
    setAppName(e.target.value);
    handleChange(e);
    if (!e.target.value) {
      nextPageState[0] = false;
      setNextPage([...nextPageState]);
    } else {
      nextPageState[0] = true;
      setNextPage([...nextPageState]);
    }
  };

  return (
    <Box>
      <Box>
        <Box className="row">
          <Box className="field custom-app-field">
            <Input
              label="App Name"
              isInvalid={touched.name && !!errors.name && !nextPageState[0]}
              mb="5px"
              name="name"
              value={appName}
              onChange={(e: any) => appNameHandler(e)}
              onBlur={handleBlur}
              onReset={handleReset}
              errorMessage={errors.name}
              placeholder="Enter the name of your app."
              autoComplete="off"
              // autoFocus={true}
            />
          </Box>
        </Box>

        <Box className="row" mt={5}>
          <Box className="field custom-app-field">
            <Input
              label="Request Base URL"
              isInvalid={touched.account_name && !!errors.account_name && !nextPageState[1]}
              mb="5px"
              name="account_name"
              value={userText + urlText}
              onChange={(e: any) => changeHandler(e)}
              onBlur={handleBlur}
              onReset={handleReset}
              errorMessage={errors.account_name}
              placeholder="Enter the base URL for making requests to your app."
              autoComplete="off"
            />
          </Box>
        </Box>
      </Box>

      <Box mt={'2rem'}>
        <Titles
          titleHeadings={['Global Params', 'Authorization', 'Headers', 'Settings']}
          selectedTitle={selectedTitle}
          setSelectedTitle={setSelectedTitle}
        />
      </Box>

      <Box className="row" mt={'1rem'}>
        {selectedTitle === 'Global Params' && <Params />}
        {selectedTitle === 'Authorization' && <Authorization />}
        {selectedTitle === 'Headers' && <Headers />}
        {selectedTitle === 'Pagination' && <Pagination />}
        {selectedTitle === 'Settings' && <Settings />}
      </Box>

      <Box mt={12} className="custom-app-apps-button">
        <Button
          variant="solid"
          variantColor="blue"
          size="sm"
          onClick={() => props.setSection(1)}
          isLoading={false}
          width="123px"
          height="42px"
          isDisabled={
            (!!Object.keys(errors).length || !Object.keys(touched).length) &&
            !(nextPageState[0] === true && nextPageState[1] === true)
          }
        >
          Next
        </Button>
      </Box>
    </Box>
  );
};

export default Apps;
