import { Box, FormControl, Grid, Icon, useDisclosure } from '@chakra-ui/core';
import { Button, Input, Select } from 'app/components';
import React, { useContext, useEffect, useState } from 'react';
import Params from '../sections/request-sections/params';
import RequestBody from '../sections/request-sections/request-body';
import Titles from '../sections/request-sections/titles';
import Settings from '../sections/request-sections/settings';
import ResponseBody from '../sections/request-sections/response-body';
import { useFormik } from 'formik';
import * as yup from 'yup';
import {
  RequestArrayProps,
  RequestParamsContext,
} from '../../contexts/request-contexts/params.context';
import { RequestHeadersContext } from '../../contexts/request-contexts/headers.context';
import { RequestPaginationContext } from '../../contexts/request-contexts/pagination.context';
import { AppParamsContext } from '../../contexts/app-contexts/params.context';
import { AppHeadersContext } from '../../contexts/app-contexts/headers.context';
import {
  fetchCustomWebhooksUrl,
  testCustomRequest,
} from 'app/authenticated-app/lists/thunks/resources';
import { useDispatch } from 'react-redux';
import JSONInput from 'react-json-editor-ajrm';
//@ts-ignore
import locale from 'react-json-editor-ajrm/locale/en';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/core';
import { WebhooksParamsContext } from '../../contexts/webhooks-contexts/params.context';

interface Props {
  setSection: Function;
  updateData?: boolean;
}

const Requests = (props: Props) => {
  const responseTypes = [
    { id: 'get', request_name: 'GET' },
    { id: 'post', request_name: 'POST' },
  ];

  // New Params
  const {
    urlText,
    setRowId,
    rowId,
    userText,
    setUserText,
    paramsState,
    setParamsState,
    createNewList,
    urlParser,
    requestArray,
    setRequestArray,
    currentCell,
    cursorPosition,
    totalText,
    dropDown,
    setUrlText,
    setDropDown,
    setCurrentCell,
    setCursorPosition,
    setTotalText,
    requestName,
    setRequestName,
    setRequestInputFocus,
    setRelativeInputFocus,
    expandedState,
    setExpandedState,
    requestFocusRef,
    relativeFocusRef,
    requestMethod,
    setRequestMethod,
    requestInnerFocus,
    setRequestInnerFocus,
    relativeInnerFocus,
    setRelativeInnerFocus,
    responseBody,
    setResponseBody,
    requestBody,
    setRequestBody,
    settingsUniqueIdentifier,
    setSettingsUniqueIdentifier,
    nextPageState,
    setNextPage,
    selectedTitle,
    setSelectedTitle,
    arrayKey,
    setArrayKey,
    jsonResponse,
    setJsonResponse,
    testResult,
    setTestResult,
    isJsonAvailable,
    setIsJsonAvailable,
    responseBodyEnabled,
    setResponseBodyEnabled,
    testResultResponse,
    setTestResultResponse,
    isJsonAvailableResponse,
    setIsJsonAvailableResponse,
    isResponseSelectorValid,
    setIsResponseSelectorValid,
    schemaResponse,
    setSchemaResponse,
  } = useContext(RequestParamsContext);

  const createNewParams = (item: number) => {
    setExpandedState(item + 1);
    paramsState[item + 1] = [
      {
        id: 0,
        isWritten: false,
        isDisabled: false,
        key_text: '',
        value_text: '',
        description_text: '',
      },
    ];
    userText[item + 1] = '';
    urlText[item + 1] = '';
    totalText[item + 1] = '';
    rowId[item + 1] = 0;
    currentCell[item + 1] = [0, 0, ''];
    cursorPosition[item + 1] = [0, 0];
    dropDown[item + 1] = [false, 0, 0];
    requestArray[item + 1] = { id: item + 1, current: false };
    requestBody[item + 1] = null;
    responseBody[item + 1] = null;
    settingsUniqueIdentifier[item + 1] = '';
    requestMethod[item + 1] = 'get';
    arrayKey[item + 1] = '';
    testResult[item + 1] = [null, false];
    isJsonAvailable[item + 1] = false;
    responseBodyEnabled[item + 1] = false;
    selectedTitle[item + 1] = 'Params';
    testResultResponse[item + 1] = [null, false];
    isJsonAvailableResponse[item + 1] = false;
    isResponseSelectorValid[item + 1] = null;
    schemaResponse[item + 1] = [null];
    setUserText([...userText]);
    setUrlText([...urlText]);
    setTotalText([...totalText]);
    setRowId([...rowId]);
    setCurrentCell([...currentCell]);
    setCursorPosition([...cursorPosition]);
    setDropDown([...dropDown]);
    setParamsState([...paramsState]);
    setRequestArray([...requestArray]);
    setRequestBody([...requestBody]);
    setResponseBody([...responseBody]);
    setSettingsUniqueIdentifier([...settingsUniqueIdentifier]);
    setRequestMethod([...requestMethod]);
    setArrayKey([...arrayKey]);
    setTestResult([...testResult]);
    setIsJsonAvailable([...isJsonAvailable]);
    setResponseBodyEnabled([...responseBodyEnabled]);
    setSelectedTitle([...selectedTitle]);
    setTestResultResponse([...testResultResponse]);
    setIsJsonAvailableResponse([...isJsonAvailableResponse]);
    setIsResponseSelectorValid([...isResponseSelectorValid]);
    setSchemaResponse([...schemaResponse]);
  };

  // New Header
  const {
    urlText: headersUrlText,
    setRowId: headersSetRowId,
    rowId: headersRowId,
    userText: headersUserText,
    setUserText: headersSetUserText,
    paramsState: headersParamState,
    setParamsState: headersSetParamsState,
    requestArray: headersRequestArray,
    setRequestArray: headersSetRequestArray,
    currentCell: headersCurrentCell,
    cursorPosition: headersCursorPosition,
    totalText: headersTotalText,
    dropDown: headersDropDown,
    setUrlText: headersSetUrlText,
    setDropDown: headersSetDropDown,
    setCurrentCell: headersSetCurrentCell,
    setCursorPosition: headersSetCursorPosition,
    setTotalText: headersSetTotalText,
    setExpandedState: headersSetExpandedState,
  } = useContext(RequestHeadersContext);

  const createNewHeaders = (item: number) => {
    headersSetExpandedState(item + 1);
    headersParamState[item + 1] = [
      {
        id: 0,
        isWritten: false,
        isDisabled: false,
        key_text: '',
        value_text: '',
        description_text: '',
      },
    ];
    headersUserText[item + 1] = '';
    headersUrlText[item + 1] = '';
    headersTotalText[item + 1] = '';
    headersRowId[item + 1] = 0;
    headersCurrentCell[item + 1] = [0, 0, ''];
    headersCursorPosition[item + 1] = [0, 0];
    headersDropDown[item + 1] = [false, 0, 0];
    headersSetUserText([...headersUserText]);
    headersSetUrlText([...headersUrlText]);
    headersSetTotalText([...headersTotalText]);
    headersSetRowId([...headersRowId]);
    headersSetCurrentCell([...headersCurrentCell]);
    headersSetCursorPosition([...headersCursorPosition]);
    headersSetDropDown([...headersDropDown]);
    headersSetParamsState([...headersParamState]);
    headersRequestArray[item + 1] = { id: item + 1, current: false };
    headersSetRequestArray([...headersRequestArray]);
  };

  const {
    urlText: paginationUrlText,
    setRowId: paginationSetRowId,
    rowId: paginationRowId,
    userText: paginationUserText,
    setUserText: paginationSetUserText,
    paramsState: paginationParamState,
    setParamsState: paginationSetParamsState,
    requestArray: paginationRequestArray,
    setRequestArray: paginationSetRequestArray,
    currentCell: paginationCurrentCell,
    cursorPosition: paginationCursorPosition,
    totalText: paginationTotalText,
    dropDown: paginationDropDown,
    setUrlText: paginationSetUrlText,
    setDropDown: paginationSetDropDown,
    setCurrentCell: paginationSetCurrentCell,
    setCursorPosition: paginationSetCursorPosition,
    setTotalText: paginationSetTotalText,
    setExpandedState: paginationSetExpandedState,
  } = useContext(RequestPaginationContext);

  const createNewPagination = (item: number) => {
    paginationSetExpandedState(item + 1);
    paginationParamState[item + 1] = [
      {
        id: 0,
        isWritten: false,
        isDisabled: false,
        key_text: '',
        value_text: '',
        description_text: '',
      },
    ];
    paginationUserText[item + 1] = '';
    paginationUrlText[item + 1] = '';
    paginationTotalText[item + 1] = '';
    paginationRowId[item + 1] = 0;
    paginationCurrentCell[item + 1] = [0, 0, ''];
    paginationCursorPosition[item + 1] = [0, 0];
    paginationDropDown[item + 1] = [false, 0, 0];
    paginationSetUserText([...paginationUserText]);
    paginationSetUrlText([...paginationUrlText]);
    paginationSetTotalText([...paginationTotalText]);
    paginationSetRowId([...paginationRowId]);
    paginationSetCurrentCell([...paginationCurrentCell]);
    paginationSetCursorPosition([...paginationCursorPosition]);
    paginationSetDropDown([...paginationDropDown]);
    paginationSetParamsState([...paginationParamState]);
    paginationRequestArray[item + 1] = { id: item + 1, current: false };
    paginationSetRequestArray([...paginationRequestArray]);
  };
  const createNewRequest = (item: number) => {
    createNewParams(item);
    createNewHeaders(item);
    createNewPagination(item);
    nextPageState[item + 1] = [false, false];
    setNextPage([...nextPageState]);
  };

  interface RequestComponentProps {
    item: RequestArrayProps;
    setSection: Function;
  }

  const RequestComponent = (innerProps: RequestComponentProps) => {
    const initialValues = {
      name: '',
      account_name: '',
    };

    const CustomAppValidationSchema = yup.object().shape({
      name: yup.string().required("Request's name is required."),
      account_name: yup.string().required("Request's relative URL is required."),
    });

    const submit = async () => {};

    const formik = useFormik({
      validationSchema: CustomAppValidationSchema,
      initialValues,
      onSubmit: submit,
    });

    const { errors, handleBlur, handleReset, handleChange, touched } = formik;

    const changeHandler = (e: any, n: number) => {
      if (e.target.value.includes(urlText[n]) && e.target.value.indexOf('?') === -1) {
        userText[n] = e.target.value.replace(urlText, '');
        setUserText(userText);
      } else if (e.target.value.indexOf('?') !== -1) {
        let splitString = e.target.value.split('?');
        userText[n] = splitString[0];
        setUserText(userText);

        const urlFieldString = splitString[1];

        if (
          (typeof urlFieldString === 'string' || urlFieldString instanceof String) &&
          urlFieldString
        ) {
          urlFieldParser(urlFieldString as string, n);
          urlParser(n);
        } else {
          paramsState[n] = [
            {
              id: 0,
              isWritten: false,
              isDisabled: false,
              key_text: null,
              value_text: null,
              description_text: '',
            },
          ];
          setParamsState(paramsState);
          urlText[n] = '?';
          setUrlText(urlText);
        }
      } else if (e.target.value.indexOf('?') === -1) {
        paramsState[n] = [
          {
            id: 0,
            isWritten: false,
            isDisabled: false,
            key_text: null,
            value_text: null,
            description_text: '',
          },
        ];
        setParamsState(paramsState);
        urlText[n] = '';
        setUrlText(urlText);
        userText[n] = '';
        setUserText(userText);
      }

      handleChange(e);
    };

    const urlFieldParser = (urlFieldString: string, n: number) => {
      const urlArray = urlFieldString.split('&');
      urlArray.forEach((item, index, array) => {
        if (!item) {
          // paramsState[n].splice(index,1);
          paramsState[n][index].key_text = '';
          // paramsState[n].forEach((item, innerIndex, array) => {
          //     if (innerIndex >= index) {
          //         array[innerIndex] = {...item, id: item.id-1}
          //     }
          // });
          setParamsState(paramsState);
        }
      });

      urlArray
        .filter(item => !!item && typeof item === 'string')
        .forEach((item, index, array) => {
          let subArray = item.split('=');

          if (
            subArray[0] !== '' &&
            (typeof subArray[1] === 'string' || (subArray[1] as any) instanceof String)
          ) {
            // paramsState[n] = paramsState[n].concat({id: paramsState[n].length, isWritten: true, isDisabled: false, key_text: null, value_text: null, description_text: ''});
            paramsState[n][index].key_text = subArray[0];
            paramsState[n][index].value_text = subArray[1];
            paramsState[n][index].isDisabled = false;
          } else if (subArray[0] !== '') {
            paramsState[n][index].key_text = subArray[0];
            paramsState[n][index].value_text = null;
            paramsState[n][index].isDisabled = false;
          } else if (subArray[0] === '') {
            if (paramsState[n][index].isWritten === false) {
              createNewList(paramsState[n][index], n);
            }
            paramsState[n][index].key_text = subArray[0];
            paramsState[n][index].value_text = subArray[1];
          }
        });

      if (urlArray.length < paramsState[n].length) {
        paramsState[n].splice(urlArray.length, paramsState[n].length - urlArray.length - 1);
        paramsState[n][urlArray.length].id = urlArray.length;
        setParamsState(paramsState);
        rowId[n] = rowId[n] - (paramsState[n].length - urlArray.length - 1);
        setRowId(rowId);
      } else if (urlArray.length >= paramsState[n].length) {
        createNewList(paramsState[n][paramsState[n].length - 1], n);
      }
    };

    const requestNameHandler = (e: any) => {
      requestName[innerProps?.item?.id] = e.target.value;
      setRequestName(requestName);
      handleChange(e);
    };

    const keyEventHandler = (e: any, i: number) => {
      if (e.target.value === '') {
        nextPageState[innerProps?.item?.id][i] = false;
        setNextPage(nextPageState);
      } else {
        nextPageState[innerProps?.item?.id][i] = true;
        setNextPage(nextPageState);
      }
      handleChange(e);
    };

    const requestMethodHandler = (e: any) => {
      requestMethod[innerProps?.item?.id] = e.target.value;
      setRequestMethod([...requestMethod]);
    };

    function useOutsideAlerter(ref: any, focusFunction: any, innerFunction: any) {
      useEffect(() => {
        function handleClickOutside(e: any) {
          if (ref.current && !ref.current.contains(e.target)) {
            focusFunction(false);
            innerFunction(false);
          } else if (ref.current && ref.current.contains(e.target)) return;
        }
        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
          // Unbind the event listener on clean up
          document.removeEventListener('mousedown', handleClickOutside);
        };
      }, [ref, focusFunction, innerFunction]);
    }

    const deleteRequestItem = (
      n: number,
      requestItem: any,
      setRequestItem: any,
      indexed: boolean,
    ) => {
      if (indexed) {
        requestItem.splice(n, 1);
        requestItem.forEach((item: any, index: number, array: Array<any>) => {
          if (index >= n) {
            array[index] = { ...item, id: item?.id - 1 };
          }
        });
        setRequestItem([...requestItem]);
      } else {
        requestItem.splice(n, 1);
        setRequestItem([...requestItem]);
      }
    };

    const deletionHandler = (n: number) => {
      requestArray.splice(n, 1);
      requestArray.forEach((item, index, array) => {
        if (index >= n) {
          array[index] = { ...item, id: item.id - 1 };
        }
      });
      setRequestArray([...requestArray]);
      [
        [userText, setUserText],
        [urlText, setUrlText],
        [totalText, setTotalText],
        [rowId, setRowId],
        [currentCell, setCurrentCell],
        [cursorPosition, setCursorPosition],
        [dropDown, setDropDown],
        [requestBody, setRequestBody],
        [responseBody, setResponseBody],
        [settingsUniqueIdentifier, setSettingsUniqueIdentifier],
        [requestName, setRequestName],
        [headersUserText, headersSetUserText],
        [headersUrlText, headersSetUrlText],
        [headersTotalText, headersSetTotalText],
        [headersRowId, headersSetRowId],
        [headersCurrentCell, headersSetCurrentCell],
        [headersCursorPosition, headersSetCursorPosition],
        [headersDropDown, headersSetDropDown],
      ].forEach(element => {
        deleteRequestItem(n, element[0], element[1], false);
      });
      [
        [paramsState, setParamsState],
        [headersParamState, headersSetParamsState],
      ].forEach(element => {
        deleteRequestItem(n, element[0], element[1], false);
      });

      if (expandedState > n) {
        setExpandedState(expandedState - 1);
      }
    };

    const dispatch = useDispatch();
    const {
      paramsState: appParamsState,
      authenticationType,
      basicAuthUserName,
      basicAuthPassword,
      userText: appUserText,
      urlText: appUrlText,
    } = useContext(AppParamsContext);
    const { paramsState: appHeadersParamState } = useContext(AppHeadersContext);
    const [testLoadingState, setTestLoadingState] = useState(false);

    const testRequest = async (n: number) => {
      const requestTestData = {
        appUserText,
        appUrlText,
        authenticationType,
        basicAuthUserName,
        basicAuthPassword,
        appParamsState,
        appHeadersParamState,
        requestUserText: userText[n],
        requestUrlText: urlText[n],
        requestParamsState: paramsState[n],
        requestHeadersParamState: headersParamState[n],
        requestBody: requestBody[n],
        requestMethod: requestMethod[n],
      };
      setTestLoadingState(true);
      const data = (await dispatch(testCustomRequest(requestTestData))) as any;

      setTestLoadingState(false);
      if (data[0]) {
        testResult[n] = [true, true];
        setTestResult([...testResult]);
      } else {
        testResult[n] = [true, false];
        setTestResult([...testResult]);
      }

      jsonResponse[n] = data[1];
      setJsonResponse([...jsonResponse]);

      if (data[1]) {
        isJsonAvailable[n] = true;
        setIsJsonAvailable([...isJsonAvailable]);
      } else {
        isJsonAvailable[n] = false;
        setIsJsonAvailable([...isJsonAvailable]);
      }
    };

    useOutsideAlerter(requestFocusRef, setRequestInputFocus, setRequestInnerFocus);
    useOutsideAlerter(relativeFocusRef, setRelativeInputFocus, setRelativeInnerFocus);
    const { isOpen, onOpen, onClose } = useDisclosure();

    const JSONResponsePromptModal = (responseProps: any) => {
      const [isCopied, setIsCopied] = useState(false);

      async function copyTextToClipboard(text: any) {
        setIsCopied(true);
        if ('clipboard' in navigator) {
          return await navigator.clipboard.writeText(text);
        } else {
          return document.execCommand('copy', true, text);
        }
      }

      return (
        <Modal
          isOpen={isOpen}
          onClose={onClose}
          isCentered={true}
          size={'lg'}
          blockScrollOnMount={false}
        >
          <ModalOverlay />
          <ModalContent borderRadius="10px">
            <ModalHeader fontSize={'16px'} fontWeight={'500'} paddingLeft={'1rem'}>
              Response Body{' '}
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody paddingX={'1rem'}>
              <Box
                className=""
                borderStyle={'solid'}
                borderWidth={'1px'}
                borderLeftWidth={'1px'}
                marginTop={'-1rem'}
                borderRadius="4px"
              >
                <JSONInput
                  theme="light_mitsuketa_tribute"
                  placeholder={responseProps.responseBody}
                  locale={locale}
                  width="inherit"
                  height="27rem"
                  confirmGood={false}
                  style={{
                    body: {
                      fontSize: '11px',
                    },
                    labelColumn: {
                      paddingRight: '2.5rem',
                    },
                  }}
                  viewOnly={true}
                  colors={{
                    keys: '#AB1010',
                    default: '#6D7580',
                    number: '#36B37E',
                    string: '#026ae8CC',
                    primitive: '#211791',
                  }}
                  onChange={(e: any) => e}
                />
              </Box>
            </ModalBody>

            <ModalFooter paddingTop={'0.5rem'} paddingX={'1rem'}>
              <Button
                size={'md'}
                rightIcon="copy"
                variantColor="blue"
                borderRadius={'8px'}
                onClick={() => copyTextToClipboard(JSON.stringify(responseProps.responseBody))}
              >
                {isCopied ? 'Copied' : 'Copy'}
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      );
    };

    const closeHandler = (n: any) => {
      testResult[n] = [false, testResult[n][1]];
      setTestResult([...testResult]);
    };

    const { generatedURL, setGeneratedURL } = useContext(WebhooksParamsContext);
    const [submissionLoadingState, setSubmissionLoadingState] = useState(false);

    const nextClickHandler = async () => {
      setSubmissionLoadingState(true);

      if (!props.updateData) {
        const webhooksRandomUrls = (await dispatch(fetchCustomWebhooksUrl(requestArray))) as any;
        setGeneratedURL(webhooksRandomUrls);
      } else {
        if (requestArray.length > generatedURL.length) {
          const webhooksRandomUrls = (await dispatch(fetchCustomWebhooksUrl(requestArray))) as any;

          for (let index = generatedURL.length; index < requestArray.length; index++) {
            generatedURL[index] = webhooksRandomUrls[index];
          }

          setGeneratedURL([...generatedURL]);
        }
      }
      props.setSection(2);
    };

    return (
      <Box>
        {expandedState === innerProps?.item?.id ? (
          <Box mb={'2rem'}>
            <Box>
              <Box className="row">
                <Box
                  className={
                    requestInnerFocus
                      ? 'field custom-app-field custom-app--request-name'
                      : 'field custom-app-field'
                  }
                >
                  <Input
                    label="Request Name"
                    isInvalid={touched.name && !!errors.name && !nextPageState[0]}
                    mb="5px"
                    name="name"
                    value={requestName[innerProps?.item?.id]}
                    onChange={(e: any) => requestNameHandler(e)}
                    onBlur={handleBlur}
                    onReset={handleReset}
                    errorMessage={errors.name}
                    placeholder="Enter a name for your request."
                    onKeyDown={(e: any) => keyEventHandler(e, 0)}
                    autoComplete="off"
                  />
                </Box>
              </Box>

              <Grid templateColumns="repeat(1, 5fr 100fr 5fr)" gap={'0.5rem'} mt={6}>
                <Box className="field custom-app-field custom-app-field--request-method">
                  <FormControl>
                    <Select
                      color="black"
                      label="Request Method"
                      value={requestMethod[innerProps?.item?.id] || ''}
                      onChange={(e: any) => requestMethodHandler(e)}
                    >
                      {responseTypes.map((i: any) => (
                        <option key={i.id} value={i.id}>
                          {i.request_name}
                        </option>
                      ))}
                      ;
                    </Select>
                  </FormControl>
                </Box>

                <Box
                  className={
                    relativeInnerFocus
                      ? 'field custom-app-field custom-app--request-name'
                      : 'field custom-app-field'
                  }
                >
                  <Input
                    label="Request Relative URL"
                    isInvalid={touched.account_name && !!errors.account_name && !nextPageState[1]}
                    mb="5px"
                    name="account_name"
                    value={userText[innerProps?.item?.id] + urlText[innerProps?.item?.id]}
                    onChange={(e: any) => changeHandler(e, innerProps?.item?.id)}
                    onBlur={handleBlur}
                    onReset={handleReset}
                    errorMessage={errors.account_name}
                    placeholder="Enter the base URL for making requests to your app."
                    onKeyDown={(e: any) => keyEventHandler(e, 1)}
                    autoComplete="off"
                  />
                </Box>

                <Box className="custom-app-apps-button">
                  <Button
                    variant="solid"
                    variantColor="blue"
                    size="sm"
                    onClick={() => testRequest(innerProps?.item?.id)}
                    isLoading={testLoadingState}
                    isDisabled={
                      (!!Object.keys(errors).length || !Object.keys(touched).length) &&
                      !(
                        nextPageState[innerProps?.item?.id][0] === true &&
                        nextPageState[innerProps?.item?.id][1] === true
                      )
                    }
                    width="123px"
                    height="40px"
                    className="custom-app--submit-button"
                  >
                    Test Request
                  </Button>
                </Box>
              </Grid>
            </Box>
            {testResult[innerProps?.item?.id][0] === true &&
            testResult[innerProps?.item?.id][1] === true ? (
              <Box className="custom-app--request-test-success">
                <Grid templateColumns="repeat(1, 1fr 30fr 1fr)" gap={'0rem'} mt={0}>
                  <Box>
                    <Icon name="alert-success" size="32px" color="blue.500"></Icon>
                  </Box>
                  <Box pl={'0.75rem'}>
                    <Box className="custom-app--request-test-success-title">
                      Successful. We've tested your request. It works!
                    </Box>
                    {isJsonAvailable ? (
                      <Box className="custom-app--request-test-success-detail" mt={'0.2rem'}>
                        Click{' '}
                        <span onClick={onOpen} className="custom-app--request-test-success-expand">
                          here
                        </span>{' '}
                        to view your JSON response.
                        <JSONResponsePromptModal
                          responseBody={jsonResponse[innerProps?.item?.id]}
                        />
                      </Box>
                    ) : (
                      <Box className="custom-app--request-test-success-detail" mt={'0.2rem'}>
                        Your response does not return JSON.
                      </Box>
                    )}
                  </Box>
                  <Box paddingTop={'0.45rem'}>
                    <Icon
                      className="custom-app--request-test-close"
                      name="close"
                      size="14px"
                      color="grey"
                      onClick={() => closeHandler(innerProps?.item?.id)}
                    ></Icon>
                  </Box>
                </Grid>
              </Box>
            ) : testResult[innerProps?.item?.id][0] === true &&
              testResult[innerProps?.item?.id][1] === false ? (
              <Box className="custom-app--request-test-failure">
                <Grid templateColumns="repeat(1, 1fr 30fr 1fr)" gap={'0rem'} mt={0}>
                  <Box>
                    <Icon name="alert-error" size="32px" color="#DA1414"></Icon>
                  </Box>
                  <Box pl={'0.75rem'}>
                    <Box className="custom-app--request-test-failure-title">
                      Something went wrong with your request. The test was not successful.
                    </Box>
                    <Box className="custom-app--request-test-failure-detail" mt={'0.2rem'}>
                      Please, check your values.
                    </Box>
                  </Box>
                  <Box paddingTop={'0.45rem'}>
                    <Icon
                      className="custom-app--request-test-close"
                      name="close"
                      size="14px"
                      color="grey"
                      onClick={() => closeHandler(innerProps?.item?.id)}
                    ></Icon>
                  </Box>
                </Grid>
              </Box>
            ) : (
              <></>
            )}

            <Box mt={'1.5rem'}>
              <Titles
                titleHeadings={[
                  'Params',
                  'Headers',
                  'Pagination',
                  'Request Body',
                  'Settings',
                  'Expected Response',
                ]}
                selectedTitle={selectedTitle}
                setSelectedTitle={setSelectedTitle}
                disabledItem={responseBodyEnabled[innerProps?.item?.id] ? '' : 'Expected Response'}
                index={innerProps?.item?.id}
              />
            </Box>

            <Box className="row custom-app--section-outer" mt={'1rem'}>
              {selectedTitle[innerProps?.item?.id] === 'Params' && (
                <Params n={innerProps.item} requestArray={requestArray} type={'params'} />
              )}
              {selectedTitle[innerProps?.item?.id] === 'Headers' && (
                <Params n={innerProps.item} requestArray={requestArray} type={'headers'} />
              )}
              {selectedTitle[innerProps?.item?.id] === 'Pagination' && (
                <Params n={innerProps.item} requestArray={requestArray} type={'pagination'} />
              )}
              {selectedTitle[innerProps?.item?.id] === 'Request Body' && (
                <RequestBody n={innerProps.item} requestArray={requestArray} />
              )}
              {selectedTitle[innerProps?.item?.id] === 'Settings' && (
                <Settings n={innerProps.item} requestArray={requestArray} />
              )}
              {selectedTitle[innerProps?.item?.id] === 'Expected Response' && (
                <ResponseBody n={innerProps.item} requestArray={requestArray} />
              )}
            </Box>
          </Box>
        ) : (
          <Box className="custom-app--request-contracted" mb={'2rem'}>
            <Grid templateColumns="repeat(1, 20fr 1fr 1fr)" gap={'1rem'} mt={0}>
              <Box>{requestName[innerProps?.item?.id]}</Box>
              <Box
                className="custom-app--request-contracted-inner"
                onClick={() => setExpandedState(innerProps?.item?.id)}
              >
                Edit
              </Box>
              <Box
                className="custom-app--request-contracted-inner-red"
                onClick={() => deletionHandler(innerProps?.item?.id)}
              >
                Delete
              </Box>
            </Grid>
          </Box>
        )}

        {requestArray.length - 1 === innerProps?.item?.id && (
          <Grid
            templateColumns="repeat(1, 1000fr 1fr 1fr)"
            gap={'1.5rem'}
            mt={expandedState === innerProps?.item?.id ? '1rem' : '2rem'}
          >
            <Box></Box>
            <Box>
              <Button
                variant="solid"
                variantColor="blue"
                size="sm"
                onClick={() => createNewRequest(innerProps?.item?.id)}
                isLoading={false}
                isDisabled={
                  (!!Object.keys(errors).length || !Object.keys(touched).length) &&
                  !(
                    nextPageState[innerProps?.item?.id][0] === true &&
                    nextPageState[innerProps?.item?.id][1] === true
                  )
                }
                width="123px"
                height="42px"
                className="custom-app-apps-button-white"
                display={requestArray.length - 1 === innerProps?.item?.id ? 'Block' : 'None'}
              >
                New request
              </Button>
            </Box>

            <Box>
              <Button
                variant="solid"
                variantColor="blue"
                size="sm"
                onClick={() => nextClickHandler()}
                isLoading={submissionLoadingState}
                isDisabled={
                  (!!Object.keys(errors).length || !Object.keys(touched).length) &&
                  !(
                    nextPageState[innerProps?.item?.id][0] === true &&
                    nextPageState[innerProps?.item?.id][1] === true
                  )
                }
                width="123px"
                height="42px"
                display={requestArray.length - 1 === innerProps?.item?.id ? 'Block' : 'None'}
                className="custom-app--submit-button"
              >
                Next
              </Button>
            </Box>
          </Grid>
        )}
      </Box>
    );
  };

  return (
    <Box>
      {requestArray.map((item, i) => (
        <RequestComponent key={i} item={item} setSection={props.setSection} />
      ))}
    </Box>
  );
};

export default Requests;
