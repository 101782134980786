import { Box, Heading, Stack, Text } from '@chakra-ui/core';
import React from 'react';
import { connect, useSelector } from 'react-redux';
import { formatToCurrency } from 'utils';
import usePermission from 'utils/usePermission';
import { RootState } from '../../../../../root';
import { fetchWallet } from '../../../payments';
import { CreditTopupButton } from './credit-topup-button';

interface CreditTopCardUIProps {
  content: string;
  amount?: number;
  credit_balance: number;
  fetchWallet: (organization_id: any) => void;
}

function mapStateToProps(state: RootState) {
  return {
    credit_balance: state.payments.wallet.data.credit_balance,
  };
}

const mapDispatchToProps = {
  fetchWallet,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

const CreditTopupCardUI = ({ content, credit_balance }: CreditTopCardUIProps) => {
  const { profile } = useSelector((state: RootState) => state.auth);
  const permissions = profile && profile.permissions;
  const requiresPermission = usePermission('campaign.top-up', permissions || []);

  return (
    <>
      <Box p="0.75rem 1rem" bg="#e8f7ff" rounded="5px">
        <Stack pb="0.5rem">
          <Heading fontSize="0.875rem" size="sm" fontWeight={500}>
            Campaign Credit: {formatToCurrency(credit_balance)}
          </Heading>
          <Text fontSize="0.75rem">{content}</Text>
        </Stack>
        {requiresPermission && <CreditTopupButton />}
      </Box>
    </>
  );
};

export const CreditTopupCard = connector(CreditTopupCardUI);
