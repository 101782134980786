import styled from '@emotion/styled';

export const Wrapper = styled.div`
  height: 100%;
  padding: 1.5rem;

  #ListGrid {
    height: calc(100% - 6rem);

    * {
      border: none;
    }
  }

  .footing {
    height: 2rem;
  }
`;
