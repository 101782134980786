import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Badge,
  Box,
  CloseButton,
  Collapse,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Icon,
  IconButton,
  Link,
  PseudoBox,
  Stack,
  Text,
  Tooltip,
  useDisclosure,
  useToast,
} from '@chakra-ui/core';
import { selectColumnsData, selectListMeta } from 'app/authenticated-app/lists/lists.selectors';
import { Column } from 'app/authenticated-app/lists/lists.types';
import {
  CampaignLinkModal,
  CreditTopupCard,
  SendCampaignModal,
  addCampaign,
  getCampaignAudience,
} from 'app/authenticated-app/marketing/campaigns';
import { CampaignPayload } from 'app/authenticated-app/marketing/campaigns/campaigns.types';
import { AIDraftGeneratorModal } from 'app/authenticated-app/marketing/campaigns/components/planner/ai-draft-generator';
import { SmsCampaignFormContainer } from 'app/authenticated-app/marketing/campaigns/components/sms-campaigns/container';
import { shortenLink } from 'app/authenticated-app/marketing/templates';
import { Editable } from 'app/authenticated-app/marketing/templates/components/Editable';
import { html2Text } from 'app/authenticated-app/marketing/templates/templates.utils';
import { fetchWallet } from 'app/authenticated-app/payments';
import { selectCreditBalance, selectWalletData } from 'app/authenticated-app/payments/selectors';
import { listSMSSenderProfiles, saveSMSSenderProfile } from 'app/authenticated-app/settings';
import {
  SMSSenderProfileModal,
  SMSSenderProfilePayload,
} from 'app/authenticated-app/settings/component/sender-profiles/sms-sender-profile-modal';
import { selectOrganisation } from 'app/authenticated-app/settings/slices';
import { SelectOptions } from 'app/authenticated-app/tables';
import {
  AdvancedSelect,
  Button,
  EmptyState,
  Input,
  Menu,
  MenuItem,
  Select,
  SmallText,
  ToastBox,
  XSmallText,
} from 'app/components';
import { selectOrganisationID, selectProfile } from 'app/unauthenticated-app/authentication';
import { AxiosError } from 'axios';
import { FormikHelpers, useFormik } from 'formik';
import { useLoading } from 'hooks';
import React, { useEffect, useRef, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { RootState } from 'root';
import {
  contentHasURL,
  contentWithoutURL,
  formatCurrency,
  stringSplice,
  textNodesUnder,
} from 'utils/functions';
import { track } from 'utils/segment';
import * as yup from 'yup';
import { ConfirmationAlert } from './confirmation-alert';
import { LimitExceededAlert } from './limit-exceeded-alert';

export const AirtimeTopup = (props: {
  is_smart_list?: boolean;
  setActiveSection: React.Dispatch<
    React.SetStateAction<'enrichment' | 'automation' | 'airtime-topup' | 'duplicate-check' | null>
  >;
}) => {
  const { setActiveSection, is_smart_list } = props;

  const editableRef = useRef<any>();
  const dispatch = useDispatch();
  const { params } = useRouteMatch<{ id: string }>();

  const meta = useSelector(selectListMeta);
  const profile = useSelector(selectProfile);
  const wallet = useSelector(selectWalletData);
  const columnsState = useSelector(selectColumnsData);
  const creditBalance = useSelector(selectCreditBalance);
  const organisation = useSelector((state: RootState) =>
    selectOrganisation(state, profile?.organisation_id ?? ''),
  );
  const organisation_id = useSelector(selectOrganisationID);

  const [smsCount, setSmsCount] = useState(0);
  const [isSaving, setIsSaving] = useState(false);
  const [characterCount, setCharacterCount] = useState(0);
  const [hasAmountColumn, setHasAmountColumn] = useState(false);
  const [isNotRegularText, setIsNotRegularText] = useState(false);
  const [campaignPayload, setCampaignPayload] = useState<CampaignPayload | undefined>();

  const toast = useToast();
  const queryClient = useQueryClient();
  const {
    isOpen: isAIContentGenerationModalOpen,
    onOpen: onOpenAIContentGenerationModal,
    onClose: onCloseAIContentGenerationModal,
  } = useDisclosure();
  const {
    isOpen: isSMSSenderProfileModalOpen,
    onOpen: onOpenSMSSenderProfileModal,
    onClose: onCloseSMSSenderProfileModal,
  } = useDisclosure();
  const {
    isOpen: campaignLinkModalIsOpen,
    onOpen: onOpenCampaignLinkModal,
    onClose: onCloseCampaignLinkModal,
  } = useDisclosure();
  const {
    isOpen: sendCampaignIsOpen,
    onOpen: onOpenSendCampaignModal,
    onClose: onCloseSendCampaignModal,
  } = useDisclosure();
  const { isOpen: isPastTime, onClose: onCloseIsPastTimeBanner } = useDisclosure(
    new Date().getHours() >= 20,
  );
  const {
    isOpen: isLimitExceededAlertOpen,
    onOpen: onOpenLimitExceededAlert,
    onClose: onCloseLimitExceededAlert,
  } = useDisclosure();
  const {
    isOpen: isConfirmationAlertOpen,
    onOpen: onOpenConfirmationAlert,
    onClose: onCloseConfirmationAlert,
  } = useDisclosure();

  const { dispatch: loadingDispatch, loading } = useLoading();

  const { data: sender_ids } = useQuery(['sms-sender-profiles'], listSMSSenderProfiles, {
    initialData: organisation?.sender_ids ?? [],
  });

  const { mutate: createSMSSenderProfileMutate, isLoading: isCreatingSMSSenderProfile } =
    useMutation<any, AxiosError, any, any>(
      (options: { payload: SMSSenderProfilePayload }) => saveSMSSenderProfile(options.payload),
      {
        onSuccess: (data, variables) => {
          const { helpers } = variables;
          queryClient.invalidateQueries(['sms-sender-profiles']);
          helpers.resetForm();
          onCloseSMSSenderProfileModal();
          setFieldValue?.('sender_id', data.name);
          toast({
            position: 'bottom-left',
            render: ({ onClose }) => (
              <ToastBox
                status="success"
                onClose={onClose}
                message="SMS sender profile added successfully"
              />
            ),
          });
        },
        onError: error => {
          toast({
            position: 'bottom-left',
            render: ({ onClose }) => <ToastBox onClose={onClose} message={error.message} />,
          });
        },
      },
    );

  const handleCreateCampaign = async (values: Partial<CampaignPayload>) => {
    try {
      const payload: Partial<CampaignPayload> = {
        type: 'airtime',
        state: 'started',
        audience_type: 2,
        name: `Airtime reward broadcast`,
        table_id: !is_smart_list ? params.id : undefined,
        smart_list_id: is_smart_list ? params.id : undefined,
        timezone:
          typeof window.Intl === undefined
            ? 'Africa/Lagos'
            : window.Intl.DateTimeFormat().resolvedOptions().timeZone,
        ...values,
      };
      setIsSaving(true);
      dispatch(addCampaign(payload));
      setIsSaving(false);
      resetForm();
      setActiveSection(null);
      setCampaignPayload(undefined);
      toast({
        position: 'bottom-left',
        render: ({ onClose }) => (
          <ToastBox status="success" onClose={onClose} message="Airtime reward sent successfully" />
        ),
      });
      organisation_id && dispatch(fetchWallet(organisation_id));
      track('SMS Campaign Created', payload);
    } catch (error: any) {
      setIsSaving(false);
      toast({
        position: 'bottom-left',
        render: ({ onClose }) => <ToastBox onClose={onClose} message={error} />,
      });
    }
  };

  const handleSubmit = () => {
    if (totalAirtimeCost > 1000000) {
      track('Airtime Amount Limit Exceeded');
      onOpenLimitExceededAlert();
    } else {
      onOpenSendCampaignModal();
    }
  };

  const { values, touched, errors, resetForm, submitForm, handleChange, setFieldValue } = useFormik<
    Partial<CampaignPayload>
  >({
    onSubmit: handleSubmit,
    initialValues: {
      content: '',
      sender_id: '',
      airtime_amount: undefined,
      airtime_amount_column: undefined,
    },
    validationSchema: yup.object().shape({
      airtime_amount: yup
        .number()
        // .when('airtime_amount_column', {
        //   is: (value: number) => !!value,
        //   then: yup.number(),
        //   otherwise: yup.number().required('Amount is required'),
        // })
        .min(20, 'Minimum amount is 20 naira')
        .required('Airtime amount is required'),
      sender_id: yup.string().required('Sender ID is required'),
      content: yup.string().required('Message is required').nullable(),
    }),
  });
  // @ts-ignore
  const columns: Column[] = columnsState.columns_by_id.map((i: string) => columnsState.columns[i]);

  const tags = columns.map(({ name, label }) => ({
    label,
    value: name,
  }));

  const totalAirtimeCost = (values.airtime_amount ?? 0) * meta.count_total;
  const totalSMSCost = (wallet.pricing['breakdown']['NG']['sms.non-dnd'] / 100) * meta.count_total;
  const totalCost = totalAirtimeCost + totalSMSCost;
  const amountColumns = columns.filter((i: Column) => i.type === 'NUMBER');

  const getContent = (value?: null | string): string => {
    if (value) {
      return value;
    }
    return '';
  };

  const findAtSigns = (text: string) => {
    return text.match(/@/g) || [];
  };

  function getCharacterCount(value: string) {
    const content = html2Text(value);
    const characterCount = content.length;
    const isNotRegularText = !!findAtSigns(value).length;
    const characterDivisor = isNotRegularText ? 60 : 160;
    const smsCount = Math.ceil(characterCount / characterDivisor);

    setCharacterCount(characterCount);
    setSmsCount(smsCount);
    setIsNotRegularText(isNotRegularText);
  }

  const handleEditableChange = (value: string) => {
    getCharacterCount(value);
    setFieldValue('content', value);
  };

  const handleEditableBlur = () => {
    const value = editableRef?.current?.innerHTML;
    getCharacterCount(value);
    setFieldValue('content', value);
  };

  async function handleShortenLink({ link, analytics }: any, formikHelpers: FormikHelpers<any>) {
    try {
      loadingDispatch({ type: 'LOADING_STARTED' });
      const response = await shortenLink({ link });
      const { short: shortenedLink } = response.data.link.link;
      let content = '';

      loadingDispatch({ type: 'LOADING_RESOLVED' });
      onCloseCampaignLinkModal();
      formikHelpers.resetForm();
      if (values.content) {
        if (contentHasURL(getContent(values.content))) {
          content = `${contentWithoutURL(getContent(values.content))}\n${shortenedLink}`;
        } else {
          content = `${values.content}\n${shortenedLink}`;
        }
      } else {
        content = shortenedLink;
      }
      setFieldValue('link', link);
      setFieldValue('analytics', analytics);
      handleEditableChange(content);
    } catch (error: any) {
      loadingDispatch({ type: 'LOADING_RESOLVED' });
      toast({
        position: 'bottom-left',
        render: ({ onClose }) => <ToastBox onClose={onClose} message={error} />,
      });
    }
  }

  const getCaretPosition = () => {
    var index = 0;
    var selection = window.getSelection();
    var textNodes = textNodesUnder(editableRef.current);

    for (var i = 0; i < textNodes.length; i++) {
      var node = textNodes[i];
      var isSelectedNode = node === selection?.focusNode;

      if (isSelectedNode) {
        index += selection?.focusOffset ?? 0;
        break;
      } else {
        index += node.textContent.length;
      }
    }

    return index;
  };

  useEffect(() => {
    const handleSetListCount = async (payload: {
      smart_list_id?: string | null;
      table_id?: string | null;
      group_id?: string | null;
      segment_id?: string | null;
      audience_id?: string | null;
      audience_type: number;
    }) => {
      try {
        const { audience } = await getCampaignAudience(payload);

        setFieldValue('count', audience.count);
      } catch (error: any) {
        toast({
          position: 'bottom-left',
          render: ({ onClose }) => <ToastBox onClose={onClose} message={error} />,
        });
      }
    };

    handleSetListCount({
      audience_type: 2,
      table_id: !is_smart_list ? params.id : null,
      smart_list_id: is_smart_list ? params.id : null,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box width="100%" bg="#FFFFFF" rounded="6px" padding="0.5rem 1.5rem" border="1px solid #DADEE3">
      <Flex
        mb="1rem"
        pb="1rem"
        pt="0.2rem"
        width="100%"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        borderBottom="1px solid #EBEEF2"
      >
        <Stack isInline alignItems="center">
          <IconButton
            isRound
            size="xs"
            mr="1rem"
            variant="ghost"
            fontSize="18px"
            aria-label="button"
            icon="chevron-left"
            onClick={() => setActiveSection(null)}
          />
          <Box fontWeight="500">Airtime Rewards</Box>
          <Badge variantColor="green">Beta</Badge>
        </Stack>
      </Flex>
      {isPastTime && (
        <Alert mb="1.5rem" status="info" rounded="4px">
          <AlertIcon />
          <AlertDescription fontSize="0.875rem">
            Campaign sent after 8PM WAT through promotional routes will be rescheduled to go out by
            8AM the following day, as per the NCC restriction.{' '}
            <Link
              pl="0.2rem"
              color="blue.500"
              fontSize="0.875rem"
              target="_blank"
              href="https://support.simpu.co/en/articles/6829317-sms-campaign-exceptions"
            >
              Learn more
            </Link>
          </AlertDescription>

          <CloseButton
            size="sm"
            top="8px"
            right="8px"
            position="absolute"
            onClick={onCloseIsPastTimeBanner}
          />
        </Alert>
      )}
      {isNotRegularText && (
        <Alert mb="1.5rem" status="info" rounded="4px">
          <AlertIcon />
          <AlertTitle mr={2}>Info</AlertTitle>
          <AlertDescription fontSize="0.875rem">
            Content with emojis and the @ sign will attract extra charges
          </AlertDescription>
          <Link
            pl="0.2rem"
            color="blue.500"
            fontSize="0.875rem"
            target="_blank"
            href="https://support.simpu.co/en/articles/6829317-sms-campaign-exceptions"
          >
            Learn more
          </Link>
          <CloseButton
            top="8px"
            size="sm"
            right="8px"
            position="absolute"
            onClick={() => setIsNotRegularText(false)}
          />
        </Alert>
      )}
      <SmsCampaignFormContainer>
        <form>
          <Stack spacing="1.5rem">
            <Box>
              <FormControl isInvalid={!!touched.sender_id && !!errors.sender_id}>
                <AdvancedSelect
                  size="sm"
                  options={sender_ids?.map((item: any) => ({
                    value: item.name,
                    label: item.name,
                  }))}
                  value={values.sender_id}
                  placeholder="Give your campaign a sender ID"
                  isInvalid={!!touched.sender_id && !!errors.sender_id}
                  onChange={({ value }) => setFieldValue('sender_id', value)}
                  label={
                    <Stack isInline alignItems="center">
                      <Text>Sender ID</Text>
                      <Tooltip
                        zIndex={10000}
                        placement="right"
                        aria-label="Campaign sender id"
                        label="A Sender ID is a quick way to let recipients of your campaign know exactly who it is from."
                      >
                        <Icon size="0.75rem" name="info" color="gray.500" />
                      </Tooltip>
                    </Stack>
                  } //@ts-ignore
                  noOptionsMessage={() => (
                    <EmptyState
                      width="320px"
                      heading="Add an SMS sender ID"
                      subheading="Standout in your customer's inbox with unique sender ID for your organisation."
                    >
                      <Button size="sm" variantColor="blue" onClick={onOpenSMSSenderProfileModal}>
                        Add SMS Sender ID
                      </Button>
                    </EmptyState>
                  )}
                />
                <FormErrorMessage>{errors.sender_id}</FormErrorMessage>
              </FormControl>
            </Box>
            <Collapse isOpen={hasAmountColumn}>
              <Box>
                <Select
                  size="sm"
                  label="Amount Column"
                  onChange={handleChange}
                  name="airtime_amount_column"
                  value={values.airtime_amount_column}
                  placeholder="Select an amount column"
                  errorMessage={errors.airtime_amount_column}
                  isInvalid={!!touched.airtime_amount_column && !!errors.airtime_amount_column}
                >
                  {amountColumns.map((i: Column) => (
                    <option key={i.id} value={i.id}>
                      {i.label}
                    </option>
                  ))}
                </Select>
              </Box>
            </Collapse>
            <Collapse isOpen={!hasAmountColumn}>
              <Box>
                <Input
                  size="sm"
                  name="airtime_amount"
                  label="Amount to topup"
                  onChange={handleChange}
                  value={values.airtime_amount}
                  errorMessage={errors.airtime_amount}
                  placeholder="Enter amount to topup here"
                  isInvalid={!!touched.airtime_amount && !!errors.airtime_amount}
                />
              </Box>
            </Collapse>
            {/* <Box>
              <Checkbox
                size="sm"
                isChecked={hasAmountColumn}
                onChange={e => setHasAmountColumn(e.target.checked)}
              >
                Check the box if the list has an amount column.
              </Checkbox>
            </Box> */}
            <Box>
              <FormControl isInvalid={!!touched.content && !!errors.content}>
                <FormLabel fontSize="0.875rem" fontWeight="400">
                  Message to be sent via SMS to the recipient after a successful top-up
                </FormLabel>
                <Box
                  p="0.5rem"
                  borderWidth="1px"
                  borderRadius="8px"
                  borderColor={!!touched.content && !!errors.content ? '#e53e3e' : '#e2e8f0'}
                  boxShadow={!!touched.content && !!errors.content ? '0 0 0 1px #e53e3e' : 'none'}
                >
                  <Flex flexDirection="column">
                    <Flex justifyContent="flex-end">
                      <Menu
                        options={tags ?? []}
                        menuListProps={{
                          minW: '80px',
                          width: 'auto',
                          height: '150px',
                          overflowY: 'auto',
                          placement: 'auto-end',
                        }}
                        menuButtonProps={{
                          px: '0',
                          minW: '0',
                          //@ts-ignore
                          type: 'button',
                          children: (
                            <Tooltip
                              label="Tags"
                              placement="top"
                              aria-label="Tags"
                              shouldWrapChildren
                            >
                              <Button
                                size="xs"
                                type="button"
                                variant="ghost"
                                color="gray.500"
                                isDisabled={!tags?.length}
                                //@ts-ignore
                                leftIcon="marketing-campaign-design-tags"
                              >
                                Personalize
                              </Button>
                            </Tooltip>
                          ),
                        }}
                        renderItem={({ label, value }: SelectOptions) => (
                          <MenuItem
                            //@ts-ignore
                            type="button"
                            fontSize="0.875rem"
                            onClick={() => {
                              const caretPosition = getCaretPosition();
                              const string = stringSplice(
                                html2Text(values.content ?? ''),
                                caretPosition,
                                ` {{${value}}} `,
                              );
                              handleEditableChange(string);
                            }}
                          >
                            <XSmallText>{label}</XSmallText>
                          </MenuItem>
                        )}
                      />
                    </Flex>
                    <Box pb="0.5rem" flex={1}>
                      <Editable
                        innerRef={editableRef}
                        onBlur={handleEditableBlur}
                        onChange={handleEditableChange}
                        placeholder="Write SMS content here..."
                        value={getContent(values.content)}
                      />
                    </Box>
                    <Flex alignItems="center" justifyContent="flex-end">
                      <PseudoBox as="span" fontSize="0.7rem">
                        {characterCount}
                      </PseudoBox>
                      <Divider mx="0.4rem" height="0.75rem" orientation="vertical" />
                      <PseudoBox as="span" fontSize="0.7rem">
                        {smsCount} SMS
                      </PseudoBox>
                    </Flex>
                  </Flex>
                  <Stack isInline alignItems="center" pt="0.5rem" borderTopWidth="1px">
                    <Box>
                      <Tooltip
                        placement="top"
                        aria-label="add"
                        label="AI generated content for your SMS campaign."
                      >
                        <Button
                          size="xs"
                          zIndex={0}
                          rounded="8px"
                          color="white"
                          //@ts-ignore
                          leftIcon="magic-wand"
                          variant="outline"
                          position="relative"
                          className="ai-button"
                          aria-label="AI generated content"
                          onClick={onOpenAIContentGenerationModal}
                        >
                          Generate text with AI
                        </Button>
                      </Tooltip>
                    </Box>
                    <Box>
                      <Tooltip
                        placement="top"
                        aria-label="add"
                        label="Add a shortened link to track conversion"
                      >
                        <IconButton
                          size="xs"
                          rounded="8px"
                          icon="link"
                          aria-label="link"
                          variant="outline"
                          variantColor="blue"
                          onClick={onOpenCampaignLinkModal}
                        />
                      </Tooltip>
                    </Box>
                  </Stack>
                </Box>
                <FormErrorMessage>{errors.content}</FormErrorMessage>
              </FormControl>
            </Box>
            <Stack>
              <SmallText color="gray.500">
                NB: Charges for this service would be billed from your available campaign credits.
              </SmallText>

              {!hasAmountColumn && totalAirtimeCost > 0 && (
                <Stack>
                  <SmallText color="gray.500">
                    Estimated airtime reward cost:
                    <span
                      style={{
                        fontWeight: 'bold',
                        marginLeft: '5px',
                        color: '#333333',
                      }}
                    >
                      NGN {formatCurrency(totalAirtimeCost)}
                    </span>
                  </SmallText>
                  <SmallText color="gray.500">
                    Estimated SMS cost:
                    <span
                      style={{
                        fontWeight: 'bold',
                        marginLeft: '5px',
                        color: '#333333',
                      }}
                    >
                      NGN {formatCurrency(totalSMSCost)}
                    </span>
                  </SmallText>
                  <SmallText color="gray.500">
                    Total cost:
                    <span
                      style={{
                        fontWeight: 'bold',
                        marginLeft: '5px',
                        color: '#333333',
                      }}
                    >
                      NGN {formatCurrency(totalCost)}
                    </span>
                  </SmallText>
                </Stack>
              )}
            </Stack>

            {!hasAmountColumn && totalAirtimeCost > creditBalance / 100 ? (
              <Box>
                <Alert
                  status="error"
                  borderRadius="10px"
                  textAlign="left"
                  fontSize="12px"
                  marginBottom="1rem"
                >
                  <AlertIcon />
                  Looks like you don't have enough credits in your balance to carry out airtime
                  topup on this list
                </Alert>
                <CreditTopupCard content="This is the credit you have for sending out campaign messages and airtime topup" />
              </Box>
            ) : (
              <Stack spacing="1.5rem">
                <Stack isInline alignItems="center">
                  <Button
                    size="sm"
                    type="button"
                    fontWeight="500"
                    onClick={() => props.setActiveSection(null)}
                  >
                    Back
                  </Button>
                  <Button
                    size="sm"
                    type="button"
                    fontWeight="500"
                    variantColor="blue"
                    onClick={submitForm}
                  >
                    Send Now or Schedule
                  </Button>
                </Stack>
              </Stack>
            )}
          </Stack>
        </form>
      </SmsCampaignFormContainer>
      {isAIContentGenerationModalOpen && (
        <AIDraftGeneratorModal
          isOpen={isAIContentGenerationModalOpen}
          onClose={onCloseAIContentGenerationModal}
          onUseGeneratedText={content => {
            setFieldValue('content', content);
          }}
        />
      )}
      {isSMSSenderProfileModalOpen && (
        <SMSSenderProfileModal
          isOpen={isSMSSenderProfileModalOpen}
          onClose={onCloseSMSSenderProfileModal}
          isLoading={isCreatingSMSSenderProfile}
          onSubmit={(payload, helpers) => createSMSSenderProfileMutate({ payload, helpers })}
        />
      )}
      {campaignLinkModalIsOpen && (
        <CampaignLinkModal
          onSubmit={handleShortenLink}
          initialValues={{ link: '' }}
          isOpen={campaignLinkModalIsOpen}
          isLoading={loading === 'pending'}
          onClose={onCloseCampaignLinkModal}
        />
      )}
      {sendCampaignIsOpen && (
        <SendCampaignModal
          type="airtime"
          campaign={values}
          isLoading={isSaving}
          isOpen={sendCampaignIsOpen}
          credit_balance={creditBalance}
          onClose={onCloseSendCampaignModal}
          onCreateCampaign={payload => {
            setCampaignPayload(payload);
            onCloseSendCampaignModal();
            onOpenConfirmationAlert();
          }}
        />
      )}
      {isConfirmationAlertOpen && (
        <ConfirmationAlert
          isLoading={isSaving}
          isOpen={isConfirmationAlertOpen}
          onClose={onCloseConfirmationAlert}
          onConfirm={() => campaignPayload && handleCreateCampaign(campaignPayload)}
        />
      )}
      {isLimitExceededAlertOpen && (
        <LimitExceededAlert isOpen={isLimitExceededAlertOpen} onClose={onCloseLimitExceededAlert} />
      )}
    </Box>
  );
};
