import {
  Flex,
  Icon,
  ModalBody,
  ModalCloseButton,
  PseudoBox,
  PseudoBoxProps,
} from '@chakra-ui/core';
import { BodyText, ModalContainer, ModalContainerOptions, SmallSubtitle } from 'app/components';
import React from 'react';

export const CreditTopupOptionsModal = (
  props: ModalContainerOptions & { onOpenCreditCardModal(): void; onOpenBankDetailsModal(): void },
) => {
  const { isOpen, onClose, onOpenCreditCardModal, onOpenBankDetailsModal } = props;

  return (
    <ModalContainer size="md" isOpen={isOpen} onClose={onClose} title="Topup Your Campaign Credit">
      <ModalCloseButton size="sm" />
      <ModalBody pb="4rem">
        <BodyText pb="1rem" fontWeight="500" color="gray.500">
          Select your payment option
        </BodyText>
        <Flex alignItems="center" justifyContent="space-between">
          <Option
            width="48%"
            as="button"
            onClick={() => {
              onClose?.();
              onOpenBankDetailsModal();
            }}
          >
            <Icon pb="1rem" size="3rem" name="bank" color="inherit" />
            <SmallSubtitle fontWeight="inherit" color="inherit" textAlign="center">
              Bank Transfer
            </SmallSubtitle>
          </Option>
          <Option
            width="48%"
            as="button"
            onClick={() => {
              onClose?.();
              onOpenCreditCardModal();
            }}
          >
            <Icon pb="1rem" size="3rem" name="credit-card" color="inherit" />
            <SmallSubtitle fontWeight="inherit" color="inherit" textAlign="center">
              Card Payment
            </SmallSubtitle>
          </Option>
        </Flex>
      </ModalBody>
    </ModalContainer>
  );
};

const Option = (props: PseudoBoxProps) => {
  return (
    <PseudoBox
      p="1rem"
      rounded="8px"
      display="flex"
      bg="transparent"
      color="gray.500"
      fontWeight="500"
      borderWidth="1px"
      alignItems="center"
      flexDirection="column"
      borderColor="gray.200"
      justifyContent="center"
      transition="all 0.2s ease-in"
      _hover={{ borderColor: 'blue.500', color: 'blue.500' }}
      {...props}
    />
  );
};
