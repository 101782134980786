import { Box, Grid, Icon } from '@chakra-ui/core';
import { Button, useResellerSettings } from 'app/components';
import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  RequestArrayProps,
  WebhooksParamsContext,
} from '../../contexts/webhooks-contexts/params.context';
import { WebhooksHeadersContext } from '../../contexts/webhooks-contexts/headers.context';
import { useDispatch } from 'react-redux';
import { createCustomApp, updateCustomApp } from 'app/authenticated-app/lists/thunks/resources';
import { AppHeadersContext } from '../../contexts/app-contexts/headers.context';
import { AppPaginationContext } from '../../contexts/app-contexts/pagination.context';
import { RequestParamsContext } from '../../contexts/request-contexts/params.context';
import { RequestHeadersContext } from '../../contexts/request-contexts/headers.context';
import { AppParamsContext } from '../../contexts/app-contexts/params.context';
import { useHistory } from 'react-router-dom';
import { buildAppsURL } from 'utils';
import { RequestPaginationContext } from '../../contexts/request-contexts/pagination.context';

interface Props {
  setSection: Function;
  refreshCustomApps: Function;
  updateData?: boolean;
  appId?: string;
}

const Webhooks = (props: Props) => {
  const routerHistory = useHistory();
  const navCreateApp = () => routerHistory.push('/s/lists/custom-apps');
  const [submissionLoadingState, setSubmissionLoadingState] = useState(false);
  const timerRef = useRef(null as any);

  // New Params
  const {
    urlText,
    setRowId,
    rowId,
    userText,
    setUserText,
    paramsState,
    setParamsState,
    requestArray,
    setRequestArray,
    currentCell,
    cursorPosition,
    totalText,
    dropDown,
    setUrlText,
    setDropDown,
    setCurrentCell,
    setCursorPosition,
    setTotalText,
    requestName,
    requestInputFocus,
    setRequestInputFocus,
    relativeInputFocus,
    setRelativeInputFocus,
    requestCursorPosition,
    relativeCursorPosition,
    expandedState,
    setExpandedState,
    requestFocusRef,
    relativeFocusRef,
    requestMethod,
    setRequestMethod,
    requestInnerFocus,
    setRequestInnerFocus,
    relativeInnerFocus,
    setRelativeInnerFocus,
    responseBody,
    setResponseBody,
    requestBody,
    setRequestBody,
    settingsUniqueIdentifier,
    setSettingsUniqueIdentifier,
    requestNameSelection,
    generatedURL,
  } = useContext(WebhooksParamsContext);

  const {
    requestName: requestNameRequests,
    userText: userTextRequests,
    requestArray: requestArrayRequests,
  } = useContext(RequestParamsContext);

  const createNewParams = (item: number) => {
    setExpandedState(item + 1);
    paramsState[item + 1] = [
      {
        id: 0,
        isWritten: false,
        isDisabled: false,
        key_text: '',
        value_text: '',
        description_text: '',
      },
    ];
    userText[item + 1] = '';
    urlText[item + 1] = '';
    totalText[item + 1] = '';
    rowId[item + 1] = 0;
    currentCell[item + 1] = [0, 0, ''];
    cursorPosition[item + 1] = [0, 0];
    dropDown[item + 1] = [false, 0, 0];
    requestArray[item + 1] = { id: item + 1, current: false };
    requestBody[item + 1] = null;
    responseBody[item + 1] = null;
    settingsUniqueIdentifier[item + 1] = '';
    requestMethod[item + 1] = 'get';
    setUserText([...userText]);
    setUrlText([...urlText]);
    setTotalText([...totalText]);
    setRowId([...rowId]);
    setCurrentCell([...currentCell]);
    setCursorPosition([...cursorPosition]);
    setDropDown([...dropDown]);
    setParamsState([...paramsState]);
    setRequestArray([...requestArray]);
    setRequestBody([...requestBody]);
    setResponseBody([...responseBody]);
    setSettingsUniqueIdentifier([...settingsUniqueIdentifier]);
    setRequestMethod([...requestMethod]);
  };

  // New Header
  const {
    urlText: headersUrlText,
    setRowId: headersSetRowId,
    rowId: headersRowId,
    userText: headersUserText,
    setUserText: headersSetUserText,
    paramsState: headersParamState,
    setParamsState: headersSetParamsState,
    requestArray: headersRequestArray,
    setRequestArray: headersSetRequestArray,
    currentCell: headersCurrentCell,
    cursorPosition: headersCursorPosition,
    totalText: headersTotalText,
    dropDown: headersDropDown,
    setUrlText: headersSetUrlText,
    setDropDown: headersSetDropDown,
    setCurrentCell: headersSetCurrentCell,
    setCursorPosition: headersSetCursorPosition,
    setTotalText: headersSetTotalText,
    setExpandedState: headersSetExpandedState,
  } = useContext(WebhooksHeadersContext);

  const createNewHeaders = (item: number) => {
    headersSetExpandedState(item + 1);
    headersParamState[item + 1] = [
      {
        id: 0,
        isWritten: false,
        isDisabled: false,
        key_text: '',
        value_text: '',
        description_text: '',
      },
    ];
    headersUserText[item + 1] = '';
    headersUrlText[item + 1] = '';
    headersTotalText[item + 1] = '';
    headersRowId[item + 1] = 0;
    headersCurrentCell[item + 1] = [0, 0, ''];
    headersCursorPosition[item + 1] = [0, 0];
    headersDropDown[item + 1] = [false, 0, 0];
    headersSetUserText([...headersUserText]);
    headersSetUrlText([...headersUrlText]);
    headersSetTotalText([...headersTotalText]);
    headersSetRowId([...headersRowId]);
    headersSetCurrentCell([...headersCurrentCell]);
    headersSetCursorPosition([...headersCursorPosition]);
    headersSetDropDown([...headersDropDown]);
    headersSetParamsState([...headersParamState]);
    headersRequestArray[item + 1] = { id: item + 1, current: false };
    headersSetRequestArray([...headersRequestArray]);
  };

  const createNewRequest = (item: number) => {
    createNewParams(item);
    createNewHeaders(item);
  };

  // Submission handling
  const dispatch = useDispatch();
  const appParams = useContext(AppParamsContext);
  const appHeaders = useContext(AppHeadersContext);
  const appPagination = useContext(AppPaginationContext);
  const appData = { app_params: appParams, app_headers: appHeaders, app_pagination: appPagination };

  const requestsParams = useContext(RequestParamsContext);
  const requestsHeaders = useContext(RequestHeadersContext);
  const requestsPagination = useContext(RequestPaginationContext);
  const requestsData = {
    requests_params: requestsParams,
    requests_headers: requestsHeaders,
    requests_pagination: requestsPagination,
  };

  const webhooksData = {
    webhooks_params: {
      urlText,
      rowId,
      userText,
      paramsState,
      requestArray,
      currentCell,
      cursorPosition,
      totalText,
      dropDown,
      setDropDown,
      requestName,
      requestInputFocus,
      relativeInputFocus,
      requestCursorPosition,
      relativeCursorPosition,
      expandedState,
      requestMethod,
      requestInnerFocus,
      relativeInnerFocus,
      responseBody,
      requestBody,
      settingsUniqueIdentifier,
      requestNameSelection,
      generatedURL,
    },
    webhooks_headers: {
      urlText: headersUrlText,
      rowId: headersRowId,
      userText: headersUserText,
      paramsState: headersParamState,
      requestArray: headersRequestArray,
      currentCell: headersCurrentCell,
      cursorPosition: headersCursorPosition,
      totalText: headersTotalText,
      dropDown: headersDropDown,
      setDropDown: headersSetDropDown,
    },
  };

  const submitCustomAppData = async () => {
    setSubmissionLoadingState(true);
    if (props.updateData && props.appId) {
      const data = (await dispatch(
        updateCustomApp(appData, requestsData, webhooksData, props.appId),
      )) as any;
      data && navCreateApp();
      props.refreshCustomApps();
    } else {
      const data = (await dispatch(createCustomApp(appData, requestsData, webhooksData))) as any;
      data && navCreateApp();
      props.refreshCustomApps();
    }
  };

  interface RequestComponentProps {
    item: RequestArrayProps;
    setSection: Function;
  }

  const RequestComponent = (innerProps: RequestComponentProps) => {
    function useOutsideAlerter(ref: any, focusFunction: any, innerFunction: any) {
      useEffect(() => {
        function handleClickOutside(e: any) {
          if (ref.current && !ref.current.contains(e.target)) {
            focusFunction(false);
            innerFunction(false);
          } else if (ref.current && ref.current.contains(e.target)) return;
        }
        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
          // Unbind the event listener on clean up
          document.removeEventListener('mousedown', handleClickOutside);
        };
      }, [ref, focusFunction, innerFunction]);
    }

    useOutsideAlerter(requestFocusRef, setRequestInputFocus, setRequestInnerFocus);
    useOutsideAlerter(relativeFocusRef, setRelativeInputFocus, setRelativeInnerFocus);

    const [isCopied, setIsCopied] = useState(-1);
    const copyTextToClipboard = async (text: any) => {
      setIsCopied(innerProps.item.id);

      timerRef.current = setTimeout(() => {
        setIsCopied(-1);
      }, 2000);

      if ('clipboard' in navigator) {
        return await navigator.clipboard.writeText(text);
      } else {
        return document.execCommand('copy', true, text);
      }
    };
    useEffect(() => {
      return () => clearTimeout(timerRef.current);
    }, []);

    return (
      <Box>
        <Box
          className="custom-app-webhooks-card"
          padding={'1rem'}
          paddingTop={'0.7rem'}
          paddingBottom={'2rem'}
          marginBottom={'1.5rem'}
        >
          <Grid templateColumns="repeat(1, 1fr 100fr 1fr)" gap={'1rem'} marginBottom={'0.6rem'}>
            <Box fontWeight={'400'} fontSize={'1rem'} whiteSpace="nowrap">
              {requestNameRequests[innerProps?.item?.id]}
            </Box>
            <Box
              fontWeight={'300'}
              fontSize={'0.85rem'}
              paddingTop={'0.15rem'}
              className="custom-app--webhooks-light"
            >
              {userTextRequests[innerProps?.item?.id]}
            </Box>
            <Box>
              {isCopied === innerProps?.item?.id ? (
                <Button
                  className="custom-app--webhooks-copied-button"
                  size={'sm'}
                  variant="solid"
                  borderRadius={'29px'}
                >
                  <Icon name="check" size="16px" color="#5ACA75" />
                </Button>
              ) : (
                <Button
                  className="custom-app--webhooks-copy-button"
                  size={'sm'}
                  variant="solid"
                  borderRadius={'29px'}
                  onClick={() =>
                    copyTextToClipboard(
                      buildAppsURL(`/apps/custom/webhooks/${generatedURL[innerProps?.item?.id]}`),
                    )
                  }
                >
                  Copy URL
                </Button>
              )}
            </Box>
          </Grid>

          <Grid templateColumns="repeat(1, 1fr 100fr)" gap={'1rem'}>
            <Box fontSize={'0.85rem'} className="custom-app--webhooks-light" whiteSpace="nowrap">
              Webhook URL:
            </Box>
            <Box fontSize={'0.85rem'} className="custom-app--webhooks-url">
              {buildAppsURL(`/apps/custom/webhooks/${generatedURL[innerProps?.item?.id]}`)}
            </Box>
          </Grid>
        </Box>

        {requestArrayRequests.length - 1 === innerProps?.item?.id && (
          <Grid
            templateColumns="repeat(1, 1000fr 1fr 1fr)"
            gap={'1.5rem'}
            mt={expandedState === innerProps?.item?.id ? '1rem' : '2rem'}
          >
            <Box></Box>
            <Box>
              <Button
                variant="solid"
                variantColor="blue"
                size="sm"
                onClick={() => createNewRequest(innerProps?.item?.id)}
                isLoading={false}
                isDisabled={false}
                width="123px"
                height="42px"
                className="custom-app-apps-button-white"
                display={
                  requestArrayRequests.length - 1 === innerProps?.item?.id &&
                  requestName[innerProps?.item?.id]
                    ? 'Block'
                    : 'None'
                }
              >
                New request
              </Button>
            </Box>

            <Box>
              <Button
                variant="solid"
                variantColor="blue"
                size="sm"
                onClick={() => submitCustomAppData()}
                isLoading={submissionLoadingState}
                isDisabled={false}
                width="123px"
                height="42px"
                display={
                  requestArrayRequests.length - 1 === innerProps?.item?.id ? 'Block' : 'None'
                }
                className="custom-app--submit-button"
              >
                Submit
              </Button>
            </Box>
          </Grid>
        )}
      </Box>
    );
  };

  const { name } = useResellerSettings();

  return (
    <Box>
      <Box
        padding={'1rem'}
        backgroundColor={'rgba(240,238,253,0.42)'}
        marginBottom={'1.5rem'}
        className="custom-app--webhooks-info"
        borderRadius={'10px'}
      >
        <Box fontWeight={'500'} marginBottom={'0.5rem'}>
          WEBHOOKS INFORMATION
        </Box>
        <Box>
          <p>
            Whenever you need information updated on {name}, you should make requests to the
            endpoints listed for each request.
          </p>
          <p>
            You could make either POST, PUT or DELETE requests. The request body should be an object
            of the resource you want to update.
          </p>
          <p>A POST request creates a new object for the resource.</p>
          <p>A PUT request updates an existing object.</p>
          <p>A DELETE request deletes an existing object.</p>
        </Box>
      </Box>
      {requestArrayRequests.map((item, i) => (
        <RequestComponent key={i} item={item} setSection={props.setSection} />
      ))}
    </Box>
  );
};

export default Webhooks;
