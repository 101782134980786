import styled from '@emotion/styled';
import { motion } from 'framer-motion';

export const ListViewPanelWrapper = styled(motion.div)`
  border: 1px solid #f2f2f2;
  border-right: 4px solid #f2f2f2;
  background-color: #ffffff;
  // height: calc(100vh - 6.7rem);
  padding: 0px 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  .views-list-section {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: calc(100% - 121px);

    .view-list {
      display: flex;
      flex-direction: column;
      overflow-y: auto;
      scroll-behavior: smooth;

      ::-webkit-scrollbar {
        width: 8px;
      }

      ::-webkit-scrollbar-track {
        background: #f5f5f5;
        border-radius: 4px;
      }

      ::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background: #ccc;
      }

      ::-webkit-scrollbar-thumb:hover {
        background: #999;
      }

      .view-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;
        border-radius: 8px;
        padding: 5px 10px;
        font-size: 12px;
        font-weight: 500;
        justify-content: space-between;

        :hover {
          background-color: #f9f9f9;
        }
      }

      .active,
      .active:hover {
        background-color: rgba(61, 67, 223, 0.035);
      }
    }
  }

  .create-option-list {
    border-top: 2px solid rgba(0, 0, 0, 0.1);
    width: 100%;
    padding: 10px 0;

    .option-item {
      display: flex;
      flex-direction: row;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      font-size: 12px;
      cursor: pointer;
      border-radius: 8px;
      padding: 5px 10px;

      :hover {
        background-color: #f9f9f9;
      }

      .contact-us {
        font-size: 10px;
        border-radius: 10px;
        padding: 2px 5px;
        background-color: #026ae8;
        color: #ffffff;
        font-weight: 500;
      }
    }
  }
`;
