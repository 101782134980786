import Axios, { AxiosRequestConfig } from 'axios';
import { loadState } from './localstorage';
import { pusher } from './pusher';

export const buildConversationUrl = (url: string) =>
  `${process.env.REACT_APP_CONVERSATION_API_URL}/api/v1/${url}`;

export const buildPaymentURL = (url: string) => `${process.env.REACT_APP_PAYMENT_API_URL}${url}`;
export const buildAppsURL = (url: string) => `${process.env.REACT_APP_APPS_API_URL}${url}`;
export const buildGraphURL = (url: string) => `${process.env.REACT_APP_GRAPH_API_URL}${url}`;
export const buildReportsURL = (url: string) => `${process.env.REACT_APP_REPORTS_API_URL}${url}`;
export const buildNotificationURL = (url: string) =>
  `${process.env.REACT_APP_NOTIFICATIONS_API_URL}${url}`;
export const buildAIURL = (url: string) => `${process.env.REACT_APP_AI_API_URL}${url}`;
export const buildEventsURL = (url: string) => `${process.env.REACT_APP_EVENTS_API_URL}${url}`;

export async function client(
  url: string,
  { data, method = 'GET', ...customConfig }: AxiosRequestConfig = {},
  tokenProtected = true,
) {
  const localData = loadState();
  const { headers: customHeaders, ...rest } = customConfig;
  const headers = { 'content-type': 'application/json' } as {
    'content-type': string;
    Authorization: string;
    organisationID: string;
    'Simpu-Socket-ID': string;
  };
  const token = localData && localData.token;
  const { organisations, profile } = localData || { organisations: null, profile: null };
  if (token && tokenProtected) {
    headers.Authorization = token;
  }
  if (profile || organisations) {
    // if no profile, use the organisationID of the last organization
    headers.organisationID = profile
      ? profile.organisation_id
      : organisations[organisations.length - 1]?.id;
  }
  if (pusher?.connection?.socket_id) {
    headers['Simpu-Socket-ID'] = pusher.connection.socket_id;
  }

  const config = {
    data,
    method,
    headers: { ...headers, ...customHeaders },
    url: `${process.env.REACT_APP_API_URL}/${url}`,
    ...rest,
  } as AxiosRequestConfig;

  try {
    const result = await Axios(config);
    const { data } = result;
    return data;
  } catch (error) {
    throw error;
  }
}
