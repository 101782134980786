import { Icon } from '@chakra-ui/core';
import * as React from 'react';
import { useSearch } from '../../../hooks';
import { InputProps, Input } from '../Input';

type SearchProps = {
  value?: string;
  placeholder?: string;
  onChange?: (value: string) => void;
  minWidth?: string;
  inputBackground?: string;
  inputFontSize?: string;
  inputRef?: React.RefObject<HTMLInputElement>;
} & Omit<InputProps, 'onChange' | 'children'>;

export function Search({
  value,
  onChange,
  placeholder,
  inputBackground,
  inputFontSize,
  inputRef,
  ...rest
}: SearchProps) {
  const { input, handleChange } = useSearch({ initialValue: value, onChange });
  return (
    <Input
      size="sm"
      type="search"
      value={input}
      ref={inputRef}
      onChange={handleChange}
      placeholder={placeholder}
      leftIcon={<Icon name="search" color="#c0c3cc" />}
      {...rest}
    />
  );
}
