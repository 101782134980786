const identify = (user_id: string, traits: any, options?: any, callback?: any) => {
  window.analytics.identify(user_id, traits, options, callback);
};

const track = (event_name: string, properties?: any, options?: any, callback?: any) => {
  window.analytics.track(event_name, properties, options, callback);
};

const group = (group_id: string, traits: any) => {
  window.analytics.group(group_id, traits);
};

export { identify, track, group };

declare global {
  interface Window {
    analytics: any;
  }
}
