import {
  Badge,
  Editable,
  EditableInput,
  EditablePreview,
  FormControl,
  FormErrorMessage,
  IconButton,
  Stack,
} from '@chakra-ui/core';
import React from 'react';
import { campaignStateLabel, campaignStatusColors } from '../../campaigns.utils';

export const SetupWidgetHeaderLeftSlot = (props: {
  value: string;
  state: string;
  isInvalid: boolean;
  className?: string;
  onClick: () => void;
  errorMessage: string;
  onChange: (value: string) => void;
}) => {
  const { state, value, onClick, onChange, className, isInvalid, errorMessage } = props;

  return (
    <Stack isInline alignItems="center">
      <IconButton
        size="sm"
        rounded="8px"
        variant="ghost"
        aria-label="Back"
        icon="arrow-back"
        borderWidth="1px"
        onClick={onClick}
      />
      <FormControl isInvalid={isInvalid}>
        <Editable
          value={value}
          className={className}
          placeholder="New campaign"
          onChange={value => onChange(value)}
        >
          <EditablePreview fontSize="1.3125rem" fontWeight="bold" />
          <EditableInput fontSize="1.3125rem" fontWeight="bold" />
        </Editable>
      </FormControl>
      <FormErrorMessage>{errorMessage}</FormErrorMessage>
      <Badge
        variant="outline"
        textTransform="capitalize"
        variantColor={campaignStatusColors[state ?? 'draft']}
      >
        {campaignStateLabel[state ?? 'draft']}
      </Badge>
    </Stack>
  );
};
