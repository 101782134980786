import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
} from '@chakra-ui/core';
import { Button, ModalContainerOptions } from 'app/components';
import React, { useRef } from 'react';

export const LimitExceededAlert: React.FC<ModalContainerOptions> = ({ isOpen, onClose }) => {
  const cancelRef = useRef<HTMLButtonElement>(null);

  return (
    <AlertDialog isCentered isOpen={isOpen} onClose={onClose} leastDestructiveRef={cancelRef}>
      <AlertDialogOverlay />
      <AlertDialogContent rounded="lg">
        <AlertDialogHeader fontSize="lg" fontWeight="bold">
          Total Airtime Amount Limit Exceeded
        </AlertDialogHeader>

        <AlertDialogBody fontSize="sm">
          As this feature is in beta we've limited the total airtime amount amount to{' '}
          <strong>N1,000,000</strong>. <br />
          We'll increase this limit as users adopt this feature. <br />
          You can run as many airtime rewards as you wish on your lists.
        </AlertDialogBody>

        <AlertDialogFooter>
          <Button w="120px" size="sm" variantColor="blue" onClick={onClose} ml={3} ref={cancelRef}>
            Ok
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
