import React, { useEffect } from 'react';
import { Box, PseudoBox } from '@chakra-ui/core';

export const MessengerCellComponent = (props: any) => {
  useEffect(() => {
    if (
      props.search_query !== '' &&
      props.value &&
      props.value?.toString().toLowerCase().includes(props.search_query.toLowerCase())
    ) {
      props.eGridCell.style.backgroundColor = '#fff3d4';
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      className="select-cell"
      style={{ display: 'flex', alignItems: 'center' }}
      onClick={e => e.stopPropagation()}
    >
      {props.value && (
        <Box display="flex" marginTop="8px">
          {String(props.value)
            .split(',')
            .map((i: string, index: number) => (
              <PseudoBox
                style={{
                  fontSize: 12,
                  backgroundColor: '#0f0f0f1a',
                  marginRight: 5,
                  padding: '2px 5px',
                  borderRadius: 5,
                  lineHeight: 'normal',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
                _hover={{
                  backgroundColor: '#545D69 !important',
                  color: 'white',
                }}
                key={index}
                onClick={event => {
                  window.open(`https://www.facebook.com/${i}`, '_blank');
                  event.stopPropagation();
                }}
              >
                {i.replace(' ', '')}
              </PseudoBox>
            ))}
        </Box>
      )}
    </Box>
  );
};
