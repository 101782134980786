import {
  Box,
  Tab as ChakraTab,
  TabList as ChakraTabList,
  Tabs as ChakraTabs,
  FlexProps,
  PseudoBox,
  Stack,
  StackProps,
  TabListProps,
  TabsProps,
} from '@chakra-ui/core';
import { AnimateSharedLayout, motion } from 'framer-motion';
import React, { CSSProperties, ReactNode } from 'react';

export const Tab = React.forwardRef((props: any, ref: any) => {
  // `isSelected` is passed to all children of `TabList`.
  return (
    <ChakraTab
      ref={ref}
      px="0.6rem"
      display="flex"
      color="gray.500"
      position="relative"
      flexDirection="column"
      borderTopLeftRadius="4px"
      isSelected={props.isSelected}
      _selected={{ color: 'blue.500' }}
      _focus={{ bg: 'transparent', boxShadow: 'none' }}
      _active={{ bg: 'transparent', boxShadow: 'none' }}
      {...props}
    >
      {props.children}
      {props.isSelected && (
        <Box
          width="30px"
          height="2px"
          bottom="-2px"
          bg="blue.500"
          position="absolute"
          borderTopLeftRadius="4px"
          borderTopRightRadius="4px"
        />
      )}
    </ChakraTab>
  );
});

export const Tabs = (
  props: TabsProps & {
    children: any;
    tabPanels?: ReactNode;
    tabListProps?: Omit<TabListProps, 'children'>;
  },
) => {
  const { children, tabPanels, tabListProps, ...rest } = props;

  return (
    <ChakraTabs {...rest}>
      <ChakraTabList {...tabListProps}>{children}</ChakraTabList>
      {tabPanels}
    </ChakraTabs>
  );
};

export const AnimatedTabList: React.FC<StackProps> = props => {
  return (
    <AnimateSharedLayout>
      <Stack
        isInline
        spacing="0"
        p="0.25rem"
        width="100px"
        height="32px"
        bg="gray.100"
        rounded="4px"
        borderWidth="1px"
        alignItems="center"
        {...props}
      />
    </AnimateSharedLayout>
  );
};

export const AnimatedTab: React.FC<
  {
    onClick(): void;
    isActive: boolean;
    children: ReactNode;
    indicatorHeight?: CSSProperties['height'];
  } & FlexProps
> = props => {
  const { onClick, children, isActive, indicatorHeight, ...rest } = props;

  return (
    <PseudoBox
      zIndex={1}
      width="50%"
      display="flex"
      bg="transparent"
      cursor="pointer"
      onClick={onClick}
      fontWeight="medium"
      position="relative"
      alignItems="center"
      justifyContent="center"
      color={isActive ? 'gray.900' : 'gray.500'}
      {...rest}
    >
      {children}
      {isActive && (
        <motion.div
          layout="position"
          style={{
            zIndex: -1,
            width: '100%',
            borderRadius: '4px',
            position: 'absolute',
            backgroundColor: 'white',
            height: indicatorHeight ?? '24px',
            boxShadow: '0px 0px 1px rgba(45, 55, 72, 0.05),0px 1px 2px rgba(45, 55, 72, 0.1)',
          }}
          layoutId="arrow"
          transition={{
            layout: {
              duration: 0.2,
              ease: 'easeInOut',
            },
          }}
        />
      )}
    </PseudoBox>
  );
};
