import React from 'react';
import { RootState } from 'root';
import { connect } from 'react-redux';
import { Component } from './component';
import { fetchCustomApps } from 'app/authenticated-app/lists/thunks';
import { Box } from '@chakra-ui/core';
import AppParamsContextProvider from './contexts/app-contexts/params.context';
import AppHeadersContextProvider from './contexts/app-contexts/headers.context';
import RequestParamsContextProvider from './contexts/request-contexts/params.context';
import RequestHeadersContextProvider from './contexts/request-contexts/headers.context';
import AppPaginationContextProvider from './contexts/app-contexts/pagination.context';
import WebhooksParamsContextProvider from './contexts/webhooks-contexts/params.context';
import WebhooksHeadersContextProvider from './contexts/webhooks-contexts/headers.context';
import RequestPaginationContextProvider from './contexts/request-contexts/pagination.context';

const mapStateToProps = (state: RootState) => ({});

const stateConnector = connect(mapStateToProps, {
  fetchCustomApps,
});

const Container = (props: any) => (
  <Box>
    <AppParamsContextProvider>
      <AppHeadersContextProvider>
        <RequestParamsContextProvider>
          <RequestHeadersContextProvider>
            <RequestPaginationContextProvider>
              <AppPaginationContextProvider>
                <WebhooksParamsContextProvider>
                  <WebhooksHeadersContextProvider>
                    <Component {...props} />
                  </WebhooksHeadersContextProvider>
                </WebhooksParamsContextProvider>
              </AppPaginationContextProvider>
            </RequestPaginationContextProvider>
          </RequestHeadersContextProvider>
        </RequestParamsContextProvider>
      </AppHeadersContextProvider>
    </AppParamsContextProvider>
  </Box>
);

export const CustomApps = stateConnector(Container);
