import { AppThunk } from '../../../../root';
import { getAPISecretKeys, revokeAPISecretKeyItem, getAPIPublicKeys } from '../reducers/developers';
import {
  createAPISecretKey,
  listAPISecretKeys,
  removeAPISecretKey,
  listAPIPublicKey,
  createAPIPublicKey,
} from '../service/developers';
import { APISecretKey } from '../settings.types';

export const fetchAPISecretKeys = (): AppThunk => async dispatch => {
  try {
    const response = await listAPISecretKeys();
    const { keys } = response.data;
    dispatch(getAPISecretKeys({ keys }));
    return response.data;
  } catch (e) {
    return null;
  }
};

export const generateAPISecretKey = (): AppThunk => async dispatch => {
  try {
    const response = await createAPISecretKey();
    dispatch(fetchAPISecretKeys());
    return response.data;
  } catch (e) {
    return null;
  }
};

export const revokeAPISecretKey = (apiKey: APISecretKey): AppThunk => async dispatch => {
  try {
    const response = await removeAPISecretKey(apiKey.id);
    const { auth_key } = response.data;
    dispatch(revokeAPISecretKeyItem({ id: auth_key.id }));
    dispatch(fetchAPISecretKeys());
    return response.data;
  } catch (e) {
    return null;
  }
};

export const fetchAPIPublicKey = (): AppThunk => async dispatch => {
  try {
    const response = await listAPIPublicKey();
    const { publishable_key } = response.data;
    dispatch(getAPIPublicKeys({ key: publishable_key }));
    return response.data;
  } catch (e) {
    return null;
  }
};

export const generateAPIPublicKey = (): AppThunk => async dispatch => {
  try {
    const response = await createAPIPublicKey();
    dispatch(fetchAPIPublicKey());
    return response.data;
  } catch (e) {
    return null;
  }
};
