import { Avatar, Box, Divider, Heading, Stack, useToast } from '@chakra-ui/core';
import {
  fileUploadAPI,
  getResellerSettings,
  saveResellerSettings,
} from 'app/authenticated-app/settings/service';
import { ResellerSettingsType } from 'app/authenticated-app/settings/settings.types';
import {
  BodyText,
  Button,
  Container,
  FileUploader,
  Input,
  ToastBox,
  XSmallText,
} from 'app/components';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { toFormData } from 'utils';

export const ResellerSettings = () => {
  const toast = useToast();

  const { data: resellerSettings } = useQuery(['reseller-settings'], getResellerSettings);

  // const [showColorPicker, setShowColorPicker] = useState(false);
  const [isUploadingImage, setIsUploadingImage] = useState(false);
  const [isSavingSettings, setIsSavingSettings] = useState(false);
  const [isUploadingFavicon, setIsUploadingFavicon] = useState(false);
  const [preview, setPreview] = useState<string | ArrayBuffer | null | undefined>('');
  const [faviconPreview, setFaviconPreview] = useState<string | ArrayBuffer | null | undefined>('');

  const handleUploadPreview = (file: File) => {
    const reader = new FileReader();
    reader.onload = e => {
      setPreview(e?.target?.result);
    };
    reader.readAsDataURL(file);
  };

  const handleUploadFaviconPreview = (file: File) => {
    const reader = new FileReader();
    reader.onload = e => {
      setFaviconPreview(e?.target?.result);
    };
    reader.readAsDataURL(file);
  };

  const handleSubmitForm = async (values: ResellerSettingsType) => {
    try {
      setIsSavingSettings(true);
      await saveResellerSettings(values);
      setIsSavingSettings(false);
      toast({
        position: 'bottom-left',
        render: ({ onClose }) => (
          <ToastBox status="success" onClose={onClose} message="Settings updated successfully" />
        ),
      });
    } catch (error: any) {
      setIsSavingSettings(false);
      toast({
        position: 'bottom-left',
        render: ({ onClose }) => <ToastBox onClose={onClose} message={error.message} />,
      });
    }
  };

  const { values, errors, touched, handleChange, submitForm, setFieldValue } =
    useFormik<ResellerSettingsType>({
      enableReinitialize: true,
      onSubmit: handleSubmitForm,
      initialValues: resellerSettings ?? {
        url: '',
        name: '',
        logo: '',
        email_template: {
          phone: '',
          address: '',
          sender_email: '',
          company_name: '',
          socials: {
            facebook: {
              url: '',
            },
            twitter: {
              url: '',
            },
            instagram: {
              url: '',
            },
            linkedin: {
              url: '',
            },
          },
        },
        meta: {
          brand_color: '',
        },
      },
    });

  const handleDropLogo = async (files: File[]) => {
    const image = files[0];
    if (image) {
      handleUploadPreview(image);
      try {
        setIsUploadingImage(true);
        const url = await fileUploadAPI(toFormData({}, image, 'file'));
        setFieldValue('logo', url);
        setIsUploadingImage(false);
        toast({
          position: 'bottom-left',
          render: ({ onClose }) => (
            <ToastBox status="success" onClose={onClose} message="Logo uploaded successfully" />
          ),
        });
      } catch (error: any) {
        setIsUploadingImage(false);
        toast({
          position: 'bottom-left',
          render: ({ onClose }) => <ToastBox onClose={onClose} message={error.message} />,
        });
      }
    } else {
      return;
    }
  };

  const handleDropFavicon = async (files: File[]) => {
    const image = files[0];
    if (image) {
      handleUploadFaviconPreview(image);
      try {
        setIsUploadingFavicon(true);
        const url = await fileUploadAPI(toFormData({}, image, 'file'));
        setFieldValue('meta.favicon', url);
        setIsUploadingFavicon(false);
        toast({
          position: 'bottom-left',
          render: ({ onClose }) => (
            <ToastBox status="success" onClose={onClose} message="Favicon uploaded successfully" />
          ),
        });
      } catch (error: any) {
        setIsUploadingFavicon(false);
        toast({
          position: 'bottom-left',
          render: ({ onClose }) => <ToastBox onClose={onClose} message={error.message} />,
        });
      }
    } else {
      return;
    }
  };

  const handleDropRejectedImage = () => {
    toast({
      description: 'Uploaded image size cannot be more than 2MB',
      status: 'error',
      duration: 9000,
      isClosable: true,
      position: 'top',
    });
  };

  return (
    <Container maxW="640px">
      <Heading mb="1.5rem" fontWeight="semibold" size="md">
        Reseller Settings
      </Heading>
      <Box>
        <Stack isInline alignItems="center" mb="1.5rem">
          <BodyText fontWeight="500">General</BodyText>
          <Divider flex={1} borderColor="gray.200" />
        </Stack>
        <Box mb="1.5rem">
          <Input
            size="md"
            id="name"
            type="text"
            name="name"
            label="Name"
            isRequired
            value={values.name}
            onChange={handleChange}
            placeholder="Enter name"
            errorMessage={errors.name}
            isInvalid={!!touched.name && !!errors.name}
          />
        </Box>
        <Box mb="1.5rem">
          <Input
            id="url"
            type="url"
            name="url"
            isRequired
            label="Domain"
            value={values.url}
            onChange={handleChange}
            errorMessage={errors.url}
            placeholder="Enter your domain"
            isInvalid={!!touched.url && !!errors.url}
          />
        </Box>
      </Box>
      <Box>
        <Stack isInline alignItems="center" mb="1rem">
          <BodyText fontWeight="500">Appearance</BodyText>
          <Divider flex={1} borderColor="gray.200" />
        </Stack>
        <Stack spacing="2rem" isInline alignItems="center" mb="1rem">
          <Stack isInline alignItems="center" position="relative">
            <Avatar
              size="lg"
              name={values?.name}
              src={preview && typeof preview === 'string' ? preview : values?.logo}
            />
            <Box>
              <FileUploader
                accept="image/*"
                maxSize={2000000}
                onUpload={handleDropLogo}
                onDropRejected={handleDropRejectedImage}
              >
                <Button isLoading={isUploadingImage} size="sm">
                  {!(preview || values?.logo) ? 'Upload' : 'Change'} Logo
                </Button>
              </FileUploader>
              <XSmallText pt="0.25rem" color="gray.500">
                Max. image size of 2MB
              </XSmallText>
            </Box>
          </Stack>
          <Stack isInline alignItems="center" position="relative">
            <Avatar
              size="lg"
              src={
                faviconPreview && typeof faviconPreview === 'string'
                  ? faviconPreview
                  : values?.meta?.favicon
              }
            />
            <Box>
              <FileUploader
                maxSize={2000000}
                onUpload={handleDropFavicon}
                accept="image/png, image/jpg, image/svg"
                onDropRejected={handleDropRejectedImage}
              >
                <Button isLoading={isUploadingFavicon} size="sm">
                  {!(faviconPreview || values?.meta?.favicon) ? 'Upload' : 'Change'} Favicon
                </Button>
              </FileUploader>
              <XSmallText pt="0.25rem" color="gray.500">
                A favicon is a browser icon representing your brand. Upload an image (PNG, JPG,
                SVG), at least 16x16 pixels.
              </XSmallText>
            </Box>
          </Stack>
        </Stack>
        {/* <Box mb="1rem">
          <FormControl>
            <FormLabel display="block" color="#333333" fontSize="0.875rem" mb="0.5rem">
              Brand Color
            </FormLabel>
            <OutsideClickHandler onClickOutside={() => setShowColorPicker(false)}>
              <Button size="sm" onClick={() => setShowColorPicker(!showColorPicker)}>
                Click to select your brand color
              </Button>
              {showColorPicker && (
                <TwitterPicker
                  triangle="hide"
                  color={values?.meta?.brand_color}
                  onChangeComplete={color => {
                    setFieldValue('brand_color', color.hex);
                  }}
                />
              )}
            </OutsideClickHandler>
          </FormControl>
        </Box> */}
      </Box>
      <Box>
        <Stack isInline alignItems="center" mb="1rem">
          <BodyText fontWeight="500">Email template</BodyText>
          <Divider flex={1} borderColor="gray.200" />
        </Stack>
        <Box mb="1.5rem">
          <Input
            size="md"
            type="text"
            id="company_name"
            label="Company name"
            onChange={handleChange}
            placeholder="Enter company name"
            name="email_template.company_name"
            value={values?.email_template?.company_name}
          />
        </Box>
        <Box mb="1.5rem">
          <Input
            id="email"
            type="email"
            label="Email address"
            onChange={handleChange}
            placeholder="Enter email address"
            name="email_template.sender_email"
            value={values?.email_template?.sender_email?.toLowerCase()}
          />
        </Box>
        <Box mb="1.5rem">
          <Input
            size="md"
            type="text"
            id="address"
            label="Address"
            onChange={handleChange}
            name="email_template.address"
            value={values?.email_template?.address}
            placeholder="Enter your company address"
          />
        </Box>
        <Box mb="1.5rem">
          <Input
            size="md"
            type="tel"
            id="phone"
            label="Phone"
            onChange={handleChange}
            name="email_template.phone"
            value={values?.email_template?.phone}
            placeholder="Enter your company phone number"
          />
        </Box>
        <Box mb="1.5rem">
          <Input
            type="url"
            label="Facebook URL"
            onChange={handleChange}
            placeholder="Enter your Facebook URL"
            id="email_template.socials.facebook.url"
            name="email_template.socials.facebook.url"
            value={values.email_template?.socials?.facebook?.url}
          />
        </Box>
        <Box mb="1.5rem">
          <Input
            type="url"
            label="Twitter URL"
            onChange={handleChange}
            placeholder="Enter your Twitter URL"
            id="email_template.socials.twitter.url"
            name="email_template.socials.twitter.url"
            value={values.email_template?.socials?.twitter?.url}
          />
        </Box>
        <Box mb="1.5rem">
          <Input
            type="url"
            label="Instagram URL"
            onChange={handleChange}
            placeholder="Enter your Instagram URL"
            id="email_template.socials.instagram.url"
            name="email_template.socials.instagram.url"
            value={values.email_template?.socials?.instagram?.url}
          />
        </Box>
      </Box>
      <Button
        size="sm"
        width="120px"
        variantColor="blue"
        onClick={submitForm}
        isLoading={isSavingSettings}
      >
        Submit
      </Button>
    </Container>
  );
};
