import React, { createContext, useEffect, useRef, useState } from "react";

const initialParamState = [
    {
        id: 0, 
        isWritten: false,
        isDisabled: false,
        key_text: '',
        value_text: '',
        description_text: '',
    }
]

interface ParamsStateProps {
    id: number;
    isWritten: boolean;
    isDisabled: boolean;
    key_text: string;
    value_text: string;
    description_text: string;
}

export interface DropDownOptionsProps {
    id: number,
    label: string,
    value: string,
    explanation: string[],
    selected: boolean
}

interface Props {
    paramsState: ParamsStateProps[];
    setParamsState: Function;
    rowId: number;
    setRowId: Function;
    currentCell: Array<number | string>;
    setCurrentCell: Function;
    createNewList: Function;
    setText: Function;
    cursorPosition: Array<number>;
    setCursorPosition: Function;
    urlText: string;
    setUrlText: Function;
    totalText: string;
    setTotalText: Function;
    userText: string;
    setUserText: Function;
    focusRef: React.MutableRefObject<any>;
    urlParser: Function;
    dropDown: Array<Boolean | number>;
    setDropDown: Function;
    dropDownOptions: DropDownOptionsProps[];
    setDropDownOptions: Function;
    appName: string;
    setAppName: Function;
    authenticationType: string;
    setAuthenticationType: Function;
    RadioImageValue: string;
    setRadioImageValue: Function;
    responseType: string;
    setResponseType: Function;
    basicAuthUserName: string;
    setBasicAuthUserName: Function;
    basicAuthPassword: string;
    setBasicAuthPassword: Function;
    isPasswordVisible: boolean;
    setIsPasswordVisible: Function;
    urlCursor: number[];
    setUrlCursor: Function;
    nextPageState: boolean[];
    setNextPage: Function;
    selectedTitle: string;
    setSelectedTitle: Function;
    hoverState: boolean;
    setHoverState: Function;
}

export const AppParamsContext = createContext({} as Props);

const AppParamsContextProvider = (props: any) => {

    const initialDropDownOptions = [
        {
            id: 0,
            label: 'Next Page Link',
            value: 'nextPageLink',
            explanation: ['Cursor-based Pagination', 'The URL of the next page.', 'Output'],
            selected: false
        },
        {
            id: 1,
            label: 'Page Parameter Key',
            value: 'pageParameterKey',
            explanation: ['Page-based Pagination', 'The key of the pagination parameter.', 'Input'],
            selected: false
        },
        {
            id: 2,
            label: 'Offset',
            value: 'offset',
            explanation: ['Offset-based Pagination', 'The key of the number of records to be skipped per iteration.', 'Input'],
            selected: false
        },
        {
            id: 3,
            label: 'Limit',
            value: 'limit',
            explanation: ['Records/Item Limit', 'The key of the number of records returned per request. For all input pagination parameters.', 'Input'],
            selected: false
        }
    ]
    
    const [paramsState, setParamsState] = useState(initialParamState) 
    const [rowId, setRowId] = useState(0) 
    const [currentCell, setCurrentCell] = useState([0, 0, ''])
    const [cursorPosition, setCursorPosition] = useState([0, 0])
    const [urlText, setUrlText] = useState('');
    const [totalText, setTotalText] = useState('');
    const [userText, setUserText] = useState('');
    const [dropDown, setDropDown] = useState([false, 0, 0]);
    const focusRef = useRef<any>();
    const [dropDownOptions, setDropDownOptions] = useState(initialDropDownOptions)
    const [appName, setAppName] = useState('')
    const [authenticationType, setAuthenticationType] = useState('no_auth');
    const [RadioImageValue, setRadioImageValue] = useState('1');
    const [responseType, setResponseType] = useState('json');
    const [basicAuthUserName, setBasicAuthUserName] = useState('');
    const [basicAuthPassword, setBasicAuthPassword] = useState('')
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const [urlCursor, setUrlCursor] = useState([0, 0]);
    const [nextPageState, setNextPage] = useState([false, false]);
    const [selectedTitle, setSelectedTitle] = useState('Global Params');
    const [hoverState, setHoverState] = useState(false)

    useEffect(() => {
        setParamsState([
            {
                id: 0, 
                isWritten: false,
                isDisabled: false,
                key_text: '',
                value_text: '',
                description_text: '',
            }
        ])
        
    }, [])

    const urlParser = () => {
        let keyText;
        let valueText;
        let parsedText = '';
        let firstTaken = false;

        paramsState.forEach((item, index, array) => {
            if (item.isWritten && !item.isDisabled) {
                if (!firstTaken) {
                    keyText = '?' + item.key_text;
                    parsedText = '/{request_url}';
                    valueText = typeof item.value_text === 'string' ? '=' + item.value_text : '';
                    parsedText += keyText+valueText
                    firstTaken = true;
                } else {
                    keyText = typeof item.key_text === 'string' ? '&' + item.key_text : '';
                    valueText = typeof item.value_text === 'string' ? '=' + item.value_text : '';
                    parsedText += keyText+valueText
                }
            }
            
        });  
        setUrlText(parsedText)
    }

    const createNewList = (previousRow: ParamsStateProps) => {
        
        paramsState[paramsState.length-1].isWritten = true

        setRowId(paramsState.length)

        const newState = paramsState.concat({id: paramsState.length, isWritten: false, isDisabled: false, key_text: '', value_text: '', description_text: ''})
        
        setParamsState(newState)
    }

    const setText = (e: any, rowId: number, text: never, key: keyof typeof initialParamState[0]) => {
        
        paramsState[rowId][key] = text
        setParamsState(paramsState)
    }

    
    const value = {paramsState, setParamsState, rowId, setRowId, currentCell, setCurrentCell, 
         createNewList, setText, cursorPosition, setCursorPosition, urlText, setUrlText, totalText,
         setTotalText, userText, setUserText, focusRef, urlParser, dropDown, setDropDown, 
         dropDownOptions, setDropDownOptions, appName, setAppName, authenticationType, setAuthenticationType,
         RadioImageValue, setRadioImageValue, responseType, setResponseType, basicAuthUserName, setBasicAuthUserName,
         basicAuthPassword, setBasicAuthPassword, isPasswordVisible, setIsPasswordVisible, urlCursor, setUrlCursor,
         nextPageState, setNextPage, selectedTitle, setSelectedTitle, hoverState, setHoverState
        };

    return (
        <AppParamsContext.Provider value={value}>
            {props.children}
        </AppParamsContext.Provider>
    )
}

export default AppParamsContextProvider
 