import { CampaignData } from './campaigns.types';

export function sortCampaignsFunc(a: CampaignData, b: CampaignData) {
  var dateA = a.created_datetime && new Date(a.created_datetime).getTime();
  var dateB = b.created_datetime && new Date(b.created_datetime).getTime();
  if (dateA && dateB) {
    return dateB - dateA;
  }
  return null;
}

export const campaignStatusColors: { [key: string]: string } = {
  draft: 'gray',
  pristine: 'gray',
  stopped: 'red',
  started: 'green',
  paused: 'yellow',
  completed: 'green',
  'insufficient credit': 'red',
  'unknown error': 'red',
  'no phone column': 'red',
  'no destination column': 'red',
  'empty content': 'red',
  'campaign not found': 'red',
  'invalid sender': 'red',
  rescheduled: 'yellow',
  'template error': 'red',
  'no recipient': 'red',
  'table not found': 'red',
  'payment error': 'red',
};

export const campaignStateLabel: { [key: string]: string } = {
  draft: 'Draft',
  pristine: 'Work In-Progress',
  stopped: 'Stopped',
  started: 'Started',
  paused: 'Paused',
  completed: 'Completed',
  'insufficient credit': 'Insufficient Credit',
  'unknown error': 'Unknown Error',
  'no phone column': 'No Phone Column',
  'no destination column': 'No Destination Column',
  'empty content': 'Empty Content',
  'campaign not found': 'Campaign Not Found',
  'invalid sender': 'Invalid Sender ID',
  rescheduled: 'Rescheduled',
  'template error': 'Template Error',
  'no recipient': 'No Recipient',
  'table not found': 'List Not Found',
  'payment error': 'Payment Error',
};
