import { ButtonProps, useDisclosure } from '@chakra-ui/core';
import { Button } from 'app/components';
import React from 'react';
import { connect, useSelector } from 'react-redux';
import { RootState } from '../../../../../root';
import { selectOrganisationID } from '../../../../unauthenticated-app/authentication';
import { fetchWallet } from '../../../payments';
import { BankDetailsModal } from './bank-details-modal';
import { CreditTopupModal } from './credit-topup-modal';
import { CreditTopupOptionsModal } from './credit-topup-options-modal';

interface CreditTopCardButtonProps {
  cards: any[];
  wallet_id: any;
  default_card: any;
  billingData?: any;
  wallet_email?: any;
  fetchWallet: (organization_id: any) => void;
  buttonProps: ButtonProps;
}

function mapStateToProps(state: RootState) {
  return {
    cards: state.payment.cards,
    wallet_id: state.payments.wallet.data.id,
    wallet_email: state.payments.wallet.data.email,
    default_card: state.payments.wallet.data.card_default,
    billingData: state.billing.subscription.data,
  };
}

const mapDispatchToProps = {
  fetchWallet,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

const Component = (props: CreditTopCardButtonProps) => {
  const { cards, wallet_id, fetchWallet, billingData, wallet_email, default_card, buttonProps } =
    props;

  const {
    isOpen: isCreditCardModalOpen,
    onOpen: onOpenCreditCardModal,
    onClose: onCloseCreditCardModal,
  } = useDisclosure();

  const {
    isOpen: isCreditTopupOptionsModalOpen,
    onClose: onCloseCreditTopupOptionsModal,
    onOpen: onOpenCreditTopupOptionsModal,
  } = useDisclosure();

  const {
    isOpen: isBankDetailsModalOpen,
    onClose: onCloseBankDetailsModal,
    onOpen: onOpenBankDetailsModal,
  } = useDisclosure();

  const organisationId = useSelector(selectOrganisationID);
  return (
    <>
      <Button
        size="xs"
        variantColor="blue"
        children="Top up now"
        onClick={onOpenCreditTopupOptionsModal}
        {...buttonProps}
      />
      {isCreditTopupOptionsModalOpen && (
        <CreditTopupOptionsModal
          isOpen={isCreditTopupOptionsModalOpen}
          onClose={onCloseCreditTopupOptionsModal}
          onOpenCreditCardModal={onOpenCreditCardModal}
          onOpenBankDetailsModal={onOpenBankDetailsModal}
        />
      )}
      {isCreditCardModalOpen && (
        <CreditTopupModal
          cards={cards}
          wallet_id={wallet_id}
          fetchWallet={fetchWallet}
          billingData={billingData}
          wallet_email={wallet_email}
          default_card={default_card}
          onOpen={onOpenCreditCardModal}
          isOpen={isCreditCardModalOpen}
          onClose={() => {
            onCloseCreditCardModal();
            onOpenCreditTopupOptionsModal();
          }}
          onCloseCreditTopupOptionsModal={onCloseCreditTopupOptionsModal}
          // @ts-ignore
          organisationId={organisationId}
        />
      )}
      {isBankDetailsModalOpen && (
        <BankDetailsModal
          isOpen={isBankDetailsModalOpen}
          onClose={() => {
            onCloseBankDetailsModal();
            onOpenCreditTopupOptionsModal();
          }}
        />
      )}
    </>
  );
};

export const CreditTopupButton = connector(Component);
