// @ts-nocheck
import React from 'react';
import { ListGrid } from '../ListViews';
import { ConfirmModal } from 'app/components';
import { ListFormView } from '../ListViews/Form';
import { useSelector } from 'react-redux';
import { selectViews } from 'app/authenticated-app/lists/lists.selectors';
import { ListView } from 'app/authenticated-app/lists.types';

interface Props {
  activeView: string;
  setActiveView: Function;
}

export const ListBody = (props: Props) => {
  const {
    activeView: activeViewID,
    clearRowsDialogOpen,
    closeClearRowsDialog,
    handleClearRows,
    deleteRowsDialogOpen,
    closeDeleteRowsDialog,
    handleDeleteRows,
  } = props;

  const views: ListView[] = useSelector(selectViews);
  const activeView: ListView = views.find((i: ListView) => i.id === activeViewID);

  return (
    <>
      {activeViewID.includes('grid') && (
        <>
          <ListGrid {...props} />
          <ConfirmModal
            isOpen={clearRowsDialogOpen}
            onClose={closeClearRowsDialog}
            title="Clear rows"
            description="All cells currently occupied by the selected rows would be completely emptied"
            onConfirm={handleClearRows}
          />
          <ConfirmModal
            isOpen={deleteRowsDialogOpen}
            onClose={closeDeleteRowsDialog}
            title="Delete rows"
            description="All selected rows would be permanently deleted"
            onConfirm={handleDeleteRows}
          />
        </>
      )}

      {activeView?.type === 'form' && <ListFormView {...props} form={activeView.form} />}
    </>
  );
};
