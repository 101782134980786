import { Box, Heading, Stack, useToast, Avatar, useDisclosure } from '@chakra-ui/core';
import { BodyText, Button, FileUploader, ToastBox, XSmallText } from 'app/components';
import {
  assignOrganisationOwnership,
  cancelDeleteAccountRequest,
  deleteAccountRequest,
  getDeleteAccountRequest,
  logout,
  selectUserDevice,
  UserProfile,
} from 'app/unauthenticated-app/authentication';
import { FormikHelpers } from 'formik';
import isEmpty from 'lodash/isEmpty';
import { useLoading } from 'hooks';
import React, { useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { SettingsProps } from '../../settings.container';
import { ChangePassordForm } from './ChangePasswordForm';
import { DeleteAccountModal, DeleteAccountPayload } from './DeleteAccountModal';
import { ProfileForm } from './ProfileForm';
import { useSelector } from 'react-redux';

export function Profile(
  props: Pick<
    SettingsProps,
    'user' | 'profile' | 'editProfile' | 'editPassword' | 'userProfileImage' | 'logUserOut'
  > &
    RouteComponentProps,
) {
  const toast = useToast();
  const history = useHistory();
  const queryClient = useQueryClient();
  const device = useSelector(selectUserDevice);
  const {
    isOpen: isDeleteAccountModalOpen,
    onClose: onCloseDeleteAccountModal,
    onOpen: onOpenCloseDeleteAccountModal,
  } = useDisclosure();

  const [isDeletingAccount, setIsDeletingAccount] = useState(false);
  const [isUploadingProfileImage, setIsUploadingProfileImage] = useState(false);
  const [preview, setPreview] = useState<string | ArrayBuffer | null | undefined>('');
  const [isCancellingDeleteAccountRequest, setIsCancellingDeleteAccountRequest] = useState(false);

  const { dispatch, tableLoading: profileLoading, loading: changePasswordLoading } = useLoading();

  const { user, profile, editProfile, editPassword, userProfileImage, logUserOut } = props;

  const { data: hasDeleteAccountRequest } = useQuery(
    'delete-account-request',
    getDeleteAccountRequest,
  );

  const handleProfileUpdate = async (values: Pick<UserProfile, 'first_name' | 'last_name'>) => {
    dispatch({ type: 'TABLE_LOADING_STARTED' });
    try {
      await editProfile({
        ...values,
        user_id: user?.id,
        organisation_id: profile?.organisation_id,
      });
      dispatch({ type: 'TABLE_LOADING_RESOLVED' });
      toast({
        position: 'bottom-left',
        render: ({ onClose }) => (
          <ToastBox status="success" onClose={onClose} message="Profile updated" />
        ),
      });
    } catch (error) {
      dispatch({ type: 'TABLE_LOADING_RESOLVED' });
      toast({
        position: 'bottom-left',
        render: ({ onClose }) => <ToastBox onClose={onClose} message={error} />,
      });
    }
  };

  const handleChangePassword = async (
    values: {
      old_password: string;
      new_password: string;
    },
    callback: () => void,
  ) => {
    dispatch({ type: 'LOADING_STARTED' });
    try {
      await editPassword(values);
      dispatch({ type: 'LOADING_RESOLVED' });
      toast({
        position: 'bottom-left',
        render: ({ onClose }) => (
          <ToastBox status="success" onClose={onClose} message="Password changed" />
        ),
      });
      callback();
    } catch (error: any) {
      dispatch({ type: 'LOADING_RESOLVED' });
      toast({
        position: 'bottom-left',
        render: ({ onClose }) => <ToastBox onClose={onClose} message={error.message} />,
      });
    }
  };

  const handleUploadPreview = (file: File) => {
    const reader = new FileReader();
    reader.onload = e => {
      setPreview(e?.target?.result);
    };
    reader.readAsDataURL(file);
  };

  const handleDropProfileImage = async (files: File[]) => {
    const image = files[0];
    if (image) {
      handleUploadPreview(image);
      try {
        setIsUploadingProfileImage(true);
        await userProfileImage(image);
        setIsUploadingProfileImage(false);
        toast({
          position: 'bottom-left',
          render: ({ onClose }) => (
            <ToastBox status="success" onClose={onClose} message="Profile image uploaded" />
          ),
        });
      } catch (error: any) {
        setIsUploadingProfileImage(false);
        toast({
          position: 'bottom-left',
          render: ({ onClose }) => <ToastBox onClose={onClose} message={error.message} />,
        });
      }
    } else {
      return;
    }
  };

  const handleDropRejectedProfileImage = () => {
    toast({
      description: 'Uploaded image size cannot be more than 2MB',
      status: 'error',
      duration: 9000,
      isClosable: true,
      position: 'top',
    });
  };

  const handleAssignOwnership = async (profile_id: string) => {
    try {
      await assignOrganisationOwnership({ profile_id });
    } catch (error) {
      setIsDeletingAccount(false);
      toast({
        position: 'bottom-left',
        render: ({ onClose }) => <ToastBox onClose={onClose} message={error} />,
      });
    }
  };

  const handleLogout = async () => {
    try {
      await logout(device?.id ?? '');
      logUserOut();
      history.push('/login');
    } catch (error: any) {
      toast({
        position: 'bottom-left',
        render: ({ onClose }) => (
          <ToastBox
            status="error"
            onClose={onClose}
            message={error?.message ?? 'Error logging out.'}
          />
        ),
      });
    }
  };

  const handleCancelDeleteAccountRequest = async () => {
    try {
      setIsCancellingDeleteAccountRequest(true);
      await cancelDeleteAccountRequest();
      queryClient.invalidateQueries('delete-account-request');
      setIsCancellingDeleteAccountRequest(false);
    } catch (error) {
      setIsCancellingDeleteAccountRequest(false);
      toast({
        position: 'bottom-left',
        render: ({ onClose }) => <ToastBox onClose={onClose} message={error} />,
      });
    }
  };

  const handleDeleteAccountRequest = async (
    values: DeleteAccountPayload,
    formikHelpers: FormikHelpers<DeleteAccountPayload>,
  ) => {
    const { reason, profile_id } = values;

    try {
      setIsDeletingAccount(true);
      await deleteAccountRequest({ reason });
      profile_id && (await handleAssignOwnership(profile_id));
      formikHelpers.resetForm();
      onCloseDeleteAccountModal();
      queryClient.invalidateQueries('delete-account-request');
      await handleLogout();
      setIsDeletingAccount(false);
    } catch (error) {
      setIsDeletingAccount(false);
      toast({
        position: 'bottom-left',
        render: ({ onClose }) => <ToastBox onClose={onClose} message={error} />,
      });
    }
  };

  return (
    <>
      <Box maxW="640px" margin="auto" paddingBottom={['5rem', '10rem', '0']}>
        <Box marginBottom="1.5rem" paddingBottom="2.5rem">
          <Stack isInline alignItems="center">
            <Heading fontWeight="semibold" size="md">
              My Settings
            </Heading>
          </Stack>

          <Stack isInline alignItems="center" position="relative" marginTop="2rem">
            <Avatar
              size="2xl"
              name={`${profile?.first_name} ${profile?.last_name}`}
              src={preview && typeof preview === 'string' ? preview : profile?.image}
            />

            <Box>
              <FileUploader
                maxSize={2000000}
                onUpload={handleDropProfileImage}
                onDropRejected={handleDropRejectedProfileImage}
              >
                <Button isLoading={isUploadingProfileImage} size="sm">
                  {!(preview || profile?.image) ? 'Upload' : 'Change'} Photo
                </Button>
              </FileUploader>
              <XSmallText pt="0.25rem" color="gray.500">
                Max. image size of 2MB
              </XSmallText>
            </Box>
          </Stack>

          <Box marginTop="2rem">
            <ProfileForm
              onSubmit={handleProfileUpdate}
              initialValues={{
                email: user?.email,
                last_name: profile?.last_name,
                first_name: profile?.first_name,
              }}
              isLoading={profileLoading === 'pending'}
            />
          </Box>
        </Box>
        <Box marginBottom="1.5rem" paddingBottom="1.5rem">
          <Stack isInline alignItems="center">
            <Heading fontWeight="semibold" size="sm" color="#333333">
              Change Password
            </Heading>
          </Stack>
          <Box marginTop="1.5rem">
            <ChangePassordForm
              onSubmit={handleChangePassword}
              isLoading={changePasswordLoading === 'pending'}
            />
          </Box>
        </Box>
        <Box rounded="8px" bg="red.50" marginBottom="1.5rem" p="1rem">
          <Stack>
            <Heading fontWeight="semibold" size="sm" color="red.500">
              Delete account
            </Heading>
            <BodyText color="red.400">
              If you want to permanently delete your account and all of its data, you can do so
              below.
            </BodyText>
          </Stack>
          <Box marginTop="1.5rem">
            {!isEmpty(hasDeleteAccountRequest) ? (
              <Button
                size="sm"
                variantColor="blue"
                onClick={handleCancelDeleteAccountRequest}
                isLoading={isCancellingDeleteAccountRequest}
              >
                Cancel delete account request
              </Button>
            ) : (
              <Button size="sm" variantColor="red" onClick={onOpenCloseDeleteAccountModal}>
                Delete your account
              </Button>
            )}
          </Box>
        </Box>
      </Box>
      <DeleteAccountModal
        isDeleting={isDeletingAccount}
        isOpen={isDeleteAccountModalOpen}
        onClose={onCloseDeleteAccountModal}
        onDeleteAccount={handleDeleteAccountRequest}
      />
    </>
  );
}
