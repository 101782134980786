import React from 'react';
import { Box, Checkbox } from '@chakra-ui/core';

export const CheckboxCell = (props: any) => {
  const handleChange = async (event: any) => {
    const value: boolean = event.target.checked;
    const rowIndex = props.node.rowIndex;
    const row = await props.getRowByIndex(rowIndex);
    const columnID = props._column.uid;
    const rowID = row['uid'];
    await props.updateCellValue(value, rowID, columnID);
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="row"
      height="35px"
    >
      <Checkbox
        borderRadius="10px"
        size="sm"
        variantColor="blue"
        className="checkbox-cell"
        onChange={handleChange}
        defaultIsChecked={!!props.value}
        isDisabled={!props.allowEdit}
      />
    </Box>
  );
};
