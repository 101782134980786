import { AxiosRequestConfig } from 'axios';
import { buildConversationUrl, client } from '../../../../utils';

export const listAPISecretKeys = () => {
  return client(`organisations/api-keys`);
};

export const createAPISecretKey = () => {
  return client('organisations/generate-api-key', { method: 'POST' });
};

export const removeAPISecretKey = (id: string) => {
  return client('organisations/revoke-api-key', { method: 'DELETE', data: { id } });
};

export const listAPIPublicKey = () => {
  return client(`organisations/publisable-key`);
};

export const createAPIPublicKey = () => {
  return client('organisations/generate-publishable-key', { method: 'POST' });
};

export const generateSessionQRCode = async (code: string) => {
  const { data } = await client('', {
    method: 'POST',
    url: buildConversationUrl(`sessions/qr-code/${code}`),
  });
  return data;
};

export const cancelSessionQRCode = async (code: string) => {
  const { data } = await client('', {
    method: 'DELETE',
    url: buildConversationUrl(`sessions/qr-code/${code}`),
  });
  return data;
};

export const addSession = async (payload: {
  type?: string;
  inbox_id?: string;
  credential_id?: string;
  integration_id?: string;
}) => {
  const { data } = await client('', {
    method: 'POST',
    data: payload,
    url: buildConversationUrl('sessions'),
  });
  return data;
};

export async function fetchCredentials(params?: { page?: number; is_approved?: boolean }) {
  const response = await client('', { url: buildConversationUrl(`channels`), params });
  return response.data;
}

export async function removeCredential(params?: AxiosRequestConfig['params']) {
  const { credential_id } = params;

  return client('', {
    url: buildConversationUrl(`auth/revoke/${credential_id}`),
    method: 'PATCH',
  });
}

export async function onUseCredentialHere(params?: AxiosRequestConfig['params']) {
  const { credentialID } = params;

  return client('', {
    method: 'POST',
    url: buildConversationUrl(`auth/use-here/${credentialID}`),
  });
}
