import { Box, Icon, Stack } from '@chakra-ui/core';
import { fetchViews } from 'app/authenticated-app/lists/lists.reducer';
import { selectViews } from 'app/authenticated-app/lists/lists.selectors';
import { selectLists } from 'app/authenticated-app/lists/lists.slice';
import { ListView } from 'app/authenticated-app/lists/lists.types';
import { BodyText, CodeSnippet, ContentWrapper, Subtitle } from 'app/components';
import codeSnippetTheme from 'prism-react-renderer/themes/shadesOfPurple';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

export const ListEmbedView = (props: RouteComponentProps<{ id: string; listViewId: string }>) => {
  const { match } = props;

  const listId = match.params.id;

  const listViewId = match.params.listViewId;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchViews(listId));
  }, [dispatch, listId]);

  const lists = useSelector(selectLists) as any;

  const views: ListView[] = useSelector(selectViews);

  const list = lists[listId];

  const activeView = views.find((i: ListView) => i.id === listViewId);

  const formLink = `${process.env.REACT_APP_FORMS_EMBED_URL}/${activeView?.form.id}`;

  const embedSnippet = `
  <iframe
      title="simpu-embed"
      class="simpu-embed"
      src="${formLink}"
      frameborder="0"
      width="100%"
      height="533"
      style="background: transparent; border: 1px solid #ccc"
    ></iframe>
  `;

  return (
    <ContentWrapper>
      <Box maxW="1024px" mx="auto">
        <Stack p="2rem" isInline>
          <Box>
            <Stack pb="0.5rem" isInline alignItems="center">
              <Icon name="code" />
              <BodyText color="gray.900">Embed form</BodyText>
            </Stack>
            <Subtitle pb="0.5rem">{list?.name}</Subtitle>
            <CodeSnippet theme={codeSnippetTheme} syntax="jsx" code={embedSnippet} />
          </Box>
        </Stack>
      </Box>
      <Box height="100%" bg="gray.200">
        <Box maxW="1024px" mx="auto">
          <Stack p="2rem" isInline>
            <Box w="660px">
              <BodyText pb="0.5rem" color="gray.900">
                Desktop embed preview
              </BodyText>
              <iframe
                height="533"
                width="100%"
                src={formLink}
                frameBorder="0"
                className="simpu-embed"
                title="simpu-embed-desktop"
                style={{ background: 'transparent', border: '1px solid #ccc' }}
              ></iframe>
            </Box>
            <Box w="344px">
              <BodyText pb="0.5rem" color="gray.900">
                Mobile embed preview
              </BodyText>
              <iframe
                height="533"
                width="100%"
                src={formLink}
                frameBorder="0"
                className="simpu-embed"
                title="simpu-embed-mobile"
                style={{ background: 'transparent', border: '1px solid #ccc' }}
              ></iframe>
            </Box>
          </Stack>
        </Box>
      </Box>
    </ContentWrapper>
  );
};
