import {
  Box,
  Flex,
  Icon,
  IconButton,
  PseudoBox,
  Stack,
  TabPanel,
  TabPanels,
  Tooltip,
  useClipboard,
} from '@chakra-ui/core';
import {
  BodyText,
  FileUploader,
  SmallSubtitle,
  SmallText,
  Subtitle,
  Tab,
  Tabs,
  useResellerSettings,
} from 'app/components';
import { FormikHandlers, FormikHelpers, FormikState } from 'formik';
import unescape from 'lodash/unescape';
import styled from '@emotion/styled';
import root from 'react-shadow';
import React, { useCallback, useEffect, useState } from 'react';
import { CampaignPayload, EmailTemplate } from '../../campaigns.types';
import { EmailTemplatePreview } from './email-template-preview';
import { track } from 'utils/segment';

type EmailCampaignSetupSelectTemplateProps = {
  onCancel?: () => void;
  templates: EmailTemplate[];
  onSubmit: FormikHandlers['handleSubmit'];
  setFieldValue?: FormikHelpers<Partial<CampaignPayload>>['setFieldValue'];
  values: FormikState<Partial<CampaignPayload>>['values'];
};

export const EmailCampaignSetupSelectTemplate = (props: EmailCampaignSetupSelectTemplateProps) => {
  const { values, onSubmit, templates, setFieldValue } = props;

  const [selectedTemplate, setSelectedTemplate] = useState<EmailTemplate | undefined>();

  const { name } = useResellerSettings();

  const handleTemplateSelect = useCallback(
    (template: EmailTemplate) => {
      if (values.json && template?.id === values.external_template_id) {
        setFieldValue?.('json', values.json);
      } else {
        setFieldValue?.('json', template?.json);
      }
      if (values.content && template?.id === values.external_template_id) {
        setFieldValue?.('content', unescape(values.content));
      } else {
        setFieldValue?.('content', unescape(template?.html));
      }
      setSelectedTemplate(template);
      setFieldValue?.('emailTemplateType', template?.json ? 'template' : 'code');
      setFieldValue?.('external_template_id', template?.id);
      track('Email Campaign Template Selected', { name: template.name });
    },
    [setFieldValue, values],
  );

  const handleCodeTypeSelect = (content?: string | ArrayBuffer | null) => {
    setFieldValue?.('content', content ?? '');
    setFieldValue?.('emailTemplateType', 'code');
    onSubmit();
  };

  const handleUploadHTMLFile = (file: File) => {
    const reader = new FileReader();
    reader.onload = e => {
      handleCodeTypeSelect(e?.target?.result);
    };
    reader.readAsText(file);
  };

  useEffect(() => {
    if (values?.external_template_id) {
      const template = templates.find(({ id }) => id === values?.external_template_id);
      if (template) {
        handleTemplateSelect(template);
      }
    } else {
      handleTemplateSelect(templates[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Box bg="white" height="100%" display="flex" flexDirection="column" overflow="hidden">
        <Subtitle px="2.5rem" pt="1.5rem" pb="0.5rem">
          Choose a starting point
        </Subtitle>
        <Tabs
          mb="0rem"
          flex={1}
          height="100%"
          overflow="hidden"
          display="flex"
          flexDirection="column"
          tabListProps={{
            px: '2.5rem',
          }}
          defaultIndex={values.emailTemplateType === 'template' ? 0 : 1}
          tabPanels={
            <TabPanels flex={1} overflow="hidden" height="100%">
              <TabPanel height="100%">
                <Stack height="100%" isInline spacing="0">
                  <Box
                    py="2rem"
                    px="1rem"
                    bg="white"
                    pb="0"
                    maxW="400px"
                    width="400px"
                    height="100%"
                    overflowY="auto"
                  >
                    <Stack pb="1rem">
                      <SmallSubtitle>New</SmallSubtitle>
                      <PseudoBox
                        as="button"
                        width="100%"
                        display="flex"
                        p="0.5rem 1rem"
                        textAlign="left"
                        alignItems="center"
                        borderBottomWidth="1px"
                        borderBottomColor="gray.100"
                        justifyContent="space-between"
                        _hover={{ bg: 'gray.100', rounded: '8px' }}
                        onClick={() => {
                          setFieldValue?.('json', '');
                          setFieldValue?.('content', '');
                          setFieldValue?.('emailTemplateType', 'template');
                          onSubmit();
                        }}
                      >
                        <BodyText fontWeight="normal">Start from scratch</BodyText>
                      </PseudoBox>
                    </Stack>
                    <Stack pb="1rem">
                      <SmallSubtitle>{name} templates</SmallSubtitle>
                      {templates
                        .filter(template => !template.organisation_id)
                        .map(template => (
                          <TemplateItem
                            key={template.id}
                            template={template}
                            onClick={() => handleTemplateSelect(template)}
                            isSelected={template.id === selectedTemplate?.id}
                          />
                        ))}
                    </Stack>
                    <Stack>
                      <SmallSubtitle>Recently used templates</SmallSubtitle>
                      {templates
                        .filter(template => template.organisation_id)
                        .map(template => (
                          <TemplateItem
                            isCustom={true}
                            key={template.id}
                            template={template}
                            onClick={() => handleTemplateSelect(template)}
                            isSelected={template.id === selectedTemplate?.id}
                          />
                        ))}
                    </Stack>
                  </Box>
                  <Box
                    flex={1}
                    py="2rem"
                    pb="0"
                    px="8rem"
                    bg="#f7f8fa"
                    height="100%"
                    overflowY="auto"
                  >
                    <root.div>
                      <EmailTemplatePreview content={unescape(selectedTemplate?.html ?? '')} />
                      <style type="text/css">
                        {`
                          * { 
                            font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; 
                          }
                        `}
                      </style>
                    </root.div>
                  </Box>
                </Stack>
              </TabPanel>
              <TabPanel height="100%">
                <Box p="2.5rem" height="100%">
                  <Stack height="100%" isInline>
                    <Box width="220px">
                      <CodeTypeItem
                        label="Paste in code"
                        caption="Paste your own custom coded design"
                        onClick={() =>
                          handleCodeTypeSelect(
                            values.content && values.emailTemplateType !== 'template'
                              ? values.content
                              : '',
                          )
                        }
                      />
                    </Box>
                    <Box width="220px">
                      <FileUploader
                        accept="text/html"
                        onUpload={files => handleUploadHTMLFile(files[0])}
                      >
                        <CodeTypeItem
                          label="Import HTML"
                          caption="Upload an HTML file with your template code"
                        />
                      </FileUploader>
                    </Box>
                  </Stack>
                </Box>
              </TabPanel>
            </TabPanels>
          }
        >
          <Tab px="1rem">
            <BodyText>Templates</BodyText>
          </Tab>
          <Tab px="1rem">
            <BodyText>Code your own</BodyText>
          </Tab>
        </Tabs>
      </Box>
    </>
  );
};

const TemplateItem = (props: {
  isCustom?: boolean;
  isDisabled?: boolean;
  isSelected?: boolean;
  onClick?: () => void;
  template: EmailTemplate;
}) => {
  const { onClick, template, isCustom = false, isSelected } = props;

  const { onCopy, hasCopied } = useClipboard(template.id);

  return (
    <TemplateItemContainer
      as="button"
      width="100%"
      display="flex"
      p="0.5rem 1rem"
      textAlign="left"
      onClick={onClick}
      alignItems="center"
      borderBottomWidth="1px"
      borderBottomColor="gray.100"
      justifyContent="space-between"
      rounded={isSelected ? '8px' : '0'}
      _hover={{ bg: 'gray.100', rounded: '8px' }}
      bg={isSelected ? 'gray.100' : 'transparent'}
    >
      <Stack isInline alignItems="center">
        {isSelected && <Icon size="0.8rem" name="check-circle" color="green.500" />}
        <BodyText fontWeight={isSelected ? 'bold' : 'normal'}>{template.name}</BodyText>
      </Stack>
      {isCustom && (
        <Tooltip label="Copy template id" aria-label="Copy template id" placement="bottom">
          <IconButton
            size="sm"
            opacity={0}
            variant="ghost"
            onClick={e => {
              e.stopPropagation();
              onCopy?.();
            }}
            aria-label="copy"
            className="copy-button"
            transition="opacity 0.2s ease-in"
            icon={hasCopied ? 'check-circle' : 'copy'}
          />
        </Tooltip>
      )}
    </TemplateItemContainer>
  );
};

const CodeTypeItem = ({
  label,
  caption,
  onClick,
  isDisabled,
  icon = 'code',
}: {
  icon?: string;
  label: string;
  caption: string;
  isDisabled?: boolean;
  onClick?: () => void;
}) => {
  return (
    <CodeTypeItemContainer
      as="button"
      onClick={onClick}
      aria-disabled={isDisabled}
      _disabled={{ bg: 'gray.50', cursor: 'not-allowed' }}
    >
      <Flex
        p="0.5rem"
        mb="0.5rem"
        rounded="4px"
        height="10rem"
        borderWidth="1px"
        alignItems="center"
        borderColor="gray.300"
        justifyContent="center"
        className="button-box"
      >
        <Icon size="3rem" name={icon} color="gray.500" />
      </Flex>
      <BodyText fontWeight="bold" textAlign="center" pb="0.5rem">
        {label}
      </BodyText>
      <SmallText color="gray.500" textAlign="center">
        {caption}
      </SmallText>
    </CodeTypeItemContainer>
  );
};

const TemplateItemContainer = styled(PseudoBox)`
  &:hover {
    .copy-button {
      opacity: 1;
    }
  }
`;

const CodeTypeItemContainer = styled(PseudoBox)`
  &:hover {
    .button-box {
      border-color: #3535e6;
    }
  }
  &[aria-disabled='true']:hover {
    .button-box {
      border-color: #dadee3;
    }
  }
`;
