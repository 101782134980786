import React, { useEffect, useRef, useState } from 'react';
import { Input } from 'app/components';

interface Props {
  isInitial: boolean;
  isEditing: boolean;
  value: string;
  onChange: Function;
  inputProps?: any;
}

export const ListTitleEditable = (props: Props) => {
  const [tempValue, setTempValue] = useState<string>('');
  const inputRef = useRef();

  const { inputProps = {} } = props;

  const handleOnEnterPressed = (event: any) => {
    if (event.key !== 'Enter') return;
    props.onChange(tempValue);
    event.target.blur();
  };

  const handleOnBlur = () => {
    if (tempValue !== props.value) {
      props.onChange(tempValue);
    }
  };

  useEffect(() => {
    setTempValue(props.value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value]);

  const handleInputChange = (e: any) => {
    const value = e.target.value;
    setTempValue(value);
  };

  useEffect(() => {
    if (tempValue === 'Untitled' && inputRef?.current) {
      // @ts-ignore
      inputRef.current?.focus();
    }
  }, [tempValue]);

  return (
    <Input
      isDisabled={props.isInitial}
      value={tempValue}
      size='sm'
      // @ts-ignore
      ref={inputRef}
      className='list-title-input'
      onChange={handleInputChange}
      onBlur={handleOnBlur}
      onKeyPress={handleOnEnterPressed}
      fontWeight='450'
      isInvalid={tempValue === ''}
      width={`${Math.max(150, tempValue?.length * 12)}px`}
      maxWidth='45vw'
      marginRight='15px'
      borderWidth='4px'
      _disabled={{
        color: '#4f4f4f',
        cursor: 'not-allowed',
      }}
      {...inputProps}
    />
  );
};
