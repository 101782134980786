import { ModalBody, ModalCloseButton, Stack } from '@chakra-ui/core';
import { Button, Input, ModalContainer, ModalContainerOptions, SmallText } from 'app/components';
import React, { useEffect, useRef, useState } from 'react';

export const FundAccountModal = (
  props: ModalContainerOptions & {
    isLoading: boolean;
    organisationName: string;
    onSubmit: (amount: number, callback: () => void) => void;
  },
) => {
  const { organisationName, isLoading, onSubmit, ...rest } = props;

  const inputRef = useRef<HTMLDivElement>(null);

  const [amount, setAmount] = useState<number | undefined>();

  const handleClearForm = () => {
    setAmount(undefined);
  };

  const handleSubmit = () => {
    onSubmit(amount ?? 0, handleClearForm);
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  return (
    <ModalContainer
      {...rest}
      initialFocusRef={inputRef}
      title={`Fund ${organisationName} with campaign credits`}
    >
      <ModalCloseButton size="sm" />
      <ModalBody pb="2rem">
        <Stack>
          <Input
            id="amount"
            name="amount"
            type="number"
            label="Amount"
            value={amount}
            ref={inputRef}
            placeholder="Enter amount to fund"
            isInvalid={!!(amount && amount < 50)}
            errorMessage="Minimum fund amount is N50"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setAmount(e.target.valueAsNumber)}
          />
          <SmallText color="gray.400">
            The minimum amount you can fund this account with is N50
          </SmallText>
          <Button
            variantColor="blue"
            isLoading={isLoading}
            onClick={handleSubmit}
            isDisabled={!amount || amount < 50}
          >
            Fund account
          </Button>
        </Stack>
      </ModalBody>
    </ModalContainer>
  );
};
