import { Stack, StackProps } from '@chakra-ui/core';
import React from 'react';
import { LogoWithText } from '../Logo';
import { BodyText } from '../Typography';

export const SimpuWatermark: React.FC<{ containerProps?: StackProps; logoProps?: any }> = ({
  logoProps,
  containerProps,
}) => {
  return (
    <Stack
      isInline
      spacing="0.2rem"
      alignItems="center"
      justifyContent="center"
      {...containerProps}
    >
      <BodyText fontStyle="500">Powered by</BodyText>
      <LogoWithText
        width="70px"
        style={{
          top: '3px',
          position: 'relative',
        }}
        {...logoProps}
      />
    </Stack>
  );
};
