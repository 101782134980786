import React from 'react';
import { Box } from '@chakra-ui/core';
import { CustomCellWrapper as Wrapper } from '../../grid/custom-cell/index.styles';

export const LongTextCell = (props: any) => {
  return (
    <Wrapper>
      <Box
        className="long-text-cell"
        // commented out on 31/05/2023 as potential fix for longtext cell not editable
        // onClick={e => e.stopPropagation()}
      >
        <Box>{props.value}</Box>
      </Box>
    </Wrapper>
  );
};
