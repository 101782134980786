import React, { createContext, useEffect, useRef, useState } from "react";

export const initialParamState = [
    {
        id: 0, 
        isWritten: false,
        isDisabled: false,
        key_text: '',
        value_text: '',
        description_text: '',
    }
]

interface ParamsStateProps {
    id: number;
    isWritten: boolean;
    isDisabled: boolean;
    key_text: string;
    value_text: string;
    description_text: string;
};

const initialDropDownOptions = [
    {
        id: 0,
        label: 'Next Page Link',
        value: 'nextPageLink',
        explanation: 'The URL of the next page',
        selected: false
    },
    {
        id: 1,
        label: 'Pagination Parameter',
        value: 'paginationParameter',
        explanation: 'URL pagination parameter',
        selected: false
    },
    {
        id: 2,
        label: 'Next Page Link',
        value: 'nextPageLink',
        explanation: 'The URL of the next page',
        selected: false
    }
]

export interface DropDownOptionsProps {
    id: number,
    label: string,
    value: string,
    explanation: string,
    selected: boolean
};

export interface RequestArrayProps {
    id: number; 
    current: boolean;
};

interface Props {
    paramsState: ParamsStateProps[][];
    setParamsState: (paramsState: ParamsStateProps[][]) => void;

    rowId: number[];
    setRowId: (rowId: number[]) => void;

    currentCell: Array<number | string>[];
    setCurrentCell: (currentCell: Array<number | string>[]) => void;

    createNewList: (previousRow: ParamsStateProps, n: number) => void;
    setText: (e: any, rowId: number, text: never, key: keyof typeof initialParamState[0], n: number) => void;

    cursorPosition: Array<number>[];
    setCursorPosition: (cursorPosition: Array<number>[]) => void;

    urlText: string[];
    setUrlText: (urlText: string[]) => void;

    totalText: string[];
    setTotalText: (totalText: string[]) => void;

    userText: string[];
    setUserText: (userText: string[]) => void;

    focusRef: React.MutableRefObject<Array<HTMLDivElement | null>>;
    urlParser: (n: number) => void;

    dropDown: Array<Boolean | number>[];
    setDropDown: Function;

    dropDownOptions: DropDownOptionsProps[];
    setDropDownOptions: (dropDownOptions: DropDownOptionsProps[]) => void;

    requestArray: RequestArrayProps[];
    setRequestArray: (requestArray: RequestArrayProps[]) => void;

    focusState: boolean;
    setFocusState: (focusState: boolean) => void;

    nameRef: React.MutableRefObject<Array<HTMLDivElement | null>>;
    urlRef: React.MutableRefObject<Array<HTMLDivElement | null>>;

    requestName: string[];
    setRequestName: (requestName: string[]) => void;

    requestInputFocus: boolean;
    setRequestInputFocus: (requestInputFocus: boolean) => void;

    relativeInputFocus: boolean;
    setRelativeInputFocus: (relativeInputFocus: boolean) => void;

    requestCursorPosition: number[];
    setRequestCursorPosition: (requestCursorPosition: number[]) => void;

    relativeCursorPosition: number[];
    setRelativeCursorPosition: (relativeCursorPosition: number[]) => void;

    expandedState: number;
    setExpandedState: (expandedState: number) => void;

    requestFocusRef: React.MutableRefObject<any>;
    relativeFocusRef: React.MutableRefObject<any>;

    requestMethod: string[];
    setRequestMethod: (requestMethod: string[]) => void;
};

export const WebhooksHeadersContext = createContext({} as Props);

const WebhooksHeadersContextProvider = (props: any) => {
    
    const [paramsState, setParamsState] = useState([initialParamState])
    const [rowId, setRowId] = useState([0])
    const [currentCell, setCurrentCell] = useState([[0, 0, '']])
    const [cursorPosition, setCursorPosition] = useState([[0, 0]])
    const [urlText, setUrlText] = useState(['']);
    const [totalText, setTotalText] = useState(['']);
    const [userText, setUserText] = useState(['']);
    const [dropDown, setDropDown] = useState([[false, 0, 0]]);
    const focusRef = useRef<Array<HTMLDivElement | null>>([]);
    const [dropDownOptions, setDropDownOptions] = useState(initialDropDownOptions)
    const [requestArray, setRequestArray] = useState([{
        id: 0,
        current: false,
    }])
    const [focusState, setFocusState] = useState(false)
    const nameRef = useRef<Array<HTMLDivElement | null>>([]);
    const urlRef = useRef<Array<HTMLDivElement | null>>([]);
    const [requestName, setRequestName] = useState([''])
    const [requestInputFocus, setRequestInputFocus] = useState(false)
    const [relativeInputFocus, setRelativeInputFocus] = useState(false)
    const [requestCursorPosition, setRequestCursorPosition] = useState([0,0])
    const [relativeCursorPosition, setRelativeCursorPosition] = useState([0,0])
    const [expandedState, setExpandedState] = useState(0)
    const requestFocusRef = useRef<any>();
    const relativeFocusRef = useRef<any>();
    const [requestMethod, setRequestMethod] = useState([''])

    useEffect(() => {
        setParamsState([[
            {
                id: 0, 
                isWritten: false,
                isDisabled: false,
                key_text: '',
                value_text: '',
                description_text: '',
            }
        ]])
        
    }, []);

    const urlParser = (n: number) => {
        let keyText;
        let valueText;
        let parsedText = '';
        let firstTaken = false;

        paramsState[n].forEach((item, index, array) => {
            if (item.isWritten && !item.isDisabled) {
                if (!firstTaken) {
                    keyText = '?' + item.key_text;
                    parsedText = '';
                    valueText = typeof item.value_text === 'string' ? '=' + item.value_text : '';
                    parsedText += keyText+valueText;
                    firstTaken = true;
                } else {
                    keyText = typeof item.key_text === 'string' ? '&' + item.key_text : '';
                    valueText = typeof item.value_text === 'string' ? '=' + item.value_text : '';
                    parsedText += keyText+valueText;
                };
            };
            
        });
        urlText[n] = parsedText;
        setUrlText([...urlText]);
    };

    const createNewList = (previousRow: ParamsStateProps, n: number) => {
        
        paramsState[n][paramsState[n].length-1].isWritten = true;

        rowId[n] = paramsState[n].length;
        setRowId([...rowId]);

        paramsState[n] = paramsState[n].concat({id: paramsState[n].length, isWritten: false, isDisabled: false, key_text: '', value_text: '', description_text: ''});

        setParamsState([...paramsState]);
    };

    const setText = (e: any, rowId: number, text: never, key: keyof typeof initialParamState[0], n: number) => {
        
        paramsState[n][rowId][key] = text;
        setParamsState([...paramsState]);
    };

    
    const value = {paramsState, setParamsState, rowId, setRowId, currentCell, 
        setCurrentCell, createNewList, setText, cursorPosition, setCursorPosition, 
        urlText, setUrlText, totalText, setTotalText, userText, setUserText, focusRef, 
        urlParser, dropDown, setDropDown, dropDownOptions, setDropDownOptions, requestArray,
        setRequestArray, focusState, setFocusState, nameRef, urlRef, requestName, setRequestName,
        requestInputFocus, setRequestInputFocus, relativeInputFocus, setRelativeInputFocus,
        requestCursorPosition, setRequestCursorPosition, relativeCursorPosition, setRelativeCursorPosition,
        expandedState, setExpandedState, requestFocusRef, relativeFocusRef, requestMethod, setRequestMethod
    };

    return (
        <WebhooksHeadersContext.Provider value={value}>
            {props.children}
        </WebhooksHeadersContext.Provider>
    )
};

export default WebhooksHeadersContextProvider
 