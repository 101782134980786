import React from 'react';
import Editor from 'react-simple-code-editor';
//@ts-ignore
import { highlight as prismHighlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import 'prismjs/themes/prism.css';

export type CodeEditorProps = {
  value: string;
  language?: any;
  className?: string;
  style?: React.CSSProperties;
  onValueChange: (value: string) => void;
  highlight?: (value: string) => string | React.ReactNode;
};

export function CodeEditor(props: CodeEditorProps) {
  const {
    value,
    style,
    highlight,
    onValueChange,
    language = languages.js,
    className = 'code-editor',
    ...rest
  } = props;

  const hightlightWithLineNumbers = (input: string) =>
    prismHighlight(input, language)
      .split('\n')
      .map((line: string, i: number) => `<span class='editorLineNumber'>${i + 1}</span>${line}`)
      .join('\n');

  return (
    <Editor
      value={value}
      style={{
        fontSize: 14,
        fontFamily: '"Fira code", "Fira Mono", monospace',
        ...style,
      }}
      className={className}
      textareaId="codeEditor"
      onValueChange={onValueChange}
      highlight={highlight ?? hightlightWithLineNumbers}
      {...rest}
    />
  );
}
