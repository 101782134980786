import { Stack } from '@chakra-ui/core';
import { Button, ConfirmModal, EmptyState, TableDropdownAction } from 'app/components';
import React from 'react';
import { SenderProfilePageProp } from '../../settings.types';
import {
  SMSSenderProfileItem,
  SMSSenderProfileLoadingItem,
  SMSSenderProfileListHeader,
} from './sms-sender-profile-item';
import { SMSSenderProfileModal, SMSSenderProfilePayload } from './sms-sender-profile-modal';

export const SMSSenderProfiles = (props: SenderProfilePageProp<SMSSenderProfilePayload>) => {
  const {
    senderProfiles,
    onAddSenderProfile,
    senderProfileToEdit,
    senderProfileToDelete,
    isLoadingSenderProfiles,
    isUpdatingSenderProfile,
    isDeletingSenderProfile,
    setSenderProfileToDelete,
    setSenderProfileToEdit,
    onUpdateSenderProfile,
    onDeleteSenderProfile,
    senderProfileToSetAsDefault,
    onSetSenderProfileAsDefult,
    isSettingSenderProfileAsDefault,
    setSenderProfileToSetAsDefault,
  } = props;

  const tableActions = (
    item: SMSSenderProfilePayload,
  ): TableDropdownAction<SMSSenderProfilePayload>[] => [
    { label: 'Edit sender profile', icon: 'edit', onClick: setSenderProfileToEdit },
    { label: 'Use as default', icon: 'star', onClick: setSenderProfileToSetAsDefault },
    { label: 'Delete sender profile', icon: 'trash', onClick: setSenderProfileToDelete },
  ];

  if (isLoadingSenderProfiles) {
    return (
      <Stack>
        <SMSSenderProfileListHeader />
        {Array.from({ length: 10 }, (v, i) => (
          <SMSSenderProfileLoadingItem key={`${i}`} />
        ))}
      </Stack>
    );
  }

  if (!senderProfiles?.length) {
    return (
      <EmptyState
        width="320px"
        marginLeft="0"
        height="50vh"
        heading="No SMS Sender Profiles"
        subheading="Standout in your customer's inbox with unique sender ids for your organisation."
      >
        <Button size="sm" variantColor="blue" onClick={onAddSenderProfile}>
          Add SMS Sender Profile
        </Button>
      </EmptyState>
    );
  }

  return (
    <>
      <Stack>
        <SMSSenderProfileListHeader />
        {senderProfiles?.map((item: any) => (
          <SMSSenderProfileItem
            data={item}
            actions={tableActions(item)}
            onClick={() => setSenderProfileToEdit(item)}
          />
        ))}
      </Stack>
      {!!senderProfileToEdit && (
        <SMSSenderProfileModal
          isOpen={!!senderProfileToEdit}
          onSubmit={onUpdateSenderProfile}
          initialValues={senderProfileToEdit}
          isLoading={!!isUpdatingSenderProfile}
          onClose={() => setSenderProfileToEdit(undefined)}
        />
      )}
      {!!senderProfileToDelete && (
        <ConfirmModal
          title="Delete Sender Profile"
          isOpen={!!senderProfileToDelete}
          isLoading={!!isDeletingSenderProfile}
          onClose={() => setSenderProfileToDelete(undefined)}
          onConfirm={() => onDeleteSenderProfile(senderProfileToDelete)}
        />
      )}
      {!!senderProfileToSetAsDefault && (
        <ConfirmModal
          title="Set Default  Sender Profile"
          isOpen={!!senderProfileToSetAsDefault}
          isLoading={!!isSettingSenderProfileAsDefault}
          onClose={() => setSenderProfileToSetAsDefault(undefined)}
          onConfirm={() => onSetSenderProfileAsDefult(senderProfileToSetAsDefault)}
        />
      )}
    </>
  );
};
