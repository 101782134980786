import React, { useEffect, useState } from 'react';
import { Box, IconButton, Image, Input, Select, Spinner } from '@chakra-ui/core';
import camelCase from 'lodash/camelCase';
import { Button } from 'app/components';
import { PropertySchema, useProperties } from '../../../../../../../tables/components';
import { ListService } from '../../../../../../service';

import enrichImage from '../../../../../../assets/enrich.svg';

interface Props {
  importedData: any;
  handleCreateTable: Function;
  dataSample: any;
}

export const CreateListImportMapping = (props: Props) => {
  const [createLoading, setCreateLoading] = useState(false);
  const [columnDetectionLoading, setColumnDetectionLoading] = useState(false);

  const columns = props.importedData?.columns?.map(({ name }: any) => ({
    label: name.trim(),
    hidden: false,
    type: 'TEXT',
    name: name.trim().toLowerCase(),
  })) as PropertySchema[];

  const { properties, onPropertyUpdate, onPropertyDelete, resetProperties } = useProperties({
    properties: columns,
  });

  const handleCreate = async () => {
    setCreateLoading(true);
    await props.handleCreateTable(properties);
    setCreateLoading(false);
  };

  const detectColumns = async (dataSample: any) => {
    if (dataSample == null) return;
    setColumnDetectionLoading(true);
    try {
      const detectionResult = await ListService.detectColumns(dataSample);

      Object.keys(detectionResult).forEach((i: string) => {
        detectionResult[i.trim().toLowerCase()] = detectionResult[i];
      });

      const newProperties: any[] = [];
      properties.forEach((property: any) => {
        let detectedType = detectionResult?.[property.name] || property.type;
        detectedType = detectedType.toUpperCase();
        if (detectedType === 'SOCIAL MEDIA') {
          detectedType = 'TEXT'
        }
        newProperties.push({
          ...property,
          type: detectedType,
        });
      });
      resetProperties(newProperties);
    } catch (e) {
      console.log(e);
    }
    setColumnDetectionLoading(false);
  };

  useEffect(() => {
    detectColumns(props.dataSample);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {columnDetectionLoading && (
        <Box display="flex" flexDirection="column" alignItems="center">
          <Box>
            <Image src={enrichImage} height="120px" />
          </Box>

          <Box marginY={2} fontSize="14px">
            We're just running some column auto-detection on your data
          </Box>

          <Box>
            <Spinner
              display="flex"
              justifySelf="center"
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="blue.500"
              size="lg"
              margin="30% auto"
            />
          </Box>
        </Box>
      )}

      {!columnDetectionLoading && (
        <>
          <Box className="description">
            Set the columns from your file as properties to create a new list
          </Box>

          <Box display="flex" flexDirection="column" marginY="20px">
            {properties?.map((property: any, index: number) => (
              <Box
                key={index}
                display="flex"
                flexDirection="row"
                width="100%"
                justifyContent="space-between"
                alignItems="center"
                marginY="7.5px"
              >
                <Input
                  width="45%"
                  padding="5px 12.5px"
                  backgroundColor="#FAFAFA"
                  size="sm"
                  borderRadius="5px"
                  value={property.label}
                  onChange={(e: any) =>
                    onPropertyUpdate(
                      {
                        ...property,
                        label: e.target.value,
                        name: camelCase(e.target.value),
                      },
                      index,
                    )
                  }
                />
                <Select
                  width="45%"
                  padding="5px 10px"
                  backgroundColor="#FAFAFA"
                  borderRadius="5px"
                  size="sm"
                  value={property.type}
                  onChange={(e: any) =>
                    onPropertyUpdate({ ...property, type: e.target.value }, index)
                  }
                >
                  <option value="TEXT">Text</option>
                  <option value="NUMBER">Number</option>
                  <option value="PHONE NUMBER">Phone Number</option>
                  <option value="EMAIL">Email</option>
                  <option value="DATE">Date</option>
                  <option value="URL">URL</option>
                </Select>

                <IconButton
                  aria-label="delete"
                  // @ts-ignore
                  icon="trash"
                  size="xs"
                  borderRadius="10px"
                  marginRight="1rem"
                  isDisabled={properties?.length === 1}
                  onClick={() => onPropertyDelete(index)}
                />
              </Box>
            ))}
          </Box>

          <Box mt="10px" width="fit-content">
            <Button
              variant="solid"
              variantColor="blue"
              size="sm"
              isLoading={createLoading}
              onClick={handleCreate}
            >
              Create Table
            </Button>
          </Box>
        </>
      )}
    </>
  );
};
