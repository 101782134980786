import React from 'react';
import { Avatar, Box } from '@chakra-ui/core';
import { OrganizationPickerProps } from '../types';
import { loadState } from '../../../../utils';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../../root';
import { authLoading, fetchProfile } from '../../../unauthenticated-app/authentication/service';
import { Organization } from '../../../authenticated-app/settings/settings.types';
import { useHistory } from 'react-router-dom';
import { useFlagsmith } from 'flagsmith/react';

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & OrganizationPickerProps;

const connector = connect(
  (state: RootState) => ({
    profile: state.auth.profile,
    isLoading: state.auth.loading,
  }),
  {
    authLoading,
    fetchProfile,
  },
);

export const OrganizationPicker = connector((props: Props) => {
  const { organizations, profile, authLoading, fetchProfile } = props;

  const history = useHistory();
  const flagsmith = useFlagsmith();

  const reloadOrganization = async (id: string) => {
    if (!!loadState() && id !== profile?.organisation_id) {
      try {
        authLoading(true);
        const { user, profile } = await fetchProfile(id, { organisationID: id });

        flagsmith.identify(user?.id, {
          ...profile,
          email: user.email,
          app: 'marketing',
        });
        // authLoading(false);
      } catch (e) {}

      if (window.location.pathname.includes('list')) {
        history.push('/s/lists/lists');
      } else if (window.location.pathname.includes('developers')) {
        history.push('/s/developers/sms-logs');
      } else if (window.location.pathname.includes('marketing')) {
        history.push('/s/marketing/dashboard');
      } else {
        history.push('/s/lists/lists');
      }

      window.location.reload();
    }
  };

  return (
    <div className="section-organizations">
      <Box marginBottom="10px" fontSize="14px" color="#757575">
        Organizations
      </Box>

      {organizations.map((i: Organization) => (
        <Box
          className="organization-item"
          key={i.id}
          fontSize="14px"
          fontWeight={i.id === profile?.organisation_id ? 500 : 400}
          color={i.id === profile?.organisation_id ? '#333333' : '#757575'}
          cursor={i.id === profile?.organisation_id ? 'default' : 'pointer'}
          onClick={() => {
            if (i.id === profile?.organisation_id) return;
            reloadOrganization(i.id);
          }}
        >
          <Avatar size="xs" name={i.name} mr="10px" color="white" src={i.image ?? ''} />
          {i.name}
        </Box>
      ))}
    </div>
  );
});
