import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Stack,
} from '@chakra-ui/core';
import { BodyText, Button, ModalContainerOptions } from 'app/components';
import React, { useRef } from 'react';

export const ConfirmMergeModal: React.FC<
  ModalContainerOptions & { base_list: any; head_list: any; isLoading?: boolean; onConfirm(): void }
> = props => {
  const { isOpen, onClose, isLoading, onConfirm, base_list, head_list } = props;
  const cancelRef = useRef<HTMLButtonElement>(null);
  return (
    <AlertDialog isCentered isOpen={isOpen} onClose={onClose} leastDestructiveRef={cancelRef}>
      <AlertDialogOverlay />
      <AlertDialogContent rounded="16px">
        <AlertDialogHeader fontSize="lg" fontWeight="bold">
          Merge lists
        </AlertDialogHeader>
        <AlertDialogBody>
          <Stack>
            <BodyText>
              Are you sure you'll like to merge <strong>{head_list.name}</strong> list into{' '}
              <strong>{base_list.name}</strong> list?
            </BodyText>
            <BodyText>
              Carrying out this action will result in both lists being merged and the
              <strong> {head_list.name}</strong> list will be deleted permanently.
            </BodyText>
            <BodyText fontWeight="bold">This action cannot be undone.</BodyText>
          </Stack>
        </AlertDialogBody>

        <AlertDialogFooter>
          <Button size="sm" ref={cancelRef} onClick={onClose}>
            Cancel
          </Button>
          <Button ml={3} size="sm" onClick={onConfirm} variantColor="blue" isLoading={isLoading}>
            Merge lists
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
