import { Box, Button, Flex, ModalBody, ModalCloseButton, Stack } from '@chakra-ui/core';
import { Input, ModalContainer, ModalContainerOptions } from 'app/components';
import { FormikConfig, useFormik } from 'formik';
import React, { useRef } from 'react';
import * as yup from 'yup';

type FormValues = {
  id?: string;
  name: string;
};

export const ResellerSubaccountModal = (
  props: ModalContainerOptions & {
    isLoading?: boolean;
    initialValues?: FormValues;
    onSubmit: FormikConfig<FormValues>['onSubmit'];
  },
) => {
  const { onSubmit, isLoading, initialValues, onClose, ...rest } = props;

  const initialFocusRef = useRef<HTMLInputElement>(null);

  const { values, errors, resetForm, touched, handleChange, handleSubmit } = useFormik<FormValues>({
    onSubmit,
    initialValues: initialValues ?? { name: '' },
    validationSchema: yup.object().shape({
      name: yup.string().required('Subaccount name is required'),
    }),
  });

  const handleCloseModal = () => {
    resetForm();
    onClose?.();
  };

  return (
    <ModalContainer
      onClose={handleCloseModal}
      initialFocusRef={initialFocusRef}
      title={`${!initialValues ? 'Create' : 'Edit'} Subaccount`}
      {...rest}
    >
      <ModalCloseButton size="sm" />
      <ModalBody pb="2rem">
        <form onSubmit={handleSubmit}>
          <Stack spacing="1rem">
            <Box>
              <Input
                size="sm"
                type="text"
                name="name"
                label="Name"
                value={values.name}
                ref={initialFocusRef}
                onChange={handleChange}
                errorMessage={errors.name}
                placeholder="Subaccount name"
                isInvalid={!!(touched.name && errors.name)}
              />
            </Box>
            <Flex justifyContent="flex-end">
              <Button
                size="sm"
                type="submit"
                variantColor="blue"
                isLoading={isLoading}
                alignSelf="flex-start"
              >
                Submit
              </Button>
            </Flex>
          </Stack>
        </form>
      </ModalBody>
    </ModalContainer>
  );
};
