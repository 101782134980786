import { useToast } from '@chakra-ui/core';
import { useFlagsmith } from 'flagsmith/react';
import Cookie from 'js-cookie';
import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { group, identify, track } from 'utils/segment';
import { ToastBox } from '../../../../components';
import { LoginFormInitialValuesProps } from '../../components';
import { fetchProfile, login, saveUser } from '../../service';
import { LoginComponent } from './Login.component';

const connector = connect(null, { saveUser, fetchProfile });

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = RouteComponentProps & PropsFromRedux;

function Login(props: Props) {
  const { history, saveUser, fetchProfile } = props;
  const toast = useToast();
  const [loading, setLoading] = React.useState<boolean>(false);

  const flagsmith = useFlagsmith();

  async function handleLogin(values: LoginFormInitialValuesProps) {
    setLoading(true);
    try {
      Cookie.remove('auth_period');
      sessionStorage.removeItem('auth_valid');
      const data = await login(values);

      saveUser(data);

      if (!values.rememberMe) {
        Cookie.set('auth_period', 'session');
        sessionStorage.setItem('auth_valid', 'true');
      }

      const { profile, user } = await fetchProfile(data.organisations[0].id, {
        organisationID: data.organisations[0].id,
      });

      identify(user?.id ?? '', {
        ...profile,
        app: 'marketing',
        email: user.email,
      });

      flagsmith.identify(user?.id, {
        ...profile,
        email: user.email,
        app: 'marketing',
      });

      group(profile.organisation_id, {
        name: data?.organisations?.find(
          (organisation: any) => organisation.id === profile.organisation_id,
        )?.name,
        app: 'marketing',
      });

      track('Signed In', { ...profile, email: user.email });
      setLoading(false);

      history.push('/s/lists/lists');
    } catch (error: any) {
      setLoading(false);
      toast({
        position: 'bottom-left',
        render: ({ onClose }) => (
          <ToastBox
            onClose={onClose}
            message={error.message ?? 'Sorry, unable to login. Please check your credentials'}
          />
        ),
      });
    }
  }

  return <LoginComponent {...props} onSubmit={handleLogin} isLoading={loading} />;
}

export const LoginContainer = connector(Login);
