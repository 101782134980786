import { Box, Heading, Stack, useDisclosure, useToast } from '@chakra-ui/core';
import { selectWalletData } from 'app/authenticated-app/payments/selectors';
import { Button, Container, SmallText, Subtitle, ToastBox } from 'app/components';
import { FormikHelpers } from 'formik';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { numberWithCommas } from 'utils';
import { CampaignReviewProps, SendCampaignModal, TestMessageModal } from '..';
import { sendTestEmail } from '../../campaigns.service';
import { EmailCampaignSetupTemplateReview } from './email-template-review';

export const EmailCampaignSetupReview = (
  props: CampaignReviewProps & {
    sendCampaignIsOpen?: boolean;
    onCloseSendCampaignModal?: () => void;
    onGoToSection?(section: number): void;
    onSendTestEmail?(): void;
  },
) => {
  const {
    campaign,
    isSaving,
    onSubmit,
    onGoToSection,
    credit_balance,
    sendCampaignIsOpen,
    isSavingDraft,
    onSaveDraft,
    onCloseSendCampaignModal,
  } = props;

  const toast = useToast();
  const {
    isOpen: testMessageModalIsOpen,
    onClose: onCloseTestMessageModal,
    onOpen: onOpenTestMessageModal,
  } = useDisclosure();
  const wallet = useSelector(selectWalletData);

  const [isSendingTestMessage, setIsSendingTestMessage] = useState(false);

  const { count, sender_id, content, subject, from_name, table_id, smart_list_id, reply_to } =
    campaign;

  const handleSaveDraft = () => {
    const { template_type, campaign_id, ...rest } = campaign;
    onSaveDraft({
      ...rest,
      state: 'draft',
      content: campaign.content,
      template_id: template_type === 'built-in' ? null : campaign.template_id,
    });
  };

  const handleSendTestMessage = async (
    { recipients }: { recipients: string },
    { resetForm }: FormikHelpers<{ recipients: string }>,
  ) => {
    if (content) {
      const payload = {
        content,
        table_id,
        recipients,
        smart_list_id,
        subject: subject ?? '',
        reply_to: reply_to ?? '',
        from_name: from_name ?? '',
        sender_id: sender_id ?? '',
      };
      try {
        setIsSendingTestMessage(true);
        await sendTestEmail(payload);
        setIsSendingTestMessage(false);
        onCloseTestMessageModal();
        resetForm();
        toast({
          position: 'bottom-left',
          render: ({ onClose }) => (
            <ToastBox status="success" onClose={onClose} message="Test email sent successfully" />
          ),
        });
      } catch (error) {
        setIsSendingTestMessage(false);
        toast({
          position: 'bottom-left',
          render: ({ onClose }) => <ToastBox onClose={onClose} message={error} />,
        });
      }
    }
  };

  return (
    <Box pt="2rem" bg="white" height="100vh" overflowY="auto">
      <Container px="2rem" maxW="1200px">
        <Subtitle pb="1rem">Review your campaign</Subtitle>
        <Box flexWrap="wrap" display="flex" justifyContent="space-between">
          <Box width="48%">
            <Stack
              py="1rem"
              isInline
              spacing="0.2rem"
              borderBottomWidth="1px"
              justifyContent="space-between"
            >
              <Stack>
                <Stack>
                  <Heading fontSize="0.875rem" fontWeight={600}>
                    Estimated recipients
                  </Heading>
                  <SmallText fontSize="1.5rem" fontWeight={600}>
                    {numberWithCommas(count)}
                  </SmallText>
                </Stack>
                <Stack>
                  <Heading fontSize="0.875rem" fontWeight={600}>
                    Estimated cost
                  </Heading>
                  <SmallText color="green.500" fontSize="1.5rem" fontWeight={600}>
                    &#8358;
                    {numberWithCommas(
                      (count ?? 0) * (wallet.pricing['breakdown']['NG']['email'] / 100),
                    )}
                  </SmallText>
                </Stack>
              </Stack>
              <Button size="sm" variant="outline" onClick={() => onGoToSection?.(1)}>
                Edit recipients
              </Button>
            </Stack>
            <Stack
              py="1rem"
              isInline
              alignItems="center"
              borderBottomWidth="1px"
              justifyContent="space-between"
            >
              <Box>
                <Heading fontSize="0.875rem" fontWeight={600}>
                  Campaign design
                </Heading>
              </Box>
              <Button size="sm" variant="outline" onClick={() => onGoToSection?.(1)}>
                Edit design
              </Button>
            </Stack>
            <Stack
              py="1rem"
              isInline
              alignItems="center"
              borderBottomWidth="1px"
              justifyContent="space-between"
            >
              <Box>
                <Heading fontSize="0.875rem" fontWeight={600}>
                  Test & save campaign
                </Heading>
              </Box>
              <Stack isInline alignItems="center">
                <Button size="sm" variant="outline" onClick={onOpenTestMessageModal}>
                  Send a test email
                </Button>
                {(!campaign.state ||
                  campaign.state === 'pristine' ||
                  campaign.state === 'draft') && (
                  <Button
                    size="sm"
                    variant="outline"
                    isLoading={isSavingDraft}
                    onClick={handleSaveDraft}
                  >
                    Save campaign as draft
                  </Button>
                )}
              </Stack>
            </Stack>
          </Box>
          <Box width="48%">
            <EmailCampaignSetupTemplateReview campaign={campaign} />
          </Box>
        </Box>
        <SendCampaignModal
          type="email"
          campaign={campaign}
          isLoading={isSaving}
          isOpen={sendCampaignIsOpen}
          onCreateCampaign={onSubmit}
          credit_balance={credit_balance}
          onClose={onCloseSendCampaignModal}
        />
        <TestMessageModal
          type="email"
          isOpen={testMessageModalIsOpen}
          credit_balance={credit_balance}
          isLoading={isSendingTestMessage}
          onSubmit={handleSendTestMessage}
          onClose={onCloseTestMessageModal}
        />
      </Container>
    </Box>
  );
};
