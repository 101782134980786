import { Box, Stack } from '@chakra-ui/core'
import React, { useContext } from 'react'
import { useDispatch } from 'react-redux'
import { AppHeadersContext } from '../../../contexts/app-contexts/headers.context'
import { AppParamsContext } from '../../../contexts/app-contexts/params.context'
import { RequestHeadersContext } from '../../../contexts/request-contexts/headers.context'
import { RequestParamsContext } from '../../../contexts/request-contexts/params.context'
import { fetchCustomAppSchema, testCustomRequest } from 'app/authenticated-app/lists/thunks/resources';

interface Props {
    titleHeadings: Array<string>;
    selectedTitle: Array<string>;
    setSelectedTitle: Function;
    disabledItem?: string;
    index: number;
}

const Titles = (props: Props) => {
    const dispatch = useDispatch();
    const {setJsonResponse, jsonResponse, setResponseBody, responseBody, userText, urlText, paramsState, requestBody, 
        requestMethod, testResultResponse, setTestResultResponse, isJsonAvailableResponse, setIsJsonAvailableResponse, 
        arrayKey, isResponseSelectorValid, setIsResponseSelectorValid, schemaResponse, setSchemaResponse} = useContext(RequestParamsContext)
    const {paramsState: appParamsState, authenticationType, basicAuthUserName, basicAuthPassword, userText: appUserText, urlText: appUrlText, RadioImageValue} = useContext(AppParamsContext);
    const {paramsState: appHeadersParamState} = useContext(AppHeadersContext);
    const {paramsState: headersParamState} = useContext(RequestHeadersContext);

    const testRequest = async (n: number) => {
        const requestTestData = {
            appUserText,
            appUrlText,
            authenticationType,
            basicAuthUserName,
            basicAuthPassword,
            appParamsState,
            appHeadersParamState,
            requestUserText: userText[n],
            requestUrlText: urlText[n],
            requestParamsState: paramsState[n],
            requestHeadersParamState: headersParamState[n],
            requestBody: requestBody[n],
            requestMethod: requestMethod[n],
        }
        const data = (await dispatch(testCustomRequest(requestTestData)) as any);

        if (data[0]) {
            testResultResponse[n] = [true, true]
            setTestResultResponse([...testResultResponse])
        } else {
            testResultResponse[n] = [true, false]
            setTestResultResponse([...testResultResponse])
        }

        jsonResponse[n] = data[1]
        setJsonResponse([...jsonResponse])

        if (data[1]) {
            isJsonAvailableResponse[n] = true
            setIsJsonAvailableResponse([...isJsonAvailableResponse])
        } else {
            isJsonAvailableResponse[n] = false
            setIsJsonAvailableResponse([...isJsonAvailableResponse])
        }

    };

    const fetchSchema = async () => {
        const schemaData = (await dispatch(fetchCustomAppSchema(responseBody[props.index])) as any);
        schemaResponse[props.index] = schemaData;
        setSchemaResponse([...schemaResponse]);
    };

    const arrayFetcher = (responseObject: any, key: string) => {
        const subKeys = key.split('.')
        let node = responseObject;
        for(let index in subKeys) {
            key = subKeys[index];
            if(key in node) {
                node = node[key];
            } else {
                node = null;
                break;
            }
    }
        return node;
    };

    const clickHandler = async (heading: string) => {
        if (heading === props.disabledItem) {
            props.setSelectedTitle([...props.selectedTitle])
            
        } else {
            props.selectedTitle[props.index] = heading
            props.setSelectedTitle([...props.selectedTitle])
            if (heading === 'Expected Response') {
                await testRequest(props.index)
                
                try {
                    RadioImageValue === '2' ? responseBody[props.index] = arrayFetcher(jsonResponse[props.index], arrayKey[props.index])[0] : responseBody[props.index] = jsonResponse[props.index][0]
                    isResponseSelectorValid[props.index] = true
                    setIsResponseSelectorValid([...isResponseSelectorValid])
                    await fetchSchema()
                } catch (TypeError) {
                    responseBody[props.index] = undefined
                    isResponseSelectorValid[props.index] = false
                    setIsResponseSelectorValid([...isResponseSelectorValid])
                }
                setResponseBody([...responseBody])
            }
        }
    }

    return (
        <Stack className='customAppTitles' isInline spacing={6} align="center">
            {props.titleHeadings.map((heading: string) => (
                <Box key={heading}
                     color={heading === props.selectedTitle[props.index] ? 'blue.500' : 'grey'} 
                     borderBottom={heading === props.selectedTitle[props.index] ? '2px' : "0px"} 
                     borderBottomColor={heading === props.selectedTitle[props.index] ? 'blue.500' : 'none'}
                     paddingBottom={'0.36rem'}
                     className={heading === props.disabledItem ? 'custom-app--titles-disabled' : ''}
                     onClick={() => clickHandler(heading)}>
                     {heading}
                </Box>
             ))}
        </Stack>
    )
}

export default Titles

