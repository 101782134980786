import {
  Alert,
  AlertDescription,
  AlertTitle,
  Badge,
  Box,
  Link as ChakraLink,
  CloseButton,
  Collapse,
  Icon,
  IconButton,
  Image,
  PseudoBox,
  Spinner,
  Stack,
  useDisclosure,
  useToast,
} from '@chakra-ui/core';
import enrichDataSvg from 'app/authenticated-app/lists/assets/enrich-data.svg';
import newAutomationSvg from 'app/authenticated-app/lists/assets/new-automation.svg';
import emptyAutomations from 'app/authenticated-app/lists/assets/no-automations.svg';
import sendEmailSvg from 'app/authenticated-app/lists/assets/send-email.svg';
import { selectAutomations, selectColumnsData } from 'app/authenticated-app/lists/lists.selectors';
import { Column, ListAutomation } from 'app/authenticated-app/lists/lists.types';
import { Button, ConfirmModal, EmptyState, ToastBox, useResellerSettings } from 'app/components';
import React, { ReactNode, useState } from 'react';
import { useSelector } from 'react-redux';

interface SuggestedAutomationButtonProps {
  onClick: () => void;
  label: string;
  description: string;
  image: any;
  disabled?: boolean;
  loading?: boolean;
  children?: ReactNode;
}

const SuggestedAutomationButton = (props: SuggestedAutomationButtonProps) => {
  return (
    <PseudoBox
      display="flex"
      position="relative"
      flexDirection="row"
      alignItems="center"
      padding="0.5rem 1rem"
      width="100%"
      backgroundColor="#FFFFFF"
      border="1px solid #DADEE3"
      borderRadius="5px"
      onClick={() => {
        if (!props.loading) {
          props.onClick();
        }
      }}
      _hover={{
        backgroundColor: 'rgba(61,67,223,0.015)',
      }}
      cursor={props.disabled || props.loading ? 'not-allowed' : 'pointer'}
    >
      <Image
        src={props.image}
        height="45px"
        marginRight="20px"
        opacity={props.disabled ? 0.4 : 1}
      />
      <Box width="100%">
        <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
          <Box
            fontWeight="500"
            fontSize="14px"
            color="#2B3A4B"
            opacity={props.disabled ? 0.4 : 1}
            alignItems="center"
          >
            {props.label} {props.loading && <Spinner size="xs" ml="2rem" />}
          </Box>
          {props.disabled && (
            <Box
              fontSize="10px"
              backgroundColor="#026ae8"
              color="#FFFFFF"
              paddingX="5px"
              paddingY="2px"
              borderRadius="8px"
              fontWeight="500"
            >
              Contact us
            </Box>
          )}
        </Box>
        <Box fontSize="12px" color="#858C94" opacity={props.disabled ? 0.4 : 1}>
          {props.description}
        </Box>
      </Box>
      {props.children}
    </PseudoBox>
  );
};

interface AutomationItemProps extends ListAutomation {
  onClick: Function;
  delete: Function;
}

const AutomationItem = (props: AutomationItemProps) => {
  const [deleteLoading, setDeleteLoading] = useState(false);

  const {
    isOpen: deleteDialogOpen,
    onClose: closeDeleteDialog,
    onOpen: openDeleteDialog,
  } = useDisclosure(false);

  const handleDelete = async () => {
    setDeleteLoading(true);
    await props.delete(() => setDeleteLoading(false));
  };

  return (
    <>
      <PseudoBox
        display="flex"
        flexDirection="row"
        alignItems="center"
        padding="0.5rem 1rem"
        justifyContent="space-between"
        width="100%"
        height="65px"
        backgroundColor="#FFFFFF"
        border="1px solid #DADEE3"
        borderRadius="5px"
        marginY="0.5rem"
        onClick={() => props.onClick()}
        _hover={{
          backgroundColor: 'rgba(61,67,223,0.015)',
        }}
        cursor="pointer"
      >
        <Box>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Box fontWeight="500" fontSize="14px" color="#2B3A4B" alignItems="center">
              {props.name}
            </Box>
          </Box>
          <Box
            fontSize="12px"
            color="#858C94"
            width="335px"
            style={{ textOverflow: 'ellipsis' }}
            overflow="hidden"
            whiteSpace="nowrap"
          >
            {props.description || 'No description'}
          </Box>
        </Box>

        <Box display="flex" flexDirection="row" alignItems="center">
          {props.is_active ? (
            <Box
              fontSize="10px"
              backgroundColor="#ccffd9"
              color="#287D3C"
              padding="0px 3px"
              borderRadius="4px"
              fontWeight="500"
            >
              ON
            </Box>
          ) : (
            <Box
              fontSize="10px"
              backgroundColor="#DADEE3"
              color="#333333"
              padding="0px 3px"
              borderRadius="4px"
              fontWeight="500"
            >
              OFF
            </Box>
          )}
          <IconButton
            size="xs"
            variantColor="red"
            variant="ghost"
            onClick={(event: any) => {
              event.stopPropagation();
              openDeleteDialog();
            }}
            aria-label="delete automation"
            // @ts-ignore
            icon="trash"
            marginX="0.5rem"
          />
          <Icon name="chevron-right" />
        </Box>
      </PseudoBox>
      <ConfirmModal
        title="Delete Automation"
        isOpen={deleteDialogOpen}
        isLoading={deleteLoading}
        onConfirm={handleDelete}
        onClose={closeDeleteDialog}
      />
    </>
  );
};

interface Props {
  setActiveSection: Function;
  createAutomation: Function;
  selectAutomation: Function;
  deleteAutomation: Function;
}

export const LandingScreen = (props: Props) => {
  const [createAutomationLoading, setCreateAutomationLoading] = useState<boolean>(false);
  const [loadingTrigger, setLoadingTrigger] = useState<'primary' | 'secondary'>('primary');
  const [showAirtimePhoneColumnError, setShowAirtimePhoneColumnError] = useState(false);

  const columnsState = useSelector(selectColumnsData);
  const automations: ListAutomation[] = useSelector(selectAutomations);

  const toast = useToast();
  const { name } = useResellerSettings();

  // @ts-ignore
  const columns: Column[] = columnsState.columns_by_id.map((i: string) => columnsState.columns[i]);
  const phoneColumns = columns.filter((i: Column) => i.type === 'PHONE NUMBER');

  const createAutomation = async (trigger: 'primary' | 'secondary' = 'primary') => {
    setLoadingTrigger(trigger);
    setCreateAutomationLoading(true);
    try {
      const automation: ListAutomation = await props.createAutomation();
      toast({
        position: 'bottom-left',
        render: ({ onClose }) => (
          <ToastBox onClose={onClose} message="Automation created successfully" status="success" />
        ),
      });
      return props.selectAutomation(automation.id);
    } catch (e) {
      toast({
        position: 'bottom-left',
        render: ({ onClose }) => (
          <ToastBox
            onClose={onClose}
            message="Unable to create automation, please try again"
            status="error"
          />
        ),
      });
    }
    setCreateAutomationLoading(false);
  };

  const deleteAutomation = async (automationID: string, cb: Function) => {
    try {
      await props.deleteAutomation(automationID);
      toast({
        position: 'bottom-left',
        render: ({ onClose }) => (
          <ToastBox onClose={onClose} status="success" message="Automation deleted successfully" />
        ),
      });
    } catch (e) {
      cb();
      toast({
        position: 'bottom-left',
        render: ({ onClose }) => (
          <ToastBox
            onClose={onClose}
            status="success"
            message="Unable to delete automation, please try again"
          />
        ),
      });
    }
  };

  return (
    <>
      <Box>
        {automations.length === 0 && (
          <Box>
            <EmptyState
              imageSize="300px"
              marginLeft="0"
              imageContainerProp={{
                marginBottom: 10,
              }}
              heading={`Let ${name} do the work for you by automating your most common tasks`}
              headingProps={{
                color: '#545D69',
                fontSize: '18px',
              }}
              image={emptyAutomations}
            />
          </Box>
        )}

        {automations.length > 0 && (
          <>
            <Box width="100%">
              <Box
                display="flex"
                alignItems="center"
                flexDirection="row"
                justifyContent="space-between"
              >
                <Box fontSize="14px" fontWeight="500">
                  Automations
                </Box>
                <Button
                  leftIcon="small-add"
                  variantColor="blue"
                  variant="link"
                  size="xs"
                  onClick={() => createAutomation('secondary')}
                  isLoading={createAutomationLoading && loadingTrigger === 'secondary'}
                >
                  New Automation
                </Button>
              </Box>
            </Box>

            <Box>
              {automations.map((i: ListAutomation) => (
                <AutomationItem
                  key={i.id}
                  onClick={() => props.selectAutomation(i.id)}
                  {...i}
                  delete={(cb: Function) => {
                    deleteAutomation(i.id, cb);
                  }}
                />
              ))}
            </Box>
          </>
        )}

        <Stack mt="3rem">
          <Box fontSize="14px" fontWeight="500">
            Suggested Automations
          </Box>
          <Box>
            <SuggestedAutomationButton
              label="Enrich your list"
              description="Enrich your list to make the most out of your data."
              image={enrichDataSvg}
              onClick={() => {
                props.setActiveSection('enrichment');
              }}
            />
          </Box>
          <Box>
            <SuggestedAutomationButton
              image={newAutomationSvg}
              onClick={() => createAutomation()}
              label="Automate messages for your list"
              loading={createAutomationLoading && loadingTrigger === 'primary'}
              description="Define an event that initiates a notification, an update to your base, and more."
            />
          </Box>
          <Box>
            <SuggestedAutomationButton
              image={sendEmailSvg}
              label="Airtime rewards"
              onClick={() => {
                if (!phoneColumns.length) {
                  setShowAirtimePhoneColumnError(true);
                } else {
                  props.setActiveSection('airtime-topup');
                }
              }}
              description="Reward your customers with airtime, bulk send airtime to your list."
            >
              <Badge top="8px" right="12px" position="absolute" variantColor="green">
                Beta
              </Badge>
            </SuggestedAutomationButton>
          </Box>
          <Collapse isOpen={showAirtimePhoneColumnError}>
            <Alert
              borderWidth="2px"
              borderColor="blue.500"
              alignItems="flex-start"
              flexDirection="column"
              status="info"
              rounded="4px"
              bg="blue.100"
            >
              <AlertTitle mb="0.2rem">Info</AlertTitle>
              <AlertDescription mb="0.5rem" fontSize="0.875rem">
                The list must have a phone number column to use this feature.{' '}
                <ChakraLink
                  pl="0.2rem"
                  color="blue.500"
                  fontSize="0.875rem"
                  target="_blank"
                  href="https://support.simpu.co/en/articles/6788747-changing-column-property-type-s-in-data"
                >
                  Learn more
                </ChakraLink>
              </AlertDescription>

              <CloseButton
                top="8px"
                size="sm"
                right="8px"
                position="absolute"
                onClick={() => setShowAirtimePhoneColumnError(false)}
              />
            </Alert>
          </Collapse>
        </Stack>
      </Box>
    </>
  );
};
