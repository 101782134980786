import { format, utcToZonedTime } from 'date-fns-tz';
import differenceInDays from 'date-fns/differenceInDays';
import differenceInSeconds from 'date-fns/differenceInSeconds';
import differenceInYears from 'date-fns/differenceInYears';
import isToday from 'date-fns/isToday';
import isYesterday from 'date-fns/isYesterday';

export const objectIsEmpty = (obj: object) => {
  if (!Boolean(obj)) {
    return true;
  }
  if (Object.keys(obj).length === 0) {
    return true;
  }
};

export const toFormData = (values: object, files?: File, fileName: string = 'file') => {
  const formData = new FormData();
  if (!objectIsEmpty(values)) {
    const valuesArray = Object.entries(values);
    valuesArray.forEach(value => {
      if (Array.isArray(value[1])) {
        const arrayString = value[1].join(',');
        formData.set(value[0], arrayString);
      } else if (objectIsEmpty(value[1])) {
        const stringValue = JSON.stringify(value[1]);
        formData.set(value[0], stringValue);
      } else {
        formData.set(value[0], value[1]);
      }
    });
  }
  if (files) {
    formData.append(fileName ?? '', files);
  }
  return formData;
};

export function validURL(str: string) {
  var pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i',
  ); // fragment locator
  return !!pattern.test(str);
}

export function validateEmail(value: string) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(value);
}

export const scrollToSection = (id: string) => {
  const element = document.querySelector(`#${id}`);
  if (!element) return;
  element.scrollIntoView();
};

export const isInViewport = (id: string) => {
  const elem = document.querySelector(`#${id}`);
  if (!elem) return false;

  const style = getComputedStyle(elem);
  if (style.display === 'none') return false;
  if (style.visibility !== 'visible') return false;
  if (Number(style.opacity) < 0.01) return false;
  // @ts-ignore
  const { offsetWidth, offsetHeight } = elem;
  if (
    offsetWidth +
      offsetHeight +
      elem.getBoundingClientRect().height +
      elem.getBoundingClientRect().width ===
    0
  ) {
    return false;
  }
  const elemCenter = {
    x: elem.getBoundingClientRect().left + offsetWidth / 2,
    y: elem.getBoundingClientRect().top + offsetHeight / 2,
  };
  if (elemCenter.x < 0) return false;
  if (elemCenter.x > (document.documentElement.clientWidth || window.innerWidth)) return false;
  if (elemCenter.y < 0) return false;
  if (elemCenter.y > (document.documentElement.clientHeight || window.innerHeight)) return false;
  let pointContainer = document.elementFromPoint(elemCenter.x, elemCenter.y);
  if (!pointContainer) return false;
  do {
    if (pointContainer === elem) return true;
    // @ts-ignore
  } while ((pointContainer = pointContainer.parentNode));
  return false;
};

export function getReference() {
  let text = '';
  let possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-.=';

  for (let i = 0; i < 15; i++) text += possible.charAt(Math.floor(Math.random() * possible.length));

  return text;
}

export function getZonedTime(dateString: string | Date, pattern: string) {
  const date = typeof dateString === 'string' ? new Date(dateString) : dateString;
  const timeZone =
    typeof window.Intl === undefined ? '' : window.Intl.DateTimeFormat().resolvedOptions().timeZone;
  const zonedDate = utcToZonedTime(date, timeZone);
  return format(zonedDate, pattern);
}

export function formatCurrency(amount: Number) {
  const amountNotUndefined = Number(amount) || 0;
  const amountInDecimal = amountNotUndefined.toLocaleString('en-US');
  return amountInDecimal;
}

export const numberWithCommas = (x: number | undefined) =>
  x
    ? Number.isInteger(x)
      ? x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      : x.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    : '0';

export function contentHasURL(content: string) {
  return !!content.split('\n').filter(item => item.startsWith('http')).length;
}

export function contentWithoutURL(content: string) {
  return content
    .split('\n')
    .filter(item => !item.startsWith('http'))
    .join('');
}

export const getContent = (value?: null | string): string => {
  if (value) {
    return value;
  }
  return '';
};

export const hexToRgb = (hex: string): { r: number; g: number; b: number } => {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : { r: 255, g: 255, b: 255 };
};

export const getCurrencySymbol = (currency: string) => {
  const symbols: any = {
    USD: '$',
    EUR: '€',
    CRC: '₡',
    GBP: '£',
    ILS: '₪',
    INR: '₹',
    JPY: '¥',
    KRW: '₩',
    NGN: '₦',
    PHP: '₱',
    PLN: 'zł',
    PYG: '₲',
    THB: '฿',
    UAH: '₴',
    VND: '₫',
  };

  if (symbols[currency]) {
    return symbols[currency];
  } else {
    return currency;
  }
};

export function getBase64(
  file: File,
  onSuccess: (base64String: string | ArrayBuffer | null) => void,
  onError?: (error: ProgressEvent<FileReader>) => void,
) {
  var reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function () {
    onSuccess(reader.result);
  };
  reader.onerror = function (error) {
    onError?.(error);
  };
}

export default function mergeRefs<T = any>(
  refs: Array<React.MutableRefObject<T> | React.LegacyRef<T>>,
): React.RefCallback<T> {
  return value => {
    refs.forEach(ref => {
      if (typeof ref === 'function') {
        ref(value);
      } else if (ref != null) {
        (ref as React.MutableRefObject<T | null>).current = value;
      }
    });
  };
}

export const isElectron = () => {
  const userAgent = navigator.userAgent.toLowerCase();
  return userAgent.indexOf(' electron/') !== -1;
};

export const formatToCurrency = (amount: number) => {
  const valueDisplay = (amount / 100).toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  return valueDisplay.substr(1, valueDisplay.length);
};

export const stringSplice = (str: string, index: number, value: string) => {
  return str && str.substring(0, index) + value + str.substring(index);
};

export function textNodesUnder(node: any) {
  let all: any[] = [];
  for (node = node.firstChild; node; node = node.nextSibling) {
    if (node.nodeType === 3) {
      all.push(node);
    } else {
      all = all.concat(textNodesUnder(node));
    }
  }
  return all;
}

export const formatMessageDateTime = (time: Date) => {
  const now = new Date();
  switch (true) {
    case differenceInSeconds(now, time) < 60:
      return 'a few seconds ago';
    case isToday(time):
      return format(time, 'hh:mm a');
    case isYesterday(time):
      return `Yesterday, ${format(time, 'hh:mm a')}`;
    case differenceInDays(now, time) < 7:
      return format(time, 'eee, hh:mm a ');
    case differenceInYears(now, time) === 0:
      return format(time, 'dd MMM hh:mm a');
    default:
      return format(time, 'dd MMM yyyy hh:mm a');
  }
};

export const mapObjectsToNewObject = (obj1: any, obj2: any) => {
  const result: any = {};

  for (let key in obj1) {
    if (obj1.hasOwnProperty(key) && obj2.hasOwnProperty(key)) {
      result[obj1[key]] = obj2[key];
    }
  }

  return result;
};
