import React from 'react';
import { EditAutomation } from './screens';
import { Box } from '@chakra-ui/core';
import { ListAutomation } from 'app/authenticated-app/lists/lists.types';
import { useSelector } from 'react-redux';
import { selectAutomations } from 'app/authenticated-app/lists/lists.selectors';

interface Props {
  setActiveSection: Function;
  selectedAutomation: string;
  updateAutomation: Function;
  addAutomationAction: Function;
  updateAutomationAction: Function;
  deleteAutomation: Function;
  deleteAutomationAction: Function;
}

export const AutomationScreen = (props: Props) => {
  const automationList = useSelector(selectAutomations);
  const automation = automationList.find((i: ListAutomation) => i.id === props.selectedAutomation);

  return (
    <Box width="100%">
      <EditAutomation
        automation={automation}
        setActiveSection={props.setActiveSection}
        updateAutomation={props.updateAutomation}
        addAutomationAction={props.addAutomationAction}
        updateAutomationAction={props.updateAutomationAction}
        deleteAutomation={props.deleteAutomation}
        deleteAutomationAction={props.deleteAutomationAction}
      />
    </Box>
  );
};
