// @ts-nocheck
import React, { useState } from 'react';
import { Box, Icon, Input } from '@chakra-ui/core';

const SelectValueItem = (props: any) => {
  const { allowEdit = true } = props;

  const handleDeleteValue = (event: any) => {
    event.stopPropagation();
    props.delete();
  };

  return (
    <div
      className="value-item"
      style={{
        fontSize: 12,
        backgroundColor: props.color,
        marginRight: 5,
        padding: '2px 5px',
        borderRadius: 2,
        lineHeight: 'normal',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        cursor: allowEdit ? 'pointer' : 'not-allowed',
      }}
      onClick={(e: any) => e.stopPropagation()}
    >
      {props.value}
      <Box
        marginLeft="5px"
        display="flex"
        padding="2.5px"
        borderRadius="2px"
        backgroundColor="rgba(0,0,0,0.1)"
        onClick={handleDeleteValue}
        className="close-icon"
      >
        <Icon name="close" size="8px" cursor="pointer" className="close-icon" />
      </Box>
    </div>
  );
};

const SelectInput = ({ addOption, isDisabled = false, setSelectQuery }: any) => {
  const [value, setValue] = useState('');

  const handleChange = (event: any) => {
    setValue(event.target.value);
    setSelectQuery(event.target.value);
  };

  const handleKeyPress = (e: any) => {
    if (e.key !== 'Enter') return;
    if (value === '') return;
    addOption(value);
    setValue('');
  };

  return (
    <Input
      variant="unstyled"
      value={value}
      onChange={handleChange}
      onKeyPress={handleKeyPress}
      fontSize="14px"
      isDisabled={isDisabled}
    />
  );
};

export const SelectCell = (props: any) => {
  const addOption = async (value: string) => {
    const option = await props.addOption(props._column.uid, value);
    const row_index = props.node.rowIndex;
    const row = await props.getRowByIndex(row_index);
    const columnID = props._column.uid;
    const row_id = row['uid'];
    await props.updateCellValue([option.id], row_id, columnID);
  };

  const { allowEdit = false } = props;

  const deleteValue = async () => {
    if (!allowEdit) return;

    const row_index = props.node.rowIndex;
    const row = await props.getRowByIndex(row_index);
    const columnID = props._column.uid;
    const row_id = row['uid'];
    props.updateCellValue([], row_id, columnID);
  };

  let value = props.value;
  if (Array.isArray(props.value)) value = value[0];
  const options = props._column?.options
    ? props._column.options.filter((i: any) => !i.isDeleted && !i.is_deleted)
    : [];
  const selectedOption = options.find((i: any) => String(i.id) === String(value));

  return (
    <div className="select-cell" style={{ display: 'flex', alignItems: 'center' }}>
      <div>
        {selectedOption && (
          <SelectValueItem
            value={selectedOption.name}
            color={selectedOption.color}
            delete={deleteValue}
            allowEdit={allowEdit}
            id={selectedOption.id}
          />
        )}
      </div>
      {allowEdit && <SelectInput isDisabled={!allowEdit} addOption={addOption} setSelectQuery={props.setSelectQuery} />}
    </div>
  );
};
