import { Box, Checkbox, Flex, Heading, Icon, Stack, Switch, useToast } from '@chakra-ui/core';
import { BodyText, Button, Container, ToastBox } from 'app/components';
import { AxiosError } from 'axios';
import { useFormik } from 'formik';
import React, { useCallback } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { getNotificationSettings, updateNotificationSettings } from '../../service';
import { NotificationSettingsPayload } from '../../settings.types';

export function Notifications() {
  const toast = useToast();
  const queryClient = useQueryClient();

  const settingsNameRegister: { [key: string]: string } = {
    'organisation.team.created': 'New team is created',
    'organisation.team.removed': 'A team is deleted',
    'billing.credits.depleted': 'Campaign credits exceeded',
    'billing.credits.threshold-exceeded': 'Campaign credits threshold exceeded',
    'marketing.campaign.started': 'A new campaign has started',
    'marketing.campaign.processed': 'A new campaign has been completed',
    'billing.credits.daily-usage': 'Daily usage of campaign credits',
  };

  const defaultValues: NotificationSettingsPayload = {
    id: undefined,
    email_active: true,
    push_active: true,
    smart_delivery: true,
    profile_id: undefined,
    attributes: {
      'organisation.team.created': {
        email: true,
        push: true,
      },
      'organisation.team.removed': {
        email: true,
        push: true,
      },
      'organisation.invite.accepted': {
        email: true,
        push: true,
      },
      'marketing.campaign.started': {
        email: true,
        push: true,
      },
      'marketing.campaign.processed': {
        email: true,
        push: true,
      },
      'billing.credits.depleted': {
        email: true,
        push: true,
      },
      'billing.credits.daily-usage': {
        email: true,
        push: true,
      },
      'billing.credits.threshold-exceeded': {
        email: true,
        push: true,
      },
    },
  };

  const getAttributes = useCallback(
    (data: NotificationSettingsPayload): NotificationSettingsPayload['attributes'] => {
      const settings: NotificationSettingsPayload['attributes'] =
        {} as NotificationSettingsPayload['attributes'];
      Object.keys(data?.attributes ?? {})
        .filter(
          name => name.includes('team') || name.includes('credits') || name.includes('campaign'),
        )
        .forEach(name => {
          settings[name] = data?.attributes[name];
        });

      return settings;
    },
    [],
  );

  const { data: notificationSettings } = useQuery<NotificationSettingsPayload>(
    ['notification-settings'],
    getNotificationSettings,
    {
      select: data => ({ ...data, attributes: getAttributes(data) }),
    },
  );

  const { isLoading: isUpdatingNotificationSettings, mutate: updateNotificationSettingsMutate } =
    useMutation<any, AxiosError, any, any>(
      (payload: NotificationSettingsPayload) => updateNotificationSettings(payload),
      {
        onSuccess: () => {
          queryClient.invalidateQueries(['notification-settings']);
          toast({
            position: 'bottom-left',
            render: ({ onClose }) => (
              <ToastBox
                status="success"
                onClose={onClose}
                message="Notification settings updated successfully"
              />
            ),
          });
        },
        onError: error => {
          toast({
            position: 'bottom-left',
            render: ({ onClose }) => (
              <ToastBox
                onClose={onClose}
                message={typeof error !== 'string' ? error.message : error}
              />
            ),
          });
        },
      },
    );

  const { values, setFieldValue, submitForm } = useFormik<NotificationSettingsPayload>({
    enableReinitialize: true,
    initialValues: notificationSettings ?? defaultValues,
    onSubmit: values => updateNotificationSettingsMutate(values),
  });

  return (
    <Container maxW="640px">
      <Stack isInline mb="1.5rem" justifyContent="space-between">
        <Stack>
          <Heading size="md">Notification Settings</Heading>
          <BodyText color="gray.500">Select where and when you will be notified.</BodyText>
        </Stack>
        <Button
          size="sm"
          variantColor="blue"
          onClick={submitForm}
          isLoading={isUpdatingNotificationSettings}
        >
          Save changes
        </Button>
      </Stack>
      <Box mb="1.5rem">
        <Heading pb="1rem" size="sm">
          Notification methods
        </Heading>
        <Flex justifyContent="space-between">
          <Icon color="gray.500" size="1.5rem" name="email" mr="1rem" />
          <Flex alignItems="center" justifyContent="space-between" flex={1}>
            <Stack spacing="0.2rem" flex={1}>
              <BodyText fontWeight="500">Email digest</BodyText>
              <BodyText color="gray.500">
                Receive an email digest for wallet balance, campaigns, invites and more.
              </BodyText>
            </Stack>
            <Switch
              size="sm"
              isChecked={values?.email_active}
              onChange={(e: any) => {
                const checked = e.target.checked;
                const newAttributes = {} as NotificationSettingsPayload['attributes'];
                Object.keys(values.attributes).forEach(attribute => {
                  newAttributes[attribute] = {
                    ...values.attributes[attribute],
                    email: checked,
                  };
                });
                setFieldValue('email_active', checked);
                setFieldValue('attributes', newAttributes);
              }}
            />
          </Flex>
        </Flex>
      </Box>

      <Box
        pt="2rem"
        pb="5rem"
        px="1rem"
        rounded="8px"
        boxShadow="0px 21px 31px 6px rgba(0, 0, 0, 0.04)"
      >
        <Flex
          pb="1rem"
          mb="1rem"
          alignItems="center"
          borderBottomWidth="1px"
          justifyContent="space-between"
        >
          <Heading size="sm">Notify me about</Heading>
          <BodyText fontWeight="500">Email</BodyText>
        </Flex>
        <Stack spacing="1rem">
          {Object.keys(values?.attributes ?? {}).map(item => (
            <Flex key={item} alignItems="center" justifyContent="space-between">
              <BodyText>{settingsNameRegister[item]}</BodyText>
              <Checkbox
                isChecked={values?.attributes?.[item]?.email}
                onChange={(e: any) => {
                  const checked = e.target.checked;
                  setFieldValue('attributes', {
                    ...values.attributes,
                    [item]: { ...values.attributes[item], email: checked },
                  });
                }}
              />
            </Flex>
          ))}
        </Stack>
      </Box>
    </Container>
  );
}
