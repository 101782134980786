import { Box, Grid } from '@chakra-ui/core';
import { Button, Input } from 'app/components';
import { useFormik } from 'formik';
import React from 'react';
import * as yup from 'yup';

const validationSchema = yup.object().shape({
  old_password: yup
    .string()
    .min(6, 'Password must be at least 6 characters long')
    .required('Old password is required'),
  new_password: yup
    .string()
    .min(6, 'Password must be at least 6 characters long')
    .required('New password is required'),
});

export type ChangePassordFormInitialValues = yup.InferType<typeof validationSchema>;

export type ChangePassordFormProps = {
  isLoading?: boolean;
  onSubmit: (values: ChangePassordFormInitialValues, callback: () => void) => void;
};

export function ChangePassordForm({ onSubmit, isLoading }: ChangePassordFormProps) {
  const formik = useFormik({
    validationSchema,
    initialValues: {
      old_password: '',
      new_password: '',
    },
    onSubmit: (values: ChangePassordFormInitialValues, { resetForm }) =>
      onSubmit(values, resetForm),
  });

  const isDisabled = () => {
    const { old_password, new_password } = formik.values;
    return !old_password || !new_password;
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid gap="1rem" mb="0.5rem" gridTemplateColumns={['auto', 'auto', 'auto auto', 'auto auto']}>
        <Box marginBottom="0.875rem">
          <Input
            type="password"
            id="old_password"
            name="old_password"
            label="Old password"
            onChange={formik.handleChange}
            placeholder="Enter old password"
            value={formik.values.old_password}
            errorMessage={formik.errors.old_password}
            isInvalid={!!formik.touched.old_password && !!formik.errors.old_password}
          />
        </Box>
        <Box marginBottom="0.875rem">
          <Input
            type="password"
            id="new_password"
            name="new_password"
            label="New password"
            onChange={formik.handleChange}
            placeholder="Enter new password"
            value={formik.values.new_password}
            errorMessage={formik.errors.new_password}
            isInvalid={!!formik.touched.new_password && !!formik.errors.new_password}
          />
        </Box>
      </Grid>
      <Button
        size="sm"
        type="submit"
        variantColor="blue"
        isLoading={isLoading}
        isDisabled={isDisabled()}
      >
        Change Password
      </Button>
    </form>
  );
}
