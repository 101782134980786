import React, { forwardRef } from 'react';
import {
  Heading as ChakraHeading,
  Text as ChakraText,
  HeadingProps,
  BoxProps,
} from '@chakra-ui/core';

export const Title = forwardRef((props: HeadingProps, ref: any) => (
  <ChakraHeading ref={ref} fontSize="4rem" fontWeight="bold" {...props} />
));

export const Heading2 = forwardRef((props: HeadingProps, ref: any) => (
  <ChakraHeading ref={ref} fontSize="2.25rem" fontWeight="bold" {...props} />
));

export const Heading3 = forwardRef((props: HeadingProps, ref: any) => (
  <ChakraHeading ref={ref} fontSize="1.5rem" fontWeight="bold" {...props} />
));

export const Subtitle = forwardRef((props: BoxProps, ref: any) => (
  <ChakraText ref={ref} fontSize="1.3125rem" fontWeight="bold" {...props} />
));

export const SmallSubtitle = forwardRef((props: BoxProps, ref: any) => (
  <ChakraText ref={ref} fontSize="1rem" fontWeight="bold" {...props} />
));

export const BodyText = forwardRef((props: BoxProps, ref: any) => (
  <ChakraText ref={ref} fontSize="0.875rem" fontWeight="normal" {...props} />
));

export const SmallText = forwardRef((props: BoxProps, ref: any) => (
  <ChakraText ref={ref} fontSize="0.75rem" fontWeight="normal" {...props} />
));

export const XSmallText = forwardRef((props: BoxProps, ref: any) => (
  <ChakraText ref={ref} fontSize="0.6875rem" fontWeight="normal" {...props} />
));

export const XXSmallText = forwardRef((props: BoxProps, ref: any) => (
  <ChakraText ref={ref} fontSize="0.5625rem" fontWeight="normal" {...props} />
));

export const PreTitle = forwardRef((props: BoxProps, ref: any) => (
  <ChakraText
    ref={ref}
    fontWeight="bold"
    fontSize="0.625rem"
    textTransform="uppercase"
    {...props}
  />
));
