import { Badge, Box, Flex, Icon, PseudoBox, Skeleton, Stack, useDisclosure } from '@chakra-ui/core';
import React from 'react';
import { BodyText, TableDropdown, TableDropdownAction } from '../../../../components';
import { SMSSenderProfilePayload } from './sms-sender-profile-modal';

export type SenderProfileItemProps<T> = {
  data: T;
  onClick: () => void;
  actions: TableDropdownAction<T>[];
};

export function SMSSenderProfileItem(props: SenderProfileItemProps<SMSSenderProfilePayload>) {
  const { data, onClick, actions } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <PseudoBox cursor="pointer" _hover={{ bg: '#f9f9f9' }} onClick={onClick}>
      <Flex py="1rem" alignItems="center" borderTopWidth="1px" justifyContent="space-between">
        <Stack isInline alignItems="center" pl="1rem">
          <BodyText textTransform="capitalize">{data.name}</BodyText>
          {data.is_default && (
            <Badge fontSize="0.5rem" variant="outline">
              Default
            </Badge>
          )}
        </Stack>
        <Box>
          <TableDropdown<SMSSenderProfilePayload>
            data={data}
            open={onOpen}
            isOpen={isOpen}
            close={onClose}
            actions={actions}
          >
            <Icon name="chevron-down" size="1.2rem" />
          </TableDropdown>
        </Box>
      </Flex>
    </PseudoBox>
  );
}

export function SMSSenderProfileListHeader() {
  return (
    <Flex pb="1rem" alignItems="center" justifyContent="space-between">
      <Stack pl="1rem">
        <BodyText fontWeight="bold">Name</BodyText>
      </Stack>
    </Flex>
  );
}

export function SMSSenderProfileLoadingItem() {
  return (
    <Stack isInline p="1rem" borderTopWidth="1px">
      <Box width={['calc(25% - 1rem)', 'calc(50% - 1rem)', 'calc(25% - 1rem)']}>
        <Skeleton height="10px" width="100%" />
      </Box>
    </Stack>
  );
}
