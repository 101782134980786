import { Box, BoxProps, Button, IconButton, PseudoBox } from '@chakra-ui/core';
import { DOTS, usePagination } from 'hooks';
import React from 'react';

/**
 * https://www.freecodecamp.org/news/build-a-custom-pagination-component-in-react/
 */

type Props = {
  page: number;
  total: number;
  pageSize?: number;
  siblingCount?: number;
  onPageChange: (page: number) => void;
} & BoxProps;

export const Pagination = ({
  total,
  page = 1,
  siblingCount,
  onPageChange,
  pageSize = 10,
  ...rest
}: Props) => {
  const totalPages = Math.ceil(total / pageSize);

  const paginationRange = usePagination({
    total,
    pageSize,
    siblingCount,
    currentPage: page,
  });

  // If there are less than 2 times in pagination range we shall not render the component
  if (page === 0 || (paginationRange ?? []).length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(page + 1);
  };

  const onPrevious = () => {
    onPageChange(page - 1);
  };

  return (
    <Box width="100%" display="flex" justifyContent="center" {...rest}>
      <Box justifySelf="center" display="flex" justifyContent="space-between" alignItems="center">
        <IconButton
          size="xs"
          variant="ghost"
          icon="arrow-back"
          marginRight="1rem"
          variantColor="blue"
          onClick={onPrevious}
          aria-label="Previous"
          isDisabled={page === 1}
        />
        {paginationRange?.map(pageNumber => {
          // If the pageItem is a DOT, render the DOTS unicode character
          if (pageNumber === DOTS) {
            return <PseudoBox color="blue.500">&#8230;</PseudoBox>;
          }

          // Render our Page Pills
          return (
            <Button
              size="xs"
              marginX="2px"
              key={pageNumber}
              variantColor="blue"
              onClick={() => {
                if (typeof pageNumber !== 'string') {
                  onPageChange(pageNumber);
                }
              }}
              variant={page === pageNumber ? 'solid' : 'ghost'}
            >
              {pageNumber}
            </Button>
          );
        })}
        <IconButton
          size="xs"
          variant="ghost"
          onClick={onNext}
          aria-label="Next"
          marginLeft="1rem"
          variantColor="blue"
          icon="arrow-forward"
          isDisabled={page === totalPages}
        />
      </Box>
    </Box>
  );
};
