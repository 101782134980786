import React, { useEffect, useRef, useState } from 'react';
import { Box, Input } from '@chakra-ui/core';
import { motion } from 'framer-motion';
import { PopoverWrapper } from '../../list-view.styles';
import { PopoverListItem } from '../popover-list-item';
import { PropertySelect } from './property/property-select';
import { customizable_properties } from '../../list.data';
import { getColumnCustomizationComponent } from '../../lists.utils';
import { useSelector } from 'react-redux';
import { selectEnrichmentSettings } from '../../lists.selectors';

export const GridColumnMenu = (props: any) => {
  const [initialized, setInitialized] = useState<boolean>(false);
  const { parent_type = 'grid' } = props;

  const [stage, setStage] = useState('property');

  const container = useRef();

  const [tempLabel, setTempLabel] = useState('');
  const [headerPosition, setHeaderPosition] = useState({
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  });
  const col_id = props.columnID;

  const fetchHeaderPosition = () => {
    const column_header_element = document.querySelectorAll(`[col-id="${col_id}"]`)[0];
    if (!column_header_element) return;
    const rect = column_header_element.getBoundingClientRect();
    setHeaderPosition({
      left: rect.left,
      right: rect.right,
      top: rect.top,
      bottom: rect.bottom,
    });
  };

  const fetchFieldPosition = () => {
    const { field_position: rect } = props;
    setHeaderPosition({
      left: rect.left,
      right: rect.right,
      top: rect.top,
      bottom: rect.bottom,
    });
  };

  const checkColumnIndex = (columnID: string) => {
    return props.columnsByID.findIndex((i: string) => i === columnID);
  };

  const handleTempLabelChange = (event: any) => {
    const value = event.target.value;
    setTempLabel(value);
  };

  const handleClick = (e: any) => {
    const enrich_dialog_element = document.getElementById('enrich-modal');

    // @ts-ignore
    if (container.current.contains(e.target) || !props.isOpen) return;
    if (tempLabel !== props.column.label) props.updateLabel(col_id, tempLabel);
    if (enrich_dialog_element) {
      // @ts-ignore
      if (enrich_dialog_element.contains(e.target) || !props.isOpen) return;
    }
    props.close();
  };

  const handleEnterPressed = (e: any) => {
    if (e.key !== 'Enter') return;
    if (tempLabel === props.column.label) props.close();
    props.updateLabel(col_id, tempLabel);
    props.close();
  };

  const attachOutsideClickListener = () => {
    document.addEventListener('mousedown', handleClick);
  };
  const detachOutsideClickListener = () => {
    document.removeEventListener('mousedown', handleClick);
  };

  const attachEnterPressedListener = () => {
    const input_element = document.getElementById('col-label-input');
    if (!input_element) return;
    input_element.addEventListener('keydown', handleEnterPressed);
  };

  const detachEnterPressedListener = () => {
    const input_element = document.getElementById('col-label-input');
    if (!input_element) return;
    input_element.removeEventListener('keydown', handleEnterPressed);
  };

  const handleTypeChanged = (type: string) => {
    const payload = {
      type,
      label: tempLabel,
    };
    props.updateColumn(col_id, payload);
    setStage('property');
    if (['CREATED AT', 'UPDATED AT', 'DATE'].includes(type)) props.close();
  };

  const CustomizationComponent: React.ReactNode = getColumnCustomizationComponent(
    props?.column?.type,
  );

  useEffect(() => {
    if (parent_type === 'grid' && props.isOpen) {
      fetchHeaderPosition();
      setInitialized(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.columnID]);

  useEffect(() => {
    if (parent_type === 'field' && props.isOpen) {
      fetchFieldPosition();
      setInitialized(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isOpen]);

  useEffect(() => {
    setStage('property');
    attachOutsideClickListener();
    return detachOutsideClickListener;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isOpen, tempLabel]);

  useEffect(() => {
    attachEnterPressedListener();
    return detachEnterPressedListener;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isOpen, tempLabel]);

  useEffect(() => {
    if (props.column) setTempLabel(props.column.label);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.column]);

  const left_overflow =
    parent_type === 'field' ? 0 : window.innerWidth - (headerPosition.left + 280);
  const top_overflow = window.innerHeight - (headerPosition.top - 50 + 300);

  useEffect(() => {
    if (!props.isOpen) setInitialized(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isOpen]);

  const enrichmentSettings = useSelector(selectEnrichmentSettings);

  let animateProps = {};

  if (parent_type !== 'field') {
    animateProps = {
      initial: { opacity: 0, scaleY: 0.5, y: -50 },
      animate: { opacity: 1, scaleY: 1, y: 0 },
      transition: { type: 'spring', duration: 0.005, stiffness: 460, damping: 30 },
    };
  }

  return (
    <Box
      width='240px'
      zIndex={1000000000000}
      boxShadow='none'
      position='absolute'
      left={
        parent_type === 'field'
          ? headerPosition.left - 600
          : headerPosition.left + Math.min(left_overflow, 5)
      }
      top={
        parent_type === 'field'
          ? headerPosition.bottom - 80 + Math.min(top_overflow, 0)
          : headerPosition.bottom - 47.5
      }
      ref={container}
      onContextMenu={event => {
        event.preventDefault();
        return false;
      }}
    >
      {props.isOpen && initialized && (
        <motion.div {...animateProps}>
          <PopoverWrapper position='fixed' zIndex={1000000000}>
            <Box className='input-item' width='auto' style={{ width: 'auto' }}>
              <Input
                value={tempLabel}
                onChange={handleTempLabelChange}
                variant='filled'
                height={30}
                fontSize={13}
                isInvalid={tempLabel.length === 0}
                id='col-label-input'
                style={{
                  backgroundColor: '#EDF2F7',
                }}
                isFullWidth
                autoFocus
              />
            </Box>

            <div className='property-type-label'>PROPERTY TYPE</div>
            <PropertySelect
              column={props.column}
              columnIndex={checkColumnIndex(props.column.uid)}
              onChange={handleTypeChanged}
              disabled={
                props.column?.type === 'DND' ||
                // @ts-ignore
                enrichmentSettings['mapping'][props.column.id]
              }
            />

            {stage === 'property' && (
              <>
                <hr />
                {customizable_properties.includes(props?.column?.type) && (
                  <PopoverListItem
                    label='Customize field'
                    icon='settings'
                    onClick={() => setStage('customize')}
                  />
                )}

                {props.hideColumn && (
                  <PopoverListItem
                    label='Hide field'
                    icon='view-off'
                    onClick={() => props.hideColumn(col_id)}
                  />
                )}
                {props.deleteColumn && (
                  <PopoverListItem
                    label='Delete field'
                    icon='trash'
                    onClick={() => props.deleteColumn(col_id)}
                    disabled={
                      props.column?.type === 'DND' ||
                      props.columnsByID?.length === 1 ||
                      checkColumnIndex(props.column.uid) === 0 ||
                      // @ts-ignore
                      enrichmentSettings['mapping'][props.column.id]
                    }
                  />
                )}
              </>
            )}
            {stage === 'customize' && (
              // @ts-ignore
              <CustomizationComponent
                columns={props.columns}
                column={props.column}
                updateCustomization={props.updateCustomization}
                close={props.close}
              />
            )}
          </PopoverWrapper>
        </motion.div>
      )}
    </Box>
  );
};
