import {
  Box,
  Button,
  ButtonProps,
  Icon,
  List,
  ListIcon,
  ListItem,
  Popover,
  PopoverContent,
  PopoverProps,
  PopoverTrigger,
  Stack,
} from '@chakra-ui/core';
import React, { ReactNode } from 'react';
import { BodyText, SmallText } from '../Typography';

export type TableDropdownAction<T> = {
  icon?: string;
  label?: string;
  onClick?: (data: T) => void;
};

export function TableDropdown<T>({
  data,
  open,
  close,
  isOpen,
  actions,
  children,
  popoverProps,
  triggerButtonProps,
}: {
  data: T;
  open?(): void;
  close?(): void;
  isOpen?: boolean;
  children?: ReactNode;
  actions: TableDropdownAction<T>[];
  popoverProps?: Omit<PopoverProps, 'children'>;
  triggerButtonProps?: Omit<ButtonProps, 'children'>;
}) {
  return (
    <Popover
      usePortal
      onOpen={open}
      onClose={close}
      isOpen={isOpen}
      placement="bottom-end"
      {...popoverProps}
    >
      <PopoverTrigger>
        <Button
          size="xs"
          variant="ghost"
          onClick={e => e.stopPropagation()}
          {...triggerButtonProps}
        >
          {!!children ? (
            children
          ) : (
            <Stack isInline alignItems="center">
              <Icon top="4px" name="more" size="0.875rem" color="blue.500" position="relative" />
              <BodyText>Options</BodyText>
            </Stack>
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent
        zIndex={104}
        maxWidth="180px"
        boxShadow="0px 5px 20px rgba(21, 27, 38, 0.08)"
        _focus={{ boxShadow: '0px 5px 20px rgba(21, 27, 38, 0.08)' }}
      >
        <List>
          {actions.map((action, i) => (
            <ListItem
              key={i}
              outline="none"
              cursor="pointer"
              _hover={{ backgroundColor: 'gray.100' }}
              onClick={e => {
                e.stopPropagation();
                action.onClick && action.onClick(data);
              }}
            >
              <Stack
                isInline
                as="button"
                width="100%"
                spacing="2px"
                outline="none"
                cursor="pointer"
                padding="0.5rem"
                fontSize="0.75rem"
                alignItems="center"
              >
                {action.icon && (
                  <Box>
                    <ListIcon size="0.875rem" color="#66788a" icon={action.icon} />
                  </Box>
                )}
                <Box>
                  <SmallText>{action.label}</SmallText>
                </Box>
              </Stack>
            </ListItem>
          ))}
        </List>
      </PopoverContent>
    </Popover>
  );
}
