import React, { useState } from 'react';
import { ConfigureEnrichmentCategory, EnrichmentSummary } from './screens';
import { Box } from '@chakra-ui/core';
import { useSelector } from 'react-redux';
import {
  selectEnrichmentOptions,
  selectEnrichmentSettings,
} from 'app/authenticated-app/lists/lists.selectors';

interface Props {
  setActiveSection: Function;
  updateEnrichmentSettings: Function;
  queueEnrichment: Function;
}

export const EnrichmentScreen = (props: Props) => {
  const [activeCategory, setActiveCategory] = useState<'phone' | 'person' | 'company' | null>(null);

  const enrichmentOptions = useSelector(selectEnrichmentOptions);
  const enrichmentSettings = useSelector(selectEnrichmentSettings);

  return (
    <Box
      backgroundColor="#FFFFFF"
      padding="0.5rem 1.5rem"
      borderRadius="6px"
      border="1px solid #DADEE3"
      // margin="auto"
      width="100%"
    >
      {!activeCategory && (
        <EnrichmentSummary
          setActiveSection={props.setActiveSection}
          setActiveCategory={setActiveCategory}
          enrichmentSettings={enrichmentSettings}
          updateEnrichmentSettings={props.updateEnrichmentSettings}
          queueEnrichment={props.queueEnrichment}
        />
      )}
      {activeCategory && (
        <ConfigureEnrichmentCategory
          setActiveCategory={setActiveCategory}
          category={activeCategory}
          // @ts-ignore
          options={enrichmentOptions[activeCategory]}
          enrichmentSettings={enrichmentSettings[activeCategory]}
          updateEnrichmentSettings={props.updateEnrichmentSettings}
        />
      )}
    </Box>
  );
};
