import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Icon,
  PseudoBox,
  Stack,
} from '@chakra-ui/core';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '../Button';
import { SmallSubtitle, SmallText } from '../Typography';

export const HelpDrawer = (props: {
  isOpen: boolean;
  onClose(): void;
  section?: 'default' | 'shortcuts';
}) => {
  const { isOpen, onClose, section: sectionProps } = props;

  const [section, setSection] = useState<'default' | 'shortcuts'>(sectionProps ?? 'default');

  const history = useHistory();

  const handleCloseDrawer = () => {
    setSection('default');
    onClose();
  };

  const helpOptions = [
    {
      heading: 'Help Center',
      caption: 'Open Simpu Marketing Help Center',
      leftIcon: 'question',
      rightIcon: 'external-link',
      onClick: () =>
        window.open('https://support.simpu.co/en/collections/3580014-marketing', '_blank'),
    },
    {
      heading: 'Settings',
      caption: 'Access your workspace settings',
      leftIcon: 'settings',
      rightIcon: 'chevron-right',
      onClick: () => {
        history.push('/s/settings/overview');
        handleCloseDrawer();
      },
    },
    // {
    //   heading: 'Keyboard Shortcuts',
    //   caption: 'Work faster with keyboard shortcuts',
    //   leftIcon: 'keyboard',
    //   rightIcon: 'chevron-right',
    //   onClick: () => {
    //     setSection('shortcuts');
    //   },
    // },
  ];

  return (
    <Drawer isOpen={isOpen} onClose={handleCloseDrawer} isFullHeight={true}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton size="sm" />
        {section === 'default' && (
          <DrawerHeader px="1rem" py="0.5rem" fontSize="1rem" borderBottomWidth="1px">
            Help & Support
          </DrawerHeader>
        )}
        {/* {section === 'shortcuts' && (
          <DrawerHeader px="1rem" py="0.5rem" borderBottomWidth="1px">
            <Stack isInline alignItems="center">
              <IconButton
                size="xs"
                variant="ghost"
                aria-label="back"
                icon="chevron-left"
                onClick={() => setSection('default')}
              />
              <BodyText fontSize="1rem" fontWeight="600">
                Keyboard Shortcuts
              </BodyText>
            </Stack>
          </DrawerHeader>
        )} */}
        <DrawerBody p="1rem">
          {section === 'default' && (
            <>
              {helpOptions.map((item, index) => (
                <HelpOption key={`${index}`} {...item} />
              ))}
            </>
          )}
        </DrawerBody>
        <DrawerFooter
          px="1rem"
          alignItems="center"
          justifyContent="space-between"
          borderTopWidth="1px"
        >
          <Button
            size="sm"
            onClick={() => window.open('https://headwayapp.co/simpuhq-changelog', '_target')}
          >
            Changelog
          </Button>
          <Button size="sm">Contact us</Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

type HelpOptionProps = {
  heading: string;
  caption: string;
  leftIcon: string;
  rightIcon: string;
  onClick(): void;
};

const HelpOption = (props: HelpOptionProps) => {
  const { heading, caption, leftIcon, onClick, rightIcon } = props;

  return (
    <PseudoBox
      p="0.5rem"
      mb="1rem"
      width="100%"
      bg="gray.50"
      rounded="8px"
      display="flex"
      cursor="pointer"
      borderWidth="1px"
      onClick={onClick}
      alignItems="center"
      _hover={{
        bg: 'gray.100',
      }}
    >
      <Stack flex={1} isInline alignItems="flex-start">
        <Icon position="relative" top="3px" name={leftIcon} color="blue.500" />
        <Stack spacing="0">
          <SmallSubtitle fontWeight="500" fontSize="0.875rem">
            {heading}
          </SmallSubtitle>
          <SmallText>{caption}</SmallText>
        </Stack>
      </Stack>
      <Icon size="1rem" name={rightIcon} color="gray.500" />
    </PseudoBox>
  );
};

// const ShortcutItem = (props: { label: string; children: ReactNode }) => {
//   const { label, children } = props;
//   return (
//     <Flex pb="1rem" alignItems="center" justifyContent="space-between">
//       <BodyText>{label}</BodyText>
//       {children}
//     </Flex>
//   );
// };
