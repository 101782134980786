import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'root';
import { listWhatsappLogs, singleWhatsappLog } from './log.service';

export const fetchWhatsappLogs = createAsyncThunk('logs/fetchWhatsappLogs', async (params: any) => {
  const response = await listWhatsappLogs(params);
  return response;
});

export const fetchMoreWhatsappLogs = createAsyncThunk(
  'logs/fetchMoreWhatsappLogs',
  async ({ ...params }: any) => {
    const response = await listWhatsappLogs(params);
    return response;
  },
);

export const fetchSingleWhatsappLog = createAsyncThunk(
  'logs/fetchSingleWhatsappLog',
  async (id: string) => {
    const response = await singleWhatsappLog(id);
    return response;
  },
);

const initialState = {
  inbox_reports: [] as any,
  inbox_report: {} as any,
  meta: {} as any,
  loading: 'idle' as string,
  loadMore: 'idle' as string,
  loadSingleLog: 'idle' as string,
};

const whatsappLogSlice = createSlice({
  name: 'whatsapp_logs',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchWhatsappLogs.pending, (state, action) => {
        state.loading = 'pending';
      })
      .addCase(fetchWhatsappLogs.fulfilled, (state, action) => {
        state.loading = 'complete';
        const { data, meta } = action.payload;
        state.inbox_reports = data;
        state.meta = meta;
      })
      .addCase(fetchMoreWhatsappLogs.pending, (state, action) => {
        state.loadMore = 'pending';
      })
      .addCase(fetchMoreWhatsappLogs.fulfilled, (state, action) => {
        state.loadMore = 'complete';
        const { data, meta } = action.payload;
        state.inbox_reports = [...state.inbox_reports, ...data];
        state.meta = meta;
      })
      .addCase(fetchSingleWhatsappLog.pending, (state, action) => {
        state.loadSingleLog = 'pending';
      })
      .addCase(fetchSingleWhatsappLog.fulfilled, (state, action) => {
        state.loadSingleLog = 'complete';
        const { data } = action.payload;
        state.inbox_report = data;
      });
  },
});

export const whatsappLogsReducer = whatsappLogSlice.reducer;
export const selectFromWhatsappLogs = (state: RootState) => state.whatsappLogs;
