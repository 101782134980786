// @ts-nocheck
import React, { useRef, useState } from 'react';
import {
  Box,
  Icon,
  IconButton,
  Modal,
  ModalOverlay,
  useDisclosure,
  useToast,
} from '@chakra-ui/core';
import { useReactToPrint } from 'react-to-print';
import { RecordModalWrapper as Wrapper } from './index.styles';
import { getFieldRenderer } from '../../lists.utils';
import { GridColumnMenu } from '../grid/column-menu';
import { Menu, MenuItem, ToastBox, Button } from 'app/components';
import { Divider } from '@chakra-ui/core/dist';
import { Column } from '../../lists.types';

interface HeaderMenuOptionProps {
  label: string;
  onClick: Function;
  icon: string;
}

const HeaderMenuOption = (props: HeaderMenuOptionProps) => {
  const { label, onClick, icon, ...rest } = props;

  return (
    <Box
      {...rest}
      display="flex"
      flexDirection="row"
      alignItems="center"
      onClick={onClick}
      fontSize="12px"
    >
      <Icon name={icon} mr="0.25rem" />
      <Box>{label}</Box>
    </Box>
  );
};

interface FieldProps {
  value: any;
  column: any;
  updateCellValue: Function;
  updateSelectOption: Function;
}

export const Field = (props: FieldProps) => {
  const FieldComponent = getFieldRenderer(props.column.type);
  return <FieldComponent {...props} />;
};

const Header = (props: any) => {
  const { isOpen, onClose, onOpen } = useDisclosure();

  const [triggerPosition, setTriggerPosition] = useState({
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
  });

  const { column } = props;
  const triggerRef = useRef(null);

  const handleTriggerPressed = () => {
    const rect = triggerRef.current.getBoundingClientRect();
    setTriggerPosition({ ...rect, top: rect.top - 20, bottom: rect.bottom - 20 });
    onOpen();
  };

  const handleClose = () => {
    onClose();
  };

  const handleUpdateLabel = (columnID: string, label: string) => {
    props.updateColumnLabel(columnID, label);
  };

  const handleUpdateType = (columnID: string, type: string) => {
    props.updateColumnType(columnID, type);
  };

  const handleDelete = (columnID: string) => {
    handleClose();
    props.deleteColumn(columnID);
  };

  const handleHide = (columnID: string) => {
    handleClose();
    props.hideColumn(columnID);
  };
  const handleUpdate = (columnID: string, payload: {}) => {
    props.updateColumn(columnID, payload);
  };

  return (
    <>
      <Box paddingX="0.7rem" display="flex" flexDirection="row" alignItems="center" color="#A5ABB3">
        <Box fontWeight="500" fontSize="12px" paddingY="0.75rem" mr="0.5rem">
          {column.label}
        </Box>
        <Box
          className="label-menu"
          display="flex"
          alignItems="center"
          height="100%"
          paddingBottom="5px"
        >
          <IconButton
            ref={triggerRef}
            height="12px"
            className="icon"
            icon="chevron-down"
            size="10px"
            backgroundColor="transparent"
            onClick={handleTriggerPressed}
          />
        </Box>
      </Box>
      <GridColumnMenu
        parent_type="field"
        column={column}
        columns={props.columns}
        isOpen={isOpen}
        close={handleClose}
        columnID={column.uid}
        field_position={triggerPosition}
        deleteColumn={handleDelete}
        hideColumn={handleHide}
        updateLabel={handleUpdateLabel}
        updateType={handleUpdateType}
        updateColumn={handleUpdate}
        updateCustomization={props.updateColumnCustomization}
      />
    </>
  );
};

export const RecordModalComponent = (props: any) => {
  const modalRef = useRef();
  const {
    isOpen,
    onClose,
    node: { data, rowIndex },
    row_count,
    createColumn,
    deleteColumn,
    updateColumnCustomization,
    updateColumnLabel,
    updateColumnType,
    updateColumn,
    hideColumn,
    selectRows,
    deleteRows,
  } = props;

  const toast = useToast();

  const { updateSelectOption, addSelectOption } = props;

  const columns = props.columns_by_id.map((col_id: string) => props.columns[col_id]);

  const updateCellValue = (value, columnID) => {
    props.updateRowCell({
      row_id: data.uid,
      columnID,
      value,
    });
  };

  const handleCreateColumn = async () => {
    await createColumn();
    await modalRef.current.scrollTo({
      top: 100000000000,
      behavior: 'auto',
    });
  };

  const deleteRow = async () => {
    await selectRows([data.uid], 'id');
    onClose();
    deleteRows();
    toast({
      position: 'bottom-left',
      render: ({ onClose }) => (
        <ToastBox status="success" onClose={onClose} message="Record deleted" />
      ),
    });
  };

  const handlePrint = useReactToPrint({
    content: () => modalRef.current,
  });

  return (
    <Modal
      preserveScrollBarGap
      scrollBehavior="inside"
      isCentered
      size="sm"
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay />
      <Wrapper borderRadius="8px" ref={modalRef}>
        <Box className="heading-toolbar">
          <Box display="flex" alignItems="center" flexDirection="row">
            <IconButton
              variant="ghost"
              size="xs"
              aria-label="up"
              icon="chevron-up"
              mr="0.5rem"
              isDisabled={rowIndex === 0}
              onClick={() => props.openRow(rowIndex - 1)}
            />
            <IconButton
              variant="ghost"
              size="xs"
              aria-label="down"
              icon="chevron-down"
              isDisabled={rowIndex === row_count - 1}
              onClick={() => props.openRow(rowIndex + 1)}
            />
          </Box>
          <Box display="flex" alignItems="center" flexDirection="row">
            <Menu
              isOpen
              {...{
                renderItem: option => <MenuItem {...option} />,
                menuButtonProps: {
                  as: IconButton,
                  ariaLabel: 'more-options',
                  icon: 'overflow',
                  variant: 'ghost',
                  size: 'xs',
                },
                menuListProps: {
                  minWidth: 100,
                },
                options: [
                  {
                    children: (
                      <HeaderMenuOption
                        icon="inbox-report-download"
                        label="Print record"
                        onClick={handlePrint}
                      />
                    ),
                    key: 0,
                  },
                  {
                    children: (
                      <HeaderMenuOption icon="trash" label="Delete record" onClick={deleteRow} />
                    ),
                    key: 1,
                    color: '#BF2600',
                  },
                ],
              }}
            />
            <Box marginX="0.25rem">
              <Divider orientation="vertical" height="25px" color="#DADEE3" />
            </Box>
            <IconButton
              variant="ghost"
              size="xs"
              aria-label="close"
              icon="small-close"
              onClick={onClose}
            />
          </Box>
        </Box>

        <Box
          display="flex"
          flexDirection="column"
          width="100%"
          paddingX="1.5rem"
          paddingY="1.5rem"
          overflowY="auto"
          scrollBehavior="smooth"
        >
          {columns.map((column: Column, index: number) => {
            if (index === 0)
              return (
                <Box
                  className="column-item"
                  display="flex"
                  flexDirection="column"
                  width="100%"
                  marginBottom="1.5rem"
                >
                  <Box color="#A5ABB3" fontSize="12px" marginBottom="0.5rem" paddingX="0.7rem">
                    {column.label}
                  </Box>
                  <Field
                    {...{
                      value: data[column.uid],
                      columns: props.columns,
                      column,
                      updateCellValue,
                      updateSelectOption,
                      addSelectOption,
                    }}
                    fieldProps={{
                      variant: 'filled',
                      fontSize: 24,
                      fontWeight: 500,
                      borderRadius: '0.4rem',
                      backgroundColor: 'transparent',
                    }}
                  />
                </Box>
              );

            return (
              <Box
                className="column-item"
                display="flex"
                flexDirection="row"
                width="100%"
                marginBottom="1rem"
              >
                <Box width="40%">
                  <Header
                    {...{
                      column,
                      columns: props.columns,
                      deleteColumn,
                      updateColumnCustomization,
                      updateColumnLabel,
                      updateColumnType,
                      hideColumn,
                      updateColumn,
                    }}
                  />
                </Box>
                <Box flex={1}>
                  <Field
                    {...{
                      value: data[column.uid],
                      columns: props.columns,
                      placeholder: 'Start typing...',
                      column,
                      updateCellValue,
                      updateSelectOption,
                      addSelectOption,
                    }}
                    fieldProps={{
                      variant: 'filled',
                      fontSize: 14,
                      borderRadius: '0.25rem',
                      backgroundColor: 'transparent',
                      size: 'sm',
                    }}
                  />
                </Box>
              </Box>
            );
          })}
          <Box padding="1rem">
            <Button size="sm" variant="link" fontSize="14px" onClick={handleCreateColumn}>
              Add a new column to this list
            </Button>
          </Box>
        </Box>
      </Wrapper>
    </Modal>
  );
};
