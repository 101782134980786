import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'root';
import { listAirtimeLogs } from './log.service';

export const fetchAirtimeLogs = createAsyncThunk('logs/fetchAirtimeLogs', async (params: any) => {
  const response = await listAirtimeLogs(params);
  return response;
});

export const fetchMoreAirtimeLogs = createAsyncThunk(
  'logs/fetchMoreAirtimeLogs',
  async ({ ...params }: any) => {
    const response = await listAirtimeLogs(params);
    return response;
  },
);

const initialState = {
  airtime_reports: [] as any,
  meta: {} as any,
  loading: 'idle' as string,
  loadMore: 'idle' as string,
  loadSingleLog: 'idle' as string,
};

const airtimeLogSlice = createSlice({
  name: 'airtime_logs',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchAirtimeLogs.pending, (state, action) => {
        state.loading = 'pending';
      })
      .addCase(fetchAirtimeLogs.fulfilled, (state, action) => {
        state.loading = 'complete';
        const { data, meta } = action.payload;
        state.airtime_reports = data;
        state.meta = meta;
      })
      .addCase(fetchMoreAirtimeLogs.pending, (state, action) => {
        state.loadMore = 'pending';
      })
      .addCase(fetchMoreAirtimeLogs.fulfilled, (state, action) => {
        state.loadMore = 'complete';
        const { data, meta } = action.payload;
        state.airtime_reports = [...state.airtime_reports, ...data];
        state.meta = meta;
      });
  },
});

export const airtimeLogsReducer = airtimeLogSlice.reducer;
export const selectFromAirtimeLogs = (state: RootState) => state.airtimeLogs;
export const selectAirtimeLogs = (state: RootState) => state.airtimeLogs.airtime_reports;
export const selectSingleAirtimeLogs = createSelector(
  [selectAirtimeLogs, (state, id) => id],
  (items, id) => items.find((item: any) => item.id === id),
);
