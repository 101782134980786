import {
  Box,
  Checkbox,
  Icon,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Stack,
} from '@chakra-ui/core';
import React, { ChangeEvent, useState } from 'react';
import { PopoverWrapper } from '../../../list-view.styles';

export const DepthSelector = (props: {
  value: { [key: string]: boolean };
  onChange(value: { [key: string]: boolean }): void;
}) => {
  const { value: valueProp, onChange } = props;

  const [value, setValue] = useState<{ [key: string]: boolean }>(valueProp ?? {});

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const payload = {
      ...value,
      [e.target.value]: e.target.checked,
    };
    setValue(payload);
    onChange(payload);
  };

  return (
    <Popover>
      <PopoverTrigger>
        <Box className="operator-select" alignItems="center">
          <Box className="selected-label-text">Depth</Box>
          <Icon name="chevron-down" />
        </Box>
      </PopoverTrigger>
      <PopoverContent
        zIndex={4}
        width="max-content"
        boxShadow="none"
        _focus={{
          boxShadow: 'none',
          outline: 'none',
        }}
      >
        <PopoverWrapper>
          <Stack>
            <Checkbox size="sm" value="year" isChecked={value.year} onChange={handleChange}>
              Year
            </Checkbox>
            <Checkbox size="sm" value="month" isChecked={value.month} onChange={handleChange}>
              Month
            </Checkbox>
            <Checkbox size="sm" value="day" isChecked={value.day} onChange={handleChange}>
              Day
            </Checkbox>
          </Stack>
        </PopoverWrapper>
      </PopoverContent>
    </Popover>
  );
};
