import { Box, Link, Stack } from '@chakra-ui/core';
import React, { forwardRef } from 'react';
import { BodyText } from '../Typography';

type Bar = {
  key?: string;
  value: number;
  name: string;
  href?: string;
  target?: string;
  color?: string;
  icon?: any;
};

const getWidthsFromValues = (dataValues: number[]) => {
  let maxValue = -Infinity;
  dataValues.forEach(value => {
    maxValue = Math.max(maxValue, value);
  });

  return dataValues.map(value => {
    if (value === 0) return 0;
    return Math.max((value / maxValue) * 100, 1);
  });
};

export interface BarListProps extends React.HTMLAttributes<HTMLDivElement> {
  data: Bar[];
  color?: string;
  showAnimation?: boolean;
  valueFormatter?: (value: number) => string;
}

export const BarList = forwardRef<HTMLDivElement, BarListProps>((props, ref) => {
  const {
    data = [],
    color,
    valueFormatter = (value: number) => value.toString(),
    showAnimation = true,
    className,
    ...other
  } = props;

  const widths = getWidthsFromValues(data.map(item => item.value));

  return (
    <Stack ref={ref} isInline spacing="6" {...other}>
      <Box position="relative" w="100%">
        {data.map((item, idx) => {
          const Icon = item.icon;

          return (
            <Box
              height="8"
              rounded="md"
              display="flex"
              alignItems="center"
              width={`${widths[idx]}%`}
              key={item.key ?? item.name}
              bg={item.color ?? color ?? 'gray.500'}
              mb={idx === data.length - 1 ? '0' : '10px'}
              transition={showAnimation ? 'all 1s' : ''}
            >
              <Box display="flex" alignItems="center" position="absolute" maxW="100%" left="0.5rem">
                <Box mr={2}>{Icon ? Icon : null}</Box>
                {item.href ? (
                  <Link
                    color="gray.900"
                    href={item.href}
                    rel="noreferrer"
                    overflow="hidden"
                    whiteSpace="nowrap"
                    style={{
                      textOverflow: 'ellipsis',
                    }}
                    _hover={{
                      textDecoration: 'underline',
                    }}
                    target={item.target ?? '_blank'}
                  >
                    {item.name}
                  </Link>
                ) : (
                  <BodyText
                    color="gray.900"
                    overflow="hidden"
                    whiteSpace="nowrap"
                    fontSize="0.875rem"
                    style={{
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {item.name}
                  </BodyText>
                )}
              </Box>
            </Box>
          );
        })}
      </Box>
      <Box textAlign="right" minW="min-content">
        {data.map((item, idx) => (
          <Box
            height="8"
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            key={item.key ?? item.name}
            mb={idx === data.length - 1 ? '0' : '10px'}
          >
            <BodyText
              color="gray.800"
              overflow="hidden"
              whiteSpace="nowrap"
              fontSize="0.875rem"
              style={{
                textOverflow: 'ellipsis',
              }}
            >
              {valueFormatter(item.value)}
            </BodyText>
          </Box>
        ))}
      </Box>
    </Stack>
  );
});
