import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { APISecretKey, APIPublicKey } from '../settings.types';

const developersInitialState: { secretKeys: APISecretKey[]; publicKey?: APIPublicKey } = {
  secretKeys: [],
  publicKey: undefined,
};

export const developersSlice = createSlice({
  name: 'developers',
  initialState: developersInitialState,
  reducers: {
    getAPISecretKeys(state, action: PayloadAction<{ keys: APISecretKey[] }>) {
      const { keys } = action.payload;
      state.secretKeys = keys;
    },
    revokeAPISecretKeyItem(state, action: PayloadAction<{ id: string }>) {
      const { id } = action.payload;
      state.secretKeys = state.secretKeys.filter(i => i.id !== id);
    },
    getAPIPublicKeys(state, action: PayloadAction<{ key: APIPublicKey }>) {
      const { key } = action.payload;
      state.publicKey = key;
    },
  },
});
