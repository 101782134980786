import { Box, Grid } from '@chakra-ui/core';
import { Button, Input } from 'app/components';
import { useFormik } from 'formik';
import React from 'react';
import * as yup from 'yup';

const validationSchema = yup.object().shape({
  first_name: yup.string().required('First name is required'),
  last_name: yup.string().required('Last name is required'),
});

type ProfileFormInitialValues = yup.InferType<typeof validationSchema> & {
  email?: string;
};

type ProfileFormProps = {
  isLoading?: boolean;
  initialValues?: any;
  onSubmit: (values: ProfileFormInitialValues) => void;
};

export function ProfileForm({ onSubmit, isLoading, initialValues }: ProfileFormProps) {
  const formik = useFormik({
    validationSchema,
    initialValues: initialValues || {
      last_name: '',
      first_name: '',
    },
    onSubmit: (values: ProfileFormInitialValues) => onSubmit(values),
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid mb="0.5rem" gap="1rem" gridTemplateColumns={['auto', 'auto', 'auto auto', 'auto auto']}>
        <Box marginBottom="0.875rem">
          <Input
            size="md"
            type="text"
            id="first_name"
            name="first_name"
            label="First name"
            placeholder="Enter first name"
            onChange={formik.handleChange}
            value={formik.values.first_name}
            errorMessage={formik.errors.first_name}
            isInvalid={!!formik.touched.first_name && !!formik.errors.first_name}
          />
        </Box>
        <Box marginBottom="0.875rem">
          <Input
            type="text"
            id="last_name"
            name="last_name"
            label="Last name"
            placeholder="Enter last name"
            value={formik.values.last_name}
            onChange={formik.handleChange}
            errorMessage={formik.errors.last_name}
            isInvalid={!!formik.touched.last_name && !!formik.errors.last_name}
          />
        </Box>
        <Box marginBottom="0.875rem">
          <Input
            id="email"
            isDisabled
            type="text"
            name="email"
            label="Email address"
            onChange={formik.handleChange}
            placeholder="Enter email address"
            errorMessage={formik.errors.email}
            value={formik.values.email?.toLowerCase()}
            isInvalid={!!formik.touched.email && !!formik.errors.email}
          />
        </Box>
      </Grid>
      <Button size="sm" type="submit" variantColor="blue" isLoading={isLoading}>
        Update
      </Button>
    </form>
  );
}
