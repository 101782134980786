import { Box, Flex, Stack } from '@chakra-ui/core';
import styled from '@emotion/styled';
import { CodeEditor, Subtitle } from 'app/components';
import { FormikState } from 'formik';
import unescape from 'lodash/unescape';
//@ts-ignore
import { languages } from 'prismjs/components/prism-core';
import React from 'react';
import { CampaignPayload } from '../../campaigns.types';

export const EmailCodeEditor = (props: {
  onChange?: (value: string) => void;
  values: FormikState<Pick<CampaignPayload, 'content'>>['values'];
}) => {
  const { values, onChange } = props;

  const handleCodeChange = (value: string) => {
    onChange?.(value);
  };

  return (
    <EmailCodeEditorContainer
      isInline
      spacing="0"
      borderWidth="1px"
      flex={1}
      height="100%"
      overflow="hidden"
    >
      <Flex width="50%" flexDirection="column" bg="white" height="100%" overflowY="auto">
        <Box p="0.5rem" width="100%" borderBottomWidth="1px">
          <Subtitle textAlign="center">Edit Code</Subtitle>
        </Box>
        <Box pt="1rem" flex={1} height="100%" maxHeight="100%" overflowY="auto">
          <CodeEditor
            language={languages.html}
            className="simpu-code-editor"
            value={values.content ?? ''}
            onValueChange={handleCodeChange}
            style={{ minHeight: '100%' }}
          />
        </Box>
      </Flex>
      <Flex
        width="50%"
        bg="#f6fafd"
        height="100%"
        overflowY="auto"
        borderLeftWidth="1px"
        justifyContent="center"
      >
        <Box dangerouslySetInnerHTML={{ __html: unescape(values.content ?? '') }} />
      </Flex>
    </EmailCodeEditorContainer>
  );
};

export const EmailCodeEditorContainer = styled(Stack)`
  .simpu-code-editor {
    counter-reset: line;
  }

  .simpu-code-editor #codeEditor {
    outline: none;
    padding-left: 60px !important;
  }

  .simpu-code-editor pre {
    padding-left: 60px !important;
  }

  .simpu-code-editor .editorLineNumber {
    position: absolute;
    left: 0;
    color: #cccccc;
    text-align: right;
    width: 40px;
    font-weight: 100;
  }
`;
