/* eslint-disable react/no-unescaped-entities */
import {
  Box,
  Button,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Stack,
  Tooltip,
} from '@chakra-ui/core';
import { BodyText, PreTitle } from 'app/components';
import React from 'react';
import { useQuery } from 'react-query';
import { aiToneAnalyzer } from '../../campaigns.service';
import { useDebounce } from 'hooks';

export const ToneAnalyzer: React.FC<{
  text: string;
  showPopover?: boolean;
}> = props => {
  const { text, showPopover = true } = props;

  const debouncedSearchQuery = useDebounce(text, 1000);

  const TONE_EMOJIS = {
    casual: '👕',
    formal: '👔',
    encouraging: '👍',
    assertive: '☝️',
  };

  enum TONE {
    assertive = 'assertive',
    casual = 'casual',
    encouraging = 'encouraging',
    formal = 'formal',
  }

  const { data: tone, isLoading } = useQuery<keyof typeof TONE>(
    ['text-tone-analysis', debouncedSearchQuery],
    () => aiToneAnalyzer(debouncedSearchQuery),
    { enabled: !!debouncedSearchQuery },
  );

  if (!tone) {
    return null;
  }

  return showPopover ? (
    <Popover>
      <Tooltip
        zIndex={100000}
        placement="top"
        aria-label={TONE[tone]}
        label={`You're text sounds ${TONE[tone]} ${TONE_EMOJIS[tone]}`}
      >
        <Box display="inline-block">
          <PopoverTrigger>
            <Button
              size="xs"
              variant="ghost"
              fontSize="1rem"
              isLoading={isLoading}
              _focus={{ boxShadow: 'none' }}
              aria-label="Tone analyzer button"
            >
              {TONE_EMOJIS[tone]}
            </Button>
          </PopoverTrigger>
        </Box>
      </Tooltip>
      <PopoverContent
        p="1rem"
        maxW="220px"
        borderWidth="0"
        boxShadow="0px 5px 20px rgba(21, 27, 38, 0.08)"
        _focus={{ boxShadow: '0px 5px 20px rgba(21, 27, 38, 0.08)' }}
      >
        <Stack>
          <PreTitle>Simpu tone analyzer</PreTitle>
          <BodyText>Here's how your text sounds</BodyText>
          <BodyText>
            {TONE[tone]} {TONE_EMOJIS[tone]}
          </BodyText>
        </Stack>
      </PopoverContent>
    </Popover>
  ) : (
    <Tooltip
      zIndex={100000}
      placement="top"
      aria-label={TONE[tone]}
      label={`You're text sounds ${TONE[tone]} ${TONE_EMOJIS[tone]}`}
    >
      <Button
        size="xs"
        variant="ghost"
        fontSize="1rem"
        isLoading={isLoading}
        _focus={{ boxShadow: 'none' }}
        aria-label="Tone analyzer button"
      >
        {TONE_EMOJIS[tone]}
      </Button>
    </Tooltip>
  );
};
