import React, { useEffect } from 'react';
import { Box, Button, Icon } from '@chakra-ui/core';
import { useSelector } from 'react-redux';
import { selectColumnsData } from 'app/authenticated-app/lists/lists.selectors';
import { Column, ListAutomation } from 'app/authenticated-app/lists/lists.types';
import { FilterItem } from 'app/authenticated-app/lists/components/filter/filter-item';
import { available_operators } from 'app/authenticated-app/lists/list.data';

interface ConditionProps {
  condition: any;
  index: number;
  handleDelete: Function;
  handleUpdate: Function;
}

const TriggerCondition = (props: ConditionProps) => {
  const { index } = props;

  const { columns, columns_by_id, column_id_map } = useSelector(selectColumnsData);

  const column = Object.values(columns).find((i: any) => i.id === props.condition.column_id);

  if (!column) {
    return <></>;
  }

  const condition = {
    ...props.condition,
    // @ts-ignore
    columnID: column['uid'],
    subOperator: props.condition.sub_operator,
  };

  const handleUpdate = (conditionIndex: number, payload: any) => {
    if (payload['columnID']) {
      const updatedColumnUID = payload['columnID'];

      // @ts-ignore
      const updatedColumnID = column_id_map[updatedColumnUID];

      // @ts-ignore
      payload['column_id'] = updatedColumnID;
      delete payload.columnID;

      if (payload['column_id'] !== condition['columnID']) {
        // @ts-ignore
        const updatedColumn = columns[updatedColumnUID];

        const allowed_operator = Object.values(available_operators).find((i: any) =>
          // @ts-ignore
          i.column_types.includes(updatedColumn?.type),
        );
        // @ts-ignore
        payload['operator'] = allowed_operator['key'];
        if (['CREATED AT', 'UPDATED AT', 'DATE'].includes(updatedColumn.type))
          payload['sub_operator'] = 'exact date';
        else payload['sub_operator'] = null;

        if (['DND', 'CHECKBOX', 'BOOLEAN'].includes(updatedColumn.type)) payload['value'] = true;
      }
    }
    if (payload['subOperator']) {
      // @ts-ignore
      payload['sub_operator'] = payload['subOperator'];
      delete payload.subOperator;
    }
    props.handleUpdate(conditionIndex, payload);
  };

  return (
    <Box my="0.5rem">
      <FilterItem
        {...{
          ...condition,
          index,
          key: index,
          uid: index,
          columns,
          columns_by_id,
          allow_conjunction_select: index !== 0,
          updateFilter: handleUpdate,
          deleteFilter: props.handleDelete,
          debounceUpdate: true,
        }}
      />
    </Box>
  );
};

interface Props {
  automation: ListAutomation | null;
  handleTriggerConditionsUpdate: Function;
}

export const AutomationTriggerConditionsSection = (props: Props) => {
  const triggerConditions = props.automation?.trigger_conditions || [];
  const columnData = useSelector(selectColumnsData);

  const addCondition = () => {
    const firstColumnID = columnData.columns_by_id[0];
    const firstColumn: Column = columnData.columns[firstColumnID];

    let operator = 'contains';
    let subOperator = null;
    if (['SELECT', 'MULTI SELECT'].includes(firstColumn.type)) {
      operator = 'isAnyOf';
    }
    if (['DATE', 'CREATED AT', 'UPDATED AT'].includes(firstColumn.type)) {
      operator = 'dateEqual';
      subOperator = 'exact date';
    }
    if (firstColumn.type === 'NUMBER') {
      operator = 'equals';
    }

    let conjunction: 'and' | 'or' | null = null;
    if (triggerConditions?.length > 0) conjunction = 'and';
    if (triggerConditions?.length > 1) conjunction = triggerConditions[1].conjunction;

    const condition = {
      value: '',
      column_id: firstColumn['id'],
      sub_operator: subOperator,
      operator,
      conjunction,
    };

    const updatedConditions = [...triggerConditions, condition];
    props.handleTriggerConditionsUpdate(updatedConditions);
  };

  const updateCondition = (conditionIndex: number, payload: any) => {
    const updatedConditions: any[] = [...triggerConditions];
    const updatedCondition = {
      ...updatedConditions[conditionIndex],
      ...payload,
    };

    const conditionColumn: Column = Object.values(columnData.columns).find(
      // @ts-ignore
      (i: Column) => i.id === updatedCondition['column_id'],
    );

    if (['DATE', 'CREATED AT', 'UPDATED AT'].includes(conditionColumn.type)) {
      const { sub_operator } = updatedCondition;
      if (sub_operator === 'today') updatedCondition['meta_value'] = { diff: '0', period: 'day' };
      if (sub_operator === 'tomorrow')
        updatedCondition['meta_value'] = { diff: '+1', period: 'day' };
      if (sub_operator === 'yesterday')
        updatedCondition['meta_value'] = { diff: '-1', period: 'day' };
      if (sub_operator === 'one week ago')
        updatedCondition['meta_value'] = { diff: '-7', period: 'day' };
      if (sub_operator === 'one week from now')
        updatedCondition['meta_value'] = { diff: '+7', period: 'day' };
      if (sub_operator === 'one month ago')
        updatedCondition['meta_value'] = { diff: '-1', period: 'month' };
      if (sub_operator === 'one month from now')
        updatedCondition['meta_value'] = { diff: '+1', period: 'month' };
      if (sub_operator === 'number of days from now') {
        updatedCondition['meta_value'] = {
          diff: updatedCondition['value'] ? `+${updatedCondition['value']}` : '0',
          period: 'day',
        };
      }
    } else {
      updatedCondition['meta_value'] = null;
    }

    updatedConditions[conditionIndex] = updatedCondition;
    props.handleTriggerConditionsUpdate(updatedConditions);
  };

  const removeCondition = (conditionIndex: number) => {
    const updatedConditions = triggerConditions.filter(
      (i: any, index: number) => index !== conditionIndex,
    );

    if (updatedConditions.length > 0) {
      updatedConditions[0] = {
        ...updatedConditions[0],
        conjunction: null,
      };
    }
    props.handleTriggerConditionsUpdate(updatedConditions);
  };

  useEffect(() => {
    if (
      props.automation?.trigger_type === 'condition' &&
      props.automation.trigger_conditions.length === 0
    ) {
      addCondition();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.automation?.trigger_type]);

  return (
    <>
      <Box my="1rem" mb="0.5rem" fontWeight="500" fontSize="12px">
        CONDITIONS
      </Box>

      <Box
        borderRadius="6px"
        border="1px solid #DADEE3"
        padding="1rem 1rem"
        backgroundColor="#FFFFFF"
      >
        {triggerConditions.length === 0 && (
          <Box fontSize="14px" color="#A5ABB3">
            No trigger conditions configured
          </Box>
        )}

        {triggerConditions.map((i: any, index: number) => (
          <TriggerCondition
            key={index}
            index={index}
            condition={i}
            handleDelete={() => removeCondition(index)}
            handleUpdate={updateCondition}
          />
        ))}
      </Box>

      <Box width="100%" display="flex" my="1rem" justifyContent="center">
        <Button
          color="#026ae8"
          fontSize="14px"
          fontWeight="400"
          variant="link"
          onClick={addCondition}
        >
          <Icon name="inbox-plus-circle" mr="5px" />
          <Box>Add a condition</Box>
        </Button>
      </Box>
    </>
  );
};
