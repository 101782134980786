import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Flex,
  Heading,
  Link,
  Stack,
  useDisclosure,
  useToast,
} from '@chakra-ui/core';
import { deleteEmailDomain, getEmailDomains } from 'app/authenticated-app/marketing/campaigns';
import { EmailDomain } from 'app/authenticated-app/marketing/campaigns/campaigns.types';
import { BodyText, Button, ConfirmModal, Container, PreTitle, ToastBox } from 'app/components';
import { AxiosError } from 'axios';
import React from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { EmailDomainListItem } from './email-domain-list-item';
import { EmailDomainAuthModal } from './email-domain-modal';

export const SendingDomains = () => {
  const toast = useToast();
  const queryClient = useQueryClient();
  const {
    isOpen: isDomainAuthModalOpen,
    onOpen: onOpenDomainAuthModal,
    onClose: onCloseDomainAuthModal,
  } = useDisclosure();

  const [domainToView, setDomainToView] = React.useState<EmailDomain | undefined>();
  const [domainToDelete, setDomainToDelete] = React.useState<EmailDomain | undefined>();

  const { data: emailDomains } = useQuery<EmailDomain[]>('email-domains', getEmailDomains);

  const { mutate: mutateDeleteDomain, isLoading: isDeleteingDomain } = useMutation<
    any,
    AxiosError,
    any,
    any
  >((payload: EmailDomain) => deleteEmailDomain(payload.id), {
    onSuccess: () => {
      queryClient.invalidateQueries('email-domains');

      setDomainToDelete(undefined);

      toast({
        position: 'bottom-left',
        render: ({ onClose }) => (
          <ToastBox status="success" message="Domain deleted successfully" onClose={onClose} />
        ),
      });
    },
    onError: error => {
      queryClient.invalidateQueries('email-domains');
      toast({
        position: 'bottom-left',
        render: ({ onClose }) => <ToastBox message={error.message} onClose={onClose} />,
      });
    },
  });

  return (
    <Container maxW="640px">
      <Box mb="2rem">
        <Stack
          isInline
          mb="1rem"
          spacing="0"
          justifyContent="space-between"
          id="email-domain-authentication"
        >
          <Stack width="48%">
            <Heading size="md" fontWeight="semibold">
              Domain Authentication
            </Heading>
            <BodyText pb="0.5rem" color="#4f4f4f">
              Improve deliverability by proving to inbox providers that you own the domain you're
              sending from.
            </BodyText>
          </Stack>
          <Button size="sm" variantColor="blue" onClick={onOpenDomainAuthModal}>
            Authenticate domain
          </Button>
        </Stack>
        <Alert status="info" rounded="4px" alignItems="flex-start">
          <AlertIcon />
          <AlertDescription fontSize="0.875rem">
            <BodyText>
              This will require a technical team member i.e a developer or system administrator.
            </BodyText>
            <Link
              target="_blank"
              fontWeight="500"
              color="blue.500"
              href="https://support.simpu.co/en/articles/6640381-how-to-authenticate-your-domain-to-send-email-campaigns-on-simpu"
            >
              Learn more
            </Link>
          </AlertDescription>
        </Alert>
      </Box>
      {emailDomains && (
        <Box>
          <Flex pb="0.5rem" px="1rem" alignItems="center">
            <PreTitle color="gray.500" width="30%">
              Status
            </PreTitle>
            <PreTitle color="gray.500" width="70%">
              Domain
            </PreTitle>
          </Flex>
          <Box borderWidth="1px" borderBottomWidth="0">
            {emailDomains.map(domain => (
              <EmailDomainListItem
                key={domain.id}
                domain={domain}
                onView={() => {
                  setDomainToView(domain);
                  onOpenDomainAuthModal();
                }}
                onDelete={() => setDomainToDelete(domain)}
              />
            ))}
          </Box>
        </Box>
      )}
      {isDomainAuthModalOpen && (
        <EmailDomainAuthModal
          initialValues={domainToView}
          isOpen={isDomainAuthModalOpen}
          onClose={() => {
            setDomainToView(undefined);
            onCloseDomainAuthModal();
          }}
        />
      )}
      <ConfirmModal
        title="Delete Domain"
        isOpen={!!domainToDelete}
        isLoading={isDeleteingDomain}
        onClose={() => setDomainToDelete(undefined)}
        onConfirm={() => mutateDeleteDomain(domainToDelete)}
      />
    </Container>
  );
};
