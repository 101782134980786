import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../root';

export const selectListCount = createSelector(
  (state: RootState) => state.lists,
  lists => lists.lists_by_id.length,
);

export const selectLists = createSelector(
  (state: RootState) => state.lists,
  list => list.lists,
);

export const selectSmartLists = createSelector(
  (state: RootState) => state.lists,
  list => list.smart_lists,
);

export const selectAudiences = createSelector(
  (state: RootState) => state.lists,
  lists => lists.audiences,
);

export const selectListsById = createSelector(
  (state: RootState) => state.lists,
  list => list.lists_by_id,
);

export const selectSmartListsById = createSelector(
  (state: RootState) => state.lists,
  list => list.smart_lists_by_id,
);

export const selectAudiencesById = createSelector(
  (state: RootState) => state.lists,
  list => list.audiences_by_id,
);

export const selectListFilters = createSelector(
  (state: RootState) => state.lists.filters,
  (state: RootState) => state.lists.filters_by_id,
  (filters, filters_by_id) => ({ filters, filters_by_id }),
);

export const selectColumnsData = createSelector(
  (state: RootState) => state.lists.columns,
  (state: RootState) => state.lists.columns_by_id,
  (state: RootState) => state.lists.column_id_map,
  (columns, columns_by_id, column_id_map) => ({ columns, columns_by_id, column_id_map }),
);

export const selectRowsCount = createSelector(
  (state: RootState) => state.lists.rows_by_id,
  rows_by_id => rows_by_id.length,
);

export const selectListResources = createSelector(
  (state: RootState) => state.resources,
  resources => {
    // @ts-ignore
    return resources.by_id.map((i: string) => resources.data[i]);
  },
);

export const selectDataModels = createSelector(
  (state: RootState) => state.dataModels,
  dataModels => {
    // @ts-ignore
    return dataModels.by_id.map((i: string) => dataModels.data[i]);
  },
);

export const selectSegments = createSelector(
  (state: RootState) => state.segments,
  segments => {
    // @ts-ignore
    return segments.by_id.map((i: string) => segments.data[i]);
  },
);

export const selectListMeta = createSelector(
  (state: RootState) => state.lists,
  list => list.meta,
);

export const selectInitialListId = createSelector(
  (state: RootState) => state.lists,
  list => list.initial_list,
);

export const selectViews = createSelector(
  (state: RootState) => state.lists,
  lists => {
    // @ts-ignore
    return lists.views.by_id.map((i: string) => lists.views.data[i]);
  },
);

export const selectViewsLoading = createSelector(
  (state: RootState) => state.lists,
  lists => lists.views.loading,
);

export const selectEnrichmentSettingsLoading = createSelector(
  (state: RootState) => state.lists,
  lists => lists.enrichment_settings.loading,
);

export const selectEnrichmentSettings = createSelector(
  (state: RootState) => state.lists,
  lists => lists.enrichment_settings.data,
);

export const selectEnrichmentOptions = createSelector(
  (state: RootState) => state.lists,
  lists => lists.enrichment_options.data,
);

export const selectAutomations = createSelector(
  (state: RootState) => state.automations,
  // @ts-ignore
  automations => automations.by_id.map((id: string) => automations.data[id]),
);

export const selectAutomationActions = createSelector(
  (state: RootState) => state.automations,
  // @ts-ignore
  automations => automations.actions.by_id.map((id: string) => automations.actions.data[id]),
);

export const selectAutomationActionsLoading = createSelector(
  (state: RootState) => state.automations,
  automations => automations.actions.loading,
);

export const selectAutomationPanelOpen = createSelector(
  (state: RootState) => state.lists,
  // @ts-ignore
  lists => lists.uiConfig.automationPanelOpen,
);

export const selectAutomationPanelActiveSection = createSelector(
  (state: RootState) => state.lists,
  // @ts-ignore
  lists => lists.uiConfig.automationPanelActiveSection,
);

export const selectCreatedAtColumn = createSelector(
  (state: RootState) => state.lists,
  lists => {
    // @ts-ignore
    return lists.columns_by_id.find((i: string) => lists.columns[i].type === 'CREATED AT');
  },
);

export const selectUpdatedAtColumn = createSelector(
  (state: RootState) => state.lists,
  lists => {
    // @ts-ignore
    return lists.columns_by_id.find((i: string) => lists.columns[i].type === 'UPDATED AT');
  },
);
