import React, { useEffect } from 'react';
import { Box } from '@chakra-ui/core';
import { CustomCellWrapper as Wrapper } from '../../grid/custom-cell/index.styles';

export const TextCellComponent = (props: any) => {
  useEffect(() => {
    if (
      props.search_query !== '' &&
      props.value &&
      props.value?.toString().toLowerCase().includes(props.search_query.toLowerCase())
    ) {
      props.eGridCell.style.backgroundColor = '#fff3d4';
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Wrapper>
      <Box className="text-cell">
        <Box>{props.value}</Box>
      </Box>
    </Wrapper>
  );
};
