import { Box, Flex, Heading, Icon, PseudoBox, PseudoBoxProps, Stack } from '@chakra-ui/core';
import { BodyText, Container, SmallText, useResellerSettings } from 'app/components';
import React from 'react';
import { useHistory } from 'react-router-dom';

type Action = {
  icon: string;
  label: string;
  caption: string;
  onClick: () => void;
};

export const SettingsOverview = () => {
  const history = useHistory();
  const { name } = useResellerSettings();

  // const {
  //   isOpen: isHelpDrawerOpen,
  //   onClose: onCloseHelpDrawer,
  //   onOpen: onOpenHelpDrawer,
  // } = useDisclosure();

  const actions: Action[] = [
    {
      icon: 'user',
      label: 'Invite teammates',
      caption: 'Invite your teammates and collaborate with them on SMS and email campaigns.',
      onClick: () => history.push('/s/settings/organisation/people'),
    },
    {
      icon: 'team',
      label: 'Create teams',
      caption: 'Collaborate with your teammates on campaigns and',
      onClick: () => history.push('/s/settings/organisation/teams'),
    },
    {
      icon: 'email-forwarding',
      label: 'Setup email sending domains',
      caption: 'Setup your domains to send out email campaigns with your organisation emails.',
      onClick: () => history.push('/s/settings/organisation/sending-domains'),
    },
    {
      icon: 'marketing-email-report-recipients',
      label: 'Setup sender profiles',
      caption: 'Standout in your users inbox with unique sender ids and profiles.',
      onClick: () => history.push('/s/settings/organisation/sender-profiles'),
    },
    {
      icon: 'note',
      label: 'Setup your company profile',
      caption: 'Setup your company profile to be displayed at the footer of your email campaigns.',
      onClick: () => history.push('/s/settings/organisation/company-profile'),
    },
  ];

  const helpOptions: Action[] = [
    {
      icon: 'documentation',
      label: 'Start guide',
      caption: 'Quick tips for new users.',
      onClick: () =>
        window.open('https://support.simpu.co/en/collections/3580014-marketing', '_blank'),
    },
    {
      icon: 'question',
      label: 'Help center',
      caption: `Learn how ${name} works and ProTips.`,
      onClick: () =>
        window.open('https://support.simpu.co/en/collections/3580014-marketing', '_blank'),
    },
    // {
    //   icon: 'keyboard',
    //   label: 'Keyboard Shortcuts',
    //   caption: 'Work faster with keyboard shortcuts.',
    //   onClick: () => {
    //     onOpenHelpDrawer();
    //   },
    // },
  ];

  return (
    <Container>
      <Stack mb="2rem" pb="1rem">
        <Heading size="md" fontWeight="semibold">
          Settings overview
        </Heading>
        <BodyText>Manage your organisation settings.</BodyText>
      </Stack>
      <Box pb="2rem">
        <Heading pb="1rem" size="sm" fontWeight="semibold">
          Explore features
        </Heading>
        <Flex alignItems="flex-start" flexWrap="wrap">
          {actions.map((action, index) => (
            <ActionCard
              {...action}
              mb="1rem"
              mr="1rem"
              key={`${index}`}
              width="calc(33.33% - 1rem)"
            />
          ))}
        </Flex>
      </Box>
      <Box pb="2rem">
        <Heading pb="1rem" size="sm" fontWeight="semibold">
          Go further
        </Heading>
        <Flex alignItems="flex-start" flexWrap="wrap">
          {helpOptions.map((action, index) => (
            <HelpCard
              {...action}
              mb="1rem"
              mr="1rem"
              key={`${index}`}
              width="calc(33.33% - 1rem)"
            />
          ))}
        </Flex>
      </Box>
      {/* {isHelpDrawerOpen && (
        <HelpDrawer section="shortcuts" isOpen={isHelpDrawerOpen} onClose={onCloseHelpDrawer} />
      )} */}
    </Container>
  );
};

const ActionCard = (props: Action & Omit<PseudoBoxProps, 'onClick'>) => {
  const { label, caption, icon, onClick } = props;

  return (
    <PseudoBox
      as="button"
      p="0.875rem"
      bg="gray.50"
      rounded="4px"
      textAlign="left"
      color="gray.900"
      borderWidth="1px"
      //@ts-ignore
      onClick={onClick}
      transition="all 0.2s"
      alignItems="flex-start"
      justifyContent="flex-start"
      boxShadow="0 1px 2px 0 rgba(0, 0, 0, 0.05)"
      _hover={{
        color: 'white',
        bg: 'blue.500',
        borderColor: 'blue.500',
      }}
      {...props}
    >
      <Stack width="100%" alignItems="flex-start">
        <Stack isInline alignItems="center">
          <Icon name={icon} color="currentColor" />
          <BodyText fontWeight="500">{label}</BodyText>
        </Stack>
        <SmallText>{caption}</SmallText>
      </Stack>
    </PseudoBox>
  );
};

const HelpCard = (props: Action & Omit<PseudoBoxProps, 'onClick'>) => {
  const { label, caption, icon, onClick, ...rest } = props;

  return (
    <PseudoBox
      bg="white"
      p="0.875rem"
      display="flex"
      cursor="pointer"
      textAlign="left"
      color="gray.900"
      borderWidth="1px"
      //@ts-ignore
      onClick={onClick}
      alignItems="center"
      transition="all 0.2s"
      justifyContent="space-between"
      _hover={{
        bg: 'gray.50',
      }}
      {...rest}
    >
      <Stack flex={1} alignItems="flex-start">
        <Stack isInline alignItems="center">
          <Icon name={icon} color="currentColor" />
          <BodyText fontWeight="500">{label}</BodyText>
        </Stack>
        <SmallText>{caption}</SmallText>
      </Stack>
      <Icon size="1rem" name="chevron-right" color="gray.500" />
    </PseudoBox>
  );
};
