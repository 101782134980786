import {
  Box,
  Icon,
  IconButton,
  IconButtonProps,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  PseudoBox,
  PseudoBoxProps,
  Stack,
  Tooltip,
} from '@chakra-ui/core';
import styled from '@emotion/styled';
import React, { ReactNode } from 'react';
import { BodyText, SmallText } from '../Typography';

export const AppSwitcher = (props: { token: string; buttonProps?: Partial<IconButtonProps> }) => {
  const { token, buttonProps } = props;

  const apps = [
    {
      label: 'Inbox',
      description: 'inbox.simpu.co',
      icon: <Icon name="apps-menu-inbox" size="2rem" />,
      url: `${process.env.REACT_APP_INBOX_URL}/direct-auth?token=${token}`,
      isAcive: !!(window?.location?.origin === process.env.REACT_APP_INBOX_URL),
    },
    {
      label: 'Marketing',
      description: 'app.simpu.co',
      icon: <Icon name="apps-menu-marketing" size="2rem" />,
      url: `${process.env.REACT_APP_MARKETING_URL}/direct-auth?token=${token}`,
      isAcive: !!(window?.location?.origin === process.env.REACT_APP_MARKETING_URL),
    },
    // {
    //   label: 'Developers',
    //   description: 'developers.simpu.co',
    //   icon: <Icon name="apps-menu-developers" size="2rem" />,
    //   url: `${process.env.REACT_APP_DEVELOPERS_URL}/direct-auth?token=${token}`,
    //   isAcive: !!(window?.location?.origin === process.env.REACT_APP_DEVELOPERS_URL),
    // },
  ];

  return (
    <Popover placement="auto-end">
      <Tooltip label="Switch Apps" aria-label="Switch Apps">
        <Box display="inline-block">
          <PopoverTrigger>
            <IconButton
              size="xs"
              color="white"
              fontSize="1rem"
              variant="ghost"
              //@ts-ignore
              icon="apps-menu"
              aria-label="Apps"
              _focus={{ color: 'white', bg: 'rgba(255, 255, 255, 0.25)' }}
              _hover={{ color: 'white', bg: 'rgba(255, 255, 255, 0.25)' }}
              _active={{ color: 'white', bg: 'rgba(255, 255, 255, 0.25)' }}
              {...buttonProps}
            />
          </PopoverTrigger>
        </Box>
      </Tooltip>
      <PopoverContent
        zIndex={10}
        maxW="220px"
        rounded="8px"
        borderWidth="0"
        position="absolute"
        boxShadow="0px 5px 20px rgba(21, 27, 38, 0.08)"
        _focus={{ boxShadow: '0px 5px 20px rgba(21, 27, 38, 0.08)' }}
      >
        <PopoverBody px="0">
          <Stack>
            {apps?.map((app, index) => (
              <AppItem width="100%" key={`${app.label}-${index}`} {...app} />
            ))}
          </Stack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export const AppItem = (
  props: {
    url: string;
    label: string;
    icon: ReactNode;
    isActive?: boolean;
    description: string;
  } & PseudoBoxProps,
) => {
  const { url, icon, label, description, isActive, ...rest } = props;
  return (
    <AppItemContainer
      {...rest}
      as="button"
      display="flex"
      textAlign="left"
      p="0.25rem 0.5rem"
      alignItems="center"
      _hover={{
        bg: 'gray.50',
      }}
      //@ts-ignore
      disabled={isActive}
      _disabled={{
        cursor: 'default',
      }}
      onClick={isActive ? undefined : () => window.open(url, '_blank')}
    >
      {icon}
      <Box ml="0.25rem" flex={1}>
        <BodyText color="gray.900" fontWeight="500">
          {label}
        </BodyText>
        <SmallText color="gray.500">{description}</SmallText>
      </Box>
      {isActive ? (
        <Icon name="check" size="0.75rem" />
      ) : (
        <Icon
          opacity={0}
          color="blue.500"
          className="arrow"
          name="arrow-forward"
          willChange="opacity"
          transition="opacity 0.2s ease-in"
        />
      )}
    </AppItemContainer>
  );
};

const AppItemContainer = styled(PseudoBox)`
  &:hover {
    .arrow {
      opacity: 1;
    }
  }
`;
