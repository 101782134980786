import React from 'react';
import { Box, BoxProps } from '@chakra-ui/core';

export type ContentWrapperProps = { children: React.ReactNode } & BoxProps;

export const ContentWrapper = ({ children, ...rest }: ContentWrapperProps) => {
  return (
    <Box
        backgroundColor="white"
        minHeight="100%"
        height="100%"
        overflowY="auto"
        {...rest}
    >
      {children}
    </Box>
  );
};
