import {
  Avatar,
  Box,
  Flex,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from '@chakra-ui/core';
import format from 'date-fns/format';
import { capitalize } from 'lodash';
import React, { SyntheticEvent } from 'react';
import { useSelector } from 'react-redux';
import { loadState } from 'utils';
import usePermission from 'utils/usePermission';
import { PermissionDropdown } from './permission-dropdown';
import { selectAllPermissions } from './redux';

export const OptionItem = (props: {
  onClick?: Function;
  icon: string;
  label: string;
  color?: string;
}) => (
  <MenuItem
    // @ts-ignore
    onClick={props?.onClick}
    color={props.color}
    fontSize="12px"
  >
    <Icon name={props.icon} size="14px" mr="10px" color={props.color} />
    {props.label}
  </MenuItem>
);

export const PeopleTableColumns = ({
  copyMemberEmail,
  onDeleteMember,
  onUpdateMember,
  onUpdateAccess,
}: any) => [
  {
    Header: 'Name',
    width: 225,
    accessor: '',
    Cell: ({ row: { original } }: any) => {
      const name = `${capitalize(original.first_name)} ${capitalize(original.last_name)}`;
      return (
        <Box display="flex" height="100%" alignItems="center">
          <Avatar size="sm" name={name} color="white" marginRight="15px" src={original.image} />
          <Box>{name}</Box>
        </Box>
      );
    },
  },
  {
    Header: 'Email',
    width: 375,
    accessor: '',
    Cell: ({ row: { original } }: any) => {
      return (
        <Box color="#757575" height="100%" display="flex" alignItems="center">
          {original.email}
        </Box>
      );
    },
  },
  {
    Header: 'Role',
    width: 225,
    accessor: '',
    Cell: ({ row: { original } }: any) => {
      const permissionsData = useSelector(selectAllPermissions).permissionsData;
      const { permission_id, name, page_access, permissions: p } = original;
      const permission = {
        id: permission_id,
        name,
        permissions: p,
        page_access,
        created_datetime: '',
        updated_datetime: '',
      };

      const {
        profile: { permissions },
      } = loadState();

      const updateThePermission = (data: any) => {
        onUpdateAccess(data);
      };

      const requiresPermission = usePermission('people.invite', permissions || []);

      return (
        <>
          {requiresPermission ? (
            <PermissionDropdown
              buttonProps={{
                borderWidth: '0',
              }}
              popoverProps={{
                placement: 'bottom',
              }}
              permissions={permissionsData}
              updateData={() => onUpdateMember(original)}
              handleUpdatedAccess={updateThePermission}
              permission={permission}
            />
          ) : (
            <Flex height="100%" align="center">
              {permission.name}
            </Flex>
          )}
        </>
      );
    },
  },
  {
    Header: 'Last Login',
    accessor: '',
    Cell: ({ row: { original } }: any) => {
      return (
        <Box color="#757575" height="100%" display="flex" alignItems="center">
          {original.updated_datetime && format(new Date(original.last_login), 'MMM do')}
        </Box>
      );
    },
  },
  {
    Header: '',
    width: 100,
    accessor: 'id',
    Cell: ({ row: { original } }: any) => {
      const {
        profile: { permissions },
      } = loadState();
      const requiresPermission = usePermission('people.invite', permissions || []);
      return (
        <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center">
          <Menu>
            <MenuButton
              as={IconButton}
              // @ts-ignore
              icon="overflow"
              size="sm"
              padding=".5rem"
              variant="ghost"
              height="auto"
              minWidth="auto"
              onClick={(event: SyntheticEvent) => event.stopPropagation()}
            />

            <MenuList minWidth="150px" placement="bottom">
              <OptionItem
                icon="copy"
                label="Copy email"
                onClick={(e: SyntheticEvent) => {
                  e.stopPropagation();
                  copyMemberEmail(original);
                }}
              />
              {requiresPermission && (
                <OptionItem
                  icon="delete"
                  label="Remove user"
                  color="#E73D51"
                  onClick={(e: SyntheticEvent) => {
                    e.stopPropagation();
                    onDeleteMember(original);
                  }}
                />
              )}
            </MenuList>
          </Menu>
        </Box>
      );
    },
  },
];
