import { buildAIURL, client } from 'utils';
import { List, MergeListsPayload } from '../lists.types';

const updateList = async (listID: string, payload: any): Promise<List> => {
  const { data }: { data: List } = await client(`lists/list/${listID}`, {
    method: 'PATCH',
    data: payload,
  });
  return data;
};

const detectColumns = async (
  payload: { [key: string]: any }[],
): Promise<{ [key: string]: any }> => {
  const { data } = await client('', {
    url: buildAIURL('/lists/data_types'),
    method: 'POST',
    data: payload,
  });
  return data;
};

const cleanDuplicates = async (listID: string, pivotColumns: string[]) => {
  return client(`lists/list/${listID}/clean`, {
    method: 'PUT',
    data: {
      pivot_columns: pivotColumns,
    },
  });
};

const mergeLists = async (payload: MergeListsPayload) => {
  const { data } = await client('lists/list/merge', {
    method: 'POST',
    data: payload,
  });
  return data;
};

const checkForDuplicates = async (list_id: string, payload: { pivot_columns: string[] }) => {
  const { data } = await client(`lists/list/duplicate/${list_id}/check`, {
    method: 'PUT',
    data: payload,
  });
  return data;
};

const listDuplicateGroups = async (duplicate_check_id: string) => {
  const { data } = await client(`lists/list/duplicate/${duplicate_check_id}/group`);
  return data;
};

const resolveDuplicateGroup = async (payload: {
  row: string;
  duplicate_check_id: string;
  duplicate_group_id: string;
}) => {
  const { row, duplicate_check_id, duplicate_group_id } = payload;
  const { data } = await client(
    `lists/list/duplicate/${duplicate_check_id}/group/${duplicate_group_id}`,
    {
      method: 'PUT',
      params: { row },
    },
  );
  return data;
};

export const ListService = {
  updateList,
  detectColumns,
  cleanDuplicates,
  mergeLists,
  checkForDuplicates,
  listDuplicateGroups,
  resolveDuplicateGroup,
};
