import { Box, Heading, Stack, useToast } from '@chakra-ui/core';
import { BodyText, Button, Container, Input, Textarea, ToastBox } from 'app/components';
import { selectOrganisationID } from 'app/unauthenticated-app/authentication';
import { useFormik } from 'formik';
import React from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import * as yup from 'yup';
import { getCompanyProfile, saveCompanyProfile, updateCompanyProfile } from '../../service';
import { selectOrganisations } from '../../slices';

export type CompanyProfilePayload = {
  name: string;
  website: string;
  postal_address: string;
  footer: string;
  id?: string;
};

export const CompanyProfile = () => {
  const toast = useToast();
  const queryClient = useQueryClient();
  const organizations = useSelector(selectOrganisations);
  const organizationID = useSelector(selectOrganisationID);
  const currentOrganization = organizations.find((i: any) => i.id === organizationID);

  const { data: companyProfile, isLoading: isLoadingCompanyProfile } =
    useQuery<CompanyProfilePayload>(['company-profile'], getCompanyProfile);

  const { mutateAsync: saveCompanyProfileMutate, isLoading: isSavingCompanyProfile } = useMutation(
    (payload: CompanyProfilePayload) => saveCompanyProfile(payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['company-profile']);
        toast({
          position: 'bottom-left',
          render: ({ onClose }) => (
            <ToastBox
              status="success"
              onClick={onClose}
              message="Company profile saved successfully"
            />
          ),
        });
      },
      onError: error => {
        toast({
          position: 'bottom-left',
          //@ts-ignore
          render: ({ onClose }) => <ToastBox onClick={onClose} message={error.message} />,
        });
      },
    },
  );

  const { mutateAsync: updateCompanyProfileMutate, isLoading: isUpdatingCompanyProfile } =
    useMutation((payload: Partial<CompanyProfilePayload>) => updateCompanyProfile(payload), {
      onSuccess: () => {
        queryClient.invalidateQueries(['company-profile']);
        toast({
          position: 'bottom-left',
          render: ({ onClose }) => (
            <ToastBox
              status="success"
              onClick={onClose}
              message="Company profile updated successfully"
            />
          ),
        });
      },
      onError: error => {
        toast({
          position: 'bottom-left',
          //@ts-ignore
          render: ({ onClose }) => <ToastBox onClick={onClose} message={error.message} />,
        });
      },
    });

  const handleFormSubmit = async (payload: CompanyProfilePayload) => {
    if (companyProfile) {
      await updateCompanyProfileMutate({ ...payload, id: companyProfile?.id });
    } else {
      await saveCompanyProfileMutate(payload);
    }
  };

  const { values, errors, touched, handleChange, handleSubmit } = useFormik<CompanyProfilePayload>({
    initialValues: {
      name: companyProfile?.name ?? currentOrganization?.name ?? '',
      website: companyProfile?.website ?? '',
      footer: companyProfile?.footer ?? '',
      postal_address: companyProfile?.postal_address ?? '',
    },
    onSubmit: handleFormSubmit,
    validationSchema: yup.object().shape({
      name: yup.string().required('Company name is required'),
      website: yup
        .string()
        .matches(
          /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/,
          'Enter a valid url',
        ),
    }),
  });

  return (
    <Container maxW="640px">
      <Stack marginBottom="3rem">
        <Heading size="md" fontWeight="semibold">
          Company Profile
        </Heading>
        <BodyText>
          Setup your company profile to be displayed at the footer of your email campaigns.
        </BodyText>
      </Stack>
      <Box>
        <form onSubmit={handleSubmit}>
          <Stack spacing="2rem" alignItems="flex-start">
            <Box width="100%">
              <Input
                name="name"
                label="Company name"
                value={values.name}
                onChange={handleChange}
                errorMessage={errors.name}
                isInvalid={!!touched.name && !!errors.name}
              />
            </Box>
            <Box width="100%">
              <Input
                type="url"
                name="website"
                label="Company website"
                value={values.website}
                onChange={handleChange}
                errorMessage={errors.website}
                isInvalid={!!touched.website && !!errors.website}
              />
            </Box>
            <Box width="100%">
              <Textarea
                name="postal_address"
                label="Company address"
                onChange={handleChange}
                value={values.postal_address}
              />
            </Box>
            <Button
              size="sm"
              type="submit"
              variantColor="blue"
              isDisabled={isLoadingCompanyProfile}
              isLoading={isSavingCompanyProfile || isUpdatingCompanyProfile}
            >
              Save changes
            </Button>
          </Stack>
        </form>
      </Box>
    </Container>
  );
};
