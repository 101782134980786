import { Box, Button, Collapse, ModalBody, Stack, Textarea, useDisclosure } from '@chakra-ui/core';
import { BodyText, ModalContainer, ModalContainerOptions, SmallSubtitle } from 'app/components';
import React, { useEffect, useRef, useState } from 'react';
import { ToneAnalyzer } from './tone-analyzer';
import { aiGenerateReply } from '../../campaigns.service';
import { track } from 'utils/segment';

export const AIDraftGenerator: React.FC<{ onUseGeneratedText(text: string): void }> = ({
  onUseGeneratedText,
}) => {
  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <>
      <Button size="xs" variantColor="blue" onClick={onOpen}>
        Generate
      </Button>
      <AIDraftGeneratorModal
        isOpen={isOpen}
        onClose={onClose}
        onUseGeneratedText={onUseGeneratedText}
      />
    </>
  );
};

export const AIDraftGeneratorModal: React.FC<
  ModalContainerOptions & { textToReply?: string; onUseGeneratedText(text: string): void }
> = ({ isOpen, onClose, textToReply, onUseGeneratedText }) => {
  const [prompt, setPrompt] = useState('');
  const [generatedText, setGeneratedText] = useState('');
  const [isGenerating, setIsGenerating] = useState(false);

  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.focus();
    }
  }, []);

  const handleGenerateText = async () => {
    try {
      setIsGenerating(true);
      const text = await aiGenerateReply({ prompt, text: textToReply });
      setIsGenerating(false);
      setGeneratedText(text);
      track('AI Marketing Content Generated');
    } catch (error) {
      setIsGenerating(false);
    }
  };

  const handleCloseModal = () => {
    setPrompt('');
    setGeneratedText('');
    onClose?.();
  };

  const handleUseGeneratedText = () => {
    onUseGeneratedText(generatedText.replace(/^(['"])(.*)\1$/, '$2'));
    track('AI Marketing Content Used');
    handleCloseModal();
  };

  return (
    <ModalContainer
      size="lg"
      showCloseButton
      isOpen={isOpen}
      onClose={onClose}
      initialFocusRef={textAreaRef}
      title="Draft with Simpu AI ✨"
    >
      <ModalBody pt="0" px="0">
        <Stack px="1rem" pb="1rem">
          <Textarea
            value={prompt}
            ref={textAreaRef}
            fontSize="0.875rem"
            onChange={(e: any) => setPrompt(e.target.value)}
            placeholder="Type a short message, and Simpu AI will generate a draft"
          />
          <Collapse isOpen={!!generatedText}>
            <Stack>
              <SmallSubtitle>Generated text</SmallSubtitle>
              <Box p="1rem" rounded="8px" borderWidth="1px">
                <BodyText fontSize="0.875rem">{generatedText}</BodyText>
              </Box>
            </Stack>
          </Collapse>
        </Stack>
        <>
          {!!generatedText ? (
            <Stack
              p="1rem"
              isInline
              alignItems="center"
              borderTopWidth="1px"
              borderTopColor="gray.50"
            >
              <Box>
                <ToneAnalyzer text={generatedText ?? ''} showPopover={false} />
              </Box>
              <Box>
                <Button size="sm" variantColor="blue" onClick={handleUseGeneratedText}>
                  Use text
                </Button>
              </Box>
              <Box>
                <Button
                  size="sm"
                  variant="outline"
                  isLoading={isGenerating}
                  onClick={handleGenerateText}
                >
                  Regenerate
                </Button>
              </Box>
            </Stack>
          ) : (
            <Stack
              p="1rem"
              isInline
              alignItems="center"
              borderTopWidth="1px"
              borderTopColor="gray.50"
            >
              <Button
                size="sm"
                variantColor="blue"
                isDisabled={!prompt}
                isLoading={isGenerating}
                onClick={handleGenerateText}
              >
                Generate
              </Button>
            </Stack>
          )}
        </>
      </ModalBody>
    </ModalContainer>
  );
};
