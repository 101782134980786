import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'root';
import { getCustomPermissions, getPermissions, getPermissionSettings, savePermissions, sendInvite, updatePermissions, updateUserPermission } from './permissions.service';

export const fetchBulitInPermissions = createAsyncThunk('permissions/fetchBuiltInPermissions', async () => {
  const response = await getPermissions();
  return response.data;
});

export const postPermissions = createAsyncThunk('permissions/savePermission', async (data) => {
  const response = await savePermissions(data);
  return response.data;
});

export const patchPermissions = createAsyncThunk('permissions/updatePermission', async (data) => {
  const response = await updatePermissions(data);
  return response.data;
});

export const fetchCustomPermissions = createAsyncThunk('permissions/fetchCustomPermissions', async () => {
  const response = await getCustomPermissions();
  return response.data;
});

export const fetchPermissionSettings = createAsyncThunk('permissions/fetchPermissionSettings', async () => {
  const response = await getPermissionSettings();
  return response.data;
});

export const patchUserPermissions = createAsyncThunk('permissions/updateUserPermissions', async (data: any) => {
  const response = await updateUserPermission(data);
  return response.data;
});

export const sendAnInvite = createAsyncThunk('organisation/invite', async (data: any) => {
  const { email, link, permission_id, page_access } = data;
  const response = await sendInvite({ email, link, permission_id, page_access });
  return response.data;
});

const initialState = {
  permissionsData: [] as any,
  updatedMember: {} as any,
  loading: 'idle' as string,
  userRoleUpdate: 'idle' as string
}

const permissionSlice = createSlice({
  name: 'permissions',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchBulitInPermissions.fulfilled, (state, action) => {
        state.loading = 'complete';
        const { permissions } = action.payload;
        state.permissionsData = permissions;
      })
  }
});

export const permissionsReducer =  permissionSlice.reducer;

export const selectAllPermissions = (state: RootState) => state.permissions;