import { getResellerSettingsByOrigin } from 'app/authenticated-app/settings';
import { ResellerSettingsType } from 'app/authenticated-app/settings/settings.types';
import { createContext } from 'hooks';
import React, { ReactNode, useEffect } from 'react';
import { useQuery } from 'react-query';

export const [Provider, useResellerSettings] = createContext<ResellerSettingsType | any>({});

export const ResellerSettingsProvider = (props: { children: ReactNode }) => {
  const { data: resellerSettings } = useQuery<ResellerSettingsType>(
    ['reseller-settings-by-origin'],
    getResellerSettingsByOrigin,
    {
      initialData: {
        url: '',
        name: 'Simpu',
        logo: '/images/logo/logo-with-text-black.png',
        email_template: {
          sender_email: 'info@simpu.co',
        },
        meta: {
          favicon: '/favicon.ico',
        },
      },
      enabled:
        process.env.NODE_ENV === 'production'
          ? window.location.origin !== process.env.REACT_APP_MARKETING_URL
          : false,
    },
  );

  useEffect(() => {
    document.title = resellerSettings?.name ?? 'Simpu';
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement('link');
      //@ts-ignore
      link.rel = 'icon';
      document.getElementsByTagName('head')[0].appendChild(link);
    }
    //@ts-ignore
    link.href = resellerSettings?.meta?.favicon ?? resellerSettings?.logo;
  }, [resellerSettings]);

  return <Provider value={resellerSettings ?? {}}>{props.children}</Provider>;
};
