import React from 'react';
import { Box, Checkbox } from '@chakra-ui/core/dist';

export const CheckboxFieldComponent = (props: any) => {
  const { inputProps = {} } = props;

  const { column, isDisabled } = props;

  const handleChange = async (event: any) => {
    const value: boolean = event.target.checked;
    props.updateCellValue(value, column.uid);
  };

  return (
    <Box
      padding="7.5px 12.5px"
      width="100%"
      borderRadius="3px"
      display="flex"
      flexWrap="wrap"
      alignItems="center"
      flexDirection="row"
      fontSize="16px"
      border="1px solid #E2E8F0"
      {...inputProps}
    >
      <Checkbox
        isDisabled={isDisabled}
        borderRadius="5px"
        size="md"
        variantColor="blue"
        className="checkbox-cell"
        onChange={handleChange}
        defaultIsChecked={!!props.value}
      />
    </Box>
  );
};
