import { PropertySchema } from '../property';

export const defaultProperties = [
  {
    name: 'name',
    type: 'TEXT',
    label: 'Name',
    hidden: false,
  },
  {
    name: 'email',
    type: 'EMAIL',
    hidden: false,
    label: 'Email Address',
  },
  {
    name: 'phone',
    hidden: false,
    type: 'PHONE NUMBER',
    label: 'Phone Number',
  },
] as PropertySchema[];
