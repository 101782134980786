import { useDisclosure, useToast } from '@chakra-ui/core';
import {
  selectAudiences,
  selectAudiencesById,
  selectDataModels,
  selectLists,
  selectListsById,
  selectSegments,
  selectSmartLists,
  selectSmartListsById,
} from 'app/authenticated-app/lists';
import { listSMSSenderProfiles, saveSMSSenderProfile } from 'app/authenticated-app/settings';
import { SMSSenderProfilePayload } from 'app/authenticated-app/settings/component/sender-profiles/sms-sender-profile-modal';
import { selectOrganisation } from 'app/authenticated-app/settings/slices';
import { SelectOptions } from 'app/authenticated-app/tables';
import { ToastBox } from 'app/components';
import { selectProfile, selectUser } from 'app/unauthenticated-app/authentication';
import { AxiosError } from 'axios';
import { IEmojiData } from 'emoji-picker-react';
import { FormikHelpers, useFormik } from 'formik';
import { useLoading } from 'hooks';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useQuery as useQueryParams } from 'hooks';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RootState } from 'root';
import { client, contentHasURL, contentWithoutURL, textNodesUnder, toFormData } from 'utils';
import { track } from 'utils/segment';
import * as yup from 'yup';
import { shortenLink, TemplateData } from '../../templates';
import { html2Text } from '../../templates/templates.utils';
import {
  editCampaign,
  fetchEthnicGroups,
  fetchGenders,
  fetchReligions,
  fetchStates,
  selectEthnicGroups,
  selectGenders,
  selectReligions,
  selectStates,
} from '../campaigns.reducer';
import {
  campaignFrequencyFuzzy,
  campaignTemplateFuzzy,
  deleteCampaignItem,
  getCampaignAudience,
  saveCampaignItemState,
  sendTestCampaignMessage,
} from '../campaigns.service';
import { CampaignData, CampaignPayload } from '../campaigns.types';
import { PlannerPropsWithRedux } from '../planner.types';

export const useSMSCampaign = (
  props: Pick<
    PlannerPropsWithRedux,
    | 'addAudience'
    | 'fetchWallet'
    | 'importBulkUpload'
    | 'editTemplate'
    | 'updateCampaign'
    | 'saveCampaignDraft'
  > & {
    initialValues?: CampaignPayload;
    onSubmit: (payload: Partial<CampaignPayload>) => void;
  },
) => {
  const {
    fetchWallet,
    addAudience,
    editTemplate,
    updateCampaign,
    initialValues,
    importBulkUpload,
    saveCampaignDraft,
  } = props;

  const [smsCount, setSmsCount] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [currentEmoji, setCurrentEmoji] = useState('');
  const [tags, setTags] = useState<SelectOptions[]>([]);
  const [characterCount, setCharacterCount] = useState(0);
  const [frequencyType, setFrequencyType] = useState(false);
  const [isNotRegularText, setIsNotRegularText] = useState(false);
  const [suggestedTemplates, setSuggestedTemplates] = useState([]);
  const [hasNoPhoneNumberType, setHasNoPhoneNumberType] = useState(false);
  const [editorPlaceholder, setEditorPlaceholder] = useState('Write SMS content here...');
  const [selectedTemplate, setSelectedTemplate] = useState<TemplateData | undefined>();

  const user = useSelector(selectUser);
  const lists: any = useSelector(selectLists);
  const states = useSelector(selectStates);
  const profile = useSelector(selectProfile);
  const genders = useSelector(selectGenders);
  const segments = useSelector(selectSegments);
  const religions = useSelector(selectReligions);
  const audiences = useSelector(selectAudiences);
  const smart_lists: any = useSelector(selectSmartLists);
  const ethnicGroups = useSelector(selectEthnicGroups);
  const audiences_by_id = useSelector(selectAudiencesById);
  const lists_by_id: string[] = useSelector(selectListsById);
  const smart_lists_by_id = useSelector(selectSmartListsById);
  const data_models = useSelector(selectDataModels);
  const organisation = useSelector((state: RootState) =>
    selectOrganisation(state, profile?.organisation_id ?? ''),
  );

  const toast = useToast();
  const history = useHistory();
  const query = useQueryParams();
  const reduxDispatch = useDispatch();
  const queryClient = useQueryClient();
  const { dispatch, loading } = useLoading();
  const {
    isOpen: isOpenNewAudienceModal,
    onOpen: onOpenNewAudienceModal,
    onClose: onCloseNewAudienceModal,
  } = useDisclosure();
  const {
    isOpen: templateModalIsOpen,
    onOpen: onOpenTemplateModal,
    onClose: onCloseTemplateModal,
  } = useDisclosure();
  const {
    isOpen: campaignLinkModalIsOpen,
    onOpen: onOpenCampaignLinkModal,
    onClose: onCloseCampaignLinkModal,
  } = useDisclosure();
  const {
    isOpen: testMessageModalIsOpen,
    onOpen: onOpenTestMessageModal,
    onClose: onCloseTestMessageModal,
  } = useDisclosure();
  const {
    isOpen: sendCampaignIsOpen,
    onOpen: onOpenSendCampaignModal,
    onClose: onCloseSendCampaignModal,
  } = useDisclosure();
  const {
    isOpen: deleteCampaignModalIsOpen,
    onOpen: onOpenDeleteCampaignModal,
    onClose: onCloseDeleteCampaignModal,
  } = useDisclosure();
  const {
    isOpen: copyCampaignModalIsOpen,
    onOpen: onOpenCopyCampaignModal,
    onClose: onCloseCopyCampaignModal,
  } = useDisclosure();
  const {
    isOpen: isSMSSenderProfileModalOpen,
    onOpen: onOpenSMSSenderProfileModal,
    onClose: onCloseSMSSenderProfileModal,
  } = useDisclosure();
  const {
    isOpen: isAIContentGenerationModalOpen,
    onOpen: onOpenAIContentGenerationModal,
    onClose: onCloseAIContentGenerationModal,
  } = useDisclosure();

  const editableRef = useRef<any>();

  const { isOpen: isPastTime, onClose: onCloseIsPastTimeBanner } = useDisclosure(
    new Date().getHours() >= 20,
  );

  const { data: sender_ids } = useQuery(['sms-sender-profiles'], listSMSSenderProfiles, {
    initialData: organisation?.sender_ids ?? [],
  });

  const { mutate: mutateCampaignState } = useMutation<any, AxiosError, any, any>(
    (payload: { campaign: CampaignData; state: CampaignData['state'] }) =>
      saveCampaignItemState({ id: payload.campaign.id, state: payload.state }),
    {
      onSuccess: ({ data }) => {
        const campaign = data.campaign;

        reduxDispatch(editCampaign({ campaign }));
        queryClient.invalidateQueries('campaigns');
      },
      onError: (error, _, context) => {
        toast({
          position: 'bottom-left',
          render: ({ onClose }) => <ToastBox onClose={onClose} message={error.message} />,
        });
      },
    },
  );

  const { isLoading: isDeletingCampaign, mutate: mutateDeleteCampaign } = useMutation<
    any,
    AxiosError,
    any,
    any
  >((campaign: CampaignData) => deleteCampaignItem({ id: campaign.id }), {
    onSuccess: () => {
      queryClient.invalidateQueries('campaigns');
      history.push('/s/marketing/campaigns');
      toast({
        position: 'bottom-left',
        render: ({ onClose }) => (
          <ToastBox status="success" onClose={onClose} message="Campaign deleted successfully" />
        ),
      });
    },
    onError: (error, _, context) => {
      toast({
        position: 'bottom-left',
        render: ({ onClose }) => <ToastBox onClose={onClose} message={error.message} />,
      });
    },
  });

  const onOpenDropdownOptions = () => setIsOpen(true);

  const onCloseDropdownOptions = () => setIsOpen(false);

  const onClosePhoneNumberTypeAlert = () => setHasNoPhoneNumberType(false);

  const formikValues = useFormik<CampaignPayload>({
    enableReinitialize: true,
    onSubmit: values => {
      if (values.state?.toLowerCase() === 'paused') {
        props.onSubmit(values);
      } else {
        onOpenSendCampaignModal();
      }
    },
    initialValues:
      {
        ...initialValues,

        sender_id:
          sender_ids?.find((id: SMSSenderProfilePayload) => initialValues?.sender_id === id.name)
            ?.name ?? '',
      } ?? {},
    validationSchema: yup.object().shape({
      name: yup.string().required('Campaign name is required'),
      sender_id: yup.string().required('Sender ID is required'),
      campaign_id: yup.string().required('Select campaign recipients'),
      content: yup
        .string()
        .when('template_id', {
          is: (value: string) => !!value,
          then: yup.string(),
          otherwise: yup.string().required('Campaign message is required'),
        })
        .nullable(),
    }),
  });

  const { values, setFieldValue } = formikValues;

  const recipientListId = query.get('id');
  const recipientListType = query.get('type');

  const listOptions = lists_by_id
    //@ts-ignore
    .map((id: string) => lists[id])
    .map(({ name, id }) => ({ label: name, value: id }));

  const smartListOptions = smart_lists_by_id
    //@ts-ignore
    .map((id: string) => smart_lists[id])
    .map(({ name, id }) => ({ label: name, value: `${id}-smartList` }));

  const audienceOptions = audiences_by_id
    //@ts-ignore
    .map((id: string) => audiences[id])
    .map(({ name, id }) => ({ label: name, value: `${id}-audience` }));

  // const importOptions = [{ label: 'Import a CSV/Excel file', value: 'import' }];
  const segmentOptions = segments.map(({ name, id }) => ({ label: name, value: `${id}-segment` }));

  const newAudienceOptions = [{ label: 'Create a new audience', value: 'new-audience' }];

  const allLists =
    user?.account_type === 9
      ? [
          { label: 'New Audience', options: newAudienceOptions, showBadge: false },
          // { label: 'Import', options: importOptions, showBadge: false },
          { label: 'Lists', options: listOptions, showBadge: true },
          { label: 'Smart lists', options: smartListOptions, showBadge: true },
          { label: 'Segments', options: segmentOptions, showBadge: true },
          { label: 'Audiences', options: audienceOptions, showBadge: true },
        ]
      : [
          // { label: 'Import', options: importOptions, showBadge: false },
          { label: 'Lists', options: listOptions, showBadge: true },
          { label: 'Smart lists', options: smartListOptions, showBadge: true },
          { label: 'Segments', options: segmentOptions, showBadge: true },
        ];

  const allStates = states.map(state => ({ value: state, label: state }));
  const allGenders = genders.map(state => ({ value: state, label: state }));
  const allReligions = religions.map(religion => ({ value: religion, label: religion }));
  const allEthnicGroups = ethnicGroups.map(ethnicGroup => ({
    value: ethnicGroup,
    label: ethnicGroup,
  }));

  const handleGetListCount = async (payload: {
    smart_list_id?: string | null;
    table_id?: string | null;
    group_id?: string | null;
    segment_id?: string | null;
    audience_id?: string | null;
    audience_type: number;
  }) => {
    try {
      const { audience } = await getCampaignAudience(payload);
      return audience.count;
    } catch (error: any) {
      toast({
        position: 'bottom-left',
        render: ({ onClose }) => <ToastBox onClose={onClose} message={error} />,
      });
    }
  };

  const handleGetAudience = async (audienceId: string) => {
    try {
      const response = await client(`audience_list/${audienceId}`);
      const { audience_list } = response.data;
      return audience_list;
    } catch (error: any) {
      toast({
        position: 'bottom-left',
        render: ({ onClose }) => <ToastBox onClose={onClose} message={error} />,
      });
    }
  };

  const handleBulkUpload = async (files: File[]) => {
    const file = files[0];
    try {
      const payload = toFormData({ name: file.name }, file, 'file');
      const data = await importBulkUpload(payload);
      setFieldValue('import_id', data.import.id);
      setFieldValue('campaign_id', data.import.id);
      setFieldValue('count', data.import.count);
      setFieldValue('table_id', null);
      setFieldValue('smart_list_id', null);
      setFieldValue('segment_id', null);
      setFieldValue('audience_type', 3);
      toast({
        position: 'bottom-left',
        render: ({ onClose }) => (
          <ToastBox status="success" onClose={onClose} message="File uploaded successfully" />
        ),
      });
    } catch (error: any) {
      toast({
        position: 'bottom-left',
        render: ({ onClose }) => <ToastBox onClose={onClose} message={error} />,
      });
    }
  };

  const handleAudienceChange = async (value?: string) => {
    if (value === 'import') {
      open();
    } else if (value === 'new-audience') {
      onOpenNewAudienceModal();
    } else {
      if (value?.endsWith('-smartList')) {
        value = value.replace('-smartList', '');
        const count = await handleGetListCount({ smart_list_id: value, audience_type: 2 });
        setFieldValue('smart_list_id', value);
        setFieldValue('campaign_id', value);
        setFieldValue('table_id', null);
        setFieldValue('import_id', null);
        setFieldValue('audience_id', null);
        setFieldValue('segment_id', null);
        setFieldValue('count', count);
        setFieldValue('audience_type', 2);
      } else if (value?.endsWith('-audience')) {
        value = value.replace('-audience', '');
        const count = await handleGetAudience(value);
        setFieldValue('audience_id', value);
        setFieldValue('campaign_id', value);
        setFieldValue('table_id', null);
        setFieldValue('import_id', null);
        setFieldValue('smart_list_id', null);
        setFieldValue('segment_id', null);
        setFieldValue('count', count?.filters?.count ?? 0);
        setFieldValue('audience_type', 1);
      } else if (value?.endsWith('-segment')) {
        value = value.replace('-segment', '');
        const count = await handleGetListCount({ segment_id: value, audience_type: 2 });
        setFieldValue('segment_id', value);
        setFieldValue('campaign_id', value);
        setFieldValue('table_id', null);
        setFieldValue('import_id', null);
        setFieldValue('smart_list_id', null);
        setFieldValue('audience_id', null);
        setFieldValue('count', count ?? 0);
        setFieldValue('audience_type', 2);
      } else {
        const count = await handleGetListCount({ table_id: value, audience_type: 2 });
        setFieldValue('table_id', value);
        setFieldValue('campaign_id', value);
        setFieldValue('import_id', null);
        setFieldValue('smart_list_id', null);
        setFieldValue('audience_id', null);
        setFieldValue('segment_id', null);
        setFieldValue('count', count);
        setFieldValue('audience_type', 2);
      }
    }
  };

  const handleCreateNewAudience = async (
    payload: {
      name: string;
      count: number;
      lga?: string[];
      state?: string[];
      gender?: string[];
      predicted_religion?: string[];
      predicted_ethnicity?: string[];
    },
    callback: () => void,
  ) => {
    try {
      const { name, state, lga, gender, count, predicted_religion, predicted_ethnicity } = payload;
      dispatch({ type: 'LOADING_STARTED' });
      const audience_list: any = await addAudience?.({
        name,
        filters: {
          lga: lga ? lga : undefined,
          state: state ? state : undefined,
          count: count ? count : undefined,
          gender: gender ? gender : undefined,
          predicted_religion: predicted_religion ? predicted_religion : undefined,
          predicted_ethnicity: predicted_ethnicity ? predicted_ethnicity : undefined,
        },
      });
      dispatch({ type: 'LOADING_RESOLVED' });
      setFieldValue('audience_id', `${audience_list.id}`);
      setFieldValue('campaign_id', `${audience_list.id}`);
      setFieldValue('count', audience_list.filters.count);
      setFieldValue('audience_type', 1);
      onCloseNewAudienceModal();
      toast({
        position: 'bottom-left',
        render: ({ onClose }) => (
          <ToastBox status="success" onClose={onClose} message="Audience created" />
        ),
      });
      callback();
      track('New Audience Created', payload);
    } catch (error: any) {
      dispatch({ type: 'LOADING_RESOLVED' });
      toast({
        position: 'bottom-left',
        render: ({ onClose }) => <ToastBox onClose={onClose} message={error} />,
      });
    }
  };

  const handleUpdateTemplate = async (payload: TemplateData) => {
    try {
      await editTemplate(payload);
    } catch (error: any) {
      toast({
        position: 'bottom-left',
        render: ({ onClose }) => <ToastBox onClose={onClose} message={error} />,
      });
    }
  };

  const handleEditCampaign = async (payload: Partial<CampaignPayload>) => {
    try {
      dispatch({ type: 'LOADING_STARTED' });
      await updateCampaign(payload);
      dispatch({ type: 'LOADING_RESOLVED' });
      toast({
        position: 'bottom-left',
        render: ({ onClose }) => (
          <ToastBox status="success" onClose={onClose} message="Campaign updated successfully" />
        ),
      });
      track('SMS Campaign Updated', payload);
      history.push('/s/marketing/campaigns');
    } catch (error: any) {
      dispatch({ type: 'LOADING_RESOLVED' });
      toast({
        position: 'bottom-left',
        render: ({ onClose }) => <ToastBox onClose={onClose} message={error} />,
      });
    }
  };

  const handleSaveDraft = async (payload: CampaignPayload) => {
    try {
      dispatch({ type: 'LOADING_STARTED' });
      await saveCampaignDraft(payload);
      dispatch({ type: 'LOADING_RESOLVED' });
      toast({
        position: 'bottom-left',
        render: ({ onClose }) => (
          <ToastBox status="success" onClose={onClose} message="Campaign saved as draft" />
        ),
      });
      track('SMS Campaign Draft', payload);
      history.push('/s/marketing/campaigns');
    } catch (error: any) {
      dispatch({ type: 'LOADING_RESOLVED' });
      toast({
        position: 'bottom-left',
        render: ({ onClose }) => <ToastBox onClose={onClose} message={error} />,
      });
    }
  };

  const handleDuplicateCampaign = async () => {
    await handleSaveDraft({
      ...initialValues,
      state: 'draft',
      schedule_end: undefined,
      schedule_start: undefined,
      send_time: undefined,
      timezone: undefined,
    });
  };

  function handleSelectTemplate(data: TemplateData) {
    const { id, type, name, template } = data;
    setFieldValue('template_id', id);
    setFieldValue('content', template);
    setFieldValue('template_type', type);
    track('SMS Campaign Template Selected', { name, type, id });
    setSelectedTemplate(data);
    onCloseTemplateModal();
  }
  async function handleShortenLink({ link, analytics }: any, formikHelpers: FormikHelpers<any>) {
    try {
      dispatch({ type: 'LOADING_STARTED' });
      const response = await shortenLink({ link });
      const { short: shortenedLink } = response.data.link.link;
      let content = '';

      dispatch({ type: 'LOADING_RESOLVED' });
      onCloseCampaignLinkModal();
      formikHelpers.resetForm();
      if (values.content) {
        if (contentHasURL(getContent(values.content))) {
          content = `${contentWithoutURL(getContent(values.content))}\n${shortenedLink}`;
        } else {
          content = `${values.content}\n${shortenedLink}`;
        }
      } else {
        content = shortenedLink;
      }
      setFieldValue('link', link);
      setFieldValue('analytics', analytics);
      handleEditableChange(content);
      track('SMS Campaign Link Added', { link });
    } catch (error: any) {
      dispatch({ type: 'LOADING_RESOLVED' });
      toast({
        position: 'bottom-left',
        render: ({ onClose }) => <ToastBox onClose={onClose} message={error} />,
      });
    }
  }

  const handleSendTestMessage = async (
    { recipients }: { recipients: string },
    { resetForm }: FormikHelpers<{ recipients: string }>,
  ) => {
    if (values.content) {
      const payload = {
        recipients,
        content: values.content,
        table_id: values.table_id,
        sender_id: values.sender_id,
        smart_list_id: values.smart_list_id,
      };
      try {
        dispatch({ type: 'LOADING_STARTED' });
        await sendTestCampaignMessage(payload);
        dispatch({ type: 'LOADING_RESOLVED' });
        profile?.organisation_id && (await fetchWallet(profile?.organisation_id));
        onCloseTestMessageModal();
        resetForm();
        toast({
          position: 'bottom-left',
          render: ({ onClose }) => (
            <ToastBox status="success" onClose={onClose} message="Test message sent successfully" />
          ),
        });
        track('SMS Campaign Test Sent');
      } catch (error: any) {
        dispatch({ type: 'LOADING_RESOLVED' });
        toast({
          position: 'bottom-left',
          render: ({ onClose }) => <ToastBox onClose={onClose} message={error} />,
        });
      }
    }
  };

  const handleSaveAsDraft = async (payload: Partial<CampaignPayload>) => {
    if (
      values.template_id &&
      selectedTemplate &&
      selectedTemplate?.type !== 'built-in' &&
      selectedTemplate?.template !== values.content
    ) {
      handleUpdateTemplate({
        ...selectedTemplate,
        template: html2Text(getContent(values?.content)),
      });
    }
    if (values.state && values.state === 'draft') {
      await handleEditCampaign(payload);
    } else {
      await handleSaveDraft(payload);
    }
  };

  const handleCampaignFrequencyCheck = async (text: string) => {
    try {
      const frequency_type = await campaignFrequencyFuzzy(text);
      setFrequencyType(frequency_type);
    } catch (error) {}
  };

  const handleGetSuggestedTemplates = async (text: string) => {
    try {
      const templates = await campaignTemplateFuzzy(text);
      setSuggestedTemplates(templates);
    } catch (error) {}
  };

  function onEmojiClick(event: React.MouseEvent<Element, MouseEvent>, emojiObject: IEmojiData) {
    setCurrentEmoji(emojiObject.emoji);
    track('SMS Campaign Emoji Added');
  }

  function handleEditableChange(value: string) {
    getCharacterCount(value);
    setFieldValue('content', value);
  }

  function handleEditableBlur() {
    const value = editableRef?.current?.innerHTML;
    getCharacterCount(value);
    setFieldValue('content', value);
  }

  function handleCampaignStateChange(campaign: CampaignData, state: CampaignData['state']) {
    mutateCampaignState({ campaign, state });
  }

  function handleCampaignDelete() {
    if (initialValues) {
      mutateDeleteCampaign(initialValues);
    }
  }

  const getAudienceValue = () => {
    const { table_id, audience_id, smart_list_id, segment_id } = values;
    if (!!table_id) {
      return table_id;
    }
    if (!!smart_list_id) {
      return `${smart_list_id}-smartList`;
    }
    if (!!audience_id) {
      return `${audience_id}-audience`;
    }
    if (!!segment_id) {
      return `${segment_id}-segment`;
    }
    return '';
  };

  const emojiRegex =
    /([\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2694-\u2697]|\uD83E[\uDD10-\uDD5D])/g;

  const atSignRegex = /@/g;

  function findEmojis(text: string) {
    return text.match(emojiRegex) || [];
  }

  function findAtSigns(text: string) {
    return text.match(atSignRegex) || [];
  }

  function getCharacterCount(value: string) {
    const content = html2Text(value);
    const characterCount = content.length;
    const isNotRegularText = !!findAtSigns(value).length || !!findEmojis(value).length;
    const characterDivisor = isNotRegularText ? 60 : 160;
    const smsCount = Math.ceil(characterCount / characterDivisor);

    setCharacterCount(characterCount);
    setSmsCount(smsCount);
    setIsNotRegularText(isNotRegularText);
  }

  /**
   * https://stackoverflow.com/questions/64618729/how-do-you-get-and-set-the-caret-position-in-a-contenteditable/64823701#64823701
   */
  function getCaretPosition() {
    var index = 0;
    var selection = window.getSelection();
    var textNodes = textNodesUnder(editableRef.current);

    for (var i = 0; i < textNodes.length; i++) {
      var node = textNodes[i];
      var isSelectedNode = node === selection?.focusNode;

      if (isSelectedNode) {
        index += selection?.focusOffset ?? 0;
        break;
      } else {
        index += node.textContent.length;
      }
    }

    return index;
  }

  const getContent = (value?: null | string): string => {
    if (value) {
      return value;
    }
    return '';
  };

  function getTableActions(data: CampaignData) {
    if (data.state === 'paused' || data.state === 'stopped') {
      return [
        {
          icon: 'play',
          label: 'Start campaign',
          onClick: (data: CampaignData) => handleCampaignStateChange(data, 'started'),
        },
        {
          icon: 'copy',
          label: 'Duplicate campaign',
          onClick: onOpenCopyCampaignModal,
        },
        {
          icon: 'delete',
          label: 'Delete campaign',
          onClick: onOpenDeleteCampaignModal,
        },
      ];
    }

    if (data.state === 'started' && !!data.schedule_start) {
      return [
        {
          icon: 'pause',
          label: 'Pause campaign',
          onClick: (data: CampaignData) => {
            const state = data.state === 'started' ? 'paused' : 'started';
            handleCampaignStateChange(data, state);
          },
        },
        {
          icon: 'stop',
          label: 'Stop campaign',
          onClick: (data: CampaignData) => handleCampaignStateChange(data, 'stopped'),
        },
        {
          icon: 'copy',
          label: 'Duplicate campaign',
          onClick: onOpenCopyCampaignModal,
        },
        {
          icon: 'delete',
          label: 'Delete campaign',
          onClick: onOpenDeleteCampaignModal,
        },
      ];
    }

    return [
      {
        icon: 'copy',
        label: 'Duplicate campaign',
        onClick: onOpenCopyCampaignModal,
      },
      {
        icon: 'delete',
        label: 'Delete campaign',
        onClick: onOpenDeleteCampaignModal,
      },
    ];
  }

  const { getInputProps, open } = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    maxSize: 52428800,
    onDrop: handleBulkUpload,
    accept: '.csv, .xls, .xlsx',
  });

  const getListProperties = useCallback(
    (list_id: string) => {
      if (list_id && lists_by_id?.includes(list_id)) {
        return lists[list_id].columns.map(({ name, label }: { name: string; label: string }) => ({
          label,
          value: name,
        }));
      }
    },
    [lists, lists_by_id],
  );

  const { mutate: createSMSSenderProfileMutate, isLoading: isCreatingEmailSenderProfile } =
    useMutation<any, AxiosError, any, any>(
      (options: { payload: SMSSenderProfilePayload }) => saveSMSSenderProfile(options.payload),
      {
        onSuccess: (data, variables) => {
          const { helpers } = variables;
          queryClient.invalidateQueries(['sms-sender-profiles']);
          helpers.resetForm();
          onCloseSMSSenderProfileModal();
          setFieldValue?.('sender_id', data.name);
          toast({
            position: 'bottom-left',
            render: ({ onClose }) => (
              <ToastBox
                status="success"
                onClose={onClose}
                message="SMS sender profile added successfully"
              />
            ),
          });
        },
        onError: error => {
          toast({
            position: 'bottom-left',
            render: ({ onClose }) => <ToastBox onClose={onClose} message={error.message} />,
          });
        },
      },
    );

  useEffect(() => {
    const getCount = async (payload: {
      smart_list_id?: string | null;
      table_id?: string | null;
      group_id?: string | null;
      segment_id?: string | null;
      audience_id?: string | null;
      audience_type: number;
    }) => {
      if (payload.audience_id) {
        const count = await handleGetAudience(payload.audience_id);
        setFieldValue('count', count?.filters?.count ?? 0);
      } else {
        const count = await handleGetListCount(payload);
        setFieldValue('count', count);
      }
    };

    const { table_id, audience_id, segment_id, smart_list_id } = values;

    if (table_id || audience_id || segment_id || smart_list_id) {
      getCount({ table_id, audience_id, audience_type: 2, smart_list_id, segment_id });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  useEffect(() => {
    if (values.table_id) {
      const properties = getListProperties(values.table_id);

      setTags(properties);
    }
    if (values.smart_list_id) {
      const smartList = smart_lists[values.smart_list_id];
      const properties = getListProperties(smartList?.list_id);
      setTags(properties);
    }
  }, [values.table_id, smart_lists, values.smart_list_id, getListProperties]);

  useEffect(() => {
    if (values.content) {
      getCharacterCount(values.content);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.content]);

  useEffect(() => {
    if (currentEmoji) {
      if (values.content) {
        setFieldValue('content', `${values.content}${currentEmoji}`);
      } else {
        setFieldValue('content', currentEmoji);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentEmoji]);

  useEffect(() => {
    if (values.table_id) {
      const list = lists[values?.table_id];
      const hasNoPhoneNumberType = !list?.columns.filter(
        (item: any) => item.type.toLowerCase() === 'phone number',
      ).length;
      setHasNoPhoneNumberType(hasNoPhoneNumberType);
    }
    if (values.smart_list_id) {
      const smart_list = smart_lists[values?.smart_list_id];
      const list = lists[smart_list?.table_id];
      const hasNoPhoneNumberType = !list?.columns.filter(
        (item: any) => item?.type?.toLowerCase() === 'phone number',
      ).length;
      setHasNoPhoneNumberType(hasNoPhoneNumberType);
    }
    if (values.segment_id) {
      const segment = segments.find((segment: any) => segment.id === values.segment_id);
      const data_model = data_models.find(item => item.id === segment?.data_model_id);
      const hasNoPhoneNumberType = !data_model?.columns.filter(
        (item: any) => item.data_type.toLowerCase() === 'phone number',
      ).length;
      setHasNoPhoneNumberType(hasNoPhoneNumberType);
    }
  }, [
    values.table_id,
    values.smart_list_id,
    values.segment_id,
    data_models,
    lists,
    segments,
    smart_lists,
  ]);

  useEffect(() => {
    const getAllStates = () => {
      reduxDispatch(fetchStates());
    };
    const getGenders = () => {
      reduxDispatch(fetchGenders());
    };
    const getReligions = () => {
      reduxDispatch(fetchReligions());
    };
    const getEthnicities = () => {
      reduxDispatch(fetchEthnicGroups());
    };

    getAllStates();
    getGenders();
    getReligions();
    getEthnicities();
  }, [reduxDispatch]);

  useEffect(() => {
    if (recipientListId && recipientListType) {
      setFieldValue('campaign_id', recipientListId);
      recipientListType === 'list' && setFieldValue('table_id', recipientListId);
      recipientListType === 'smart_list' && setFieldValue('smart_list_id', recipientListId);
      setFieldValue('audience_type', 2);
    }
  }, [recipientListId, recipientListType, setFieldValue]);

  return {
    tags,
    isOpen,
    loading,
    allLists,
    smsCount,
    getContent,
    formikValues,
    organisation,
    onEmojiClick,
    getInputProps,
    characterCount,
    getAudienceValue,
    handleSaveAsDraft,
    onOpenTemplateModal,
    hasNoPhoneNumberType,
    handleAudienceChange,
    onOpenDropdownOptions,
    onCloseDropdownOptions,
    handleEditableChange,
    onOpenCampaignLinkModal,
    onOpenSendCampaignModal,
    testMessageModalIsOpen,
    handleSendTestMessage,
    onCloseTestMessageModal,
    allStates,
    allGenders,
    allReligions,
    allEthnicGroups,
    isOpenNewAudienceModal,
    onCloseNewAudienceModal,
    handleCreateNewAudience,
    audiences_by_id,
    templateModalIsOpen,
    onCloseTemplateModal,
    handleSelectTemplate,
    handleShortenLink,
    campaignLinkModalIsOpen,
    onCloseCampaignLinkModal,
    sendCampaignIsOpen,
    onCloseSendCampaignModal,
    onOpenTestMessageModal,
    handleCampaignDelete,
    handleCampaignStateChange,
    isDeletingCampaign,
    deleteCampaignModalIsOpen,
    onOpenDeleteCampaignModal,
    onCloseDeleteCampaignModal,
    getTableActions,
    onClosePhoneNumberTypeAlert,
    handleCampaignFrequencyCheck,
    handleGetSuggestedTemplates,
    frequencyType,
    suggestedTemplates,
    editorPlaceholder,
    setEditorPlaceholder,
    sender_ids,
    isSMSSenderProfileModalOpen,
    onOpenSMSSenderProfileModal,
    onCloseSMSSenderProfileModal,
    createSMSSenderProfileMutate,
    isCreatingEmailSenderProfile,
    isNotRegularText,
    setIsNotRegularText,
    isPastTime,
    onCloseIsPastTimeBanner,
    copyCampaignModalIsOpen,
    onOpenCopyCampaignModal,
    onCloseCopyCampaignModal,
    handleDuplicateCampaign,
    editableRef,
    handleEditableBlur,
    getCaretPosition,
    isAIContentGenerationModalOpen,
    onOpenAIContentGenerationModal,
    onCloseAIContentGenerationModal,
  };
};
