import { Popover, PopoverContent, PopoverTrigger, useDisclosure } from '@chakra-ui/core';
import { Input } from 'app/components';
import React, { useEffect, useState } from 'react';
import { PopoverWrapper } from '../../../list-view.styles';
import { calendarOutputFormat } from '../../../list.data';
import { parseDate } from '../../../lists.utils';
import { DateCellEditor } from './editor';

export const DateFieldComponent = (props: any) => {
  const [rawValue, setRawValue] = useState('');
  const { isOpen, onClose, onOpen } = useDisclosure();

  const {
    column = {},
    placeholder,
    inputProps = {},
    fieldProps = {},
    isDisabled = false,
    rowID = null,
  } = props;

  const date_format = column?.customization?.date_format || 'D/MM/YYYY';
  const time_format = column?.customization?.time_format || 'h:mm A';
  const include_time = column?.customization?.include_time || false;

  const value = parseDate(rawValue);

  let formatted_date =
    date_format !== 'relative'
      ? value.format(date_format)
      : value.calendar(null, calendarOutputFormat);

  if (include_time) {
    if (date_format === 'relative') formatted_date += ' at';
    formatted_date += ` ${value.format(time_format)}`;
  }

  const handleUpdate = (_value: any) => {
    setRawValue(_value);
    props.updateCellValue(_value, column.uid);
    onClose();
  };

  useEffect(() => {
    setRawValue(props.value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowID]);

  return (
    <Popover
      closeOnEsc
      onOpen={onOpen}
      onClose={onClose}
      placement="bottom-start"
      isOpen={!isDisabled ? isOpen : false}
    >
      <PopoverTrigger>
        <div onClick={onOpen}>
          <Input
            paddingX="0.5rem"
            borderRadius="0.25rem"
            isDisabled={isDisabled}
            placeholder={placeholder}
            cursor={!isDisabled ? 'cursor' : 'not-allowed'}
            value={rawValue && value.isValid() ? formatted_date : ''}
            _disabled={{
              color: '#333333',
            }}
            {...inputProps}
            {...fieldProps}
          />
        </div>
      </PopoverTrigger>

      <PopoverContent
        zIndex={4}
        width="max-content"
        boxShadow="none"
        _focus={{
          boxShadow: 'none',
          outline: 'none',
        }}
      >
        <PopoverWrapper>
          <DateCellEditor
            value={rawValue}
            updateValue={handleUpdate}
            customization={column?.customization}
            close={onClose}
          />
        </PopoverWrapper>
      </PopoverContent>
    </Popover>
  );
};
