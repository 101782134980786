import { Box, Flex, Icon, Link, Stack, useDisclosure, useToast } from '@chakra-ui/core';
import { PseudoBox } from '@chakra-ui/core/dist';
import styled from '@emotion/styled';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { NavLink, Redirect, Route, Switch } from 'react-router-dom';
import { version } from '../../../../package.json';
import {
  ConfirmModal,
  ContentWrapper,
  SmallSubtitle,
  ToastBox,
  XSmallText,
} from '../../components';
import {
  Notifications,
  Organization,
  People,
  Profile,
  SettingsMobileNavItem,
  TeamMates,
  Teams,
  WorkSpaces,
} from './component';
import { CompanyProfile } from './component/company-profile';
import { SettingsOverview } from './component/overview';
import { ResellerSettings } from './component/reseller/settings';
import { ResellerSubaccount } from './component/reseller/subaccount';
import { ResellerSubaccounts } from './component/reseller/subaccounts';
import { SenderProfiles } from './component/sender-profiles';
import { SendingDomains } from './component/sending-domains';
import { getOrganisation } from './service';
import { SettingsProps } from './settings.container';

export default function Settings(props: SettingsProps) {
  const {
    user,
    match,
    team,
    teams,
    addTeam,
    profile,
    editTeam,
    removeTeam,
    editProfile,
    editPassword,
    fetchTeamMembers,
    createTeamMember,
    onInviteTeamMembers,
    deleteMemberInvite,
    deleteTeamMember,
    organisationMembers,
    openNoSubscriptionModal,
    // @ts-ignore
    reloadOrganization,
    userProfileImage,
    logUserOut,
  } = props;

  const [isLoggingOut, setIsLoggingOut] = useState(false);

  const { data: organisationData } = useQuery(['organisation'], getOrganisation);

  const handleOpenLogoutDialog = () => {
    openLogoutDialog();
  };

  const organisation_settings = [
    {
      icon: 'user',
      url: `${match.url}/overview`,
      label: 'Overview',
    },
    {
      icon: 'user',
      url: `${match.url}/organisation/general`,
      label: 'General',
    },
    {
      icon: 'user',
      url: `${match.url}/organisation/company-profile`,
      label: 'Company profile',
    },
    {
      icon: 'user',
      url: `${match.url}/organisation/sender-profiles`,
      label: 'Sender profiles',
    },
    {
      icon: 'user',
      url: `${match.url}/organisation/sending-domains`,
      label: 'Sending domains',
    },
    {
      icon: 'user',
      url: `${match.url}/organisation/people`,
      label: 'People',
    },
    {
      icon: 'user',
      url: `${match.url}/organisation/teams`,
      label: 'Teams',
    },
  ];

  const reseller_settings = [
    {
      icon: 'user',
      url: `${match.url}/reseller/settings`,
      label: 'Settings',
    },
    {
      icon: 'user',
      url: `${match.url}/reseller/sub-accounts`,
      label: 'Sub accounts',
    },
  ];

  const personal_settings = [
    {
      icon: 'user',
      url: `${match.url}/me/profile`,
      label: 'Profile',
    },
    {
      icon: 'user',
      url: `${match.url}/me/notifications`,
      label: 'Notifications',
    },
    {
      icon: 'user',
      url: `${match.url}/me/organisations`,
      label: 'Organisations',
    },
  ];

  const mobileLinks = [
    {
      icon: 'user',
      url: `${match.url}/me/profile`,
      label: 'Profile',
    },
    {
      icon: 'team',
      url: `${match.url}/organization/teams`,
      label: 'Teams',
    },
  ];

  const toast = useToast();

  const {
    isOpen: isLogoutDialogOpen,
    onClose: closeLogoutDialog,
    onOpen: openLogoutDialog,
  } = useDisclosure();

  const handleLogout = async () => {
    try {
      setIsLoggingOut(true);
      // @ts-ignore
      await props.handleLogout();
      closeLogoutDialog();
      setIsLoggingOut(false);
    } catch (error: any) {
      setIsLoggingOut(false);
      toast({
        render: ({ onClose }) => (
          <ToastBox status="error" onClose={onClose} message={error.message} />
        ),
      });
    }
  };

  return (
    <SettingsStyleWrapper>
      <ConfirmModal
        isLoading={isLoggingOut}
        onConfirm={handleLogout}
        isOpen={isLogoutDialogOpen}
        title="Logout confirmation"
        onClose={closeLogoutDialog}
      />
      <Flex height="100%">
        <Box
          height="100%"
          width={'280px'}
          paddingY="0.75rem"
          backgroundColor="#fbfbfb"
          borderLeft="solid 1px rgba(0, 0, 0, 0.08)"
          display={['none', 'none', 'none', 'block']}
          overflowY="auto"
        >
          <Box width="100%" paddingX="40px" paddingTop="15px">
            <Box fontWeight="600" fontSize="19px" color="black">
              Settings
            </Box>
          </Box>

          <Stack isInline alignItems="center" marginX="40px" marginY="16px">
            <Icon name="briefcase" color="black" size="1.2rem" />
            <SmallSubtitle position="relative" top="2px">
              Organisation
            </SmallSubtitle>
          </Stack>

          <Box display="flex" flexDirection="column" marginTop="10px">
            <Box display="flex" flexDirection="column">
              {organisation_settings.map((i: any, index: number) => (
                <SidebarOption key={index} {...i} />
              ))}
            </Box>
          </Box>

          {!!organisationData?.verified_reseller && (
            <>
              <Stack isInline alignItems="center" marginX="40px" marginY="16px">
                <Icon name="team" color="black" size="1.2rem" />
                <SmallSubtitle position="relative" top="2px">
                  Reseller
                </SmallSubtitle>
              </Stack>

              <Box display="flex" flexDirection="column" marginTop="10px">
                <Box display="flex" flexDirection="column">
                  {reseller_settings.map((i: any, index: number) => (
                    <SidebarOption key={index} {...i} />
                  ))}
                </Box>
              </Box>
            </>
          )}

          <Stack isInline alignItems="center" marginX="40px" marginY="25px">
            <Icon name="user" color="black" size="1.2rem" />
            <SmallSubtitle position="relative" top="2px">
              Account
            </SmallSubtitle>
          </Stack>

          <Box display="flex" flexDirection="column">
            <Box display="flex" flexDirection="column">
              {personal_settings.map((i: any, index: number) => (
                <SidebarOption key={index} {...i} />
              ))}
              <PseudoBox
                px="40px"
                fontSize="14px"
                paddingY="8px"
                cursor="pointer"
                color="#ababab"
                fontWeight="400"
                bg="transparent"
                borderLeft="unset"
                _hover={{
                  bg: 'gray.100',
                  color: '#333333',
                }}
                transition="all 0.1s ease-in"
                onClick={handleOpenLogoutDialog}
              >
                Logout
              </PseudoBox>
            </Box>
          </Box>
          <XSmallText pt="50px" px="40px" color="gray.500">
            v{version}
          </XSmallText>
        </Box>
        <ContentWrapper
          flex={1}
          paddingTop="2rem"
          paddingBottom="2rem"
          backgroundColor="white"
          paddingLeft={['1rem', '1rem', '2rem', '0']}
          paddingRight={['1rem', '1rem', '2rem', '0']}
        >
          <Switch>
            <Route
              path={`${match.path}/me/profile`}
              render={routeProps => (
                <Profile
                  {...{
                    profile,
                    user,
                    editProfile,
                    editPassword,
                    userProfileImage,
                    logUserOut,
                    ...routeProps,
                  }}
                />
              )}
            />
            <Route
              path={`${match.path}/me/organisations`}
              render={routeProps => (
                <WorkSpaces {...routeProps} reloadOrganization={reloadOrganization} />
              )}
            />
            <Route path={`${match.path}/me/notifications`} render={() => <Notifications />} />
            <Route
              exact
              path={`${match.path}/organisation/general`}
              render={routeProps => <Organization routeProps={routeProps} />}
            />
            <Route
              exact
              path={`${match.path}/organisation/teams`}
              render={routeProps => (
                <Teams
                  {...{
                    teams,
                    profile,
                    addTeam,
                    editTeam,
                    removeTeam,
                    openNoSubscriptionModal,
                    ...routeProps,
                  }}
                />
              )}
            />
            <Route
              exact
              path={`${match.path}/organisation/teams/:id/members`}
              render={routeProps => (
                <TeamMates
                  {...{
                    team,
                    fetchTeamMembers,
                    createTeamMember,
                    onInviteTeamMembers,
                    organisationMembers,
                    deleteMemberInvite,
                    deleteTeamMember,
                    ...routeProps,
                  }}
                />
              )}
            />
            {/* <Route
              exact
              path={`${match.path}/organisation/teams`}
              render={routeProps => (
                <TeamsContainer
                  {...{
                    team,
                    teams,
                    profile,
                    addTeam,
                    editTeam,
                    removeTeam,
                    fetchTeamMembers,
                    createTeamMember,
                    onInviteTeamMembers,
                    organisationMembers,
                    deleteMemberInvite,
                    deleteTeamMember,
                    openNoSubscriptionModal,
                    ...routeProps,
                  }}
                />
              )}
            /> */}
            <Route
              exact
              path={`${match.path}/organisation/people`}
              render={routeProps => <People routeProps={routeProps} />}
            />
            <Route
              exact
              render={() => <SendingDomains />}
              path={`${match.path}/organisation/sending-domains`}
            />
            <Route
              exact
              render={() => <CompanyProfile />}
              path={`${match.path}/organisation/company-profile`}
            />
            <Route
              exact
              render={() => <SenderProfiles />}
              path={`${match.path}/organisation/sender-profiles`}
            />
            <Route exact path={`${match.path}/overview`} render={() => <SettingsOverview />} />
            <Route render={() => <ResellerSettings />} path={`${match.path}/reseller/settings`} />
            <Route
              render={() => <ResellerSubaccount />}
              path={`${match.path}/reseller/sub-accounts/:id`}
            />
            <Route
              render={() => <ResellerSubaccounts />}
              path={`${match.path}/reseller/sub-accounts`}
            />
            <Route path="*">
              <Redirect to={`${match.path}/overview`} />
            </Route>
          </Switch>
        </ContentWrapper>
        <Stack
          isInline
          bottom="0"
          width="100%"
          zIndex={1000}
          spacing="14px"
          paddingX="1rem"
          position="fixed"
          paddingY="0.8rem"
          background="white"
          boxShadow="0 0px 22px rgba(0,0,0,0.1)"
          display={['flex', 'flex', 'flex', 'none']}
        >
          {mobileLinks.map((link, index) => {
            return (
              <Box key={index} width={`${100 / mobileLinks.length}%`}>
                <SettingsMobileNavItem {...link} />
              </Box>
            );
          })}
        </Stack>
      </Flex>
    </SettingsStyleWrapper>
  );
}

const SidebarOption = ({ url, label }: any) => {
  return (
    <Link
      px="40px"
      to={url}
      //@ts-ignore
      as={NavLink}
      fontSize="14px"
      paddingY="8px"
      color="#ababab"
      cursor="pointer"
      fontWeight="400"
      bg="transparent"
      borderLeft="unset"
      transition="all 0.1s ease-in"
      className="sidebar-option"
      _hover={{
        color: '#333333',
        bg: 'gray.100',
      }}
    >
      {label}
    </Link>
  );
};

const SettingsStyleWrapper = styled(Box)`
  height: 100%;

  .sidebar-option {
    &:active {
      box-shadow: none;
    }

    &:focus {
      box-shadow: none;
    }

    &.active {
      color: #333333;
      font-weight: 600;
      background-color: #f4f6f9;
      border-left: 3px solid #026ae8;
    }
  }
`;
