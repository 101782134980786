import {
  Badge,
  Box,
  Heading,
  Stack,
  Menu,
  MenuButton,
  IconButton,
  MenuList,
  useToast,
} from '@chakra-ui/core';
import { deleteResellerSubaccountInvite } from 'app/authenticated-app/settings/service';
import { ConfirmModal, EmptyState, Input, Table, ToastBox } from 'app/components';
import format from 'date-fns/format';
import React, { SyntheticEvent, useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';
import noPeople from '../../../assets/no-people.svg';
import { OptionItem } from '../../people/table-columns';
import { InviteModal } from './invite-modal';

export const SubAccountInvites = (props: { invites?: any[]; sub_account_id: string }) => {
  const { invites, sub_account_id } = props;

  const toast = useToast();
  const queryClient = useQueryClient();

  const [searchQuery, setSearchQuery] = useState<string>('');
  const [isDeletingInvite, setIsDeletingInvite] = useState(false);
  const [inviteToDelete, setInviteToDelete] = useState<any | undefined>();

  const rows = invites?.filter((i: any) =>
    JSON.stringify(i).toLowerCase().includes(searchQuery.toLowerCase()),
  );

  const inviteStatusRegister: {
    [key: string]: { colorScheme: string };
  } = {
    accepted: { colorScheme: 'green' },
    sent: { colorScheme: 'yellow' },
  };

  const handleDeleteInvite = async () => {
    try {
      setIsDeletingInvite(true);
      await deleteResellerSubaccountInvite(sub_account_id, { id: inviteToDelete.id });
      setIsDeletingInvite(false);
      queryClient.invalidateQueries(['sub-account-invites', sub_account_id]);
      setInviteToDelete(undefined);
    } catch (error: any) {
      setIsDeletingInvite(false);
      toast({
        position: 'bottom-left',
        render: ({ onClose }) => <ToastBox onClose={onClose} message={error.message} />,
      });
    }
  };

  const InvitesTableColumns = ({ onDeleteInvite }: { onDeleteInvite(original: any): void }) => [
    {
      Header: 'Email',
      width: 375,
      accessor: '',
      Cell: ({ row: { original } }: any) => {
        return (
          <Box color="#757575" height="100%" display="flex" alignItems="center">
            {original.email}
          </Box>
        );
      },
    },
    {
      Header: 'Status',
      width: 225,
      accessor: '',
      Cell: ({ row: { original } }: any) => {
        const { colorScheme } = inviteStatusRegister[original?.status];

        return (
          <Badge fontSize="0.6rem" variantColor={colorScheme}>
            {original?.status}
          </Badge>
        );
      },
    },
    {
      Header: 'Date Sent',
      accessor: '',
      Cell: ({ row: { original } }: any) => {
        return (
          <Box color="#757575" height="100%" display="flex" alignItems="center">
            {original.created_datetime &&
              format(
                original?.created_datetime ? new Date(original?.created_datetime) : new Date(),
                'MMM do yyyy',
              )}
          </Box>
        );
      },
    },
    {
      Header: '',
      width: 100,
      accessor: 'id',
      Cell: ({ row: { original } }: any) => {
        if (original.status !== 'sent') return null;
        return (
          <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center">
            <Menu>
              <MenuButton
                as={IconButton}
                // @ts-ignore
                icon="overflow"
                size="sm"
                padding=".5rem"
                variant="ghost"
                height="auto"
                minWidth="auto"
                onClick={(event: SyntheticEvent) => event.stopPropagation()}
              />

              <MenuList minWidth="150px" placement="bottom">
                <OptionItem
                  icon="delete"
                  label="Remove user"
                  color="#E73D51"
                  onClick={(e: SyntheticEvent) => {
                    e.stopPropagation();
                    onDeleteInvite(original);
                  }}
                />
              </MenuList>
            </Menu>
          </Box>
        );
      },
    },
  ];

  const columns = useMemo(
    () => InvitesTableColumns({ onDeleteInvite: setInviteToDelete }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <div>
      <Stack isInline alignItems="center" marginBottom="1.5rem">
        <Heading size="sm" color="#333333" fontWeight="semibold">
          Invites
        </Heading>
        <Box fontWeight="400" color="#757575" fontSize="14px">
          - {invites?.length} invite(s)
        </Box>
      </Stack>
      <Stack isInline alignItems="center" spacing="0" justifyContent="space-between">
        <Input
          size="sm"
          autoFocus
          value={searchQuery}
          containerWidth="50%"
          placeholder="Search by name or email"
          onChange={(e: any) => setSearchQuery(e.target.value)}
        />
      </Stack>

      <Box marginTop="25px">
        {!!rows?.length && (
          <Table
            // @ts-ignore
            columns={columns}
            data={rows}
            onRowClick={() => {}}
          />
        )}
        {!rows?.length && (
          <EmptyState
            marginY="10vh"
            image={noPeople}
            subheading="No invites found"
            subheadingProps={{ marginTop: '25px', fontWeight: '500' }}
          >
            <InviteModal
              sub_account_id={sub_account_id}
              buttonProps={{
                size: 'sm',
                variantColor: 'blue',
                children: 'Invite people',
              }}
            />
          </EmptyState>
        )}
      </Box>
      {!!inviteToDelete && (
        <ConfirmModal
          title="Delete invite"
          isOpen={!!inviteToDelete}
          isLoading={isDeletingInvite}
          onConfirm={handleDeleteInvite}
          onClose={() => setInviteToDelete(undefined)}
        />
      )}
    </div>
  );
};
