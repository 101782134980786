import { Box, Checkbox, CloseButton, Grid } from '@chakra-ui/core'
import React, { useContext, useEffect, useState } from 'react'
import { RequestParamsContext, DropDownOptionsProps, RequestArrayProps } from '../../../contexts/request-contexts/params.context'
import { RequestHeadersContext } from '../../../contexts/request-contexts/headers.context'
import { RequestPaginationContext } from '../../../contexts/request-contexts/pagination.context'

interface Prop {
    n: RequestArrayProps;
    requestArray: RequestArrayProps[];
    type: string;
};

const Params = (props: Prop) => {

    const ContextData = props.type === 'params' ? RequestParamsContext : props.type === 'headers' ? RequestHeadersContext : RequestPaginationContext

    const {paramsState, setParamsState, rowId, setRowId, currentCell, setCurrentCell, createNewList, setText, cursorPosition, setCursorPosition, focusRef, urlParser, setDropDown, dropDown, dropDownOptions, focusState, setFocusState} = useContext(ContextData as typeof RequestParamsContext)

    const [showCloseIcon, setShowCloseIcon] = useState(0)
    const [dropDownRightPanel, setDropDownRightPanel] = useState(dropDownOptions[0].explanation)

    const changeHandler = (e: any, innerprops: any, textValue: never, columnName: any, columnNumber: any) => {

        cursorPosition[props.n?.id] = [e.currentTarget.selectionStart, e.currentTarget.selectionEnd]
        setCursorPosition([...cursorPosition])

        setText(e, innerprops.row?.id, textValue, columnName, props.n?.id)

        currentCell[props.n?.id] = [innerprops.row?.id, columnNumber, 'changeEvent']
        setCurrentCell([...currentCell])

        if (!innerprops.row?.isWritten) {
            createNewList(innerprops.row, props.n?.id)
        };
        urlParser(props.n?.id)
        
    };

    const keyEventHandler = (e: any, innerProps: any, column: any) => {
        cursorPosition[props.n?.id] = [e.currentTarget.selectionStart, e.currentTarget.selectionEnd]
        setCursorPosition([...cursorPosition])
    
        let isCellStart = e.currentTarget.selectionStart === 0 ? true : false
        let isCellEnd = e.currentTarget.selectionStart === e.currentTarget.value.length ? true : false

        if (e.key === 'ArrowLeft' && column > 1 && isCellStart) { // Move left
            currentCell[props.n?.id] = [innerProps.row.id, column-1, 'cellChanger']
            setCurrentCell([...currentCell])

        } else if (e.key === 'ArrowLeft' && column < 2 && innerProps.row.id > 0 && isCellStart) { // Move to previous row
            currentCell[props.n?.id] = [innerProps.row.id-1, 3, 'cellChanger']
            setCurrentCell([...currentCell])

        } else if (e.key === 'ArrowRight' && column < 3 && isCellEnd) { // Move Right
            currentCell[props.n?.id] = [innerProps.row.id, column+1, 'cellChanger']
            setCurrentCell([...currentCell])

        } else if (e.key === 'ArrowRight' && column > 2 && innerProps.row.id < paramsState[props.n?.id].length-1 && isCellEnd) { // Move to next row
            currentCell[props.n?.id] = [innerProps.row.id+1, 1, 'cellChanger']
            setCurrentCell([...currentCell])

        } else if (e.key === 'ArrowUp' && innerProps.row.id > 0) { // Move up
            currentCell[props.n?.id] = [innerProps.row.id-1, column, 'cellChanger']
            setCurrentCell([...currentCell])

        } else if (e.key === 'ArrowDown' && innerProps.row.id < paramsState[props.n?.id].length-1) { // Move down
            currentCell[props.n?.id] = [innerProps.row.id+1, column, 'cellChanger']
            setCurrentCell([...currentCell])

        } else if (e.key === '{' ) { // Drop down menu open
            dropDown[props.n?.id] = [true, innerProps?.row?.id, column, e.currentTarget.selectionStart]
            setDropDown([...dropDown])

        } else if (e.key === '}' ) { // Drop down menu close
            dropDown[props.n?.id] = [false, innerProps.row.id, column, e.currentTarget.selectionStart]
            setDropDown([...dropDown])

        } else if (e.key === 'Backspace' && innerProps.row.id < paramsState[props.n?.id].length-1) { // Drop down menu close
            if (e.target.value.at(-1) === '{') {
                dropDown[props.n?.id] = [false, innerProps.row.id, column, e.currentTarget.selectionStart]
                setDropDown([...dropDown])
                
            } else if (e.target.value.at(-1) === '}') {
                dropDown[props.n?.id] = [true, innerProps.row.id, column, e.currentTarget.selectionStart]
                setDropDown([...dropDown])
            };

        } else {
            currentCell[props.n?.id] = [innerProps.row.id, column, 'sameCell']
            setCurrentCell([...currentCell])
        };
    };

    const cellFocus = (innerProps: any, column: any) => {
        if (currentCell[props?.n?.id][0] === innerProps?.row?.id && currentCell[props?.n?.id][1] === column && focusState){
            return true
        } else {
            return false
        };   
    };
    
    const clickEventHandler = (e: any, innerProps: any, column: any) => {

        cursorPosition[props.n?.id] = [e.currentTarget.selectionStart, e.currentTarget.selectionEnd]
        setCursorPosition([...cursorPosition])

        currentCell[props.n?.id] = [innerProps.row.id, column, 'clickEvent']
        setCurrentCell([...currentCell])

        if (currentCell[props.n?.id][0] !== dropDown[props.n?.id][1]) {
            dropDown[props.n?.id][0] = false
            setDropDown([...dropDown])
        }
        
        if (currentCell[props.n?.id][0] === dropDown[props.n?.id][1] && currentCell[props.n?.id][1] === dropDown[props.n?.id][2] && focusState === true && e.target.value.includes('{')
            && !e.target.value.includes('}')) {
            dropDown[props.n?.id] = [dropDown[props.n?.id][0], innerProps.row.id, column, e.currentTarget.selectionStart]
            setDropDown([...dropDown])
        };

        dropDown[props.n?.id] = [!dropDown[props.n?.id][0], innerProps.row.id, column, e.currentTarget.selectionStart]
        setDropDown([...dropDown])
    };

    const focusEventHandler = (e: any, innerProps: any, column: any) => {
        if (currentCell[props.n?.id][2] === 'clickEvent' && currentCell[props.n?.id][1] === column && currentCell[props.n?.id][0] === innerProps.row.id) {
            e.currentTarget.setSelectionRange(cursorPosition[props.n?.id][0], cursorPosition[props.n?.id][1])

        } else if (currentCell[props.n?.id][2] === 'changeEvent' && currentCell[props.n?.id][1] === column && currentCell[props.n?.id][0] === innerProps.row.id) {
            e.currentTarget.setSelectionRange(cursorPosition[props.n?.id][0], cursorPosition[props.n?.id][1])

        } else if (currentCell[props.n?.id][2] === 'sameCell' && currentCell[props.n?.id][1] === column && currentCell[props.n?.id][0] === innerProps.row.id) {
            e.currentTarget.setSelectionRange(cursorPosition[props.n?.id][0], cursorPosition[props.n?.id][1])

        } else if (currentCell[props.n?.id][2] === 'cellChanger') {
            e.currentTarget.setSelectionRange(e.currentTarget.value.length, e.currentTarget.value.length)
        };
    };
    
    const closeButtonHandler = (row: any) => {
        paramsState[props.n?.id].splice(row.id, 1)
        paramsState[props.n?.id].forEach((item, index, array) => {
            if (index >= row.id) {
                array[index] = {...item, id: item.id-1};
            };
        });
        setParamsState([...paramsState])
        rowId[props.n?.id] = rowId[props.n?.id]-1
        setRowId([...rowId])
        urlParser(props.n?.id)

        dropDown[props.n?.id] = [false, row.id, 2]
        setDropDown([...dropDown])
    };

    const mouseOverEvent = (e: any, innerProps: any) => {
        setShowCloseIcon(innerProps.row.id)
    };

    const mouseLeaveEvent = (e: any, innerProps: any) => {
        setShowCloseIcon(-1)
    };


    function useOutsideAlerter(ref: any) {
        useEffect(() => {
            function handleClickOutside(e: any) {
                if (ref.current && !ref.current.contains(e.target)) {
                    setFocusState(false)
                } else if (ref.current && ref.current.contains(e.target))
                    setFocusState(true)
            }
            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    const checkBoxchangeHandler = (innerProps: any) => {
        innerProps.row.isDisabled = !innerProps.row.isDisabled
        urlParser(props.n?.id)
        
    };
    
    const dropDownClickHandler = (rowId: number, dropDownObject: DropDownOptionsProps, cursorIndex: number) => {
        const dropDownText = dropDownObject.value

        if (paramsState[props?.n?.id][rowId]['value_text'].substring(cursorIndex-1, cursorIndex) === '{') {
            paramsState[props?.n?.id][rowId]['value_text'] = paramsState[props?.n?.id][rowId]['value_text'].substring(0, cursorIndex) + dropDownText + '}' + paramsState[props?.n?.id][rowId]['value_text'].substring(cursorIndex);
            cursorPosition[props.n?.id] = [cursorPosition[props?.n?.id][0]+dropDownText.length+1, cursorPosition[props?.n?.id][1]+dropDownText.length+1]
            setCursorPosition([...cursorPosition])
        } else {
            paramsState[props?.n?.id][rowId]['value_text'] = paramsState[props?.n?.id][rowId]['value_text'].substring(0, cursorIndex) + '{' + dropDownText + '}' + paramsState[props?.n?.id][rowId]['value_text'].substring(cursorIndex);
            cursorPosition[props.n?.id] = [cursorPosition[props?.n?.id][0]+dropDownText.length+2, cursorPosition[props?.n?.id][1]+dropDownText.length+2]
            setCursorPosition([...cursorPosition])
        }
        setParamsState([...paramsState])
        urlParser(props.n?.id)
        dropDown[props.n?.id][0] = false
        setDropDown([...dropDown])
        setDropDownRightPanel(dropDownOptions[0].explanation)
    };

    const Row = (innerProps: any) => {

        return (
            <tr className='custom-app--params-rows' onMouseOver={(e)=>mouseOverEvent(e, innerProps)} onMouseLeave={(e)=>mouseLeaveEvent(e, innerProps)}>
                <td id='custom-app--params-checkbox'>
                {innerProps.row.isWritten && <Checkbox defaultIsChecked={!innerProps.row.isDisabled} onChange={() => checkBoxchangeHandler(innerProps)} name="name" id='custom-app--params-checkbox-input'></Checkbox>}  
                </td>
                <td>
                    <input type="text" name="name" placeholder={innerProps.row.isWritten? "" : "Key"}
                    autoFocus={cellFocus(innerProps, 1)}
                    value={innerProps.row.key_text} 
                    onChange={(e: any) => changeHandler(e, innerProps, e.target.value as never, 'key_text', 1)} 
                    onClick={(e: any)=> clickEventHandler(e, innerProps, 1)}
                    onFocus={(e: any)=>focusEventHandler(e, innerProps, 1)}
                    onKeyDown={(e: any) => {keyEventHandler(e, innerProps, 1)}}
                    autoComplete='off'
                    />  
                </td>

                <td className='custom-app--params-value-row'>
                    <input type="text" name="name" placeholder={innerProps.row.isWritten? "" : "Value"}
                    autoFocus={cellFocus(innerProps, 2)} 
                    value={innerProps.row.value_text} 
                    onChange={(e) => changeHandler(e, innerProps, e.target.value as never, 'value_text', 2)}
                    onClick={(e)=> clickEventHandler(e, innerProps, 2)} 
                    onFocus={(e)=>focusEventHandler(e, innerProps, 2)}
                    onKeyDown={(e) => {keyEventHandler(e, innerProps, 2)}}
                    className='custom-app--params-value-input'
                    autoComplete='off'
                    />
                    {dropDown[props?.n?.id][0] && dropDown[props?.n?.id][1] === innerProps?.row?.id && dropDown[props?.n?.id][2] === 2 && cellFocus(innerProps, 2) &&
                    <DropDownMenu dropDownOptions={dropDownOptions} inputValue={innerProps.row.description_text} rowId={innerProps.row.id} cursorPosition={dropDown[props?.n?.id][3]}/>}
                    
                </td>
                <td id='custom-app--row-right'>
                    <input type="text" name="name" placeholder={innerProps.row.isWritten? "" : "Description"}
                    autoFocus={cellFocus(innerProps, 3)} 
                    value={innerProps.row.description_text} 
                    onChange={(e) => changeHandler(e, innerProps, e.target.value as never, 'description_text', 3)} 
                    onClick={(e)=> clickEventHandler(e, innerProps, 3)}
                    onFocus={(e)=>focusEventHandler(e, innerProps, 3)}
                    onKeyDown={(e) => {keyEventHandler(e, innerProps, 3)}}
                    autoComplete='off'
                    />
                    {paramsState[props?.n?.id].length-1 !== innerProps.row.id && showCloseIcon === innerProps.row.id && !cellFocus(innerProps, 3) && <CloseButton className='custom-app--params-close-button' size="sm" onClick={() => closeButtonHandler(innerProps.row)}/>}
                </td>

                
            </tr>
        )
    }

    const DropDownMenu = (propsDrop: any) => {
        
        return (
            <Grid templateColumns="repeat(1, 10fr 12fr)" gap={1} className='custom-app--params-dropdown-request' >

                <Box className='custom-app--params-dropdown-mainside'>
                    {propsDrop.dropDownOptions
                    .filter((item: DropDownOptionsProps) => item.selected === false)
                    .map((item: DropDownOptionsProps, index: number) =>
                    <Box className='custom-app--params-dropdown-item' key={index} 
                        onMouseOver={() => setDropDownRightPanel(item.explanation)}
                        onClick={() => dropDownClickHandler(propsDrop.rowId, item, cursorPosition[props?.n?.id][0])}>
                        {item.value}
                    </Box>)}
                </Box>
                    
                <Box className='custom-app--params-dropdown-aside'>
                    <Box className='custom-app--params-dropdown-aside-title'>
                    <span className='custom-app--params-dropdown-span'>{dropDownRightPanel[0]}</span>
                    </Box>
                    <Box>
                    {dropDownRightPanel[1]}
                    </Box>
                    <Box className='custom-app-params-dropdown-tag'>
                    <span>{dropDownRightPanel[2]}</span>
                    </Box>
                </Box>

            </Grid>
        )
    }
    
    // useEffect(() => {
    //     focusRef.current = focusRef.current.slice(0, props.requestArray.length);
    // }, [props.requestArray, focusRef]);

    useOutsideAlerter(focusRef);
    
    return (
        <Box className='custom-app--params' ref={focusRef}>
            <table className='custom-app--params-tables'>
                <thead>
                    <tr className='custom-app-table-header'>
                        <th className='custom-app-row-header'></th>
                        <th className='custom-app-row-header'>KEY</th>
                        <th className='custom-app-row-header'>{props.type === 'pagination' ? 'PAGINATION PARAMETER' : 'VALUE'}</th>
                        <th className='custom-app-row-header custom-app--header-right'>{props.type === 'pagination' ? 'VALUE PER REQUEST' : 'DESCRIPTION'}</th>
                    </tr>
                </thead> 

                <tbody className='custom-app--params-table-body'>
                    {paramsState[props?.n?.id]?.map((row) => (
                    <Row key={row.id} row={row} />)
                    )}
                </tbody>
            </table> 
        </Box>
    )
};

export default Params
