import { Box, PseudoBox } from '@chakra-ui/core';
import { XSmallText } from 'app/components';
import React from 'react';
import root from 'react-shadow';
import unescape from 'lodash/unescape';
import { CampaignPayload } from '../../campaigns.types';
import { EmailTemplatePreview } from './email-template-preview';

type EmailCampaignSetupTemplateReviewProps = {
  campaign?: CampaignPayload;
};

export const EmailCampaignSetupTemplateReview = (props: EmailCampaignSetupTemplateReviewProps) => {
  const { campaign } = props;

  const { subject, content } = campaign ?? {};

  return (
    <Box rounded="4px" borderWidth="1px">
      <Box bg="white" px="1.5rem" py="0.25rem" borderBottomWidth="1px">
        <XSmallText color="gray.800">
          Subject:
          <PseudoBox as="span" pl="0.5rem" color="gray.400">
            {subject}
          </PseudoBox>
        </XSmallText>
      </Box>
      <root.div>
        <EmailTemplatePreview content={unescape(content ?? '')} />
        <style type="text/css">
          {`
            * { 
              font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; 
            }
          `}
        </style>
      </root.div>
      {/* <Box
        whiteSpace="normal"
        wordBreak="break-word"
        dangerouslySetInnerHTML={{ __html: content ?? '' }}
      /> */}
    </Box>
  );
};
