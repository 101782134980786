import { client } from 'utils';

export const getPermissions = () => client('permissions');

export const savePermissions = (payload: any) => {
  return client('permissions/save', {
    data: payload,
    method: 'POST'
  });
};

export const updatePermissions = (payload: any) => {
  return client('permissions', {
    data: payload,
    method: 'PATCH'
  });
};

export const getCustomPermissions = () => client('permissions/custom');

export const getPermissionSettings = () => client('permissions/settings');

export const updateUserPermission = (payload: any) => {
  return client('profile/permission/save', {
    data: payload,
    method: 'PATCH'
  });
};

export const sendInvite = (payload: any) => {
  return client('organisations/invite', {
    data: payload,
    method: 'POST'
  });
};