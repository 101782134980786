import React, { useEffect, useState } from 'react';
import { Box, IconButton, useToast } from '@chakra-ui/core/dist';
import { List, ListImportType } from '../../../../../lists.types';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { generateRandomListColor, generateRandomListIcon } from '../../../../../lists.utils';
import { CreateListWrapper as Wrapper } from './index.styles';
import { CreateListOptions } from './stages/options';
import { DEFAULTTABLE } from '../../../../../../tables/tables.data';
import { selectUserID } from '../../../../../../../unauthenticated-app/authentication';
import { ToastBox } from '../../../../../../../components';
import { TemplateTypes } from '../../../../../../tables';
import { CreateListUploadPrompt } from './stages/upload-prompt';
import { CreateListImportMapping } from './stages/mapping';
import { CreateListImportSuccessPrompt } from './stages/success-prompt';
import { PropertySchema } from '../../../../../../tables/components';
import { SelectListResource } from './stages/select-resource';
import { SelectListSource } from './stages/select-source';
import { CreateListSchemaMapping } from './stages/schema-mapping';

interface Props {
  importedData: any;
  addList: Function;
  addListFromTemplate: Function;
  openNoSubscriptionModal: Function;
  handleImport: Function;
  handleImportMapping: Function;
  handleImportNewTable: Function;
  fetchGSheetSpreadSheets: Function;
  fetchGSheetMetadata: Function;
  queueResourceImport: Function;
  queueAppImport: Function;
  fetchResourceSchema: Function;
  fetchAppEndpoints: Function;
  fetchAppEndpointSchema: Function;
  customApps: never[];
  customAppsKeys: never[];
}

export const CreateList = (props: Props) => {
  const [uploadLoading, setUploadLoading] = useState(false);
  const [importType, setImportType] = useState<ListImportType | null>(null);
  const [selectedResource, setSelectedResource] = useState<string | null>(null);
  const [sourceMeta, setSourceMeta] = useState<any[] | null>(null);
  const [selectedSheet, setSelectedSheet] = useState<null | string>(null);
  const [selectedSource, setSelectedSource] = useState<null | string>(null);
  const [accessType, setAccessType] = useState<'public' | 'private'>('private');

  const [sources, setSources] = useState([]);
  const [file, setFile] = useState<File | null>(null);
  const [stage, setStage] =
    useState<
      | 'options'
      | 'upload-prompt'
      | 'import-mapping'
      | 'select-resource'
      | 'select-source'
      | 'schema-mapping'
      | 'success-prompt'
    >('options');
  const [importedList, setImportedList] = useState<List | null>(null);
  const [createListLoading, setCreateListLoading] = useState<boolean>(false);
  const [addFromTemplateLoading, setAddFromTemplateLoading] = useState<TemplateTypes | null>(null);
  const [dataSample, setDataSample] = useState<{ [key: string]: any }[] | null>(null);

  const {
    addList,
    addListFromTemplate,
    importedData,
    fetchGSheetSpreadSheets,
    fetchGSheetMetadata,
    queueResourceImport,
    queueAppImport,
    fetchResourceSchema,
  } = props;

  const toast = useToast();
  const routerHistory = useHistory();

  const userID = useSelector(selectUserID);

  const goBack = () => {
    if (stage === 'options') routerHistory.push('/s/lists');
    else {
      if (stage === 'upload-prompt') {
        setStage('options');
        setImportType(null);
        setFile(null);
      }
      if (stage === 'import-mapping') {
        setStage('upload-prompt');
        setFile(null);
      }

      if (stage === 'select-resource') {
        setStage('options');
      }
      if (stage === 'select-source') {
        setStage('select-resource');
      }
      if (stage === 'schema-mapping') {
        setStage('select-source');
      }
    }
  };

  const handleCreateList = async () => {
    setCreateListLoading(true);
    try {
      const table = await addList({
        user_id: userID,
        columns: DEFAULTTABLE.properties,
        name: 'Untitled',
        color: generateRandomListColor(),
        icon: generateRandomListIcon(),
        preload_rows: true,
        access_type: accessType,
      });
      toast({
        position: 'bottom-left',
        render: ({ onClose }) => (
          <ToastBox status="success" onClose={onClose} message="List created" />
        ),
      });

      return routerHistory.replace(`/s/lists/view/${table.id}`);
    } catch (e: any) {
      toast({
        position: 'bottom-left',
        render: ({ onClose }) => <ToastBox onClose={onClose} message={e} />,
      });
    }
    setCreateListLoading(false);
  };

  const handleCreateListFromTemplate = async (template_type: TemplateTypes) => {
    setAddFromTemplateLoading(template_type);
    try {
      const { table } = await addListFromTemplate({ type: template_type, access_type: accessType });
      toast({
        position: 'bottom-left',
        render: ({ onClose }) => (
          <ToastBox status="success" onClose={onClose} message="List created" />
        ),
      });

      return routerHistory.replace(`/s/lists/view/${table.id}`);
    } catch (e: any) {
      toast({
        position: 'bottom-left',
        render: ({ onClose }) => <ToastBox onClose={onClose} message={e} />,
      });
    }
    setAddFromTemplateLoading(null);
  };

  const handleSelectImportType = (_type: ListImportType) => {
    setImportType(_type);
    if (!_type) return;
    const allKeys = [
      'mysql',
      'pgsql',
      'mssql',
      'google-sheets',
      'shopify',
      'woo-commerce',
      'mambu',
      'custom',
      'magento',
      'typeform',
      'hubspot',
    ].concat(props.customAppsKeys);
    if (allKeys.includes(_type)) {
      setStage('select-resource');
    } else setStage('upload-prompt');
  };
  const handleUpload = (_file: File, _dataSample: any) => {
    setFile(_file);
    setDataSample(_dataSample);
  };

  const handleFileImport = async () => {
    setUploadLoading(true);
    await props.handleImport({
      file,
      source: importType === 'ms-excel' ? 'excel' : 'csv',
      name: file?.name,
      agree: true,
      date_format: '',
    });
    setUploadLoading(false);
  };

  const handleNewTableImport = async (properties: PropertySchema[]) => {
    try {
      const list = await props.handleImportNewTable(properties, accessType);
      setImportedList(list);
      toast({
        position: 'bottom-left',
        render: ({ onClose }) => (
          <ToastBox status="success" onClose={onClose} message="List import queued successfully" />
        ),
      });
      setStage('success-prompt');
    } catch (e: any) {
      toast({
        position: 'bottom-left',
        render: ({ onClose }) => <ToastBox onClose={onClose} message={e} />,
      });
    }
  };

  useEffect(() => {
    if (importedData) {
      setStage('import-mapping');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [importedData]);

  return (
    <Box className="content">
      <Box className="section-title">
        <Box className="title">
          {stage !== 'success-prompt' && (
            <IconButton
              mb="5px"
              mr="10px"
              size="sm"
              rounded="8px"
              variant="ghost"
              onClick={goBack}
              icon="arrow-back"
              aria-label="back"
              borderWidth="1px"
            />
          )}
          Create List
        </Box>
      </Box>

      <Wrapper>
        {stage === 'options' && (
          <CreateListOptions
            createList={handleCreateList}
            selectImportType={handleSelectImportType}
            createListLoading={createListLoading}
            addFromTemplateLoading={addFromTemplateLoading}
            handleAddFromTemplate={handleCreateListFromTemplate}
            setAccessType={setAccessType}
            accessType={accessType}
            customApps={props.customApps}
          />
        )}
        {stage === 'upload-prompt' && (
          <CreateListUploadPrompt
            file={file}
            importType={importType}
            handleUpload={handleUpload}
            handleFileImport={handleFileImport}
            uploadLoading={uploadLoading}
          />
        )}
        {stage === 'import-mapping' && (
          <CreateListImportMapping
            importedData={importedData}
            handleCreateTable={handleNewTableImport}
            dataSample={dataSample}
          />
        )}
        {stage === 'success-prompt' && (
          <CreateListImportSuccessPrompt
            id={importedList?.id}
            proceed={() => routerHistory.replace(`/s/lists/view/${importedList?.id}`)}
          />
        )}
        {stage === 'select-resource' && (
          <SelectListResource
            fetchSources={() => {}}
            setSources={setSources}
            setStage={setStage}
            selectedResource={selectedResource}
            setSelectedResource={setSelectedResource}
            importType={importType}
            fetchGSheetSpreadSheets={fetchGSheetSpreadSheets}
            fetchResourceSchema={fetchResourceSchema}
            fetchAppEndpoints={props.fetchAppEndpoints}
            customApps={props.customApps}
          />
        )}
        {stage === 'select-source' && (
          <SelectListSource
            {...{
              importType,
              sources,
              setStage,
              selectedResource,
              fetchGSheetMetadata,
              sourceMeta,
              setSourceMeta,
              selectedSheet,
              setSelectedSheet,
              selectedSource,
              setSelectedSource,
              fetchAppEndpointSchema: props.fetchAppEndpointSchema,
              customApps: props.customApps,
            }}
          />
        )}
        {stage === 'schema-mapping' && (
          <CreateListSchemaMapping
            {...{
              importType,
              setImportedList,
              setStage,
              selectedResource,
              selectedSheet,
              selectedSource,
              sources,
              sourceMeta,
              queueResourceImport,
              queueAppImport,
              accessType,
              customApps: props.customApps,
            }}
          />
        )}
      </Wrapper>
    </Box>
  );
};
