import { Flex, Icon, IconButton, Stack } from '@chakra-ui/core';
import styled from '@emotion/styled';
import { EmailDomain } from 'app/authenticated-app/marketing/campaigns/campaigns.types';
import { SmallText } from 'app/components';
import React from 'react';

export const EmailDomainListItem = (props: {
  onView(): void;
  onDelete(): void;
  domain: EmailDomain;
}) => {
  const { domain, onView, onDelete } = props;

  const domainStatusRegister: { [key: string]: { color: string; icon: string; text: string } } = {
    pending: { color: 'yellow.500', icon: 'warning', text: 'Pending' },
    verified: { color: 'green.500', icon: 'check-circle', text: 'Verified' },
    failed: { color: 'red.500', icon: 'warning-2', text: 'Unverified' },
  };

  const { color, icon, text } = domainStatusRegister[domain.status];

  return (
    <EmailDomainListItemContainer p="1rem" alignItems="center" borderBottomWidth="1px">
      <Stack isInline width="30%" color={color} alignItems="center">
        <Icon name={icon} />
        <SmallText>{text}</SmallText>
      </Stack>
      <Stack width="70%" isInline alignItems="center" spacing="1rem" justifyContent="space-between">
        <SmallText fontWeight="bold">{domain.domain}</SmallText>
        <Stack isInline>
          <IconButton
            size="xs"
            icon="view"
            opacity={0}
            variant="ghost"
            onClick={onView}
            transform="all 0.2s"
            className="email-domain-btn"
            aria-label="view domain details"
            _hover={{
              color: 'blue.500',
              bg: 'transparent',
            }}
            _focus={{
              color: 'blue.500',
              bg: 'transparent',
              boxShadow: 'none',
            }}
            _active={{
              color: 'blue.500',
              bg: 'transparent',
              boxShadow: 'none',
            }}
          />
          <IconButton
            //@ts-ignore
            icon="trash"
            size="xs"
            opacity={0}
            variant="ghost"
            transform="all 0.2s"
            aria-label="delete domain"
            className="email-domain-btn"
            onClick={onDelete}
            _hover={{
              color: 'red.500',
              bg: 'transparent',
            }}
            _focus={{
              color: 'red.500',
              bg: 'transparent',
              boxShadow: 'none',
            }}
            _active={{
              color: 'red.500',
              bg: 'transparent',
              boxShadow: 'none',
            }}
          />
        </Stack>
      </Stack>
    </EmailDomainListItemContainer>
  );
};

const EmailDomainListItemContainer = styled(Flex)`
  &:hover {
    .email-domain-btn {
      opacity: 1;
    }
  }
`;
