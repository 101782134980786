import { Box, Flex, Heading, ModalBody, ModalCloseButton, Stack } from '@chakra-ui/core';
import {
  BodyText,
  Button,
  ModalContainer,
  ModalContainerOptions,
  SimpuWatermark,
  SmallSubtitle,
} from 'app/components';
import React, { ReactNode, useRef } from 'react';
import { exportComponentAsPNG } from 'react-component-export-image';
import { CampaignData } from '../../campaigns.types';
import { track } from 'utils/segment';

export const ShareCampaignAnalyticsModal: React.FC<
  ModalContainerOptions & { campaign: CampaignData; children: ReactNode }
> = ({ isOpen, campaign, onClose, children }) => {
  const componentToDownloadRef = useRef();

  const handleDownloadImage = () => {
    exportComponentAsPNG(componentToDownloadRef, {
      fileName: `${campaign.name} Campaign Report - ${new Date()}`,
    });
    track('Downlaod Campaign Analytics');
  };

  return (
    <ModalContainer
      size="6xl"
      isOpen={isOpen}
      onClose={onClose}
      isCentered={false}
      title="Share Campaign Analytics"
    >
      <ModalCloseButton size="sm" />
      <ModalBody>
        <Stack spacing="1rem">
          <Flex alignItems="center" justifyContent="space-between">
            <Box>
              <SmallSubtitle fontWeight="500">Download an image of this report</SmallSubtitle>
              <BodyText>
                Download a snapshot image of this report and share with your teammates.
              </BodyText>
            </Box>
            <Button size="sm" variantColor="blue" onClick={handleDownloadImage}>
              Download Image
            </Button>
          </Flex>
          <Box p="1rem" rounded="4px" bg="gray.100" overflowY="auto" ref={componentToDownloadRef}>
            <Stack spacing="1rem">
              <Heading textAlign="center" size="md">
                {campaign.name} Campaign Report
              </Heading>
              <Box>{children}</Box>
              <SimpuWatermark
                containerProps={{
                  isInline: false,
                }}
                logoProps={{
                  width: '120px',
                  style: {
                    top: '-32px',
                    position: 'relative',
                  },
                }}
              />
            </Stack>
          </Box>
        </Stack>
      </ModalBody>
    </ModalContainer>
  );
};
