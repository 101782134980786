import styled from '@emotion/styled';

// noinspection CssUnknownTarget
export const Wrapper = styled.div`
  display: flex;

  .side-bar {
    position: fixed;
    width: 286px;
    height: calc(100vh - 60px);
    padding: 50px 18px;
    margin-left: 15vw;

    @media (max-width: 1400px) {
      margin-left: 10vw;
    }
    @media (max-width: 1000px) {
      margin-left: 5vw;
    }
    @media (max-width: 800px) {
      margin-left: 2.5vw;
    }
    @media (max-width: 640px) {
      z-index: 2;
      width: 100%;
      height: auto;
      padding: 1rem;
      margin-left: 0;
      background-color: white;
      border-bottom: 1px solid #e2e8f0;
    }

    .search-section {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 25px;

      .action-section {
        display: flex;
        align-items: center;
        margin-left: 10px;

        .btn-bg {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 2px;
          border-radius: 5px;
          margin-left: 3px;
          cursor: pointer;
        }

        .active {
          background-color: rgba(196, 196, 196, 0.22);
        }
      }
    }

    .search-input {
      border-radius: 8px;
      height: 30px;
      width: -webkit-fill-available;
      box-shadow: none;
      outline: none;
      font-size: 14px;
      line-height: 18px;
      border: none;
      background-color: rgba(196, 196, 196, 0.22);
    }

    .option-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 10px 0;
      padding: 5px 10px;
      border-radius: 5px;
      font-size: 14px;
      color: #4f4f4f;
      cursor: pointer;
    }

    .option-item:hover,
    .active {
      background-color: rgba(196, 196, 196, 0.22);
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    width: calc(70vw - 286px);

    color: white;
    padding: 45px 20px;

    margin-left: calc(15vw + 286px);

    @media (max-width: 1400px) {
      margin-left: calc(10vw + 286px);
      width: calc(80vw - 286px);
    }

    @media (max-width: 1000px) {
      margin-left: calc(5vw + 286px);
      width: calc(90vw - 286px);
    }

    @media (max-width: 640px) {
      width: 100%;
      margin-left: 0;
      padding-top: 380px;
    }

    .section-title {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;

      .title {
        font-size: 26px;
        font-weight: 600;
        color: #333333;
      }

      .inactive {
        color: #b0b0b0;
      }

      .upgrade-badge {
        border-radius: 10px;
        padding: 2px 5px;
        font-size: 10px;
        background-color: #026ae8;
        font-weight: 500;
        color: white;
        cursor: pointer;
        transition: all 0.1s ease-in;

        outline: none;

        :hover {
          transform: scale(1.02);
        }
      }
    }

    .description {
      font-size: 14px;
      color: #333333;
    }

    .section-type {
      margin-top: 20px;

      .section-heading {
        color: #737373;
        margin-bottom: 20px;
        font-size: 12px;
        font-weight: 500;
        display: flex;
        text-transform: uppercase;
        flex-direction: row;
        width: 100%;
        align-items: center;
      }

      .section-grid {
        color: #333333;

        .item {
          height: 40px;
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 10px;
          border: lightgrey 2px solid;
          border-radius: 8px;
          font-size: 14px;
          cursor: pointer;

          .coming-soon {
            background-color: #e8f3f2;
            color: limegreen;
            font-size: 12px;
            margin-left: auto;
            text-align: right;
            padding: 0 5px;
            border-radius: 5px;
          }

          :hover {
            background-color: #fafafa;
          }
        }

        .disabled {
          opacity: 0.65;
          cursor: not-allowed;
          background-color: #e7e7e7 !important;
        }
      }
    }

    .section-resource-config {
      display: flex;
      flex-direction: row;

      .section-resource-config-form {
        max-width: 550px;
        color: #333333;

        .row {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          margin-bottom: 35px;

          .label {
            font-weight: 400;
            font-size: 14px;
            width: 140px;
            margin-top: 5px;
            margin-right: 15px;
            display: flex;
            align-items: flex-end;
            color: #737373;
          }

          .field {
            width: 100%;

            .description {
              color: #737373;
              font-size: 12px;
            }
          }
        }

        .section-info {
          font-size: 13px !important;
          display: flex;
          flex-direction: column;
          color: #333333;
          background-color: #f5f5f5;
          border-radius: 5px;
          padding: 10px;
          height: fit-content;

          .link {
            color: #5252ff;
            cursor: pointer;

            :hover {
              text-decoration: underline;
            }
          }
        }

        .section-actions {
          margin-top: 40px;
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          align-items: center;

          * {
            font-weight: 500;
          }
        }
      }

      .section-resource-config-info {
        font-size: 13px !important;
        margin-left: 35px;
        display: flex;
        flex-direction: column;
        color: #333333;
        background-color: #f5f5f5;
        border-radius: 5px;
        padding: 15px;
        height: fit-content;
        width: 100%;
        max-width: 250px;

        .description {
          font-size: 13px;
          color: #737373;
          font-weight: 400;
          margin-bottom: 10px;
        }

        .ip {
          font-weight: 500;
        }
      }
    }

    .list-item--grid,
    .add-button--grid {
      cursor: pointer;
      max-width: 75px;

      * {
        transition: all 0.2s ease-in;
      }

      .box {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 16px;
        height: 80px;
        margin-bottom: 13px;

        img {
          height: 32px;
        }
      }
      
      

      .label {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        font-size: 13px;
        line-height: 16px;
        text-align: center;
        color: #333333;
      }

      :hover {
        .box {
          transform: scale(1.02);
          box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        }
      }

      .action-btn-bg {
        position: absolute;
        right: 8px;
        bottom: 8px;
        display: none;
        align-items: center;
        padding: 0px;
        border-radius: 5px;
        transition: all 0.2s ease-in-out;

        :hover {
          background-color: rgba(0, 0, 0, 0.3);
          opacity: 0.5;
        }
      }

      :hover {
        .action-btn-bg {
          display: flex;
        }
      }
    }

    .add-button--grid {
      .box {
        background-color: rgba(0, 0, 0, 0.1);
        color: #dbdbdb;
      }
    }

    .add-button--grid-custom {
      cursor: pointer;
      max-width: 75px;

      * {
        transition: all 0.2s ease-in;
      }

      .box {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 16px;
        height: 80px;
        margin-bottom: 13px;

        img {
          height: 32px;
        }
      }

      .label {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        font-size: 13px;
        line-height: 16px;
        text-align: center;
        color: #333333;
      }

      :hover {
        .box {
          transform: scale(1.02);
          box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        }
      }

      .action-btn-bg {
        position: absolute;
        right: 8px;
        bottom: 8px;
        display: none;
        align-items: center;
        padding: 0px;
        border-radius: 5px;
        transition: all 0.2s ease-in-out;

        :hover {
          background-color: rgba(0, 0, 0, 0.3);
          opacity: 0.5;
        }
      }

      :hover {
        .action-btn-bg {
          display: flex;
        }
      }
    }

    .add-button--grid-custom {
      .box {
        color: #dbdbdb;
        width: 75px;
      }
      }
    }

    .customBackIcon {
      cursor: pointer;
    }

    .customAppTitles {
      cursor: pointer;
      font-size: 0.9rem;
    }

    .custom-app--grid-icon {
      display: grid;
      grid-template-columns: 1fr fit-content(5%);
    }

    .custom-app--grid {
      display: grid;
      grid-template-columns: 1fr fit-content(5%);
    }

    .custom-app--params {
      overflow: auto;
      color: #333333;
      width: 100%;
      height: 13rem;
      border-radius: 10px;
      border: 1px solid #DADEE3;
      // position: relative;
      padding: 0 0.35rem;
      // border-bottom: 0px solid #DADEE3;
      // border-bottom-left-radius: 0px;
      // border-bottom-right-radius: 0px;
    }

    .custom-app--section-outer{
      position: relative;
    }

    .custom-app--params-tables{
      color: #333333;
      width: 100%;
      margin: 0 auto;
      margin-top: 0.5rem;
    }

    .custom-app-table-header{
      font-weight: 200;
      text-align: left;
      height: 2.01rem;
    }

    .custom-app--params-table-body{
      
    }

    #custom-app--params-checkbox {
      width: 2rem;
      border-bottom-width: 1px;
      border-right-width: 1px;
      padding-left: 0.5rem;
      padding-top: 0.3rem;
    }

    .custom-app--params-rows td{
      width: calc(32%);
      height: 2.1rem;
      font-size: 0.75rem;
      border-bottom-width: 1px;
      border-bottom-color: #DADEE3;
      border-right-width: 1px;
      padding-top: 0;
    }

    .custom-app--params-rows input {
      width: 100%;
      border: none;
      height: 100%;
      padding-left: 0.32rem;
    }

    .custom-app--params-rows input:focus {
      width: 100%;
      outline: none;
      border:1.5px solid #026ae8;
      height: 100%;
      padding-left: calc(0.32rem - 1.5px);
    }

    .custom-app-field label {
      font-size: 0.85rem;
    }

    .custom-app-field input, select {
      font-size: 0.87rem;
      color: black;
      border-color: #858C94;
    
    }

    .custom-app-field-settings input, select {
      font-size: 0.87rem;
      color: black;
      border-color: #858C94;
      border-radius: 5px;
    }

    .custom-app-field-settings input{
      font-size: 0.87rem;
      color: black;
      border-color: #EBEEF2;
      border-radius: 5px;
    }

    .custom-app-field--request-method select {
      width: 10rem;
    }

    .custom-app-row-header {
      font-size: 0.75rem;
      font-weight: 400;
      border-bottom-width: 1px;
      border-right-width: 1px;
      padding-left: 0.5rem;
      min-width: 1.6rem;
      border-bottom-color: #DADEE3;
    }

    #custom-app--row-right {
      width: calc(50% - 1rem);
      border-right-width: 0px;
      position: relative;
    }

    .custom-app--header-right {
      border-right-width: 0px;
    }

    #custom-app--params-checkbox-input {
      width: 1rem;
      heigth: 1rem;
      // color: #2B3A4B;
      // font-size: 0.8rem;
    }

    .custom-app--params-close-button {
      position: absolute;
      left: calc(100% - 1.6rem);
      margin-top: 0.34rem;
    }

    #custom-app--row-close {
      border-right-width: 0px;
      border-left-width: 0px;
    }

    .custom-app-apps-button {
      float: right;
    }

    .custom-app-apps-button-white {
      background-color: white;
      border: 1px solid #026ae8;
      color: blue;
      :hover {
        background-color: white;
        border-width: 2px;
      }
    }

    .custom-app--radio-image {
        border-width: 1px;
        border-radius: 10px;
        width: 100%;
        height: 18rem;
        padding-left: 1.05rem;
        padding-top: 0.85rem;
        color: #333333;
        font-size: 0.85rem;
        position: relative;
        z-index: 1;
    }

    .custom-app--radio-image-title {
      background-color: white;
      position: absolute;
      bottom: 17.25rem;
      z-index: 2;
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    }

    .custom-app-radio-input {
      opacity: 0;
      width: 0;
      height: 0;
      &:checked + .custom-app-radio-new {
        border: 4px solid #026ae8;
      }
    }

    .custom-app-radio-new {
      width: 1em;
      height: 1em;
      border-radius: 50%;
      border: 1px solid #CDCED9;
    }

    .custom-app-radio-label {
      cursor: pointer;
    }

    .custom-app--params-value-row{
    }

    .custom-app--params-value-input{
    }

    .custom-app--params-dropdown{
      position: absolute;
      z-index: 10;
      background-color: #F9F9FA;
      width: 15%;
      max-width: 20rem;
      box-shadow: rgba(99,99,99,0.2) 0px 1px 8px 0px;
      border: rgba(67,90,111,0.1) solid 1px;
      border-radius: 8px;
      padding: 0.3rem;
      height: 8.2rem;
    }

    .custom-app--params-dropdown-request{
      position: absolute;
      z-index: 10;
      background-color: #F9F9FA;
      width: 31%;
      max-width: 20rem;
      box-shadow: rgba(99,99,99,0.2) 0px 1px 8px 0px;
      border: rgba(67,90,111,0.1) solid 1px;
      border-radius: 8px;
      padding: 0.3rem;
      height: 8.2rem;
    }

    .custom-app--params-dropdown-item{
      border-bottom: rgba(67,90,111,0.1) solid 1px;
      padding-right: .2rem;
      padding-left: .25rem;
      padding-top: 0.3rem;
      padding-bottom: 0.3rem;
      font-size: 0.66rem;
      :hover {
        cursor: pointer;
        background-color: rgba(196,196,196,0.22);
      }
    }

    .custom-app--params-dropdown-aside{
      padding-top: 0.3rem;
      padding-left: 0.2rem;
      border-left: rgba(67,90,111,0.1) solid 1px;
      font-size: 0.66rem;
      overflow: auto;
    }

    .custom-app--params-dropdown-aside-title{
      white-space: nowrap;
      color: #026ae8;
      margin-bottom: 0.34rem;
      font-weight: 500;
    }

    .custom-app--params-dropdown-mainside{
      :hover {
        cursor: pointer;
      }
    }

    // .custom-app--params-dropdown-span{
    //   background-color: #026ae8;
    //   border-radius: 4px;
    //   padding: 0.1rem;
    // }

    .custom-app-params-dropdown-tag{
      color: white;
      margin-top: 0.5rem;
      span {
        background-color: #026ae8AA;
        border-radius: 6px;
        padding: 0.2rem;
      }
    }

    .custom-app--list-view{
      position: relative;
    }

    .custom-app--grid-menu{
      position: absolute;
      color: #333333;
      font-size: 0.8rem;
      background-color: white;
      box-shadow: rgba(99,99,99,0.2) 0px 1px 8px 0px;
      border: rgba(67,90,111,0.1) solid 1px;
      border-radius: 6px;
      padding: 0.25rem 0.25rem;
      margin-top: -1.5rem;
      margin-left: -1.5rem;
      z-index: 10;
    }

    .custom-app--grid-menu-item{
      padding: 0.3rem 0.6rem;
      
      :hover {
        cursor: pointer;
        background-color: rgba(196,196,196,0.22);
      }
      span {
        color: #E73D51
      }
    }

    .custom-app--request-container{
      padding: 3px;
      border-radius: 10px;
      .warning-box {
        display: none;
      }
    }

    .custom-app--request-body-title {
      color: #333333;
      font-size: 0.85rem;
    }

    .custom-app--request-contracted {
      border: 1px solid #DADEE3;;
      border-radius: 8px;
      color: #333333;
      padding: 0.5rem 1rem;
      box-shadow: 0px 3px 32px rgba(236, 240, 245, 0.44);
    }

    .custom-app--request-contracted-inner{
      color: #026ae8;

      :hover {
        cursor: pointer;
      }
    }

    .custom-app--request-contracted-inner-red{
      color: #DA1414;

      :hover {
        cursor: pointer;
      }
    }

    .custom-app--request-name input{
      border: 2px solid #026ae8;
      :focus {
        border: 2px solid #026ae8;
        box-shadow: none;
      }
    }

    .custom-app--authorisation-text{
      color: #2B3A4B;
      font-size: 0.8rem;
    }

    .custom-app--authorisation-text-secondary{
      color: #026ae8;
      font-size: 0.8rem;
      :hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }

    .custom-app--submit-button div{
      right: 3.4rem;
    }

    .custom-app--request-test-success{
      margin-top: 0.85rem;
      color: #394452;
      background-color: #E8F7FF;
      margin-bottom: -0.9rem;
      padding: 0.75rem 1rem;
      border-radius: 10px; 
    }

    .custom-app--request-test-success-response{
      margin-top: 0.85rem;
      color: #394452;
      background-color: #E8F7FF;
      margin-bottom: 1rem;
      padding: 0.75rem 1rem;
      border-radius: 10px; 
    }

    .custom-app--request-test-success-title{
      font-size: 0.875rem;
      font-weight: 500;
    }

    .custom-app--request-test-success-detail{
      font-size: 0.79rem;
      :hover {
        cursor: pointer;
      }
    }

    .custom-app--request-test-failure{
      margin-top: 0.85rem;
      color: #394452;
      background-color: #FAE7E7;
      margin-bottom: -0.9rem;
      padding: 0.75rem 1rem;
      border-radius: 10px; 
    }

    .custom-app--request-test-failure-response{
      margin-top: 0.85rem;
      color: #394452;
      background-color: #FAE7E7;
      margin-bottom: 1rem;
      padding: 0.75rem 1rem;
      border-radius: 10px; 
    }

    .custom-app--request-test-failure-title{
      font-size: 0.875rem;
      font-weight: 500;
    }

    .custom-app--request-test-failure-detail{
      font-size: 0.79rem;
    }

    .custom-app--request-test-close{
      :hover {
        cursor: pointer;
      }
    }

    .custom-app--request-test-success-expand{
      color: #026ae8;
    }
    
    .custom-app--titles-disabled{
      :hover {
        cursor: not-allowed;
      }
    }

    .custom-app-webhooks-card{
      color: black;
      border: 1px solid #ECF0F5;
      border-radius: 10px;
      box-shadow: 0px 3px 32px rgba(236, 240, 245, 0.44);
    }

    .custom-app--webhooks-info{
      color: #333333;
      font-size: 0.75rem;
      border: 1px solid #ECF0F5;
      box-shadow: 0px 3px 32px rgba(236, 240, 245, 0.44);
    }

    .custom-app--webhooks-light{
      color: #A5ABB3;
    }

    .custom-app--webhooks-url{
      color: #2B3A4B;
    }
    
    .custom-app--webhooks-copied-button{
      background: #EDF9F0;
      color: #5ACA75;
      border: 1px solid #5ACA75;
      font-weight: 400;
    }

    .custom-app--webhooks-copy-button{
      border: 1px solid #858C94;
      color: #858C94;
      font-weight: 400;
      font-size: 0.7rem;

    }

    .list-item--list,
    .add-button--list {
      display: flex;
      flex-direction: row;
      align-items: center;
      cursor: pointer;
      padding: 6px 8px;
      border-radius: 10px;
      transition: all 0.2s ease-in-out;
      justify-content: space-between;

      .box {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        height: 45px;
        width: 45px;
        margin-right: 15px;

        img {
          height: 18px;
        }
      }

      .label {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 16px;
        text-align: center;
        color: #333333;
      }

      .action-btn-bg {
        display: none;
        align-items: center;
        padding: 0px;
        border-radius: 5px;
        background-color: #c3c3c3;
        transition: all 0.2s ease-in-out;

        :hover {
          background-color: #585858;
        }
      }

      :hover {
        background-color: #e9e9e9;

        .action-btn-bg {
          display: flex;
        }
      }
    }

    .active {
      background-color: #e9e9e9;

      .action-btn-bg {
        display: flex;
      }
    }

    .add-button--list {
      .box {
        background-color: rgba(196, 196, 196, 0.22);
      }
    }
  }

  .filter-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;
    width: 100%;

    * {
      color: #333333;
    }

    .conjunction,
    .operand {
      font-size: 14px;
      width: 100px;
      margin-right: 4px;
    }

    .selected-label-text {
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .column-select,
    .conjunction-select {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      background-color: #f6f6f6;
      border-radius: 5px;
      padding: 5px 10px;
      width: 180px;
      margin-right: 4px;
      font-size: 14px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      .selected-label-text {
        width: 200px;
      }

      :hover {
        cursor: pointer;
      }

      * {
        font-size: 14px;
      }

      .icon {
        margin-right: 10px;
      }
    }

    .conjunction-select {
      padding-left: 10px;
      width: 100px;
    }

    .operator-select {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      background-color: #f6f6f6;
      border-radius: 5px;
      padding: 5px 10px;
      margin-right: 4px;
      width: 240px !important;
      font-size: 14px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      :hover {
        cursor: pointer;
      }

      * {
        font-size: 14px;
      }
    }

    .value-input {
      background-color: #f6f6f6;
      font-size: 14px;
      padding: 5px 10px;
      width: 180px;
      height: fit-content;
      box-shadow: none;
      margin-right: 10px;

      :focus {
        box-shadow: none;
        outline: none;
      }
    }

    .icon-menu {
      height: 25px;
      width: 25px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      border-radius: 13px;
      cursor: pointer;
      transition: all 0.2s ease-in;

      :hover {
        background-color: #f2f2f2;
      }
    }

    * {
      font-size: 16px;
    }
  }
`;
