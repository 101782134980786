import { Box, BoxProps } from '@chakra-ui/core';
import { SmallSubtitle } from 'app/components/Typography';
import * as React from 'react';

type BottomCardProps = {
  label?: string;
};

export function BottomCard({ label, children, ...props }: BottomCardProps & BoxProps) {
  return (
    <Box
      py="2rem"
      rounded="8px"
      color="gray.500"
      background="#fff"
      letterSpacing="wide"
      marginBottom="0.5rem"
      boxShadow="0 1px 3px 0 rgba(0, 0, 0, 0.1),0 1px 2px 0 rgba(0, 0, 0, 0.06)"
      {...props}
    >
      <SmallSubtitle px="2rem" color="gray.900">
        {label}
      </SmallSubtitle>
      {children}
    </Box>
  );
}
