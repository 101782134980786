import { Box, Flex, Heading, Image, Text, Link as ChakraLink } from '@chakra-ui/core';
import { useResellerSettings } from 'app/components';
import React from 'react';
import { Link } from 'react-router-dom';
import { AuthLayoutContainer } from './AuthLayout.style';

export interface AuthLayoutProps {
  heading?: string;
  subheading?: string;
  children: React.ReactNode;
  footing?: React.ReactNode;
}

export function AuthLayout({ heading, subheading, children, footing }: AuthLayoutProps) {
  const resellerSettings = useResellerSettings();

  const { logo, name } = resellerSettings ?? {};

  return (
    <AuthLayoutContainer>
      <Box
        height="100%"
        position="relative"
        display="flex"
        maxW="1100px"
        mx="auto"
        mb="0"
        alignItems="center"
        flexDirection="column"
        justifyContent="space-between"
      >
        <Flex alignItems="center" justifyContent="center">
          <Image alt="Logo" src={logo} width="150px" />
        </Flex>

        <Flex>
          <Box margin={['1rem', '1rem', '2rem']} width={['auto', 'auto', '520px']}>
            <Box
              mb="30px"
              py="40px"
              position="relative"
              paddingBottom="45px"
              borderRadius="4px"
              backgroundColor="white"
              px={['1rem', '1rem', '40px']}
              boxShadow="0px 4px 10px rgba(0, 0, 0, 0.1)"
            >
              <Box
                display="flex"
                marginBottom="20px"
                flexDirection="column"
                justifyContent="center"
              >
                <Box marginBottom="16px">
                  <Heading
                    size="xl"
                    fontSize="32px"
                    fontFamily="Averta"
                    paddingBottom="8px"
                    fontWeight="500"
                    color="#333333"
                  >
                    {heading}
                  </Heading>
                  <Text fontSize="18px" fontWeight="400" color="rgba(0,0,0,0.5)">
                    {subheading}
                  </Text>
                </Box>
              </Box>
              {children}
            </Box>
            <Box width="100%" display="flex" justifyContent="center">
              {footing}
            </Box>
          </Box>
        </Flex>
        <Flex py="50px">
          <Text mx="15px">© {name}</Text>
          <ChakraLink href="https://www.simpu.co/legal/privacy-policy" isExternal>
            <Text mx="15px">Privacy & Terms</Text>
          </ChakraLink>
          <Link to="#">
            <Text mx="15px">Contact Us</Text>
          </Link>
        </Flex>
      </Box>
    </AuthLayoutContainer>
  );
}
