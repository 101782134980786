import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'root';
import { listOtpLogs } from './log.service';

export const fetchOtpLogs = createAsyncThunk('logs/fetchOtpLogs', async (params: any) => {
  const response = await listOtpLogs(params);
  return response;
});

export const fetchMoreOtps = createAsyncThunk('logs/fetchMoreOtp', async (params: any) => {
  const response = await listOtpLogs(params);
  return response;
});

const initialState = {
  otp_reports: [] as any,
  otp_report: {} as any,
  meta: {} as any,
  loading: 'idle' as string,
  loadMore: 'idle' as string,
};

export const otpSlice = createSlice({
  name: 'otp_slice',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchOtpLogs.pending, (state, action) => {
        state.loading = 'pending';
      })
      .addCase(fetchOtpLogs.fulfilled, (state, action) => {
        state.loading = 'complete';
        const { data, meta } = action.payload;
        state.otp_reports = data;
        state.meta = meta;
      })
      .addCase(fetchMoreOtps.pending, (state, action) => {
        state.loadMore = 'pending';
      })
      .addCase(fetchMoreOtps.fulfilled, (state, action) => {
        state.loadMore = 'complete';
        const { data, meta } = action.payload;
        state.otp_reports = [...state.otp_reports, ...data];
        state.meta = meta;
      });
  },
});

export const otpLogsReducer = otpSlice.reducer;
export const selectFromOtpLogs = (state: RootState) => state.otpLogs;
