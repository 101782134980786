import React from 'react';
import { IconButton, Modal, ModalOverlay } from '@chakra-ui/core';
import { EnrichConfirmationModalWrapper as Wrapper } from './index.styles';
import { EmptyState, useResellerSettings } from 'app/components';
import enrichData from '../../assets/enrich.svg';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export const EnrichConfirmationModal = ({ isOpen, onClose }: Props) => {
  const { name } = useResellerSettings();

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <Wrapper id="enrich-modal">
        <IconButton
          position="absolute"
          aria-label="close"
          icon="small-close"
          variant="solid"
          variantColor="blue"
          borderRadius="15px"
          size="xs"
          right="-10px"
          top="-10px"
          onClick={onClose}
        />
        <EmptyState
          image={enrichData}
          heading="Data Enrichment"
          subheading={
            <>
              {name} delivers complete, always-fresh visitor, contact, and account profiles, so
              you’re prepared to reach the right people, make great first impressions, and nurture
              relationships throughout their lifecycle.
              <br />
              <br />
              Phone enrichment costs{' '}
              <span
                style={{
                  fontWeight: 'bold',
                  marginLeft: '5px',
                  color: '#333333',
                }}
              >
                NGN 1.00{' '}
              </span>
              per row, while person or company information enrichment costs{' '}
              <span
                style={{
                  fontWeight: 'bold',
                  marginLeft: '5px',
                  color: '#333333',
                }}
              >
                NGN 50.00{' '}
              </span>
              per row.
            </>
          }
          subheadingProps={{
            textAlign: 'justify',
            color: 'rgba(51,51,51, 0.5)',
            opacity: 1,
          }}
        />
      </Wrapper>
    </Modal>
  );
};
