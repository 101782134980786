import React, { useEffect, useRef } from 'react';

export const EmailTemplatePreview = (props: { content: string }) => {
  const { content } = props;
  const emailContentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const emailContentAnchorTags = emailContentRef?.current?.getElementsByTagName('a');

    for (let i = 0; i < (emailContentAnchorTags?.length ?? 0); i++) {
      (emailContentAnchorTags ?? [])[i].setAttribute('target', '_blank');
    }
  }, []);

  return <div ref={emailContentRef} dangerouslySetInnerHTML={{ __html: content }} />;
};
