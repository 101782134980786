import React, { useEffect } from 'react';
import { Box, PseudoBox } from '@chakra-ui/core';

export const EmailCell = (props: any) => {
  useEffect(() => {
    if (
      props.search_query !== '' &&
      props.value &&
      props.value?.toString().toLowerCase().includes(props.search_query.toLowerCase())
    ) {
      props.eGridCell.style.backgroundColor = '#fff3d4';
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box style={{ display: 'flex', alignItems: 'center' }}>
      {props.value && (
        <Box display="flex" marginTop="8px">
          {String(props.value)
            .split(',')
            .map((i: string, index: number) => (
              <PseudoBox
                className="cell-action"
                style={{
                  fontSize: 12,
                  backgroundColor: '#0f0f0f1a',
                  marginRight: 5,
                  padding: '2px 5px',
                  borderRadius: 5,
                  lineHeight: 'normal',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  cursor: 'pointer',
                  transition: 'all 0.1s ease-in',
                  textDecoration: 'underline',
                }}
                _hover={{
                  backgroundColor: '#545D69 !important',
                  color: 'white',
                }}
                key={index}
                onClick={event => {
                  props.api.stopEditing();
                  window.location.href = `mailto:${i}`;
                  event.stopPropagation();
                }}
              >
                {i.replace(' ', '').toLowerCase()}
              </PseudoBox>
            ))}
        </Box>
      )}
    </Box>
  );
};
