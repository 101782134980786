import React, { forwardRef } from 'react';
import whiteLogo from './logo-white.png';
import blackLogo from './logo-black.png';
import blackLogoStripped from './logo-black-stripped.png';
import blackLogoWithTextStripped from './logo-with-text-black-stripped.png';
import blackLogoWithText from './logo-with-text-black.png';
import whiteLogoWithText from './logo-with-text-white.png';

export const LogoWithText = forwardRef(({ color = 'black', ...rest }: any, ref: any) => {
  const srcRegister: { [key: string]: any } = {
    black: blackLogoWithText,
    white: whiteLogoWithText,
  };

  return <img ref={ref} {...rest} src={srcRegister[color]} alt="Simpu logo" />;
});

export const StrippedLogoWithText = forwardRef(({ color = 'black', ...rest }: any, ref: any) => {
  const srcRegister: { [key: string]: any } = {
    black: blackLogoWithTextStripped,
    white: whiteLogoWithText,
  };

  return <img ref={ref} {...rest} src={srcRegister[color]} alt="Simpu logo" />;
});

export const StrippedLogo = forwardRef(({ color = 'white', ...rest }: any, ref: any) => {
  const srcRegister: { [key: string]: any } = {
    white: whiteLogo,
    black: blackLogoStripped,
  };
  return <img ref={ref} {...rest} src={srcRegister[color]} alt="Simpu logo" />;
});

export const Logo = forwardRef(({ color = 'white', ...rest }: any, ref: any) => {
  const srcRegister: { [key: string]: any } = {
    white: whiteLogo,
    black: blackLogo,
  };

  return <img {...rest} src={srcRegister[color]} alt="Simpu logo" />;
});
