import React from 'react';
import { Box, BoxProps, Divider, Icon, SimpleGrid, Spinner } from '@chakra-ui/core/dist';
import { ResourceType } from 'app/authenticated-app/lists/lists.types';
import { PseudoBox, Radio } from '@chakra-ui/core';
import { useResellerSettings } from 'app/components';
import styled from '@emotion/styled';

interface ButtonProps {
  icon: string;
  label: string;
  disabled?: boolean;
  loading?: boolean;

  onClick?(): void;
}

const OptionButtonBox = styled(Box)`
  &:hover {
    background-color: #fafafa;
  }
  &::before,
  &::after {
    box-sizing: inherit;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
  }
  &.draw {
    transition: color 0.25s;
  }
  &.draw::before,
  &.draw::after {
    border: 2px solid transparent;
    width: 0;
    height: 0;
  }
  &.draw::before {
    top: 0;
    left: 0;
  }
  &.draw::after {
    bottom: 0;
    right: 0;
  }
  &.draw:hover::before,
  &.draw:hover::after {
    width: 100%;
    height: 100%;
  }
  &.draw:hover::before {
    border-top-color: #026ae8;
    border-right-color: #026ae8;
    transition: width 0.25s ease-out, height 0.25s ease-out 0.25s;
  }
  &.draw:hover::after {
    border-bottom-color: #026ae8;
    border-left-color: #026ae8;
    transition: border-color 0s ease-out 0.5s, width 0.25s ease-out 0.5s,
      height 0.25s ease-out 0.75s;
  }

  &.meet::after {
    top: 0;
    left: 0;
  }
  &.meet:hover::before {
    border-top-color: #026ae8;
    border-right-color: #026ae8;
  }
  &.meet:hover::after {
    border-bottom-color: #026ae8;
    border-left-color: #026ae8;
    transition: height 0.25s ease-out, width 0.25s ease-out 0.25s;
  }
`;

const OptionButton = ({
  onClick,
  icon,
  label,
  disabled,
  loading,
  ...rest
}: ButtonProps & BoxProps) => (
  <OptionButtonBox
    p="5px 10px"
    display="flex"
    cursor="pointer"
    alignItems="center"
    position="relative"
    boxShadow="inset 0 0 0 2px #E2E8F0"
    className={`draw meet ${disabled && 'disabled'}`}
    onClick={() => {
      if (!loading && onClick) onClick();
    }}
    {...rest}
  >
    <Icon mr="5px" className="icon" name={icon} size="14px" />
    <Box fontSize="14px">{label}</Box>
    {loading && <Spinner size="xs" marginLeft="auto" />}
    {disabled && (
      <Box color="limegreen" fontSize="12px" ml="auto" textAlign="right">
        coming soon
      </Box>
    )}
  </OptionButtonBox>
);

interface Props {
  createListLoading?: boolean;
  selectImportType: Function;
  createList: Function;
  addFromTemplateLoading: string | null;
  handleAddFromTemplate: Function;
  setAccessType: Function;
  accessType: 'public' | 'private';
  customApps: never[];
}

export const CreateListOptions = (props: Props) => {
  const { name } = useResellerSettings();

  const {
    createListLoading,
    selectImportType,
    handleAddFromTemplate,
    addFromTemplateLoading,
    customApps,
    accessType,
    setAccessType,
  } = props;

  return (
    <Box>
      <Box className="section">
        <Box className="title">
          <Box className="title-text">Collaboration Access</Box>
          <Divider height="2px" orientation="horizontal" width="100%" />
        </Box>
        <Box className="description">
          Keep your list private or make it public to collaborate on a shared view of table data
          with everyone in your organisation.
        </Box>

        <Box display="flex" flexDirection="row">
          <PseudoBox
            display="flex"
            alignItems="center"
            padding="13px"
            border="1px solid #ECF0F5"
            boxShadow="0px 3px 32px rgba(236, 240, 245, 0.36)"
            borderRadius="8px"
            backgroundColor="#ffffff"
            mr="1rem"
            cursor="pointer"
            fontSize="14px"
            _hover={{
              backgroundColor: '#fafafa',
            }}
            onClick={() => setAccessType('private')}
          >
            <Radio
              isChecked={accessType === 'private'}
              size="md"
              mr="0.75rem"
              onChange={() => {}}
            />
            <Box>Private</Box>
          </PseudoBox>
          <PseudoBox
            display="flex"
            alignItems="center"
            padding="13px"
            border="1px solid #ECF0F5"
            boxShadow="0px 3px 32px rgba(236, 240, 245, 0.36)"
            borderRadius="8px"
            backgroundColor="#ffffff"
            mr="1rem"
            cursor="pointer"
            fontSize="14px"
            _hover={{
              backgroundColor: '#fafafa',
            }}
            onClick={() => setAccessType('public')}
          >
            <Radio isChecked={accessType === 'public'} size="md" mr="0.75rem" onChange={() => {}} />
            <Box>Public</Box>
          </PseudoBox>
        </Box>
      </Box>

      <Box className="section">
        <Box className="title">
          <Box className="title-text">From scratch</Box>
          <Divider height="2px" orientation="horizontal" width="100%" />
        </Box>

        <Box className="description">
          Create new blank list with custom fields and property types
        </Box>

        <OptionButton
          icon="small-add"
          label="Start from scratch"
          onClick={() => props.createList()}
          loading={createListLoading}
          width={220}
        />
      </Box>

      <Box className="section">
        <Box className="title">
          <Box className="title-text">Import data</Box>
          <Divider height="2px" orientation="horizontal" width="100%" />
        </Box>

        <Box className="description">Bring your existing data into {name}</Box>

        <SimpleGrid minChildWidth={200} spacing="25px">
          <OptionButton icon="csvFile" label="CSV file" onClick={() => selectImportType('csv')} />
          <OptionButton
            icon="ms-excel"
            label="Microsoft Excel"
            onClick={() => selectImportType('ms-excel')}
          />
          <OptionButton
            icon="ios-numbers"
            label="Apple Numbers"
            onClick={() => selectImportType('apple-numbers')}
          />
          <OptionButton
            icon="ms-access"
            label="Microsoft Access"
            onClick={() => selectImportType('csv')}
          />
          <OptionButton icon="asana" label="Asana" onClick={() => selectImportType('asana')} />
          <OptionButton icon="trello" label="Trello" onClick={() => selectImportType('trello')} />
          <OptionButton
            icon="mailchimp"
            label="Mailchimp"
            onClick={() => selectImportType('csv')}
          />
          <OptionButton
            icon="g-sheets"
            label="Google Sheets"
            onClick={() => selectImportType('google-sheets')}
          />
          <OptionButton icon="mysql" label="MySQL" onClick={() => selectImportType('mysql')} />
          <OptionButton
            icon="postgreSql"
            label="PostgreSQL"
            onClick={() => selectImportType('pgsql')}
          />
          <OptionButton
            icon="mssql"
            label="Microsoft SQL Server"
            onClick={() => selectImportType('mssql')}
          />
          <OptionButton
            icon="woo-commerce"
            label="WooCommerce"
            onClick={() => selectImportType('woo-commerce')}
          />
          <OptionButton
            icon="shopify"
            label="Shopify"
            onClick={() => selectImportType('shopify')}
          />
          <OptionButton icon="mambu" label="Mambu" onClick={() => selectImportType('mambu')} />
          <OptionButton
            icon="magento"
            label="Magento"
            onClick={() => selectImportType('magento')}
          />
          <OptionButton
            icon="typeform"
            label="Typeform"
            onClick={() => selectImportType('typeform')}
          />
          <OptionButton
            icon="hubspot"
            label="Hubspot"
            onClick={() => selectImportType('hubspot')}
          />
          {/* <OptionButton
            icon="list-api"
            label="Custom Integration"
            onClick={() => selectImportType('custom')}
          /> */}
          <OptionButton icon="ios-contacts" label="Contacts" disabled />
          <OptionButton icon="calendar2" label="Calendar" disabled />
        </SimpleGrid>
      </Box>

      <Box className="section">
        <Box className="title">
          <Box className="title-text" width="35% important">
            Use a template
          </Box>
          <Divider height="2px" orientation="horizontal" width="100%" />
        </Box>

        <Box className="description">Select a template to get started and customize as you go.</Box>

        <SimpleGrid minChildWidth={200} spacing="25px">
          <OptionButton
            icon="phone"
            label="Contact list"
            onClick={() => handleAddFromTemplate('contact_template')}
            loading={addFromTemplateLoading === 'contact_template'}
          />
          <OptionButton
            icon="at-sign"
            label="Sales CRM"
            onClick={() => handleAddFromTemplate('sales_crm')}
            loading={addFromTemplateLoading === 'sales_crm'}
          />
          <OptionButton
            icon="chat"
            label="Personal CRM"
            onClick={() => handleAddFromTemplate('personal_crm')}
            loading={addFromTemplateLoading === 'personal_crm'}
          />
          <OptionButton
            icon="dollar"
            label="Expense tracker"
            onClick={() => handleAddFromTemplate('expense_tracker')}
            loading={addFromTemplateLoading === 'expense_tracker'}
          />
          <OptionButton
            icon="hamburger"
            label="Product Inventory"
            onClick={() => handleAddFromTemplate('product_inventory')}
            loading={addFromTemplateLoading === 'product_inventory'}
          />
        </SimpleGrid>
      </Box>

      {customApps.length > 0 && (
        <Box className="section">
          <Box className="title">
            <Box className="title-text" width="35% important" whiteSpace="nowrap">
              Custom Apps
            </Box>
            <Divider height="2px" orientation="horizontal" width="100%" />
          </Box>

          <Box className="description">Import data from your custom integrations.</Box>

          <SimpleGrid columns={3} spacing="25px">
            {customApps
              .filter((i: ResourceType) => i.is_custom)
              .map((i: ResourceType) => (
                <OptionButton
                  icon={i.icon}
                  label={i.label}
                  key={i.key}
                  onClick={() => selectImportType(i.key)}
                />
              ))}
          </SimpleGrid>
        </Box>
      )}
    </Box>
  );
};
