import React, { ReactNode } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalSizes,
  IModal,
  BoxProps,
  ModalContentProps,
} from '@chakra-ui/core';

export interface ModalContainerOptions {
  title?: ReactNode;
  isOpen?: boolean;
  size?: ModalSizes;
  onOpen?: () => void;
  onClose?: () => void;
  showCloseButton?: boolean;
  children?: React.ReactNode;
  titleStyleProps?: BoxProps;
  modalOverlayProps?: BoxProps;
  modalContentProps?: Omit<ModalContentProps, 'children'>;
}

export function ModalContainer({
  title,
  isOpen,
  onClose,
  children,
  size = 'lg',
  showCloseButton,
  initialFocusRef,
  titleStyleProps,
  isCentered = true,
  modalContentProps,
  modalOverlayProps,
  ...rest
}: ModalContainerOptions & IModal) {
  return (
    <>
      <Modal
        size={size}
        isOpen={isOpen}
        onClose={onClose}
        isCentered={isCentered}
        initialFocusRef={initialFocusRef}
        {...rest}
      >
        <ModalOverlay {...modalOverlayProps} />
        <ModalContent marginY="auto" borderRadius="16px" {...modalContentProps}>
          {title && <ModalHeader {...titleStyleProps}>{title}</ModalHeader>}
          {showCloseButton && <ModalCloseButton size="sm" />}
          {children}
        </ModalContent>
      </Modal>
    </>
  );
}
