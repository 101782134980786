import { Box, Spinner, useDisclosure, useToast } from '@chakra-ui/core/dist';
import { ContentWrapper, ToastBox } from 'app/components';
import { useWindowDimensions } from 'hooks';
import { Channel } from 'pusher-js';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { pusher } from 'utils/pusher';
import { ListQuickActivityModal } from '../../components/quick-activity-panel';
import { setListImportStatus } from '../../lists.reducer';
import { selectAutomationPanelOpen } from '../../lists.selectors';
import { selectListImportStatus } from '../../lists.slice';
import { List, SmartList } from '../../lists.types';
import { ListViewStyles as Wrapper } from './component.styles';
import { ListBody, ListHeading, ListViewPanel } from './components';
import { ListSidePanel } from './components/ListSidePanel';

export const Component = (props: any) => {
  const {
    selected_list,
    rows_loading,
    lists_fetched,
    selectList,
    fetchRows,
    lists,
    rows,
    columns,
    rows_by_id,
    columns_by_id,
    column_id_map,
    meta,
    updateColumnArrangement,
    updateRowCell,
    list_syncing,
    createColumn,
    createRow,
    selectRows,
    clearRows,
    deleteRows,
    updateListName,
    updateList,
    deleteColumn,
    hideColumn,
    showColumn,
    updateColumnLabel,
    updateColumnType,
    addFilter,
    filters,
    filters_by_id,
    updateFilter,
    deleteFilter,
    sorts,
    sorts_by_id,
    addSort,
    deleteSort,
    updateSort,
    deleteList,
    addSelectOption,
    getRowByIndex,
    updateSelectOption,
    selected_rows,
    updateColumnCustomization,
    updateColumn,
    createSmartList,
    smart_lists,
    is_smart_list,
    selected_smart_list,
    smart_lists_fetched,
    selectSmartList,
    updateSmartList,
    deleteSmartList,
    selectSmartListID,
    updateSmartListFilters,
    clearFilters,
    search_query,
    updateSearchQuery,
    initial_list,
    openNoSubscriptionModal,
    enrichColumnData,
    views,
    createView,
    updateForm,
    updateView,
    deleteView,
    updateEnrichmentSettings,
    queueEnrichment,
    createAutomation,
    updateAutomation,
    deleteAutomation,
    fetchAutomationActions,
    addAutomationAction,
    updateAutomationAction,
    deleteAutomationAction,
    toggleAutomationPanel,
    reloadSelectedList,
  } = props;

  const [activeView, setActiveView] = useState('grid');
  const [subscribed, setSubscribed] = useState<boolean>(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!is_smart_list) {
      const {
        match: { params },
        history,
      } = props;
      const list_id: string = params.id;
      if (!props.lists[list_id] && lists_fetched) history.push('/s/lists');
      else if (props.lists[list_id]) selectList(list_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (is_smart_list) {
      const {
        match: { params },
        history,
      } = props;
      const smart_list_id: string = params.id;
      if (!props.smart_lists[smart_list_id] && smart_lists_fetched) history.push('/s/lists');
      else if (props.smart_lists[smart_list_id]) selectSmartList(smart_list_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    attachDisableScrollListener();
    return detachDisableScrollListener;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const attachDisableScrollListener = () => {
    // @ts-ignore
    document.body.style.overscrollBehaviorX = 'none';
  };

  const detachDisableScrollListener = () => {
    // @ts-ignore
    document.body.style.overscrollBehaviorX = 'initial';
  };

  const {
    isOpen: clearRowsDialogOpen,
    onClose: closeClearRowsDialog,
    onOpen: openClearRowsDialog,
  } = useDisclosure();

  const {
    isOpen: deleteRowsDialogOpen,
    onClose: closeDeleteRowsDialog,
    onOpen: openDeleteRowsDialog,
  } = useDisclosure();

  const {
    isOpen: viewPanelOpen,
    onClose: closeViewPanel,
    onToggle: toggleViewPanel,
  } = useDisclosure();

  const {
    onOpen: openQuickActionPanel,
    isOpen: quickActionPanelOpen,
    onClose: closeQuickActionPanel,
  } = useDisclosure();

  const listImportStatus: string | undefined = useSelector(selectListImportStatus);
  const automationPanelOpen: boolean = useSelector(selectAutomationPanelOpen);

  console.log(automationPanelOpen);

  const { width: windowWidth } = useWindowDimensions();

  const togglePanelBehaviour = windowWidth < 2400;

  const toast = useToast();

  const handleClearRows = () => {
    props.clearRows();
    toast({
      position: 'bottom-left',
      render: ({ onClose }) => (
        <ToastBox onClose={onClose} status="success" message="Rows cleared successfully" />
      ),
    });
    closeClearRowsDialog();
  };

  const handleDeleteRows = () => {
    props.deleteRows();
    toast({
      position: 'bottom-left',
      render: ({ onClose }) => (
        <ToastBox onClose={onClose} status="success" message="Rows deleted successfully" />
      ),
    });
    closeDeleteRowsDialog();
  };

  const list: List = lists[selected_list];
  const smart_list: SmartList = smart_lists[selected_smart_list];

  const subscribeToChannel = () => {
    const listChannelName: string = `private-list-${list?.id}`;
    const listChannel: Channel = pusher.subscribe(listChannelName);
    setSubscribed(true);

    listChannel.bind('row-create', (data: any) => {
      props.handleRowInsertEvent(data);
    });
    listChannel.bind('row-update', (data: any) => {
      props.handleRowUpdateEvent(data);
    });
    listChannel.bind('row-delete', (data: any) => {
      props.handleRowDeleteEvent(data);
    });
  };

  const unsubscribeFromChannel = () => {
    const listChannelName: string = `private-list-${list?.id}`;
    pusher.unsubscribe(listChannelName);
  };

  useEffect(() => {
    if (listImportStatus && listImportStatus === 'DONE') {
      dispatch(setListImportStatus(undefined));
      toast({
        position: 'bottom-left',
        render: ({ onClose }) => (
          <ToastBox onClose={onClose} status="success" message="List import completed" />
        ),
      });
      openQuickActionPanel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listImportStatus]);

  useEffect(() => {
    if (automationPanelOpen && viewPanelOpen && togglePanelBehaviour) {
      closeViewPanel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [automationPanelOpen]);

  useEffect(() => {
    if (pusher && (selected_list || selected_smart_list) && !subscribed) {
      subscribeToChannel();
      return unsubscribeFromChannel;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pusher, selected_list, selected_smart_list]);

  if (!selected_list && !selected_smart_list) {
    return (
      <ContentWrapper paddingBottom="8rem">
        <Box display="flex" height="100vh">
          <Spinner margin="auto" />
        </Box>
      </ContentWrapper>
    );
  }

  const handleQuickAction = (action: 'duplicate' | 'enrichment' | 'automation' | 'form') => {
    closeQuickActionPanel();
    switch (action) {
      case 'automation':
      case 'enrichment':
        toggleAutomationPanel({ automationPanelOpen: true });
        break;
      case 'form':
        toggleViewPanel();
    }
  };

  return (
    <>
      <ContentWrapper
        style={{
          overscrollBehaviorX: 'none',
          backgroundColor: '#09101d',
          overflow: 'hidden',
        }}
      >
        <Box
          display="flex"
          flexDirection="row"
          backgroundColor="#09101d"
          height="100%"
          // backgroundColor="#026ae8"
          width="100vw"
          justifyContent="space-between"
        >
          <Wrapper
            width={!automationPanelOpen ? '100%' : 'calc(100vw - 600px)'}
            borderTopRightRadius={!automationPanelOpen ? '0px' : '10px'}
            height="100%"
            flexDirection="column"
            display="flex"
          >
            <ListHeading
              {...{
                columns,
                columns_by_id,
                rows_loading,
                list,
                smart_list,
                updateColumnArrangement,
                list_syncing,
                history: props.history,
                updateListName,
                updateList,
                hideColumn,
                showColumn,
                addFilter,
                filters,
                filters_by_id,
                updateFilter,
                deleteFilter,
                sorts,
                sorts_by_id,
                addSort,
                deleteSort,
                updateSort,
                deleteList,
                createSmartList,
                updateSmartList,
                deleteSmartList,
                selectSmartList,
                selectSmartListID,
                updateSmartListFilters,
                clearFilters,
                is_smart_list,
                search_query,
                updateSearchQuery,
                initial_list,
                selected_rows,
                openClearRowsDialog,
                openDeleteRowsDialog,
                toggleViewPanel: () => {
                  if (automationPanelOpen && !viewPanelOpen && togglePanelBehaviour) {
                    toggleAutomationPanel({ automationPanelOpen: false });
                  }
                  toggleViewPanel();
                },
                viewPanelOpen,
                activeView,
                deleteView,
                reloadSelectedList,
              }}
            />
            <Box display="flex" flex="1" className="list-content">
              <ListViewPanel
                isPanelOpen={viewPanelOpen}
                {...{ activeView, setActiveView, views, createView, updateView, deleteView }}
              />
              <ListBody
                {...{
                  activeView,
                  setActiveView,
                  meta,
                  rows,
                  columns,
                  rows_by_id,
                  columns_by_id,
                  column_id_map,
                  rows_loading,
                  fetchRows,
                  updateRowCell,
                  list,
                  updateColumnArrangement,
                  createColumn,
                  createRow,
                  selectRows,
                  clearRows,
                  deleteRows,
                  deleteColumn,
                  hideColumn,
                  updateColumnLabel,
                  updateColumnType,
                  addSelectOption,
                  getRowByIndex,
                  updateSelectOption,
                  selected_rows,
                  updateColumnCustomization,
                  updateColumn,
                  is_smart_list,
                  search_query,
                  filters,
                  filters_by_id,
                  sorts,
                  sorts_by_id,
                  openNoSubscriptionModal,
                  enrichColumnData,
                  clearRowsDialogOpen,
                  closeClearRowsDialog,
                  handleClearRows,
                  deleteRowsDialogOpen,
                  closeDeleteRowsDialog,
                  handleDeleteRows,
                  updateForm,
                  createAutomation,
                }}
              />
            </Box>
          </Wrapper>
          {automationPanelOpen && (
            <ListSidePanel
              is_smart_list={is_smart_list}
              isOpen={automationPanelOpen}
              updateEnrichmentSettings={updateEnrichmentSettings}
              queueEnrichment={queueEnrichment}
              createAutomation={createAutomation}
              updateAutomation={updateAutomation}
              fetchAutomationActions={fetchAutomationActions}
              addAutomationAction={addAutomationAction}
              updateAutomationAction={updateAutomationAction}
              deleteAutomation={deleteAutomation}
              deleteAutomationAction={deleteAutomationAction}
            />
          )}
          {quickActionPanelOpen && (
            <ListQuickActivityModal
              isOpen={quickActionPanelOpen}
              onClose={closeQuickActionPanel}
              handleQuickAction={handleQuickAction}
            />
          )}
        </Box>
      </ContentWrapper>
    </>
  );
};
