import { Box, Checkbox, CloseButton, Grid } from '@chakra-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import {
  AppHeadersContext,
  DropDownOptionsProps,
} from '../../../contexts/app-contexts/headers.context';

const Params = () => {
  const [showCloseIcon, setShowCloseIcon] = useState(0);
  const [focusState, setFocusState] = useState(false);

  const {
    paramsState,
    setParamsState,
    rowId,
    setRowId,
    currentCell,
    setCurrentCell,
    createNewList,
    setText,
    cursorPosition,
    setCursorPosition,
    focusRef,
    urlParser,
    setDropDown,
    dropDown,
    dropDownOptions,
  } = useContext(AppHeadersContext);
  const [dropDownRightPanel, setDropDownRightPanel] = useState(dropDownOptions[0].explanation);
  const changeHandler = (
    e: any,
    props: any,
    textValue: any,
    columnName: any,
    columnNumber: any,
  ) => {
    setCursorPosition([e.currentTarget.selectionStart, e.currentTarget.selectionEnd]);
    setText(e, props.row?.id, textValue, columnName);
    setCurrentCell([props.row.id, columnNumber, 'changeEvent']);
    if (!props.row.isWritten) {
      createNewList(props.row);
    }
    urlParser();
  };

  const keyEventHandler = (e: any, props: any, column: any) => {
    setCursorPosition([e.currentTarget.selectionStart, e.currentTarget.selectionEnd]);

    let isCellStart = e.currentTarget.selectionStart === 0 ? true : false;
    let isCellEnd = e.currentTarget.selectionStart === e.currentTarget.value.length ? true : false;

    if (e.key === 'ArrowLeft' && column > 1 && isCellStart) {
      // Move left
      setCurrentCell([props.row.id, column - 1, 'cellChanger']);
    } else if (e.key === 'ArrowLeft' && column < 2 && props.row.id > 0 && isCellStart) {
      // Move to previous row
      setCurrentCell([props.row.id - 1, 3, 'cellChanger']);
    } else if (e.key === 'ArrowRight' && column < 3 && isCellEnd) {
      // Move Right
      setCurrentCell([props.row.id, column + 1, 'cellChanger']);
    } else if (
      e.key === 'ArrowRight' &&
      column > 2 &&
      props.row.id < paramsState.length - 1 &&
      isCellEnd
    ) {
      // Move to next row
      setCurrentCell([props.row.id + 1, 1, 'cellChanger']);
    } else if (e.key === 'ArrowUp' && props.row.id > 0) {
      // Move up
      setCurrentCell([props.row.id - 1, column, 'cellChanger']);
    } else if (e.key === 'ArrowDown' && props.row.id < paramsState.length - 1) {
      // Move down
      setCurrentCell([props.row.id + 1, column, 'cellChanger']);
    } else if (e.key === '{' && props.row.id < paramsState.length - 1) {
      // Drop down menu open
      setDropDown([true, props.row.id, column]);
    } else if (e.key === '}' && props.row.id < paramsState.length - 1) {
      // Drop down menu close
      setDropDown([false, props.row.id, column]);
    } else if (e.key === 'Backspace' && props.row.id < paramsState.length - 1) {
      // Drop down menu close
      if (e.target.value.at(-1) === '{') {
        setDropDown([false, props.row.id, column]);
      } else if (e.target.value.at(-1) === '}') {
        setDropDown([true, props.row.id, column]);
      }
    } else {
      setCurrentCell([props.row.id, column, 'sameCell']);
    }
  };

  const cellFocus = (props: any, column: any) => {
    if (currentCell[0] === props.row.id && currentCell[1] === column && focusState === true) {
      return true;
    } else {
      return false;
    }
  };

  const clickEventHandler = (e: any, props: any, column: any) => {
    setCursorPosition([e.currentTarget.selectionStart, e.currentTarget.selectionEnd]);
    setCurrentCell([props.row.id, column, 'clickEvent']);
    setDropDown([false, dropDown[1], dropDown[2]]);

    if (
      currentCell[0] === dropDown[1] &&
      currentCell[1] === dropDown[2] &&
      focusState === true &&
      e.target.value.includes('{') &&
      !e.target.value.includes('}')
    ) {
      setDropDown([!dropDown[0], props.row.id, column]);
    }
  };

  const focusEventHandler = (e: any, props: any, column: any) => {
    if (
      currentCell[2] === 'clickEvent' &&
      currentCell[1] === column &&
      currentCell[0] === props.row.id
    ) {
      e.currentTarget.setSelectionRange(cursorPosition[0], cursorPosition[1]);
    } else if (
      currentCell[2] === 'changeEvent' &&
      currentCell[1] === column &&
      currentCell[0] === props.row.id
    ) {
      e.currentTarget.setSelectionRange(cursorPosition[0], cursorPosition[1]);
    } else if (
      currentCell[2] === 'sameCell' &&
      currentCell[1] === column &&
      currentCell[0] === props.row.id
    ) {
      e.currentTarget.setSelectionRange(cursorPosition[0], cursorPosition[1]);
    } else if (currentCell[2] === 'cellChanger') {
      e.currentTarget.setSelectionRange(e.currentTarget.value.length, e.currentTarget.value.length);
    }
  };

  const closeButtonHandler = (props: any) => {
    paramsState.splice(props.row.id, 1);
    paramsState.forEach((item, index, array) => {
      if (index >= props.row.id) {
        array[index] = { ...item, id: item.id - 1 };
      }
    });
    setParamsState(paramsState);
    setRowId(rowId - 1);
    urlParser();
    setDropDown([false, props.row.id, 2]);
  };

  const mouseOverEvent = (e: any, props: any) => {
    setShowCloseIcon(props.row.id);
  };

  const mouseLeaveEvent = (e: any, props: any) => {
    setShowCloseIcon(paramsState.length - 1);
  };

  function useOutsideAlerter(ref: any) {
    useEffect(() => {
      function handleClickOutside(e: any) {
        if (ref.current && !ref.current.contains(e.target)) {
          setFocusState(false);
        } else if (ref.current && ref.current.contains(e.target)) setFocusState(true);
      }
      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  }

  const checkBoxchangeHandler = (props: any) => {
    props.row.isDisabled = !props.row.isDisabled;
    urlParser();
  };

  const dropDownClickHandler = (rowId: number, dropDownText: string) => {
    paramsState[rowId]['value_text'] = paramsState[rowId]['value_text'] + dropDownText;
    setParamsState(paramsState);
    setCursorPosition([
      cursorPosition[0] + dropDownText.length,
      cursorPosition[1] + dropDownText.length,
    ]);
    urlParser();
  };

  const Row = (props: any) => {
    return (
      <tr
        className="custom-app--params-rows"
        onMouseOver={e => mouseOverEvent(e, props)}
        onMouseLeave={e => mouseLeaveEvent(e, props)}
      >
        <td id="custom-app--params-checkbox">
          {props.row.isWritten && (
            <Checkbox
              defaultIsChecked={!props.row.isDisabled}
              onChange={() => checkBoxchangeHandler(props)}
              name="name"
              id="custom-app--params-checkbox-input"
            ></Checkbox>
          )}
        </td>
        <td>
          <input
            type="text"
            name="name"
            placeholder={props.row.isWritten ? '' : 'Key'}
            autoFocus={cellFocus(props, 1)}
            value={props.row.key_text}
            onChange={(e: any) => changeHandler(e, props, e.target.value, 'key_text', 1)}
            onClick={(e: any) => clickEventHandler(e, props, 1)}
            onFocus={(e: any) => focusEventHandler(e, props, 1)}
            onKeyDown={(e: any) => {
              keyEventHandler(e, props, 1);
            }}
            autoComplete="off"
          />
        </td>

        <td className="custom-app--params-value-row">
          <input
            type="text"
            name="name"
            placeholder={props.row.isWritten ? '' : 'Value'}
            autoFocus={cellFocus(props, 2)}
            value={props.row.value_text}
            onChange={e => changeHandler(e, props, e.target.value, 'value_text', 2)}
            onClick={e => clickEventHandler(e, props, 2)}
            onFocus={e => focusEventHandler(e, props, 2)}
            onKeyDown={e => {
              keyEventHandler(e, props, 2);
            }}
            className="custom-app--params-value-input"
            autoComplete="off"
          />
          {dropDown[0] &&
            dropDown[1] === props.row.id &&
            dropDown[2] === 2 &&
            cellFocus(props, 2) && (
              <DropDownMenu
                dropDownOptions={dropDownOptions}
                inputValue={props.row.description_text}
                rowId={props.row.id}
              />
            )}
        </td>
        <td id="custom-app--row-right">
          <input
            type="text"
            name="name"
            placeholder={props.row.isWritten ? '' : 'Description'}
            autoFocus={cellFocus(props, 3)}
            value={props.row.description_text}
            onChange={e => changeHandler(e, props, e.target.value, 'description_text', 3)}
            onClick={e => clickEventHandler(e, props, 3)}
            onFocus={e => focusEventHandler(e, props, 3)}
            onKeyDown={e => {
              keyEventHandler(e, props, 3);
            }}
            autoComplete="off"
          />
          {paramsState.length - 1 !== props.row.id &&
            showCloseIcon === props.row.id &&
            !cellFocus(props, 3) && (
              <CloseButton
                className="custom-app--params-close-button"
                size="sm"
                onClick={() => closeButtonHandler(props)}
              />
            )}
        </td>
      </tr>
    );
  };

  useOutsideAlerter(focusRef);

  const DropDownMenu = (props: any) => {
    return (
      <Grid templateColumns="repeat(1, 1fr 1fr)" gap={1} className="custom-app--params-dropdown">
        <Box>
          {props.dropDownOptions.map((item: DropDownOptionsProps) => (
            <Box
              className="custom-app--params-dropdown-item"
              key={item.id}
              onMouseOver={() => setDropDownRightPanel(item.explanation)}
              onClick={() => dropDownClickHandler(props.rowId, item.value)}
            >
              {item.value}
            </Box>
          ))}
        </Box>

        <Box className="custom-app--params-dropdown-aside">{dropDownRightPanel}</Box>
      </Grid>
    );
  };

  return (
    <Box className="custom-app--params" ref={focusRef}>
      <table className="custom-app--params-tables">
        <thead>
          <tr className="custom-app-table-header">
            <th className="custom-app-row-header"></th>
            <th className="custom-app-row-header">KEY</th>
            <th className="custom-app-row-header">VALUE</th>
            <th className="custom-app-row-header custom-app--header-right">DESCRIPTION</th>
          </tr>
        </thead>

        <tbody className="custom-app--params-table-body">
          {paramsState.map(row => (
            <Row key={row.id} row={row} />
          ))}
        </tbody>
      </table>
    </Box>
  );
};

export default Params;
