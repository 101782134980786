import React, { useEffect, useState } from 'react';
import { Box, IconButton, Image, useToast } from '@chakra-ui/core/dist';
import { Button, ToastBox } from 'app/components';

export const AttachmentFieldComponent = (props: any) => {
  const [dummyValue, setDummyValue] = useState<any[]>([]);
  const toast = useToast();

  const { column, isDisabled = false } = props;

  const handleUpload = async (error: any, result: any) => {
    if (result && result?.event !== 'success') return;
    if (error) {
      return toast({
        position: 'bottom-left',
        render: ({ onClose }) => (
          <ToastBox
            status="error"
            message="Unable to complete upload, please try again"
            onClose={onClose}
          />
        ),
      });
    }

    const {
      thumbnail_url,
      height,
      width,
      secure_url,
      bytes,
      format,
      original_filename,
      public_id,
      resource_type,
    } = result.info;

    const image = {
      height,
      width,
      id: public_id,
      url: secure_url,
      thumbnail: thumbnail_url,
      size: bytes,
      filename: `${original_filename}${format ? `.${format}` : ''}`,
      type: `${resource_type}${format ? `/${format}` : ''}`,
    };

    let payload;

    if (Array.isArray(dummyValue)) {
      payload = [...dummyValue, image];
    } else payload = [image];

    setDummyValue(payload);
    props.updateCellValue(payload, column.uid);
  };

  const deleteAttachment = async (attachmentIndex: number) => {
    const updatedValue = dummyValue.filter((i: any, index: number) => index !== attachmentIndex);
    setDummyValue(updatedValue);
    props.updateCellValue(updatedValue, column.uid);

    return toast({
      position: 'bottom-left',
      render: ({ onClose }) => (
        <ToastBox status="success" message="Attachment removed successfully!" onClose={onClose} />
      ),
    });
  };

  const openWidget = () => {
    // @ts-ignore
    const widget = window.cloudinary.createUploadWidget(
      {
        cloudName: `simpu-inc`,
        uploadPreset: `attachment-upload`,
        sources: ['local', 'url', 'camera', 'dropbox', 'google_drive', 'instagram'],
        multiple: false,
        showPoweredBy: false,
        singleUploadAutoClose: true,
        styles: {
          palette: {
            window: '#FFF',
            windowBorder: '#90A0B3',
            tabIcon: '#0E2F5A',
            menuIcons: '#5A616A',
            textDark: '#000000',
            textLight: '#FFFFFF',
            link: '#026ae8',
            action: '#FF620C',
            inactiveTabIcon: '#0E2F5A',
            error: '#F44235',
            inProgress: '#026ae8',
            complete: '#20B832',
            sourceBg: '#E4EBF1',
          },
          fonts: {
            "'DM Sans', calibri": 'https://fonts.googleapis.com/css?family=DM+Sans',
          },
        },
      },
      handleUpload,
    );
    widget.open();
  };

  useEffect(() => {
    setDummyValue(props.value || []);
  }, [props.value]);

  return (
    <Box display="flex" flexWrap="wrap" flexDirection="column">
      {!isDisabled && (
        <Box flex={1} mb="0.5rem">
          <Button
            variant="solid"
            size="xs"
            onClick={openWidget}
            borderRadius="5px"
            leftIcon="attachment"
            aria-label="add"
            alignSelf="center"
          >
            Add Attachment
          </Button>
        </Box>
      )}

      <Box display="flex" flexWrap="wrap" flexDirection="row" alignItems="center">
        {Array.isArray(dummyValue) && (
          <>
            {dummyValue.map((i: any, index: number) => (
              <Box
                key={index}
                marginRight="5px"
                cursor="pointer"
                onClick={() => window.open(i?.url, '_blank')}
                title={i.filename}
                display="flex"
                flexDirection="row"
              >
                {i?.thumbnail ? (
                  <Image
                    className="file-attachment"
                    style={{
                      height: 150,
                      width: 100,
                      borderRadius: 8,
                      padding: 3,
                      objectFit: 'cover',
                    }}
                    src={i.thumbnail.replace('h_60', 'h_150').replace('w_90', 'w_100')}
                    alt={i?.filename}
                  />
                ) : (
                  <Image
                    className="file-attachment"
                    style={{
                      height: 50,
                      width: 30,
                      borderRadius: 8,
                      padding: 3,
                      objectFit: 'contain',
                    }}
                    src={require('../../../assets/unknown-file.svg')}
                    alt={i.filename}
                  />
                )}
                {!isDisabled && (
                  <IconButton
                    className="file-attachment-close-button"
                    aria-label="close"
                    icon="small-close"
                    size="xs"
                    height="16px"
                    minWidth="16px"
                    onClick={(e: any) => {
                      e.stopPropagation();
                      deleteAttachment(index);
                    }}
                  />
                )}
              </Box>
            ))}
          </>
        )}
      </Box>
    </Box>
  );
};
