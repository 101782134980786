import { createSelector, createSlice } from '@reduxjs/toolkit';
import { GlobalsState } from './globals.types';
import { RootState } from 'root';

const initialState = {
  createTableModalIsOpen: false,
  noSubscriptionModalIsOpen: false,
  noSubscriptionModalHeading: '',
  noSubscriptionModalSubHeading: '',
  sessionExpiryFlag: false,
} as GlobalsState;

const globalSlice = createSlice({
  initialState,
  name: 'global',
  reducers: {
    openCreateTableModal(state) {
      state.createTableModalIsOpen = true;
    },
    closeCreateTableModal(state) {
      state.createTableModalIsOpen = false;
    },
    openNoSubscriptionModal(state, action) {
      const { heading, subHeading } = action.payload;
      state.noSubscriptionModalIsOpen = true;
      state.noSubscriptionModalHeading = heading;
      state.noSubscriptionModalSubHeading = subHeading;
    },
    closeNoSubscriptionModal(state) {
      state.noSubscriptionModalIsOpen = false;
      state.noSubscriptionModalHeading = '';
      state.noSubscriptionModalSubHeading = '';
    },
    setSessionExpiryFlag(state, action) {
      state.sessionExpiryFlag = action.payload;
    },
  },
});

export const selectSessionExpiryFlag = createSelector(
  (state: RootState) => state.globals,
  globals => globals.sessionExpiryFlag,
);

export const {
  openCreateTableModal,
  closeCreateTableModal,
  openNoSubscriptionModal,
  closeNoSubscriptionModal,
  setSessionExpiryFlag,
} = globalSlice.actions;

export const globalsReducer = globalSlice.reducer;
