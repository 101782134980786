import { useHistory } from 'react-router';

export const useUpgrageActions = () => {
  const history = useHistory();

  const handleTalkToSales = () => {
    window.location.href = 'mailto:user@example.com?subject=Enterprise%20Upgrade';
  };

  const handleUpgrade = () => {
    history.push('/s/settings/organization/billing/upgrade');
  };
  return { handleTalkToSales, handleUpgrade };
};
