import { Box, Flex, Icon, PseudoBox, Stack, Text, Tooltip } from '@chakra-ui/core';
import { BodyText, TableDropdown } from 'app/components';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'root';
import usePermission from 'utils/usePermission';
import { getZonedTime } from '../../../../../utils';
import { PhoneContainer } from '../../campaigns';
import { TemplateData } from '../templates.types';
import { html2Text } from '../templates.utils';

type Props = {
  tableActions: any[];
  template: TemplateData;
  showActionBtns?: boolean;
  hasActiveTable?: boolean;
  onClick?: (data: TemplateData) => void;
};

export const TemplateListItem = (props: Props) => {
  const { template, tableActions, onClick, hasActiveTable = true, showActionBtns = true } = props;

  const handleClick = (e: any) => {
    e.preventDefault();
    onClick?.(template);
  };

  const { profile } = useSelector((state: RootState) => state.auth);
  const permissions = profile && profile.permissions;
  const requiresPermission = usePermission('template.*', permissions || []);

  return (
    <Stack spacing="6">
      <Box>
        <PhoneContainer sender_id="">
          <Flex
            flex={1}
            overflowY="auto"
            flexDirection="column"
            maxH="calc(100% - 25px)"
            justifyContent="space-between"
          >
            <Box
              p="0.5rem"
              width="100%"
              bg="#f3f3f3"
              overflowY="auto"
              borderRadius="8px"
              wordBreak="break-word"
              whiteSpace="pre-line"
            >
              <Text fontSize="xs" color="color.700">
                {html2Text(template.template)}
              </Text>
            </Box>
          </Flex>
        </PhoneContainer>
      </Box>
      <Flex alignItems="center" justifyContent="space-between">
        <Stack spacing={1}>
          <Stack isInline alignItems="center">
            <PseudoBox
              as="h1"
              fontSize="sm"
              color="blue.500"
              fontWeight="bold"
              textTransform="capitalize"
              _hover={{
                textDecoration: 'underline',
              }}
              onClick={requiresPermission ? handleClick : undefined}
              cursor={onClick && requiresPermission ? 'pointer' : 'default'}
            >
              {template.name}
            </PseudoBox>
            {!hasActiveTable && (
              <Tooltip
                zIndex={10000}
                placement="right"
                aria-label="Template item info"
                label="The list associated to this template has been deleted."
              >
                <Icon size="0.75rem" name="info" color="gray.500" />
              </Tooltip>
            )}
          </Stack>
          <BodyText fontSize="xs" color="gray.500">
            {template.created_datetime &&
              getZonedTime(template.created_datetime, 'dd MMM yyyy, hh:mm:a')}
          </BodyText>
        </Stack>
        {showActionBtns && requiresPermission && (
          <TableDropdown<TemplateData> data={template} actions={tableActions} />
        )}
      </Flex>
    </Stack>
  );
};
