import { AxiosRequestConfig } from 'axios';
import {
  buildAIURL,
  buildConversationUrl,
  buildGraphURL,
  buildPaymentURL,
  buildReportsURL,
  client,
} from '../../../../utils';
import {
  CampaignData,
  CampaignStates,
  CampaignTypes,
  EmailCampaignDesign,
  EmailCampaignDesignPayload,
  InboxType,
  MarketingReportSchema,
  MarketingSettingsSchema,
} from './campaigns.types';

export const CAMPAIGN_LIST_LIMIT = 15;
export const CAMPAIGN_SMS_AMOUNT = 2;

export const listCampaigns = (params?: any) => {
  return client('campaign', { params });
};

export const createCampaignItem = (payload: Partial<CampaignData>) => {
  return client('campaign/create', { method: 'POST', data: payload });
};

export const updateCampaignItem = (payload: CampaignData) => {
  return client('campaign/update', { method: 'PATCH', data: payload });
};

export const fetchCampaignItem = (id: CampaignData['id']) => {
  return client(`campaign/${id}`, { method: 'GET' });
};

export const fetchCampaignReport = (id: CampaignData['id']) => {
  return client(`campaign/report/${id}`, { method: 'GET' });
};

export const updateCampaignAudienceItem = (payload: CampaignData) => {
  return client('campaign/save-audience', { data: payload, method: 'PATCH' });
};

export const updateCampaignContentItem = (payload: CampaignData) => {
  return client('campaign/save-content', { data: payload, method: 'PATCH' });
};

export const updateCampaignBudgetItem = (payload: {
  id: CampaignData['id'];
  budget: CampaignData['budget'];
}) => {
  return client('campaign/save-budget', { data: payload, method: 'PATCH' });
};

export const getCampaignBudgetItem = (id: CampaignData['id']) => {
  return client(`campaign/${id}/budget/`);
};

export const updateCampaignScheduleItem = (payload: CampaignData) => {
  return client('campaign/save-schedule', { data: payload, method: 'PATCH' });
};

export const importBulkItem = (payload: FormData) => {
  return client('campaign/save-bulk', { data: payload, method: 'POST' });
};

export const deleteCampaignItem = (payload: { id: CampaignData['id'] }) => {
  return client('campaign/delete', { data: payload, method: 'DELETE' });
};

export const saveCampaignItemState = (payload: {
  id: CampaignData['id'];
  state: CampaignData['state'];
}) => {
  return client('campaign/save-state', { data: payload, method: 'PATCH' });
};

export const createLinkShortnerItem = (payload: { link: string }) => {
  return client('links/create', { method: 'POST', data: payload });
};

export const searchCampaigns = async ({
  query,
  state,
  type,
}: {
  query?: string;
  type?: CampaignTypes;
  state?: CampaignStates;
}) => {
  const response = await client('campaign/search', { params: { query, state, type } });
  return response.data.campaigns;
};

export const filterCampaigns = ({
  state,
  page,
  type,
}: {
  state?: string;
  page?: number;
  type?: string;
}) => {
  return client('campaign/filter', {
    params: { state, page, limit: CAMPAIGN_LIST_LIMIT, type },
  });
};

export const sendTestCampaignMessage = (data: {
  content: string;
  recipients: string;
  sender_id?: string;
  table_id?: string | null;
  smart_list_id?: string | null;
}) => {
  return client('campaign/send-test', { data, method: 'POST' });
};

export const getCampaignAudience = async (payload: {
  audience_type: number;
  smart_list_id?: string | null;
  table_id?: string | null;
  group_id?: string | null;
  segment_id?: string | null;
  audience_id?: string | null;
}) => {
  const { data } = await client('campaign/get-audience', { data: payload, method: 'POST' });
  return data;
};

export const topupCampaignCredits = async (payload: { amount: number; card: string }) => {
  const { data } = await client('', {
    data: payload,
    method: 'POST',
    url: buildPaymentURL(`/credits/top-up`),
  });
  return data;
};

export const identityGraphNumberFilter = async (payload: {
  state?: string[];
  lga?: string[];
  gender?: string[];
  predicted_religion?: string[];
  predicted_ethnicity?: string[];
}) => {
  const data = await client('', {
    data: payload,
    method: 'POST',
    url: buildGraphURL(`/filter`),
  });
  return data;
};

export const identityGraphDistinctValues = async (payload: string[]) => {
  return await client('', {
    data: payload,
    method: 'POST',
    url: buildGraphURL(`/distinct_values`),
  });
};

export const identityGraphDependentValues = async (payload: {
  attribute: string;
  dependent: string;
  attribute_values: string[];
}) => {
  return await client('', {
    data: payload,
    method: 'POST',
    url: buildGraphURL(`/dependent_values`),
  });
};

export const getMarketingSettings = async () => {
  const { data } = await client('organisations/settings');
  return data.settings;
};

export const saveMarketingSettings = async (payload: MarketingSettingsSchema) => {
  const { marketing } = payload;
  const { data } = await client('organisations/settings/save', {
    data: { marketing: marketing },
    method: 'PATCH',
  });
  return data;
};

export const generateMarketingReport = async (
  payload: Omit<MarketingReportSchema, 'to' | 'from'> & {
    to?: string;
    from?: string;
    campaign_id?: string;
  },
) => {
  const { data } = await client('', {
    data: payload,
    method: 'POST',
    url: buildReportsURL(`data_export/request`),
  });
  return data;
};

export const campaignImport = (payload: FormData) => {
  return client('campaign/import', { data: payload, method: 'POST' });
};

export const createCoupon = async (payload: any) => {
  const { data } = await client('campaign/create', { data: payload, method: 'POST' });
  return data;
};

export const filterCoupons = ({ query, page }: { query?: string; page?: number }) => {
  return client('campaign/filter/coupon', {
    params: { state: query, page, limit: CAMPAIGN_LIST_LIMIT },
  });
};

export const searchCoupons = async ({
  query,
  state,
}: {
  query?: string;
  state?: CampaignTypes;
}) => {
  const response = await client('campaign/search/coupon', { params: { query, state } });
  return response.data.campaigns;
};

export const getDashboardData = async (data: { period: number; type?: string }) => {
  const { period, type = 'sms' } = data;
  const response = await client('', {
    data: { period },
    method: 'POST',
    url: buildReportsURL(`campaigns/${type}`),
  });
  return response.data;
};

export const getEmailTemplates = async () => {
  const { data } = await client('email/templates');
  return data.templates;
};

export const getEmailTemplate = async (id: string) => {
  const { data } = await client(`email/template/${id}`);
  return data.template;
};

export const saveEmailTemplates = async (payload: { name?: string; json?: any; html?: string }) => {
  const { data } = await client('email/template/create', { method: 'POST', data: payload });
  return data.template;
};

export const updateEmailTemplate = async (payload: {
  id: string;
  name?: string;
  json?: any;
  html?: string;
}) => {
  const { data } = await client('email/template/update', { method: 'PATCH', data: payload });
  return data.template;
};

export const deleteEmailTemplate = async (payload: { id: string }) => {
  const { data } = await client('email/template/delete', { method: 'DELETE', data: payload });
  return data;
};

export const getEmailDomains = async () => {
  const { data } = await client('domains');
  return data.domains;
};

export const createEmailDomain = async (domain: string) => {
  const { data } = await client('domains/authenticate/save', { method: 'POST', data: { domain } });
  return data.domain;
};

export const validateEmailDomain = async (domainId: string) => {
  const response = await client('domains/authenticate/validate', {
    method: 'POST',
    data: { id: domainId },
  });
  return response.data.domain;
};

export const deleteEmailDomain = async (domainId: string) => {
  const response = await client('domains/authenticate/delete', {
    method: 'DELETE',
    data: { id: domainId },
  });
  return response;
};

export const fetchEmailCampaignReport = async (id: CampaignData['id']) => {
  const { data } = await client(`campaign/email/report/${id}`, { method: 'GET' });
  return data;
};

export const fetchEmailCampaignDesign = async (): Promise<EmailCampaignDesign[]> => {
  const { data } = await client(`email/designs`);
  return data.designs;
};

export const saveEmailCampaignDesign = async (
  payload: Omit<EmailCampaignDesignPayload, 'id'>,
): Promise<EmailCampaignDesign> => {
  const { data } = await client(`email/design/save`, { data: payload, method: 'POST' });
  return data;
};

export const updateEmailCampaignDesign = async (
  payload: EmailCampaignDesignPayload,
): Promise<EmailCampaignDesign> => {
  const { data } = await client(`email/design/save`, { data: payload, method: 'POST' });
  return data;
};

export const sendTestEmail = async (payload: {
  content: string;
  recipients: string;
  sender_id: string;
  subject: string;
  from_name: string;
  table_id?: string | null;
  smart_list_id?: string | null;
}) => {
  const { data } = await client('email/send', { data: payload, method: 'POST' });
  return data;
};

export const reportExportList = async (params: AxiosRequestConfig['params']) => {
  const { data, meta } = await client('', {
    params: { ...params, limit: 15 },
    url: buildReportsURL(`data_export`),
  });
  return { data, meta };
};

export const campaignFrequencyFuzzy = async (text: string) => {
  const { data } = await client('', {
    method: 'POST',
    data: { text },
    url: buildAIURL(`campaign_frequency/fuzzy`),
  });
  return data.frequency_type;
};

export const campaignTemplateFuzzy = async (text: string) => {
  const { data } = await client('', {
    method: 'POST',
    data: { text },
    url: buildAIURL(`template/fuzzy`),
  });
  return data;
};

export const generateAIContent = async (text: string[]) => {
  const { data } = await client('', {
    method: 'POST',
    data: { text },
    url: buildAIURL(`analyse/campaign_generation`),
  });

  return data.template;
};

export const aiGenerateReply = async (payload: { prompt: string; text?: string }) => {
  const { data } = await client('', {
    method: 'POST',
    data: payload,
    url: buildAIURL(`analyse/reply_compose_text`),
  });
  return data.reply;
};

export const aiToneAnalyzer = async (text: string) => {
  const { data } = await client('', {
    method: 'POST',
    data: { text },
    url: buildAIURL(`analyse/sentiment`),
  });
  return data.tone;
};

export const getInboxes = async (params: {
  q?: string;
  members?: boolean;
  is_pinned?: boolean;
  credentials?: boolean;
  show_report?: boolean;
  type: InboxType['type'];
}) => {
  const { type, ...rest } = params;

  const { data } = await client('', {
    params: rest,
    url: buildConversationUrl(`inbox/list/${type}`),
  });

  return data.inboxes;
};

export const createInbox = async (payload: Partial<InboxType>, type: InboxType['type']) => {
  const { data } = await client('', {
    data: payload,
    method: 'POST',
    url: buildConversationUrl(`inbox/${type}`),
  });

  return data.inbox;
};
