// @ts-nocheck
import React from 'react';
import { Box, Icon, Popover, PopoverContent, PopoverTrigger } from '@chakra-ui/core';
import { PopoverWrapper } from '../../../list-view.styles';

const DecimalFormatOption = (props: any) => {
  const { decimalPlaces } = props;
  return (
    <Box className={`list-item ${props.active ? 'active' : ''}`} onClick={props.onClick}>
      {(1).toFixed(decimalPlaces)}
    </Box>
  );
};

export const DecimalFormatSelect = (props: any) => {
  let customization = props?.column?.customization || {};
  let decimalPlaces = customization?.decimal_places || 1;

  const handleDecimalPlaceSelect = (value: number) => {
    props.updateCustomization(props.column.uid, {
      ...customization,
      decimal_places: value,
    });
  };

  return (
    <Popover trigger="hover" placement="right-start">
      {({ onClose }) => (
        <>
          <PopoverTrigger>
            <div className="list-item-no-icon">
              {(1).toFixed(decimalPlaces)}
              <Box display="flex" justifyContent="flex-end" alignItems="center">
                <Icon name="chevron-right" size="12.5px" />
              </Box>
            </div>
          </PopoverTrigger>
          <PopoverContent
            zIndex={4}
            width="max-content"
            boxShadow="none"
            _focus={{
              boxShadow: 'none',
              outline: 'none',
            }}
          >
            <PopoverWrapper>
              {[1, 2, 3, 4].map((i: any, index) => (
                <DecimalFormatOption
                  key={index}
                  active={i === decimalPlaces}
                  decimalPlaces={i}
                  onClick={() => {
                    handleDecimalPlaceSelect(i);
                    // onClose();
                    props.close();
                  }}
                />
              ))}
            </PopoverWrapper>
          </PopoverContent>
        </>
      )}
    </Popover>
  );
};
