import React, { createContext, useEffect, useRef, useState } from "react";

export const initialParamState = [
    {
        id: 0, 
        isWritten: false,
        isDisabled: false,
        key_text: '',
        value_text: '',
        description_text: '',
    }
]

interface ParamsStateProps {
    id: number;
    isWritten: boolean;
    isDisabled: boolean;
    key_text: any;
    value_text: any;
    description_text: string;
};

export interface DropDownOptionsProps {
    id: number,
    label: string,
    value: string,
    explanation: string[],
    selected: boolean
};

export interface RequestArrayProps {
    id: number; 
    current: boolean;
};

export interface Props {
    paramsState: ParamsStateProps[][];
    setParamsState: (paramsState: ParamsStateProps[][]) => void;

    rowId: number[];
    setRowId: (rowId: number[]) => void;

    currentCell: Array<number | string>[];
    setCurrentCell: (currentCell: Array<number | string>[]) => void;

    createNewList: (previousRow: ParamsStateProps, n: number) => void;
    setText: (e: any, rowId: number, text: never, key: keyof typeof initialParamState[0], n: number) => void;

    cursorPosition: Array<number>[];
    setCursorPosition: (cursorPosition: Array<number>[]) => void;

    urlText: string[];
    setUrlText: (urlText: string[]) => void;

    totalText: string[];
    setTotalText: (totalText: string[]) => void;

    userText: string[];
    setUserText: (userText: string[]) => void;

    focusRef: React.MutableRefObject<Array<HTMLDivElement | null>>;
    urlParser: (n: number) => void;

    dropDown: Array<any>[];
    setDropDown: Function;

    dropDownOptions: DropDownOptionsProps[];
    setDropDownOptions: (dropDownOptions: DropDownOptionsProps[]) => void;

    requestArray: RequestArrayProps[];
    setRequestArray: (requestArray: RequestArrayProps[]) => void;

    focusState: boolean;
    setFocusState: (focusState: boolean) => void;

    nameRef: React.MutableRefObject<Array<HTMLDivElement | null>>;
    urlRef: React.MutableRefObject<Array<HTMLDivElement | null>>;

    requestName: string[];
    setRequestName: (requestName: string[]) => void;

    requestInputFocus: boolean;
    setRequestInputFocus: (requestInputFocus: boolean) => void;

    relativeInputFocus: boolean;
    setRelativeInputFocus: (relativeInputFocus: boolean) => void;

    requestCursorPosition: number[];
    setRequestCursorPosition: (requestCursorPosition: number[]) => void;

    relativeCursorPosition: number[];
    setRelativeCursorPosition: (relativeCursorPosition: number[]) => void;

    expandedState: number;
    setExpandedState: (expandedState: number) => void;

    requestFocusRef: React.MutableRefObject<any>;
    relativeFocusRef: React.MutableRefObject<any>;

    requestMethod: string[];
    setRequestMethod: (requestMethod: string[]) => void;

    requestInnerFocus: boolean;
    setRequestInnerFocus: (requestInnerFocus: boolean) => void;

    relativeInnerFocus: boolean;
    setRelativeInnerFocus: (relativeInnerFocus: boolean) => void;

    responseBody: any[];
    setResponseBody: (responseBody: any[]) => void;

    requestBody: any[];
    setRequestBody: (requestBody: any[]) => void;

    settingsUniqueIdentifier: string[];
    setSettingsUniqueIdentifier: (settingsUniqueIdentifier: string[]) => void;

    arrayKey: string[];
    setArrayKey: (arrayKey: string[]) => void;

    nextPageState: boolean[][];
    setNextPage: (nextPageState: boolean[][]) => void;

    selectedTitle: string[];
    setSelectedTitle: (selectedTitle: string[]) => void;

    jsonResponse:  any[];
    setJsonResponse: (jsonResponse: any[]) => void;

    testResult: Array<boolean | null>[];
    setTestResult: (testResult: Array<boolean | null>[]) => void;

    isJsonAvailable: boolean[];
    setIsJsonAvailable: (isJsonAvailable: boolean[]) => void;

    responseBodyEnabled: boolean[];
    setResponseBodyEnabled: (responseBodyEnabled: boolean[]) => void;

    testResultResponse: Array<boolean | null>[];
    setTestResultResponse: (testResult: Array<boolean | null>[]) => void;

    isJsonAvailableResponse: boolean[];
    setIsJsonAvailableResponse: (isJsonAvailable: boolean[]) => void;

    isResponseSelectorValid: (boolean | null)[];
    setIsResponseSelectorValid: (isResponseSelectorValid: (boolean | null)[]) => void;

    schemaResponse: any[][];
    setSchemaResponse: (schemaResponse: any[][]) => void;

};

export const RequestParamsContext = createContext({} as Props);

const RequestParamsContextProvider = (props: any) => {

    const initialDropDownOptions = [
        {
            id: 0,
            label: 'Next Page Link',
            value: 'nextPageLink',
            explanation: ['Cursor-based Pagination', 'The URL of the next page.', 'Output'],
            selected: false
        },
        {
            id: 1,
            label: 'Page Parameter Key',
            value: 'pageParameterKey',
            explanation: ['Page-based Pagination', 'The key of the pagination parameter.', 'Input'],
            selected: false
        },
        {
            id: 2,
            label: 'Offset',
            value: 'offset',
            explanation: ['Offset-based Pagination', 'The key of the number of records to be skipped per iteration.', 'Input'],
            selected: false
        },
        {
            id: 3,
            label: 'Limit',
            value: 'limit',
            explanation: ['Records/Item Limit', 'The key of the number of records returned per request. For all input pagination parameters.', 'Input'],
            selected: false
        }
    ]
    
    const [paramsState, setParamsState] = useState(
        [
            [
                {
                    id: 0, 
                    isWritten: false,
                    isDisabled: false,
                    key_text: null,
                    value_text: null,
                    description_text: '',
                }
            ]
        ]
    )
    const [rowId, setRowId] = useState([0])
    const [currentCell, setCurrentCell] = useState([[0, 0, '']])
    const [cursorPosition, setCursorPosition] = useState([[0, 0]])
    const [urlText, setUrlText] = useState(['']);
    const [totalText, setTotalText] = useState(['']);
    const [userText, setUserText] = useState(['']);
    const [dropDown, setDropDown] = useState([[false, 0, 0]]);
    const focusRef = useRef<Array<HTMLDivElement | null>>([]);
    const [dropDownOptions, setDropDownOptions] = useState(initialDropDownOptions)
    const [requestArray, setRequestArray] = useState([{
        id: 0,
        current: false,
    }])
    const [focusState, setFocusState] = useState(false)
    const nameRef = useRef<Array<HTMLDivElement | null>>([]);
    const urlRef = useRef<Array<HTMLDivElement | null>>([]);
    const [requestName, setRequestName] = useState([''])
    const [requestInputFocus, setRequestInputFocus] = useState(false)
    const [relativeInputFocus, setRelativeInputFocus] = useState(false)
    const [requestCursorPosition, setRequestCursorPosition] = useState([0,0])
    const [relativeCursorPosition, setRelativeCursorPosition] = useState([0,0])
    const [expandedState, setExpandedState] = useState(0)
    const requestFocusRef = useRef<any>();
    const relativeFocusRef = useRef<any>();
    const [requestMethod, setRequestMethod] = useState(['get'])
    const [requestInnerFocus, setRequestInnerFocus] = useState(false)
    const [relativeInnerFocus, setRelativeInnerFocus] = useState(false)
    const [responseBody, setResponseBody] = useState([null])
    const [requestBody, setRequestBody] = useState([null])
    const [settingsUniqueIdentifier, setSettingsUniqueIdentifier] = useState([''])
    const [arrayKey, setArrayKey] = useState([''])
    const [nextPageState, setNextPage] = useState([[false, false]])
    const [selectedTitle, setSelectedTitle] = useState(['Params'])
    const [jsonResponse, setJsonResponse] = useState([null])
    const [testResult, setTestResult] = useState([[null, false]])
    const [isJsonAvailable, setIsJsonAvailable] = useState([false])
    const [responseBodyEnabled, setResponseBodyEnabled] = useState([false])
    const [testResultResponse, setTestResultResponse] = useState([[null, false]]) 
    const [isJsonAvailableResponse, setIsJsonAvailableResponse] = useState([false]) 
    const [isResponseSelectorValid, setIsResponseSelectorValid] = useState([null] as (boolean | null)[])
    const [schemaResponse, setSchemaResponse] = useState([[null as any]])

    useEffect(() => {
        setParamsState([[
            {
                id: 0, 
                isWritten: false,
                isDisabled: false,
                key_text: null,
                value_text: null,
                description_text: '',
            }
        ]])
        
    }, []);

    const urlParser = (n: number) => {
        let keyText;
        let valueText;
        let parsedText = '';
        let firstTaken = false;

        paramsState[n].forEach((item, index, array) => {
            if (item.isWritten && !item.isDisabled) {
                if (!firstTaken) {
                    keyText = '?' + item.key_text;
                    parsedText = '';
                    valueText = item.value_text && typeof item.value_text === 'string' ? '=' + item.value_text : typeof item.value_text === 'string' ? '=' : '';
                    parsedText += keyText+valueText;
                    firstTaken = true;
                } else {
                    keyText = item.key_text && typeof item.key_text === 'string' ? '&' + item.key_text : typeof item.key_text === 'string' ? '&' : '&';
                    valueText = item.value_text && typeof item.value_text === 'string' ? '=' + item.value_text : typeof item.value_text === 'string' ? '=' : '';
                    parsedText += keyText+valueText;
                };
            };
            
        });
        urlText[n] = parsedText;
        setUrlText(urlText);
    };

    const createNewList = (previousRow: ParamsStateProps, n: number) => {
        paramsState[n][paramsState[n]?.length-1].isWritten = true;

        rowId[n] = paramsState[n].length;
        setRowId(rowId);

        paramsState[n] = paramsState[n].concat({id: paramsState[n].length, isWritten: false, isDisabled: false, key_text: null, value_text: null, description_text: ''});
        dropDown[n] = [false, 0, 0]
        setDropDown(dropDown)
        setParamsState(paramsState);
    };

    const setText = (e: any, rowId: number, text: never, key: keyof typeof initialParamState[0], n: number) => {
        
        paramsState[n][rowId][key] = text;
        setParamsState([...paramsState]);
    };

    
    const value = {paramsState, setParamsState, rowId, setRowId, currentCell, 
        setCurrentCell, createNewList, setText, cursorPosition, setCursorPosition, 
        urlText, setUrlText, totalText, setTotalText, userText, setUserText, focusRef, 
        urlParser, dropDown, setDropDown, dropDownOptions, setDropDownOptions, requestArray,
        setRequestArray, focusState, setFocusState, nameRef, urlRef, requestName, setRequestName,
        requestInputFocus, setRequestInputFocus, relativeInputFocus, setRelativeInputFocus,
        requestCursorPosition, setRequestCursorPosition, relativeCursorPosition, setRelativeCursorPosition,
        expandedState, setExpandedState, requestFocusRef, relativeFocusRef, requestMethod, setRequestMethod,
        requestInnerFocus, setRequestInnerFocus, relativeInnerFocus, setRelativeInnerFocus,
        responseBody, setResponseBody, requestBody, setRequestBody, settingsUniqueIdentifier, 
        setSettingsUniqueIdentifier, arrayKey, setArrayKey, nextPageState, setNextPage, selectedTitle,
        setSelectedTitle, jsonResponse, setJsonResponse, testResult, setTestResult, isJsonAvailable, 
        setIsJsonAvailable, responseBodyEnabled, setResponseBodyEnabled, testResultResponse, 
        setTestResultResponse, isJsonAvailableResponse, setIsJsonAvailableResponse, isResponseSelectorValid, 
        setIsResponseSelectorValid, schemaResponse, setSchemaResponse
    };

    return (
        <RequestParamsContext.Provider value={value}>
            {props.children}
        </RequestParamsContext.Provider>
    )
};

export default RequestParamsContextProvider
 

