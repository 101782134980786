import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { FullPageSpinner } from './app/components';
import { AcceptOrganisationInvite } from './app/unauthenticated-app/organisation/';
import { AcceptTeamInvite } from './app/unauthenticated-app/team/';
import { VerifyEmailPage } from './app/unauthenticated-app/verify';

const loadAuthenticatedApp = () => import('./app/authenticated-app');

const UnauthenticatedApp = React.lazy(() => import('./app/unauthenticated-app'));
const AuthenticatedApp = React.lazy(loadAuthenticatedApp);

const App = () => {
  return (
    <React.Suspense fallback={<FullPageSpinner />}>
      <Switch>
        <Route
          exact
          path="/"
          render={() => {
            return <Redirect to="/s/lists/lists" />;
          }}
        />
        <Route path="/s" render={routeProps => <AuthenticatedApp {...routeProps} />} />
        <Route
          path="/organisations/invite/accept/:email/:token/:organisation_id/:organisationName"
          render={routeProps => <AcceptOrganisationInvite {...routeProps} />}
        />
        <Route
          path="/team/invite/:email/:token/:team_id/:teamName"
          render={routeProps => <AcceptTeamInvite {...routeProps} />}
        />
        <Route path="/verify/:token" render={routeProps => <VerifyEmailPage {...routeProps} />} />
        <UnauthenticatedApp />
      </Switch>
    </React.Suspense>
  );
};

export default App;
