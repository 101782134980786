import React, { ChangeEvent, useEffect, useState } from 'react';
import { ListAutomation } from 'app/authenticated-app/lists/lists.types';
import {
  AutomationActionsSection,
  AutomationHeaderSection,
  AutomationTriggerConditionsSection,
  AutomationTriggerSection,
} from './components';
import { Button, ConfirmModal, ToastBox } from 'app/components';
import { IconButton, Stack, Switch, Tooltip, useDisclosure, useToast } from '@chakra-ui/core';

interface Props {
  automation: ListAutomation | null;
  setActiveSection: Function;
  updateAutomation: Function;
  addAutomationAction: Function;
  updateAutomationAction: Function;
  deleteAutomation: Function;
  deleteAutomationAction: Function;
}

export const EditAutomation = (props: Props) => {
  const [automation, setAutomation] = useState<ListAutomation | null>(null);
  const [triggerType, setTriggerType] = useState<string>('schedule');
  const [deleteLoading, setDeleteLoading] = useState(false);

  const goBack = () => props.setActiveSection(null);

  const toast = useToast();
  const {
    isOpen: deleteDialogOpen,
    onClose: closeDeleteDialog,
    onOpen: openDeleteDialog,
  } = useDisclosure();

  useEffect(() => {
    if (props.automation) {
      setAutomation(props.automation);

      if (['schedule', 'condition'].includes(props.automation.trigger_type)) {
        setTriggerType(props.automation.trigger_type);
      } else {
        setTriggerType(props.automation.trigger_event);
      }
    }
  }, [props.automation]);

  const handleTriggerTypeChanged = (e: ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value;

    let payload;

    if (['schedule', 'condition'].includes(value)) {
      payload = {
        trigger_type: value,
        trigger_event: null,
      };

      if (value === 'schedule') {
        payload = {
          ...payload,
          schedule_start: new Date().toISOString(),
          schedule_interval: 'daily',
        };
      }
    } else {
      payload = {
        trigger_type: 'event',
        trigger_event: value,
      };
    }

    if (value !== 'schedule') {
      payload = {
        ...payload,
        schedule_start: null,
        schedule_interval: null,
      };
    }

    // @ts-ignore
    const updatedAutomation: ListAutomation = {
      ...automation,
      ...payload,
    };

    setAutomation(updatedAutomation);
    setTriggerType(value);

    props.updateAutomation(automation?.id, payload);
  };
  const handleScheduleIntervalChanged = (e: ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value;

    let payload = {
      schedule_interval: value,
    };

    // @ts-ignore
    const updatedAutomation: ListAutomation = {
      ...automation,
      ...payload,
    };
    setAutomation(updatedAutomation);
    props.updateAutomation(automation?.id, payload);
  };
  const handleTriggerConditionsUpdate = (conditions: any[]) => {
    const payload = {
      trigger_conditions: conditions,
    };

    // @ts-ignore
    setAutomation({
      ...automation,
      ...payload,
    });
    props.updateAutomation(automation?.id, payload);
  };

  const handleAutomationUpdate = (payload: any) => {
    setAutomation({
      ...automation,
      ...payload,
    });
    props.updateAutomation(automation?.id, payload);
  };

  const handleActiveSwitchChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.checked;
    const payload = {
      is_active: value,
    };
    handleAutomationUpdate(payload);
  };

  const handleDelete = async () => {
    setDeleteLoading(true);
    try {
      await props.deleteAutomation(automation?.id);
      toast({
        position: 'bottom-left',
        render: () => <ToastBox status="success" message="Automation deleted successfully" />,
      });
      setDeleteLoading(false);
      goBack();
    } catch (e) {
      toast({
        position: 'bottom-left',
        render: () => (
          <ToastBox status="success" message="Unable to delete automation, please try again" />
        ),
      });
    }
  };

  return (
    <>
      <AutomationHeaderSection
        automation={automation}
        handleAutomationUpdate={handleAutomationUpdate}
        goBack={goBack}
        deleteAutomation={props.deleteAutomation}
      />
      <AutomationTriggerSection
        automation={automation}
        triggerType={triggerType}
        handleTriggerTypeChanged={handleTriggerTypeChanged}
        handleScheduleIntervalChanged={handleScheduleIntervalChanged}
        handleAutomationUpdate={handleAutomationUpdate}
      />
      <AutomationTriggerConditionsSection
        automation={automation}
        handleTriggerConditionsUpdate={handleTriggerConditionsUpdate}
      />
      <AutomationActionsSection
        automation={automation}
        addAutomationAction={props.addAutomationAction}
        updateAutomationAction={props.updateAutomationAction}
        deleteAutomationAction={props.deleteAutomationAction}
      />
      <Stack mb="4" isInline alignItems="center">
        <Tooltip
          hasArrow
          placement="top"
          aria-label="toggle status"
          label="Toggle Automation Status"
        >
          <Switch
            size="sm"
            marginX="10px"
            isChecked={!!automation?.is_active}
            onChange={handleActiveSwitchChange}
          />
        </Tooltip>
        <Button size="sm" flex={1} variant="solid" variantColor="blue" onClick={goBack}>
          Save automation
        </Button>
        <Tooltip
          hasArrow
          ml="0.5rem"
          placement="top"
          aria-label="delete automation"
          label="Delete Automation"
        >
          <IconButton
            size="sm"
            variantColor="red"
            variant="outline"
            onClick={openDeleteDialog}
            aria-label="delete automation"
            // @ts-ignore
            icon="trash"
          />
        </Tooltip>
      </Stack>
      <ConfirmModal
        title="Delete Automation"
        isOpen={deleteDialogOpen}
        isLoading={deleteLoading}
        onConfirm={handleDelete}
        onClose={closeDeleteDialog}
      />
    </>
  );
};
