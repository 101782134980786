import * as React from 'react';
import { Box, Spinner, BoxProps } from '@chakra-ui/core';
import { BodyText } from '../Typography';

export function FullPageSpinner(props: BoxProps & { loadingText?: string }) {
  return (
    <Box
      height="calc(100vh - 48px)"
      display="flex"
      alignItems="center"
      flexDirection="column"
      justifyContent="center"
      {...props}
    >
      <Spinner size="md" speed="0.9s" thickness="3px" color="blue.500" emptyColor="gray.200" />
      {props.loadingText && <BodyText my={4}>{props.loadingText}</BodyText>}
    </Box>
  );
}
