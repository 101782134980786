import * as React from 'react';

export function createContext<T>(props?: any) {
  const Context = React.createContext<T | undefined>(undefined);

  function useContext() {
    const context = React.useContext<T | undefined>(Context);
    if (!context) {
      throw new Error('useContext must be within a Provider with a value');
    }
    return context;
  }

  return [Context.Provider, useContext, Context] as const;
}

export function createHookContext<P, R>(hook: (props: P) => R) {
  const [ContextProvider, useContext] = createContext<R>();
  const Provider: React.FC<P> = props => {
    const context = hook(props);
    const memoContext = React.useMemo(() => context, [context]);

    return React.createElement(ContextProvider, {
      value: memoContext,
      children: props.children,
    });
  };
  const useProviderContext = () => useContext();
  return [Provider, useProviderContext] as const;
}
