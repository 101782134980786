import styled from '@emotion/styled';
import { ModalContent } from '@chakra-ui/core';

export const RecordModalWrapper = styled(ModalContent)`
  min-width: 600px;
  max-height: 90vh;

  //overflow-x: hidden;
  overflow-y: auto;
  scroll-behavior: smooth;

  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    background: #f5f5f5;
    border-radius: 4px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background: #ccc;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #999;
  }

  @media print {
    overflow-y: visible;
    max-height: max-content;
    box-shadow: none;
    margin-top: 0;
    padding: 10px 0;

    .section-header {
      position: relative;
      padding-top: 0;

      .actions {
        display: none !important;
      }
    }

    .add-button {
      display: none;
    }
  }

  .heading-toolbar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    padding: 1rem;
    border-bottom: 1px #dadee3 solid;
  }

  .section-header {
    position: sticky;
    top: -30px;
    z-index: 100000;
    background-color: white;
    transition: all 0.2s ease-in;
    padding-top: 20px;
    border-bottom: solid #e7e6e6 2px;

    .actions {
      display: flex;
      margin-bottom: 15px;
      align-items: center;
    }
  }

  .section-header + .section-header-stuck {
  }

  .heading {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 15px;
  }

  .column-item {
    .label-menu {
      display: none;
    }

    :hover {
      .label-menu {
        display: block;
      }
    }
  }

  .add-button {
    align-self: flex-start;
    font-weight: 500;
    margin: 10px 0;
  }

  .file-attachment {
    border: 2px solid rgba(0, 0, 0, 0.08);
    transition: all ease-in 0.09s;

    :hover {
      border: 2px solid rgba(0, 0, 0, 0.2);
    }
  }

  .file-attachment-close-button {
    height: 10px;
    font-size: 5px;
    width: 10px;
    min-width: 10px;
    top: 0px;
    z-index: 100;
    position: static;
  }
`;
