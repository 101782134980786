import { createSelector } from '@reduxjs/toolkit';
import { orderBy } from 'lodash';
import { RootState } from '../../../../root';

export const selectApiSecretKeys = createSelector(
  (state: RootState) => state.developers,
  developers => orderBy(developers.secretKeys, 'created_datetime', 'desc'),
);

export const selectApiPublicKey = createSelector(
  (state: RootState) => state.developers,
  developers => developers.publicKey?.key,
);
