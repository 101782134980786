import React from 'react';
import { OrganizationComponent as Component } from './component';
import { RootState } from '../../../../../root';
import { connect, ConnectedProps } from 'react-redux';
import { organisationImage, saveOrganisation } from 'app/unauthenticated-app/authentication';
import { logUserOut } from 'app/unauthenticated-app/authentication';

export type OrganizationPageProps = ConnectedProps<typeof stateConnector>;

const mapStateToProps = (state: RootState) => ({});

const stateConnector = connect(mapStateToProps, {
  logUserOut,
  organisationImage,
  saveOrganisation,
});

const Container = (props: any) => {
  return <Component {...props} />;
};

export const Organization = stateConnector(Container);
