import { Badge, Icon, PseudoBox, PseudoBoxProps, Stack } from '@chakra-ui/core';
import { BodyText } from 'app/components';
import React, { forwardRef } from 'react';

type ColumnItemProps = {
  column: any;
  onChange(column: any): void;
} & PseudoBoxProps;

export const ColumnItem: React.FC<ColumnItemProps> = forwardRef((props, ref) => {
  const { column, onChange, ...rest } = props;

  // const user_id = useSelector(selectUserID);
  // const lists = useSelector(selectLists) as any;
  // const createdAtColumnUID = useSelector(selectCreatedAtColumn);
  // const updatedAtColumnUID = useSelector(selectUpdatedAtColumn);

  // const toast = useToast();
  // const { head_list_id } = useParams<{ base_list_id: string; head_list_id: string }>();

  // // const [searchValue, setSearchValue] = useState('');
  // // const [isUpdatingColumnType, setIsUpdatingColumnType] = useState(false);

  // // const head_list = lists[head_list_id];

  // // let filtered_properties = Object.values(available_properties).filter(
  // //   (i: any) => i.label.toLowerCase().includes(searchValue.toLowerCase()) && !i.system,
  // // );

  // // const handleSearchValueChange = (event: any) => {
  // //   const value = event.target.value;
  // //   setSearchValue(value);
  // // };

  // // const handleChange = async (type: string) => {
  // //   const updatedColumn = {
  // //     ...column,
  // //     type,
  // //   };

  // //   const updated_columns = head_list?.columns.map((col: any) => {
  // //     if (col.id === updatedColumn.id) {
  // //       return updatedColumn;
  // //     }
  // //     return col;
  // //   });

  // //   const updated_list = { ...head_list, columns: updated_columns };

  // //   try {
  // //     setIsUpdatingColumnType(true);
  // //     const { data } = await client(`lists/list/${updated_list.id}`, {
  // //       method: 'PATCH',
  // //       data: { ...updated_list, user_id },
  // //     });
  // //     setIsUpdatingColumnType(false);
  // //     toast({
  // //       position: 'bottom-left',
  // //       render: ({ onClose }) => (
  // //         <ToastBox
  // //           status="success"
  // //           onClose={onClose}
  // //           message="Column property updated successfully"
  // //         />
  // //       ),
  // //     });
  // //   } catch (error: any) {
  // //     setIsUpdatingColumnType(false);
  // //     toast({
  // //       duration: null,
  // //       position: 'bottom-left',
  // //       render: ({ onClose }) => <ToastBox message={error?.message ?? error} onClose={onClose} />,
  // //     });
  // //   }
  // // };

  return (
    <PseudoBox
      px={2}
      ref={ref}
      bg="gray.50"
      height="32px"
      rounded="4px"
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      _hover={{
        bg: 'gray.100',
      }}
      {...rest}
    >
      <Stack isInline alignItems="center">
        <Icon size="0.8rem" color="gray.500" name="drag-handle" />
        <BodyText>{column.label}</BodyText>
      </Stack>
      <Stack isInline alignItems="center">
        <Badge fontSize="0.7rem" variantColor="green">
          {column.type}
        </Badge>
        {/* <Popover placement="bottom-end">
          {({ onClose }) => (
            <>
              <Tooltip
                placement="bottom"
                aria-label="Edit column property"
                label={<BodyText>Edit column property</BodyText>}
              >
                <Box display="inline-block">
                  <PopoverTrigger>
                    <IconButton
                      size="xs"
                      icon="edit"
                      variant="ghost"
                      color="gray.500"
                      aria-label="Edit"
                      isLoading={isUpdatingColumnType}
                    />
                  </PopoverTrigger>
                </Box>
              </Tooltip>
              <PopoverContent
                zIndex={4}
                boxShadow="none"
                width="max-content"
                _focus={{
                  boxShadow: 'none',
                  outline: 'none',
                }}
              >
                <PopoverWrapper>
                  <Box className="input-item">
                    <Input
                      height={30}
                      fontSize={13}
                      variant="filled"
                      value={searchValue}
                      onChange={handleSearchValueChange}
                      placeholder="Search property types"
                    />
                  </Box>
                  {filtered_properties.map((i: any) => (
                    <PopoverListItem
                      {...i}
                      active={i.key === column?.type}
                      disabled={
                        (i.key === 'CREATED AT' && createdAtColumnUID != null) ||
                        (i.key === 'UPDATED AT' && updatedAtColumnUID != null)
                      }
                      onClick={() => {
                        handleChange(i.key);
                        onClose?.();
                      }}
                      tooltip
                    />
                  ))}
                </PopoverWrapper>
              </PopoverContent>
            </>
          )}
        </Popover> */}
      </Stack>
    </PseudoBox>
  );
});
