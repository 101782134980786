import { Heading, ModalBody, ModalCloseButton, useToast } from '@chakra-ui/core';
import { selectProfile } from 'app/unauthenticated-app/authentication';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { client, mapObjectsToNewObject } from 'utils';
import { Button } from '../Button';
import { ModalContainer, ModalContainerOptions } from '../ModalContainer';
import { BodyText } from '../Typography';
import { ToastBox } from '../ToastBox';
import Axios from 'axios';

export const ComingSoonModal: React.FC<ModalContainerOptions> = props => {
  const { isOpen, onClose } = props;

  const [isSubmitting, setSubmitting] = useState(false);

  const toast = useToast();
  const profile = useSelector(selectProfile);

  const { data: form } = useQuery(['get-form'], async () => {
    const { data } = await Axios.get(
      `${process.env.REACT_APP_API_URL}/lists/form/1d9513da66655e9bb8902d079c1b524f`,
    );
    return data.data;
  });

  const handleNotifyMeClick = async () => {
    try {
      setSubmitting(true);
      const formColumns: { [key: string]: string } = {};
      form?.columns.forEach((c: any) => {
        formColumns[c.name.replace(/\s+/g, '')] = c.id;
      });
      const payload = mapObjectsToNewObject(formColumns, {
        email: profile?.email,
        name: `${profile?.first_name}`,
        feature: 'Unsubscribers list',
      });
      await client(`lists/form/1d9513da66655e9bb8902d079c1b524f`, {
        method: 'POST',
        data: payload,
      });
      setSubmitting(false);
      onClose?.();
      toast({
        position: 'bottom',
        render: ({ onClose }) => (
          <ToastBox
            status="success"
            onClose={onClose}
            message="An email will be sent to you once the feature is available."
          />
        ),
      });
    } catch (error: any) {
      setSubmitting(false);
      toast({
        position: 'bottom-left',
        render: ({ onClose }) => <ToastBox onClose={onClose} message={error?.message ?? error} />,
      });
    }
  };

  return (
    <ModalContainer isOpen={isOpen} onClose={onClose}>
      <ModalCloseButton size="sm" />
      <ModalBody
        py={10}
        display="flex"
        textAlign="center"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
      >
        <Heading pb={4} size="lg">
          Coming soon
        </Heading>
        <BodyText color="gray.500" maxW="sm" pb={4}>
          We are currently working to make this feature available as soon as possible. Opt-in to get
          notified once it's available by clicking the button below.
        </BodyText>
        <Button
          size="sm"
          variantColor="blue"
          isLoading={isSubmitting}
          onClick={handleNotifyMeClick}
        >
          Notify me
        </Button>
      </ModalBody>
    </ModalContainer>
  );
};
