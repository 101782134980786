import { client } from 'utils';
import { ListRow } from '../lists.types';

const updateRow = async (rowID: string, payload: any): Promise<ListRow> => {
  const { data }: { data: ListRow } = await client(`lists/row/${rowID}`, {
    method: 'PATCH',
    data: payload,
  });
  return data;
};

export const RowService = {
  updateRow,
};
