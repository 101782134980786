import { useToast } from '@chakra-ui/core';
import { ToastBox } from 'app/components';
import { useLoading, useBeforeunload } from 'hooks';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { sendAmplitudeData } from 'utils';
import { track } from 'utils/segment';
import {
  addCampaign,
  EmailCampaignSetupWidget,
  saveEmailCampaignDesign,
  updateCampaign,
  updateEmailCampaignDesign,
} from '.';
import { CampaignPayload } from './campaigns.types';

export const CreateEmailCampaign = () => {
  const toast = useToast();

  const history = useHistory();

  const dispatch = useDispatch();

  const { dispatch: loadingDispatch, loading, globalLoading } = useLoading();

  const handleCreateCampaign = async (payload: CampaignPayload) => {
    const { name, external_template_data, email_campaign_design_id } = payload;

    try {
      loadingDispatch({ type: 'GLOBAL_LOADING_STARTED' });
      await dispatch(addCampaign(payload));
      if (!email_campaign_design_id) {
        await saveEmailCampaignDesign({ name: name ?? '', design: external_template_data });
      } else {
        await updateEmailCampaignDesign({
          name: name ?? '',
          id: email_campaign_design_id,
          design: external_template_data,
        });
      }
      loadingDispatch({ type: 'GLOBAL_LOADING_RESOLVED' });
      toast({
        position: 'bottom-left',
        render: ({ onClose }) => (
          <ToastBox status="success" onClose={onClose} message="Campaign created successfully" />
        ),
      });
      track('Email Campaign Created', payload);
      history.push('/s/marketing/campaigns');
    } catch (error: any) {
      loadingDispatch({ type: 'GLOBAL_LOADING_RESOLVED' });
      toast({
        position: 'bottom-left',
        render: ({ onClose }) => <ToastBox onClose={onClose} message={error} />,
      });
    }
  };

  const handleSaveCampaignDraft = async (payload: CampaignPayload) => {
    try {
      loadingDispatch({ type: 'LOADING_STARTED' });
      await dispatch(updateCampaign(payload));
      loadingDispatch({ type: 'LOADING_RESOLVED' });
      toast({
        position: 'bottom-left',
        render: ({ onClose }) => (
          <ToastBox status="success" onClose={onClose} message="Campaign saved as draft" />
        ),
      });
      sendAmplitudeData('emailCampaignSavedAsDraft', { data: payload });
      history.push('/s/marketing/campaigns');
    } catch (error: any) {
      loadingDispatch({ type: 'LOADING_RESOLVED' });
      toast({
        position: 'bottom-left',
        render: ({ onClose }) => <ToastBox onClose={onClose} message={error} />,
      });
    }
  };

  useBeforeunload((e: any) => {
    e.preventDefault();
  });

  return (
    <EmailCampaignSetupWidget
      type="new"
      onSubmit={handleCreateCampaign}
      onSaveDraft={handleSaveCampaignDraft}
      isSavingDraft={loading === 'pending'}
      isCreatingCampaign={globalLoading === 'pending'}
    />
  );
};
