import styled from '@emotion/styled';
import { Box } from '@chakra-ui/core/dist';

export const DashboardContentWrapper = styled(Box)`
  // margin-top: 48px;
  .no-border-last > div:last-child {
    border-width: 0;
  }
  @media (max-width: 767px) {
    margin-top: 0px;
  }
`;
