import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch, RootState } from '../../../../root';
import {
  getProfile,
  updateProfile,
  changePassword,
  forgotPassword,
  saveUserProfileImage,
  saveOrganisationImage,
} from './authentication.service';
import { AuthInitialState, UserProfile, User, Device, Organization } from './types';
import { ForgotPasswordInitialValuesProps } from '../components';
import { loadState, saveState, toFormData } from 'utils';
import { pusher } from 'utils/pusher';
import { registerDeviceForNotifications } from '.';
import { selectOrgMembers } from 'app/authenticated-app/settings/slices';
import { TeamMember } from 'app/authenticated-app/settings/settings.types';

const initialState = {
  user: null,
  token: null,
  device: null,
  profile: null,
  loading: false,
  organisations: null,
  forgotPasswordToken: null,
} as AuthInitialState;

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    saveUser(state, action) {
      const { user, token, profile, organisations } = action.payload;
      state.user = user;
      state.token = token;
      state.profile = profile;
      state.organisations = organisations;
    },
    saveProfile(state, action) {
      const { profile } = action.payload;
      state.profile = profile;
    },
    saveOrganisation(state, action) {
      const { organisation } = action.payload;
      const index = state.organisations?.findIndex(item => item.id === organisation.id);
      (state.organisations ?? [])[index ?? 0] = organisation;
    },
    logUserOut(state) {
      state.user = null;
      state.token = null;
      state.profile = null;
      state.organisations = null;
      pusher.disconnect();
    },
    setForgotPasswordToken(state, action: PayloadAction<{ token: string }>) {
      const { token } = action.payload;
      state.token = token;
    },
    setAuthLoading(state, action: PayloadAction<{ loading: boolean }>) {
      state.loading = action.payload.loading;
    },
    setUserDevice(state, action: PayloadAction<{ device: Device }>) {
      const { device } = action.payload;
      state.device = device;
    },
  },
});

export const {
  saveUser,
  saveProfile,
  logUserOut,
  setForgotPasswordToken,
  setAuthLoading,
  setUserDevice,
  saveOrganisation,
} = authSlice.actions;

export const authReducer = authSlice.reducer;

export const fetchProfile =
  (organizationID: string, headers?: any) => async (dispatch: AppDispatch) => {
    const data = await getProfile(organizationID, headers);
    dispatch(saveUser(data));
    return data;
  };

export const editProfile =
  (
    values: Partial<Pick<UserProfile, 'first_name' | 'last_name' | 'organisation_id'>> & {
      user_id: User['id'];
    },
  ) =>
  async (dispatch: AppDispatch) => {
    const data = await updateProfile(values);
    dispatch(saveUser(data));
    return data;
  };

export const userProfileImage = (image: File) => async (dispatch: AppDispatch) => {
  const data = await saveUserProfileImage(toFormData({}, image, 'image'));
  dispatch(saveProfile({ profile: data }));
  return data;
};

export const organisationImage = (image: File) => async (dispatch: AppDispatch) => {
  const data = await saveOrganisationImage(toFormData({}, image, 'image'));
  dispatch(saveOrganisation({ organisation: data }));
  const organisations = (loadState() ?? {}).organisations;
  const newOrganisations = organisations.map((item: Organization) => {
    if (item.id === data.id) {
      return data;
    }
    return item;
  });
  saveState({ ...(loadState() ?? {}), organisations: newOrganisations });
  return data;
};

export const editPassword =
  (values: { old_password: string; new_password: string }) => async () => {
    return await changePassword(values);
  };

export const forgotPasswordAsync =
  (values: ForgotPasswordInitialValuesProps & { link: string }) =>
  async (dispatch: AppDispatch) => {
    const response = await forgotPassword(values);
    return response;
  };

export const authLoading = (loading: boolean) => async (dispatch: AppDispatch) => {
  dispatch(setAuthLoading({ loading }));
  return loading;
};

export const saveUserDevice =
  (
    payload: {
      os?: string | null;
      name?: string | null;
      imei?: string | null;
      model?: string | null;
      brand?: string | null;
      fcm_token?: string | null;
      os_version?: string | null;
      os_api_level?: string | null;
    },
    authPayload: { token: string },
  ) =>
  async (dispatch: AppDispatch) => {
    const device = await registerDeviceForNotifications(payload, authPayload);
    dispatch(setUserDevice(device));
    return device;
  };

export const selectProfile = createSelector(
  (state: RootState) => state.auth,
  auth => auth.profile,
);

export const selectProfileRole = createSelector(
  selectOrgMembers,
  (_: RootState, payload: { profile_id: string }) => payload,
  (orgMembers: TeamMember[], payload: { profile_id: string }) =>
    orgMembers.find(member => member.profile_id === payload.profile_id)?.name?.toLowerCase(),
);

export const selectOrganisationID = createSelector(
  selectProfile,
  profile => profile?.organisation_id,
);

export const selectUserID = createSelector(selectProfile, profile => profile?.user_id);

export const selectToken = createSelector(
  (state: RootState) => state.auth,
  auth => auth.token,
);

export const selectUser = createSelector(
  (state: RootState) => state.auth,
  auth => auth.user,
);

export const selectUserEmail = createSelector(selectUser, user => user?.email || '');

export const selectUserDevice = createSelector(
  (state: RootState) => state.auth,
  auth => auth.device,
);
