import { buildReportsURL, client } from 'utils';

export const listLogs = (payload: any) => {
  return client(``, {
    url: buildReportsURL('sms/logs'),
    data: payload,
    method: 'POST',
  });
};

export const listEmailLogs = (payload: any) => {
  return client(``, {
    url: buildReportsURL('email/logs'),
    data: payload,
    method: 'POST',
  });
};

export const listOtpLogs = (payload: any) => {
  return client(``, {
    url: buildReportsURL('otp/logs'),
    data: payload,
    method: 'POST',
  });
};

export const singleLog = (id: string) => {
  return client('', {
    url: buildReportsURL(`sms/log/${id}`),
  });
};

export const singleEmailLog = (id: string) => {
  return client('', {
    url: buildReportsURL(`email/log/${id}`),
  });
};

export const resendSMS = (payload: any) => {
  return client('campaign/send-test', {
    data: payload,
    method: 'POST',
  });
};

export const listWhatsappLogs = (payload: any) => {
  return client(``, {
    url: buildReportsURL('inbox/logs'),
    data: payload,
    method: 'POST',
  });
};

export const singleWhatsappLog = (id: string) => {
  return client('', {
    url: buildReportsURL(`inbox/log/${id}`),
  });
};

export const listAirtimeLogs = (payload: any) => {
  return client(``, {
    url: buildReportsURL('airtime/logs'),
    data: payload,
    method: 'POST',
  });
};
