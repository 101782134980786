import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'root';
import { listEmailLogs, singleEmailLog } from './log.service';

export const fetchEmailLogs = createAsyncThunk('logs/fetchEmailLogs', async (params: any) => {
  const response = await listEmailLogs(params);
  return response;
});

export const fetchMoreEmailLogs = createAsyncThunk(
  'logs/fetchMoreEMailLogs',
  async ({ ...params }: any) => {
    const response = await listEmailLogs(params);
    return response;
  },
);

export const fetchSingleEmailLog = createAsyncThunk('logs/updateEmailLog', async (id: string) => {
  const response = await singleEmailLog(id);
  return response;
});

const initialState = {
  email_reports: [] as any,
  email_report: {} as any,
  meta: {} as any,
  loading: 'idle' as string,
  loadMore: 'idle' as string,
  loadSingleLog: 'idle' as string,
};

const emailSlice = createSlice({
  name: 'email_logs',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchEmailLogs.pending, (state, action) => {
        state.loading = 'pending';
      })
      .addCase(fetchEmailLogs.fulfilled, (state, action) => {
        state.loading = 'complete';
        const { data, meta } = action.payload;
        state.email_reports = data;
        state.meta = meta;
      })
      .addCase(fetchMoreEmailLogs.pending, (state, action) => {
        state.loadMore = 'pending';
      })
      .addCase(fetchMoreEmailLogs.fulfilled, (state, action) => {
        state.loadMore = 'complete';
        const { data, meta } = action.payload;
        state.email_reports = [...state.email_reports, ...data];
        state.meta = meta;
      })
      .addCase(fetchSingleEmailLog.pending, (state, action) => {
        state.loadSingleLog = 'pending';
      })
      .addCase(fetchSingleEmailLog.fulfilled, (state, action) => {
        state.loadSingleLog = 'complete';
        const { data } = action.payload;
        state.email_report = data;
      });
  },
});

export const emailLogsReducer = emailSlice.reducer;
export const selectFromEmailLogs = (state: RootState) => state.emailLogs;
