import React, { useState, useEffect, useCallback } from 'react';
import { Box, Textarea } from '@chakra-ui/core';
import _ from 'lodash';

export const LongTextEditor = (props: any) => {
  const [initialized, setInitialized] = useState<boolean>(false);
  const [value, setValue] = useState('');

  const handleChange = async (event: any) => {
    const _value = event.target.value;
    setValue(_value);
    debouncedCall(_value, false);
  };

  const handleEditorInit = () => {
    setValue(props.value);
    setInitialized(true);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedCall = useCallback(_.debounce(props.updateValue, 1000), []);

  useEffect(() => {
    handleEditorInit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box display="flex">
      {initialized && (
        <Textarea
          placeholder="Here is a sample placeholder"
          autoFocus
          fontSize="14px"
          padding="5px"
          minWidth="250px"
          value={value}
          onChange={handleChange}
          onBlur={() => props.updateValue(value, false)}
        />
      )}
    </Box>
  );
};
