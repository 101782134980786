import {
  Accordion,
  AccordionHeader,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Alert,
  AlertIcon,
  AlertTitle,
  Box,
  CloseButton,
  ControlBox,
  PseudoBox,
  RadioGroup,
  RadioProps,
  Stack,
  useToast,
  VisuallyHidden,
} from '@chakra-ui/core';
import {
  setListDuplicateJobData,
  toggleAutomationPanel,
} from 'app/authenticated-app/lists/lists.reducer';
import { selectListDuplicateJobData } from 'app/authenticated-app/lists/lists.slice';
import { ListDuplicateGroup } from 'app/authenticated-app/lists/lists.types';
import { ListService } from 'app/authenticated-app/lists/service';
import { BodyText, Button, FullPageSpinner, ToastBox } from 'app/components';
import React, { forwardRef, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { track } from 'utils/segment';

export const DuplicateGroups: React.FC<{
  pivotColumns: string[];
  setPivotColumns(columns: string[]): void;
  setActiveSection: React.Dispatch<
    React.SetStateAction<'enrichment' | 'automation' | 'airtime-topup' | 'duplicate-check' | null>
  >;
}> = props => {
  const { pivotColumns, setActiveSection } = props;

  const dispatch = useDispatch();
  const listDuplicateJobData = useSelector(selectListDuplicateJobData);

  const { isLoading, data: duplicateGroups } = useQuery<ListDuplicateGroup[]>(
    ['duplicate-groups', listDuplicateJobData.object_id],
    () => ListService.listDuplicateGroups(listDuplicateJobData.object_id ?? ''),
    {
      enabled: listDuplicateJobData.status === 'done',
    },
  );

  const [groups, setGroups] = useState<ListDuplicateGroup[] | undefined>();

  useEffect(() => {
    if (duplicateGroups?.length) {
      setGroups(duplicateGroups);
    }
  }, [duplicateGroups]);

  const handleResolveGroupComplete = (group_id: string) => {
    setGroups(groups => groups?.filter(g => g.id !== group_id));
  };

  if (isLoading) {
    return <FullPageSpinner />;
  }

  if (!duplicateGroups?.length) {
    return (
      <Alert alignItems="flex-start" flexDirection="column" rounded="md" status="success">
        <Stack spacing="2">
          <Stack isInline alignItems="center">
            <AlertIcon size="4" />
            <AlertTitle>
              You have a clean list <span role="img">🎉</span>
            </AlertTitle>
          </Stack>
          <BodyText>No duplicates were found in your list.</BodyText>
        </Stack>
        <CloseButton
          position="absolute"
          right="8px"
          top="8px"
          onClick={() => {
            setActiveSection(null);
            dispatch(
              setListDuplicateJobData({
                list_id: undefined,
                status: undefined,
                object_id: undefined,
              }),
            );
            dispatch(toggleAutomationPanel({ automationPanelOpen: false }));
          }}
        />
      </Alert>
    );
  }

  return (
    <Stack spacing={4}>
      <Box fontSize="1rem">
        Below are the group of rows with duplicate for the selected column(s). Select the row with
        right data and click the resolve button to delete the other rows. Once your are done click
        the <strong>Finish</strong> button to reload the data on the list.
      </Box>
      <Accordion>
        {groups?.map((group, index) => (
          <DuplicateGroupItem
            key={group.id}
            group={group}
            index={index + 1}
            pivotColumns={pivotColumns}
            onResolveGroupComplete={() => handleResolveGroupComplete(group.id)}
          />
        ))}
      </Accordion>
      <Button
        size="sm"
        onClick={() => {
          setActiveSection(null);
          dispatch(
            setListDuplicateJobData({
              list_id: undefined,
              status: undefined,
              object_id: undefined,
            }),
          );
          dispatch(toggleAutomationPanel({ automationPanelOpen: false }));
          window.location.reload();
        }}
      >
        Finish
      </Button>
    </Stack>
  );
};

const DuplicateGroupItem: React.FC<{
  index: number;
  pivotColumns: string[];
  group: ListDuplicateGroup;
  onResolveGroupComplete(): void;
}> = forwardRef((props, ref) => {
  const { index, group, onResolveGroupComplete, pivotColumns } = props;
  const [row, setRow] = useState('');
  const [isResolving, setIsResolving] = useState(false);

  const toast = useToast();
  const listDuplicateJobData = useSelector(selectListDuplicateJobData);

  const handleResolveGroup = async () => {
    try {
      setIsResolving(true);
      await ListService.resolveDuplicateGroup({
        row,
        duplicate_group_id: group.id,
        duplicate_check_id: listDuplicateJobData.object_id ?? '',
      });
      setIsResolving(false);
      toast({
        position: 'bottom-left',
        render: ({ onClose }) => (
          <ToastBox status="success" message="Duplicate resolved successfully" onClose={onClose} />
        ),
      });
      onResolveGroupComplete();
      track('Resolved List duplicate');
    } catch (error: any) {
      setIsResolving(false);
      toast({
        duration: null,
        position: 'bottom-left',
        render: ({ onClose }) => <ToastBox message={error?.message ?? error} onClose={onClose} />,
      });
    }
  };

  return (
    <AccordionItem ref={ref} rounded={4} mb={4} borderWidth="2px">
      <AccordionHeader border="0" boxShadow="none" _focus={{ boxShadow: 'none' }}>
        <BodyText fontWeight="semibold" flex="1" textAlign="left">
          Group {index}
        </BodyText>
        <AccordionIcon />
      </AccordionHeader>
      <AccordionPanel border="0" pb={4}>
        <Stack>
          <RadioGroup
            spacing={4}
            value={row}
            onChange={e => {
              setRow(e.target.value);
            }}
          >
            {group.rows.map(r => (
              <CustomRadio value={r.id} key={r.id}>
                <Stack spacing={0}>
                  {Object.keys(r.columns).map(c => {
                    return (
                      <Stack isInline alignItems="center">
                        <BodyText
                          color="gray.900"
                          fontWeight={pivotColumns.includes(c) ? 'medium' : 'normal'}
                        >
                          {r.columns[c]}
                        </BodyText>
                      </Stack>
                    );
                  })}
                </Stack>
              </CustomRadio>
            ))}
          </RadioGroup>
          <Button
            size="sm"
            variantColor="blue"
            isLoading={isResolving}
            onClick={handleResolveGroup}
          >
            Resolve
          </Button>
        </Stack>
      </AccordionPanel>
    </AccordionItem>
  );
});

const CustomRadio: React.FC<RadioProps> = forwardRef((props, ref) => {
  const { isChecked, children, ...rest } = props;

  return (
    <PseudoBox
      p="1rem"
      ref={ref}
      bg="white"
      as="label"
      border="1px"
      rounded="lg"
      display="flex"
      fontWeight={500}
      cursor="pointer"
      color="lightBlack"
      textAlign="center"
      borderColor="inherit"
      alignItems="flex-start"
      aria-checked={isChecked}
      _hover={{ bg: 'gray.50' }}
      fontSize={['0.68rem', '0.68rem', '0.875rem']}
    >
      <VisuallyHidden
        as="input"
        //@ts-ignore
        type="radio"
        aria-checked={isChecked}
        defaultChecked={isChecked}
        {...rest}
      />

      <ControlBox
        size="16px"
        bg="white"
        border="2px"
        rounded="full"
        type="radio"
        borderColor="inherit"
        _disabled={{ opacity: 0.4 }}
        _focus={{ boxShadow: 'outline' }}
        _hover={{ borderColor: 'gray.300' }}
        _checked={{ bg: 'blue.500', borderColor: 'blue.500' }}
      >
        <Box size="50%" bg="white" rounded="full" />
      </ControlBox>

      <Box flex={1} ml={2} userSelect="none" position="relative" top="-2px">
        {children}
      </Box>
    </PseudoBox>
  );
});
