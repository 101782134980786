import { Stack, StackProps, BoxProps, Tooltip, Icon, TooltipProps } from '@chakra-ui/core';
import { BodyText, Subtitle } from 'app/components';
import React from 'react';

const Card = (props: StackProps) => {
  return (
    <Stack
      spacing="0"
      borderRadius="8px"
      position="relative"
      mb={['2', '2', '0']}
      boxShadow="0 1px 3px 0 rgba(0, 0, 0, 0.1),0 1px 2px 0 rgba(0, 0, 0, 0.06)"
      {...props}
    />
  );
};

const DetailTitle = (props: BoxProps) => <BodyText fontWeight="500" color="gray.600" {...props} />;

const DetailValue = (props: BoxProps) => <Subtitle fontWeight="500" {...props} />;

const Detail = (props: StackProps) => <Stack p="15px 30px" {...props} />;

const DetailTooltip = (props: Omit<TooltipProps, 'children'>) => (
  <Tooltip hasArrow placement="top" {...props}>
    <Icon size="0.75rem" color="gray.600" name="info-outline" />
  </Tooltip>
);

export { Card, Detail, DetailTitle, DetailValue, DetailTooltip };
