import { Box, Heading, useClipboard, useDisclosure, useToast } from '@chakra-ui/core';
import { ConfirmModal, SmallText, TableDropdownAction, ToastBox } from 'app/components';
import * as React from 'react';
import { useLoading } from '../../../../hooks';
import { sendAmplitudeData } from '../../../../utils/amplitude';
import { TemplateFormValues, TemplateModal } from './components';
import { TemplateContent } from './components/template-content';
import { TemplateData, TemplatesContainerProps } from './templates.types';
import { html2Text } from './templates.utils';

export function SMSTemplatesComponent(props: TemplatesContainerProps) {
  const {
    lists,
    templates,
    addTemplate,
    lists_by_id,
    editTemplate,
    removeTemplate,
    fetchTemplates,
    sampleTemplates,
    templateCategories,
    fetchSampleTemplates,
    templatesSearchResults,
  } = props;
  const mappedTemplateCategories = templateCategories.map(item => item.category);

  const { isOpen, onClose, onOpen } = useDisclosure();
  const [activeTab, setActiveTab] = React.useState('my-templates');
  const [templateToCopy, setTemplateToCopy] = React.useState<TemplateData | undefined>();
  const [templateToEdit, setTemplateToEdit] = React.useState<TemplateData | undefined>();
  const [templateToDelete, setTemplateToDelete] = React.useState<TemplateData | undefined>();
  const [selectedCategory, setSelectedCategory] = React.useState(mappedTemplateCategories[0]);
  const [templateToDuplicate, setTemplateToDuplicate] = React.useState<TemplateData | undefined>();

  const activeTabRef = React.useRef(activeTab);

  const { onCopy, hasCopied } = useClipboard(templateToCopy?.id);

  const tableActions = [
    {
      icon: 'copy',
      label: 'Duplicate template',
      onClick: (data: TemplateData) => setTemplateToDuplicate(data),
    },
    {
      icon: hasCopied ? 'check-circle' : 'copy',
      onClick: (data: TemplateData) => setTemplateToCopy(data),
      label: hasCopied ? 'Template id copied' : 'Copy template id',
    },
    {
      icon: 'delete',
      label: 'Delete template',
      onClick: (data: TemplateData) => setTemplateToDelete(data),
    },
  ] as TableDropdownAction<TemplateData>[];

  const listOptions = lists_by_id
    //@ts-ignore
    .map((id: string) => lists[id])
    .map(({ name, id }: { name: string; id: string }) => ({ label: name, value: id }));

  const toast = useToast();
  const { dispatch, loading, globalLoading } = useLoading();

  function handleTabChange(tab: string) {
    setActiveTab(tab);
    activeTabRef.current = tab;
  }

  function handleSearchSampleTemplates(query: string) {
    console.log(query);
  }

  async function handleFetchCategoryTemplates(category: string) {
    try {
      setSelectedCategory(category);
      dispatch({ type: 'LOADING_STARTED' });
      await fetchSampleTemplates({ category });
      dispatch({ type: 'LOADING_RESOLVED' });
    } catch (error: any) {
      dispatch({ type: 'LOADING_RESOLVED' });
      toast({
        position: 'bottom-left',
        render: ({ onClose }) => <ToastBox onClose={onClose} message={error} />,
      });
    }
  }

  const handleSearchMyTemplates = async (query: string) => {
    try {
      dispatch({ type: 'GLOBAL_LOADING_STARTED' });
      await templatesSearchResults(query);
      dispatch({ type: 'GLOBAL_LOADING_RESOLVED' });
    } catch (error: any) {
      dispatch({ type: 'GLOBAL_LOADING_RESOLVED' });
      toast({
        position: 'bottom-left',
        render: ({ onClose }) => <ToastBox onClose={onClose} message={error} />,
      });
    }
  };

  const handleSearchTemplates = async (query: string) => {
    if (query) {
      if (activeTabRef.current === 'my-templates') {
        handleSearchMyTemplates(query);
      } else {
        handleSearchSampleTemplates(query);
      }
    } else {
      try {
        dispatch({ type: 'GLOBAL_LOADING_STARTED' });
        await fetchTemplates();
        dispatch({ type: 'GLOBAL_LOADING_RESOLVED' });
      } catch (error: any) {
        dispatch({ type: 'GLOBAL_LOADING_RESOLVED' });
        toast({
          position: 'bottom-left',
          render: ({ onClose }) => <ToastBox onClose={onClose} message={error} />,
        });
      }
    }
  };

  async function handleAddTemplate(template: TemplateFormValues) {
    try {
      dispatch({ type: 'LOADING_STARTED' });
      await addTemplate(template);
      dispatch({ type: 'LOADING_RESOLVED' });
      onClose();
      sendAmplitudeData('templateAdded');
      toast({
        position: 'bottom-left',
        render: ({ onClose }) => (
          <ToastBox onClose={onClose} status="success" message="Template created" />
        ),
      });
    } catch (error: any) {
      dispatch({ type: 'LOADING_RESOLVED' });
      toast({
        position: 'bottom-left',
        render: ({ onClose }) => <ToastBox onClose={onClose} message={error} />,
      });
    }
  }

  async function handleDuplicateTemplate() {
    if (templateToDuplicate) {
      const { id, ...rest } = templateToDuplicate;
      try {
        dispatch({ type: 'LOADING_STARTED' });
        await addTemplate(rest);
        dispatch({ type: 'LOADING_RESOLVED' });
        setTemplateToDuplicate(undefined);
        sendAmplitudeData('templateDuplicated');
        toast({
          position: 'bottom-left',
          render: ({ onClose }) => (
            <ToastBox onClose={onClose} status="success" message="Template duplicated" />
          ),
        });
      } catch (error: any) {
        dispatch({ type: 'LOADING_RESOLVED' });
        toast({
          position: 'bottom-left',
          render: ({ onClose }) => <ToastBox onClose={onClose} message={error} />,
        });
      }
    }
  }

  async function handleUpdateTemplate(payload: TemplateFormValues) {
    try {
      dispatch({ type: 'LOADING_STARTED' });
      await editTemplate({ ...payload, template: html2Text(payload.template) });
      dispatch({ type: 'LOADING_RESOLVED' });
      setTemplateToEdit(undefined);
      sendAmplitudeData('templateUpdated');
      toast({
        position: 'bottom-left',
        render: ({ onClose }) => (
          <ToastBox onClose={onClose} status="success" message="Template edited" />
        ),
      });
    } catch (error: any) {
      dispatch({ type: 'LOADING_RESOLVED' });
      toast({
        position: 'bottom-left',
        render: ({ onClose }) => <ToastBox onClose={onClose} message={error} />,
      });
    }
  }

  async function handleDeleteTemplate() {
    if (templateToDelete) {
      try {
        dispatch({ type: 'LOADING_STARTED' });
        await removeTemplate(templateToDelete.id);
        dispatch({ type: 'LOADING_RESOLVED' });
        setTemplateToDelete(undefined);
        sendAmplitudeData('templateDeleted');
        toast({
          position: 'bottom-left',
          render: ({ onClose }) => (
            <ToastBox onClose={onClose} status="success" message="Template deleted" />
          ),
        });
      } catch (error: any) {
        dispatch({ type: 'LOADING_RESOLVED' });
        toast({
          position: 'bottom-left',
          render: ({ onClose }) => <ToastBox onClose={onClose} message={error} />,
        });
      }
    }
  }

  React.useEffect(() => {
    handleFetchCategoryTemplates(mappedTemplateCategories[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (templateToCopy) {
      onCopy?.();
    }
  }, [onCopy, templateToCopy]);

  return (
    <>
      <Box pb="6">
        <Heading as="h4" pb="0.2rem" fontSize="1.2rem" fontWeight={600} color="black">
          SMS Templates
        </Heading>
        <SmallText maxW="500px" color="#4f4f4f" fontSize="0.875rem">
          Easily design templated messages for your SMS campaigns to reach your customers.
        </SmallText>
      </Box>
      <TemplateContent
        templates={templates}
        selectedTab={activeTab}
        lists_by_id={lists_by_id}
        tableActions={tableActions}
        onTabChange={handleTabChange}
        onSearch={handleSearchTemplates}
        sampleTemplates={sampleTemplates}
        selectedCategory={selectedCategory}
        categories={mappedTemplateCategories}
        isLoading={globalLoading === 'pending'}
        handleCategoryClick={handleFetchCategoryTemplates}
        stateOptions={[
          { label: 'Saved Templates', value: 'my-templates' },
          { label: 'Sample Templates', value: 'sample-templates' },
        ]}
        onTemplateItemClick={(data: TemplateData) => setTemplateToEdit(data)}
        onOpenCreateTemplateModal={onOpen}
      />
      <TemplateModal
        lists={lists}
        isOpen={isOpen}
        onClose={onClose}
        title="Add template"
        listOptions={listOptions}
        lists_by_id={lists_by_id}
        onSubmit={handleAddTemplate}
        isLoading={loading === 'pending'}
      />
      <TemplateModal
        lists={lists}
        title="Edit template"
        listOptions={listOptions}
        isOpen={!!templateToEdit}
        lists_by_id={lists_by_id}
        initialValues={templateToEdit}
        onSubmit={handleUpdateTemplate}
        isLoading={loading === 'pending'}
        onClose={() => setTemplateToEdit(undefined)}
      />
      <ConfirmModal
        title="Duplicate template"
        isOpen={!!templateToDuplicate}
        isLoading={loading === 'pending'}
        onConfirm={handleDuplicateTemplate}
        onClose={() => setTemplateToDuplicate(undefined)}
      />
      <ConfirmModal
        title="Delete template"
        isOpen={!!templateToDelete}
        onConfirm={handleDeleteTemplate}
        isLoading={loading === 'pending'}
        onClose={() => setTemplateToDelete(undefined)}
      />
    </>
  );
}
