import { Box, Icon, Image, Stack, useDisclosure } from '@chakra-ui/core';
import styled from '@emotion/styled';
import * as React from 'react';
import { Button } from '../Button';
import { Logo } from '../Logo';
import { NetworkStatusIndicator } from '../NetworkStatus';
import { useResellerSettings } from '../ResellerProvider';
import { Dashboard } from './dashboard';
import { Content } from './dashboard.content';
import { DashboardHeader } from './dashboard.header';
import { DashboardMobile } from './dashboard.mobile';
import { simpuThemeFull } from './dashboard.theme';
import { DashboardLinks, DashboardProps } from './types';
// import { version } from '../../../../package.json';
// import { NewVersionModal } from '../NewVersionModal.tsx';

export const SimpuDashboardContainer = styled.div`
  a {
    &.active {
      color: #3d50df !important;
      background-color: rgba(61, 80, 223, 0.06);
    }
  }
`;

const links: DashboardLinks[] = [
  { icon: 'home', label: 'Home', url: '/s/home' },
  { icon: 'time-schedule', label: 'Marketing', url: '/s/marketing' },
  { icon: 'hamburger', label: 'Data', url: '/s/lists' },
  { icon: 'dollar', label: 'Payments', url: '/s/payments' },
  { icon: 'code', label: 'Developers', url: '/s/developers' },
];

export const SimpuDashboard = (props: DashboardProps) => {
  const {
    profile,
    tables,
    children,
    onLogout,
    user,
    onboarding_task_info,
    organizations,
    toggleAutomationPanel,
    onOpenHelpDrawer,
  } = props;

  const { isOpen, onClose, onOpen } = useDisclosure();
  // const {
  //   isOpen: isNewVersionModalOpen,
  //   onClose: onCloseNewVersionModal,
  //   onOpen: onOpenNewVersionModal,
  // } = useDisclosure();
  const btnRef = React.useRef(null);

  const theme = simpuThemeFull;

  const [first] = links;

  const page_access = profile && profile?.page_access;
  let permittedLinks = links.filter((obj: any) => page_access?.includes(obj.label.toLowerCase()));
  permittedLinks =
    profile?.page_access?.includes('*') || !profile?.page_access
      ? links
      : [first, ...permittedLinks];

  const resellerSettings = useResellerSettings();

  const { logo, name } = resellerSettings ?? {};

  // React.useEffect(() => {
  //   const currentVersion = version;
  //   const oldVersion = localStorage.getItem('simpu.version');
  //   if (oldVersion !== currentVersion) {
  //     onOpenNewVersionModal();
  //   }
  // }, [onOpenNewVersionModal]);

  return (
    <>
      <Dashboard theme={theme}>
        <DashboardHeader
          user={user}
          logout={onLogout}
          profile={profile}
          organizations={organizations}
          onOpenCloseHelpDrawer={onOpenHelpDrawer}
          onboarding_task_info={onboarding_task_info}
          toggleAutomationPanel={toggleAutomationPanel}
        />
        <NetworkStatusIndicator />
        <Box
          padding="20px 10px"
          backgroundColor="rgb(246 250 253)"
          display={['block', 'block', 'none', 'none']}
        >
          <Stack width="100%" isInline alignItems="center">
            <Box>
              <Button
                size="xs"
                ref={btnRef}
                onClick={onOpen}
                variant="ghost"
                display={['block', 'block', 'none', 'none']}
              >
                <Icon name="hamburger" size="16px" />
              </Button>
            </Box>
            {name.toLowerCase() !== 'simpu' ? (
              <Image alt="Logo" src={logo} width="24px" />
            ) : (
              <Logo width="24px" />
            )}
          </Stack>
        </Box>
        <Content>{children}</Content>
      </Dashboard>

      <DashboardMobile
        links={permittedLinks}
        isOpen={isOpen}
        tables={tables}
        onClose={onClose}
        theme={simpuThemeFull}
        finalFocusRef={btnRef}
        logout={onLogout}
      />
      {/* {isNewVersionModalOpen && (
        <NewVersionModal isOpen={isNewVersionModalOpen} onClose={onCloseNewVersionModal} />
      )} */}
    </>
  );
};
