/* eslint-disable react/no-unescaped-entities */
import {
  Alert,
  AlertIcon,
  AlertTitle,
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  ModalBody,
  Select,
  Stack,
} from '@chakra-ui/core';
import {
  BodyText,
  Filter,
  ModalContainer,
  ModalContainerOptions,
  OptionsMenu,
  SmallText,
  Button,
} from 'app/components';
import { selectProfile, selectUserID } from 'app/unauthenticated-app/authentication';
import { FormikConfig, useFormik } from 'formik';
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'root';
import * as yup from 'yup';
import { selectOtherOrgMembers } from '../../slices';

export type DeleteAccountPayload = { reason: string; profile_id?: string };

export const DeleteAccountModal = (
  props: ModalContainerOptions & {
    isDeleting?: boolean;
    onDeleteAccount: FormikConfig<DeleteAccountPayload>['onSubmit'];
  },
) => {
  const { isOpen, onClose, isDeleting, onDeleteAccount } = props;

  const reasons = ["I can't delete my inbox"];

  const [teammateSearch, setTeammateSearch] = useState('');
  const [selectedTeammate, setSelectedTeammate] = useState<Filter<string | undefined>>();

  const profile = useSelector(selectProfile);
  const user_id = useSelector(selectUserID);
  const otherOrgMembers = useSelector((state: RootState) =>
    selectOtherOrgMembers(state, { user_id: user_id || '' }),
  );

  const { is_owner } = profile ?? {};

  let members = useMemo(
    () =>
      otherOrgMembers
        ?.map(({ first_name, last_name, profile_id }) => ({
          label: `${first_name} ${last_name}`,
          value: profile_id,
        }))
        .filter(({ label }) =>
          label?.toLocaleLowerCase().includes(teammateSearch.toLocaleLowerCase()),
        ),
    [teammateSearch, otherOrgMembers],
  ) as Filter<string | undefined>[];

  const {
    values,
    errors,
    touched,
    setFieldValue,
    handleChange,
    handleSubmit,
  } = useFormik<DeleteAccountPayload>({
    onSubmit: onDeleteAccount,
    initialValues: { reason: '' },
    validationSchema: yup.object().shape({
      reason: yup.string().required('Reason is required'),
    }),
  });

  const handleTeammateSelect = (teammate: Filter<string | undefined>) => {
    setSelectedTeammate(teammate);
    setFieldValue('profile_id', teammate.value);
  };

  return (
    <ModalContainer
      size="lg"
      isOpen={isOpen}
      showCloseButton
      onClose={onClose}
      title="Delete account request"
    >
      <ModalBody px="0">
        <form onSubmit={handleSubmit}>
          <Stack spacing="2rem" pb="1rem" px="1rem">
            <Alert rounded="4px" status="info" alignItems="flex-start">
              <AlertIcon />
              <Stack>
                {is_owner && (
                  <>
                    <AlertTitle>You are an account owner</AlertTitle>
                    <BodyText>
                      As an account owner you are required to transfer ownership of your
                      organisation to another organisation member.
                    </BodyText>
                  </>
                )}
                <BodyText>
                  If you are sure you want to proceed with the request of your account deletion,
                  please continue below.
                </BodyText>
                <BodyText>
                  This initiates an account deletion which will result in the erase of all data
                  associated with your account from our servers.
                </BodyText>
                <BodyText>
                  This action can be cancelled within the next{' '}
                  <Box as="span" fontWeight="bold">
                    30 days
                  </Box>
                  .
                </BodyText>
              </Stack>
            </Alert>
            <Stack>
              {is_owner && (
                <Box>
                  <FormLabel fontSize="0.875rem">
                    Select member to transfer organisation ownership to
                  </FormLabel>
                  <OptionsMenu<Filter<string | undefined>>
                    popoverContentProps={{
                      maxW: '200px',
                    }}
                    options={members ?? []}
                    onSelectItem={handleTeammateSelect}
                    onSearch={query => setTeammateSearch(query)}
                    renderItem={(item: Filter<string | undefined>) => (
                      <SmallText color="gray.900">{item.label}</SmallText>
                    )}
                    isItemSelected={item => selectedTeammate?.value === item.value}
                    triggerButtonProps={{
                      size: 'md',
                      type: 'button',
                      width: '100%',
                      justifyContent: 'space-between',
                      rightIcon: 'chevron-down',
                      children: !selectedTeammate ? (
                        <SmallText color="gray.900">Select member</SmallText>
                      ) : (
                        <SmallText color="gray.900">
                          {members.find(member => member.value === selectedTeammate.value)?.label}
                        </SmallText>
                      ),
                    }}
                  />
                </Box>
              )}
              <FormControl isInvalid={!!touched.reason && !!errors.reason}>
                <FormLabel fontSize="0.875rem">
                  Tell us why you are leaving{' '}
                  <span role="img" aria-label="sad emoji">
                    😔
                  </span>
                </FormLabel>
                <Select name="reason" value={values.reason} onChange={handleChange}>
                  <option>--Select reason--</option>
                  {reasons.map((item, index) => (
                    <option key={`${item}-${index}`} value={item}>
                      {item}
                    </option>
                  ))}
                </Select>
                <FormErrorMessage>{errors.reason}</FormErrorMessage>
              </FormControl>
            </Stack>
          </Stack>
          <Box p="1rem" borderTopWidth="1px">
            <Button isLoading={isDeleting} type="submit" variantColor="red" width="100%">
              Delete your account
            </Button>
          </Box>
        </form>
      </ModalBody>
    </ModalContainer>
  );
};
