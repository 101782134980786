import {
  BoxProps,
  Icon,
  IconProps,
  Menu,
  MenuButton,
  MenuButtonProps,
  MenuItem,
  MenuItemProps,
  MenuList,
  MenuListProps,
  Stack,
  Text,
} from '@chakra-ui/core';
import React, { useState } from 'react';

export type Filter<T> = {
  label: string;
  value: T;
};

export type FilterDropdownProps<T> = {
  filters: Filter<T>[];
  menuButton?: {
    textProps?: BoxProps;
    iconProps?: IconProps;
    containerProps?: MenuButtonProps;
  };
  menuItemProps?: MenuItemProps;
  onFilterChange?(filter: Filter<T>): void;
  menuListProps?: Omit<MenuListProps, 'children'>;
};

export function FilterDropdown<T>(props: FilterDropdownProps<T>) {
  const { filters, menuListProps, menuItemProps, menuButton, onFilterChange } = props;
  const { textProps, iconProps, containerProps } = menuButton ?? {};
  const [selectedFilter, setSelectedFilter] = useState<Filter<T>>(filters[0]);

  const handleFilterChange = (filter: Filter<T>) => {
    setSelectedFilter(filter);
    onFilterChange?.(filter);
  };

  return (
    <Menu>
      <MenuButton {...containerProps}>
        <Stack isInline alignItems="center">
          <Text
            color="#4f4f4f"
            fontWeight={500}
            fontSize="0.75rem"
            textTransform="uppercase"
            {...textProps}
          >
            {selectedFilter.label}
          </Text>
          <Icon color="#4f4f4f" name="chevron-down" {...iconProps} />
        </Stack>
      </MenuButton>
      <MenuList minW="10px" placement="bottom-start" {...menuListProps}>
        {filters.map((filter, index) => (
          <MenuItem
            color="#4f4f4f"
            fontWeight={500}
            key={`${index}`}
            fontSize="0.75rem"
            textTransform="uppercase"
            onClick={() => handleFilterChange(filter)}
            {...menuItemProps}
          >
            {filter.label}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
}
