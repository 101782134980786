import {
  Flex,
  Heading,
  Icon,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useDisclosure,
  useToast,
} from '@chakra-ui/core';
import { BodyText, Button, Container, Menu, MenuItem, SmallText, ToastBox } from 'app/components';
import { AxiosError } from 'axios';
import { FormikHelpers } from 'formik';
import React, { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  deleteEmailSenderProfile,
  deleteSMSSenderProfile,
  emailSenderProfileAsDefault,
  listEmailSenderProfiles,
  listSMSSenderProfiles,
  saveEmailSenderProfile,
  saveSMSSenderProfile,
  smsSenderProfileAsDefault,
  updateEmailSenderProfile,
  updateSMSSenderProfile,
} from '../../service';
import { EmailSenderProfileModal, EmailSenderProfilePayload } from './email-sender-profile-modal';
import { EmailSenderProfiles } from './email-sender-profiles';
import { SMSSenderProfileModal, SMSSenderProfilePayload } from './sms-sender-profile-modal';
import { SMSSenderProfiles } from './sms-sender-profiles';

export const SenderProfiles = () => {
  const toast = useToast();
  const queryClient = useQueryClient();

  const [smsSenderProfileToEdit, setSMSSenderProfileToEdit] = useState<
    SMSSenderProfilePayload | undefined
  >();
  const [smsSenderProfileToDelete, setSMSSenderProfileToDelete] = useState<
    SMSSenderProfilePayload | undefined
  >();
  const [smsSenderProfileToSetAsDefault, setSMSSenderProfileToSetAsDefault] = useState<
    SMSSenderProfilePayload | undefined
  >();
  const [emailSenderProfileToEdit, setEmailSenderProfileToEdit] = useState<
    EmailSenderProfilePayload | undefined
  >();
  const [emailSenderProfileToDelete, setEmailSenderProfileToDelete] = useState<
    EmailSenderProfilePayload | undefined
  >();

  const [emailSenderProfileToSetAsDefault, setEmailSenderProfileToSetAsDefault] = useState<
    EmailSenderProfilePayload | undefined
  >();

  const { data: smsSenderProfiles, isLoading: isLoadingSmsSenderProfiles } = useQuery(
    ['sms-sender-profiles'],
    listSMSSenderProfiles,
  );

  const { data: emailSenderProfiles, isLoading: isLoadingEmailSenderProfiles } = useQuery(
    ['email-sender-profiles'],
    listEmailSenderProfiles,
  );

  const {
    isLoading: isSavingSMSSenderProfile,
    mutateAsync: saveSMSSenderProfileMutate,
  } = useMutation<any, AxiosError, any, any>(
    (options: { payload: SMSSenderProfilePayload }) => saveSMSSenderProfile(options.payload),
    {
      onSuccess: (_, variables) => {
        const { helpers } = variables;
        queryClient.invalidateQueries(['sms-sender-profiles']);
        helpers.resetForm();
        onCloseAddSMSSenderProfileModal();
        toast({
          position: 'bottom-left',
          render: ({ onClose }) => (
            <ToastBox
              status="success"
              onClose={onClose}
              message="SMS sender profile added successfully"
            />
          ),
        });
      },
      onError: error => {
        toast({
          position: 'bottom-left',
          render: ({ onClose }) => <ToastBox onClose={onClose} message={error} />,
        });
      },
    },
  );

  const {
    isLoading: isUpdatingSMSSenderProfile,
    mutateAsync: updateSMSSenderProfileMutate,
  } = useMutation<any, AxiosError, any, any>(
    (options: { payload: Partial<SMSSenderProfilePayload> }) =>
      updateSMSSenderProfile(options.payload),
    {
      onSuccess: (_, variables) => {
        const { helpers } = variables;
        queryClient.invalidateQueries(['sms-sender-profiles']);
        helpers.resetForm();
        setSMSSenderProfileToEdit(undefined);
        toast({
          position: 'bottom-left',
          render: ({ onClose }) => (
            <ToastBox
              status="success"
              onClose={onClose}
              message="SMS sender profile updated successfully"
            />
          ),
        });
      },
      onError: error => {
        toast({
          position: 'bottom-left',
          render: ({ onClose }) => <ToastBox onClose={onClose} message={error.message} />,
        });
      },
    },
  );

  const {
    isLoading: isSettingSMSSenderProfileAsDefault,
    mutateAsync: setSMSSenderProfileAsDefaultMutate,
  } = useMutation<any, AxiosError, any, any>(
    (id: SMSSenderProfilePayload['id']) => smsSenderProfileAsDefault({ id }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['sms-sender-profiles']);
        setSMSSenderProfileToSetAsDefault(undefined);
        toast({
          position: 'bottom-left',
          render: ({ onClose }) => (
            <ToastBox
              status="success"
              onClose={onClose}
              message="SMS sender profile set as default successfully"
            />
          ),
        });
      },
      onError: error => {
        toast({
          position: 'bottom-left',
          render: ({ onClose }) => <ToastBox onClose={onClose} message={error.message} />,
        });
      },
    },
  );

  const {
    isLoading: isDeletingSMSSenderProfile,
    mutateAsync: deleteSMSSenderProfileMutate,
  } = useMutation<any, AxiosError, any, any>(
    (id: SMSSenderProfilePayload['id']) => deleteSMSSenderProfile({ id }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['sms-sender-profiles']);
        setSMSSenderProfileToDelete(undefined);
        toast({
          position: 'bottom-left',
          render: ({ onClose }) => (
            <ToastBox
              status="success"
              onClose={onClose}
              message="SMS sender profile deleted successfully"
            />
          ),
        });
      },
      onError: error => {
        toast({
          position: 'bottom-left',
          render: ({ onClose }) => <ToastBox onClose={onClose} message={error.message} />,
        });
      },
    },
  );

  const {
    isLoading: isSavingEmailSenderProfile,
    mutateAsync: saveEmailSenderProfileMutate,
  } = useMutation<any, AxiosError, any, any>(
    (options: { payload: EmailSenderProfilePayload }) => saveEmailSenderProfile(options.payload),
    {
      onSuccess: (_, variables) => {
        const { helpers } = variables;
        queryClient.invalidateQueries(['email-sender-profiles']);
        helpers.resetForm();
        onCloseAddEmailSenderProfileModal();
        toast({
          position: 'bottom-left',
          render: ({ onClose }) => (
            <ToastBox
              status="success"
              onClose={onClose}
              message="Email sender profile added successfully"
            />
          ),
        });
      },
      onError: error => {
        toast({
          position: 'bottom-left',
          render: ({ onClose }) => <ToastBox onClose={onClose} message={error.message} />,
        });
      },
    },
  );

  const {
    isLoading: isUpdatingEmailSenderProfile,
    mutateAsync: updateEmailSenderProfileMutate,
  } = useMutation<any, AxiosError, any, any>(
    (options: { payload: Partial<EmailSenderProfilePayload> }) =>
      updateEmailSenderProfile(options.payload),
    {
      onSuccess: (_, variables) => {
        const { helpers } = variables;
        queryClient.invalidateQueries(['email-sender-profiles']);
        helpers.resetForm();
        setEmailSenderProfileToEdit(undefined);
        toast({
          position: 'bottom-left',
          render: ({ onClose }) => (
            <ToastBox
              status="success"
              onClose={onClose}
              message="Email sender profile updated successfully"
            />
          ),
        });
      },
      onError: error => {
        toast({
          position: 'bottom-left',
          render: ({ onClose }) => <ToastBox onClose={onClose} message={error.message} />,
        });
      },
    },
  );

  const {
    isLoading: isSettingEmailSenderProfileAsDefault,
    mutateAsync: setEmailSenderProfileAsDefaultMutate,
  } = useMutation<any, AxiosError, any, any>(
    (id: EmailSenderProfilePayload['id']) => emailSenderProfileAsDefault({ id }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['email-sender-profiles']);
        setEmailSenderProfileToSetAsDefault(undefined);
        toast({
          position: 'bottom-left',
          render: ({ onClose }) => (
            <ToastBox
              status="success"
              onClose={onClose}
              message="Email sender profile set as default successfully"
            />
          ),
        });
      },
      onError: error => {
        toast({
          position: 'bottom-left',
          render: ({ onClose }) => <ToastBox onClose={onClose} message={error.message} />,
        });
      },
    },
  );

  const {
    isLoading: isDeletingEmailSenderProfile,
    mutateAsync: deleteEmailSenderProfileMutate,
  } = useMutation<any, AxiosError, any, any>(
    (id: EmailSenderProfilePayload['id']) => deleteEmailSenderProfile({ id }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['email-sender-profiles']);
        setEmailSenderProfileToDelete(undefined);
        toast({
          position: 'bottom-left',
          render: ({ onClose }) => (
            <ToastBox
              status="success"
              onClose={onClose}
              message="Email sender profile deleted successfully"
            />
          ),
        });
      },
      onError: error => {
        toast({
          position: 'bottom-left',
          render: ({ onClose }) => <ToastBox onClose={onClose} message={error.message} />,
        });
      },
    },
  );

  const {
    isOpen: isAddSMSSenderProfileModalOpen,
    onClose: onCloseAddSMSSenderProfileModal,
    onOpen: onOpenAddSMSSenderProfileModal,
  } = useDisclosure();
  const {
    isOpen: isAddEmailSenderProfileModalOpen,
    onClose: onCloseAddEmailSenderProfileModal,
    onOpen: onOpenAddEmailSenderProfileModal,
  } = useDisclosure();

  const handleSaveSMSSenderProfile = async (
    payload: SMSSenderProfilePayload,
    helpers: FormikHelpers<SMSSenderProfilePayload>,
  ) => {
    await saveSMSSenderProfileMutate({ payload, helpers });
  };

  const handleUpdateSMSSenderProfile = async (
    payload: Partial<SMSSenderProfilePayload>,
    helpers: FormikHelpers<SMSSenderProfilePayload>,
  ) => {
    await updateSMSSenderProfileMutate({ payload, helpers });
  };

  const handleSetSMSSenderProfileAsDefault = async (
    payload: Partial<EmailSenderProfilePayload>,
  ) => {
    await setSMSSenderProfileAsDefaultMutate(payload.id);
  };

  const handleDeleteSMSSenderProfile = async (payload: Partial<SMSSenderProfilePayload>) => {
    await deleteSMSSenderProfileMutate(payload.id);
    setSMSSenderProfileToDelete(undefined);
  };

  const handleSaveEmailSenderProfile = async (
    payload: EmailSenderProfilePayload,
    helpers: FormikHelpers<EmailSenderProfilePayload>,
  ) => {
    await saveEmailSenderProfileMutate({ payload, helpers });
  };

  const handleUpdateEmailSenderProfile = async (
    payload: Partial<EmailSenderProfilePayload>,
    helpers: FormikHelpers<EmailSenderProfilePayload>,
  ) => {
    await updateEmailSenderProfileMutate({ payload, helpers });
  };

  const handleSetEmailSenderProfileAsDefault = async (
    payload: Partial<EmailSenderProfilePayload>,
  ) => {
    await setEmailSenderProfileAsDefaultMutate(payload.id);
  };

  const handleDeleteEmailSenderProfile = async (payload: Partial<EmailSenderProfilePayload>) => {
    await deleteEmailSenderProfileMutate(payload.id);
  };

  return (
    <Container maxW="640px">
      <Flex width="100%" marginBottom="3rem" justifyContent="space-between">
        <Stack>
          <Heading size="md" fontWeight="semibold">
            Sender Profiles
          </Heading>
          <BodyText>Standout in your users inbox with unique sender ids and profiles.</BodyText>
        </Stack>
        <Menu
          menuListProps={{
            placement: 'bottom-end',
          }}
          renderItem={(option, index) => <MenuItem key={`${index}`} {...option} />}
          menuButtonProps={{
            as: Button,
            size: 'sm',
            //@ts-ignore
            variantColor: 'blue',
            _focus: { boxShadow: 'none' },
            children: (
              <Stack isInline alignItems="center">
                <SmallText fontWeight="bold">Add sender profile</SmallText>
                <Icon size="1rem" name="chevron-down" />
              </Stack>
            ),
          }}
          options={[
            {
              children: (
                <Stack isInline alignItems="center">
                  <Icon name="smslogs" />
                  <SmallText>SMS Sender Profile</SmallText>
                </Stack>
              ),
              onClick: onOpenAddSMSSenderProfileModal,
            },
            {
              children: (
                <Stack isInline alignItems="center">
                  <Icon name="inbox-mail" />
                  <SmallText>Email Sender Profile</SmallText>
                </Stack>
              ),
              onClick: onOpenAddEmailSenderProfileModal,
            },
          ]}
        />
      </Flex>
      <Tabs variant="unstyled">
        <TabList rounded="4px" mb="2rem">
          <Tab
            height="2rem"
            rounded="4px"
            fontSize="0.875rem"
            _selected={{ fontWeight: '500', color: 'white', bg: 'blue.500' }}
          >
            SMS
          </Tab>
          <Tab
            height="2rem"
            rounded="4px"
            fontSize="0.875rem"
            _selected={{ fontWeight: '500', color: 'white', bg: 'blue.500' }}
          >
            Email
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <SMSSenderProfiles
              senderProfiles={smsSenderProfiles}
              senderProfileToEdit={smsSenderProfileToEdit}
              senderProfileToDelete={smsSenderProfileToDelete}
              setSenderProfileToEdit={setSMSSenderProfileToEdit}
              onAddSenderProfile={onOpenAddSMSSenderProfileModal}
              onUpdateSenderProfile={handleUpdateSMSSenderProfile}
              onDeleteSenderProfile={handleDeleteSMSSenderProfile}
              setSenderProfileToDelete={setSMSSenderProfileToDelete}
              isLoadingSenderProfiles={isLoadingSmsSenderProfiles}
              isUpdatingSenderProfile={isUpdatingSMSSenderProfile}
              isDeletingSenderProfile={isDeletingSMSSenderProfile}
              setSenderProfileToSetAsDefault={setSMSSenderProfileToSetAsDefault}
              senderProfileToSetAsDefault={smsSenderProfileToSetAsDefault}
              onSetSenderProfileAsDefult={handleSetSMSSenderProfileAsDefault}
              isSettingSenderProfileAsDefault={isSettingSMSSenderProfileAsDefault}
            />
          </TabPanel>
          <TabPanel>
            <EmailSenderProfiles
              senderProfiles={emailSenderProfiles}
              senderProfileToEdit={emailSenderProfileToEdit}
              senderProfileToDelete={emailSenderProfileToDelete}
              setSenderProfileToEdit={setEmailSenderProfileToEdit}
              onAddSenderProfile={onOpenAddEmailSenderProfileModal}
              onUpdateSenderProfile={handleUpdateEmailSenderProfile}
              onDeleteSenderProfile={handleDeleteEmailSenderProfile}
              setSenderProfileToDelete={setEmailSenderProfileToDelete}
              isLoadingSenderProfiles={isLoadingEmailSenderProfiles}
              isUpdatingSenderProfile={isUpdatingEmailSenderProfile}
              isDeletingSenderProfile={isDeletingEmailSenderProfile}
              setSenderProfileToSetAsDefault={setEmailSenderProfileToSetAsDefault}
              senderProfileToSetAsDefault={emailSenderProfileToSetAsDefault}
              onSetSenderProfileAsDefult={handleSetEmailSenderProfileAsDefault}
              isSettingSenderProfileAsDefault={isSettingEmailSenderProfileAsDefault}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
      {isAddSMSSenderProfileModalOpen && (
        <SMSSenderProfileModal
          isLoading={isSavingSMSSenderProfile}
          onSubmit={handleSaveSMSSenderProfile}
          isOpen={isAddSMSSenderProfileModalOpen}
          onClose={onCloseAddSMSSenderProfileModal}
        />
      )}
      {isAddEmailSenderProfileModalOpen && (
        <EmailSenderProfileModal
          isLoading={isSavingEmailSenderProfile}
          isOpen={isAddEmailSenderProfileModalOpen}
          onClose={onCloseAddEmailSenderProfileModal}
          onSubmit={handleSaveEmailSenderProfile}
        />
      )}
    </Container>
  );
};
