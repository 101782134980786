import Pusher, { PresenceChannel } from 'pusher-js';
import { buildConversationUrl } from './api-client';

let pusher: Pusher;

const initializePusher = (token?: string, userID?: string) => {
  if (!pusher) {
    pusher = new Pusher(process.env.REACT_APP_PUSHER_APP_KEY || '', {
      forceTLS: true,
      cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
      authEndpoint: buildConversationUrl('auth/websocket'),
      auth: {
        headers: { authorization: token },
      },
    });

    pusher.connection.bind('error', function (error: any) {
      console.error('connection error', error);
    });

    pusher.subscribe(`private-${userID}`);
  }
};

const subscribeToPresenceChannel = (channelName: string) => {
  const channel = pusher.channel(channelName) as PresenceChannel;

  if (channel) {
    return channel;
  }

  return pusher.subscribe(channelName) as PresenceChannel;
};

export { pusher, initializePusher, subscribeToPresenceChannel };
