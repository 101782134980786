import {
  Alert,
  AlertDescription,
  Badge,
  Box,
  Flex,
  Heading,
  Icon,
  IconButton,
  PseudoBox,
  Stack,
  Tooltip,
  useDisclosure,
  useToast,
} from '@chakra-ui/core';
import { available_properties } from 'app/authenticated-app/lists/list.data';
import { selectLists } from 'app/authenticated-app/lists/lists.selectors';
import { ListService } from 'app/authenticated-app/lists/service';
import { BodyText, Button, ContentWrapper, ToastBox } from 'app/components';
import React, { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { ColumnItem } from './column-item';
import { ConfirmMergeModal } from './confirm-merge-modal';
import { MergeInstructionsModal } from './merge-instructions-modal';
import Cookies from 'js-cookie';
import { track } from 'utils/segment';

export const ListMerge = () => {
  const toast = useToast();
  const history = useHistory();
  const lists = useSelector(selectLists) as any;
  const {
    isOpen: isConfirmAlertOpen,
    onClose: onCloseConfirmAlert,
    onOpen: onOpenConfirmAlert,
  } = useDisclosure();
  const {
    isOpen: isInstructionsModalOpen,
    onClose: onCloseInstructionsModal,
    onOpen: onOpenInstructionsModal,
  } = useDisclosure();
  const { base_list_id, head_list_id } =
    useParams<{ base_list_id: string; head_list_id: string }>();

  const base_list = lists[base_list_id];
  const head_list = lists[head_list_id];

  const [isMerging, setIsMerging] = useState(false);
  const [columnMergeError, setColumnMergeError] = useState(false);
  const [baseColumnWithError, setBaseColumnWithError] = useState<string | undefined>();
  const [newListColumns, setNewListColumns] = useState(
    base_list?.columns?.map((column: any) => ({ base: column })) ?? [],
  );
  const [headListColumns, setHeadListColumns] = useState(head_list?.columns ?? []);

  const handleSetMergeErrorState = (id: string) => {
    setColumnMergeError(true);
    setBaseColumnWithError(id);
  };

  const handleClearMergeErrorState = () => {
    setColumnMergeError(false);
    setBaseColumnWithError(undefined);
  };

  const handleRemoveColumn = (column: any, i: number) => {
    const x = newListColumns
      .map((c: any, index: number) => {
        if (i === index) {
          const { base } = column;
          if (base) {
            return { base };
          }
          return null;
        }
        return c;
      })
      .filter((item: any) => !!item);
    setHeadListColumns([...headListColumns, column.head]);
    setNewListColumns(x);
  };

  const handleDragUpdate = (result: any) => {
    if (!result.destination) return;
    if (result.destination.droppableId === 'head-columns') return;

    setTimeout(() => {
      handleClearMergeErrorState();
    }, 1000);

    const draggableItemId = result?.draggableId;
    const headColumn = head_list?.columns?.find((column: any) => column.id === draggableItemId);
    const baseColumn = base_list?.columns?.find(
      (column: any) => column.id === result.destination.droppableId,
    );

    if (baseColumn && headColumn.type.toLowerCase() !== baseColumn.type.toLowerCase()) {
      handleSetMergeErrorState(baseColumn.id);
    }
  };

  const handleDragEnd = (result: any) => {
    if (!result.destination) return;
    if (result.reason !== 'DROP') return;
    if (result.destination.droppableId === 'head-columns') return;

    const draggableItemId = result.draggableId;

    if (result.destination.droppableId === 'new-columns') {
      // use draggableId to find head column
      const head = head_list?.columns.find((column: any) => column.id === draggableItemId);
      const newColumn = { head, base: null };

      // add head column to list of base columns
      setNewListColumns([...newListColumns, newColumn]);
      setHeadListColumns(headListColumns.filter((column: any) => column.id !== draggableItemId));
    } else {
      const updatedColumns = newListColumns;

      // use draggableId to find head column
      const head = head_list?.columns.find((column: any) => column.id === draggableItemId);

      // use result.destination.droppableId to find base column
      const columnToUpdateIndex = newListColumns.findIndex(
        (column: any) => column.base.id === result.destination.droppableId,
      );

      //merge head column with base column
      updatedColumns[columnToUpdateIndex] = {
        ...updatedColumns[columnToUpdateIndex],
        head,
      };
      setNewListColumns(updatedColumns);
      setHeadListColumns(headListColumns.filter((column: any) => column.id !== draggableItemId));
    }
    setTimeout(() => {
      handleClearMergeErrorState();
    }, 1000);
  };

  const handleMergeList = async () => {
    const columns = newListColumns.map((item: any) => ({
      base: item?.base?.id,
      head: item?.head?.id ?? null,
    }));
    const payload = {
      columns,
      base: base_list_id,
      head: head_list_id,
    };

    try {
      setIsMerging(true);
      const { base_list_id } = await ListService.mergeLists(payload);
      setIsMerging(false);
      toast({
        position: 'bottom-left',
        render: ({ onClose }) => (
          <ToastBox status="success" onClose={onClose} message="Lists merged succesfully" />
        ),
      });
      Cookies.set('merge_list_base_list_id', base_list_id);
      onCloseConfirmAlert();
      history.push(`/s/lists/lists`);
      track('Merged lists', {
        base_list_id,
        head_list_id,
      });
      window.location.reload();
    } catch (error: any) {
      setIsMerging(false);
      toast({
        duration: null,
        position: 'bottom-left',
        render: ({ onClose }) => <ToastBox onClose={onClose} message={error?.message ?? error} />,
      });
    }
  };

  useEffect(() => {
    const merge_instructions_modal_display = Cookies.get('merge_instructions_modal_display');

    if (
      base_list_id &&
      head_list_id &&
      (!merge_instructions_modal_display || merge_instructions_modal_display === 'yes')
    ) {
      onOpenInstructionsModal();
    }
  }, [base_list_id, head_list_id, onOpenInstructionsModal]);

  return (
    <ContentWrapper>
      <Box py={8} maxW="1024px" mx="auto" px={4}>
        <Stack spacing={4}>
          <Flex alignItems="center" justifyContent="space-between">
            <Box>
              <Heading size="lg">Compare & Merge Lists</Heading>
              <BodyText color="gray.500">
                Compare list columns and merge the head list into the base list.
              </BodyText>
            </Box>
            <Button
              size="sm"
              leftIcon="info-outline"
              variant="outline"
              onClick={onOpenInstructionsModal}
            >
              Merge instructions
            </Button>
          </Flex>
          <Flex
            px={4}
            height="60px"
            rounded="4px"
            borderWidth="1px"
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack isInline alignItems="center">
              <Icon name="merge" />
              <Stack
                px={2}
                isInline
                height="30px"
                rounded="4px"
                borderWidth="1px"
                alignItems="center"
              >
                <BodyText fontWeight="500">Base:</BodyText>
                <BodyText>{base_list?.name}</BodyText>
              </Stack>
              <Icon name="arrow-back" />
              <Stack
                px={2}
                isInline
                height="30px"
                rounded="4px"
                borderWidth="1px"
                alignItems="center"
              >
                <BodyText fontWeight="500">Head:</BodyText>
                <BodyText>{head_list?.name}</BodyText>
              </Stack>
            </Stack>
            <Button
              size="sm"
              variantColor="blue"
              onClick={onOpenConfirmAlert}
              isDisabled={!newListColumns.some((item: any) => !!item.head)}
            >
              Merge lists
            </Button>
          </Flex>
          <Alert py={2} rounded="8px" status="info">
            <AlertDescription>
              <BodyText fontWeight="500">
                To get started drag a column from the right list (head) onto to the left list (base)
              </BodyText>
            </AlertDescription>
          </Alert>
          <DragDropContext onDragEnd={handleDragEnd} onDragUpdate={handleDragUpdate}>
            <Flex justifyContent="space-between">
              <Stack width={['100%', '49%', '49%']} p={4} rounded="4px" borderWidth="1px">
                <Stack>
                  <BodyText color="gray.500" fontSize="1.5rem" fontWeight="500">
                    {base_list?.name} list (Base)
                  </BodyText>
                  <BodyText fontSize="1rem" fontWeight="500">
                    Columns
                  </BodyText>
                </Stack>
                <Droppable droppableId="new-columns" isDropDisabled={columnMergeError}>
                  {(provided, snapshot) => (
                    <Stack
                      px={4}
                      py={8}
                      rounded="4px"
                      borderWidth="1.5px"
                      borderStyle="dashed"
                      borderColor="gray.300"
                      ref={provided.innerRef}
                      bg={snapshot.isDraggingOver ? 'gray.100' : 'inherit'}
                      {...provided.droppableProps}
                    >
                      {newListColumns.map((column: any, index: number) => (
                        <Box key={`${column?.base?.id}-${index}`}>
                          <Droppable
                            isDropDisabled={columnMergeError}
                            droppableId={column?.base?.id ?? column?.head?.id}
                          >
                            {(provided, snapshot) => (
                              <PseudoBox
                                p={2}
                                rounded="4px"
                                borderWidth="1.5px"
                                borderStyle="dashed"
                                ref={provided.innerRef}
                                bg={snapshot.isDraggingOver ? 'gray.100' : 'inherit'}
                                borderColor={
                                  columnMergeError && column?.base?.id === baseColumnWithError
                                    ? 'red.500'
                                    : 'gray.300'
                                }
                                {...provided.droppableProps}
                              >
                                <Stack>
                                  {Object.keys(column).map(key => {
                                    if (column[key]) {
                                      const { label, type, id } = column[key] ?? {};
                                      return (
                                        <Flex
                                          px={2}
                                          key={id}
                                          bg="gray.50"
                                          height="32px"
                                          rounded="4px"
                                          alignItems="center"
                                          justifyContent="space-between"
                                        >
                                          <Stack isInline rounded="4px" alignItems="center">
                                            <Icon
                                              color="gray.500"
                                              name={available_properties[type]?.icon}
                                            />
                                            <BodyText fontWeight="500">{label}</BodyText>
                                          </Stack>
                                          <Stack isInline alignItems="center">
                                            <Badge fontSize="0.7rem" variantColor="green">
                                              {type}
                                            </Badge>
                                            {key === 'head' && (
                                              <Tooltip
                                                aria-label="Move column back"
                                                label={<BodyText>Move column back</BodyText>}
                                              >
                                                <IconButton
                                                  size="xs"
                                                  variant="ghost"
                                                  color="gray.500"
                                                  icon="arrow-forward"
                                                  aria-label="Move column back"
                                                  onClick={() => handleRemoveColumn(column, index)}
                                                />
                                              </Tooltip>
                                            )}
                                          </Stack>
                                        </Flex>
                                      );
                                    }
                                    return null;
                                  })}
                                </Stack>
                                {provided.placeholder}
                              </PseudoBox>
                            )}
                          </Droppable>
                        </Box>
                      ))}
                      {provided.placeholder}
                    </Stack>
                  )}
                </Droppable>
              </Stack>
              <Stack width={['100%', '49%', '49%']} p={4} borderWidth="1px" rounded="4px">
                <Stack>
                  <BodyText color="gray.500" fontSize="1.5rem" fontWeight="500">
                    {head_list?.name} list (Head)
                  </BodyText>
                  <BodyText fontSize="1rem" fontWeight="500">
                    Columns
                  </BodyText>
                </Stack>
                {!headListColumns.length ? (
                  <Heading fontWeight="400" py={4} size="sm">
                    No columns to merge
                  </Heading>
                ) : (
                  <Droppable droppableId="head-columns">
                    {provided => (
                      <Stack ref={provided.innerRef} {...provided.droppableProps}>
                        {headListColumns?.map((column: any, index: number) => (
                          <Box key={column.id}>
                            <Draggable index={index} draggableId={column.id}>
                              {provided => (
                                <ColumnItem
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  ref={provided.innerRef}
                                  column={column}
                                  onChange={() => {}}
                                />
                              )}
                            </Draggable>
                          </Box>
                        ))}
                        {provided.placeholder}
                      </Stack>
                    )}
                  </Droppable>
                )}
              </Stack>
            </Flex>
          </DragDropContext>
        </Stack>
      </Box>
      {isConfirmAlertOpen && (
        <ConfirmMergeModal
          isLoading={isMerging}
          base_list={base_list}
          head_list={head_list}
          onConfirm={handleMergeList}
          isOpen={isConfirmAlertOpen}
          onClose={onCloseConfirmAlert}
        />
      )}
      {isInstructionsModalOpen && (
        <MergeInstructionsModal
          isOpen={isInstructionsModalOpen}
          onClose={onCloseInstructionsModal}
        />
      )}
    </ContentWrapper>
  );
};
