import { Box, Checkbox, Divider, FormControl, Grid, Icon } from '@chakra-ui/core';
import { Input, Select } from 'app/components';
import React, { useContext } from 'react';
import { AppParamsContext } from '../../../contexts/app-contexts/params.context';
import { useFormik } from 'formik';
import * as yup from 'yup';

const Authorization = () => {
  const {
    authenticationType,
    setAuthenticationType,
    basicAuthUserName,
    setBasicAuthUserName,
    basicAuthPassword,
    setBasicAuthPassword,
    isPasswordVisible,
    setIsPasswordVisible,
  } = useContext(AppParamsContext);

  const autheticationTypes = [
    { id: 'no_auth', request_name: 'No Auth' },
    { id: 'basic_auth', request_name: 'Basic Auth' },
  ];

  const initialValues = {
    name: '',
    password: '',
  };

  const CustomAppValidationSchema = yup.object().shape({
    name: yup.string().nullable(),
    password: yup.string().nullable(),
  });

  const submit = async () => {};

  const formik = useFormik({
    validationSchema: CustomAppValidationSchema,
    initialValues,
    onSubmit: submit,
  });

  const { errors, handleReset, touched } = formik;

  const changeHandler = (e: any, setter: Function) => {
    setter(e.target.value);
  };

  const checkBoxchangeHandler = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  return (
    <Grid templateColumns="repeat(1, 50fr 1fr 80fr)" gap={'0.5rem'} mt={0} minH={'10rem'}>
      <Box marginTop="0.75rem" className="custom-app-field" width="100%">
        <FormControl>
          <Select
            color="black"
            label="Authorization Type"
            value={authenticationType || ''}
            onChange={(e: any) => setAuthenticationType(e.target.value)}
          >
            {autheticationTypes.map((i: any) => (
              <option key={i.id} value={i.id}>
                {i.request_name}
              </option>
            ))}
          </Select>
        </FormControl>
      </Box>

      <Divider orientation="vertical" />
      {authenticationType === 'no_auth' && (
        <Box marginTop="0.1rem">
          <Box className="custom-app--authorisation-text">
            This app would not use any authorization.
          </Box>
        </Box>
      )}
      {authenticationType === 'basic_auth' && (
        <Box marginTop="0.1rem">
          <Box className="custom-app--authorisation-text">
            This allows for the creation of a default connection to your app, used for testing the
            request(s) added in the next section.
          </Box>
          <Box
            className="custom-app--authorisation-text-secondary"
            marginTop={'0.2rem'}
            onClick={() => window.open('/s/lists/connections', '_blank')}
          >
            You could create more connections later.
            <Icon name="external-link" size="10px" mb={'0.15rem'} />
          </Box>
          <Box marginTop="1.4rem" className="custom-app-field" width="100%">
            <Input
              label="Username"
              isInvalid={touched.name && !!errors.name}
              mb="5px"
              name="name"
              defaultValue={basicAuthUserName}
              onBlur={(e: any) => changeHandler(e, setBasicAuthUserName)}
              onReset={handleReset}
              errorMessage={errors.name}
              placeholder="Username"
            />
          </Box>
          <Box marginTop="1.7rem" className="custom-app-field" width="100%">
            <Input
              label="Password"
              isInvalid={touched.password && !!errors.password}
              mb="5px"
              name="password"
              defaultValue={basicAuthPassword}
              onBlur={(e: any) => changeHandler(e, setBasicAuthPassword)}
              onReset={handleReset}
              errorMessage={errors.password}
              type={!isPasswordVisible ? 'password' : ''}
              placeholder="Password"
            />
          </Box>
          <Box marginTop={'1.2rem'}>
            <Checkbox
              defaultIsChecked={isPasswordVisible}
              onChange={() => checkBoxchangeHandler()}
              name="name"
              id="custom-app--params-checkbox-input"
            >
              {' '}
              <span className="custom-app--authorisation-text">Show Password</span>{' '}
            </Checkbox>
          </Box>
        </Box>
      )}
    </Grid>
  );
};

export default Authorization;
