import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';

type Props = { isLoggedIn?: boolean; hasPageAccess?: boolean } & RouteProps;

export const AuthRoute = ({ isLoggedIn, ...rest }: Props) => {
  return isLoggedIn ? <Redirect to="/s/lists/lists" /> : <Route {...rest} />;
};

export function ProtectedRoute({ isLoggedIn, hasPageAccess = true, ...rest }: Props) {
  return isLoggedIn ? (
    hasPageAccess ? (
      <Route {...rest} />
    ) : (
      <Redirect to="/s/lists/lists" />
    )
  ) : (
    <Redirect to="/login" />
  );
}
