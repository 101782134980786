import { Button, ButtonProps } from '@chakra-ui/core';
import React from 'react';

export const TemplateSuggestionButton = (props: ButtonProps) => {
  return (
    <Button
      size="xs"
      bg="white"
      width="150px"
      rounded="100px"
      fontWeight="400"
      textAlign="left"
      overflow="hidden"
      borderWidth="1px"
      variant="unstyled"
      borderStyle="solid"
      whiteSpace="nowrap"
      px="0.5rem!important"
      borderColor="gray.200"
      justifyContent="flex-start"
      transition="all 0.2s ease-in"
      style={{
        textOverflow: 'ellipsis',
      }}
      _hover={{
        bg: 'blue.500',
        color: 'white',
        borderColor: 'blue.500',
      }}
      {...props}
    />
  );
};
