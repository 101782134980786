import {
  Flex,
  Icon,
  MenuButtonProps,
  Spinner,
  Stack,
  useDisclosure,
  useToast,
} from '@chakra-ui/core';
import { selectListsById, selectSegments } from 'app/authenticated-app/lists';
import { toggleAutomationPanel } from 'app/authenticated-app/lists/lists.reducer';
import { listEmailSenderProfiles } from 'app/authenticated-app/settings';
import { EmailSenderProfilePayload } from 'app/authenticated-app/settings/component/sender-profiles/email-sender-profile-modal';
import { Button, InfoModal, Menu, MenuItem, SmallText, ToastBox } from 'app/components';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { sendAmplitudeData } from 'utils';
import { addCampaign, getEmailDomains } from '../../../../../marketing/campaigns';
import { CampaignPayload, EmailDomain } from '../../../../../marketing/campaigns/campaigns.types';

export const ListHeadingActionsButton = (
  props: Partial<MenuButtonProps> & { type?: 'smart_list' | 'list'; id?: string },
) => {
  const { type, id, ...rest } = props;

  const toast = useToast();
  const history = useHistory();
  const dispatch = useDispatch();
  const lists = useSelector(selectListsById);
  const segments = useSelector(selectSegments);
  const {
    isOpen: isNoListInfoModalOpen,
    onClose: onCloseNoListInfoModal,
    onOpen: onOpenNoListInfoModal,
  } = useDisclosure();
  const {
    isOpen: isNoEmailDomainModalOpen,
    onClose: onCloseNoEmailDomainModal,
    onOpen: onOpenNoEmailDomainModal,
  } = useDisclosure();

  const [isCreatingEmailCampaign, setIsCreatingEmailCampaign] = useState(false);

  const { data: emailSenderProfiles } = useQuery<EmailSenderProfilePayload[] | undefined>(
    ['email-sender-profiles'],
    listEmailSenderProfiles,
  );

  const { data: emailDomains } = useQuery<EmailDomain[]>('email-domains', getEmailDomains);

  async function handleCreateEmailDraftEmailCampaign() {
    try {
      const payload: Partial<CampaignPayload> = {
        name: 'New campaign',
        state: 'pristine',
        type: 'email',
        via: 'email',
        from_name: emailSenderProfiles?.find(item => item.is_default)?.from_name,
        sender_id: emailSenderProfiles?.find(item => item.is_default)?.from_email ?? '',
        reply_to: emailSenderProfiles?.find(item => item.is_default)?.reply_to ?? '',
      };
      setIsCreatingEmailCampaign(true);
      const { campaign }: any = await dispatch(addCampaign(payload));
      setIsCreatingEmailCampaign(false);
      sendAmplitudeData('emailCampaignSavedAsDraft', { data: payload });
      const url =
        type && id
          ? `/s/marketing/email-campaigns/${campaign.id}?type=${type}&id=${id}`
          : `/s/marketing/email-campaigns/${campaign.id}`;

      history.push(url);
    } catch (error: any) {
      setIsCreatingEmailCampaign(false);
      toast({
        position: 'bottom-left',
        render: ({ onClose }) => <ToastBox onClose={onClose} message={error} />,
      });
    }
  }

  function handleCreateOneTimeSMSCampaign() {
    if (!lists.length && !segments.length) {
      onOpenNoListInfoModal();
    } else {
      const url =
        type && id
          ? `/s/marketing/sms-campaigns/new/one-time-campaign?type=${type}&id=${id}`
          : `/s/marketing/sms-campaigns/new/one-time-campaign`;

      history.push(url);
    }
  }

  function handleOpenSidePanel() {
    dispatch(
      toggleAutomationPanel({
        automationPanelOpen: true,
        automationPanelActiveSection: 'airtime-topup',
      }),
    );
  }

  async function handleCreateEmailCampaign() {
    if (!lists.length && !segments.length) {
      onOpenNoListInfoModal();
    } else if (
      !emailDomains?.length ||
      !emailDomains?.filter(domain => domain.status === 'verified').length
    ) {
      onOpenNoEmailDomainModal();
    } else {
      handleCreateEmailDraftEmailCampaign();
    }
  }

  return (
    <>
      <Menu
        renderItem={(option, index) => <MenuItem key={`${index}`} {...option} />}
        menuButtonProps={{
          ...rest,
          size: 'xs',
          as: Button,
          //@ts-ignore
          variant: 'ghost',
          //@ts-ignore
          leftIcon: 'send',
          children: 'Actions',
          fontWeight: 'normal',
          _focus: { boxShadow: 'none' },
          _hover: {
            backgroundColor: '#eeeeee',
          },
        }}
        options={[
          {
            children: (
              <Stack isInline alignItems="center">
                <Icon name="smslogs" />
                <SmallText>SMS Broadcast</SmallText>
              </Stack>
            ),
            onClick: handleCreateOneTimeSMSCampaign,
          },
          {
            children: (
              <Flex alignItems="center" justifyContent="space-between">
                <Stack isInline alignItems="center">
                  <Icon name="inbox-mail" />
                  <SmallText>Email Broadcast</SmallText>
                </Stack>
                {isCreatingEmailCampaign && <Spinner size="sm" color="blue.500" />}
              </Flex>
            ),
            onClick: handleCreateEmailCampaign,
          },
          {
            children: (
              <Flex alignItems="center" justifyContent="space-between">
                <Stack isInline alignItems="center">
                  <Icon name="mobile" />
                  <SmallText>Airtime Rewards</SmallText>
                </Stack>
              </Flex>
            ),
            onClick: handleOpenSidePanel,
          },
        ]}
      />
      {isNoListInfoModalOpen && (
        <InfoModal
          isOpen={isNoListInfoModalOpen}
          onClose={onCloseNoListInfoModal}
          heading="You have no lists created"
          caption="Create a list to get started with sending campaigns!"
          buttonProps={{
            children: 'Create list',
            onClick: () => history.push('/s/lists/lists/new'),
          }}
        />
      )}
      {isNoEmailDomainModalOpen && (
        <InfoModal
          isOpen={isNoEmailDomainModalOpen}
          onClose={onCloseNoEmailDomainModal}
          heading="Authenicate your organization's email domain"
          caption="Authenticate your organization domains and start sending email campaigns with your organisation emails."
          buttonProps={{
            children: 'Authenticate domain',
            onClick: () => history.push('/s/settings/organisation/sending-domains'),
          }}
        />
      )}
    </>
  );
};
