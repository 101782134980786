import React, { ChangeEvent } from 'react';
import { Box, Divider, Icon } from '@chakra-ui/core';
import { ListAutomation } from 'app/authenticated-app/lists/lists.types';
import { Select } from 'app/components';
import { DateFieldComponent } from '../../../../../../../../components/cell-properties';

interface Props {
  automation: ListAutomation | null;
  triggerType: string;
  handleTriggerTypeChanged: (e: ChangeEvent<HTMLSelectElement>) => void;
  handleScheduleIntervalChanged: (e: ChangeEvent<HTMLSelectElement>) => void;
  handleAutomationUpdate: Function;
}

export const AutomationTriggerSection = (props: Props) => {
  const {
    automation,
    triggerType,
    handleTriggerTypeChanged,
    handleScheduleIntervalChanged,
  } = props;

  const tzDiff = -(new Date().getTimezoneOffset() / 60);

  return (
    <Box>
      <Box my="1rem" mb="0.5rem" fontWeight="500" fontSize="12px">
        TRIGGER
      </Box>
      <Box
        borderRadius="6px"
        border="1px solid #DADEE3"
        padding="1rem 1rem"
        backgroundColor="#FFFFFF"
      >
        <Box fontWeight="500" fontSize="14px">
          <Icon
            // @ts-ignore
            name="inbox-calendar"
            mr="0.5rem"
          />
          {triggerType === 'schedule' && 'At a scheduled time'}
          {triggerType === 'row.update' && 'When a row is updated'}
          {triggerType === 'form.submit' && 'When a form is submitted'}
        </Box>
        <Divider my="0.5rem" />
        <Box>
          <Select
            size="sm"
            label="Trigger Type"
            mt="1rem"
            mb="1rem"
            onChange={handleTriggerTypeChanged}
            value={triggerType}
            autoFocus
          >
            <option value="schedule">At a scheduled time</option>
            {/*<option value="row.create">When a record is created</option>*/}
            <option value="row.update">When a record is updated</option>
            <option value="form.submit">When a form is submitted</option>
            {/*<option value="condition">When a record matches conditions</option>*/}
          </Select>

          {triggerType === 'schedule' && props.automation && (
            <>
              <Box fontSize="14px" color="#A5ABB3">
                Dates and times are in your time zone: GMT{tzDiff > 0 ? '+' : '-'}
                {tzDiff}
              </Box>

              <Select
                size="sm"
                label="Interval"
                mt="1rem"
                mb="1rem"
                // @ts-ignore
                value={automation?.schedule_interval || ''}
                onChange={handleScheduleIntervalChanged}
              >
                <option value="daily">Daily</option>
                <option value="weekly">Weekly</option>
                <option value="bi-weekly">Bi-weekly</option>
                <option value="monthly">Monthly</option>
                <option value="one-time">One Time</option>
              </Select>

              <DateFieldComponent
                value={automation?.schedule_start}
                mt="1rem"
                inputProps={{
                  size: 'sm',
                  label: automation?.schedule_interval === 'one-time' ? 'At' : 'Starting',
                  width: '100%',
                }}
                updateCellValue={(value: any) => {
                  props.handleAutomationUpdate({
                    schedule_start: value,
                  });
                }}
                column={{
                  customization: {
                    include_time: true,
                    date_format: 'MMMM Do YYYY    ',
                  },
                }}
              />
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};
