import { Box, ModalBody, Stack } from '@chakra-ui/core';
import { Button, Input, ModalContainer, ModalContainerOptions } from 'app/components';
import { FormikConfig, useFormik } from 'formik';
import React, { useRef } from 'react';
import * as yup from 'yup';

export type EmailSenderProfilePayload = {
  from_name: string;
  from_email: string;
  reply_to?: string;
  id?: string;
  is_default?: boolean;
};

export const EmailSenderProfileModal = (
  props: ModalContainerOptions & {
    isLoading: boolean;
    initialValues?: EmailSenderProfilePayload;
    onSubmit: FormikConfig<EmailSenderProfilePayload>['onSubmit'];
  },
) => {
  const { isOpen, onClose, initialValues, onSubmit, isLoading } = props;

  const initialInputRef = useRef<HTMLInputElement>(null);

  const title = !initialValues ? 'Add sender profile' : 'Edit sender profile';
  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
  } = useFormik<EmailSenderProfilePayload>({
    onSubmit,
    validationSchema: yup.object().shape({
      from_name: yup.string().required('Sender name is required'),
      from_email: yup.string().required('Sender email address is required'),
    }),
    initialValues: initialValues ?? {
      from_name: '',
      from_email: '',
      reply_to: '',
    },
  });

  return (
    <ModalContainer
      showCloseButton
      title={title}
      isOpen={isOpen}
      onClose={onClose}
      initialFocusRef={initialInputRef}
    >
      <ModalBody pb="1rem">
        <form onSubmit={handleSubmit}>
          <Stack spacing="2rem" alignItems="flex-start">
            <Box width="100%">
              <Input
                type="text"
                name="from_name"
                ref={initialInputRef}
                onChange={handleChange}
                value={values.from_name}
                label="Sender name ('From')"
                placeholder="James from Company"
                errorMessage={errors.from_name}
                isInvalid={!!touched.from_name && !!errors.from_name}
              />
            </Box>
            <Box width="100%">
              <Input
                type="email"
                name="from_email"
                onChange={handleChange}
                value={values.from_email}
                label="Sender email address"
                placeholder="example@company.com"
                errorMessage={errors.from_email}
                isInvalid={!!touched.from_email && !!errors.from_email}
              />
            </Box>
            <Box width="100%">
              <Input
                type="email"
                name="reply_to"
                onChange={handleChange}
                value={values.reply_to}
                label="Reply-to address (optional)"
                placeholder="example@company.com"
                errorMessage={errors.reply_to}
                isInvalid={!!touched.reply_to && !!errors.reply_to}
              />
            </Box>
            <Button size="sm" type="submit" variantColor="blue" isLoading={isLoading}>
              Save sender profile
            </Button>
          </Stack>
        </form>
      </ModalBody>
    </ModalContainer>
  );
};
