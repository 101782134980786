import React, { ChangeEvent, useState } from 'react';
import { Box, IconButton } from '@chakra-ui/core';
import { capitalize } from 'lodash';
import { Checkbox } from '@chakra-ui/core/dist';

interface Props {
  category: 'phone' | 'person' | 'company';
  enrichmentSettings: any;
  setActiveCategory: Function;
  updateEnrichmentSettings: Function;
  options: { [key: string]: any };
}

export const ConfigureEnrichmentCategory = (props: Props) => {
  const options = props.options;
  const settings = props.enrichmentSettings;

  const [tempValue, setTempValue] = useState<string[]>(settings);

  const handleChange = (key: string, isChecked: boolean) => {
    if (isChecked) {
      setTempValue([...tempValue, key]);
    } else {
      setTempValue(tempValue.filter((i: string) => i !== key));
    }
  };

  const handleSettingsUpdate = () => {
    props.updateEnrichmentSettings({
      [props.category]: tempValue,
    });
  };

  return (
    <>
      <Box
        paddingTop="0.2rem"
        paddingBottom="1rem"
        display="flex"
        flexDirection="row"
        alignItems="center"
        borderBottom=" 1px solid #EBEEF2"
        width="100%"
        justifyContent="space-between"
      >
        <Box display="flex" alignItems="center">
          <IconButton
            variant="ghost"
            aria-label="button"
            icon="chevron-left"
            onClick={() => {
              handleSettingsUpdate();
              props.setActiveCategory(null);
            }}
            fontSize="18px"
            mr="1rem"
            size="xs"
            isRound
          />
          <Box fontWeight="500">{capitalize(props.category)} enrichment details</Box>
        </Box>
      </Box>

      <Box marginY="1rem">
        <Box display="flex" flexDirection="column">
          {Object.values(options).map((i: any, index: number) => (
            <Checkbox
              key={index}
              my="0.5rem"
              size="sm"
              isChecked={tempValue.includes(i.key)}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                handleChange(i.key, event.target.checked)
              }
            >
              <Box fontSize="14px" color="rgba(27, 43, 65, 0.72)">
                {i.label}
              </Box>
            </Checkbox>
          ))}
        </Box>
      </Box>
    </>
  );
};
