import { Box, Flex, IconButton, Stack } from '@chakra-ui/core';
import { selectListDuplicateJobData } from 'app/authenticated-app/lists/lists.slice';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { DuplicateGroups } from './screens/DuplicateGroups';
import { SelectPivotColumns } from './screens/SelectPivotColumns';

export const DuplicateCheck: React.FC<{
  setActiveSection: React.Dispatch<
    React.SetStateAction<'enrichment' | 'automation' | 'airtime-topup' | 'duplicate-check' | null>
  >;
}> = ({ setActiveSection }) => {
  const listDuplicateJobData = useSelector(selectListDuplicateJobData);

  const [pivotColumns, setPivotColumns] = useState<string[]>([]);
  const [section, setSection] =
    useState<'select-pivot-columns' | 'duplicate-groups'>('select-pivot-columns');

  const handleGoBack = () => {
    if (section === 'duplicate-groups') {
      setSection('select-pivot-columns');
    } else {
      setActiveSection(null);
    }
  };

  useEffect(() => {
    if (listDuplicateJobData.object_id) {
      setSection('duplicate-groups');
    }
  }, [listDuplicateJobData.object_id]);

  return (
    <Box
      width="100%"
      bg="#FFFFFF"
      rounded="6px"
      border="1px solid #DADEE3"
      padding="0.5rem 1.5rem 1rem"
    >
      <Flex
        mb="1rem"
        pb="1rem"
        pt="0.2rem"
        width="100%"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        borderBottom="1px solid #EBEEF2"
      >
        <Stack isInline alignItems="center">
          <IconButton
            isRound
            size="xs"
            mr="1rem"
            variant="ghost"
            fontSize="18px"
            aria-label="button"
            icon="chevron-left"
            onClick={handleGoBack}
          />
          <Box fontWeight="500">Duplicates Check</Box>
        </Stack>
      </Flex>
      {section === 'select-pivot-columns' && (
        <SelectPivotColumns
          setSection={setSection}
          pivotColumns={pivotColumns}
          setPivotColumns={setPivotColumns}
        />
      )}
      {section === 'duplicate-groups' && (
        <DuplicateGroups
          pivotColumns={pivotColumns}
          setPivotColumns={setPivotColumns}
          setActiveSection={setActiveSection}
        />
      )}
    </Box>
  );
};
