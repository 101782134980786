import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Flex,
  Link,
  SimpleGrid,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/core';
import { selectSegments, selectSmartLists } from 'app/authenticated-app/lists';
import { selectLists } from 'app/authenticated-app/lists/lists.slice';
import { BodyText, Button, PreTitle, SmallSubtitle } from 'app/components';
import { BarList } from 'app/components/BarList';
import country from 'country-list-js';
import isEmpty from 'lodash/isEmpty';
import React, { forwardRef, useMemo } from 'react';
import { Bar } from 'react-chartjs-2';
import ReactCountryFlag from 'react-country-flag';
import { useSelector } from 'react-redux';
import { getZonedTime, numberWithCommas } from 'utils';
import { track } from 'utils/segment';
import { CampaignData, EmailCampaignAnalyticsData } from '../../campaigns.types';
import { ClicksDownloadCard } from './ClicksDownloadCard';
import { EmailReportSectionHeader } from './EmailReportSectionHeader';
import * as C from './EmailReportSummaryCard';

type Props = Partial<
  Pick<EmailCampaignAnalyticsData, 'reports' | 'most_clicked_users' | 'most_opened_users'>
> & {
  campaign?: CampaignData;
  isGeneratingImage?: boolean;
  isExportSuccessToastOpen?: boolean;
  onCloseExportSuccessToast(): void;
  onOpenReportsDownloadModal(): void;
  onOpenComingSoonModal?(): void;
  cities?: {
    [key: string]: {
      city: string;
      count: number;
      country: string;
    };
  };
  countries?: {
    [key: string]: {
      count: number;
      country: string;
    };
  };
};

export const EmailAnalytics: React.FC<Props> = forwardRef(
  (
    {
      cities,
      reports,
      campaign,
      countries,
      isGeneratingImage,
      most_opened_users,
      most_clicked_users,
      isExportSuccessToastOpen,
      onCloseExportSuccessToast,
      onOpenReportsDownloadModal,
      onOpenComingSoonModal,
    },
    ref: any,
  ) => {
    const lists: any = useSelector(selectLists);
    const segments = useSelector(selectSegments);
    const smart_lists: any = useSelector(selectSmartLists);

    const audienceListName = useMemo(() => {
      const { table_id, segment_id, smart_list_id } = campaign ?? {};

      if (table_id) {
        return lists[table_id]?.name;
      }
      if (smart_list_id) {
        return smart_lists[smart_list_id]?.name;
      }
      if (segment_id) {
        return segments.find(segment => segment.id === segment_id)?.name;
      }
    }, [campaign, lists, smart_lists, segments]);

    const chartData = {
      labels: Object.keys(reports ?? {}).map(item => {
        return getZonedTime(item, 'dd/MM');
      }),
      datasets: [
        {
          barPercentage: 0.9,
          label: 'Emails opened',
          backgroundColor: '#9BE169',
          data: Object.keys(reports ?? {}).map(item => (reports ?? {})[item]?.total_opened),
        },
        {
          barPercentage: 0.9,
          label: 'Links clicked',
          backgroundColor: '#AF78FF',
          data: Object.keys(reports ?? {}).map(item => (reports ?? {})[item]?.total_clicked),
        },
      ],
    };

    const barChartOptions = {
      legend: {
        align: 'start',
        maxHeight: 200,
        labels: {
          boxWidth: 8,
          fontSize: 12,
          usePointStyle: true,
          fontColor: '#2B3A4B',
        },
      },
      scales: {
        xAxes: [
          {
            categoryPercentage: 0.05,
          },
        ],
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
            },
          },
        ],
      },
    };

    const handleViewUnsubscribersList = () => {
      track('Coming Soon Feature', {
        name: 'Unsubscribers list',
      });
      onOpenComingSoonModal?.();
    };

    return (
      <Box ref={ref}>
        <Box
          mb="2rem"
          p="1.5rem"
          bg="white"
          rounded="8px"
          boxShadow="0 1px 3px 0 rgba(0, 0, 0, 0.1),0 1px 2px 0 rgba(0, 0, 0, 0.06)"
        >
          <EmailReportSectionHeader title="Campaign Info" />
          <Flex justifyContent="space-between">
            <Stack>
              <Stack isInline alignItems="center">
                <PreTitle fontSize="0.875rem" top="2px" position="relative" color="gray.900">
                  Name:
                </PreTitle>
                <BodyText color="gray.500">{campaign?.name}</BodyText>
              </Stack>
              <Stack isInline alignItems="center">
                <PreTitle fontSize="0.875rem" top="2px" position="relative" color="gray.900">
                  Subject:
                </PreTitle>
                <BodyText color="gray.500">{campaign?.subject}</BodyText>
              </Stack>
              <Stack isInline alignItems="center">
                <PreTitle fontSize="0.875rem" top="2px" position="relative" color="gray.900">
                  Sent from:
                </PreTitle>
                <BodyText color="gray.500">{campaign?.sender_id}</BodyText>
              </Stack>
              <Stack isInline alignItems="center">
                <PreTitle fontSize="0.875rem" top="2px" position="relative" color="gray.900">
                  Reply to:
                </PreTitle>
                <BodyText color="gray.500">{campaign?.sender_id}</BodyText>
              </Stack>
              <Stack isInline alignItems="center">
                <PreTitle fontSize="0.875rem" top="2px" position="relative" color="gray.900">
                  List:
                </PreTitle>
                <BodyText color="gray.500">{audienceListName ?? '-'}</BodyText>
              </Stack>
              <Stack isInline alignItems="center">
                <PreTitle fontSize="0.875rem" top="2px" position="relative" color="gray.900">
                  Date created:
                </PreTitle>
                <BodyText color="gray.500">
                  {campaign?.created_datetime
                    ? getZonedTime(campaign.created_datetime, 'dd MMM yyyy, hh:mm a')
                    : '-'}
                </BodyText>
              </Stack>
            </Stack>
          </Flex>
        </Box>
        <Flex pb="2rem" flexWrap="wrap" justifyContent="space-between">
          <C.Card width={['100%', '48%', '19%']}>
            <C.Detail bg="white" borderTopRightRadius="8px" borderTopLeftRadius="8px">
              <C.DetailTitle>Sent</C.DetailTitle>
              <C.DetailValue>{`${numberWithCommas(campaign?.total_sent)}`}</C.DetailValue>
            </C.Detail>
          </C.Card>
          <C.Card width={['100%', '48%', '19%']}>
            <C.Detail bg="white" borderTopRightRadius="8px" borderTopLeftRadius="8px">
              <C.DetailTitle>Delivered</C.DetailTitle>
              <C.DetailValue>{`${numberWithCommas(campaign?.total_delivered)}`}</C.DetailValue>
            </C.Detail>
            <C.Detail bg="gray.100" borderBottomRightRadius="8px" borderBottomLeftRadius="8px">
              <Stack isInline alignItems="center">
                <C.DetailTitle>Delivery rate</C.DetailTitle>
                <C.DetailTooltip
                  label="Ratio between delivered and sent"
                  aria-label="Ratio between delivered and sent"
                />
              </Stack>
              <C.DetailValue>
                {(((campaign?.total_delivered || 0) / (campaign?.total_sent || 1)) * 100).toFixed(
                  1,
                )}
                %
              </C.DetailValue>
            </C.Detail>
          </C.Card>
          <C.Card width={['100%', '48%', '19%']}>
            <C.Detail bg="white" borderTopRightRadius="8px" borderTopLeftRadius="8px">
              <C.DetailTitle>Opened</C.DetailTitle>
              <C.DetailValue>{`${numberWithCommas(campaign?.total_opened)}`}</C.DetailValue>
            </C.Detail>
            <C.Detail bg="gray.100" borderBottomRightRadius="8px" borderBottomLeftRadius="8px">
              <Stack isInline alignItems="center">
                <C.DetailTitle>Open rate</C.DetailTitle>
                <C.DetailTooltip
                  label="Ratio between opened and delivered"
                  aria-label="Ratio between opened and delivered"
                />
              </Stack>
              <C.DetailValue>
                {(((campaign?.total_opened || 0) / (campaign?.total_delivered || 1)) * 100).toFixed(
                  1,
                )}
                %
              </C.DetailValue>
            </C.Detail>
          </C.Card>
          <C.Card width={['100%', '48%', '19%']}>
            <C.Detail bg="white" borderTopRightRadius="8px" borderTopLeftRadius="8px">
              <C.DetailTitle>Clicked</C.DetailTitle>
              <C.DetailValue>{`${numberWithCommas(campaign?.total_clicked)}`}</C.DetailValue>
            </C.Detail>
            <C.Detail bg="gray.100" borderBottomRightRadius="8px" borderBottomLeftRadius="8px">
              <Stack isInline alignItems="center">
                <C.DetailTitle>Click rate</C.DetailTitle>
                <C.DetailTooltip
                  label="Ratio between clicked and delivered"
                  aria-label="Ratio between clicked and delivered"
                />
              </Stack>
              <C.DetailValue>
                {(
                  ((campaign?.total_clicked || 0) / (campaign?.total_delivered || 1)) *
                  100
                ).toFixed(1)}
                %
              </C.DetailValue>
            </C.Detail>
          </C.Card>
          <C.Card width={['100%', '48%', '19%']}>
            <C.Detail bg="white" borderTopRightRadius="8px" borderTopLeftRadius="8px">
              <C.DetailTitle>Bounced</C.DetailTitle>
              <C.DetailValue>{`${numberWithCommas(campaign?.total_bounced)}`}</C.DetailValue>
            </C.Detail>
            <C.Detail bg="gray.100" borderBottomRightRadius="8px" borderBottomLeftRadius="8px">
              <Stack isInline alignItems="center">
                <C.DetailTitle>Bounced rate</C.DetailTitle>
                <C.DetailTooltip
                  label="Ratio between bounced and sent. Note that bounced emails will not receive emails from your future campaigns."
                  aria-label="Ratio between bounced and sent"
                />
              </Stack>
              <C.DetailValue>
                {(((campaign?.total_bounced || 0) / (campaign?.total_sent || 1)) * 100).toFixed(1)}%
              </C.DetailValue>
            </C.Detail>
          </C.Card>
        </Flex>
        {campaign?.total_sent !== campaign?.count && (
          <Alert
            mb="2rem"
            rounded="8px"
            status="info"
            variant="subtle"
            flexDirection="column"
            alignItems="flex-start"
            boxShadow="0 1px 3px 0 rgba(0, 0, 0, 0.1),0 1px 2px 0 rgba(0, 0, 0, 0.06)"
          >
            <Stack mb={2} isInline alignItems="center">
              <AlertIcon size="20px" />
              <AlertTitle fontSize="md">Unsubscribers list</AlertTitle>
            </Stack>
            <AlertDescription fontSize="sm">
              You have recipients of this campaign in your unsubscribers list, hence the total
              number of sent emails is less than the total number of recipients in the list used for
              the campaign.{' '}
              <Button
                size="xs"
                variantColor="blue"
                variant="link"
                display="inline-block"
                onClick={handleViewUnsubscribersList}
              >
                View unsubscribers list
              </Button>
            </AlertDescription>
          </Alert>
        )}
        {!isEmpty(reports) && (
          <Box
            mb="2rem"
            p="1.5rem"
            bg="white"
            rounded="8px"
            boxShadow="0 1px 3px 0 rgba(0, 0, 0, 0.1),0 1px 2px 0 rgba(0, 0, 0, 0.06)"
          >
            <SmallSubtitle pb="1rem">Conversation rate</SmallSubtitle>
            <Bar data={chartData} options={barChartOptions} />
          </Box>
        )}

        {!!most_clicked_users?.length && (
          <SimpleGrid columns={!!campaign?.clicks?.summary?.length ? [1, 1, 2] : 1} spacing={4}>
            <Box
              mb="2rem"
              p="1.5rem"
              bg="white"
              rounded="8px"
              boxShadow="0 1px 3px 0 rgba(0, 0, 0, 0.1),0 1px 2px 0 rgba(0, 0, 0, 0.06)"
            >
              <EmailReportSectionHeader
                title="Click performance"
                description="See how many times contacts clicked each of the links in your email."
              />
              <Box>
                <Flex
                  mb="1rem"
                  pb="0.875rem"
                  alignItems="center"
                  borderBottomWidth="1px"
                  justifyContent="space-between"
                >
                  <PreTitle fontSize="0.75rem" fontWeight="bold" color="gray.400">
                    Link
                  </PreTitle>
                  <PreTitle fontSize="0.75rem" fontWeight="bold" color="gray.400">
                    Number of clicks
                  </PreTitle>
                </Flex>
                {most_clicked_users?.map(item => (
                  <Flex
                    mb="1rem"
                    pb="0.875rem"
                    alignItems="center"
                    borderBottomWidth="1px"
                    justifyContent="space-between"
                  >
                    <Link
                      width="400px"
                      target="_blank"
                      color="blue.500"
                      fontWeight="bold"
                      overflow="hidden"
                      whiteSpace="nowrap"
                      fontSize="0.875rem"
                      textDecoration="none"
                      style={{
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {item.url}
                    </Link>
                    <BodyText fontWeight="bold" color="gray.900">
                      {item.count}
                    </BodyText>
                  </Flex>
                ))}
              </Box>
              {!!most_clicked_users?.length && !isGeneratingImage && (
                <ClicksDownloadCard
                  title="Email Campaign Re-targeting"
                  onDownload={() => {
                    track('Clicked Download Clicks Report', { type: campaign?.type });
                    onOpenReportsDownloadModal();
                  }}
                  isExportSuccessToastOpen={isExportSuccessToastOpen}
                  onCloseExportSuccessToast={onCloseExportSuccessToast}
                  description=" The campaign had some engagement (link clicks), download the list of recipients that engaged with the campaign and re-target them with another campaign."
                />
              )}
            </Box>
            {!!campaign?.clicks?.summary?.length && (
              <Box
                mb="2rem"
                p="1.5rem"
                bg="white"
                rounded="8px"
                display="flex"
                flexDirection="column"
                boxShadow="0 1px 3px 0 rgba(0, 0, 0, 0.1),0 1px 2px 0 rgba(0, 0, 0, 0.06)"
              >
                <Flex flex="1" flexDirection="column">
                  <Tabs flex={1} variant="unstyled">
                    <Stack pb="1rem" isInline justifyContent="space-between">
                      <SmallSubtitle color="gray.900">Locations</SmallSubtitle>
                      <TabList position="relative" top="-8px">
                        <Tab
                          p="2"
                          mr="2"
                          bg="gray.50"
                          rounded="8px"
                          fontSize="0.875rem"
                          _hover={{
                            bg: 'gray.100',
                          }}
                          _focus={{
                            boxShadow: 'none',
                          }}
                          _selected={{
                            bg: 'gray.200',
                            fontWeight: '500',
                            boxShadow: 'none',
                          }}
                        >
                          Country
                        </Tab>
                        <Tab
                          p="2"
                          bg="gray.50"
                          rounded="8px"
                          fontSize="0.875rem"
                          _hover={{
                            bg: 'gray.100',
                          }}
                          _focus={{
                            boxShadow: 'none',
                          }}
                          _selected={{
                            bg: 'gray.200',
                            fontWeight: '500',
                            boxShadow: 'none',
                          }}
                        >
                          City
                        </Tab>
                      </TabList>
                    </Stack>
                    <TabPanels>
                      <TabPanel>
                        <BarList
                          color="blue.100"
                          data={Object.keys(countries ?? {}).map(item => {
                            const { count, country: countryIso2 } = countries?.[item] ?? {};
                            return {
                              key: countryIso2,
                              value: count ?? 0,
                              name: country.findByIso2(countryIso2 ?? '')?.name ?? '',
                              icon: <ReactCountryFlag countryCode={countryIso2 ?? ''} svg />,
                            };
                          })}
                        />
                      </TabPanel>
                      <TabPanel>
                        <BarList
                          color="blue.100"
                          data={Object.keys(cities ?? {}).map(item => {
                            const { count, country: countryIso2, city } = cities?.[item] ?? {};
                            return {
                              name: city ?? '',
                              value: count ?? 0,
                              key: `${city}-${countryIso2}`,
                              icon: <ReactCountryFlag countryCode={countryIso2 ?? ''} svg />,
                            };
                          })}
                        />
                      </TabPanel>
                    </TabPanels>
                  </Tabs>
                  {!!most_clicked_users?.length && !isGeneratingImage && (
                    <ClicksDownloadCard
                      title="Email Campaign Re-targeting"
                      onDownload={() => {
                        track('Clicked Download Clicks Report', { type: campaign?.type });
                        onOpenReportsDownloadModal();
                      }}
                      isExportSuccessToastOpen={isExportSuccessToastOpen}
                      onCloseExportSuccessToast={onCloseExportSuccessToast}
                      description=" The campaign had some engagement (link clicks), download the list of recipients that engaged with the campaign and re-target them with another campaign."
                    />
                  )}
                </Flex>
              </Box>
            )}
          </SimpleGrid>
        )}
        {!!most_opened_users?.length && (
          <Box
            mb="2rem"
            p="1.5rem"
            bg="white"
            rounded="8px"
            boxShadow="0 1px 3px 0 rgba(0, 0, 0, 0.1),0 1px 2px 0 rgba(0, 0, 0, 0.06)"
          >
            <EmailReportSectionHeader
              title="Subscribers with most opens"
              description="See which of your subscribers interracted with your email the most."
            />
            <Box>
              {most_opened_users?.map(item => (
                <Flex
                  mb="1rem"
                  pb="0.875rem"
                  alignItems="center"
                  borderBottomWidth="1px"
                  justifyContent="space-between"
                >
                  <BodyText fontWeight="bold" color="blue.500">
                    {item.email}
                  </BodyText>
                  <BodyText fontWeight="bold" color="gray.900">
                    {item.count}
                  </BodyText>
                </Flex>
              ))}
            </Box>
          </Box>
        )}
      </Box>
    );
  },
);
