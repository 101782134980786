import { Box } from '@chakra-ui/core';
import styled from '@emotion/styled';

export const LexicalEditorStyles = styled(Box)`
  .ltr {
    text-align: left;
  }
  .rtl {
    text-align: left;
  }
  .editor-container {
    border-radius: 4px;
    color: #000;
    position: relative;
    line-height: 20px;
    font-weight: 400;
    text-align: left;
    border: 1px solid #ebeef2;
  }

  .editor-inner span.editor-image {
    cursor: default;
    display: inline-block;
    position: relative;
    user-select: none;
  }

  .editor-inner .editor-image img {
    max-width: 100%;
    cursor: default;
  }

  .editor-inner .editor-image img.focused {
    outline: 2px solid rgb(60, 132, 244);
    user-select: none;
  }

  .editor-inner .editor-image img.focused.draggable {
    cursor: grab;
  }

  .editor-inner .editor-image img.focused.draggable:active {
    cursor: grabbing;
  }

  .editor-inner .editor-image .image-caption-container .tree-view-output {
    margin: 0;
    border-radius: 0;
  }

  .editor-inner .editor-image .image-caption-container {
    display: block;
    position: absolute;
    bottom: 4px;
    left: 0;
    right: 0;
    padding: 0;
    margin: 0;
    border-top: 1px solid #fff;
    background-color: rgba(255, 255, 255, 0.9);
    min-width: 100px;
    color: #000;
    overflow: hidden;
  }

  .editor-inner .editor-image .image-caption-button {
    display: block;
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
    width: 30%;
    padding: 10px;
    margin: 0 auto;
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.5);
    min-width: 100px;
    color: #fff;
    cursor: pointer;
    user-select: none;
  }

  .editor-inner .editor-image .image-caption-button:hover {
    background-color: rgba(60, 132, 244, 0.5);
  }

  .editor-inner .editor-image .image-resizer {
    display: block;
    width: 7px;
    height: 7px;
    position: absolute;
    background-color: rgb(60, 132, 244);
    border: 1px solid #fff;
  }

  .editor-inner .editor-image .image-resizer.image-resizer-n {
    top: -6px;
    left: 48%;
    cursor: n-resize;
  }

  .editor-inner .editor-image .image-resizer.image-resizer-ne {
    top: -6px;
    right: -6px;
    cursor: ne-resize;
  }

  .editor-inner .editor-image .image-resizer.image-resizer-e {
    bottom: 48%;
    right: -6px;
    cursor: e-resize;
  }

  .editor-inner .editor-image .image-resizer.image-resizer-se {
    bottom: -2px;
    right: -6px;
    cursor: nwse-resize;
  }

  .editor-inner .editor-image .image-resizer.image-resizer-s {
    bottom: -2px;
    left: 48%;
    cursor: s-resize;
  }

  .editor-inner .editor-image .image-resizer.image-resizer-sw {
    bottom: -2px;
    left: -6px;
    cursor: sw-resize;
  }

  .editor-inner .editor-image .image-resizer.image-resizer-w {
    bottom: 48%;
    left: -6px;
    cursor: w-resize;
  }

  .editor-inner .editor-image .image-resizer.image-resizer-nw {
    top: -6px;
    left: -6px;
    cursor: nw-resize;
  }
  .editor-inner {
    background: #fff;
    position: relative;
  }
  .editor-input {
    tab-size: 1;
    outline: 0;
    resize: none;
    height: 200px;
    font-size: 14px;
    overflow-y: auto;
    caret-color: #444;
    padding: 8px 12px;
    position: relative;
  }
  .editor-placeholder {
    top: 8px;
    left: 12px;
    color: #999;
    font-size: 14px;
    user-select: none;
    overflow: hidden;
    position: absolute;
    pointer-events: none;
    display: inline-block;
    text-overflow: ellipsis;
  }
  .editor-text-bold {
    font-weight: bold;
  }
  .editor-text-italic {
    font-style: italic;
  }
  .editor-text-underline {
    text-decoration: underline;
  }
  .editor-text-strikethrough {
    text-decoration: line-through;
  }
  .editor-text-underlinestrikethrough {
    text-decoration: underline line-through;
  }
  .editor-text-code {
    background-color: rgb(240, 242, 245);
    padding: 1px 0.25rem;
    font-family: Menlo, Consolas, Monaco, monospace;
    font-size: 94%;
  }
  .editor-link {
    color: rgb(33, 111, 219);
    text-decoration: none;
  }
  .editor-code {
    background-color: rgb(240, 242, 245);
    font-family: Menlo, Consolas, Monaco, monospace;
    display: block;
    padding: 8px 8px 8px 52px;
    line-height: 1.53;
    font-size: 13px;
    margin: 0;
    margin-top: 8px;
    margin-bottom: 8px;
    tab-size: 2;
    overflow-x: auto;
    position: relative;
  }
  .editor-code:before {
    content: attr(data-gutter);
    position: absolute;
    background-color: #eee;
    left: 0;
    top: 0;
    border-right: 1px solid #ccc;
    padding: 8px;
    color: #777;
    white-space: pre-wrap;
    text-align: right;
    min-width: 25px;
  }
  .editor-code:after {
    content: attr(data-highlight-language);
    top: 0;
    right: 3px;
    padding: 3px;
    font-size: 10px;
    text-transform: uppercase;
    position: absolute;
    color: rgba(0, 0, 0, 0.5);
  }
  .editor-tokencomment {
    color: slategray;
  }

  .editor-tokenpunctuation {
    color: #999;
  }

  .editor-tokenproperty {
    color: #905;
  }

  .editor-tokenselector {
    color: #690;
  }

  .editor-tokenoperator {
    color: #9a6e3a;
  }

  .editor-tokenattr {
    color: #07a;
  }

  .editor-tokenvariable {
    color: #e90;
  }

  .editor-tokenfunction {
    color: #dd4a68;
  }

  .editor-paragraph {
    margin: 0;
    margin-bottom: 8px;
    position: relative;
  }

  .editor-paragraph:last-child {
    margin-bottom: 0;
  }

  .editor-heading-h1 {
    font-size: 24px;
    color: rgb(5, 5, 5);
    font-weight: 400;
    margin: 0;
    margin-bottom: 12px;
    padding: 0;
  }

  .editor-heading-h2 {
    font-size: 15px;
    color: rgb(101, 103, 107);
    font-weight: 700;
    margin: 0;
    margin-top: 10px;
    padding: 0;
    text-transform: uppercase;
  }

  .editor-quote {
    margin: 0;
    margin-left: 20px;
    font-size: 15px;
    color: rgb(101, 103, 107);
    border-left-color: rgb(206, 208, 212);
    border-left-width: 4px;
    border-left-style: solid;
    padding-left: 16px;
  }

  .editor-list-ol {
    padding: 0;
    margin: 0;
    margin-left: 16px;
  }

  .editor-list-ul {
    padding: 0;
    margin: 0;
    margin-left: 16px;
  }

  .editor-listitem {
    margin: 8px 32px 8px 32px;
  }

  .editor-nested-listitem {
    list-style-type: none;
  }

  pre::-webkit-scrollbar {
    background: transparent;
    width: 10px;
  }

  pre::-webkit-scrollbar-thumb: {
    background: #999;
  }
  .emoji {
    color: transparent;
    background-size: 16px 16px;
    background-position: center;
    background-repeat: no-repeat;
    vertical-align: middle;
    margin: 0 -1px;
  }

  .emoji-inner {
    padding: 0 0.15em;
  }

  .emoji-inner::selection {
    color: transparent;
    background-color: rgba(150, 150, 150, 0.4);
  }

  .emoji-inner::moz-selection {
    color: transparent;
    background-color: rgba(150, 150, 150, 0.4);
  }

  .emoji.happysmile {
    background-image: url(/images/emoji/1F642.png);
  }

  .toolbar {
    display: flex;
    margin-bottom: 1px;
    background: #fff;
    padding: 4px;
    flex-wrap: wrap;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    vertical-align: middle;
    border-bottom: 1px solid #ebeef2;
  }

  .toolbar button.toolbar-item {
    border: 0;
    display: flex;
    background: none;
    border-radius: 10px;
    padding: 8px;
    cursor: pointer;
    vertical-align: middle;
  }

  .toolbar button.toolbar-item:disabled {
    cursor: not-allowed;
  }

  .toolbar button.toolbar-item.spaced: {
    margin-right: 2px;
  }

  .toolbar button.toolbar-item i.format {
    background-size: contain;
    display: inline-block;
    height: 18px;
    width: 18px;
    margin-top: 2px;
    vertical-align: -0.25em;
    opacity: 0.6;
  }

  .toolbar button.toolbar-item:disabled i.format {
    opacity: 0.2;
  }

  .toolbar button.toolbar-item.active {
    background-color: rgba(223, 232, 250, 0.3);
  }

  .toolbar button.toolbar-item.active i {
    opacity: 1;
  }

  .toolbar .toolbar-item:hover:not([disabled]) {
    background-color: #eee;
  }

  .toolbar .divider {
    width: 1px;
    background-color: #eee;
    margin: 0 4px;
  }

  .toolbar select.toolbar-item {
    border: 0;
    display: flex;
    background: none;
    border-radius: 10px;
    padding: 8px;
    vertical-align: middle;
    width: 70px;
    font-size: 14px;
    color: #777;
    text-overflow: ellipsis;
  }

  .toolbar select.code-language {
    text-transform: capitalize;
    width: 130px;
  }

  .toolbar .toolbar-item .text {
    display: flex;
    line-height: 20px;
    vertical-align: middle;
    font-size: 14px;
    color: #777;
    text-overflow: ellipsis;
    width: auto;
    overflow: hidden;
    height: 20px;
    text-align: left;
  }

  .toolbar .toolbar-item .icon {
    display: flex;
    width: 20px;
    height: 20px;
    user-select: none;
    margin-right: 8px;
    line-height: 16px;
    background-size: contain;
  }

  .toolbar i.chevron-down {
    margin-top: 3px;
    width: 16px;
    height: 16px;
    display: flex;
    user-select: none;
  }

  .toolbar i.chevron-down.inside {
    width: 16px;
    height: 16px;
    display: flex;
    margin-left: -25px;
    margin-top: 11px;
    margin-right: 10px;
    pointer-events: none;
  }

  i.chevron-down {
    background-color: transparent;
    background-size: contain;
    display: inline-block;
    height: 8px;
    width: 8px;
    background-repeat: no-repeat;
    background-image: url(/images/icons/chevron-down.svg);
  }

  #block-controls button:hover {
    background-color: #efefef;
  }

  #block-controls button:focus-visible {
    border-color: blue;
  }

  #block-controls span.block-type {
    background-size: contain;
    display: block;
    width: 18px;
    height: 18px;
    margin: 2px;
  }

  #block-controls span.block-type.paragraph {
    background-repeat: no-repeat;
    background-image: url(/images/icons/text-paragraph.svg);
  }

  #block-controls span.block-type.h1 {
    background-repeat: no-repeat;
    background-image: url(/images/icons/type-h1.svg);
  }

  #block-controls span.block-type.h2 {
    background-repeat: no-repeat;
    background-image: url(/images/icons/type-h2.svg);
  }

  #block-controls span.block-type.quote {
    background-repeat: no-repeat;
    background-image: url(/images/icons/chat-square-quote.svg);
  }

  #block-controls span.block-type.ul {
    background-repeat: no-repeat;
    background-image: url(/images/icons/list-ul.svg);
  }

  #block-controls span.block-type.ol {
    background-repeat: no-repeat;
    background-image: url(/images/icons/list-ol.svg);
  }

  #block-controls span.block-type.code {
    background-repeat: no-repeat;
    background-image: url(/images/icons/code.svg);
  }

  .dropdown {
    z-index: 5;
    display: block;
    position: absolute;
    box-shadow: 0 12px 28px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.1),
      inset 0 0 0 1px rgba(255, 255, 255, 0.5);
    border-radius: 8px;
    min-width: 100px;
    min-height: 40px;
    background-color: #fff;
  }

  .dropdown .item {
    margin: 0 8px 0 8px;
    padding: 8px;
    color: #050505;
    cursor: pointer;
    line-height: 16px;
    font-size: 15px;
    display: flex;
    align-content: center;
    flex-direction: row;
    flex-shrink: 0;
    justify-content: space-between;
    background-color: #fff;
    border-radius: 8px;
    border: 0;
    min-width: 268px;
  }

  .dropdown .item .active {
    display: flex;
    width: 20px;
    height: 20px;
    background-size: contain;
  }

  .dropdown .item:first-child {
    margin-top: 8px;
  }

  .dropdown .item:last-child {
    margin-bottom: 8px;
  }

  .dropdown .item:hover {
    background-color: #eee;
  }

  .dropdown .item .text {
    display: flex;
    line-height: 20px;
    flex-grow: 1;
    width: 200px;
  }

  .dropdown .item .icon {
    display: flex;
    width: 20px;
    height: 20px;
    user-select: none;
    margin-right: 12px;
    line-height: 16px;
    background-size: contain;
  }

  .link-editor {
    position: absolute;
    z-index: 100;
    top: -10000px;
    left: -10000px;
    margin-top: -6px;
    max-width: 300px;
    width: 100%;
    opacity: 0;
    background-color: #fff;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    transition: opacity 0.5s;
  }

  .link-editor .link-input {
    display: block;
    width: calc(100% - 24px);
    box-sizing: border-box;
    margin: 8px 12px;
    padding: 8px 12px;
    border-radius: 15px;
    background-color: #eee;
    font-size: 15px;
    color: rgb(5, 5, 5);
    border: 0;
    outline: 0;
    position: relative;
    font-family: inherit;
  }

  .link-editor div.link-edit {
    background-image: url(/images/icons/pencil-fill.svg);
    background-size: 16px;
    background-position: center;
    background-repeat: no-repeat;
    width: 35px;
    vertical-align: -0.25em;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    cursor: pointer;
  }

  .link-editor .link-input a {
    color: rgb(33, 111, 219);
    text-decoration: none;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    margin-right: 30px;
    text-overflow: ellipsis;
  }

  .link-editor .link-input a:hover {
    text-decoration: underline;
  }

  .link-editor .button {
    width: 20px;
    height: 20px;
    display: inline-block;
    padding: 6px;
    border-radius: 8px;
    cursor: pointer;
    margin: 0 2px;
  }

  .link-editor .button.hovered {
    width: 20px;
    height: 20px;
    display: inline-block;
    background-color: #eee;
  }

  .link-editor .button i {
    background-size: contain;
    display: inline-block;
    height: 20px;
    width: 20px;
    vertical-align: -0.25em;
  }
  .actions i {
    background-size: contain;
    display: inline-block;
    height: 20px;
    width: 20px;
    vertical-align: -0.25em;
  }

  i.undo {
    background-repeat: no-repeat;
    background-image: url(/images/icons/arrow-counterclockwise.svg);
  }

  i.redo {
    background-repeat: no-repeat;
    background-image: url(/images/icons/arrow-clockwise.svg);
  }

  .icon.paragraph {
    background-repeat: no-repeat;
    background-image: url(/images/icons/text-paragraph.svg);
  }

  .icon.large-heading {
    background-repeat: no-repeat;
    background-image: url(/images/icons/type-h1.svg);
  }
  .icon.h1 {
    background-repeat: no-repeat;
    background-image: url(/images/icons/type-h1.svg);
  }

  .icon.small-heading {
    background-repeat: no-repeat;
    background-image: url(/images/icons/type-h2.svg);
  }

  .icon.h2 {
    background-repeat: no-repeat;
    background-image: url(/images/icons/type-h2.svg);
  }

  .icon.bullet-list {
    background-repeat: no-repeat;
    background-image: url(/images/icons/list-ul.svg);
  }
  .icon.ul {
    background-repeat: no-repeat;
    background-image: url(/images/icons/list-ul.svg);
  }
  .icon.numbered-list {
    background-repeat: no-repeat;
    background-image: url(/images/icons/list-ol.svg);
  }
  .icon.ol {
    background-repeat: no-repeat;
    background-image: url(/images/icons/list-ol.svg);
  }

  .icon.quote {
    background-repeat: no-repeat;
    background-image: url(/images/icons/chat-square-quote.svg);
  }

  .icon.code {
    background-repeat: no-repeat;
    background-image: url(/images/icons/code.svg);
  }

  .icon.font-family {
    background-repeat: no-repeat;
    background-image: url(/images/icons/font-family.svg);
  }

  .icon.font-color {
    background-repeat: no-repeat;
    background-image: url(/images/icons/font-color.svg);
  }

  .icon.bg-color {
    background-repeat: no-repeat;
    background-image: url(/images/icons/bg-color.svg);
  }

  i.bold {
    background-repeat: no-repeat;
    background-image: url(/images/icons/type-bold.svg);
  }

  i.italic {
    background-repeat: no-repeat;
    background-image: url(/images/icons/type-italic.svg);
  }

  i.underline {
    background-repeat: no-repeat;
    background-image: url(/images/icons/type-underline.svg);
  }

  i.strikethrough {
    background-repeat: no-repeat;
    background-image: url(/images/icons/type-strikethrough.svg);
  }

  i.code {
    background-repeat: no-repeat;
    background-image: url(/images/icons/code.svg);
  }

  i.link {
    background-repeat: no-repeat;
    background-image: url(/images/icons/link.svg);
  }

  i.left-align {
    background-repeat: no-repeat;
    background-image: url(/images/icons/text-left.svg);
  }

  i.center-align {
    background-repeat: no-repeat;
    background-image: url(/images/icons/text-center.svg);
  }

  i.right-align {
    background-repeat: no-repeat;
    background-image: url(/images/icons/text-right.svg);
  }

  i.justify-align {
    background-repeat: no-repeat;
    background-image: url(/images/icons/justify.svg);
  }

  i.image {
    background-repeat: no-repeat;
    background-image: url(/images/icons/file-image.svg);
  }
`;
