import styled from '@emotion/styled';
import { Box } from '@chakra-ui/core/dist';

// noinspection CssUnknownTarget
export const ListSidePanelWrapper = styled(Box)`
  width: 590px;
  background-color: #f4f6f9;
  border-top-left-radius: 10px;
  padding: 2rem 1rem;

  .filter-item {
    display: flex;
    flex-direction: row;
    justify-content: unset;
    align-items: center;
    margin-bottom: 0.5rem;
    width: 100%;

    .conjunction,
    .operand {
      font-size: 14px;
      margin-right: 4px;
    }

    .selected-label-text {
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .column-select,
    .conjunction-select {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      background-color: #f6f6f6;
      border-radius: 5px;
      padding: 5px 10px;
      margin-right: 4px;
      font-size: 12px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      :hover {
        cursor: pointer;
      }

      * {
        font-size: 12px;
      }

      .icon {
        display: none;
      }
    }

    .conjunction-select {
      padding-left: 10px;
    }

    .operator-select-container {
      max-width: 390px;
    }

    .operator-select {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      background-color: #f6f6f6;
      border-radius: 5px;
      padding: 5px 10px;
      margin-right: 4px;
      font-size: 12px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      :hover {
        cursor: pointer;
      }

      * {
        font-size: 12px;
      }
    }

    .value-input {
      background-color: #f6f6f6;
      font-size: 12px;
      padding: 5px 10px;
      width: 100px;
      height: fit-content;
      box-shadow: none;
      margin-right: 4px;

      :focus {
        box-shadow: none;
        outline: none;
      }
    }

    .icon-menu {
      height: 25px;
      width: 25px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      border-radius: 13px;
      cursor: pointer;
      transition: all 0.2s ease-in;

      :hover {
        background-color: #f2f2f2;
      }
    }
  }
`;
