import {
  Box,
  Flex,
  Heading,
  Progress,
  SimpleGrid,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/core';
import { PreTitle, SmallSubtitle, ToastBox } from 'app/components';
import { BarList } from 'app/components/BarList';
import { selectUserID } from 'app/unauthenticated-app/authentication';
import country from 'country-list-js';
import { format } from 'date-fns';
import groupBy from 'lodash/groupBy';
import isEmpty from 'lodash/isEmpty';
import React, { forwardRef, useMemo, useState } from 'react';
import { Line } from 'react-chartjs-2';
import ReactCountryFlag from 'react-country-flag';
import { useSelector } from 'react-redux';
import { numberWithCommas } from 'utils';
import { track } from 'utils/segment';
import { generateMarketingReport } from '../../campaigns.service';
import { CampaignData } from '../../campaigns.types';
import { BottomCard } from './BottomCard';
import { ClicksDownloadCard } from './ClicksDownloadCard';
import { ClicksReportDownloadModal } from './ClicksReportDownloadModal';

export type OneTimeCampaignAnalyticsProps = {
  campaign: CampaignData;
  isGeneratingImage?: boolean;
};

export const OneTimeCampaignAnalytics = forwardRef(
  (props: OneTimeCampaignAnalyticsProps, ref: any) => {
    const { campaign, isGeneratingImage = false } = props;
    const {
      name: campaignName,
      type: campaignType,
      clicks,
      reports,
      total_sent,
      total_dnd,
      total_delivered,
      total_undelivered,
    } = campaign;
    const conversionRate =
      clicks?.total_clicks && total_sent ? Math.ceil((clicks?.total_clicks / total_sent) * 100) : 0;

    const toast = useToast();

    const user_id = useSelector(selectUserID);
    const {
      isOpen: isExportSuccessToastOpen,
      onOpen: onOpenExportSuccessToast,
      onClose: onCloseExportSuccessToast,
    } = useDisclosure();
    const {
      isOpen: isReportsDownloadModalOpen,
      onClose: onCloseReportsDownloadModal,
      onOpen: onOpenReportsDownloadModal,
    } = useDisclosure();

    const [isGeneratingReport, setIsGeneratingReport] = useState(false);

    const handleGenerateSMSClickReport = async () => {
      try {
        setIsGeneratingReport(true);
        await generateMarketingReport({
          via: 'sms',
          user_id: user_id ?? '',
          campaign_id: campaign.id,
          type: 'marketing.campaign.clicks',
        });
        setIsGeneratingReport(false);
        onCloseReportsDownloadModal();
        onOpenExportSuccessToast();
        track('Downloaded Clicks Report', {
          name: campaignName,
          type: campaignType,
        });
        toast({
          position: 'bottom-left',
          render: ({ onClose }) => (
            <ToastBox status="success" onClose={onClose} message="Report generated successfully" />
          ),
        });
      } catch (error: any) {
        setIsGeneratingReport(false);
        toast({
          position: 'bottom-left',
          render: ({ onClose }) => <ToastBox onClose={onClose} message={error.message} />,
        });
      }
    };

    const clicksData = useMemo(() => {
      if (!clicks?.summary || !clicks?.summary.length) return;

      const countries: { [key: string]: { country: string; count: number } } = {};
      const cities: { [key: string]: { city: string; count: number; country: string } } = {};

      const groupedCityData = groupBy(clicks?.summary, item => item.city);
      const groupedCountryData = groupBy(clicks?.summary, item => item.country);

      Object.keys(groupedCountryData).forEach(item => {
        countries[item] = {
          country: item,
          count: groupedCountryData[item].reduce((acc, item) => item.count + acc, 0),
        };
      });

      Object.keys(groupedCityData).forEach(item => {
        cities[item] = {
          city: item,
          country: groupedCityData[item][0].country,
          count: groupedCityData[item].reduce((acc, item) => item.count + acc, 0),
        };
      });

      return { cities, countries };
    }, [clicks?.summary]);

    const { cities, countries } = clicksData ?? {};

    return (
      <Stack ref={ref} spacing="1.5rem">
        {!isEmpty(reports) && (
          <BottomCard label="Engagement Details">
            <Flex pt="1rem" px="2rem">
              <Stack spacing="0.2rem">
                <PreTitle color="gray.500">Total Clicks</PreTitle>
                <Heading color="gray.900" size="xl">
                  {numberWithCommas(clicks?.total_clicks)}
                </Heading>
              </Stack>
            </Flex>
            <Box px="2rem">
              <Line
                height={80}
                data={{
                  labels: Object.keys(reports)
                    .sort((a, b) => {
                      const dateA = a && new Date(a).getTime();
                      const dateB = b && new Date(b).getTime();
                      if (dateA && dateB) {
                        return dateA - dateB;
                      }
                      return 0;
                    })
                    .map((i: any) => format(new Date(i), 'dd/MM')),
                  datasets: [
                    {
                      fill: false,
                      label: 'No. of clicks',
                      backgroundColor: 'rgb(255, 99, 132)',
                      borderColor: 'rgba(255, 99, 132, 0.2)',
                      data: Object.keys(reports)
                        .sort((a, b) => {
                          const dateA = a && new Date(a).getTime();
                          const dateB = b && new Date(b).getTime();
                          if (dateA && dateB) {
                            return dateA - dateB;
                          }
                          return 0;
                        })
                        .map((i: any) => reports[i].total_click | 0),
                    },
                  ],
                }}
                options={{
                  scales: {
                    yAxes: [
                      {
                        ticks: {
                          beginAtZero: true,
                        },
                      },
                    ],
                  },
                }}
              />
            </Box>
          </BottomCard>
        )}

        <SimpleGrid spacing={4} columns={!!clicks?.summary?.length ? [1, 1, 2] : 1}>
          <BottomCard label="Conversion Details" width="100%">
            <Flex height="100%" px="2rem" pt="1rem" flexDirection="column">
              <Stack flex={1} spacing="1.5rem">
                <Stack fontSize="0.875rem" isInline alignItems="center">
                  <Text width="25%">SMS Sent</Text>
                  <Progress
                    flex={1}
                    size="sm"
                    width="100%"
                    rounded="100px"
                    value={total_sent ? (total_sent / total_sent) * 100 : 0}
                  />
                  <Text width="25%">
                    {total_sent ? (total_sent / total_sent) * 100 : 0}% (
                    {numberWithCommas(total_sent)})
                  </Text>
                </Stack>
                <Stack fontSize="0.875rem" isInline alignItems="center">
                  <Text width="25%">SMS Delivered</Text>
                  <Progress
                    flex={1}
                    size="sm"
                    width="100%"
                    rounded="100px"
                    value={total_delivered && total_sent ? (total_delivered / total_sent) * 100 : 0}
                  />
                  <Text width="25%">
                    {Math.ceil(
                      total_delivered && total_sent ? (total_delivered / total_sent) * 100 : 0,
                    )}
                    % ({numberWithCommas(total_delivered)})
                  </Text>
                </Stack>
                <Stack fontSize="0.875rem" isInline alignItems="center">
                  <Text width="25%">SMS Undelivered</Text>
                  <Progress
                    flex={1}
                    size="sm"
                    width="100%"
                    rounded="100px"
                    value={
                      total_undelivered && total_sent ? (total_undelivered / total_sent) * 100 : 0
                    }
                  />
                  <Text width="25%">
                    {Math.ceil(
                      total_undelivered && total_sent ? (total_undelivered / total_sent) * 100 : 0,
                    )}
                    % ({numberWithCommas(total_undelivered)})
                  </Text>
                </Stack>
                <Stack fontSize="0.875rem" isInline alignItems="center">
                  <Text width="25%">SMS DND</Text>
                  <Progress
                    flex={1}
                    size="sm"
                    width="100%"
                    rounded="100px"
                    value={total_dnd && total_sent ? (total_dnd / total_sent) * 100 : 0}
                  />
                  <Text width="25%">
                    {Math.ceil(total_dnd && total_sent ? (total_dnd / total_sent) * 100 : 0)}% (
                    {numberWithCommas(total_dnd)})
                  </Text>
                </Stack>
                <Stack fontSize="0.875rem" isInline alignItems="center">
                  <Text width="25%">Clicks</Text>
                  <Progress
                    flex={1}
                    size="sm"
                    width="100%"
                    rounded="100px"
                    value={
                      clicks?.total_clicks && total_sent
                        ? (clicks?.total_clicks / total_sent) * 100
                        : 0
                    }
                  />
                  <Text width="25%">
                    {Math.ceil(
                      clicks?.total_clicks && total_sent
                        ? (clicks?.total_clicks / total_sent) * 100
                        : 0,
                    )}
                    % ({numberWithCommas(clicks?.total_clicks)})
                  </Text>
                </Stack>
                <Stack fontSize="0.875rem" isInline alignItems="center">
                  <Text width="25%">Conversion</Text>
                  <Progress
                    flex={1}
                    size="sm"
                    width="100%"
                    rounded="100px"
                    value={conversionRate}
                  />
                  <Text width="25%">{conversionRate}%</Text>
                </Stack>
                <Text fontWeight="normal" fontSize="0.7rem">
                  Conversion = clicks / sent
                </Text>
              </Stack>
              {/* <Flex
              p="1rem"
              bg="gray.50"
              rounded="8px"
              flexDirection="column"
              justifyContent="center"
            ></Flex> */}
            </Flex>
          </BottomCard>
          {!!clicks?.summary?.length && (
            <Box
              py="2rem"
              rounded="8px"
              color="gray.500"
              background="#fff"
              letterSpacing="wide"
              marginBottom="0.5rem"
              boxShadow="0 1px 3px 0 rgba(0, 0, 0, 0.1),0 1px 2px 0 rgba(0, 0, 0, 0.06)"
            >
              <Flex height="100%" px="2rem" flexDirection="column">
                <Tabs flex={1} variant="unstyled">
                  <Stack pb="1rem" isInline justifyContent="space-between">
                    <SmallSubtitle color="gray.900">Locations</SmallSubtitle>
                    <TabList position="relative" top="-8px">
                      <Tab
                        p="2"
                        mr="2"
                        bg="gray.50"
                        rounded="8px"
                        fontSize="0.875rem"
                        _hover={{
                          bg: 'gray.100',
                        }}
                        _focus={{
                          boxShadow: 'none',
                        }}
                        _selected={{
                          bg: 'gray.200',
                          fontWeight: '500',
                          boxShadow: 'none',
                        }}
                      >
                        Country
                      </Tab>
                      <Tab
                        p="2"
                        bg="gray.50"
                        rounded="8px"
                        fontSize="0.875rem"
                        _hover={{
                          bg: 'gray.100',
                        }}
                        _focus={{
                          boxShadow: 'none',
                        }}
                        _selected={{
                          bg: 'gray.200',
                          fontWeight: '500',
                          boxShadow: 'none',
                        }}
                      >
                        City
                      </Tab>
                    </TabList>
                  </Stack>
                  <TabPanels>
                    <TabPanel>
                      <BarList
                        color="blue.100"
                        data={Object.keys(countries ?? {}).map(item => {
                          const { count, country: countryIso2 } = countries?.[item] ?? {};
                          return {
                            key: countryIso2,
                            value: count ?? 0,
                            name: country.findByIso2(countryIso2 ?? '')?.name ?? '',
                            icon: <ReactCountryFlag countryCode={countryIso2 ?? ''} svg />,
                          };
                        })}
                      />
                    </TabPanel>
                    <TabPanel>
                      <BarList
                        color="blue.100"
                        data={Object.keys(cities ?? {}).map(item => {
                          const { count, country: countryIso2, city } = cities?.[item] ?? {};
                          return {
                            name: city ?? '',
                            value: count ?? 0,
                            key: `${city}-${countryIso2}`,
                            icon: <ReactCountryFlag countryCode={countryIso2 ?? ''} svg />,
                          };
                        })}
                      />
                    </TabPanel>
                  </TabPanels>
                </Tabs>
                {!!clicks?.total_clicks && !isGeneratingImage && (
                  <ClicksDownloadCard
                    title="SMS Campaign Re-targeting"
                    onDownload={() => {
                      track('Clicked Download Clicks Report', { type: campaign?.type });
                      onOpenReportsDownloadModal();
                    }}
                    isExportSuccessToastOpen={isExportSuccessToastOpen}
                    onCloseExportSuccessToast={onCloseExportSuccessToast}
                    description="The campaign had some engagement (link clicks), download the list of recipients that engaged with the campaign and re-target them with another campaign."
                  />
                )}
              </Flex>
            </Box>
          )}
        </SimpleGrid>

        {isReportsDownloadModalOpen && (
          <ClicksReportDownloadModal
            isLoading={isGeneratingReport}
            isOpen={isReportsDownloadModalOpen}
            onClose={onCloseReportsDownloadModal}
            handleDownload={handleGenerateSMSClickReport}
          />
        )}
      </Stack>
    );
  },
);
