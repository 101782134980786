import { Box, Link, PseudoBox, Skeleton } from '@chakra-ui/core';
import { TableLayout } from 'app/components';
import React, { ReactNode } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { numberWithCommas } from 'utils';

type DashboardTableProps<T> = {
  data: T[];
  headings: string[];
  renderItem(item: T, index?: number): ReactNode;
};

export function DashboardTable<T>({ data, headings, renderItem }: DashboardTableProps<T>) {
  return (
    <Box borderWidth="1px" borderRadius="5px">
      <Box overflowY="auto">
        <TableLayout>
          <thead>
            <tr>
              {headings.map((heading, index) => (
                <th key={`${index}`}>{heading}</th>
              ))}
            </tr>
          </thead>
          <tbody>{data.map(renderItem)}</tbody>
        </TableLayout>
      </Box>
    </Box>
  );
}

export function DashboardTableItem(props: { item: any; onClick?(): void }) {
  const { item, onClick } = props;
  return (
    <PseudoBox
      as="tr"
      cursor="pointer"
      onClick={onClick}
      _hover={{ bg: onClick ? 'gray.100' : 'unset' }}
    >
      <td>{item.title}</td>
      <td>{numberWithCommas(item.sent)}</td>
      <td>{numberWithCommas(item.delivered)}</td>
      <td>{item.ctr !== undefined ? `${parseFloat(item.ctr).toFixed(1)}%` : '-'}</td>
      <td>{item.cvr !== undefined ? `${parseFloat(item.cvr).toFixed(1)}%` : '-'}</td>
    </PseudoBox>
  );
}

export function SMSReportTableItem(props: { item: any }) {
  const { item } = props;
  const { id } = useParams<{ id: string }>();
  return (
    <PseudoBox as="tr">
      <td>{item.title}</td>
      <td>{numberWithCommas(item.sent)}</td>
      <td>{numberWithCommas(item.delivered)}</td>
      <td>{numberWithCommas(item.dnd)}</td>
      <td>{numberWithCommas(item.links)}</td>
      <td>{item.cvr !== undefined ? `${parseFloat(item.cvr).toFixed(1)}%` : '-'}</td>
      <td>
        <Link
          //@ts-ignore
          as={NavLink}
          fontWeight="500"
          color="blue.500"
          to={`/s/marketing/sms-campaigns/${id}/analytics`}
        >
          View more
        </Link>
        {/* <NavLink></NavLink> */}
      </td>
    </PseudoBox>
  );
}

export function DashboardTableLoadingItem() {
  return (
    <tr>
      <td>
        <Box width="100%">
          <Skeleton height="10px" width="100%" my="10px" />
        </Box>
      </td>
      <td>
        <Box width="100%">
          <Skeleton height="10px" width="100%" my="10px" />
        </Box>
      </td>
      <td>
        <Box width="100%">
          <Skeleton height="10px" width="100%" my="10px" />
        </Box>
      </td>
      <td>
        <Box width="100%">
          <Skeleton height="10px" width="100%" my="10px" />
        </Box>
      </td>
      <td>
        <Box width="100%">
          <Skeleton height="10px" width="100%" my="10px" />
        </Box>
      </td>
    </tr>
  );
}
