import {
  Alert,
  AlertDescription,
  AlertIcon,
  Button,
  Icon,
  ModalBody,
  ModalCloseButton,
  PseudoBox,
  Stack,
} from '@chakra-ui/core';
import {
  BodyText,
  Heading3,
  ModalContainer,
  ModalContainerOptions,
  Subtitle,
} from 'app/components';
import React from 'react';

export const ClicksReportDownloadModal = (
  props: ModalContainerOptions & {
    type?: 'email' | 'sms';
    isLoading?: boolean;
    handleDownload(): void;
  },
) => {
  const { type = 'sms', isLoading, isOpen, onClose, handleDownload } = props;
  return (
    <ModalContainer size="lg" isOpen={isOpen} onClose={onClose}>
      <ModalCloseButton size="sm" />
      <ModalBody py="2rem">
        <Stack mb="1rem" textAlign="center" alignItems="center">
          <Heading3>Get access to campaign clicks</Heading3>
          <BodyText color="gray.500">
            Download the list of recipients that engaged with the campaign (clicks) and re-target
            them with another campaign.
          </BodyText>
        </Stack>
        <Stack borderWidth="1px" rounded="8px" spacing="1rem" p="1rem 0.5rem">
          <Subtitle>How much does this cost?</Subtitle>
          <BodyText>
            <PseudoBox as="strong" fontSize="1.5rem">
              &#8358; 1
            </PseudoBox>{' '}
            / recipient that engaged with the campaign (clicks).
          </BodyText>
          <BodyText>Here's what you get in the downloaded list:</BodyText>
          <Stack>
            <Stack isInline alignItems="center">
              <Icon size="0.8rem" color="green.500" name="check" />
              <BodyText color="gray.500">
                Recipient {type === 'email' ? 'email' : 'phone number'} information
              </BodyText>
            </Stack>
            <Stack isInline alignItems="center">
              <Icon size="0.8rem" color="green.500" name="check" />
              <BodyText color="gray.500">Recipient location information</BodyText>
            </Stack>
            <Stack isInline alignItems="center">
              <Icon size="0.8rem" color="green.500" name="check" />
              <BodyText color="gray.500">Click date and time</BodyText>
            </Stack>
          </Stack>
          <BodyText>
            You can create a new campaign with this list to re-engage recipients that engage with
            your content.
          </BodyText>
          <Alert alignItems="flex-start" rounded="8px" borderWidth="2px" borderColor="blue.500">
            <AlertIcon />
            <Stack spacing="0">
              <AlertDescription>
                <BodyText>The cost of this will be taken from your campaign credits.</BodyText>
              </AlertDescription>
            </Stack>
          </Alert>
          <Button isLoading={isLoading} variantColor="blue" size="sm" onClick={handleDownload}>
            Download list
          </Button>
        </Stack>
      </ModalBody>
    </ModalContainer>
  );
};
