import { Box, Divider, Grid, Icon, useDisclosure } from '@chakra-ui/core';
import { ResourceType } from 'app/authenticated-app/lists/lists.types';
import { icons } from 'feather-icons';
import React, { useContext, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { AppParamsContext } from '../contexts/app-contexts/params.context';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/core';
import { Button } from 'app/components';

export const icon_options: { [index: string]: any } = Object.keys(icons).reduce(
  (acc, i) => ({
    ...acc,
    [i]: `${icons[i].toSvg()}`,
  }),
  {},
);

interface Props {
  searchValue: string;
  customApps: never[];
  showMenu: (string | number | boolean)[];
  setShowMenu: Function;
  deletionHandler: Function;
  updateCustomApp: Function;
}

const AddButtonGrid = (props: any) => (
  <div className="add-button--grid" onClick={props.onClick}>
    <div className="box">
      <img
        alt="list-icon"
        src={`data:image/svg+xml;utf8,${icons['plus'].toSvg({ color: 'grey' })}`}
      />
    </div>
    <div className="label">New App</div>
  </div>
);

interface GridItemProps {
  label: string;
  onClick?: Function;
  showMenu: Function;
  color: string;
  icon: string;
  id: string;
  index: number;
  showMenuProp: (string | number | boolean)[];
}

const colors = ['#545D69', '#140c74', '#E63425', '#0F6D8E', '#DD0081'];
const custom_icons = ['command', 'compass', 'codesandbox', 'database', 'cast'];

function useOutsideAlerter(ref: any) {
  const { setHoverState } = useContext(AppParamsContext);

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        setHoverState(false);
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, setHoverState]);
}

export const ListCustomApps = (props: Props) => {
  const routerHistory = useHistory();
  const navCreateApp = () => routerHistory.push('/s/lists/custom-apps/new');

  const focusRef = useRef<Array<HTMLDivElement | null>>([]);
  const { hoverState, setHoverState } = useContext(AppParamsContext);

  const showAppMenu = (e: Event, key: string, i: number) => {
    setHoverState(true);
    if (props.showMenu[1] === key) {
      props.setShowMenu([!props.showMenu[0], key, i]);
    } else {
      props.setShowMenu([true, key, i]);
    }
  };

  const { isOpen, onOpen, onClose } = useDisclosure();

  const DeletePromptModal = (deletePromptProp: any) => {
    return (
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        isCentered={true}
        size={'md'}
        blockScrollOnMount={false}
      >
        <ModalOverlay />
        <ModalContent borderRadius="10px">
          <ModalHeader fontSize={'18px'} fontWeight={'500'}>
            Delete Custom App
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box>Are you sure you want to delete the custom app?</Box>
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost" size={'sm'} mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button
              size={'sm'}
              rightIcon="delete"
              variantColor="red"
              onClick={() => {
                props.deletionHandler(deletePromptProp.itemId);
                onClose();
              }}
            >
              {' '}
              Delete
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  };

  const GridItem = (gridItemProps: GridItemProps) => {
    return (
      <Box className="add-button--grid-custom">
        <Box
          backgroundColor={gridItemProps.color ? gridItemProps.color : '#545D69'}
          className="box"
          onClick={() => props.updateCustomApp(gridItemProps.id)}
        >
          <img
            alt="connection"
            src={`data:image/svg+xml;utf8,${icons[
              gridItemProps.icon ? gridItemProps.icon : 'cast'
            ].toSvg({ color: 'white' })}`}
          />

          <Box
            className="action-btn-bg"
            onClick={(e: any) => {
              e.stopPropagation();
              gridItemProps.showMenu(e, gridItemProps.id, gridItemProps.index);
            }}
          >
            <Icon name="chevron-down" color="white" size="14px" />
          </Box>
        </Box>

        <Box
          className="label"
          overflow="hidden"
          // @ts-ignore
          textOverflow="ellipsis"
          maxWidth="260px"
        >
          {gridItemProps.label}
        </Box>

        {gridItemProps.showMenuProp[0] &&
          gridItemProps.showMenuProp[1] === gridItemProps.id &&
          hoverState && (
            <Box className="custom-app--grid-menu">
              <Box
                className="custom-app--grid-menu-item"
                onClick={() => props.updateCustomApp(gridItemProps.id)}
              >
                <Icon name="edit" size="14px" mr="10px" />
                Update app
              </Box>
              <Divider marginY="0" />
              <Box
                className="custom-app--grid-menu-item"
                // onClick={() => props.deletionHandler(gridItemProps.id)}
                onClick={onOpen}
              >
                <Icon name="trash" size="14px" mr="10px" color="#E73D51" />
                <span>Delete app</span>
              </Box>
            </Box>
          )}
      </Box>
    );
  };

  useEffect(() => {
    focusRef.current = focusRef.current?.slice(0, props.customApps?.length);
  }, [props.customApps]);

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  return (
    <>
      <Box
        className="content custom-app--list-view"
        ref={wrapperRef}
        onClick={() => props.setShowMenu([false, props.showMenu[1], props.showMenu[2]])} // onMouseLeave={() => setHoverState(false)}
      >
        <Box
          className="section-title"
          onClick={() => props.setShowMenu([false, props.showMenu[1], props.showMenu[2]])}
        >
          <Box className="title">Custom Apps</Box>
        </Box>

        <Grid
          templateRows="repeat(auto-fill, 1fr)"
          templateColumns="repeat(auto-fill, minmax(102.758px, 1fr))"
          rowGap={8}
          columnGap={1}
        >
          <AddButtonGrid onClick={navCreateApp} />

          {props.customApps
            .filter((item: ResourceType, i) => item.label.match(props.searchValue))
            .map((item: ResourceType, i) => (
              <Box key={item.key} ref={el => (focusRef.current[i] = el)}>
                <GridItem
                  key={item.key}
                  label={item.label}
                  showMenu={showAppMenu}
                  color={colors[(i % colors.length) - 1]}
                  icon={custom_icons[(i % colors.length) - 1]}
                  id={item.key}
                  index={i}
                  showMenuProp={props.showMenu}
                />
                {props.showMenu[1] === item.key && <DeletePromptModal itemId={item.key} />}
              </Box>
            ))}
        </Grid>
      </Box>
    </>
  );
};
