import { Checkbox, Icon, PseudoBox } from '@chakra-ui/core';
import React, { ReactNode, useEffect, useState } from 'react';

type OptionProps<T> = {
  item: T;
  onSelect(): void;
  isSelected?: boolean;
  renderItem?: (item: T) => ReactNode;
};

export function OptionMenuSingleOption<T>(props: OptionProps<T>) {
  const { item, onSelect, isSelected, renderItem } = props;

  return (
    <PseudoBox
      as="button"
      py="0.5rem"
      px="0.75rem"
      width="100%"
      display="flex"
      cursor="pointer"
      onClick={onSelect}
      alignItems="center"
      _hover={{ bg: 'gray.200' }}
      justifyContent="space-between"
    >
      {renderItem?.(item)}
      {isSelected && <Icon name="check" size="0.8rem" color="blue.500" />}
    </PseudoBox>
  );
}

export function OptionMenuMultiOption<T>(props: OptionProps<T>) {
  const { item, onSelect, isSelected, renderItem } = props;

  const [isChecked, setIsChecked] = useState(isSelected ?? false);

  useEffect(() => {
    setIsChecked(isSelected ?? false);
  }, [isSelected]);

  return (
    <PseudoBox
      as="button"
      py="0.5rem"
      px="0.75rem"
      width="100%"
      display="flex"
      alignItems="center"
      justifyContent="space-between"
    >
      {renderItem?.(item)}
      <Checkbox size="sm" isChecked={isChecked} onChange={onSelect} />
    </PseudoBox>
  );
}
