import { Box, Stack } from '@chakra-ui/core';
import React from 'react';

interface Props {
  titleHeadings: Array<string>;
  selectedTitle: string;
  setSelectedTitle: Function;
}

const Titles = (props: Props) => {
  return (
    <Stack className="customAppTitles" isInline spacing={6} align="center">
      {props.titleHeadings.map((heading: string) => (
        <Box
          key={heading}
          color={heading === props.selectedTitle ? 'blue.500' : 'grey'}
          borderBottom={heading === props.selectedTitle ? '2px' : '0px'}
          borderBottomColor={heading === props.selectedTitle ? 'blue.500' : 'none'}
          paddingBottom={'0.36rem'}
          onClick={() => props.setSelectedTitle(heading)}
        >
          {heading}
        </Box>
      ))}
    </Stack>
  );
};

export default Titles;
