import { useSelector } from 'react-redux';
import React, { ChangeEvent } from 'react';
import { Input } from 'app/components';
import { Box, Heading, Icon, Stack, Text } from '@chakra-ui/core';
import Cookie from 'js-cookie';
import { RootState } from 'root';
import { CreditTopupButton } from 'app/authenticated-app/marketing/campaigns/components/credit-topup-button';

type Props = {
  searchValue: string;
  setSearchValue: Function;
  visualization: string;
  setVisualization: Function;
  selectTab: Function;
  selectedTab: string;
  totalLists: number;
};

const tab_options = [
  { key: 'lists', label: 'Lists', icon: 'grid2', children: ['favorites', 'trash'] },
  { key: 'smart', label: 'Smart Lists', icon: 'grid2', children: [] },
  { key: 'connections', label: 'Connections', icon: 'connection', children: [] },
  { key: 'data-models', label: 'Data Models', icon: 'dataModel', children: [] },
  { key: 'segments', label: 'Segments', icon: 'segment-2', children: [] },
  { key: 'custom-apps', label: 'Custom Apps', icon: 'list-api', children: [] },
];

export const ListSidebar = (props: Props) => {
  const { searchValue, setSearchValue, visualization, setVisualization, selectedTab, totalLists } =
    props;

  const handleSearchInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  const { user } = useSelector((state: RootState) => state.auth);
  const { organisationMembers } = useSelector((state: RootState) => state.teams);
  const userRole = organisationMembers.find(member => member.id === user?.id);
  const name = userRole?.name;

  let permittedTabs;

  if (name === 'Admin') {
    permittedTabs = tab_options;
  } else if (name === 'Member') {
    permittedTabs = [
      { key: 'lists', label: 'Lists', icon: 'grid2', children: ['favorites', 'trash'] },
      { key: 'smart', label: 'Smart Lists', icon: 'grid2', children: [] },
      { key: 'custom-apps', label: 'Custom Apps', icon: 'list-api', children: [] },
    ];
  } else {
    permittedTabs = [
      { key: 'lists', label: 'Lists', icon: 'grid2', children: ['favorites', 'trash'] },
    ];
  }

  return (
    <div className="side-bar">
      <div className="search-section">
        <Input
          value={searchValue}
          className="search-input"
          variant="filled"
          placeholder="Search"
          onChange={handleSearchInputChange}
        />
        {['lists', 'smart', 'trash', 'favorites'].includes(selectedTab) && (
          <div className="action-section">
            <div
              className={`btn-bg ${visualization === 'grid' && 'active'}`}
              onClick={() => {
                setVisualization('grid');
                Cookie.set('lists_visualization', 'grid');
              }}
            >
              <Icon size="11px" name="grid3" />
            </div>
            <div
              className={`btn-bg ${visualization === 'list' && 'active'}`}
              onClick={() => {
                setVisualization('list');
                Cookie.set('lists_visualization', 'list');
              }}
            >
              <Icon size="12px" name="hamburger" />
            </div>
          </div>
        )}
      </div>
      <div>
        {permittedTabs.map((i: any, index: any) => (
          <div
            key={index}
            className={`option-item ${selectedTab === i.key && 'active'}`}
            onClick={() => props.selectTab(i.key)}
          >
            <Icon
              name={i.icon}
              size={i.key === 'custom-apps' ? '21px' : '16px'}
              marginRight={i.key === 'custom-apps' ? '5px' : '10px'}
              color={
                selectedTab === i.key || i.children.includes(selectedTab) ? '#026ae8' : '#A5ABB3'
              }
            />
            <Box
              className="text"
              color={
                selectedTab === i.key || i.children.includes(selectedTab) ? '#026ae8' : '#A5ABB3'
              }
            >
              {i.label}
            </Box>
          </div>
        ))}
      </div>

      <Box p="0.75rem 1rem" bg="#e8f7ff" rounded="5px" mt="15px">
        <Stack pb="0.5rem">
          <Heading fontSize="0.875rem" size="sm" fontWeight={500}>
            Lists Created: {totalLists}
          </Heading>
          <Text fontSize="0.75rem">You have no limits on the number of lists you can create</Text>
          <Box>
            <CreditTopupButton />
          </Box>
        </Stack>
      </Box>
    </div>
  );
};
