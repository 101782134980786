import { Box, Flex, PseudoBox, Skeleton } from '@chakra-ui/core';
import styled from '@emotion/styled';
import React, { ReactNode } from 'react';
import { numberWithCommas } from 'utils';

type CampaignStatsTableProps<T> = {
  data: T[];
  headings: string[];
  renderItem(item: T, index?: number): ReactNode;
};

export function CampaignStatsTable<T>({ data, headings, renderItem }: CampaignStatsTableProps<T>) {
  return (
    <Box>
      <Box overflowY="auto">
        <CampaignStatsTableLayout>
          <thead>
            <tr>
              {headings.map((heading, index) => (
                <th key={`${index}`}>{heading}</th>
              ))}
            </tr>
          </thead>
          <tbody>{data.map(renderItem)}</tbody>
        </CampaignStatsTableLayout>
      </Box>
    </Box>
  );
}

export function CampaignStatsTableItem(props: { item: any }) {
  const { item } = props;
  return (
    <PseudoBox as="tr" cursor="pointer" _hover={{ bg: 'gray.100' }}>
      <td>
        <Flex alignItems="center">
          <Box mr="0.75rem" size="0.5rem" borderRadius="50%" background={item.color} />
          {item.title}
        </Flex>
      </td>
      <td>{numberWithCommas(item.sent)}</td>
      <td>{numberWithCommas(item.delivered)}</td>
      <td>{numberWithCommas(item.dnd)}</td>
      <td>{numberWithCommas(item.no_of_clicks)}</td>
      <td>{item.ctr !== undefined ? `${parseFloat(item.ctr).toFixed(1)}%` : '-'}</td>
      <td>{item.cvr !== undefined ? `${parseFloat(item.cvr).toFixed(1)}%` : '-'}</td>
    </PseudoBox>
  );
}

export function CampaignStatsTableLoadingItem() {
  return (
    <tr>
      <td>
        <Box width="100%">
          <Skeleton height="10px" width="100%" my="10px" />
        </Box>
      </td>
      <td>
        <Box width="100%">
          <Skeleton height="10px" width="100%" my="10px" />
        </Box>
      </td>
      <td>
        <Box width="100%">
          <Skeleton height="10px" width="100%" my="10px" />
        </Box>
      </td>
      <td>
        <Box width="100%">
          <Skeleton height="10px" width="100%" my="10px" />
        </Box>
      </td>
      <td>
        <Box width="100%">
          <Skeleton height="10px" width="100%" my="10px" />
        </Box>
      </td>
      <td>
        <Box width="100%">
          <Skeleton height="10px" width="100%" my="10px" />
        </Box>
      </td>
      <td>
        <Box width="100%">
          <Skeleton height="10px" width="100%" my="10px" />
        </Box>
      </td>
    </tr>
  );
}

export const CampaignStatsTableLayout = styled.table`
  width: 100%;

  th {
    color: #2b3a4b;
    font-size: 14px;
    font-weight: 500;
    text-align: start;
    padding: 12px 24px;
    border-bottom: 1px solid #e2e8f0;
  }

  td {
    color: #000;
    font-size: 14px;
    font-weight: 400;
    padding: 16px 24px;
    border-bottom: 1px solid #e2e8f0;
  }

  tbody tr:last-of-type td {
    border-bottom: none;
  }
`;
