import { AppThunk } from 'root';
import {
  fetchRows,
  resetRows,
  selectList,
  selectSmartList,
  setEnrichmentOptionsData,
  setEnrichmentOptionsLoading,
  setEnrichmentSettingsData,
  setEnrichmentSettingsLoading,
  setLists,
} from '../lists.reducer';
import { client } from 'utils';
import { generateFilterPayload, wait } from '../lists.utils';

export const fetchEnrichmentOptions = (): AppThunk => async dispatch => {
  dispatch(setEnrichmentOptionsLoading(true));
  const response = await client('lists/enrichment/options', {
    method: 'GET',
  });
  dispatch(setEnrichmentOptionsData({ data: response.data }));
  dispatch(setEnrichmentOptionsLoading(false));
  return response.data;
};

export const fetchEnrichmentSettings = (listID: string): AppThunk => async dispatch => {
  dispatch(setEnrichmentSettingsLoading(true));

  const response = await client(`lists/enrichment/${listID}`, {
    method: 'GET',
  });
  dispatch(setEnrichmentSettingsData({ data: response.data }));

  dispatch(setEnrichmentSettingsLoading(false));
  return response.data;
};

export const updateEnrichmentSettings = (payload: {
  phone_column_id?: string;
  email_column_id?: string;
  phone?: string[];
  person?: string[];
  company?: string[];
}): AppThunk => async (dispatch, getState) => {
  dispatch(setEnrichmentSettingsLoading(true));
  const {
    lists: { selected_list: listID, selected_smart_list: smartListID, lists_by_id, lists },
  } = getState();

  const enrichmentSettingsResponse = await client(`lists/enrichment/${listID}`, {
    method: 'PATCH',
    data: payload,
  });

  dispatch(setEnrichmentSettingsData({ data: enrichmentSettingsResponse.data }));
  dispatch(setEnrichmentSettingsLoading(false));

  if (payload['person'] || payload['phone'] || payload['company']) {
    const listResponse = await client(`lists/list/${listID}`, {
      method: 'GET',
    });
    // @ts-ignore
    const updatedData = { ...lists, [listID]: listResponse.data };
    dispatch(setLists({ data: updatedData, by_id: lists_by_id }));

    if (!!smartListID) {
      // @ts-ignore
      dispatch(selectSmartList(smartListID));
    } else {
      // @ts-ignore
      dispatch(selectList(listID));
    }
    dispatch(resetRows());
  }

  return enrichmentSettingsResponse.data;
};

export const queueEnrichment = (): AppThunk => async (dispatch, getState) => {
  const {
    lists: { selected_list: listID, filters, filters_by_id, columns, column_id_map },
  } = getState();
  const response = await client(`lists/enrichment/${listID}`, {
    method: 'POST',
    data: {
      filters: generateFilterPayload(filters, filters_by_id, column_id_map, columns),
    },
  });
  await wait(4000);
  dispatch(resetRows());
  // @ts-ignore
  dispatch(fetchRows({ list: listID, page: 1 }));
  return response.data;
};
