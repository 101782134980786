import { useToast } from '@chakra-ui/core';
import { listSMSSenderProfiles } from 'app/authenticated-app/settings';
import { SMSSenderProfilePayload } from 'app/authenticated-app/settings/component/sender-profiles/sms-sender-profile-modal';
import { selectOrganisation } from 'app/authenticated-app/settings/slices';
import React from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { RootState } from 'root';
import { track } from 'utils/segment';
import { useBeforeunload, useLoading } from '../../../../hooks';
import { ContentWrapper, ToastBox } from '../../../components';
import { selectOrganisationID, selectProfile } from '../../../unauthenticated-app/authentication';
import { CampaignPayload } from './campaigns.types';
import { SmsCampaignForm } from './components/sms-campaigns/sms-campaign-form';
import { plannerConnector } from './planner.connector';
import { PlannerProps } from './planner.types';

const CreateCampaignUI = (props: PlannerProps) => {
  const { addCampaign, history, ...rest } = props;

  const toast = useToast();
  const { loading, dispatch } = useLoading();
  const profile = useSelector(selectProfile);
  const organisation = useSelector((state: RootState) =>
    selectOrganisation(state, profile?.organisation_id ?? ''),
  );
  const organisation_id = useSelector(selectOrganisationID);

  const { data: sender_ids } = useQuery<SMSSenderProfilePayload[]>(
    ['sms-sender-profiles'],
    listSMSSenderProfiles,
    {
      initialData: organisation?.sender_ids ?? [],
    },
  );

  const handleCreateCampaign = async (payload: Partial<CampaignPayload>) => {
    try {
      dispatch({ type: 'LOADING_STARTED' });
      await addCampaign(payload);
      dispatch({ type: 'LOADING_RESOLVED' });
      toast({
        position: 'bottom-left',
        render: ({ onClose }) => (
          <ToastBox status="success" onClose={onClose} message="Campaign created successfully" />
        ),
      });
      organisation_id && (await rest.fetchWallet(organisation_id));
      track('SMS Campaign Created', payload);
      history.push('/s/marketing/campaigns');
    } catch (error: any) {
      dispatch({ type: 'LOADING_RESOLVED' });
      toast({
        position: 'bottom-left',
        render: ({ onClose }) => <ToastBox onClose={onClose} message={error} />,
      });
    }
  };

  useBeforeunload((e: any) => {
    e.preventDefault();
  });

  return (
    <ContentWrapper p="1.875rem">
      <SmsCampaignForm
        isLoading={loading === 'pending'}
        onSubmit={handleCreateCampaign}
        initialValues={{
          state: 'draft',
          sender_id: sender_ids?.find((item: any) => item.is_default)?.name,
        }}
        {...rest}
      />
    </ContentWrapper>
  );
};

export const CreateSMSCampaign = plannerConnector(CreateCampaignUI);
