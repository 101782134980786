import React, { useEffect, useState } from 'react';
import { Box, Checkbox, IconButton, useToast } from '@chakra-ui/core/dist';
import { ListImportType } from '../../../../../../lists.types';
import { Button, FileUploader, SmallText, ToastBox } from 'app/components';
import Papa from 'papaparse';
import * as XLSX from 'xlsx';

const MAX_DATA_SAMPLE_SIZE: number = 500;

const description_map = {
  csv: 'Get started quickly by importing a CSV file. Most spreadsheets can be easily exported as a CSV.',
  'ms-excel': 'Get started quickly by importing an .xls or .xlsx file.',
  'apple-numbers':
    'You will first need to export the from Apple Numbers as a .CSV file in order to import it into a new list.',
  asana:
    'You will first need to export the data from Asana as a .CSV file in order to import it into a new list.',
  trello:
    'You will first need to export the data from Trello as a .CSV file in order to import it into a new list.',
  'ms-access':
    'You will first need to export the data as a .CSV file in order to import it into a new list.',
  'google-sheets': 'Import data from Google Sheets',
  calendar: 'Import data from your Calendar',
  contacts: 'Import data from your Contacts',
  mysql: 'Import data from your MySQL database',
  mssql: 'Import data from your Microsoft SQL server database',
};

interface Props {
  file: File | null;
  importType: ListImportType | null;
  handleUpload: Function;
  handleFileImport: Function;
  uploadLoading: boolean;
}

export const CreateListUploadPrompt = (props: Props) => {
  const [termsAgreed, setTermsAgreed] = useState<boolean>(false);
  const { importType, file, uploadLoading, handleFileImport } = props;

  const toast = useToast();

  const handleUpload = async ([uploaded_file]: File[]) => {
    if (uploaded_file) {
      if (importType !== 'ms-excel') {
        await Papa.parse(uploaded_file, {
          header: true,
          complete: function (results) {
            const dataOutput = results.data;
            const dataSample = dataOutput.slice(0, MAX_DATA_SAMPLE_SIZE);
            return props.handleUpload(uploaded_file, dataSample);
          },
        });
      } else {
        const rawDataBuffer = await uploaded_file.arrayBuffer();
        const workbook = XLSX.read(rawDataBuffer);
        const dataOutput = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]], {});
        const dataSample = dataOutput.slice(0, MAX_DATA_SAMPLE_SIZE);
        return props.handleUpload(uploaded_file, dataSample);
      }
    }
  };
  const clearFile = () => {
    props.handleUpload(null, null);
  };

  const handleFileDropRejected = () => {
    toast({
      duration: null,
      position: 'bottom-left',
      render: ({ onClose }) => (
        <ToastBox
          onClose={onClose}
          message="File size limit exceeded. Maximum upload file size is 10MB"
        />
      ),
    });
  };

  useEffect(() => {
    setTermsAgreed(false);
  }, [file]);

  if (!importType) return <Box />;

  return (
    <Box>
      <Box className="description">{description_map[importType]}</Box>

      <Box
        display="flex"
        flexDirection="row"
        marginTop="25px"
        width="fit-content"
        alignItems="center"
      >
        <FileUploader
          maxSize={10485760}
          onUpload={handleUpload}
          onDropRejected={handleFileDropRejected}
          accept={importType === 'ms-excel' ? '.xls, .xlsx' : '.csv'}
        >
          <Button variant="link" variantColor="blue" size="sm">
            {importType === 'ms-excel' ? 'Select an Excel file' : 'Select a .CSV file'}
          </Button>
        </FileUploader>

        {file && (
          <Box className="file-name">
            {file.name}
            <IconButton
              aria-label="clear"
              icon="small-close"
              size="xs"
              ml="10px"
              onClick={clearFile}
            />
          </Box>
        )}
      </Box>
      <SmallText color="gray.500">Maximum upload file size is 10MB</SmallText>
      {file && (
        <Box>
          <Box display="flex" flexDirection="row" alignItems="flex-start" mt="20px">
            <Checkbox
              mr="15px"
              mt="3px"
              isChecked={termsAgreed}
              onChange={() => setTermsAgreed(!termsAgreed)}
            />
            <Box className="description">
              I agree that all contacts in this import are expecting to hear from me or my
              organisation. I have a prior relationship with these contacts and I have emailed or
              messaged them at least once in the past year. I can confirm that this list wasn’t
              purchased, rented, appended, or provided by a third party.
            </Box>
          </Box>

          <Box mt="15px">
            <Button
              variant="solid"
              variantColor="blue"
              size="sm"
              isDisabled={!termsAgreed}
              onClick={() => handleFileImport()}
              isLoading={uploadLoading}
            >
              Upload
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
};
