import { Box, Flex, FlexProps } from '@chakra-ui/core';
import { BodyText, SmallSubtitle } from 'app/components';
import React, { ReactNode } from 'react';

type Props = {
  title?: string;
  children?: ReactNode;
  description?: string;
} & FlexProps;

export const EmailReportSectionHeader = (props: Props) => {
  const { title, children, description, ...rest } = props;

  return (
    <Flex
      mb="1rem"
      pb="1rem"
      alignItems="center"
      borderBottomWidth="1px"
      justifyContent="space-between"
      {...rest}
    >
      <Box>
        <SmallSubtitle color="gray.900">{title}</SmallSubtitle>
        {description && (
          <BodyText pt="0.3rem" color="gray.500">
            {description}
          </BodyText>
        )}
      </Box>
      {children}
    </Flex>
  );
};
