import {
  Alert,
  AlertDescription,
  AlertTitle,
  Badge,
  Box,
  Collapse,
  Flex,
  Icon,
  ModalBody,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/core';
import {
  BodyText,
  Button,
  Input,
  ModalContainer,
  ModalContainerOptions,
  Select,
  ToastBox,
} from 'app/components';
import { FormikHelpers, useFormik } from 'formik';
import React, { useState } from 'react';
import { track } from 'utils/segment';
import * as yup from 'yup';
import { generateAIContent } from '../../campaigns.service';

type FormValues = {
  contentType: string;
  businessType: string;
  otherContentType?: string;
  otherBusinessType?: string;
};

export const AIContentGenerationModal: React.FC<
  ModalContainerOptions & { onUseContent(content: string): void }
> = props => {
  const { onUseContent, onClose, ...rest } = props;

  const [generatedContent, setGeneratedContent] = useState('');

  const toast = useToast();

  const businessTypes = [
    'Accommodation',
    'Agriculture',
    'Arts & Design',
    'Banking',
    'E-commerce',
    'Education',
    'Entertainment',
    'Financial services',
    'Food & Drink',
    'Healthcare',
    'Logistics',
    'Non-profit',
    'Religion',
    'Technology',
    'Telecommunication',
    'Travel',
    'Other',
  ];

  const contentTypes = [
    'Appointment',
    'Birthday',
    'Confirmation',
    'Discount',
    'Feedback',
    'Giveaways',
    'Holiday',
    'Invitation',
    'Payments',
    'Promotion',
    'Program',
    'Reminder',
    'Sales',
    'Welcome',
    'Milestone',
    'Other',
  ];

  const handleCloseModal = () => {
    setGeneratedContent('');
    onClose?.();
  };

  const handleUseContent = () => {
    onUseContent(generatedContent.replaceAll('\n', ' ').replace(/^(['"])(.*)\1$/, '$2'));
    track('AI Marketing Content Used');
    handleCloseModal();
  };

  const handleGenerateContent = async (
    values: FormValues,
    formikHelpers: FormikHelpers<FormValues>,
  ) => {
    try {
      const { businessType, contentType, otherBusinessType, otherContentType } = values;
      const payload = ['sms', otherBusinessType ?? businessType, otherContentType ?? contentType];

      formikHelpers.setSubmitting(true);
      const content = await generateAIContent(payload);
      setGeneratedContent(content);
      formikHelpers.setSubmitting(false);
      track('AI Marketing Content Generated');
    } catch (error: any) {
      formikHelpers.setSubmitting(false);
      toast({
        position: 'bottom',
        render: ({ onClose }) => <ToastBox onClose={onClose} message={error} />,
      });
    }
  };

  const { values, handleChange, touched, errors, handleSubmit, isSubmitting } =
    useFormik<FormValues>({
      onSubmit: handleGenerateContent,
      initialValues: {
        contentType: '',
        businessType: '',
        otherContentType: undefined,
        otherBusinessType: undefined,
      },
      validationSchema: yup.object().shape({
        businessType: yup.string().required('Business type is required'),
        contentType: yup.string().required('Content type is required'),
      }),
    });

  return (
    <ModalContainer
      showCloseButton
      title="AI Generated Content"
      onClose={handleCloseModal}
      {...rest}
    >
      <ModalBody px="1.5rem" pb="1.5rem">
        <Stack spacing="1.5rem">
          <Alert variant="top-accent" alignItems="flex-start" rounded="8px" status="success">
            <Box>
              <AlertTitle fontSize="0.875rem">
                <Stack isInline alignItems="center">
                  <Icon name="magic-wand" />
                  <Text>AI Powered Magic</Text>
                  <Badge
                    variant="solid"
                    fontSize="0.75rem"
                    alignItems="center"
                    variantColor="green"
                    display="inline-flex"
                    textTransform="capitalize"
                  >
                    New
                  </Badge>
                </Stack>
              </AlertTitle>
              <AlertDescription fontSize="0.875rem">
                Generate SMS campaign content with AI powered by GPT.
              </AlertDescription>
            </Box>
          </Alert>
          <Box>
            <form onSubmit={handleSubmit}>
              <Stack spacing="1.5rem">
                <Box>
                  <Select
                    size="sm"
                    name="businessType"
                    label="Businsess type"
                    onChange={handleChange}
                    value={values.businessType}
                    placeholder="Select a business type"
                    isInvalid={!!touched.businessType && !!errors.businessType}
                  >
                    {businessTypes.map(businessType => (
                      <option key={businessType} value={businessType}>
                        {businessType}
                      </option>
                    ))}
                  </Select>

                  {values.businessType === 'Other' && (
                    <Input
                      mt="1rem"
                      size="sm"
                      name="otherBusinessType"
                      onChange={handleChange}
                      value={values.otherBusinessType}
                      placeholder="Enter your business type"
                    />
                  )}
                </Box>
                <Box>
                  <Select
                    size="sm"
                    name="contentType"
                    label="Content type"
                    onChange={handleChange}
                    value={values.contentType}
                    placeholder="Select a content type"
                    isInvalid={!!touched.contentType && !!errors.contentType}
                  >
                    {contentTypes.map(contentType => (
                      <option key={contentType} value={contentType}>
                        {contentType}
                      </option>
                    ))}
                  </Select>
                  {values.contentType === 'Other' && (
                    <Input
                      mt="1rem"
                      size="sm"
                      name="otherContentType"
                      onChange={handleChange}
                      value={values.otherContentType}
                      placeholder="Enter your content type"
                    />
                  )}
                </Box>
                <Collapse isOpen={!!generatedContent}>
                  <Box p="1rem" rounded="8px" borderWidth="1px">
                    <BodyText fontSize="0.875rem">{generatedContent}</BodyText>
                  </Box>
                </Collapse>
                {!!generatedContent ? (
                  <Flex alignItems="center" justifyContent="space-between">
                    <Button
                      size="sm"
                      width="48%"
                      type="button"
                      variantColor="blue"
                      onClick={handleUseContent}
                    >
                      Use content
                    </Button>
                    <Button
                      size="sm"
                      width="48%"
                      type="submit"
                      variant="outline"
                      isLoading={isSubmitting}
                    >
                      Generate new content
                    </Button>
                  </Flex>
                ) : (
                  <Button
                    size="sm"
                    isFullWidth
                    type="submit"
                    variantColor="blue"
                    isLoading={isSubmitting}
                  >
                    {!!generatedContent ? 'Generate new content' : 'Generate content'}
                  </Button>
                )}
              </Stack>
            </form>
          </Box>
        </Stack>
      </ModalBody>
    </ModalContainer>
  );
};
