import React, { useState } from 'react';
import { Box } from '@chakra-ui/core';
import { Button, Input } from '../../../../../../../components';
import { useFormik } from 'formik';
import * as yup from 'yup';

interface Props {
  value: string;
  updateButtonText: Function;
}

export const EditableButtonSection = (props: Props) => {
  const [value, setValue] = useState<string>(props.value || 'Submit');
  const [editorActive, setEditorActive] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const validationSchema = yup.object().shape({
    submit_button_text: yup
      .string()
      .required('Button text is required')
      .max(18, 'Button text must be less than 18 characters')
      .min(2, 'Button text must be at least 2 characters'),
  });

  const formik = useFormik({
    validationSchema: validationSchema,
    enableReinitialize: true,
    initialValues: { submit_button_text: value || 'Submit' },
    onSubmit: values => handleSave(values),
  });

  const handleSave = async (values: any) => {
    setIsLoading(true);
    await props.updateButtonText(values.submit_button_text);
    setValue(values.submit_button_text);
    setEditorActive(false);
    setIsLoading(false);
  };
  const handleCancel = () => {
    setEditorActive(false);
    formik.resetForm();
  };

  return (
    <Box
      marginTop="10px"
      width="35rem"
      padding="20px"
      alignSelf="center"
      display="flex"
      flexDirection="row"
      alignItems="center"
    >
      {!editorActive && (
        <>
          <Button variantColor="blue" size="sm" fontWeight="500">
            {value}
          </Button>
          <Box ml="20px" display="flex" flexDirection="row" alignItems="center">
            <Button fontSize="12px" variant="link" onClick={() => setEditorActive(true)}>
              Edit label
            </Button>
          </Box>
        </>
      )}

      {editorActive && (
        <Box display="flex" width="100%" height="3rem">
          <Input
            label="Button Text"
            size="sm"
            name="submit_button_text"
            value={formik.values.submit_button_text}
            onBlur={formik.handleBlur('submit_button_text')}
            onChange={formik.handleChange}
            isInvalid={!!formik.errors.submit_button_text}
            errorMessage={formik.errors.submit_button_text || ''}
            autoFocus
          />
          <Box display="flex" flexDirection="row" ml="5px">
            <Button
              size="sm"
              mr="5px"
              fontWeight={500}
              variant="outline"
              variantColor="green"
              onClick={formik.handleSubmit}
              isLoading={isLoading}
            >
              Save
            </Button>
            <Button
              size="sm"
              fontWeight={500}
              variant="outline"
              variantColor="red"
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
};
