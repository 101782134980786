import {
  Box,
  Button,
  Flex,
  FormControl,
  Grid,
  Icon,
  Select,
  Input,
  Spinner,
  useDisclosure,
  IconButton,
} from '@chakra-ui/core';
import React, { useContext, useEffect, useRef, useState } from 'react';
import JSONInput from 'react-json-editor-ajrm';
//@ts-ignore
import locale from 'react-json-editor-ajrm/locale/en';
import {
  RequestParamsContext,
  RequestArrayProps,
} from '../../../contexts/request-contexts/params.context';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/core';
import { fetchCustomAppSchema } from 'app/authenticated-app/lists/thunks/resources';
import { useDispatch } from 'react-redux';

interface Prop {
  n: RequestArrayProps;
  requestArray: RequestArrayProps[];
}

const ResponseBody = (props: Prop) => {
  const {
    responseBody,
    testResultResponse,
    setTestResultResponse,
    isJsonAvailableResponse,
    jsonResponse,
    isResponseSelectorValid,
    arrayKey,
    schemaResponse,
    setSchemaResponse,
  } = useContext(RequestParamsContext);
  const [focus, setFocus] = useState(false);
  const focusRef = useRef<any>();

  function useOutsideAlerter(ref: any) {
    useEffect(() => {
      function handleClickOutside(e: any) {
        if (ref.current && !ref.current.contains(e.target)) {
          setFocus(false);
        } else if (ref.current && ref.current.contains(e.target)) setFocus(true);
      }
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  }

  const changeHandler = async (e: any) => {
    setFocus(true);
    responseBody[props.n?.id] = e.jsObject;
    await fetchSchema();
  };

  useOutsideAlerter(focusRef);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const JSONResponsePromptModal = (responseProps: any) => {
    const [isCopied, setIsCopied] = useState(false);
    async function copyTextToClipboard(text: any) {
      setIsCopied(true);
      if ('clipboard' in navigator) {
        return await navigator.clipboard.writeText(text);
      } else {
        return document.execCommand('copy', true, text);
      }
    }

    return (
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        isCentered={true}
        size={'lg'}
        blockScrollOnMount={false}
      >
        <ModalOverlay />
        <ModalContent borderRadius="10px">
          <ModalHeader fontSize={'16px'} fontWeight={'500'} paddingLeft={'1rem'}>
            Response Body{' '}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody paddingX={'1rem'}>
            <Box
              className=""
              borderStyle={'solid'}
              borderWidth={'1px'}
              borderLeftWidth={'1px'}
              marginTop={'-1rem'}
              borderRadius="4px"
            >
              <JSONInput
                theme="light_mitsuketa_tribute"
                placeholder={responseProps.responseBody}
                locale={locale}
                width="inherit"
                height="27rem"
                confirmGood={false}
                style={{
                  body: {
                    fontSize: '11px',
                  },
                  labelColumn: {
                    paddingRight: '2.5rem',
                  },
                }}
                viewOnly={true}
                colors={{
                  keys: '#AB1010',
                  default: '#6D7580',
                  number: '#36B37E',
                  string: '#026ae8CC',
                  primitive: '#211791',
                }}
                onChange={(e: any) => e}
              />
            </Box>
          </ModalBody>

          <ModalFooter paddingTop={'0.5rem'} paddingX={'1rem'}>
            <Button
              size={'md'}
              rightIcon="copy"
              variantColor="blue"
              borderRadius={'8px'}
              onClick={() => copyTextToClipboard(JSON.stringify(responseProps.responseBody))}
            >
              {isCopied ? 'Copied' : 'Copy'}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  };

  const closeHandler = (n: any) => {
    testResultResponse[n] = [false, testResultResponse[n][1]];
    setTestResultResponse([...testResultResponse]);
  };

  const dispatch = useDispatch();
  const fetchSchema = async () => {
    const schemaData = (await dispatch(fetchCustomAppSchema(responseBody[props.n?.id]))) as any;
    schemaResponse[props.n?.id] = schemaData;
    setSchemaResponse([...schemaResponse]);
  };

  const toTitleCase = (phrase: string) => {
    return phrase
      .toLowerCase()
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  const schemaChangeHandler = (e: any, index: number) => {
    const newValue = e.target.value.toUpperCase();
    schemaResponse[props.n?.id][index][1] = newValue;
    setSchemaResponse([...schemaResponse]);
  };

  const schemaDeletionHandler = (index: number) => {
    schemaResponse[props.n?.id].splice(index, 1);
    setSchemaResponse([...schemaResponse]);
  };

  return (
    <>
      {responseBody[props.n?.id] !== null ? (
        <Box>
          {testResultResponse[props.n?.id][0] === true &&
          testResultResponse[props.n?.id][1] === true &&
          isResponseSelectorValid[props.n?.id] === true ? (
            <Box className="custom-app--request-test-success-response">
              <Grid templateColumns="repeat(1, 1fr 30fr 1fr)" gap={'0rem'} mt={0}>
                <Box>
                  <Icon name="alert-success" size="32px" color="blue.500"></Icon>
                </Box>
                <Box pl={'0.75rem'}>
                  <Box className="custom-app--request-test-success-title">
                    We've tested your request. It works! We have also filled in the expected
                    response for you.
                  </Box>
                  {isJsonAvailableResponse ? (
                    <Box className="custom-app--request-test-success-detail" mt={'0.2rem'}>
                      Click{' '}
                      <span onClick={onOpen} className="custom-app--request-test-success-expand">
                        here
                      </span>{' '}
                      to view your JSON response.
                      <JSONResponsePromptModal responseBody={jsonResponse[props.n?.id]} />
                    </Box>
                  ) : (
                    <Box className="custom-app--request-test-success-detail" mt={'0.2rem'}>
                      Your response does not return JSON.
                    </Box>
                  )}
                </Box>
                <Box paddingTop={'0.45rem'}>
                  <Icon
                    className="custom-app--request-test-close"
                    name="close"
                    size="14px"
                    color="grey"
                    onClick={() => closeHandler(props.n?.id)}
                  ></Icon>
                </Box>
              </Grid>
            </Box>
          ) : testResultResponse[props.n?.id][0] === true &&
            testResultResponse[props.n?.id][1] === true &&
            isResponseSelectorValid[props.n?.id] === false ? (
            <Box className="custom-app--request-test-success-response">
              <Grid templateColumns="repeat(1, 1fr 30fr 1fr)" gap={'0rem'} mt={0}>
                <Box>
                  <Icon name="alert-error" size="32px" color="blue.500"></Icon>
                </Box>
                <Box pl={'0.75rem'}>
                  <Box className="custom-app--request-test-success-title">
                    Your request works but there is no key '{arrayKey[props.n?.id]}' in the response
                    object. Please, check your settings.
                  </Box>
                  {isJsonAvailableResponse ? (
                    <Box className="custom-app--request-test-success-detail" mt={'0.2rem'}>
                      Click{' '}
                      <span onClick={onOpen} className="custom-app--request-test-success-expand">
                        here
                      </span>{' '}
                      to view your JSON response.
                      <JSONResponsePromptModal responseBody={jsonResponse[props.n?.id]} />
                    </Box>
                  ) : (
                    <Box className="custom-app--request-test-success-detail" mt={'0.2rem'}>
                      Your response does not return JSON.
                    </Box>
                  )}
                </Box>
                <Box paddingTop={'0.45rem'}>
                  <Icon
                    className="custom-app--request-test-close"
                    name="close"
                    size="14px"
                    color="grey"
                    onClick={() => closeHandler(props.n?.id)}
                  ></Icon>
                </Box>
              </Grid>
            </Box>
          ) : testResultResponse[props.n?.id][0] === true &&
            testResultResponse[props.n?.id][1] === false ? (
            <Box className="custom-app--request-test-failure-response">
              <Grid templateColumns="repeat(1, 1fr 30fr 1fr)" gap={'0rem'} mt={0}>
                <Box>
                  <Icon name="alert-error" size="32px" color="#DA1414"></Icon>
                </Box>
                <Box pl={'0.75rem'}>
                  <Box className="custom-app--request-test-failure-title">
                    Something went wrong with your request. The test was not successful.
                  </Box>
                  <Box className="custom-app--request-test-failure-detail" mt={'0.2rem'}>
                    Please, check your values.
                  </Box>
                </Box>
                <Box paddingTop={'0.45rem'}>
                  <Icon
                    className="custom-app--request-test-close"
                    name="close"
                    size="14px"
                    color="grey"
                    onClick={() => closeHandler(props.n?.id)}
                  ></Icon>
                </Box>
              </Grid>
            </Box>
          ) : (
            <></>
          )}
          <Box marginBottom="0.5rem" marginTop="0.3rem" className="custom-app--request-body-title">
            <Box fontWeight={500}>
              The response of your request is automatically filled if your app data is filled
              correctly.
            </Box>
            <Box>You could also edit it or paste another object.</Box>
          </Box>
          <Flex>
            <Box
              w="50.7rem"
              h="100%"
              ref={focusRef}
              className="custom-app--request-container"
              border={focus ? '1px solid #026ae8BB' : '1px solid #A5ABB3'}
            >
              <JSONInput
                theme="light_mitsuketa_tribute"
                placeholder={responseBody[props.n?.id]}
                locale={locale}
                width="100%"
                height="400px"
                confirmGood={false}
                style={{
                  body: {
                    fontSize: '12px',
                  },
                }}
                colors={{
                  keys: '#AB1010',
                  default: '#6D7580',
                  number: '#36B37E',
                  string: '#026ae8CC',
                  primitive: '#211791',
                }}
                onChange={(e: any) => changeHandler(e)}
              />
            </Box>
          </Flex>
        </Box>
      ) : (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          paddingTop={'6.5rem'}
          paddingBottom={'5rem'}
        >
          <Spinner color="blue.500" size="md" thickness="3px" speed="0.9s" emptyColor="gray.200" />
        </Box>
      )}

      {responseBody[props.n?.id] && schemaResponse[props.n?.id][0] ? (
        <Box marginTop={'1.65rem'}>
          <Box fontSize={'0.85rem'} color={'black'} marginBottom={'1.2rem'}>
            <Box fontWeight={500} marginBottom={'0.2rem'}>
              {' '}
              Generated Schema for list imports:{' '}
            </Box>
            <Box>You could edit the type of each key from the dropdowns at the right.</Box>
          </Box>

          {schemaResponse[props.n?.id].map((value: any[], index: number, array: any[]) => (
            <Grid templateColumns="repeat(1, 50fr 50fr 1fr)" gap={5} marginTop={'0.7rem'}>
              <Box className="field custom-app-field-settings">
                <Input placeholder="" value={value[0]} size="sm" backgroundColor="#FAFAFA" />
              </Box>

              <Box className="field custom-app-field-settings">
                <FormControl>
                  <Select
                    color="black"
                    value={value[1] === 'URL' ? value[1] || '' : toTitleCase(value[1]) || ''}
                    onChange={(e: any) => schemaChangeHandler(e, index)}
                    size="sm"
                    backgroundColor="#FAFAFA"
                  >
                    <option key="Text" value="Text">
                      Text
                    </option>
                    <option key="Number" value="Number">
                      Number
                    </option>
                    <option key="Phone Number" value="Phone Number">
                      Phone Number
                    </option>
                    <option key="Email" value="Email">
                      Email
                    </option>
                    <option key="Date" value="Date">
                      Date
                    </option>
                    <option key="URL" value="URL">
                      URL
                    </option>
                  </Select>
                </FormControl>
              </Box>

              <IconButton
                aria-label="delete"
                // @ts-ignore
                icon="trash"
                size="xs"
                borderRadius="10px"
                isDisabled={array.length === 1}
                onClick={() => schemaDeletionHandler(index)}
                color={'black'}
                marginTop={'0.25rem'}
                width={'1rem'}
              />
            </Grid>
          ))}
        </Box>
      ) : responseBody[props.n?.id] && !schemaResponse[props.n?.id][0] ? (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          paddingTop={'6.5rem'}
          paddingBottom={'5rem'}
        >
          <Spinner color="blue.500" size="md" thickness="3px" speed="0.9s" emptyColor="gray.200" />
        </Box>
      ) : (
        <Box></Box>
      )}
    </>
  );
};

export default ResponseBody;
