import { Box } from '@chakra-ui/core';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';

const glowingBorderKeyframe = keyframes`
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
`;

export const SmsCampaignFormContainer = styled(Box)`
  .editable {
    padding: 0 !important;
    border: none !important;
    border-radius: 0 !important;

    &:focus {
      border: none !important;
      box-shadow: none !important;
    }

    &:hover {
      border: none !important;
      box-shadow: none !important;
    }
  }
  .ai-button:after {
    z-index: -1;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: #09101d;
    left: 0;
    top: 0;
    border-radius: 8px;
  }

  .ai-button:before {
    content: 'hello';
    background: linear-gradient(
      45deg,
      #ff0000,
      #ff7300,
      #fffb00,
      #48ff00,
      #00ffd5,
      #002bff,
      #7a00ff,
      #ff00c8,
      #ff0000
    );
    position: absolute;
    top: -2px;
    left: -2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    -webkit-filter: blur(5px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    animation: ${glowingBorderKeyframe} 20s linear infinite;
    transition: opactiy 0.3s ease-in-out;
    border-radius: 8px;
  }
`;
