import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Badge,
  Box,
  Checkbox,
  CloseButton,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  Icon,
  IconButton,
  Link,
  Popover,
  PopoverContent,
  PopoverTrigger,
  PseudoBox,
  Stack,
  Text,
  Tooltip,
} from '@chakra-ui/core';
import {
  DashboardTable,
  SMSReportTableItem,
} from 'app/authenticated-app/marketing/dashboard/components';
import { Editable } from 'app/authenticated-app/marketing/templates/components/Editable';
import { html2Text } from 'app/authenticated-app/marketing/templates/templates.utils';
import { SMSSenderProfileModal } from 'app/authenticated-app/settings/component/sender-profiles/sms-sender-profile-modal';
import { SelectOptions } from 'app/authenticated-app/tables';
import {
  AdvancedSelect,
  BodyText,
  Button,
  ConfirmModal,
  EmptyState,
  Input,
  Menu,
  MenuItem,
  SmallSubtitle,
  Subtitle,
  TableDropdown,
  XSmallText,
} from 'app/components';
import Picker from 'emoji-picker-react';
import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { numberWithCommas, stringSplice } from 'utils';
import { CampaignData, CampaignPayload } from '../../campaigns.types';
import { campaignStateLabel, campaignStatusColors } from '../../campaigns.utils';
import { useSMSCampaign } from '../../hooks/use-sms-campaign';
import { plannerConnector } from '../../planner.connector';
import { PlannerPropsWithRedux } from '../../planner.types';
import {
  CampaignLinkModal,
  NewAudienceModal,
  PhoneContainer,
  SendCampaignModal,
  TemplateSelectModal,
  TestMessageModal,
} from '../planner';
import { AIContentGenerationModal } from '../planner/ai-content-generation-modal';
import { SmsCampaignFormContainer } from './container';
import { TemplateSuggestionButton } from './template-suggestion-button';

const Form = (
  props: PlannerPropsWithRedux & {
    isLoading?: boolean;
    initialValues?: CampaignPayload;
    onSubmit: (payload: Partial<CampaignPayload>) => void;
  },
) => {
  const {
    onSubmit,
    isLoading,
    addAudience,
    fetchWallet,
    editTemplate,
    initialValues,
    fetchTemplates,
    credit_balance,
    importBulkUpload,
    templateCategories,
    fetchSampleTemplates,
    saveCampaignDraft,
    updateCampaign,
    wallet,
  } = props;
  const { name, clicks, total_sent, total_dnd, total_delivered } = initialValues ?? {};

  const defaultIntialValues = {
    name: '',
    count: 0,
    content: '',
    sender_id: '',
    table_id: null,
    import_id: null,
    campaign_id: '',
    segment_id: null,
    audience_id: null,
    smart_sending: '1',
    variants_count: '',
    smart_list_id: null,
  };

  const history = useHistory();
  const {
    tags,
    isOpen,
    loading,
    allLists,
    smsCount,
    getContent,
    formikValues,
    onEmojiClick,
    getInputProps,
    characterCount,
    getAudienceValue,
    handleSaveAsDraft,
    onOpenTemplateModal,
    hasNoPhoneNumberType,
    handleAudienceChange,
    onOpenDropdownOptions,
    onCloseDropdownOptions,
    handleEditableChange,
    onOpenCampaignLinkModal,
    testMessageModalIsOpen,
    handleSendTestMessage,
    onCloseTestMessageModal,
    allStates,
    allGenders,
    allReligions,
    allEthnicGroups,
    isOpenNewAudienceModal,
    onCloseNewAudienceModal,
    handleCreateNewAudience,
    audiences_by_id,
    templateModalIsOpen,
    onCloseTemplateModal,
    handleSelectTemplate,
    handleShortenLink,
    campaignLinkModalIsOpen,
    onCloseCampaignLinkModal,
    sendCampaignIsOpen,
    onCloseSendCampaignModal,
    onOpenTestMessageModal,
    handleCampaignDelete,
    isDeletingCampaign,
    deleteCampaignModalIsOpen,
    getTableActions,
    onCloseDeleteCampaignModal,
    handleCampaignFrequencyCheck,
    handleGetSuggestedTemplates,
    frequencyType,
    suggestedTemplates,
    editorPlaceholder,
    setEditorPlaceholder,
    sender_ids,
    isSMSSenderProfileModalOpen,
    onOpenSMSSenderProfileModal,
    onCloseSMSSenderProfileModal,
    createSMSSenderProfileMutate,
    isCreatingEmailSenderProfile,
    isNotRegularText,
    setIsNotRegularText,
    isPastTime,
    onCloseIsPastTimeBanner,
    copyCampaignModalIsOpen,
    onCloseCopyCampaignModal,
    handleDuplicateCampaign,
    editableRef,
    handleEditableBlur,
    getCaretPosition,
    isAIContentGenerationModalOpen,
    onOpenAIContentGenerationModal,
    onCloseAIContentGenerationModal,
  } = useSMSCampaign({
    onSubmit,
    fetchWallet,
    addAudience,
    editTemplate,
    updateCampaign,
    initialValues: initialValues
      ? {
          ...defaultIntialValues,
          ...initialValues,
          campaign_id:
            initialValues?.smart_list_id ||
            initialValues?.table_id ||
            initialValues?.import_id ||
            initialValues?.segment_id ||
            initialValues?.audience_id,
        }
      : defaultIntialValues,
    importBulkUpload,
    saveCampaignDraft,
  });

  const { values, errors, touched, setFieldValue, handleChange } = formikValues;

  const formIsDisabled = values.state?.toLowerCase() !== 'draft';

  const isPaused = values.state?.toLowerCase() === 'paused';

  const estimatedCost = useMemo(() => {
    return (
      (values.count ?? 0) *
      (wallet.pricing['breakdown']['NG']['sms.non-dnd'] / 100) *
      (smsCount || 1)
    );
  }, [smsCount, values.count, wallet.pricing]);

  const renderCampaignTableItem = (item: any, index: number) => {
    return <SMSReportTableItem item={item} key={`${index}`} />;
  };

  return (
    <SmsCampaignFormContainer mx="auto" maxW="840px">
      {hasNoPhoneNumberType && (
        <Alert mb="1rem" status="error" rounded="4px">
          <AlertIcon />
          <AlertTitle mr={2}>Error</AlertTitle>
          <AlertDescription fontSize="0.875rem">
            Selected list has no phone number column. Select a list with phone number column.
          </AlertDescription>
          <Link
            pl="0.2rem"
            color="blue.500"
            fontSize="0.875rem"
            target="_blank"
            href="https://support.simpu.co/en/articles/6788747-changing-column-property-type-s-in-data"
          >
            Learn more
          </Link>
        </Alert>
      )}
      {isNotRegularText && (
        <Alert mb="1rem" status="warning" rounded="4px">
          <AlertIcon />
          <AlertTitle mr={2}>Info</AlertTitle>
          <AlertDescription fontSize="0.875rem">
            Content with emojis and the @ sign will attract extra charges
          </AlertDescription>
          <Link
            pl="0.2rem"
            color="blue.500"
            fontSize="0.875rem"
            target="_blank"
            href="https://support.simpu.co/en/articles/6829317-sms-campaign-exceptions"
          >
            Learn more
          </Link>
          <CloseButton
            top="8px"
            size="sm"
            right="8px"
            position="absolute"
            onClick={() => setIsNotRegularText(false)}
          />
        </Alert>
      )}
      {isPastTime && (
        <Alert mb="1rem" status="warning" rounded="4px">
          <AlertIcon />
          <AlertDescription fontSize="0.875rem">
            Campaign sent after 8PM WAT through promotional routes will be rescheduled to go out by
            8AM the following day, as per the NCC restriction.{' '}
            <Link
              pl="0.2rem"
              color="blue.500"
              fontSize="0.875rem"
              target="_blank"
              href="https://support.simpu.co/en/articles/6829317-sms-campaign-exceptions"
            >
              Learn more
            </Link>
          </AlertDescription>

          <CloseButton
            size="sm"
            top="8px"
            right="8px"
            position="absolute"
            onClick={onCloseIsPastTimeBanner}
          />
        </Alert>
      )}
      <Box mb="4rem">
        <Flex mb="1rem" alignItems="center" justifyContent="space-between">
          <Stack isInline alignItems="center">
            <IconButton
              size="sm"
              rounded="8px"
              variant="ghost"
              aria-label="Back"
              icon="arrow-back"
              borderWidth="1px"
              onClick={() => history.goBack()}
            />
            <Box>
              <Subtitle>{values.name || 'New campign'}</Subtitle>
            </Box>
            <Badge
              variant="outline"
              textTransform="capitalize"
              variantColor={campaignStatusColors[values.state ?? 'draft']}
            >
              {campaignStateLabel[values.state ?? 'draft']}
            </Badge>
          </Stack>
          <Stack isInline alignItems="center">
            {initialValues?.content && (
              <Box>
                <TableDropdown<CampaignData>
                  data={values}
                  isOpen={isOpen}
                  open={onOpenDropdownOptions}
                  close={onCloseDropdownOptions}
                  actions={getTableActions?.(values) ?? []}
                />
              </Box>
            )}
          </Stack>
        </Flex>
        {!['draft', 'pristine'].includes(values?.state ?? '') && (
          <DashboardTable<any>
            renderItem={renderCampaignTableItem}
            data={[
              {
                title: name,
                dnd: total_dnd,
                sent: total_sent,
                delivered: total_delivered,
                links: clicks?.total_clicks,
                cvr:
                  clicks?.total_clicks && total_sent
                    ? Math.ceil((clicks?.total_clicks / total_sent) * 100)
                    : 0,
              },
            ]}
            headings={['title', 'sent', 'delivered', 'DND', 'Links opened', 'CVR', '']}
          />
        )}
      </Box>
      <Stack pb="100px" isInline spacing="4rem" alignItems="flex-start">
        <input {...getInputProps()} />
        <Box w="50%" flex={1}>
          <FormControl mb="2rem" isInvalid={!!touched.campaign_id && !!errors.campaign_id}>
            <AdvancedSelect
              isGroup
              isSearchable
              options={allLists}
              value={getAudienceValue()}
              isDisabled={formIsDisabled || isPaused}
              label={
                <Stack isInline alignItems="center">
                  <Text>Send to</Text>
                  <Tooltip
                    zIndex={10000}
                    placement="right"
                    aria-label="Campaign recipients"
                    label="Choose from your list, smart lists, segments, or target new audiences."
                  >
                    <Icon size="0.75rem" name="info" color="gray.500" />
                  </Tooltip>
                </Stack>
              }
              placeholder="Search & Select a list"
              onChange={({ value }) => handleAudienceChange(value)}
              isInvalid={!!touched.campaign_id && !!errors.campaign_id}
            />
            <FormErrorMessage>{errors.campaign_id}</FormErrorMessage>
          </FormControl>
          <Box mb="2rem">
            <Input
              type="text"
              name="name"
              value={values.name}
              label="Campaign name"
              onChange={handleChange}
              errorMessage={errors.name}
              isDisabled={formIsDisabled || isPaused}
              onBlur={() => {
                if (values.name) {
                  handleCampaignFrequencyCheck(values.name);
                  handleGetSuggestedTemplates(values.name);
                }
              }}
              placeholder="Give your campaign a name"
              isInvalid={!!touched.name && !!errors.name}
            />
          </Box>
          <Box mb="2rem">
            <FormControl isInvalid={!!touched.sender_id && !!errors.sender_id}>
              <AdvancedSelect
                options={sender_ids?.map((item: any) => ({
                  value: item.name,
                  label: item.name,
                }))}
                value={values.sender_id}
                isDisabled={formIsDisabled || isPaused}
                placeholder="Give your campaign a sender ID"
                isInvalid={!!touched.sender_id && !!errors.sender_id}
                onChange={({ value }) => setFieldValue('sender_id', value)}
                label={
                  <Stack isInline alignItems="center">
                    <Text>Campaign sender ID</Text>
                    <Tooltip
                      zIndex={10000}
                      placement="right"
                      aria-label="Campaign sender id"
                      label="A Sender ID is a quick way to let recipients of your campaign know exactly who it is from."
                    >
                      <Icon size="0.75rem" name="info" color="gray.500" />
                    </Tooltip>
                  </Stack>
                } //@ts-ignore
                noOptionsMessage={() => (
                  <EmptyState
                    width="320px"
                    heading="Add an SMS sender ID"
                    subheading="Standout in your customer's inbox with unique sender ID for your organisation."
                  >
                    <Button size="sm" variantColor="blue" onClick={onOpenSMSSenderProfileModal}>
                      Add SMS Sender ID
                    </Button>
                  </EmptyState>
                )}
              />
              <FormErrorMessage>{errors.sender_id}</FormErrorMessage>
            </FormControl>
          </Box>
          {!!suggestedTemplates.length && (
            <Box p="0.5rem 1rem" bg="gray.50" roundedTopLeft="8px" roundedTopRight="8px">
              <BodyText mb="0.5rem" color="gray.500">
                Here are a few templates to start your campaign.
              </BodyText>
              <Stack isInline>
                {suggestedTemplates.map((template, i) => (
                  <TemplateSuggestionButton
                    key={`${i}`}
                    onClick={() => handleEditableChange(template)}
                    onMouseOver={() => {
                      setEditorPlaceholder(template);
                    }}
                    onMouseOut={() => {
                      setEditorPlaceholder('Write SMS content here...');
                    }}
                  >
                    {template}
                  </TemplateSuggestionButton>
                ))}
                <TemplateSuggestionButton width="auto" onClick={onOpenTemplateModal}>
                  More templates
                </TemplateSuggestionButton>
              </Stack>
            </Box>
          )}
          <Box mb="2rem">
            <FormControl isInvalid={!!touched.content && !!errors.content}>
              <Box
                p="0.5rem"
                borderWidth="1px"
                borderRadius="8px"
                borderColor={!!touched.content && !!errors.content ? '#e53e3e' : '#e2e8f0'}
                boxShadow={!!touched.content && !!errors.content ? '0 0 0 1px #e53e3e' : 'none'}
              >
                <Flex flexDirection="column">
                  {!formIsDisabled && (
                    <Flex justifyContent="flex-end">
                      <Menu
                        options={tags ?? []}
                        menuListProps={{
                          minW: '80px',
                          width: 'auto',
                          height: '150px',
                          overflowY: 'auto',
                          placement: 'auto-end',
                        }}
                        menuButtonProps={{
                          px: '0',
                          minW: '0',
                          children: (
                            <Tooltip
                              label="Tags"
                              placement="top"
                              aria-label="Tags"
                              shouldWrapChildren
                            >
                              <Button
                                size="xs"
                                variant="ghost"
                                color="gray.500"
                                isDisabled={formIsDisabled || !tags?.length}
                                //@ts-ignore
                                leftIcon="marketing-campaign-design-tags"
                              >
                                Personalize
                              </Button>
                            </Tooltip>
                          ),
                        }}
                        renderItem={({ label, value }: SelectOptions) => (
                          <MenuItem
                            fontSize="0.875rem"
                            onClick={() => {
                              const caretPosition = getCaretPosition();
                              const string = stringSplice(
                                html2Text(values.content ?? ''),
                                caretPosition,
                                ` {{${value}}} `,
                              );
                              handleEditableChange(string);
                            }}
                          >
                            <XSmallText>{label}</XSmallText>
                          </MenuItem>
                        )}
                      />
                    </Flex>
                  )}
                  <Box pb="0.5rem" flex={1}>
                    <Editable
                      innerRef={editableRef}
                      isDisabled={formIsDisabled}
                      onBlur={handleEditableBlur}
                      onChange={handleEditableChange}
                      placeholder={editorPlaceholder}
                      value={getContent(values.content)}
                    />
                  </Box>
                  <Flex alignItems="center" justifyContent="flex-end">
                    <PseudoBox as="span" fontSize="0.7rem">
                      {characterCount}
                    </PseudoBox>
                    <Divider mx="0.4rem" height="0.75rem" orientation="vertical" />
                    <PseudoBox as="span" fontSize="0.7rem">
                      {smsCount} SMS
                    </PseudoBox>
                  </Flex>
                </Flex>
                <Stack pt="0.5rem" isInline borderTopWidth="1px" alignItems="center">
                  <Box>
                    <Popover placement="bottom-end">
                      <PopoverTrigger>
                        <Button
                          size="xs"
                          variant="outline"
                          variantColor="blue"
                          isDisabled={formIsDisabled}
                        >
                          <Tooltip
                            label="Emoji"
                            placement="top"
                            aria-label="emoji"
                            shouldWrapChildren
                          >
                            <Icon name="happy" size="0.8rem" />
                          </Tooltip>
                        </Button>
                      </PopoverTrigger>
                      <PopoverContent
                        width="auto"
                        zIndex={4}
                        boxShadow="0px 5px 20px rgba(21, 27, 38, 0.08)"
                        _focus={{ boxShadow: '0px 5px 20px rgba(21, 27, 38, 0.08)' }}
                      >
                        <Picker onEmojiClick={onEmojiClick} preload={true} />
                      </PopoverContent>
                    </Popover>
                  </Box>
                  <Box>
                    <Tooltip
                      placement="top"
                      aria-label="add"
                      label="Use your custom templates or one of our pre-written ones."
                    >
                      <IconButton
                        size="xs"
                        rounded="8px"
                        icon="copy"
                        aria-label="copy"
                        variant="outline"
                        variantColor="blue"
                        isDisabled={formIsDisabled}
                        onClick={onOpenTemplateModal}
                      />
                    </Tooltip>
                  </Box>
                  <Box>
                    <Tooltip
                      placement="top"
                      aria-label="add"
                      label="Add a shortened link to track conversion"
                    >
                      <IconButton
                        size="xs"
                        rounded="8px"
                        icon="link"
                        aria-label="link"
                        variant="outline"
                        variantColor="blue"
                        isDisabled={formIsDisabled}
                        onClick={onOpenCampaignLinkModal}
                      />
                    </Tooltip>
                  </Box>
                  <Box>
                    <Tooltip
                      placement="top"
                      aria-label="add"
                      label="AI generated content for your SMS campaign."
                    >
                      <Button
                        size="xs"
                        zIndex={0}
                        rounded="8px"
                        color="white"
                        //@ts-ignore
                        leftIcon="magic-wand"
                        variant="outline"
                        position="relative"
                        className="ai-button"
                        isDisabled={formIsDisabled}
                        aria-label="AI generated content"
                        onClick={onOpenAIContentGenerationModal}
                      >
                        Generate text with AI
                      </Button>
                    </Tooltip>
                  </Box>
                </Stack>
              </Box>
              <FormErrorMessage>{errors.content}</FormErrorMessage>
            </FormControl>
          </Box>
          <Box mb="2rem">
            <FormControl isInvalid={!!touched.is_trackable && !!errors.is_trackable}>
              <Stack isInline alignItems="center">
                <Checkbox
                  isChecked={values.is_trackable}
                  onChange={e => setFieldValue('is_trackable', e.target.checked)}
                >
                  Trackable links
                </Checkbox>

                <Tooltip
                  aria-label="Trackable links"
                  label={
                    <>
                      <SmallSubtitle fontSize="0.8rem" fontWeight="500">
                        Improve campaign engagement
                      </SmallSubtitle>
                      <BodyText fontSize="0.75rem">
                        Boost engagement with trackable links in your SMS campaign. Simpu provides
                        data on engaged recipients for effective re-engagement.{' '}
                      </BodyText>
                    </>
                  }
                >
                  <Icon name="info" />
                </Tooltip>
              </Stack>
            </FormControl>
          </Box>
          {isPaused && (
            <Button
              size="sm"
              variantColor="blue"
              leftIcon="check-circle"
              isLoading={isLoading}
              onClick={formikValues.submitForm}
              isDisabled={!values.content || hasNoPhoneNumberType}
            >
              Save updates
            </Button>
          )}
          {!(formIsDisabled || isPaused) && (
            <Stack isInline alignItems="center">
              <Button
                size="sm"
                variantColor="blue"
                leftIcon="check-circle"
                onClick={formikValues.submitForm}
                isDisabled={!values.content || hasNoPhoneNumberType}
              >
                Schedule or Send Now
              </Button>
              <Button
                size="sm"
                leftIcon="edit"
                isDisabled={!values.content}
                onClick={() => handleSaveAsDraft(values)}
              >
                Save as draft
              </Button>
            </Stack>
          )}
        </Box>
        <Box maxW="200px">
          <Box
            mb="1rem"
            px="2rem"
            py="1rem"
            width="100%"
            borderRadius="8px"
            boxShadow="0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)"
          >
            <Stack>
              <Box>
                <BodyText pb="0.5rem" fontWeight={500}>
                  Estimated recipients
                </BodyText>
                <SmallSubtitle fontSize="1.5rem">{numberWithCommas(values.count)}</SmallSubtitle>
              </Box>
              {values.count && (
                <Box>
                  <Stack isInline alignItems="center">
                    <BodyText pb="0.5rem" fontWeight={500}>
                      Estimated cost
                    </BodyText>
                    <Tooltip
                      aria-label="This is calculated based on the number of recipients and the number of SMS pages"
                      label="This is calculated based on the number of recipients and the number of SMS pages "
                    >
                      <Icon
                        top="-3px"
                        name="info"
                        size="1rem"
                        color="blue.500"
                        position="relative"
                      />
                    </Tooltip>
                  </Stack>
                  <SmallSubtitle fontSize="1.5rem" color="green.500">
                    &#8358;
                    {numberWithCommas(estimatedCost)}
                  </SmallSubtitle>
                </Box>
              )}
            </Stack>
          </Box>
          <PhoneContainer sender_id={values.sender_id}>
            {values.content && (
              <>
                <Flex
                  flex={1}
                  overflowY="auto"
                  flexDirection="column"
                  maxH="calc(100% - 25px)"
                  justifyContent="space-between"
                >
                  <Box
                    p="0.5rem"
                    width="100%"
                    bg="#f3f3f3"
                    overflowY="auto"
                    borderRadius="8px"
                    wordBreak="break-word"
                    whiteSpace="pre-line"
                  >
                    <Text fontSize="0.65rem" color="color.700">
                      {html2Text(getContent(values.content))}
                    </Text>
                  </Box>
                  {!!values.content && !formIsDisabled && (
                    <Stack mt="0.5rem" isInline alignItems="center">
                      <Button
                        size="xs"
                        isFullWidth
                        variantColor="blue"
                        onClick={onOpenTestMessageModal}
                        isDisabled={!(values.content && values.campaign_id)}
                      >
                        Send a test
                      </Button>
                    </Stack>
                  )}
                </Flex>
              </>
            )}
          </PhoneContainer>
        </Box>
      </Stack>
      {testMessageModalIsOpen && (
        <TestMessageModal
          credit_balance={credit_balance}
          isOpen={testMessageModalIsOpen}
          onSubmit={handleSendTestMessage}
          onClose={onCloseTestMessageModal}
          isLoading={loading === 'pending'}
        />
      )}
      {isOpenNewAudienceModal && (
        <NewAudienceModal
          states={allStates}
          genders={allGenders}
          religions={allReligions}
          ethnicGroups={allEthnicGroups}
          isOpen={isOpenNewAudienceModal}
          isLoading={loading === 'pending'}
          onClose={onCloseNewAudienceModal}
          onSubmit={handleCreateNewAudience}
          hasAudiences={!!audiences_by_id.length}
        />
      )}
      {templateModalIsOpen && (
        <TemplateSelectModal
          isOpen={templateModalIsOpen}
          onClose={onCloseTemplateModal}
          fetchTemplates={fetchTemplates}
          selectedTemplate={values.template_id}
          onSelectTemplate={handleSelectTemplate}
          fetchSampleTemplates={fetchSampleTemplates}
          categories={templateCategories.map(item => item.category)}
        />
      )}
      {campaignLinkModalIsOpen && (
        <CampaignLinkModal
          onSubmit={handleShortenLink}
          isOpen={campaignLinkModalIsOpen}
          isLoading={loading === 'pending'}
          onClose={onCloseCampaignLinkModal}
          initialValues={{ link: initialValues?.link }}
        />
      )}
      {sendCampaignIsOpen && (
        <SendCampaignModal
          campaign={values}
          isLoading={isLoading}
          isOpen={sendCampaignIsOpen}
          onCreateCampaign={onSubmit}
          frequencyType={frequencyType}
          credit_balance={credit_balance}
          onClose={onCloseSendCampaignModal}
        />
      )}
      {deleteCampaignModalIsOpen && (
        <ConfirmModal
          title="Delete campaign"
          isLoading={isDeletingCampaign}
          onConfirm={handleCampaignDelete}
          isOpen={deleteCampaignModalIsOpen}
          onClose={onCloseDeleteCampaignModal}
        />
      )}
      {isSMSSenderProfileModalOpen && (
        <SMSSenderProfileModal
          isOpen={isSMSSenderProfileModalOpen}
          onClose={onCloseSMSSenderProfileModal}
          isLoading={isCreatingEmailSenderProfile}
          onSubmit={(payload, helpers) => createSMSSenderProfileMutate({ payload, helpers })}
        />
      )}
      {copyCampaignModalIsOpen && (
        <ConfirmModal
          title="Duplicate campaign"
          isOpen={copyCampaignModalIsOpen}
          onClose={onCloseCopyCampaignModal}
          onConfirm={handleDuplicateCampaign}
          isLoading={loading === 'pending'}
        />
      )}
      {isAIContentGenerationModalOpen && (
        <AIContentGenerationModal
          isOpen={isAIContentGenerationModalOpen}
          onClose={onCloseAIContentGenerationModal}
          onUseContent={content => {
            setFieldValue('content', content);
          }}
        />
      )}
    </SmsCampaignFormContainer>
  );
};

export const SmsCampaignForm = plannerConnector(Form);

SmsCampaignForm.displayName = 'SmsCampaignForm';
