import {
  Box,
  ButtonProps,
  Flex,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverContentProps,
  PopoverTrigger,
  Spinner,
  Stack,
  useDisclosure,
} from '@chakra-ui/core';
import InfiniteScroll from 'react-infinite-scroll-component';
import React, { ChangeEvent, ReactNode, useRef } from 'react';
import ReactFocusLock from 'react-focus-lock';
import { BodyText, SmallSubtitle } from '..';
import { OptionMenuTriggerButton } from './button';
import { OptionMenuMultiOption, OptionMenuSingleOption } from './options';
import { SearchInput } from './search-input';

export type OptionsMenuProps<T> = {
  options?: T[];
  title?: string;
  hasMore?: boolean;
  isLoading?: boolean;
  searchQuery?: string;
  onFetchMoreData?(): void;
  type?: 'single' | 'multi';
  isInfiniteScroll?: boolean;
  onSelectItem?: (item: T) => void;
  triggerButtonProps: ButtonProps;
  onSearch?: (query: string) => void;
  isItemSelected?: (item: T) => boolean;
  renderOptions?(options?: T[]): ReactNode;
  renderItem?: (item: T, index?: number) => ReactNode;
  popoverContentProps?: Omit<PopoverContentProps, 'children'>;
};

export function OptionsMenu<T>(props: OptionsMenuProps<T>) {
  const {
    title,
    hasMore,
    options,
    onSearch,
    isLoading,
    renderItem,
    searchQuery,
    onSelectItem,
    renderOptions,
    isItemSelected,
    type = 'single',
    onFetchMoreData,
    isInfiniteScroll,
    triggerButtonProps,
    popoverContentProps,
  } = props;

  const { isOpen, onClose, onToggle } = useDisclosure();

  const searchInputRef = useRef<any>();

  const handleSelectItem = (item: T) => {
    onSelectItem?.(item);
    if (type === 'single') {
      onClose();
    }
  };

  const handleClose = () => {
    onSearch?.('');
    onClose();
  };

  const Option = type === 'single' ? OptionMenuSingleOption : OptionMenuMultiOption;

  return (
    <Popover
      isOpen={isOpen}
      onClose={handleClose}
      placement="bottom-start"
      initialFocusRef={searchInputRef}
    >
      <PopoverTrigger>
        <OptionMenuTriggerButton onClick={onToggle} {...triggerButtonProps} />
      </PopoverTrigger>
      <PopoverContent
        zIndex={10}
        rounded="8px"
        borderWidth="0"
        boxShadow="0px 5px 20px rgba(21, 27, 38, 0.08)"
        _focus={{ outline: 'none', boxShadow: '0px 5px 20px rgba(21, 27, 38, 0.08)' }}
        {...popoverContentProps}
      >
        {title && (
          <SmallSubtitle px="0.75rem" pb="0.5rem">
            {title}
          </SmallSubtitle>
        )}
        <ReactFocusLock returnFocus persistentFocus={false}>
          <PopoverBody px="0" py="0.75rem">
            {onSearch && (
              <Box px="0.75rem">
                <SearchInput
                  value={searchQuery}
                  placeholder="Search"
                  onChange={(e: ChangeEvent<HTMLInputElement>) => onSearch?.(e.target.value)}
                />
              </Box>
            )}
            <Box
              maxH="300px"
              overflowY="auto"
              pt={onSearch ? '2.5rem' : '0'}
              id="optionsMenuInfiniteScrollContainer"
            >
              {isLoading ? (
                <Flex
                  width="100%"
                  height="100%"
                  alignItems="center"
                  flexDirection="column"
                  justifyContent="center"
                >
                  <Spinner
                    size="sm"
                    speed="0.9s"
                    thickness="3px"
                    color="blue.500"
                    emptyColor="gray.200"
                  />
                </Flex>
              ) : isInfiniteScroll ? (
                <InfiniteScroll
                  hasMore={hasMore ?? false}
                  style={{ overflow: 'hidden' }}
                  next={() => onFetchMoreData?.()}
                  dataLength={options?.length ?? 0}
                  scrollableTarget="optionsMenuInfiniteScrollContainer"
                  loader={
                    <Flex alignItems="center" justifyContent="center" paddingTop="2rem">
                      <Spinner color="blue.500" size="xs" />
                    </Flex>
                  }
                  endMessage={
                    <Flex alignItems="center" justifyContent="center" paddingTop="2rem">
                      <BodyText>~ All loaded ~</BodyText>
                    </Flex>
                  }
                >
                  {renderOptions ? (
                    renderOptions(options)
                  ) : (
                    <Stack mt="0.5rem" spacing="0">
                      {options?.map((option, index) => (
                        <Option
                          item={option}
                          key={`${index}`}
                          renderItem={renderItem}
                          isSelected={isItemSelected?.(option)}
                          onSelect={() => handleSelectItem(option)}
                        />
                      ))}
                    </Stack>
                  )}
                </InfiniteScroll>
              ) : (
                options?.map((option, index) => (
                  <Option
                    item={option}
                    key={`${index}`}
                    renderItem={renderItem}
                    isSelected={isItemSelected?.(option)}
                    onSelect={() => handleSelectItem(option)}
                  />
                ))
              )}
            </Box>
          </PopoverBody>
        </ReactFocusLock>
      </PopoverContent>
    </Popover>
  );
}
