/* eslint-disable react/style-prop-object */
import {
  Box,
  BoxProps,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  PseudoBox,
} from '@chakra-ui/core';
import { $isCodeNode, getCodeLanguages, getDefaultCodeLanguage } from '@lexical/code';
import { $isLinkNode, TOGGLE_LINK_COMMAND } from '@lexical/link';
import { $isListNode, ListNode } from '@lexical/list';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $isHeadingNode } from '@lexical/rich-text';
import {
  $getSelectionStyleValueForProperty,
  $isParentElementRTL,
  $patchStyleText,
} from '@lexical/selection';
import { $getNearestNodeOfType, mergeRegister } from '@lexical/utils';
import { SelectOptions } from 'app/authenticated-app/tables';
import {
  $createParagraphNode,
  $createTextNode,
  $getNodeByKey,
  $getSelection,
  $insertNodes,
  $isRangeSelection,
  CAN_REDO_COMMAND,
  CAN_UNDO_COMMAND,
  FORMAT_ELEMENT_COMMAND,
  FORMAT_TEXT_COMMAND,
  SELECTION_CHANGE_COMMAND,
} from 'lexical';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { BlockOptionsDropdownList } from '../components/block-options-dropdown';
import { Divider } from '../components/divider';
import DropdownColorPicker from '../components/dropdown-color-picker';
import { FloatingLinkEditor } from '../components/floating-link-editor';
import { FontDropDown } from '../components/font-dropdown';
import { Select } from '../components/select';
import { getSelectedNode } from '../utils/functions';
import useModal from '../utils/use-modal';
import { InsertImageDialog } from './images';

const LowPriority = 1;

const supportedBlockTypes = new Set(['paragraph', 'quote', 'code', 'h1', 'h2', 'ul', 'ol']);

const blockTypeToBlockName: { [key: string]: string } = {
  code: 'Code Block',
  h1: 'Large Heading',
  h2: 'Small Heading',
  h3: 'Heading',
  h4: 'Heading',
  h5: 'Heading',
  ol: 'Numbered List',
  paragraph: 'Normal',
  quote: 'Quote',
  ul: 'Bulleted List',
};

export function ToolbarPlugin(props: { tags?: SelectOptions[]; containerProps?: BoxProps }) {
  const [editor] = useLexicalComposerContext();
  const toolbarRef = useRef(null);
  const [, setCanUndo] = useState(false);
  const [, setCanRedo] = useState(false);
  const [bgColor, setBgColor] = useState<string>('#fff');
  const [fontColor, setFontColor] = useState<string>('#000');
  const [blockType, setBlockType] = useState('paragraph');
  const [fontSize, setFontSize] = useState<string>('15px');
  const [fontFamily, setFontFamily] = useState<string>('Arial');
  const [selectedElementKey, setSelectedElementKey] = useState<any>(null);
  const [showBlockOptionsDropDown, setShowBlockOptionsDropDown] = useState(false);
  const [codeLanguage, setCodeLanguage] = useState('');
  const [, setIsRTL] = useState(false);
  const [isLink, setIsLink] = useState(false);
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [isUnderline, setIsUnderline] = useState(false);
  const [isStrikethrough, setIsStrikethrough] = useState(false);
  const [isCode, setIsCode] = useState(false);

  const [modal, showModal] = useModal();

  const updateToolbar = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode();
      const element =
        anchorNode.getKey() === 'root' ? anchorNode : anchorNode.getTopLevelElementOrThrow();
      const elementKey = element.getKey();
      const elementDOM = editor.getElementByKey(elementKey);
      if (elementDOM !== null) {
        setSelectedElementKey(elementKey);
        if ($isListNode(element)) {
          const parentList = $getNearestNodeOfType(anchorNode, ListNode);
          const type = parentList ? parentList.getTag() : element.getTag();
          setBlockType(type);
        } else {
          const type = $isHeadingNode(element) ? element.getTag() : element.getType();
          setBlockType(type);
          if ($isCodeNode(element)) {
            setCodeLanguage(element.getLanguage() || getDefaultCodeLanguage());
          }
        }
      }
      // Update text format
      setIsBold(selection.hasFormat('bold'));
      setIsItalic(selection.hasFormat('italic'));
      setIsUnderline(selection.hasFormat('underline'));
      setIsStrikethrough(selection.hasFormat('strikethrough'));
      setIsCode(selection.hasFormat('code'));
      setIsRTL($isParentElementRTL(selection));

      // Update links
      const node = getSelectedNode(selection);
      const parent = node.getParent();
      if ($isLinkNode(parent) || $isLinkNode(node)) {
        setIsLink(true);
      } else {
        setIsLink(false);
      }

      setFontSize($getSelectionStyleValueForProperty(selection, 'font-size', '15px'));
      setFontFamily($getSelectionStyleValueForProperty(selection, 'font-family', 'Arial'));

      setFontColor($getSelectionStyleValueForProperty(selection, 'color', '#000'));
      setBgColor($getSelectionStyleValueForProperty(selection, 'background-color', '#fff'));
    }
  }, [editor]);

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateToolbar();
        });
      }),
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        (_payload, newEditor) => {
          updateToolbar();
          return false;
        },
        LowPriority,
      ),
      editor.registerCommand(
        CAN_UNDO_COMMAND,
        payload => {
          setCanUndo(payload);
          return false;
        },
        LowPriority,
      ),
      editor.registerCommand(
        CAN_REDO_COMMAND,
        payload => {
          setCanRedo(payload);
          return false;
        },
        LowPriority,
      ),
    );
  }, [editor, updateToolbar]);

  const codeLanguges = useMemo(() => getCodeLanguages(), []);
  const onCodeLanguageSelect = useCallback(
    e => {
      editor.update(() => {
        if (selectedElementKey !== null) {
          const node = $getNodeByKey(selectedElementKey);
          if ($isCodeNode(node)) {
            node.setLanguage(e.target.value);
          }
        }
      });
    },
    [editor, selectedElementKey],
  );

  const insertLink = useCallback(() => {
    if (!isLink) {
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, 'https://');
    } else {
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
    }
  }, [editor, isLink]);

  const applyStyleText = useCallback(
    (styles: Record<string, string>) => {
      editor.update(() => {
        const selection = $getSelection();
        if ($isRangeSelection(selection)) {
          $patchStyleText(selection, styles);
        }
      });
    },
    [editor],
  );

  const onFontColorSelect = useCallback(
    (value: string) => {
      applyStyleText({ color: value });
    },
    [applyStyleText],
  );

  const onBgColorSelect = useCallback(
    (value: string) => {
      applyStyleText({ 'background-color': value });
    },
    [applyStyleText],
  );

  const onClickMergeTag = useCallback(
    (tag: string) => {
      editor.update(() => {
        const paragraphNode = $createParagraphNode();

        const textNode = $createTextNode(`{{${tag}}}`);

        paragraphNode.append(textNode);

        $insertNodes([paragraphNode]);
      });
    },
    [editor],
  );

  return (
    <Box className="toolbar" ref={toolbarRef} {...props?.containerProps}>
      {supportedBlockTypes.has(blockType) && (
        <>
          <Popover
            placement="top-start"
            isOpen={showBlockOptionsDropDown}
            onOpen={() => setShowBlockOptionsDropDown(true)}
            onClose={() => setShowBlockOptionsDropDown(false)}
          >
            <PopoverTrigger>
              <button
                type="button"
                aria-label="Formatting Options"
                className="toolbar-item block-controls"
              >
                <span className={'icon block-type ' + blockType} />
                <span className="text">{blockTypeToBlockName[blockType]}</span>
                <i className="chevron-down" />
              </button>
            </PopoverTrigger>
            <PopoverContent
              zIndex={100}
              maxW="200px"
              borderWidth="0"
              boxShadow="0 12px 28px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.1), inset 0 0 0 1px rgba(255, 255, 255, 0.5)"
              _focus={{
                boxShadow:
                  '0 12px 28px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.1), inset 0 0 0 1px rgba(255, 255, 255, 0.5)',
              }}
            >
              <PopoverBody p="0">
                <BlockOptionsDropdownList
                  editor={editor}
                  blockType={blockType}
                  toolbarRef={toolbarRef}
                  setShowBlockOptionsDropDown={setShowBlockOptionsDropDown}
                />
              </PopoverBody>
            </PopoverContent>
          </Popover>
          <Divider />
          <FontDropDown style={'font-family'} value={fontFamily} editor={editor} />
          <FontDropDown style={'font-size'} value={fontSize} editor={editor} />
          <Divider />
        </>
      )}
      {blockType === 'code' ? (
        <>
          <Select
            className="toolbar-item code-language"
            onChange={onCodeLanguageSelect}
            options={codeLanguges}
            value={codeLanguage}
          />
          <i className="chevron-down inside" />
        </>
      ) : (
        <>
          <button
            type="button"
            onClick={() => {
              editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold');
            }}
            className={'toolbar-item spaced ' + (isBold ? 'active' : '')}
            aria-label="Format Bold"
          >
            <i className="format bold" />
          </button>
          <button
            type="button"
            onClick={() => {
              editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'italic');
            }}
            className={'toolbar-item spaced ' + (isItalic ? 'active' : '')}
            aria-label="Format Italics"
          >
            <i className="format italic" />
          </button>
          <button
            type="button"
            onClick={() => {
              editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'underline');
            }}
            className={'toolbar-item spaced ' + (isUnderline ? 'active' : '')}
            aria-label="Format Underline"
          >
            <i className="format underline" />
          </button>
          <button
            type="button"
            onClick={() => {
              editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'strikethrough');
            }}
            className={'toolbar-item spaced ' + (isStrikethrough ? 'active' : '')}
            aria-label="Format Strikethrough"
          >
            <i className="format strikethrough" />
          </button>
          <button
            type="button"
            onClick={() => {
              editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'code');
            }}
            className={'toolbar-item spaced ' + (isCode ? 'active' : '')}
            aria-label="Insert Code"
          >
            <i className="format code" />
          </button>
          <button
            type="button"
            onClick={() => {
              showModal('Insert Image', onClose => (
                <InsertImageDialog activeEditor={editor} onClose={onClose} />
              ));
            }}
            aria-label="Insert Image"
            className={'toolbar-item spaced ' + (isLink ? 'active' : '')}
          >
            <i className="format image" />
          </button>
          <button
            type="button"
            onClick={insertLink}
            className={'toolbar-item spaced ' + (isLink ? 'active' : '')}
            aria-label="Insert Link"
          >
            <i className="format link" />
          </button>
          {isLink && createPortal(<FloatingLinkEditor editor={editor} />, document.body)}
          <DropdownColorPicker
            color={fontColor}
            title="text color"
            onChange={onFontColorSelect}
            buttonIconClassName="icon font-color"
            buttonAriaLabel="Formatting text color"
            buttonClassName="toolbar-item color-picker"
          />
          <DropdownColorPicker
            color={bgColor}
            title="bg color"
            onChange={onBgColorSelect}
            buttonIconClassName="icon bg-color"
            buttonClassName="toolbar-item color-picker"
            buttonAriaLabel="Formatting background color"
          />
          <Divider />
          <button
            type="button"
            onClick={() => {
              editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'left');
            }}
            className="toolbar-item spaced"
            aria-label="Left Align"
          >
            <i className="format left-align" />
          </button>
          <button
            type="button"
            onClick={() => {
              editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'center');
            }}
            className="toolbar-item spaced"
            aria-label="Center Align"
          >
            <i className="format center-align" />
          </button>
          <button
            type="button"
            onClick={() => {
              editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'right');
            }}
            className="toolbar-item spaced"
            aria-label="Right Align"
          >
            <i className="format right-align" />
          </button>
          <button
            type="button"
            onClick={() => {
              editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'justify');
            }}
            className="toolbar-item"
            aria-label="Justify Align"
          >
            <i className="format justify-align" />
          </button>
          {props.tags && (
            <>
              <Divider />

              <Popover placement="top-start">
                <PopoverTrigger>
                  <button
                    type="button"
                    aria-label="Personalise"
                    className="toolbar-item block-controls"
                  >
                    <span className="text">Personalise</span>
                    <i className="chevron-down" />
                  </button>
                </PopoverTrigger>
                <PopoverContent
                  zIndex={100}
                  borderWidth="0"
                  maxW="max-content"
                  boxShadow="0 12px 28px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.1), inset 0 0 0 1px rgba(255, 255, 255, 0.5)"
                  _focus={{
                    boxShadow:
                      '0 12px 28px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.1), inset 0 0 0 1px rgba(255, 255, 255, 0.5)',
                  }}
                >
                  <PopoverBody p="0">
                    {props.tags?.map(item => (
                      <PseudoBox
                        p="0.5rem"
                        cursor="pointer"
                        color="gray.500"
                        key={item.value}
                        fontSize="0.875rem"
                        _hover={{ bg: 'gray.50' }}
                        onClick={() => onClickMergeTag(item.value)}
                      >
                        {item.label}
                      </PseudoBox>
                    ))}
                  </PopoverBody>
                </PopoverContent>
              </Popover>
            </>
          )}
        </>
      )}

      {modal}
    </Box>
  );
}
