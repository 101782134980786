import { Box, FormControl, Grid, RadioGroup } from '@chakra-ui/core';
import { Select } from 'app/components';
import React, { useContext } from 'react';
import { AppParamsContext } from '../../../contexts/app-contexts/params.context';
import JSONInput from 'react-json-editor-ajrm';
//@ts-ignore
import locale from 'react-json-editor-ajrm/locale/en';

const ImageRadioButton = () => {
  const { RadioImageValue, setRadioImageValue } = useContext(AppParamsContext);

  const RadioItem = React.forwardRef((props: any, ref) => {
    const { isChecked, isDisabled, value, variantColor, ...rest } = props;

    return (
      <Grid templateColumns="repeat(1, 1fr 100fr)" gap={4} alignItems="start" pb="1.9rem">
        <Box mt="-0.8rem">
          <label className="custom-app-radio-label">
            <input
              className="custom-app-radio-input"
              type="radio"
              id="html"
              name="fav_language"
              value={value}
              defaultChecked={isChecked ? true : false}
              ref={ref}
              {...rest}
            ></input>
            <Box className="custom-app-radio-new"></Box>
          </label>
        </Box>

        <Box
          mt="0rem"
          borderColor={RadioImageValue === props.value ? '#026ae8' : '#858C94'}
          className="custom-app--radio-image"
        >
          <Box className="custom-app--radio-image-title">
            {props.value === '1' ? <>Array of Objects</> : <>Object containing Array of Objects</>}
          </Box>
          <Box className="">
            <JSONInput
              theme="light_mitsuketa_tribute"
              placeholder={
                props.value === '1'
                  ? [
                      { key1: 'value', key2: true },
                      { key1: 'value', key2: true },
                      { key1: 'value', key2: true },
                    ]
                  : {
                      entity: [
                        { key1: 'value', key2: true },
                        { key1: 'value', key2: true },
                      ],
                      page: 1,
                      limit: 2,
                    }
              }
              locale={locale}
              width="inherit"
              height="inherit"
              confirmGood={false}
              style={{
                body: {
                  fontSize: '12px',
                },
              }}
              viewOnly={true}
              colors={{
                keys: '#AB1010',
                default: '#6D7580',
                number: '#36B37E',
                string: '#026ae8CC',
                primitive: '#211791',
              }}
              onChange={(e: any) => e}
            />
          </Box>
        </Box>
      </Grid>
    );
  });

  return (
    <RadioGroup onChange={e => setRadioImageValue(e.target.value)} value={RadioImageValue}>
      <RadioItem value="1" />
      <RadioItem value="2" />
    </RadioGroup>
  );
};

const Settings = () => {
  const { responseType, setResponseType } = useContext(AppParamsContext);
  const responseTypes = [{ id: 'json', request_name: 'JSON' }];
  return (
    <Box>
      <Box marginTop="1.4rem" className="custom-app-field" width="50%">
        <FormControl>
          <Select
            color="black"
            label="App's Response Type"
            value={responseType || ''}
            onChange={(e: any) => setResponseType(e.target.value)}
          >
            {responseTypes.map((i: any) => (
              <option key={i.id} value={i.id}>
                {i.request_name}
              </option>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box className="custom-app--request-body-title" marginTop={'1.4rem'}>
        Select the type of JSON response expected. Placeholder examples are shown below:
      </Box>
      <Box marginTop="1.4rem" width="50%">
        <ImageRadioButton />
      </Box>
    </Box>
  );
};

export default Settings;
