import React, { useEffect, useState } from 'react';
import { ListFormWrapper as Wrapper } from './index.styles';
import {
  Box,
  Icon,
  IconButton,
  Image,
  Textarea as ChakraTextArea,
  Tooltip,
  useToast,
} from '@chakra-ui/core';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { available_properties } from 'app/authenticated-app/lists/list.data';
import { Button, Input, Textarea, ToastBox, useResellerSettings } from 'app/components';
import { Column, ListForm } from 'app/authenticated-app/lists/lists.types';
import { useFormik } from 'formik';
import { useCloudinary } from 'hooks';
import { AddedFormField } from './added-field';
import { EditableButtonSection } from './editable-button-section';
import { RedirectFieldSection } from './redirect-field-section';

interface Props {
  form: ListForm;
  updateForm: Function;
  columns_by_id: string[];
  columns: { [key: string]: Column };
  column_id_map: { [key: string]: string };
}

const reorder = (list: any[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

export const ListFormView = (props: Props) => {
  const [availableColumns, setAvailableColumns] = useState<string[]>([]);
  const [addedColumns, setAddedColumns] = useState<string[]>([]);
  const [requiredColumns, setRequiredColumns] = useState<string[]>([]);
  const [columnDescriptions, setColumnDescriptions] = useState<{ [key: string]: string | null }>(
    {},
  );
  const [activeField, setActiveField] = useState<null | string>(null);
  const [columnsInitialized, setColumnsInitialized] = useState<boolean>(false);
  const [activeAddedField, setActiveAddedField] = useState<string | null>(null);

  const listForm = props.form;

  const formik = useFormik({
    initialValues: {
      title: listForm.title,
      description: listForm.description,
      show_branding: listForm.show_branding,
      submit_message: listForm.submit_message,
      allow_resubmit: listForm.allow_resubmit,
      refresh_after_submit: listForm.refresh_after_submit,
      notify_after_submit: listForm.notify_after_submit,
      redirect_after_submit: listForm.redirect_after_submit,
    },
    onSubmit: () => {},
    enableReinitialize: true,
  });

  const toast = useToast();

  const { values, handleBlur, handleChange, touched } = formik;

  const handleFocus = (e: any) => {
    setActiveField(e.target.name);
  };

  const updateForm = async () => {
    try {
      await props.updateForm(listForm.id, values);
    } catch (e) {
      toast({
        position: 'bottom-left',
        render: ({ onClose }) => (
          <ToastBox onClose={onClose} message="Unable to update form, please try again" />
        ),
      });
    }
  };

  const updateFormMedia = async (name: string, imageData: any | null) => {
    try {
      const payload = {
        [name]: imageData?.secure_url || null,
      };
      await props.updateForm(listForm.id, payload);
    } catch (e) {
      toast({
        position: 'bottom-left',
        render: ({ onClose }) => (
          <ToastBox onClose={onClose} message="Unable to update form, please try again" />
        ),
      });
    }
  };

  const updateFormColumns = async () => {
    try {
      const columnPayload: {
        id: string | number;
        description: string | null;
        required: boolean;
      }[] = [];
      addedColumns.forEach((uid: string) => {
        columnPayload.push({
          id: props.columns[uid].id,
          description: columnDescriptions[uid],
          required: requiredColumns.includes(uid),
        });
      });

      const payload = {
        columns: columnPayload,
      };
      await props.updateForm(listForm.id, payload);
    } catch (e) {
      toast({
        position: 'bottom-left',
        render: ({ onClose }) => (
          <ToastBox onClose={onClose} message="Unable to update form, please try again" />
        ),
      });
    }
  };

  const updateButtonText = async (buttonText: string) => {
    try {
      const payload = {
        submit_button_text: buttonText,
      };
      await props.updateForm(listForm.id, payload);
    } catch (e) {
      toast({
        position: 'bottom-left',
        render: ({ onClose }) => (
          <ToastBox onClose={onClose} message="Unable to update form, please try again" />
        ),
      });
    }
  };

  const addAllColumns = () => {
    setAddedColumns([...addedColumns, ...availableColumns]);
    setAvailableColumns([]);
  };

  const removeAllColumns = () => {
    setAvailableColumns(addedColumns);
    setAddedColumns([]);
  };

  const handleDragEnd = (result: any) => {
    if (!result.destination) return;
    if (result.reason !== 'DROP') return;

    if (result.source.droppableId === result.destination.droppableId) {
      return handleReorderColumn(result);
    }

    if (result.destination.droppableId === '0') return handleRemoveColumn(result);
    if (result.destination.droppableId === '1') return handleAddColumn(result);
  };

  const handleAddColumn = ({ destination, draggableId }: any) => {
    const list = Array.from([...addedColumns, draggableId]);
    const updatedList: string[] = reorder(list, list.length - 1, destination.index);
    setAddedColumns(updatedList);
    setAvailableColumns(availableColumns.filter((i: string) => i !== draggableId));
  };

  const handleRemoveColumn = ({ destination, draggableId }: any) => {
    const list = Array.from([...availableColumns, draggableId]);
    const updatedList = reorder(list, list.length - 1, destination.index);
    setAvailableColumns(updatedList);
    setAddedColumns(addedColumns.filter((i: string) => i !== draggableId));
    setRequiredColumns(requiredColumns.filter((i: string) => i !== draggableId));
  };

  const handleReorderColumn = ({ source, destination }: any) => {
    const list = source.droppableId === '0' ? availableColumns : addedColumns;
    const updatedList = reorder(list, source.index, destination.index);
    if (source.droppableId === '0') setAvailableColumns(updatedList);
    else setAddedColumns(updatedList);
  };

  const coverImgURL = `url(${listForm.cover_img_url}#${new Date().getUTCMilliseconds()})`;

  const setColumnRequired = (columnID: string, checked: boolean) => {
    if (checked) {
      setRequiredColumns([...requiredColumns, columnID]);
    } else {
      setRequiredColumns(requiredColumns.filter((i: string) => i !== columnID));
    }
  };

  const initializeColumns = () => {
    const _addedColumns: any[] = [];
    const _requiredColumns: any[] = [];
    const _columnDescriptions: { [key: string]: string | null } = {};
    listForm.columns.forEach((i: any) => {
      // @ts-ignore
      const column: any = Object.values(props.columns).find(
        (j: any) => String(j.id) === String(i.id),
      );
      if (!column) return;
      const uid: string | number = column['uid'];
      const description: string | null = i['description'];
      _columnDescriptions[uid] = description;
      _addedColumns.push(uid);
      if (i.required) {
        _requiredColumns.push(uid);
      }
    });
    const _availableColumns: any[] = props.columns_by_id.filter(
      (i: string) => !_addedColumns.includes(i),
    );
    setColumnDescriptions(_columnDescriptions);
    setAvailableColumns(_availableColumns);
    setAddedColumns(_addedColumns);
    setRequiredColumns(_requiredColumns);
    setColumnsInitialized(true);
  };

  useEffect(() => {
    if (columnsInitialized) {
      updateFormColumns();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addedColumns, requiredColumns, columnDescriptions]);

  useEffect(() => {
    if (Object.keys(touched).length > 0) {
      updateForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeField, touched]);

  useEffect(() => {
    initializeColumns();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { name } = useResellerSettings();

  const coverImageWidget = useCloudinary({
    allowCrop: true,
    croppingAspectRatio: 4,
    preset: 'list-media',
    clientAllowedFormats: ['image'],
    handleUpload: (imageData: any) => updateFormMedia('cover_img_url', imageData),
  });

  const logoImageWidget = useCloudinary({
    allowCrop: true,
    preset: 'list-media',
    croppingAspectRatio: 1,
    showSkipCropButton: true,
    clientAllowedFormats: ['image'],
    handleUpload: (imageData: any) => updateFormMedia('logo_img_url', imageData),
  });

  return (
    <Wrapper>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Box className="field-panel">
          <Box className="header">
            <Box className="title">Fields</Box>
            <Box className="actions">
              {!!addedColumns.length && (
                <Box className="action-item" onClick={removeAllColumns}>
                  remove all
                </Box>
              )}
              {!!availableColumns.length && (
                <Box className="action-item" onClick={addAllColumns}>
                  add all
                </Box>
              )}
            </Box>
          </Box>

          <Droppable droppableId="0">
            {provided => (
              <Box className="field-list" ref={provided.innerRef} {...provided.droppableProps}>
                {availableColumns.map((i: any, index) => (
                  <Draggable
                    key={props.columns[i].uid}
                    // @ts-ignore
                    draggableId={props.columns[i].uid}
                    index={index}
                  >
                    {provided => (
                      <Box
                        className="field-item"
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        ref={provided.innerRef}
                      >
                        <Box display="flex" alignItems="center" flexDirection="row">
                          <Icon
                            // @ts-ignore
                            name={available_properties[props.columns[i].type].icon}
                            color="#777777"
                            size="12px"
                            mr="15px"
                          />
                          <Box>{props.columns[i].label}</Box>
                        </Box>
                        <Icon name="drag-handle" color="#777777" size="12px" />
                      </Box>
                    )}
                  </Draggable>
                ))}
                {availableColumns.length === 0 && (
                  <Box
                    borderStyle="dashed"
                    borderRadius="8px"
                    padding="1rem"
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="center"
                    fontSize="12px"
                    color="#777777"
                  >
                    <Box>Drag and drop columns here to hide</Box>
                  </Box>
                )}
              </Box>
            )}
          </Droppable>
        </Box>
        <Box className="form-section">
          <Box
            className="cover-image-section"
            onClick={coverImageWidget.openWidget}
            backgroundImage={coverImgURL}
            backgroundSize="cover"
            backgroundPosition="cover"
          >
            <Box className="cover-image-action">
              {listForm.cover_img_url ? 'Replace cover image' : 'Add a cover image'}
            </Box>
            {listForm.cover_img_url && (
              <Box mb="70px" mt="50px" ml="10px" onClick={(e: any) => e.stopPropagation()}>
                <Tooltip aria-label="Remove cover image" label="Remove cover image" hasArrow>
                  <IconButton
                    size="xs"
                    // @ts-ignore
                    icon="trash"
                    aria-label={'remove'}
                    variantColor="red"
                    variant="solid"
                    onClick={() => updateFormMedia('cover_img_url', null)}
                  />
                </Tooltip>
              </Box>
            )}
          </Box>

          <Box className="form-edit-section">
            <Box className="form-heading-section">
              <Box display="flex" marginBottom="2rem" justifyContent="space-between">
                {listForm.logo_img_url && (
                  <Image
                    src={listForm.logo_img_url}
                    maxWidth="4rem"
                    height="4rem"
                    marginRight="1rem"
                    borderRadius="8px"
                  />
                )}
                {!listForm.logo_img_url && (
                  <Box className="logo-preview" onClick={logoImageWidget.openWidget}>
                    Add a logo
                  </Box>
                )}
                {listForm.logo_img_url && (
                  <Box display="flex" alignItems="center">
                    <Button
                      mr="10px"
                      size="xs"
                      fontWeight="400"
                      onClick={logoImageWidget.openWidget}
                    >
                      Replace logo
                    </Button>

                    <Tooltip aria-label="Remove logo" label="Remove logo" hasArrow>
                      <IconButton
                        size="xs"
                        // @ts-ignore
                        icon="trash"
                        aria-label={'remove'}
                        variantColor="red"
                        variant="solid"
                        onClick={() => updateFormMedia('logo_img_url', null)}
                      />
                    </Tooltip>
                  </Box>
                )}
              </Box>

              <Box marginTop="1rem">
                <Input
                  name="title"
                  label="Title"
                  value={values.title}
                  onChange={handleChange}
                  onBlur={(e: any) => {
                    handleBlur(e);
                    setActiveField(null);
                  }}
                  onFocus={handleFocus}
                  placeholder="Form Title"
                  size="md"
                  borderRadius="8px"
                  fontWeight="600"
                  fontSize="18px"
                />
                <Textarea
                  label="Description"
                  name="description"
                  value={values.description}
                  onChange={handleChange}
                  onFocus={handleFocus}
                  onBlur={(e: any) => {
                    handleBlur(e);
                    setActiveField(null);
                  }}
                  placeholder="Description"
                  size="sm"
                  borderRadius="8px"
                  mt="1.5rem"
                />
              </Box>
            </Box>

            <Droppable droppableId="1">
              {(provided, snapshot) => (
                <Box
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  padding="20px"
                  borderRadius="1rem"
                  backgroundColor={snapshot.isDraggingOver ? 'rgba(0, 0, 0, 0.2)' : 'inherit'}
                >
                  {addedColumns.length === 0 && (
                    <Box className="empty-form-section">Drop fields here</Box>
                  )}
                  {addedColumns.map((i: any, index) => (
                    <AddedFormField
                      id={i}
                      key={i}
                      index={index}
                      label={props.columns[i].label}
                      required={requiredColumns.includes(i)}
                      active={activeAddedField === i}
                      setActive={setActiveAddedField}
                      setRequired={setColumnRequired}
                      description={columnDescriptions[i]}
                      setDescription={(description: string) => {
                        setColumnDescriptions({ ...columnDescriptions, [i]: description });
                      }}
                    />
                  ))}
                </Box>
              )}
            </Droppable>

            <EditableButtonSection
              value={listForm.submit_button_text}
              updateButtonText={updateButtonText}
            />
          </Box>
          <Box className="option-section">
            <Box className="option-content">
              <Box display="flex" alignItems="center" mb="15px">
                <input
                  style={{ height: 16, width: 16 }}
                  checked={values.show_branding}
                  type={'checkbox'}
                  name="show_branding"
                  onChange={(e: any) => formik.setFieldValue('show_branding', e.target.checked)}
                  onBlur={(e: any) => {
                    handleBlur(e);
                    setActiveField(null);
                  }}
                  onFocus={handleFocus}
                />
                <Box ml="15px" display="flex" flexDirection="row" alignItems="center">
                  <Box fontSize="12px" color="#777777">
                    Show {name} branding
                  </Box>
                </Box>
              </Box>

              <RedirectFieldSection formik={formik} setActiveField={setActiveField} />
              <Box fontSize="12px" color="#777777" mb="15px">
                After the form is submitted:
              </Box>

              <Box mb="15px">
                <Box mb="5px" fontSize="12px" color="#777777" fontWeight="500">
                  Show this message
                </Box>
                <ChakraTextArea
                  width="100%"
                  borderRadius="8px"
                  fontSize="14px"
                  padding="10px"
                  name="submit_message"
                  placeholder="Thank you for submitting the form!"
                  value={values.submit_message || ''}
                  onChange={handleChange}
                  onFocus={handleFocus}
                  onBlur={(e: any) => {
                    handleBlur(e);
                    setActiveField(null);
                  }}
                />
              </Box>

              <Box display="flex" alignItems="center" mb="15px">
                <input
                  style={{ height: 16, width: 16 }}
                  checked={values.refresh_after_submit}
                  type={'checkbox'}
                  onChange={(e: any) =>
                    formik.setFieldValue('refresh_after_submit', e.target.checked)
                  }
                  onBlur={(e: any) => {
                    handleBlur(e);
                    setActiveField(null);
                  }}
                  onFocus={handleFocus}
                />
                <Box ml="15px" display="flex" flexDirection="row" alignItems="center">
                  <Box fontSize="12px" color="#777777">
                    Show a new blank form after 5 seconds
                  </Box>
                </Box>
              </Box>

              <Box display="flex" alignItems="center" mb="15px">
                <input
                  style={{ height: 16, width: 16 }}
                  checked={values.allow_resubmit}
                  type={'checkbox'}
                  onChange={(e: any) => formik.setFieldValue('allow_resubmit', e.target.checked)}
                  onBlur={(e: any) => {
                    handleBlur(e);
                    setActiveField(null);
                  }}
                  onFocus={handleFocus}
                />
                <Box ml="15px" display="flex" flexDirection="row" alignItems="center">
                  <Box fontSize="12px" color="#777777">
                    Show a "Submit another response" button
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </DragDropContext>
    </Wrapper>
  );
};
