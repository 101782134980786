import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  ButtonProps,
  Checkbox,
  CloseButton,
  Flex,
  Icon,
  ModalBody,
  ModalCloseButton,
  Stack,
  Tooltip,
  useClipboard,
  useToast,
} from '@chakra-ui/core';
import { createEmailDomain, validateEmailDomain } from 'app/authenticated-app/marketing/campaigns';
import {
  EmailDomain,
  EmailDomainDNSRecrod,
  EmailDomainValidationData,
  EmailDomainValidationResults,
} from 'app/authenticated-app/marketing/campaigns/campaigns.types';
import {
  BodyText,
  Button,
  Input,
  ModalContainer,
  ModalContainerOptions,
  PreTitle,
  SmallSubtitle,
  SmallText,
  ToastBox,
} from 'app/components';
import { AxiosError } from 'axios';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import * as yup from 'yup';

type EmailDomainModalProps = ModalContainerOptions & { initialValues?: EmailDomain };

export const EmailDomainAuthModal = (props: EmailDomainModalProps) => {
  const { isOpen, onClose, initialValues } = props;

  const [activeStep, setActiveStep] = useState(0);
  const [domainRecord, setDomainRecord] = useState<EmailDomain | undefined>();
  const [validationErrors, setValidationErrors] =
    useState<EmailDomainValidationResults | undefined>();

  const toast = useToast();

  const queryClient = useQueryClient();

  const { mutate: mutateCreateDomain, isLoading: isCreatingDomain } = useMutation<
    any,
    AxiosError,
    any,
    any
  >((payload: { domain: string }) => createEmailDomain(payload.domain), {
    onSuccess: (data: EmailDomain) => {
      queryClient.invalidateQueries('email-domains');

      setDomainRecord(data);
      setActiveStep(1);

      toast({
        position: 'bottom-left',
        render: ({ onClose }) => (
          <ToastBox status="success" message="Domain created successfully" onClose={onClose} />
        ),
      });
    },
    onError: error => {
      queryClient.invalidateQueries('email-domains');
      toast({
        position: 'bottom-left',
        render: ({ onClose }) => <ToastBox message={error.message} onClose={onClose} />,
      });
    },
  });

  const { mutate: mutateVerifyDomain, isLoading: isVerifyingDomain } = useMutation<
    any,
    AxiosError,
    any,
    any
  >((domainId: string) => validateEmailDomain(domainId), {
    onSuccess: (data: EmailDomainValidationData) => {
      queryClient.invalidateQueries('email-domains');
      if (data.status === 'verified') {
        setDomainRecord(undefined);
        setValidationErrors(undefined);
        setActiveStep(0);
        onClose?.();
        toast({
          position: 'bottom-left',
          render: ({ onClose }) => (
            <ToastBox status="success" message="Domain validation successfully" onClose={onClose} />
          ),
        });
      } else {
        toast({
          position: 'bottom-left',
          render: ({ onClose }) => (
            <ToastBox message="Domain validation failed" onClose={onClose} />
          ),
        });
      }
    },
    onError: error => {
      queryClient.invalidateQueries('email-domains');
      toast({
        position: 'bottom-left',
        render: ({ onClose }) => <ToastBox message={error.message} onClose={onClose} />,
      });
    },
  });

  const stepsRegister: { [key: number]: { title: string } } = {
    0: {
      title: 'Add a new sending domain',
    },
    1: {
      title: 'Install DNS Records',
    },
  };

  const { title } = stepsRegister[activeStep];

  const handleCreateDomain = (values: { domain: string }) => {
    mutateCreateDomain(values);
  };

  useEffect(() => {
    if (initialValues) {
      setActiveStep(1);
      setDomainRecord(initialValues);
    }
  }, [initialValues]);

  return (
    <ModalContainer
      title={title}
      isOpen={isOpen}
      onClose={onClose}
      closeOnEsc={false}
      closeOnOverlayClick={false}
      size={activeStep === 0 ? 'md' : 'xl'}
    >
      <ModalCloseButton size="sm" />
      <ModalBody>
        {activeStep === 0 && <StepOne onSubmit={handleCreateDomain} isLoading={isCreatingDomain} />}
        {activeStep === 1 && (
          <StepTwo
            domain={domainRecord}
            isLoading={isVerifyingDomain}
            onVerifyDomain={mutateVerifyDomain}
            validationErrors={validationErrors}
            onCloseValidationErrors={() => setValidationErrors(undefined)}
          />
        )}
      </ModalBody>
    </ModalContainer>
  );
};

export const StepOne = (props: {
  isLoading: boolean;
  onSubmit(values: { domain: string }): void;
}) => {
  const { onSubmit, isLoading } = props;

  const { values, errors, touched, handleChange, handleSubmit } = useFormik({
    onSubmit,
    initialValues: { domain: '' },
    validationSchema: yup.object().shape({
      domain: yup.string().required('Domain is required'),
    }),
  });

  return (
    <Box>
      <BodyText color="gray.900" pb="1rem">
        Enter your domian
      </BodyText>
      <Input
        mb="1rem"
        autoFocus
        name="domain"
        value={values.domain}
        onChange={handleChange}
        placeholder="example.com"
        label="Domain you send from"
        errorMessage={errors.domain}
        isInvalid={!!touched.domain && !!errors.domain}
      />
      <Button mb="1rem" isLoading={isLoading} size="sm" variantColor="blue" onClick={handleSubmit}>
        Next
      </Button>
    </Box>
  );
};

export const StepTwo = (props: {
  isLoading?: boolean;
  domain?: EmailDomain;
  onCloseValidationErrors(): void;
  onVerifyDomain(id: string): void;
  validationErrors?: EmailDomainValidationResults;
}) => {
  const { domain, isLoading, onVerifyDomain, validationErrors, onCloseValidationErrors } = props;

  const { id, data } = domain ?? {};

  const [isChecked, setIsChecked] = useState(false);

  return (
    <Box>
      <SmallSubtitle color="gray.900" pb="0.5rem">
        Instructions
      </SmallSubtitle>
      <BodyText color="gray.500" pb="1rem">
        Add all of these records to your host's DNS section.
      </BodyText>
      {validationErrors && (
        <Alert mb="1rem" status="error" rounded="8px">
          <Stack>
            {Object.keys(validationErrors)
              .filter(key => !validationErrors[key].valid)
              .map(key => (
                <Stack isInline>
                  <AlertIcon />
                  <AlertDescription wordBreak="break-word">
                    <SmallText>{validationErrors[key].reason}</SmallText>
                  </AlertDescription>
                </Stack>
              ))}
          </Stack>
          <CloseButton
            size="sm"
            top="8px"
            right="8px"
            position="absolute"
            onClick={onCloseValidationErrors}
          />
        </Alert>
      )}
      {domain && (
        <Box pb="1rem">
          <Flex pb="0.5rem" alignItems="center">
            <PreTitle px="1rem" color="gray.500" width="20%">
              Type
            </PreTitle>
            <Stack px="1rem" isInline alignItems="center" width="40%">
              <PreTitle color="gray.500">Host</PreTitle>
              <Tooltip
                zIndex={10000}
                placement="top"
                aria-label="host"
                label="Some DNS host may refer to this as 'Value.'"
              >
                <Icon size="0.75rem" name="info" color="gray.500" />
              </Tooltip>
            </Stack>
            <Stack px="1rem" isInline alignItems="center" width="40%">
              <PreTitle color="gray.500">Value</PreTitle>
              <Tooltip
                zIndex={10000}
                placement="top"
                aria-label="value"
                label="Some DNS host may refer to this as 'Points To.'"
              >
                <Icon size="0.75rem" name="info" color="gray.500" />
              </Tooltip>
            </Stack>
          </Flex>
          <Box borderWidth="1px" borderBottomWidth="0">
            {Object.keys(data?.dns ?? {}).map(key => (
              <DNSRecord key={key} record={data?.dns[key]} />
            ))}
          </Box>
        </Box>
      )}
      <Stack pb="1rem" isInline alignItems="center">
        <Checkbox size="sm" isChecked={isChecked} onChange={e => setIsChecked(e.target.checked)}>
          I've added these records
        </Checkbox>
        <Button
          size="sm"
          variantColor="blue"
          isLoading={isLoading}
          isDisabled={!isChecked}
          onClick={() => onVerifyDomain(id ?? '')}
        >
          Verify
        </Button>
      </Stack>
    </Box>
  );
};

export const DNSRecord = (props: { record?: EmailDomainDNSRecrod }) => {
  const { record } = props;

  const { type, host, data } = record ?? {};

  const { onCopy: onCopyHost, hasCopied: hasCopiedHost } = useClipboard(host);
  const { onCopy: onCopyData, hasCopied: hasCopiedData } = useClipboard(data);

  return (
    <Flex alignItems="stretch" borderBottomWidth="1px">
      <Box width="20%">
        <SmallText p="1rem" color="gray.900" textTransform="uppercase">
          {type}
        </SmallText>
      </Box>
      <Flex
        p="1rem"
        width="40%"
        alignItems="center"
        borderLeftWidth="1px"
        justifyContent="space-between"
      >
        <SmallText flex={1} color="gray.900" wordBreak="break-word">
          {host}
        </SmallText>
        <CopyButton hasCopied={hasCopiedHost} onClick={onCopyHost} />
      </Flex>
      <Flex
        p="1rem"
        width="40%"
        alignItems="center"
        borderLeftWidth="1px"
        justifyContent="space-between"
      >
        <SmallText flex={1} color="gray.900" wordBreak="break-word">
          {data}
        </SmallText>
        <CopyButton hasCopied={hasCopiedData} onClick={onCopyData} />
      </Flex>
    </Flex>
  );
};

const CopyButton = (props: { hasCopied?: boolean; onClick: ButtonProps['onClick'] }) => {
  const { hasCopied, onClick } = props;

  return (
    <Button size="xs" onClick={onClick} variant="outline">
      {hasCopied ? (
        <Icon name="check-circle" color="green.500" />
      ) : (
        <SmallText fontWeight="bold">Copy</SmallText>
      )}
    </Button>
  );
};
