import {
  Avatar,
  BoxProps,
  Flex,
  IconButton,
  Popover,
  PopoverBody,
  PopoverContent,
  PseudoBox,
  Spinner,
  Stack,
  StackProps,
} from '@chakra-ui/core';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { SmallText, XSmallText } from '..';

type Size = 'sm' | 'md' | 'lg';

export type TagInputProps = {
  size?: Size;
  value?: string[];
  inputStyle?: any;
  options?: string[];
  isLoading?: boolean;
  placeholder?: string;
  selectOnBlur?: boolean;
  tagItemStyle?: StackProps;
  containerStyle?: BoxProps;
  popoverContentProps?: any;
  onSearch?: (query: string) => void;
  onChange?: (tags: string[]) => void;
};

export const TagInput = (props: TagInputProps) => {
  const {
    value,
    options,
    onSearch,
    onChange,
    isLoading,
    inputStyle,
    size = 'sm',
    tagItemStyle,
    containerStyle,
    selectOnBlur = true,
    popoverContentProps,
    placeholder = 'Enter a tag',
  } = props;

  const initialFocusRef = useRef<any>(null);

  const [input, setInput] = useState('');
  const [tags, setTags] = useState<string[]>(value ?? []);
  const [isKeyReleased, setIsKeyReleased] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const sizeLookUp: { [key: string]: string } = {
    sm: '2rem',
    md: '2.5rem',
    lg: '3rem',
  };

  const handleOpenDropdown = () => setIsDropdownOpen(true);

  const handleCloseDropdown = () => setIsDropdownOpen(false);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setInput(value);

    onSearch?.(value);

    if (options) {
      if (!value && !options.length) {
        handleCloseDropdown();
      } else {
        handleOpenDropdown();
      }
    }
  };

  const handleInputBlur = () => {
    const trimmedInput = input?.trim();
    if (trimmedInput.length && !tags.includes(trimmedInput) && selectOnBlur) {
      const tagsCopy = [...tags, trimmedInput];

      setTags(tagsCopy);
      setInput('');
      onChange?.(tagsCopy);
      handleCloseDropdown();
    }
  };

  const handleInputKeyDown = (e: any) => {
    const { key } = e;
    const trimmedInput = input?.trim();

    if ((key === ',' || key === 'Enter') && trimmedInput.length && !tags.includes(trimmedInput)) {
      const tagsCopy = [...tags, trimmedInput];
      e.preventDefault();
      setTags(tagsCopy);
      setInput('');
      onChange?.(tagsCopy);
      handleCloseDropdown();
    }

    if (key === 'Backspace' && !input.length && tags.length && isKeyReleased) {
      e.preventDefault();
      const tagsCopy = [...tags];
      const poppedTag = tagsCopy.pop();

      setTags(tagsCopy);
      poppedTag && setInput(poppedTag);
      onChange?.(tagsCopy);
    }
    setIsKeyReleased(false);
  };

  const handleInputKeyUp = () => {
    setIsKeyReleased(true);
  };

  const handleTagDelete = (index: number) => {
    setTags(prevState => prevState.filter((tag, i) => i !== index));
    onChange?.(tags.filter((tag, i) => i !== index));
  };

  const handleOptionItemClick = (item: string) => {
    const trimmedInput = input?.trim();

    if (!tags.includes(item) && trimmedInput.length && !tags.includes(trimmedInput)) {
      const tagsCopy = [...tags, item];

      setTags(tagsCopy);
      setInput('');
      onSearch?.('');
      onChange?.(tagsCopy);
      handleCloseDropdown();
      initialFocusRef.current.focus();
    }
  };

  useEffect(() => {
    if (!!value && !!Array.isArray(value)) {
      setTags(value);
    }
  }, [value]);

  return (
    <>
      <Stack
        isInline
        px="12px"
        width="100%"
        rounded="8px"
        flexWrap="wrap"
        borderWidth="1px"
        alignItems="center"
        borderColor="gray.500"
        height={sizeLookUp[size]}
        {...containerStyle}
      >
        {tags?.map((tag, index) => (
          <Stack
            isInline
            key={tag}
            px="0.5rem"
            bg="gray.100"
            rounded="100px"
            spacing="0.25rem"
            alignItems="center"
            {...tagItemStyle}
          >
            <XSmallText>{tag}</XSmallText>

            <IconButton
              size="xs"
              rounded="50%"
              variant="ghost"
              icon="small-close"
              aria-label="delete-tag"
              _focus={{ boxShadow: 'none' }}
              _active={{ boxShadow: 'none' }}
              onClick={() => handleTagDelete(index)}
            />
          </Stack>
        ))}
        <input
          type="text"
          value={input}
          ref={initialFocusRef}
          onBlur={handleInputBlur}
          placeholder={placeholder}
          onKeyUp={handleInputKeyUp}
          onChange={handleInputChange}
          onKeyDown={handleInputKeyDown}
          style={{
            width: '40%',
            border: 'none',
            outline: 'none',
            minWidth: '40%',
            borderRadius: '0',
            fontSize: '0.875rem',
            paddingRight: '0.5rem',
            backgroundColor: 'transparent',
            ...inputStyle,
          }}
        />
      </Stack>
      <Popover
        closeOnBlur={false}
        isOpen={isDropdownOpen}
        placement="bottom-start"
        onClose={handleCloseDropdown}
        initialFocusRef={initialFocusRef}
      >
        <PopoverContent
          left="42px"
          bottom="80px"
          zIndex={1000}
          height="230px"
          overflowY="auto"
          position="absolute"
          _focus={{ outline: 'none', boxShadow: '0px 5px 20px rgba(21, 27, 38, 0.08)' }}
          {...popoverContentProps}
        >
          <PopoverBody px="0" py="0.75rem">
            {isLoading ? (
              <Flex
                width="100%"
                height="100%"
                alignItems="center"
                flexDirection="column"
                justifyContent="center"
              >
                <Spinner
                  size="sm"
                  speed="0.9s"
                  thickness="3px"
                  color="blue.500"
                  emptyColor="gray.200"
                />
              </Flex>
            ) : (
              <>
                {!options?.length ? (
                  <Stack height="100%" alignItems="center" justifyContent="center">
                    <SmallText my="1rem" fontWeight="bold" color="gray.500">
                      There are no contacts
                    </SmallText>
                  </Stack>
                ) : (
                  <Stack spacing="0">
                    {options?.map((item, index) => {
                      return (
                        <PseudoBox
                          as="button"
                          py="0.5rem"
                          px="0.75rem"
                          width="100%"
                          display="flex"
                          cursor="pointer"
                          alignItems="center"
                          key={`${item}-${index}`}
                          _hover={{ bg: 'gray.200' }}
                          justifyContent="space-between"
                          onClick={e => {
                            e.stopPropagation();
                            handleOptionItemClick(item);
                          }}
                          onMouseDown={e => e.preventDefault()}
                        >
                          <Stack flex={1} isInline alignItems="center">
                            <Avatar size="xs" bg="#5D34A5" color="white" name={item} />
                            <SmallText color="gray.900">{item}</SmallText>
                          </Stack>
                        </PseudoBox>
                      );
                    })}
                  </Stack>
                )}
              </>
            )}
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </>
  );
};
