import { useToast } from '@chakra-ui/core';
import { fetchWallet } from 'app/authenticated-app/payments';
import { FullPageSpinner, ToastBox } from 'app/components';
import { selectOrganisationID } from 'app/unauthenticated-app/authentication';
import { AxiosError } from 'axios';
import { useBeforeunload, useLoading } from 'hooks';
import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { track } from 'utils/segment';
import {
  deleteCampaignItem,
  EmailCampaignSetupWidget,
  fetchCampaign,
  saveEmailCampaignDesign,
  selectCampaign,
  updateCampaign,
  updateEmailCampaignDesign,
} from '.';
import { CampaignData, CampaignPayload } from './campaigns.types';

export const ViewEmailCampaign = () => {
  const toast = useToast();

  const history = useHistory();

  const dispatch = useDispatch();

  const queryClient = useQueryClient();

  const campaign = useSelector(selectCampaign);

  const organisation_id = useSelector(selectOrganisationID);

  const { id: routeID } = useParams<{ id: CampaignData['id'] }>();

  const { dispatch: loadingDispatch, loading, actionsLoading, globalLoading } = useLoading();

  const handleEditCampaign = async (payload: Partial<CampaignPayload>) => {
    try {
      loadingDispatch({ type: 'ACTIONS_LOADING_STARTED' });
      await dispatch(updateCampaign(payload));
      loadingDispatch({ type: 'ACTIONS_LOADING_RESOLVED' });
      toast({
        position: 'bottom-left',
        render: ({ onClose }) => (
          <ToastBox status="success" onClose={onClose} message="Campaign updated successfully" />
        ),
      });
      organisation_id && (await dispatch(fetchWallet(organisation_id)));
      track('Email Campaign Created', payload);
      queryClient.invalidateQueries('campaigns');
    } catch (error: any) {
      dispatch({ type: 'ACTIONS_LOADING_RESOLVED' });
      toast({
        position: 'bottom-left',
        render: ({ onClose }) => <ToastBox onClose={onClose} message={error} />,
      });
    }
  };

  const handleSaveCampaignDraft = async (payload: CampaignPayload) => {
    const { name, external_template_data, email_campaign_design_id } = payload;

    try {
      loadingDispatch({ type: 'LOADING_STARTED' });
      await dispatch(updateCampaign(payload));
      if (!email_campaign_design_id) {
        await saveEmailCampaignDesign({ name: name ?? '', design: external_template_data });
      } else {
        await updateEmailCampaignDesign({
          name: name ?? '',
          id: email_campaign_design_id,
          design: external_template_data,
        });
      }
      loadingDispatch({ type: 'LOADING_RESOLVED' });
      toast({
        position: 'bottom-left',
        render: ({ onClose }) => (
          <ToastBox status="success" onClose={onClose} message="Campaign saved as draft" />
        ),
      });
      track('Email Campaign Draft', payload);
      queryClient.invalidateQueries('campaigns');
      history.push('/s/marketing/campaigns');
    } catch (error: any) {
      loadingDispatch({ type: 'LOADING_RESOLVED' });
      toast({
        position: 'bottom-left',
        render: ({ onClose }) => <ToastBox onClose={onClose} message={error} />,
      });
    }
  };

  const { isLoading: isDeletingCampaign, mutate: mutateDeleteCampaign } = useMutation<
    any,
    AxiosError,
    any,
    any
  >((campaign: CampaignData) => deleteCampaignItem({ id: campaign.id }), {
    onSuccess: () => {
      queryClient.invalidateQueries('campaigns');
      history.push('/s/marketing/campaigns');
      toast({
        position: 'bottom-left',
        render: ({ onClose }) => (
          <ToastBox status="success" onClose={onClose} message="Campaign deleted successfully" />
        ),
      });
    },
    onError: (error, _, context) => {
      toast({
        position: 'bottom-left',
        render: ({ onClose }) => <ToastBox onClose={onClose} message={error.message} />,
      });
    },
  });

  React.useEffect(() => {
    let didCancel = false;

    const fetchData = async () => {
      try {
        loadingDispatch({ type: 'GLOBAL_LOADING_STARTED' });
        await dispatch(fetchCampaign(routeID));
        if (!didCancel) {
          loadingDispatch({ type: 'GLOBAL_LOADING_RESOLVED' });
        }
      } catch (error) {
        loadingDispatch({ type: 'GLOBAL_LOADING_RESOLVED' });
      }
    };

    fetchData();
    return () => {
      didCancel = true;
    };
  }, [dispatch, routeID, loadingDispatch]);

  useBeforeunload((e: any) => {
    e.preventDefault();
  });

  return globalLoading === 'pending' ? (
    <FullPageSpinner />
  ) : (
    <EmailCampaignSetupWidget
      type="edit"
      campaign={{
        ...campaign,
        cta: campaign.external_template_data?.cta,
        logo: campaign.external_template_data?.logo,
        footer: campaign.external_template_data?.footer,
      }}
      onSubmit={handleEditCampaign}
      onSaveDraft={handleSaveCampaignDraft}
      isSavingDraft={loading === 'pending'}
      isDeletingCampaign={isDeletingCampaign}
      onDeleteCampaign={() => mutateDeleteCampaign(campaign)}
      isCreatingCampaign={actionsLoading === 'pending'}
    />
  );
};
