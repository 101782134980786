import { Avatar, Box, BoxProps, Icon, IconButton, Image, Stack, Tooltip } from '@chakra-ui/core';
import * as React from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import usePermission from 'utils/usePermission';
import { selectAutomationPanelOpen } from '../../authenticated-app/lists';
import { Organization } from '../../authenticated-app/settings/settings.types';
import {
  OnboardingTasksInfo,
  selectToken,
  User,
  UserProfile,
} from '../../unauthenticated-app/authentication';
import { AppSwitcher } from '../AppSwitcher';
import { Button } from '../Button';
import FrillWidget from '../Frill';
import { Logo } from '../Logo';
import { useResellerSettings } from '../ResellerProvider';
import { DashboardHeaderWrapper as Wrapper } from './dashboard.header.styles';
import { DashboardSideSheet } from './dashboard.sidesheet';
import { useFlags } from 'flagsmith/react';

type Props = {
  logout?(): void;
  user?: User | null;
  profile?: UserProfile | null;
  organizations: Organization[] | null;
  onboarding_task_info: OnboardingTasksInfo;
  toggleAutomationPanel: Function;
  onOpenCloseHelpDrawer(): void;
} & BoxProps;

export const DashboardHeader = (props: Props) => {
  const [showDialog, setShowDialog] = useState(false);
  const routerLocation = useLocation();
  const token = useSelector(selectToken);
  const { events_feature } = useFlags(['events_feature']);

  const { profile, user, logout, onboarding_task_info, organizations } = props;
  const automationPanelOpen: boolean = useSelector(selectAutomationPanelOpen);

  const permissions = profile && profile.permissions;
  const requiresPermission = usePermission('list.enrichment', permissions || []);

  const links = [
    { label: 'Data', url: '/s/lists' },
    events_feature.enabled ? { label: 'Events', url: '/s/events' } : undefined,
    { label: 'Campaigns', url: '/s/marketing' },
    { label: 'Reports', url: '/s/reports' },
    { label: 'Developers', url: '/s/developers' },
  ].filter(l => !!l);

  const updatedPageAccess = profile?.page_access?.map(item => {
    if (item === 'marketing') {
      return 'campaigns';
    }
    return item;
  });

  let accessibleLinks: any;

  if (profile?.page_access?.includes('*') || !profile?.page_access) {
    accessibleLinks = [...links, { label: 'Settings', url: '/s/settings' }];
  } else if (!profile?.page_access?.includes('marketing')) {
    accessibleLinks = [
      ...links.filter(
        (link: any) =>
          profile &&
          profile.page_access &&
          profile.roles_page_access &&
          updatedPageAccess?.includes(link.label.toLowerCase()),
      ),
      events_feature.enabled ? { label: 'Events', url: '/s/events' } : undefined,
      { label: 'Settings', url: '/s/settings' },
    ];
  } else {
    accessibleLinks = [
      ...links.filter(
        (link: any) =>
          profile &&
          profile.page_access &&
          profile.roles_page_access &&
          updatedPageAccess?.includes(link.label.toLowerCase()),
      ),
      events_feature.enabled ? { label: 'Events', url: '/s/events' } : undefined,
      { label: 'Reports', url: '/s/reports' },
      { label: 'Settings', url: '/s/settings' },
    ];
  }

  const resellerSettings = useResellerSettings();

  const { logo, name } = resellerSettings ?? {};

  const closeDialog = () => {
    setShowDialog(false);
  };

  const showHeader = routerLocation.pathname !== '/s/payments/onboarding';

  return (
    <>
      {showHeader && (
        <Wrapper>
          <div className="section">
            <NavLink to="/s/lists/lists">
              {name.toLowerCase() !== 'simpu' ? (
                <Image alt="Logo" src={logo} width="24px" />
              ) : (
                <Logo width="24px" />
              )}
            </NavLink>
            <div className="nav-items">
              {accessibleLinks
                .filter((i: any) => !!i)
                .map((link: any, index: number) => (
                  <Box display="flex" key={index}>
                    <NavLink to={link.url}>
                      <div
                        className={`item ${routerLocation.pathname.includes(link.url) && 'active'}`}
                      >
                        {link.label}
                      </div>
                    </NavLink>
                  </Box>
                ))}
            </div>
          </div>
          <Stack isInline className="section" alignItems="center">
            <Box display="flex" marginRight="1rem">
              {routerLocation.pathname.includes('/s/lists/view/') && requiresPermission && (
                <Button
                  // @ts-ignore
                  onClick={() =>
                    props.toggleAutomationPanel({ automationPanelOpen: !automationPanelOpen })
                  }
                  variant={automationPanelOpen ? 'solid' : 'link'}
                  color={automationPanelOpen ? '#333333' : '#FFFFFF'}
                  size="xs"
                >
                  Automations
                </Button>
              )}
            </Box>
            <Box>
              <FrillWidget />
            </Box>
            <Box>
              <Tooltip label="Help & Support" aria-label="Help & Support">
                <IconButton
                  size="xs"
                  variant="ghost"
                  icon="question"
                  aria-label="Help & Support"
                  onClick={props.onOpenCloseHelpDrawer}
                  _focus={{
                    bg: '#515151',
                    boxShadow: 'none',
                  }}
                  _hover={{
                    bg: '#515151',
                  }}
                  _active={{
                    bg: '#515151',
                  }}
                />
              </Tooltip>
            </Box>
            <Box>
              <AppSwitcher token={token ?? ''} />
            </Box>
            <Box
              display="flex"
              alignItems="center"
              cursor="pointer"
              onClick={() => setShowDialog(!showDialog)}
            >
              <Avatar
                size="sm"
                color="white"
                marginRight="7px"
                src={profile?.image}
                name={`${profile?.first_name} ${profile?.last_name}`}
              />
              <Icon name="chevron-down" size="20px" />
            </Box>
          </Stack>
        </Wrapper>
      )}

      <DashboardSideSheet
        onboarding_task_info={onboarding_task_info}
        user={user}
        profile={profile}
        logout={logout}
        isOpen={showDialog}
        close={closeDialog}
        organizations={organizations}
      />
    </>
  );
};
