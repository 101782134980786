import { useToast } from '@chakra-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { track } from 'utils/segment';
import { useBeforeunload, useLoading } from '../../../../hooks';
import { ContentWrapper, FullPageSpinner, ToastBox } from '../../../components';
import { selectOrganisationID } from '../../../unauthenticated-app/authentication';
import { CampaignData, CampaignPayload } from './campaigns.types';
import { SmsCampaignForm } from './components/sms-campaigns/sms-campaign-form';
import { plannerConnector } from './planner.connector';
import { PlannerProps } from './planner.types';

const ViewCampaignUI = (props: PlannerProps) => {
  const { history, campaign, fetchTemplate, fetchCampaign, updateCampaign, ...rest } = props;

  const toast = useToast();
  const { dispatch, globalLoading, loading } = useLoading();
  const { id: routeID } = useParams<{ id: CampaignData['id'] }>();
  const organisation_id = useSelector(selectOrganisationID);

  React.useEffect(() => {
    let didCancel = false;

    const fetchData = async () => {
      try {
        dispatch({ type: 'GLOBAL_LOADING_STARTED' });
        const { campaign } = await fetchCampaign(routeID, true);
        if (campaign.template_id) {
          fetchTemplate(campaign.template_id);
        }
        // Ignore if we started fetching something else
        if (!didCancel) {
          dispatch({ type: 'GLOBAL_LOADING_RESOLVED' });
        }
      } catch (error) {
        dispatch({ type: 'GLOBAL_LOADING_RESOLVED' });
      }
    };

    fetchData();
    return () => {
      didCancel = true;
    };
  }, [dispatch, fetchCampaign, routeID, fetchTemplate]);

  const handleEditCampaign = async (payload: Partial<CampaignPayload>) => {
    try {
      dispatch({ type: 'LOADING_STARTED' });
      await updateCampaign(payload);
      dispatch({ type: 'LOADING_RESOLVED' });
      toast({
        position: 'bottom-left',
        render: ({ onClose }) => (
          <ToastBox status="success" onClose={onClose} message="Campaign updated successfully" />
        ),
      });
      organisation_id && (await rest.fetchWallet(organisation_id));
      track('SMS Campaign Updated', payload);
      history.push('/s/marketing/campaigns');
    } catch (error: any) {
      dispatch({ type: 'LOADING_RESOLVED' });
      toast({
        position: 'bottom-left',
        render: ({ onClose }) => <ToastBox onClose={onClose} message={error} />,
      });
    }
  };

  useBeforeunload((e: any) => {
    e.preventDefault();
  });

  return globalLoading === 'pending' ? (
    <FullPageSpinner bg="white" height="100vh" />
  ) : (
    <ContentWrapper p="1.875rem">
      <SmsCampaignForm
        initialValues={campaign}
        onSubmit={handleEditCampaign}
        isLoading={loading === 'pending'}
        {...rest}
      />
    </ContentWrapper>
  );
};

export const ViewSMSCampaign = plannerConnector(ViewCampaignUI);
