import { Box, Checkbox, CheckboxGroup, Stack, useToast } from '@chakra-ui/core';
import { setListDuplicateJobData } from 'app/authenticated-app/lists/lists.reducer';
import { selectColumnsData } from 'app/authenticated-app/lists/lists.selectors';
import { ListService } from 'app/authenticated-app/lists/service';
import { BodyText, Button, ToastBox } from 'app/components';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { track } from 'utils/segment';

export const SelectPivotColumns: React.FC<{
  pivotColumns: string[];
  setPivotColumns(columns: string[]): void;
  setSection(section: 'select-pivot-columns' | 'duplicate-groups'): void;
}> = ({ setSection, pivotColumns, setPivotColumns }) => {
  const [isCheckingDuplicates, setCheckingDuplicates] = useState(false);

  const toast = useToast();
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const columnsState = useSelector(selectColumnsData);
  // @ts-ignore
  const columns: Column[] = columnsState.columns_by_id.map((i: string) => columnsState.columns[i]);

  const handleCheckInitiationComplete = (
    list_id: any,
    status: 'pending' | 'started' | 'done' | 'failed',
  ) => {
    if (status === 'started' || status === 'pending') {
      dispatch(setListDuplicateJobData({ list_id, status }));
    } else if (status === 'done') {
      setSection('duplicate-groups');
      dispatch(setListDuplicateJobData({ list_id, status }));
    } else {
      toast({
        duration: null,
        position: 'bottom-left',
        render: ({ onClose }) => (
          <ToastBox message="Duplicate check initiation failed" onClose={onClose} />
        ),
      });
    }
  };

  const handleCheckForDuplicates = async () => {
    try {
      setCheckingDuplicates(true);
      const { status, list_id } = await ListService.checkForDuplicates(id, {
        pivot_columns: pivotColumns,
      });
      toast({
        duration: null,
        position: 'bottom-left',
        render: ({ onClose }) => (
          <ToastBox
            status="success"
            onClose={onClose}
            message={
              <Stack>
                <BodyText fontWeight="semibold">Duplicate check initiated successfully</BodyText>
                <BodyText>
                  This process might take a few minutes, based on the amount of data in the list
                </BodyText>
              </Stack>
            }
          />
        ),
      });
      handleCheckInitiationComplete(list_id, status);
      setCheckingDuplicates(false);
      track('Initiated List duplicate');
    } catch (error: any) {
      setCheckingDuplicates(false);
      toast({
        duration: null,
        position: 'bottom-left',
        render: ({ onClose }) => <ToastBox message={error?.message ?? error} onClose={onClose} />,
      });
    }
  };

  return (
    <Stack spacing={4}>
      <Box fontSize="1rem">
        Select the email and phone number columns you will like to check for duplicates.
      </Box>
      <CheckboxGroup
        size="sm"
        value={pivotColumns}
        onChange={value => setPivotColumns(value as string[])}
      >
        {columns
          .filter(col => ['PHONE NUMBER', 'EMAIL'].includes(col.type))
          .map(col => (
            <Checkbox key={col.id} value={col.id} fontSize="0.875rem">
              {col.label}
            </Checkbox>
          ))}
      </CheckboxGroup>
      <Button
        size="sm"
        variantColor="green"
        isLoading={isCheckingDuplicates}
        onClick={handleCheckForDuplicates}
      >
        Check for duplicates
      </Button>
    </Stack>
  );
};
