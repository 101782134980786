import { Skeleton, Stack } from '@chakra-ui/core';
import React from 'react';

export const HeaderLoader = () => {
  return (
    <Stack isInline alignItems="center">
      <Skeleton width="3rem" height="3rem" rounded="50%" />
      <Stack>
        <Skeleton height="10px" width="200px" />
        <Skeleton height="10px" width="150px" />
      </Stack>
    </Stack>
  );
};
