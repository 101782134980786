// @ts-nocheck
import styled from '@emotion/styled';

export const ListGridWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  
  #ListGrid {
    flex: 1;

    * {
      border: none;
      font-family: Averta;
    }

    .ag-header {
      min-height: 35px !important;
      height: 35px !important;
    }

    .ag-header-row {
      min-height: 34px !important;
      height: 34px !important;
    }

    .ag-header-viewport {
    }

    .ag-checkbox {
    }

    .ag-center-cols-viewport {
      overflow-x: ${props => (props.allowScroll ? 'auto' : 'hidden')};
    }

    .ag-body-viewport {
      overflow-y: ${props => (props.allowScroll ? 'auto' : 'hidden')};
    }

    .ag-header-cell-label {
      height: 100%;
      font-weight: 500;
      padding: 0 !important;
      border: none;
    }

    .ag-cell-label-container {
      height: 100%;
    }

    .ag-header-cell {
      border: solid #f2f2f2 1px;
      border-right: none;
      border-bottom: none;
      background-color: white;

      :hover {
        background-color: #fafafa;
      }

      .ag-react-container {
        width: inherit;
      }
    }

    .ag-pinned-left-header {
      .ag-header-cell:nth-of-type(1) {
        border: 1px none #f2f2f2;
        border-top-style: solid;
      }

      .ag-header-cell:nth-of-type(2) {
        border-right: 4px solid rgba(243, 243, 243, 0.5);

        .ag-header-cell-resize:hover {
          right: -5px;
        }
      }
    }

    .ag-header-cell-resize:hover {
      right: -2px;
      padding: 2px;
      width: 1px;
      margin-top: 7.5px;
      height: 30px;
      background-color: #4242c1 !important;
      border-radius: 2px;
    }

    .ag-header-cell-resize::after {
      background-color: transparent !important;
    }

    .ag-cell {
      border: solid #f2f2f2 1px;
      border-top: none;
      border-right: none;
      background-color: white;
      line-height: 34px;

      .attachment-actions {
        display: none;
      }

      .file-attachment {
        height: 27px;
        width: 20px;
        border-radius: 4px;
        padding: 2px;
        object-fit: cover;
        border: 2px solid rgba(0, 0, 0, 0.08);
        transition: all ease-in 0.09s;
        :hover {
          border: 2px solid rgba(0, 0, 0, 0.2);
        }
      }

      .file-attachment-close-button {
        height: 10px;
        font-size: 5px;
        width: 10px;
        min-width: 10px;
        top: 0px;
        z-index: 100;
        position: static;
      }
    }

    .ag-row-hover {
      .ag-cell {
        background-color: #fafafa;

        .attachment-actions {
          display: flex;
          flex-direction: row;
          align-items: center;
        }

        .checkbox-cell {
          border-color: rgba(61, 67, 223, 0.2) !important;
          border-radius: 5px !important;
          border-width: 2px !important;
          border-style: solid;
        }
      }
    }

    .ag-pinned-left-cols-container {
      .ag-cell:nth-of-type(1) {
        border: 1px none #f2f2f2;
        border-bottom-style: solid;

        .row-select-cell {
          display: flex;
          align-items: center;

          .row-select-checkbox,
          .row-expand-btn {
            display: none;
          }

          .row-expand-btn {
            background-color: rgba(61, 66, 223, 0.2);
          }

          .row-select-index {
            display: flex;
          }
        }

        :hover {
          .row-select-cell {
            .row-select-checkbox,
            .row-expand-btn {
              display: flex;
            }

            .row-select-index {
              display: none;
            }
          }
        }
      }

      .ag-cell:nth-of-type(2) {
        border-right: 4px solid rgba(243, 243, 243, 0.5);
      }
    }

    .ag-full-width-row {
      border-bottom: solid #f2f2f2 1px;
      background-color: white;
      display: flex;
      align-items: center;

      * {
        display: flex;
        flex-direction: row;
        align-items: center;

        border-left: none;

        :hover {
          background-color: #fafafa;
        }
      }

      & {
        padding: 0 22px;
      }

      :hover {
        background-color: #fafafa;
      }
    }

    .ag-horizontal-left-spacer,
    .ag-horizontal-right-spacer {
      display: none;
    }

    overflow-x: hidden;
    scroll-behavior: smooth;

    .ag-center-cols-viewport {
      ::-webkit-scrollbar {
        height: 6px;
      }

      ::-webkit-scrollbar-track {
        background: #f5f5f5;
        border-radius: 4px;
      }

      ::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background: #ccc;
      }

      ::-webkit-scrollbar-thumb:hover {
        background: #999;
      }
    }
  }

  .footing {
    display: flex;
    height: 2rem;
    flex-direction: row;
    align-items: center;
    padding: 0 22px;
    border-top: 1px solid rgba(144, 164, 183, 0.22);
    font-size: 12px;
    line-height: 18px;
    color: rgba(33, 34, 66, 0.7);
  }
`;
