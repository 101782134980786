import { Draggable } from 'react-beautiful-dnd';
import { Box, Input } from '@chakra-ui/core';
import React, { useEffect, useState } from 'react';

interface Props {
  id: string;
  index: number;
  label: string;
  description: string | null;
  required: boolean;
  active: boolean;
  setActive: Function;
  setRequired: Function;
  setDescription: Function;
}

export const AddedFormField = (props: Props) => {
  const [tempDescription, setTempDescription] = useState<string | null>(null);
  const {
    id,
    index,
    label,
    description,
    required,
    setRequired,
    active,
    setActive,
    setDescription,
  } = props;

  useEffect(() => {
    if (active) {
      setTempDescription(description);
    } else {
      setDescription(tempDescription);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active]);

  return (
    <Draggable key={id} draggableId={id} index={index}>
      {provided => (
        <Box
          className={`field-item ${active && 'active'}`}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
          onClick={() => setActive(!active ? id : null)}
        >
          {!active && (
            <>
              <Box>
                <Box fontWeight="500">{label}</Box>
                {description && (
                  <Box fontSize="14px" color="#777777" my="0.75rem">
                    {description}
                  </Box>
                )}
              </Box>
              <Box
                display="flex"
                flexDirection="row"
                onClick={(e: any) => e.stopPropagation()}
                alignItems="normal"
              >
                <Box fontSize="12px" color="#777777">
                  required
                </Box>
                <input
                  style={{ marginLeft: 8, height: 16, width: 16 }}
                  checked={required}
                  type={'checkbox'}
                  onChange={(e: any) => setRequired(id, e.target.checked)}
                />
              </Box>
            </>
          )}
          {active && (
            <>
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
                mb="0.75rem"
              >
                <Box fontWeight="500">{label}</Box>
                <Box display="flex" alignItems="center">
                  <Box fontSize="12px" color="#777777">
                    required
                  </Box>
                  <input
                    style={{ marginLeft: 8, height: 16, width: 16 }}
                    checked={required}
                    type={'checkbox'}
                    onChange={(e: any) => setRequired(id, e.target.checked)}
                    onClick={(e: any) => e.stopPropagation()}
                  />
                </Box>
              </Box>

              <Input
                size="sm"
                fontFamily="Averta"
                borderRadius="6px"
                mb="10px"
                placeholder="Add some help text"
                paddingX="10px"
                value={tempDescription || ''}
                onClick={(e: any) => e.stopPropagation()}
                onChange={(e: any) => {
                  setTempDescription(e.target.value);
                }}
                onBlur={() => {
                  setDescription(tempDescription);
                }}
              />
            </>
          )}
        </Box>
      )}
    </Draggable>
  );
};
