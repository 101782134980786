import { Box, ModalBody, Stack } from '@chakra-ui/core';
import { Button, Input, ModalContainer, ModalContainerOptions } from 'app/components';
import { selectOrganisationID } from 'app/unauthenticated-app/authentication';
import { FormikConfig, useFormik } from 'formik';
import React, { useRef } from 'react';
import * as yup from 'yup';
import { useSelector } from 'react-redux';
import { selectOrganisations } from '../../slices';

export type SMSSenderProfilePayload = {
  id?: string;
  name: string;
  bind_id?: string;
  is_default?: boolean;
};

export const SMSSenderProfileModal = (
  props: ModalContainerOptions & {
    isLoading: boolean;
    initialValues?: SMSSenderProfilePayload;
    onSubmit: FormikConfig<SMSSenderProfilePayload>['onSubmit'];
  },
) => {
  const { isOpen, onClose, initialValues, onSubmit, isLoading } = props;

  const initialInputRef = useRef<HTMLInputElement>(null);
  const organizations = useSelector(selectOrganisations);
  const organizationID = useSelector(selectOrganisationID);
  const currentOrganization = organizations.find((i: any) => i.id === organizationID);

  const title = !initialValues ? 'Add sender profile' : 'Edit sender profile';
  const { values, errors, touched, handleChange, handleSubmit } =
    useFormik<SMSSenderProfilePayload>({
      onSubmit,
      initialValues: initialValues ?? {
        name: '',
      },
      validationSchema: yup.object().shape({
        name: yup
          .string()
          .max(11, 'Sender ID name cannot be longer than 11 characters')
          .required('Name is required'),
      }),
    });

  return (
    <ModalContainer
      showCloseButton
      title={title}
      isOpen={isOpen}
      onClose={onClose}
      initialFocusRef={initialInputRef}
    >
      <ModalBody pb="1rem">
        <form onSubmit={handleSubmit}>
          <Stack spacing="1rem" alignItems="flex-start">
            <Box width="100%">
              <Input
                type="text"
                name="name"
                label="Sender ID"
                value={values.name}
                ref={initialInputRef}
                onChange={handleChange}
                errorMessage={errors.name}
                placeholder={currentOrganization?.name}
                isInvalid={!!touched.name && !!errors.name}
              />
            </Box>
            <Button size="sm" type="submit" isLoading={isLoading} variantColor="blue">
              Save sender profile
            </Button>
          </Stack>
        </form>
      </ModalBody>
    </ModalContainer>
  );
};
