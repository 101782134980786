import React from 'react';
import { Wrapper } from './component.styles';
import { Heading, Stack } from '@chakra-ui/core';
import { AddonPrompt } from './components';

export const SubscriptionInfoComponent = () => {
  return (
    <Wrapper>
      <Stack isInline alignItems="center" marginBottom="3.5rem" justifyContent="space-between">
        <Heading size="sm" color="#333333" fontWeight="semibold">
          Billing and Subscription Settings
        </Heading>
      </Stack>
      <AddonPrompt />
    </Wrapper>
  );
};
