import {
  Alert,
  AlertDescription,
  AlertTitle,
  Box,
  Checkbox,
  Collapse,
  List,
  ListIcon,
  ListItem,
  ModalBody,
  ModalCloseButton,
  Stack,
} from '@chakra-ui/core';
import { BodyText, Button, ModalContainer, ModalContainerOptions } from 'app/components';
import Cookies from 'js-cookie';
import React, { useRef, useState } from 'react';

export const MergeInstructionsModal: React.FC<ModalContainerOptions> = ({ isOpen, onClose }) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [showDemoVideo, setShowDemoVideo] = useState(false);

  const handleDisplayPreference = (isChecked: boolean) => {
    if (isChecked) {
      Cookies.set('merge_instructions_modal_display', 'no');
    } else {
      Cookies.set('merge_instructions_modal_display', 'yes');
    }
  };

  return (
    <ModalContainer
      size="lg"
      isOpen={isOpen}
      onClose={onClose}
      closeOnEsc={false}
      closeOnOverlayClick={false}
      title={showDemoVideo ? 'Merge demo video' : 'Merge instructions'}
      titleStyleProps={{
        pb: '0',
      }}
      modalOverlayProps={{
        bg: 'blackAlpha.300',
        style: {
          backdropFilter: 'blur(10px) hue-rotate(90deg)',
        },
      }}
    >
      <ModalCloseButton size="sm" />
      <ModalBody pb={4}>
        <Stack spacing={4}>
          <Collapse isOpen={!showDemoVideo}>
            <Stack spacing={4}>
              <BodyText>
                Merging lists on Simpu is a fairly straightforward process, it involves merging a
                list
                <strong> (head list)</strong> into another list <strong> (base list)</strong> by
                moving columns from the head list into the base lists.
              </BodyText>
              <BodyText>
                You can merge columns from the head list into the base list or add a column from the
                head list that doesn't exist on the base list to create the merged list.
              </BodyText>
              <BodyText>
                When merging a column from the head list into the base list,{' '}
                <strong>the two columns must be of the same property type</strong>.
              </BodyText>
              <BodyText>
                When the merging process is completed,{' '}
                <strong>the head list is deleted permanently </strong>
                and the base list has the merged columns and the new columns.
              </BodyText>
              <Button
                size="sm"
                variant="link"
                leftIcon="video"
                variantColor="blue"
                alignSelf="flex-start"
                onClick={() => {
                  if (videoRef.current) {
                    videoRef.current.play();
                  }
                  setShowDemoVideo(true);
                }}
              >
                Watch a demo video
              </Button>
              <Alert alignItems="flex-start" rounded="8px" status="info">
                <Stack spacing="0.2rem">
                  <AlertTitle fontSize="0.875rem">Things to Note</AlertTitle>
                  <AlertDescription>
                    <List spacing={2}>
                      <ListItem fontSize="0.75rem">
                        <ListIcon icon="info" color="blue.500" />
                        The base list is the list you'll be creating the new list off.
                      </ListItem>
                      <ListItem fontSize="0.75rem">
                        <ListIcon icon="info" color="blue.500" />
                        The head list is the list that you'll merge into the base list.
                      </ListItem>
                    </List>
                  </AlertDescription>
                </Stack>
              </Alert>
            </Stack>
          </Collapse>
          <Collapse isOpen={showDemoVideo}>
            <Stack spacing={4}>
              <Box>
                <iframe
                  width="464"
                  height="315"
                  allowfullscreen
                  frameborder="0"
                  style={{ borderRadius: '8px' }}
                  title="Merge list instruction video"
                  src="https://www.youtube.com/embed/SyFHvE7ErmY?si=RMAiimAJRInjJ5B2"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                />
              </Box>

              <Button
                size="sm"
                variant="link"
                variantColor="blue"
                alignSelf="flex-start"
                onClick={() => {
                  if (videoRef.current) {
                    videoRef.current.pause();
                  }
                  setShowDemoVideo(false);
                }}
              >
                Close demo video
              </Button>
            </Stack>
          </Collapse>
          <Checkbox onChange={e => handleDisplayPreference(e.target.checked)}>
            <BodyText>Don't show this again</BodyText>
          </Checkbox>
          <Button size="sm" onClick={onClose} variantColor="blue">
            Start merging
          </Button>
        </Stack>
      </ModalBody>
    </ModalContainer>
  );
};
