import { theme } from '@chakra-ui/core';
import { customIcons } from './icons';

export const simpuTheme = {
  ...theme,
  icons: {
    ...theme.icons,
    ...customIcons,
  },
  fontWeight: {
    normal: 400,
    medium: 500,
    bold: 600,
  },
  colors: {
    ...theme.colors,
    black: '#333333',
    blue: {
      50: '#def2ff',
      100: '#aed5ff',
      200: '#7eb9ff',
      300: '#4c9dfe',
      400: '#1b81fd',
      500: '#026ae8',
      600: '#0051b2',
      700: '#003a80',
      800: '#002350',
      900: '#000d20',
    },
    gray: {
      50: '#fafafa',
      100: '#F4F6F9',
      200: '#EBEEF2',
      300: '#DADEE3',
      400: '#A5ABB3',
      500: '#858C94',
      600: '#6D7580',
      700: '#545D69',
      800: '#394452',
      900: '#2B3A4B',
    },
    primary: '#026ae8',
    lightBlack: 'rgba(17,17,17,0.5)',
    brandBlack: '#09101D',
  },
  fonts: {
    body: 'Averta, sans-serif',
    heading: 'Averta, sans-serif',
    mono: 'Averta, sans-serif',
  },
};
