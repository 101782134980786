import {
  Alert,
  AlertIcon,
  IconButton,
  ModalBody,
  ModalCloseButton,
  PseudoBox,
  Stack,
  Tooltip,
  useClipboard,
  useToast,
} from '@chakra-ui/core';
import {
  createDedicatedVirtualAccount,
  fetchDedicatedVirtualAccount,
  fetchWallet,
} from 'app/authenticated-app/payments';
import { DedidcatedVirtualAccount } from 'app/authenticated-app/payments/payments.types';
import { selectWalletData } from 'app/authenticated-app/payments/selectors';
import {
  BodyText,
  Button,
  FullPageSpinner,
  Input,
  ModalContainer,
  ModalContainerOptions,
  SmallSubtitle,
  ToastBox,
} from 'app/components';
import { selectOrganisationID } from 'app/unauthenticated-app/authentication';
import { useFormik } from 'formik';
import React from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';

export const BankDetailsModal = (props: ModalContainerOptions) => {
  const { isOpen, onClose } = props;

  const toast = useToast();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const walletData = useSelector(selectWalletData);
  const organisation_id = useSelector(selectOrganisationID);

  const {
    isError,
    data: dedicatedVirtualAccount,
    isLoading: isLoadingDedicatedVirtualAccount,
  } = useQuery<DedidcatedVirtualAccount>(
    'dedicated-virtual-account',
    fetchDedicatedVirtualAccount,
    {
      enabled: !!walletData.dedicated_account_id,
    },
  );

  const {
    mutate: mutateCreateDedicatedVirtualAccount,
    isLoading: isCreatingDedicatedVirtualAccount,
  } = useMutation((payload: { phone: string }) => createDedicatedVirtualAccount(payload), {
    onSuccess: () => {
      dispatch(fetchWallet(organisation_id ?? ''));
      queryClient.invalidateQueries('dedicated-virtual-account');
    },
    onError: (error: any) => {
      toast({
        position: 'bottom-left',
        render: ({ onClose }) => <ToastBox onClose={onClose} message={error.message} />,
      });
    },
  });

  const { values, touched, errors, handleChange, handleSubmit } = useFormik({
    initialValues: {
      phone: '',
    },
    validationSchema: yup.object().shape({
      phone: yup.string().required('Phone number is required'),
    }),
    onSubmit: values => mutateCreateDedicatedVirtualAccount(values),
  });

  return (
    <ModalContainer size="md" isOpen={isOpen} onClose={onClose} title="Bank Details">
      <ModalCloseButton size="sm" />
      <ModalBody pb="4rem">
        {isLoadingDedicatedVirtualAccount ? (
          <FullPageSpinner height="40px" />
        ) : (
          <>
            {isError && (
              <Alert rounded="8px" status="error">
                <AlertIcon />
                <BodyText>
                  An error occurred while trying to fetch account details. Please close the modal
                  and try again.
                </BodyText>
              </Alert>
            )}
            {!!walletData.dedicated_account_id ? (
              <AccountDetails dedicatedVirtualAccount={dedicatedVirtualAccount} />
            ) : (
              <form onSubmit={handleSubmit}>
                <Stack spacing="1rem">
                  <BodyText>
                    Please enter a phone number to create a dedicated account for funding your
                    organisation's campaign credits.
                  </BodyText>
                  <Input
                    type="tel"
                    id="phone"
                    name="phone"
                    label="Phone number"
                    value={values.phone}
                    onChange={handleChange}
                    errorMessage={errors.phone}
                    placeholder="+2348078657916"
                    boxShadow="0 1px 2px 0 rgb(2 43 105 / 3%)"
                    isInvalid={!!touched.phone && !!errors.phone}
                  />
                  <Button
                    isFullWidth
                    type="submit"
                    variantColor="blue"
                    isLoading={isCreatingDedicatedVirtualAccount}
                  >
                    Create bank account
                  </Button>
                </Stack>
              </form>
            )}
          </>
        )}
      </ModalBody>
    </ModalContainer>
  );
};

const AccountDetails = (props: { dedicatedVirtualAccount?: DedidcatedVirtualAccount }) => {
  const { dedicatedVirtualAccount } = props;

  const { onCopy, hasCopied } = useClipboard(dedicatedVirtualAccount?.account_number ?? '');

  return (
    <>
      <Stack
        p="1rem"
        mb="1rem"
        bg="gray.50"
        rounded="8px"
        borderWidth="1px"
        position="relative"
        borderColor="gray.50"
      >
        <Tooltip aria-label="Copy bank account" label="Copy bank account" zIndex={100000000}>
          <IconButton
            size="xs"
            right=".25rem"
            top="0.25rem"
            variant="ghost"
            onClick={onCopy}
            aria-label="copy"
            position="absolute"
            _hover={{ bg: 'gray.100' }}
            icon={hasCopied ? 'check-circle' : 'copy'}
            color={hasCopied ? 'green.500' : 'gray.900'}
          />
        </Tooltip>
        <Stack spacing="0">
          <BodyText>Bank name</BodyText>
          <SmallSubtitle>{dedicatedVirtualAccount?.bank_name}</SmallSubtitle>
        </Stack>
        <Stack spacing="0">
          <BodyText>Account number</BodyText>
          <SmallSubtitle>{dedicatedVirtualAccount?.account_number}</SmallSubtitle>
        </Stack>
        <Stack spacing="0">
          <BodyText>Account name</BodyText>
          <SmallSubtitle>{dedicatedVirtualAccount?.account_name}</SmallSubtitle>
        </Stack>
      </Stack>
      <Alert rounded="8px" status="info" variant="subtle">
        <AlertIcon />
        <BodyText>
          <PseudoBox as="span" fontWeight="bold">
            NB:
          </PseudoBox>{' '}
          Your campaign credit will be automatically updated once payment is successful. Please
          reload the page to see the changes.
        </BodyText>
      </Alert>
    </>
  );
};
