import { Box } from '@chakra-ui/core';
import { Input } from 'app/components';
import React, { useContext } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { RequestParamsContext, RequestArrayProps } from '../../../contexts/request-contexts/params.context';
import { AppParamsContext } from '../../../contexts/app-contexts/params.context';

interface Prop {
    n: RequestArrayProps;
    requestArray: RequestArrayProps[];
};

const Settings = (props: Prop) => {
    const {settingsUniqueIdentifier, setSettingsUniqueIdentifier, arrayKey, setArrayKey, responseBodyEnabled, setResponseBodyEnabled} = useContext(RequestParamsContext)
    const {RadioImageValue} = useContext(AppParamsContext)
    
    const initialValues = {
        uniqueIdentifier: '',
        arrayKey: '',
    }
    
    const CustomAppValidationSchema = yup.object().shape({
        uniqueIdentifier: yup.string().nullable(),
        account_name: yup.string().nullable(),
    });

    const submit = async () => {
    
    };

    const formik = useFormik({
        validationSchema: CustomAppValidationSchema, 
        initialValues,
        onSubmit: submit,
    });
        
    const {
        errors,
        handleReset,
        handleChange,
        handleBlur,
        touched
    } = formik;

    const changeHandler = (e: any, state: any, setState: Function) => {
        state[props.n?.id] = e.target.value
        setState([...state])

        if ((RadioImageValue === '2' && settingsUniqueIdentifier[props.n?.id] && arrayKey[props.n?.id]) || (RadioImageValue === '1' && settingsUniqueIdentifier[props.n?.id])) {
            responseBodyEnabled[props.n?.id] = true
            setResponseBodyEnabled([...responseBodyEnabled])
        } else {
            responseBodyEnabled[props.n?.id] = false
            setResponseBodyEnabled([...responseBodyEnabled])
        }
        handleBlur(e)
    }
    
    return (
        <Box>
            <Box marginTop='1.7rem' className='custom-app-field' width='50%'>
                <Input
                    label="Response Unique Identifier"
                    isInvalid={touched.uniqueIdentifier && !!errors.uniqueIdentifier}
                    mb="5px"
                    name="name"
                    defaultValue={settingsUniqueIdentifier[props.n?.id]}
                    onChange={handleChange}
                    onBlur={(e: any) => changeHandler(e, settingsUniqueIdentifier, setSettingsUniqueIdentifier)}
                    onReset={handleReset}
                    errorMessage={errors.uniqueIdentifier}
                    placeholder="Key of each response object that uniquely identifies it"
                    autoComplete='off'
                />
            </Box>

            {RadioImageValue === '2' && <Box marginTop='1.7rem' className='custom-app-field' width='50%'>
                <Input
                    label="Response Array Key"
                    isInvalid={touched.arrayKey && !!errors.arrayKey}
                    mb="5px"
                    name="name"
                    defaultValue={arrayKey[props.n?.id]}
                    onChange={handleChange}
                    onBlur={(e: any) => changeHandler(e, arrayKey, setArrayKey)}
                    onReset={handleReset}
                    errorMessage={errors.arrayKey}
                    placeholder="Key of the array of your response data"
                    autoComplete='off'
                    key={'input112'}
                />
            </Box>}
        </Box>
        
    )
}

export default Settings
