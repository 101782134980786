import { Box, Heading, Stack, useToast } from '@chakra-ui/core/dist';
import {
  Button,
  ConfirmModal,
  Container,
  EmptyState,
  Input,
  Table,
  ToastBox,
} from 'app/components';
import { capitalize } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'root';
import { validateEmail } from 'utils';
import usePermission from 'utils/usePermission';
import noPeople from '../../assets/no-people.svg';
import { Permission } from '../../settings.types';
import { PermissionDropdown } from './permission-dropdown';
import {
  fetchBulitInPermissions,
  patchUserPermissions,
  selectAllPermissions,
  sendAnInvite,
} from './redux';
import { PeopleTableColumns } from './table-columns';

export const PeopleComponent = (props: any) => {
  const { permissionsData, loading } = useSelector(selectAllPermissions);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [inviteQuery, setInviteQuery] = useState<string>('');
  const [isRemovingMember, setIsRemovingMember] = useState(false);
  const [permission, setPermission] = useState<Permission | undefined>();
  const [memberToRemove, setMemberToRemove] = useState<any | undefined>();
  const [memberToUpdate, setMemberToUpdate] = useState<any | undefined>();
  const [isUpdatingMember, setIsUpdatingMember] = useState(false);
  const [isInvitingMember, setIsInvitingmember] = useState(false);
  const [data, setData] = useState<any | undefined>();
  const validatedEmail = validateEmail(inviteQuery);

  const dispatch = useDispatch();
  const BASE_URL =
    process.env.NODE_ENV === 'development'
      ? 'http://localhost:3000/'
      : process.env.REACT_APP_DEMO_URL;

  const { people, deleteOrganisationMember } = props;

  const rows = people.filter((i: any) =>
    JSON.stringify(i).toLowerCase().includes(searchQuery.toLowerCase()),
  );

  const toast = useToast();

  const copyMemberEmail = (member: any) => {
    navigator.clipboard.writeText(member.email);
    toast({
      position: 'bottom-left',
      render: ({ onClose }) => (
        <ToastBox status="info" onClose={onClose} message="Member email copied" />
      ),
    });
  };

  const handleOpenDeleteMemberModal = (member: any) => {
    setMemberToRemove(member);
  };

  const handleOpenUpdateMemberModal = (member: any) => {
    setMemberToUpdate(member);
  };

  const handleUpdatedAccess = (data: any) => {
    setData(data);
  };

  const handleSelectInvitedMemberPermission = (permission?: Permission) => {
    setPermission(permission);
  };

  const handleDeleteMember = async () => {
    try {
      setIsRemovingMember(true);
      await deleteOrganisationMember({ user_id: memberToRemove?.id });
      setIsRemovingMember(false);
      setMemberToRemove(undefined);
      toast({
        position: 'bottom-left',
        render: ({ onClose }) => (
          <ToastBox status="success" onClose={onClose} message="Member removed successfully" />
        ),
      });
    } catch (error: any) {
      setIsRemovingMember(false);
      toast({
        position: 'bottom-left',
        render: ({ onClose }) => <ToastBox onClose={onClose} message={error.message} />,
      });
    }
  };

  const handleUpdateMember = async () => {
    try {
      setIsUpdatingMember(true);
      const profile_id = memberToUpdate?.profile_id;
      const { id, page_access } = data;
      await dispatch(
        patchUserPermissions({
          profile_id,
          permission_id: id,
          page_access,
        }),
      );
      setIsUpdatingMember(false);
      setMemberToUpdate(undefined);
      toast({
        position: 'bottom-left',
        render: ({ onClose }) => (
          <ToastBox status="success" onClose={onClose} message="Member Updated successfully" />
        ),
      });
    } catch (error: any) {
      setIsUpdatingMember(false);
      toast({
        position: 'bottom-left',
        render: ({ onClose }) => <ToastBox onClose={onClose} message={error.message} />,
      });
    }
  };

  const sendTheOrganisationInvite = async (data: any) => {
    if (permission) {
      data = {
        email: inviteQuery,
        link: `${BASE_URL}organisations/invite/accept/{{email}}/{{token}}/{{organisationID}}/{{organisationName}}`,
        permission_id: permission.id,
        page_access: permission.page_access,
      };
    }
    setIsInvitingmember(true);
    const { error }: any = await dispatch(sendAnInvite(data));
    setIsInvitingmember(false);
    if (error) {
      toast({
        position: 'bottom-left',
        render: ({ onClose }) => <ToastBox onClose={onClose} message={error.message} />,
      });
    } else {
      setInviteQuery('');
      toast({
        position: 'bottom-left',
        render: ({ onClose }) => (
          <ToastBox onClose={onClose} status="success" message={'Invite Sent'} />
        ),
      });
    }
  };

  const checkForYourself = (profile: any, obj: any) => {
    const profileName = `${capitalize(profile?.first_name)} ${capitalize(profile?.last_name)}`;
    const memberName = `${capitalize(obj?.first_name)} ${capitalize(obj?.last_name)}`;
    if (profileName === memberName) {
      return 'Yourself';
    }
    return memberName;
  };

  const checkForAorAn = (obj: any) => {
    if (obj?.name === 'Admin') {
      return `an Admin`;
    }
    return `a ${obj?.name}`;
  };

  const columns = useMemo(
    () =>
      PeopleTableColumns({
        copyMemberEmail,
        onDeleteMember: handleOpenDeleteMemberModal,
        onUpdateMember: handleOpenUpdateMemberModal,
        onUpdateAccess: handleUpdatedAccess,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const { profile } = useSelector((state: RootState) => state.auth);
  const permissions = profile && profile.permissions;
  const requiresPermission = usePermission('people.invite', permissions || []);

  const permissionTitle: any = permissionsData?.find((per: any) => per.id === data?.id);

  useEffect(() => {
    dispatch(fetchBulitInPermissions());
  }, [dispatch]);

  return (
    <Container>
      {loading === 'complete' && (
        <>
          <Stack isInline alignItems="center" marginBottom="1.5rem">
            <Heading size="md" color="#333333" fontWeight="semibold">
              Manage People
            </Heading>
            <Box fontWeight="400" color="#757575" fontSize="14px">
              - {people.length} user(s)
            </Box>
          </Stack>
          <Stack isInline alignItems="center">
            <Input
              size="sm"
              autoFocus
              value={searchQuery}
              containerWidth="50%"
              placeholder="Search by name or email"
              onChange={(e: any) => setSearchQuery(e.target.value)}
            />
            {requiresPermission && (
              <Stack width="50%" isInline spacing="0" alignItems="center">
                <Input
                  size="sm"
                  roundedTopRight="0"
                  value={inviteQuery}
                  roundedBottomRight="0"
                  placeholder="Invite by email"
                  onChange={(e: any) => setInviteQuery(e.target.value)}
                />
                <Box>
                  <PermissionDropdown
                    invite={true}
                    permission={permission}
                    permissions={permissionsData}
                    onChange={handleSelectInvitedMemberPermission}
                  />
                </Box>
                <Button
                  size="sm"
                  width="100px"
                  roundedTopLeft="0"
                  variantColor="blue"
                  roundedBottomLeft="0"
                  isLoading={isInvitingMember}
                  onClick={sendTheOrganisationInvite}
                  isDisabled={!(validatedEmail || permission) || !permission?.page_access.length}
                >
                  Invite
                </Button>
              </Stack>
            )}
          </Stack>

          <Box marginTop="25px">
            {rows.length > 0 && (
              <Table
                // @ts-ignore
                columns={columns}
                data={rows}
                onRowClick={() => {}}
              />
            )}
            {rows.length === 0 && (
              <EmptyState
                marginY="10vh"
                image={noPeople}
                subheading="No users found in your search"
                subheadingProps={{ marginTop: '25px', fontWeight: '500' }}
              />
            )}
          </Box>
          <ConfirmModal
            title="Remove member"
            isOpen={!!memberToRemove}
            isLoading={isRemovingMember}
            onConfirm={handleDeleteMember}
            onClose={() => setMemberToRemove(undefined)}
          />
          <ConfirmModal
            title="Update member role"
            hidePrompt={true}
            description={`Confirm making ${checkForYourself(
              profile,
              memberToUpdate,
            )} ${checkForAorAn(permissionTitle)} in this organisation`}
            isOpen={!!memberToUpdate}
            isLoading={isUpdatingMember}
            onConfirm={handleUpdateMember}
            onClose={() => setMemberToUpdate(undefined)}
          />
        </>
      )}
    </Container>
  );
};
