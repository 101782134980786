import React from 'react';
import { Box, IconButton, Image, useToast } from '@chakra-ui/core/dist';
import { ToastBox } from 'app/components';

export const AttachmentCell = (props: any) => {
  const toast = useToast();
  const { allowEdit = false } = props;

  const handleUpload = async (error: any, result: any) => {
    if (result && result?.event !== 'success') return;
    if (error) {
      return toast({
        position: 'bottom-left',
        render: ({ onClose }) => (
          <ToastBox
            status="error"
            message="Unable to complete upload, please try again"
            onClose={onClose}
          />
        ),
      });
    }
    const rowIndex = props.node.rowIndex;
    const row = await props.getRowByIndex(rowIndex);
    const columnID = props._column.uid;
    const rowID = row['uid'];

    const {
      thumbnail_url,
      height,
      width,
      secure_url,
      bytes,
      format,
      original_filename,
      public_id,
      resource_type,
    } = result.info;

    const image = {
      height,
      width,
      id: public_id,
      url: secure_url,
      thumbnail: thumbnail_url,
      size: bytes,
      filename: `${original_filename}${format ? `.${format}` : ''}`,
      type: `${resource_type}${format ? `/${format}` : ''}`,
    };

    let payload;

    if (Array.isArray(props.value)) {
      payload = [...props.value, image];
    } else payload = [image];

    await props.updateCellValue(payload, rowID, columnID);
  };

  const deleteAttachment = async (attachmentIndex: number) => {
    const updatedValue = props.value.filter((i: any, index: number) => index !== attachmentIndex);
    const rowIndex = props.node.rowIndex;
    const row = await props.getRowByIndex(rowIndex);
    const columnID = props._column.uid;
    const rowID = row['uid'];
    await props.updateCellValue(updatedValue, rowID, columnID);

    return toast({
      position: 'bottom-left',
      render: ({ onClose }) => (
        <ToastBox status="success" message="Attachment removed successfully!" onClose={onClose} />
      ),
    });
  };

  const openWidget = () => {
    // @ts-ignore
    const widget = window.cloudinary.createUploadWidget(
      {
        cloudName: `simpu-inc`,
        uploadPreset: `attachment-upload`,
        sources: ['local', 'url', 'camera', 'dropbox', 'google_drive', 'instagram'],
        multiple: false,
        showPoweredBy: false,
        singleUploadAutoClose: true,
        styles: {
          palette: {
            window: '#FFF',
            windowBorder: '#90A0B3',
            tabIcon: '#0E2F5A',
            menuIcons: '#5A616A',
            textDark: '#000000',
            textLight: '#FFFFFF',
            link: '#026ae8',
            action: '#FF620C',
            inactiveTabIcon: '#0E2F5A',
            error: '#F44235',
            inProgress: '#026ae8',
            complete: '#20B832',
            sourceBg: '#E4EBF1',
          },
          fonts: {
            "'DM Sans', calibri": 'https://fonts.googleapis.com/css?family=DM+Sans',
          },
        },
      },
      handleUpload,
    );
    widget.open();
  };

  return (
    <Box display="flex" alignItems="center" flexDirection="row" height="35px">
      {Array.isArray(props.value) && (
        <>
          {props.value.map((i: any, index: number) => (
            <Box
              key={index}
              marginRight="5px"
              cursor="pointer"
              onClick={() => window.open(i?.url, '_blank')}
              title={i.filename}
              minWidth="max-content"
              display="flex"
              flexDirection="row"
            >
              <Image
                className="file-attachment"
                src={i?.thumbnail || require('../../../assets/unknown-file.svg')}
                alt=""
              />
              {allowEdit && (
                <IconButton
                  className="file-attachment-close-button"
                  aria-label="close"
                  icon="close"
                  size="xs"
                  onClick={(e: any) => {
                    e.stopPropagation();
                    deleteAttachment(index);
                  }}
                />
              )}
            </Box>
          ))}
        </>
      )}

      {allowEdit && (
        <Box className="attachment-actions">
          <IconButton
            color="#757575"
            size="xs"
            onClick={openWidget}
            icon="small-add"
            aria-label="add"
            title="Add Attachment"
          />

          {!props?.value?.length && (
            <Box
              fontSize="10px"
              marginLeft="5px"
              color="#757575"
              onClick={openWidget}
              cursor="pointer"
            >
              Add attachment
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};
