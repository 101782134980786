import React from 'react';
import { connect } from 'react-redux';
import { SMSCampaginAnalyticsUI } from './analytics.ui';
import { CampaignAnalyticsProps } from './analytics.types';
import { RootState } from '../../../../root';
import { fetchCampaign } from './campaigns.reducer';
import { EmailCampaignAnalyticsUI } from './email-analytics.component';

function mapStateToProps(state: RootState) {
  return {
    campaign: state.campaigns.campaign,
  };
}

export const campaignAnalyticsConnector = connect(mapStateToProps, {
  fetchCampaign,
});

const SMSCampaignAnalyticsContainer = (props: CampaignAnalyticsProps) => {
  return <SMSCampaginAnalyticsUI {...props} />;
};

const EmailCampaignAnalyticsContainer = () => {
  return <EmailCampaignAnalyticsUI />;
};

export const SMSCampaignAnalytics = campaignAnalyticsConnector(SMSCampaignAnalyticsContainer);

export const EmailCampaignAnalytics = campaignAnalyticsConnector(EmailCampaignAnalyticsContainer);
