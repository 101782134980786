/* eslint-disable react/no-unescaped-entities */
import {
  Alert,
  AlertIcon,
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  ModalBody,
  Select,
  Stack,
} from '@chakra-ui/core';
import React from 'react';
import { BodyText, ModalContainer, ModalContainerOptions, Button } from 'app/components';
import { FormikConfig, useFormik } from 'formik';
import * as yup from 'yup';
import { DeleteAccountPayload } from './DeleteAccountModal';

export const DeleteOrganisationModal = (
  props: ModalContainerOptions & {
    isDeleting?: boolean;
    onDeleteAccount: FormikConfig<DeleteAccountPayload>['onSubmit'];
  },
) => {
  const { isOpen, onClose, isDeleting, onDeleteAccount } = props;

  const reasons = ["I can't delete my inbox"];

  const { values, errors, touched, handleChange, handleSubmit } = useFormik<DeleteAccountPayload>({
    onSubmit: onDeleteAccount,
    initialValues: { reason: '' },
    validationSchema: yup.object().shape({
      reason: yup.string().required('Reason is required'),
    }),
  });

  return (
    <ModalContainer
      size="lg"
      isOpen={isOpen}
      showCloseButton
      onClose={onClose}
      title="Delete account request"
    >
      <ModalBody px="0">
        <form onSubmit={handleSubmit}>
          <Stack spacing="2rem" pb="1rem" px="1rem">
            <Alert rounded="4px" status="info" alignItems="flex-start">
              <AlertIcon />
              <Stack>
                <BodyText>
                  If you are sure you want to proceed with the request of your organisation
                  deletion, please continue below.
                </BodyText>
                <BodyText>
                  This initiates an organisation deletion which will result in the erase of all data
                  associated with your organisation from our servers.
                </BodyText>
                <BodyText>
                  This action can be cancelled within the next{' '}
                  <Box as="span" fontWeight="bold">
                    30 days
                  </Box>
                  .
                </BodyText>
              </Stack>
            </Alert>
            <Stack>
              <FormControl isInvalid={!!touched.reason && !!errors.reason}>
                <FormLabel fontSize="0.875rem">
                  Tell us why you are leaving{' '}
                  <span role="img" aria-label="sad emoji">
                    😔
                  </span>
                </FormLabel>
                <Select name="reason" value={values.reason} onChange={handleChange}>
                  <option>--Select reason--</option>
                  {reasons.map((item, index) => (
                    <option key={`${item}-${index}`} value={item}>
                      {item}
                    </option>
                  ))}
                </Select>
                <FormErrorMessage>{errors.reason}</FormErrorMessage>
              </FormControl>
            </Stack>
          </Stack>
          <Box p="1rem" borderTopWidth="1px">
            <Button isLoading={isDeleting} type="submit" variantColor="red" width="100%">
              Delete organisation
            </Button>
          </Box>
        </form>
      </ModalBody>
    </ModalContainer>
  );
};
