// @ts-nocheck
import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { Box, Icon, Input, PseudoBox } from '@chakra-ui/core';
import { PopoverWrapper } from '../../../list-view.styles';
import { SelectCellEditor } from './editor';

const SelectInput = forwardRef(({ addOption, setInputValue, allowCreate }: any, ref: any) => {
  const [value, setValue] = useState('');

  const handleChange = (event: any) => {
    setValue(event.target.value);
    setInputValue(event.target.value);
  };

  const handleKeyPress = (e: any) => {
    if (!allowCreate) return;
    if (e.key !== 'Enter') return;
    if (value === '') return;
    addOption(value);
    setValue('');
    setInputValue('');
  };

  return (
    <Input
      ref={ref}
      variant="unstyled"
      width="auto"
      value={value}
      onChange={handleChange}
      onKeyPress={handleKeyPress}
    />
  );
});

const SelectValueItem = (props: any) => {
  const handleDeleteValue = (event: any) => {
    event.stopPropagation();
    props.delete(props.id);
  };

  return (
    <div
      className="value-item"
      style={{
        fontSize: 12,
        backgroundColor: props.color,
        margin: '0.25rem',
        padding: '4px 5px',
        borderRadius: 4,
        lineHeight: 'normal',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        cursor: 'pointer',
        ...props.itemStyles,
      }}
    >
      {props.value}
      <Box
        marginLeft="5px"
        display="flex"
        padding="2.5px"
        borderRadius="2px"
        backgroundColor="rgba(0,0,0,0.1)"
        onClick={handleDeleteValue}
        className="close-icon"
      >
        <Icon name="close" size="8px" cursor="pointer" className="close-icon" />
      </Box>
    </div>
  );
};

export const SelectFieldComponent = (props: any) => {
  const [dummyValue, setDummyValue] = useState<string | number | null>(null);
  const [dummyOptions, setDummyOptions] = useState([]);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [inputFocused, setInputFocused] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>('');

  const inputRef = useRef(null);
  const containerRef = useRef(null);
  const popoverRef = useRef(null);

  const {
    column,
    inputProps = {},
    optionItemStyles = {},
    allowCreate = true,
    isDisabled = false,
  } = props;

  useEffect(() => {
    setDummyValue(props.value);
    setDummyOptions(props.column.options || []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // @ts-ignore
    if (inputFocused && document.activeElement !== inputRef.current) inputRef.current.focus();
  }, [inputFocused]);

  const onClose = () => {
    setIsOpen(false);
  };

  const handleClick = (e: any) => {
    if (!isOpen) return;
    // @ts-ignore
    if (popoverRef.current.contains(e.target)) return;
    // @ts-ignore
    if (containerRef.current.contains(e.target)) return;
    onClose();
  };

  const deleteValue = async (option_id: string | number) => {
    setDummyValue(null);
    props.updateCellValue(null, column.uid);
  };

  const addOption = async (_value: string) => {
    const option = await props.addSelectOption(column.uid, _value);
    // @ts-ignore
    setDummyOptions([...dummyOptions, option]);
    updateValue(option.id);
  };

  const updateSelectOption = async (option_id: string | number, payload: object) => {
    const updated_options = await props.updateSelectOption(column.uid, option_id, payload);
    setDummyOptions(updated_options);
  };

  const updateValue = (_value: any) => {
    setDummyValue(_value);
    props.updateCellValue(_value, column.uid);
  };

  const attachOutsideClickListener = () => {
    document.addEventListener('mousedown', handleClick);
  };
  const detachOutsideClickListener = () => {
    document.removeEventListener('mousedown', handleClick);
  };

  useEffect(() => {
    attachOutsideClickListener();
    return detachOutsideClickListener;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  });

  let options = dummyOptions.filter((i: any) => !i.isDeleted && !i.is_deleted);
  const selected_option = options.find((i: any) => Number(i.id) === Number(dummyValue));

  return (
    <>
      <PseudoBox
        onClick={() => {
          setIsOpen(true);
          if (allowCreate) setInputFocused(true);
        }}
        ref={containerRef}
        width="100%"
        borderRadius="0.25rem"
        display="flex"
        flexWrap="wrap"
        alignItems="center"
        flexDirection="row"
        _hover={{
          backgroundColor: 'gray.100',
        }}
        cursor={isDisabled ? 'not-allowed' : 'default'}
        {...inputProps}
      >
        {selected_option && (
          <SelectValueItem
            value={selected_option.name}
            color={selected_option.color}
            delete={!isDisabled ? deleteValue : () => {}}
            id={selected_option.id}
          />
        )}
        {!isDisabled && (
          <SelectInput
            ref={inputRef}
            addOption={addOption}
            setInputValue={setInputValue}
            allowCreate={allowCreate}
          />
        )}
      </PseudoBox>
      {isOpen && !isDisabled && (
        <Box
          ref={popoverRef}
          zIndex={100000}
          position="absolute"
          width="max-content"
          boxShadow="none"
          display="flex"
        >
          <PopoverWrapper>
            <SelectCellEditor
              {...{
                options: dummyOptions.filter((i: any) =>
                  i.name.toLowerCase().includes(inputValue.toLowerCase()),
                ),
                updateSelectOption,
                updateValue,
                allowCreate,
                optionItemStyles,
              }}
            />
          </PopoverWrapper>
        </Box>
      )}
    </>
  );
};
