import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  CloseButton,
  FormControl,
  FormErrorMessage,
  Icon,
  Link,
  Stack,
  Text,
  Tooltip,
} from '@chakra-ui/core';
import { EmailSenderProfileModal } from 'app/authenticated-app/settings/component/sender-profiles/email-sender-profile-modal';
import { selectOrganisations } from 'app/authenticated-app/settings/slices';
import {
  AdvancedSelect,
  Button,
  Container,
  EmptyState,
  Input,
  useResellerSettings,
} from 'app/components';
import { selectOrganisationID } from 'app/unauthenticated-app/authentication';
import { FormikHandlers, FormikHelpers, FormikState } from 'formik';
import React from 'react';
import EmailEditor from 'react-email-editor';
import { useSelector } from 'react-redux';
import { CampaignPayload } from '../../campaigns.types';
import { useEmailCampaignContent } from '../../hooks/use-email-campaign-content';
import { EmailCodeEditor } from './email-code-editor';

type EmailCampaignSetupContentProps = {
  lists?: any;
  emailEditorIsLoaded: boolean;
  isCreatingEmailSenderProfile: boolean;
  emailEditorRef: React.MutableRefObject<any>;
  handleChange: FormikHandlers['handleChange'];
  errors: FormikState<CampaignPayload>['errors'];
  values: FormikState<CampaignPayload>['values'];
  touched: FormikState<CampaignPayload>['touched'];
  onCreateEmailSenderProfile: any;
  setEmailEditorIsLoaded: React.Dispatch<React.SetStateAction<boolean>>;
  setFieldValue?: FormikHelpers<Partial<CampaignPayload>>['setFieldValue'];
  addEmailSenderProfileModalProps: {
    isOpen: boolean;
    onOpen(): void;
    onClose(): void;
    onToggle(): void;
  };
};

export const EmailCampaignSetupContent = (props: EmailCampaignSetupContentProps) => {
  const {
    lists,
    values,
    errors,
    touched,
    handleChange,
    setFieldValue,
    emailEditorRef,
    setEmailEditorIsLoaded,
    onCreateEmailSenderProfile,
    isCreatingEmailSenderProfile,
    addEmailSenderProfileModalProps,
  } = props;

  const organizations = useSelector(selectOrganisations);
  const organizationID = useSelector(selectOrganisationID);

  const {
    companyProfile,
    senderProfiles,
    handleFromChange,
    getAudienceValue,
    handleAudienceChange,
    hasNoEmailType,
    onCloseEmailTypeAlert,
  } = useEmailCampaignContent({
    values,
    setFieldValue,
  });

  const currentOrganization = organizations.find((i: any) => i.id === organizationID);

  const onEditorLoad = () => {
    if (emailEditorRef.current) {
      setEmailEditorIsLoaded(true);
      emailEditorRef.current.editor.loadDesign(values.json);
      emailEditorRef.current.editor.setBodyValues({
        contentWidth: '500px',
        backgroundColor: '#f7f8fa',
      });
    }
  };

  const { name } = useResellerSettings();

  return (
    <Container pb="1rem" pt="1rem" mb="0" height="100%" maxW="1200px">
      {hasNoEmailType && (
        <Alert mb="4rem" status="error" rounded="4px">
          <AlertIcon />
          <AlertTitle mr={2}>Error</AlertTitle>
          <AlertDescription fontSize="0.875rem">
            Selected list has no email column. Select a list with an email column.
          </AlertDescription>
          <Link
            pl="0.2rem"
            target="_blank"
            color="blue.500"
            fontSize="0.875rem"
            href="https://support.simpu.co/en/articles/6788747-changing-column-property-type-s-in-data"
          >
            Learn more
          </Link>
          <CloseButton top="8px" right="8px" position="absolute" onClick={onCloseEmailTypeAlert} />
        </Alert>
      )}
      <Box
        height="100%"
        rounded="8px"
        boxShadow="0 0 0.3125rem rgba(23,24,24,.05),0 0.0625rem 0.125rem rgba(0,0,0,.15)"
        display="flex"
        flexDirection="column"
      >
        <Box p="1rem" bg="white">
          <Box mb="1rem">
            <Input
              type="text"
              name="name"
              value={values.name}
              label="Campaign name"
              onChange={handleChange}
              errorMessage={errors.name}
              placeholder="Give your campaign a name"
              isInvalid={!!touched.name && !!errors.name}
            />
          </Box>
          <FormControl mb="1rem" isInvalid={!!touched.campaign_id && !!errors.campaign_id}>
            <AdvancedSelect
              isGroup
              isSearchable
              options={lists}
              value={getAudienceValue()}
              label={
                <Stack isInline alignItems="center">
                  <Text>Who will you send this campaign to?</Text>
                  <Tooltip
                    zIndex={10000}
                    placement="right"
                    aria-label="Campaign recipients"
                    label="Choose from your list, smart lists, segments, or target new audiences."
                  >
                    <Icon size="0.75rem" name="info" color="gray.500" />
                  </Tooltip>
                </Stack>
              }
              placeholder="Search & Select a list"
              onChange={({ value }) => handleAudienceChange(value)}
              isInvalid={!!touched.campaign_id && !!errors.campaign_id}
            />
            <FormErrorMessage>{errors.campaign_id}</FormErrorMessage>
          </FormControl>
          <Box mb="1rem">
            <Input
              type="text"
              name="subject"
              value={values.subject}
              onChange={handleChange}
              label={<Text>Subject</Text>}
              errorMessage={errors.subject}
              placeholder={`Welcome to ${name}!`}
              isInvalid={!!touched.subject && !!errors.subject}
            />
          </Box>
          <FormControl isInvalid={!!touched.sender_id && !!errors.sender_id}>
            <AdvancedSelect
              isSearchable
              value={values.sender_id}
              options={senderProfiles}
              label={
                <Stack isInline alignItems="center">
                  <Text>From</Text>
                  <Tooltip zIndex={10000} placement="right" aria-label="From" label="From">
                    <Icon size="0.75rem" name="info" color="gray.500" />
                  </Tooltip>
                </Stack>
              }
              placeholder="Search & Select a from details"
              onChange={({ value }) => handleFromChange(value)}
              isInvalid={!!touched.sender_id && !!errors.sender_id}
              //@ts-ignore
              noOptionsMessage={() => (
                <EmptyState
                  width="320px"
                  marginLeft="0"
                  heading="Add an email sender profile"
                  subheading="Standout in your customer's inbox with unique sender profiles for your organisation."
                >
                  <Button
                    size="sm"
                    variantColor="blue"
                    onClick={addEmailSenderProfileModalProps.onOpen}
                  >
                    Add Email Sender Profile
                  </Button>
                </EmptyState>
              )}
            />
            <FormErrorMessage>{errors.sender_id}</FormErrorMessage>
          </FormControl>
        </Box>
        {values.emailTemplateType === 'template' ? (
          <EmailEditor
            //@ts-ignore
            height="100%"
            minHeight="0%"
            ref={emailEditorRef}
            //@ts-ignore
            onReady={onEditorLoad}
            tools={{
              'custom#simpu_email_footer': {
                data: {
                  website: companyProfile?.website ?? '',
                  postal_address: companyProfile?.postal_address ?? '',
                  name: companyProfile?.name ?? currentOrganization?.name ?? '',
                  show_watermark: organizationID === process.env.REACT_APP_SIMPU_ORG_ID,
                },
              },
            }}
            options={{
              customJS: [
                `${
                  process.env.NODE_ENV === 'production'
                    ? process.env.REACT_APP_URL
                    : 'http://localhost:3000'
                }/js/unlayer/custom.js`,
              ],
            }}
            projectId={parseInt(process.env.REACT_APP_UNLAYER_PROJECT_ID ?? '') ?? undefined}
          />
        ) : (
          <EmailCodeEditor
            values={{ content: values.content }}
            onChange={value => setFieldValue?.('content', value)}
          />
        )}
        {addEmailSenderProfileModalProps.isOpen && (
          <EmailSenderProfileModal
            isLoading={isCreatingEmailSenderProfile}
            isOpen={addEmailSenderProfileModalProps.isOpen}
            onClose={() => addEmailSenderProfileModalProps.onClose()}
            onSubmit={(payload, helpers) => onCreateEmailSenderProfile({ payload, helpers })}
          />
        )}
      </Box>
    </Container>
  );
};
