import React, { useRef } from 'react';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
} from '@chakra-ui/core';
import { Button, ModalContainerOptions } from 'app/components';

export const ConfirmationAlert: React.FC<
  ModalContainerOptions & { onConfirm(): void; isLoading?: boolean }
> = ({ isOpen, onClose, onConfirm, isLoading }) => {
  const cancelRef = useRef<HTMLButtonElement>(null);

  return (
    <AlertDialog isCentered isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
      <AlertDialogOverlay />
      <AlertDialogContent rounded="lg">
        <AlertDialogHeader fontSize="lg" fontWeight="bold">
          Send Airtime
        </AlertDialogHeader>

        <AlertDialogBody>
          Are you sure you want to send airtime rewards. You can't undo this action afterwards.
        </AlertDialogBody>

        <AlertDialogFooter>
          <Button size="sm" ref={cancelRef} onClick={onClose}>
            Cancel
          </Button>
          <Button size="sm" variantColor="blue" onClick={onConfirm} ml={3} isLoading={isLoading}>
            Yes, I confirm
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
