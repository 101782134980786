import React, { useEffect, useState } from 'react';
import { Switch } from 'react-router';
import { Route } from 'react-router-dom';
import { ListResources } from './list-resources';
import { CreateResource } from './create-resource';
import { useDispatch } from 'react-redux';
import { fetchCustomApps } from 'app/authenticated-app/lists/thunks/resources';
import { ToastBox } from 'app/components';
import { useToast } from '@chakra-ui/core';

interface Props {
  searchValue: string;
  deleteResource: Function;
  updateResource: Function;
  testResourceConnection: Function;
  createResource: Function;
  requestResourceAuth: Function;
  requestAppResourceAuth: Function;
  submitAppResourceAuth: Function;
  enableResourceWebhook: Function;
  disableResourceWebhook: Function;
  submitCustomAppResourceAuth: Function;
}

export const Component = (props: Props) => {
  const {
    searchValue,
    deleteResource,
    updateResource,
    requestResourceAuth,
    testResourceConnection,
    createResource,
    requestAppResourceAuth,
    submitAppResourceAuth,
    enableResourceWebhook,
    disableResourceWebhook,
    submitCustomAppResourceAuth
  } = props;

  const toast = useToast();
  const dispatch = useDispatch()
  const [customApps, setCustomApps] = useState([])

  useEffect(() => {
    let isMounted = true;

    const fetch = async () => {
      try {
        const custom = (await dispatch(fetchCustomApps()) as any);
        if (isMounted) {
          setCustomApps(custom)
        }
      } catch (e) {
        if (isMounted) {
          setCustomApps([])
        }
        toast({
          position: 'bottom-left',
          render: () => <ToastBox message="Unable to fetch custom apps, please try again" />,
        });
      }
    }
    fetch()
    return () => { isMounted = false };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Switch>
      <Route exact path="/s/lists/connections">
        <ListResources
          searchValue={searchValue}
          deleteResource={deleteResource}
          updateResource={updateResource}
          enableResourceWebhook={enableResourceWebhook}
          disableResourceWebhook={disableResourceWebhook}
        />
      </Route>
      <Route exact path="/s/lists/connections/new">
        <CreateResource
          requestResourceAuth={requestResourceAuth}
          requestAppResourceAuth={requestAppResourceAuth}
          submitAppResourceAuth={submitAppResourceAuth}
          testResourceConnection={testResourceConnection}
          createResource={createResource}
          customApps={customApps}
          submitCustomAppResourceAuth={submitCustomAppResourceAuth}
        />
      </Route>
    </Switch>
  );
};
