import React, { useEffect } from 'react';
import { Box, Icon, IconButton } from '@chakra-ui/core';
import usePermission from 'utils/usePermission';
import { useSelector } from 'react-redux';
import { RootState } from 'root';

export const Header = (props: any) => {
  const {
    column: { colId },
    handleColumnClick,
    icon,
    setActiveColumn,
  } = props;

  const disableContextMenu = (event: Event) => {
    event.preventDefault();
    // event.stopPropagation();
    return false;
  };

  const attachColumnClickListener = () => {
    const column_header_element = document.querySelectorAll(`[col-id="${colId}"]`)[0];
    if (!column_header_element) return;
    column_header_element.addEventListener('contextmenu', disableContextMenu);
    column_header_element.addEventListener('mousedown', handleColumnClick);
  };

  const detachColumnClickListener = () => {
    const column_header_element = document.querySelectorAll(`[col-id="${colId}"]`)[0];
    if (!column_header_element) return;
    column_header_element.removeEventListener('contextmenu', disableContextMenu);
    column_header_element.removeEventListener('mousedown', handleColumnClick);
  };

  const { profile } = useSelector((state: RootState) => state.auth);
  const permissions = profile && profile.permissions;
  const requiresPropertyUpdate = usePermission('list.property.update', (permissions || []));

  useEffect(() => {
    attachColumnClickListener();
    return detachColumnClickListener;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      display="flex"
      flexDirection="row"
      fontSize="12px"
      alignItems="center"
      fontWeight="500"
      color="#333333"
      overflow="hidden"
      whiteSpace="nowrap"
      justifyContent="space-between"
      onContextMenu={event => {
        event.preventDefault();
        if (requiresPropertyUpdate) setActiveColumn(colId);
        return false;
      }}
    >
      <Box display="flex" alignItems="center" flexDirection="row">
        <Icon name={icon} size="12px" marginRight="5px" color="#8c8c8c" />
        {props.displayName}
      </Box>
      <IconButton
        aria-label="open"
        icon="chevron-down"
        isRound
        height="1rem"
        minWidth="1rem"
        margin="2px"
        fontSize="12px"
        onClick={() => setActiveColumn(colId)}
        isDisabled={!requiresPropertyUpdate}
      />
    </Box>
  );
};
