import { initializeApp } from '@firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);

const messaging = getMessaging(app);

const GENERATED_MESSAGING_KEY = process.env.REACT_APP_GENERATED_MESSAGING_KEY;

export const getFCMToken = async () => {
  try {
    const currentToken = await getToken(messaging, { vapidKey: GENERATED_MESSAGING_KEY });
    return currentToken;
  } catch (error) {
    console.log('An error occurred while retrieving token. ', error);
  }
};

export const onNotificationListener = () =>
  new Promise(resolve => {
    onMessage(messaging, payload => {
      // console.log('before-app', payload);
      resolve(payload);
    });
  });
