import {
  Alert,
  AlertDescription,
  AlertIcon,
  Avatar,
  Box,
  Button,
  Collapse,
  Flex,
  IconButton,
  ModalBody,
  ModalCloseButton,
  ModalHeader,
  RadioButtonGroup,
  Stack,
  StackProps,
  Tooltip,
  useToast,
} from '@chakra-ui/core';
import {
  fundResellerSubAccount,
  getResellerSubaccountMembers,
} from 'app/authenticated-app/settings/service';
import { ResellerSubaccount } from 'app/authenticated-app/settings/settings.types';
import {
  BodyText,
  FullPageSpinner,
  Input,
  ModalContainer,
  ModalContainerOptions,
  Radio,
  Search,
  SmallText,
  ToastBox,
} from 'app/components';
import React, { useEffect, useRef, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { NavLink } from 'react-router-dom';

export const FundAccountModal = (
  props: ModalContainerOptions & { subaccounts?: ResellerSubaccount[] },
) => {
  const { onClose, isOpen, subaccounts } = props;

  const toast = useToast();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const searchInputRef = useRef<HTMLInputElement | null>(null);

  const [searchQuery, setSearchQuery] = useState('');
  const [amount, setAmount] = useState<number | undefined>();
  const [account, setAccount] = useState<ResellerSubaccount | undefined>();

  const handleClose = () => {
    onClose?.();
  };

  const { data: subAccountMembers, isLoading: isLoadingSubAccountMembers } = useQuery(
    ['sub-account-members', account?.id],
    () => getResellerSubaccountMembers(account?.id ?? ''),
    {
      enabled: !!account?.id,
    },
  );

  const { isLoading: isFundingAccount, mutateAsync: fundAccountMutate } = useMutation(
    (payload: { amount: number; organisation_id: string }) => {
      const { amount, organisation_id } = payload;
      return fundResellerSubAccount({ amount: amount * 100, organisation_id });
    },
    {
      onSuccess: () => {
        handleClose();
        toast({
          position: 'bottom-left',
          render: ({ onClose }) => (
            <ToastBox onClose={onClose} status="success" message="Pricing updated successfully" />
          ),
        });
      },
      onError: (error: any) => {
        toast({
          position: 'bottom',
          render: ({ onClose }) => <ToastBox onClose={onClose} message={error?.message ?? error} />,
        });
      },
    },
  );

  const handleFundAccount = async () => {
    await fundAccountMutate({ amount: amount ?? 0, organisation_id: account?.id ?? '' });
  };

  const handleClearState = () => {
    setSearchQuery('');
    setAmount(undefined);
    setAccount(undefined);
  };

  useEffect(() => {
    if (searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, []);

  useEffect(() => {
    if (account && inputRef.current) {
      inputRef.current.focus();
    }
  }, [account]);

  return (
    <ModalContainer
      isOpen={isOpen}
      onClose={handleClose}
      initialFocusRef={searchInputRef}
      title={
        <FundAccountModalHeader
          account={account}
          title="Fund subaccount"
          onBack={handleClearState}
        />
      }
    >
      <ModalCloseButton size="sm" />
      <ModalBody flex="unset" height="500px" pb="2rem" overflowY="auto">
        <Collapse isOpen={!account}>
          <Stack>
            <Stack>
              <Box>
                <Search
                  value={searchQuery}
                  inputRef={searchInputRef}
                  placeholder="Search subaccounts"
                  onChange={value => setSearchQuery(value)}
                />
              </Box>
              <BodyText color="gray.500">Select the subaccount to fund</BodyText>
            </Stack>
            <RadioButtonGroup
              flex={1}
              //@ts-ignore
              value={account}
              //@ts-ignore
              onChange={val => setAccount(val)}
            >
              {subaccounts
                ?.filter(account => account.name.toLowerCase().includes(searchQuery.toLowerCase()))
                .map(account => (
                  <SubAccountRadio key={account.id} mb="0.5rem" flex={1} value={account}>
                    <SubAccountItem {...account} />
                  </SubAccountRadio>
                ))}
            </RadioButtonGroup>
          </Stack>
        </Collapse>
        <Collapse isOpen={!!account}>
          <Stack spacing="1rem">
            {!!account && (
              <Box>
                <SubAccountItem p="0.5rem" rounded="8px" borderWidth="1px" {...account} />
              </Box>
            )}
            {isLoadingSubAccountMembers ? (
              <FullPageSpinner height="20vh" />
            ) : !!subAccountMembers?.members?.length ? (
              <>
                <Stack mb="0.5rem">
                  <Input
                    id="amount"
                    name="amount"
                    type="number"
                    label="Amount"
                    value={amount}
                    ref={inputRef}
                    placeholder="Enter amount to fund"
                    isInvalid={!!(amount && amount < 50)}
                    errorMessage="Minimum fund amount is N50"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setAmount(e.target.valueAsNumber)
                    }
                  />
                  <SmallText color="gray.400">
                    The minimum amount you can fund this account with is N50
                  </SmallText>
                </Stack>
                <Button
                  variantColor="blue"
                  onClick={handleFundAccount}
                  isLoading={isFundingAccount}
                  isDisabled={!amount || amount < 50}
                >
                  Fund account
                </Button>
              </>
            ) : (
              <Alert rounded="8px" alignItems="flex-start" status="warning">
                <AlertIcon />
                <AlertDescription fontSize="0.875rem" textAlign="left">
                  Sub-account needs to have at least one member who has accepted the invite, to
                  allow for account funding.
                  <br />
                  <NavLink
                    style={{ color: '#026ae8', textDecoration: 'underline' }}
                    to={`/s/settings/reseller/sub-accounts/${account?.id}/invites`}
                  >
                    Send invite for subaccount
                  </NavLink>
                </AlertDescription>
              </Alert>
            )}
          </Stack>
        </Collapse>
      </ModalBody>
    </ModalContainer>
  );
};

const FundAccountModalHeader = ({
  title,
  onBack,
  account,
}: {
  title: string;
  onBack(): void;
  account?: ResellerSubaccount;
}) => {
  return (
    <Stack isInline alignItems="center">
      {!!account && (
        <Tooltip label="back" aria-label="back">
          <IconButton
            size="sm"
            rounded="50%"
            variant="ghost"
            aria-label="back"
            onClick={onBack}
            icon="chevron-left"
          />
        </Tooltip>
      )}
      <ModalHeader p="0">{title}</ModalHeader>
    </Stack>
  );
};

const SubAccountItem = (props: ResellerSubaccount & StackProps) => {
  const { name, image, id, access, verified_reseller, ...rest } = props;

  return (
    <Stack alignItems="center" isInline {...rest}>
      <Avatar color="white" size="sm" src={image ?? ''} name={name} />
      <BodyText>{name}</BodyText>
    </Stack>
  );
};

const SubAccountRadio = React.forwardRef((props: any, ref) => {
  const { isChecked, isDisabled, value, children, ...rest } = props;
  return (
    <Flex
      ref={ref}
      p="0.5rem"
      rounded="8px"
      cursor="pointer"
      borderWidth="1px"
      alignItems="center"
      justifyContent="space-between"
      {...rest}
    >
      {children}
      <Radio value={value} isDisabled={isDisabled} isChecked={isChecked} />
    </Flex>
  );
});

SubAccountRadio.displayName = 'SubAccountRadio';
