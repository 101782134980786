import {
  Button,
  ButtonProps,
  ModalBody,
  ModalCloseButton,
  Stack,
  useDisclosure,
  useToast,
} from '@chakra-ui/core';
import { sendResellerSubaccountInvite } from 'app/authenticated-app/settings/service';
import { Input, ModalContainer, ToastBox } from 'app/components';
import React, { useEffect, useRef, useState } from 'react';
import { useQueryClient } from 'react-query';
import { validateEmail } from 'utils/functions';

export const InviteModal = (props: { sub_account_id: string; buttonProps: ButtonProps }) => {
  const { sub_account_id, buttonProps } = props;

  const [inviteQuery, setInviteQuery] = useState<string>('');
  const [isInvitingMember, setIsInvitingmember] = useState(false);

  const toast = useToast();
  const queryClient = useQueryClient();
  const initialFocusRef = useRef<HTMLInputElement>(null);
  const { isOpen: isModalOpen, onOpen: onOpenModal, onClose: onCloseModal } = useDisclosure();

  const BASE_URL =
    process.env.NODE_ENV === 'development'
      ? 'http://localhost:3000/'
      : process.env.REACT_APP_DEMO_URL;

  const handleCloseModal = () => {
    setInviteQuery('');
    onCloseModal();
  };

  const sendTheOrganisationInvite = async () => {
    try {
      setIsInvitingmember(true);
      await sendResellerSubaccountInvite(sub_account_id, {
        email: inviteQuery,
        link: `${BASE_URL}organisations/invite/accept/{{email}}/{{token}}/{{organisationID}}/{{organisationName}}`,
      });
      setIsInvitingmember(false);
      setInviteQuery('');
      queryClient.invalidateQueries(['sub-account-invites', sub_account_id]);
      handleCloseModal();
      toast({
        position: 'bottom-left',
        render: ({ onClose }) => (
          <ToastBox onClose={onClose} status="success" message={'Invite Sent'} />
        ),
      });
    } catch (error: any) {
      setIsInvitingmember(false);
      toast({
        position: 'bottom-left',
        render: ({ onClose }) => <ToastBox onClose={onClose} message={error.message} />,
      });
    }
  };

  useEffect(() => {
    if (initialFocusRef.current) {
      initialFocusRef.current.focus();
    }
  }, []);

  return (
    <>
      <Button onClick={onOpenModal} {...buttonProps} />
      {isModalOpen && (
        <ModalContainer
          isOpen={isModalOpen}
          title="Invite people"
          onClose={handleCloseModal}
          initialFocusRef={initialFocusRef}
        >
          <ModalCloseButton size="sm" />
          <ModalBody pb="2rem">
            <Stack spacing="1rem">
              <Input
                size="sm"
                label="Email"
                value={inviteQuery}
                ref={initialFocusRef}
                placeholder="Invite by email"
                onChange={(e: any) => setInviteQuery(e.target.value)}
              />
              <Button
                size="sm"
                width="100px"
                variantColor="blue"
                isLoading={isInvitingMember}
                onClick={sendTheOrganisationInvite}
                isDisabled={!validateEmail(inviteQuery)}
              >
                Send Invite
              </Button>
            </Stack>
          </ModalBody>
        </ModalContainer>
      )}
    </>
  );
};
