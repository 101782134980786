import * as React from 'react';
import { ToastBox } from '../app/components';
import { useToast } from '@chakra-ui/core';

interface Options {
  preset?: string;
  sources?: string[];
  allowCrop?: boolean;
  handleUpload: Function;
  showSkipCropButton?: boolean;
  croppingAspectRatio?: number;
  clientAllowedFormats?: string[];
  croppingDefaultSelectionRatio?: number;
  croppingCoordinatesMode?: 'face' | 'custom';
}

const widgetStyles = {
  palette: {
    window: '#FFF',
    windowBorder: '#90A0B3',
    tabIcon: '#0E2F5A',
    menuIcons: '#5A616A',
    textDark: '#000000',
    textLight: '#FFFFFF',
    link: '#026ae8',
    action: '#FF620C',
    inactiveTabIcon: '#0E2F5A',
    error: '#F44235',
    inProgress: '#026ae8',
    complete: '#20B832',
    sourceBg: '#E4EBF1',
  },
  fonts: {
    'Lato, sans-serif': 'https://fonts.googleapis.com/css?family=Lato',
  },
};

export const useCloudinary = (options: Options) => {
  const [widget, setWidget] = React.useState(null);

  const toast = useToast();

  const widgetConfig = {
    multiple: false,
    showPoweredBy: false,
    styles: widgetStyles,
    cloudName: 'simpu-inc',
    singleUploadAutoClose: true,
    cropping: options.allowCrop,
    uploadPreset: options.preset,
    croppingShowDimensions: true,
    showSkipCropButton: options.showSkipCropButton,
    croppingAspectRatio: options.croppingAspectRatio,
    clientAllowedFormats: options.clientAllowedFormats,
    croppingCoordinatesMode: options.croppingCoordinatesMode,
    croppingDefaultSelectionRatio: options.croppingDefaultSelectionRatio,
    sources: options.sources || ['local', 'camera', 'dropbox', 'google_drive', 'instagram'],
  };

  React.useEffect(() => {
    // @ts-ignore
    setWidget(window?.cloudinary?.createUploadWidget(widgetConfig, handleWidget));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openWidget = () => {
    // @ts-ignore
    widget.open();
  };

  const handleWidget = (error: any, result: any) => {
    if (result && result?.event !== 'success') return;
    if (error) {
      return toast({
        position: 'bottom-left',
        render: ({ onClose }) => (
          <ToastBox
            status="error"
            message="Unable to complete upload, please try again"
            onClose={onClose}
          />
        ),
      });
    }
    options.handleUpload(result.info);
  };

  return {
    openWidget,
    options,
    // @ts-ignore
    isOpen: widget?.showing,
  };
};
