import {
  Avatar,
  Box,
  Heading,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  Stack,
  useDisclosure,
  useToast,
} from '@chakra-ui/core';
import {
  createResellerSubaccount,
  getResellerSubaccounts,
  updateResellerSubaccount,
} from 'app/authenticated-app/settings/service';
import { ResellerSubaccount } from 'app/authenticated-app/settings/settings.types';
import {
  Button,
  Container,
  EmptyState,
  FullPageSpinner,
  Input,
  Table,
  ToastBox,
} from 'app/components';
import { AxiosError } from 'axios';
import { FormikHelpers } from 'formik';
import capitalize from 'lodash/capitalize';
import React, { SyntheticEvent, useMemo, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useHistory, useRouteMatch } from 'react-router-dom';
import noPeople from '../../../assets/no-people.svg';
import { OptionItem } from '../../people/table-columns';
import { FundAccountModal } from './fund-account-modal';
import { ResellerSubaccountModal } from './subaccount-modal';

export const ResellerSubaccounts = () => {
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [subaccountToEdit, setSubaccountToEdit] = useState<ResellerSubaccount | undefined>();

  const toast = useToast();
  const history = useHistory();
  const match = useRouteMatch();
  const queryClient = useQueryClient();

  const {
    isOpen: isCreateSubaccountModalOpen,
    onClose: onCloseCreateSubaccountModal,
    onOpen: onOpenCreateSubaccountModal,
  } = useDisclosure();
  const {
    isOpen: isFundAccountModalOpen,
    onOpen: onOpenFundAccountModal,
    onClose: onCloseFundAccountModal,
  } = useDisclosure();

  const { data: subaccounts, isLoading: isLoadingSubaccounts } = useQuery<ResellerSubaccount[]>(
    ['sub-accounts'],
    getResellerSubaccounts,
  );

  const { isLoading: isCreatingResellerSubaccount, mutateAsync: createResellerSubaccountMutate } =
    useMutation<any, AxiosError, any, any>(payload => createResellerSubaccount(payload), {
      onSuccess: () => {
        queryClient.invalidateQueries(['sub-accounts']);
        toast({
          position: 'bottom-left',
          render: ({ onClose }) => (
            <ToastBox
              status="success"
              message="Sub account created successfully"
              onClose={onClose}
            />
          ),
        });
      },
      onError: error => {
        toast({
          position: 'bottom-left',
          render: ({ onClose }) => (
            <ToastBox status="success" onClose={onClose} message={error.message} />
          ),
        });
      },
    });

  const { isLoading: isUpdatingResellerSubaccount, mutateAsync: updateResellerSubaccountMutate } =
    useMutation<any, AxiosError, any, any>(payload => updateResellerSubaccount(payload), {
      onSuccess: () => {
        queryClient.invalidateQueries(['sub-accounts']);
        toast({
          position: 'bottom-left',
          render: ({ onClose }) => (
            <ToastBox
              status="success"
              onClose={onClose}
              message="Sub account updated successfully"
            />
          ),
        });
      },
      onError: error => {
        toast({
          position: 'bottom-left',
          render: ({ onClose }) => (
            <ToastBox status="success" onClose={onClose} message={error.message} />
          ),
        });
      },
    });

  const handleCreateSubaccount = async (
    values: { name: string },
    formikHelpers: FormikHelpers<{ name: string }>,
  ) => {
    await createResellerSubaccountMutate(values);
    formikHelpers.resetForm();
    onCloseCreateSubaccountModal();
  };

  const handleUpdateSubaccount = async (values: { name: string; id?: string }) => {
    await updateResellerSubaccountMutate(values);
    setSubaccountToEdit(undefined);
  };

  const handleTableRowClick = (data: ResellerSubaccount) => {
    history.push(`${match.url}/${data.id}/members`);
  };

  const rows = subaccounts?.filter((i: any) =>
    JSON.stringify(i).toLowerCase().includes(searchQuery.toLowerCase()),
  );

  const TableColumns = ({ onUpdateSubaccount }: any) => [
    {
      Header: 'Name',
      width: 225,
      accessor: '',
      Cell: ({ row: { original } }: any) => {
        const name = capitalize(original.name);
        return (
          <Box display="flex" height="100%" alignItems="center">
            <Avatar
              size="sm"
              name={name}
              color="white"
              marginRight="15px"
              src={original.image ?? ''}
            />
            <Box>{name}</Box>
          </Box>
        );
      },
    },
    {
      Header: '',
      width: 100,
      accessor: 'id',
      Cell: ({ row: { original } }: any) => {
        return (
          <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center">
            <Menu>
              <MenuButton
                as={IconButton}
                // @ts-ignore
                icon="overflow"
                size="sm"
                padding=".5rem"
                variant="ghost"
                height="auto"
                minWidth="auto"
                onClick={(event: SyntheticEvent) => event.stopPropagation()}
              />

              <MenuList minWidth="150px" placement="bottom">
                <OptionItem
                  icon="edit"
                  label="Update subaccount"
                  onClick={(e: SyntheticEvent) => {
                    e.stopPropagation();
                    onUpdateSubaccount(original);
                  }}
                />
              </MenuList>
            </Menu>
          </Box>
        );
      },
    },
  ];

  const columns = useMemo(
    () =>
      TableColumns({
        onUpdateSubaccount: setSubaccountToEdit,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <Container>
      <Stack mb="1.5rem" isInline alignItems="center">
        <Heading fontWeight="semibold" size="md">
          Sub Accounts
        </Heading>
        {!!subaccounts?.length && (
          <Box fontWeight="400" color="#757575" fontSize="14px">
            - {subaccounts?.length} user(s)
          </Box>
        )}
      </Stack>
      {isLoadingSubaccounts ? (
        <FullPageSpinner height="50vh" />
      ) : (
        <>
          <Stack isInline alignItems="center" justifyContent="space-between">
            <Input
              size="sm"
              autoFocus
              value={searchQuery}
              containerWidth="50%"
              placeholder="Search by name"
              onChange={(e: any) => setSearchQuery(e.target.value)}
            />

            <Stack isInline alignItems="center">
              <Button size="sm" variantColor="blue" onClick={onOpenCreateSubaccountModal}>
                Create Subaccount
              </Button>
              <Button
                size="sm"
                variant="outline"
                variantColor="blue"
                onClick={onOpenFundAccountModal}
              >
                Fund subaccount
              </Button>
            </Stack>
          </Stack>
          <Box marginTop="25px">
            {!!rows?.length && (
              <Table
                // @ts-ignore
                columns={columns}
                onRowClick={handleTableRowClick}
                data={rows ?? ([] as ResellerSubaccount[])}
              />
            )}
            {!rows?.length && (
              <EmptyState
                marginY="10vh"
                image={noPeople}
                subheading="No subaccounts found"
                subheadingProps={{ marginTop: '25px', fontWeight: '500' }}
              />
            )}
          </Box>
          {isCreateSubaccountModalOpen && (
            <ResellerSubaccountModal
              onSubmit={handleCreateSubaccount}
              isOpen={isCreateSubaccountModalOpen}
              onClose={onCloseCreateSubaccountModal}
              isLoading={isCreatingResellerSubaccount}
            />
          )}
          {!!subaccountToEdit && (
            <ResellerSubaccountModal
              isOpen={!!subaccountToEdit}
              initialValues={subaccountToEdit}
              onSubmit={handleUpdateSubaccount}
              isLoading={isUpdatingResellerSubaccount}
              onClose={() => setSubaccountToEdit(undefined)}
            />
          )}
          {isFundAccountModalOpen && (
            <FundAccountModal
              subaccounts={subaccounts}
              isOpen={isFundAccountModalOpen}
              onClose={onCloseFundAccountModal}
            />
          )}
        </>
      )}
    </Container>
  );
};
