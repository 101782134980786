import { Badge, Box, Flex, Icon, PseudoBox, Skeleton, Stack, useDisclosure } from '@chakra-ui/core';
import { BodyText, TableDropdown } from 'app/components';
import React from 'react';
import { EmailSenderProfilePayload } from './email-sender-profile-modal';
import { SenderProfileItemProps } from './sms-sender-profile-item';

export const EmailSenderProfileItem = (
  props: SenderProfileItemProps<EmailSenderProfilePayload>,
) => {
  const { data, onClick, actions } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <PseudoBox cursor="pointer" _hover={{ bg: '#f9f9f9' }} onClick={onClick}>
      <Stack
        isInline
        py="1rem"
        spacing="1rem"
        flexWrap="wrap"
        alignItems="center"
        borderTopWidth="1px"
      >
        <Stack
          isInline
          pl="1rem"
          spacing="0.4rem"
          alignItems="center"
          marginBottom="1rem"
          width={'calc(40% - 1rem)'}
        >
          <BodyText textTransform="capitalize">{data.from_name}</BodyText>
          {data.is_default && (
            <Badge fontSize="0.5rem" variant="outline">
              Default
            </Badge>
          )}
        </Stack>
        <Flex marginBottom="1rem" width={'calc(40% - 1rem)'}>
          <BodyText>{data.from_email}</BodyText>
        </Flex>
        <Flex marginBottom="1rem" width={'calc(20% - 1rem)'}>
          <TableDropdown<EmailSenderProfilePayload>
            data={data}
            open={onOpen}
            close={onClose}
            isOpen={isOpen}
            actions={actions}
          >
            <Icon name="chevron-down" size="1rem" />
          </TableDropdown>
        </Flex>
      </Stack>
    </PseudoBox>
  );
};

export function EmailSenderProfileListHeader() {
  return (
    <Stack isInline spacing="1rem" flexWrap="wrap" alignItems="center">
      <Stack pl="1rem" spacing="0.2rem" marginBottom="1rem" width={'calc(40% - 1rem)'}>
        <BodyText fontWeight="bold">Sender name</BodyText>
      </Stack>
      <Flex marginBottom="1rem" width={'calc(40% - 1rem)'}>
        <BodyText fontWeight="bold">Email Address</BodyText>
      </Flex>
      <Flex marginBottom="1rem" width={'calc(20% - 1rem)'} />
    </Stack>
  );
}

export function EmailSenderProfileLoadingItem() {
  return (
    <Stack isInline px="1rem" pt="1rem" borderTopWidth="1px">
      <Box width={'calc(40% - 1rem)'}>
        <Skeleton height="10px" width="100%" />
      </Box>
      <Flex width={'calc(40% - 1rem)'}>
        <Skeleton height="10px" width="80px" />
      </Flex>
    </Stack>
  );
}
