import { Button, Flex, Stack } from '@chakra-ui/core';
import { BodyText, SmallText, ToastBox } from 'app/components';
import React from 'react';
import { NavLink } from 'react-router-dom';

export const ClicksDownloadCard: React.FC<{
  title: string;
  onDownload(): void;
  description: string;
  isExportSuccessToastOpen?: boolean;
  onCloseExportSuccessToast?(): void;
}> = ({ title, onDownload, isExportSuccessToastOpen, onCloseExportSuccessToast }) => {
  return (
    <Flex
      p="1rem"
      mt="1rem"
      bg="gray.50"
      rounded="8px"
      flexDirection="column"
      justifyContent="center"
    >
      {isExportSuccessToastOpen && (
        <ToastBox
          mx={0}
          m="0 0 0.75rem 0"
          status="info"
          onClose={onCloseExportSuccessToast}
          message={
            <SmallText>
              Export has started. Go to{' '}
              <NavLink style={{ color: '#026ae8' }} to="/s/reports/data-exports">
                Data exports
              </NavLink>{' '}
              and see if your file is ready to download.
            </SmallText>
          }
        />
      )}
      <Stack width="100%">
        <Stack spacing="0.2rem" flex={1}>
          <BodyText color="gray.900" fontWeight="500">
            {title}
          </BodyText>
          <BodyText color="gray.500">
            The campaign had some engagement (link clicks), download the list of recipients that
            engaged with the campaign and re-target them with another campaign.
          </BodyText>
        </Stack>
        <Button size="sm" variantColor="blue" onClick={onDownload}>
          Download list
        </Button>
      </Stack>
    </Flex>
  );
};
