import React from 'react';
import styled from '@emotion/styled';
import Highlight, { defaultProps, Language, PrismTheme } from 'prism-react-renderer';
import { Box, useClipboard } from '@chakra-ui/core';
import { Button } from '../Button';

const supportedSyntax: { [key: string]: Language } = {
  curl: 'bash',
  jsx: 'jsx',
  js: 'javascript',
  node: 'javascript',
};

export const CodeSnippet = (props: {
  code: string;
  syntax: string;
  theme?: PrismTheme;
  copyButtonTop?: string;
}) => {
  const { code, theme, syntax, copyButtonTop = '20px' } = props;
  const { hasCopied, onCopy } = useClipboard(code);
  return (
    <Box position="relative">
      <Button
        size="xs"
        zIndex={2}
        right="16px"
        onClick={onCopy}
        position="absolute"
        variantColor="blue"
        top={copyButtonTop}
        aria-label="copy-code-snippet"
        _focus={{ boxShadow: 'none' }}
        leftIcon={hasCopied ? 'check-circle' : 'copy'}
      >
        Copy
      </Button>
      <Highlight {...defaultProps} theme={theme} code={code} language={supportedSyntax[syntax]}>
        {({ className, style, tokens, getLineProps, getTokenProps }) => (
          <Pre className={className} style={style}>
            {tokens.map((line, i) => (
              <Line key={i} {...getLineProps({ line, key: i })}>
                <LineNo>{i + 1}</LineNo>
                <LineContent>
                  {line.map((token, key) => (
                    <span key={key} {...getTokenProps({ token, key })} />
                  ))}
                </LineContent>
              </Line>
            ))}
          </Pre>
        )}
      </Highlight>
    </Box>
  );
};

export const Pre = styled.pre`
  text-align: left;
  margin: 1em 0;
  padding: 0.5em;
  overflow: scroll;
  font-family: 'JetBrainsMono';

  & .token-line {
    line-height: 1.3em;
    height: 1.3em;
  }
`;

export const Line = styled.div`
  display: table-row;
  font-family: 'JetBrainsMono';
`;

export const LineNo = styled.span`
  display: table-cell;
  text-align: right;
  padding-right: 1em;
  user-select: none;
  opacity: 0.5;
  font-size: 12px;
  font-family: 'JetBrainsMono';
`;

export const LineContent = styled.span`
  font-size: 14px;
  display: table-cell;
  font-family: 'JetBrainsMono';
`;
