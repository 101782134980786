import { $generateHtmlFromNodes, $generateNodesFromDOM } from '@lexical/html';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { $insertNodes, CLEAR_EDITOR_COMMAND } from 'lexical';
import React, { useEffect, useState } from 'react';

export const HtmlPlugin = ({
  onChange,
  initialValue,
}: {
  initialValue?: string;
  onChange(value: string): void;
}) => {
  const [editor] = useLexicalComposerContext();

  const [isFirstRender, setIsFirstRender] = useState(true);

  useEffect(() => {
    editor.dispatchCommand(CLEAR_EDITOR_COMMAND, undefined);
    if (!initialValue || !isFirstRender) return;

    setIsFirstRender(false);

    editor.update(() => {
      const parser = new DOMParser();
      const dom = parser.parseFromString(initialValue, 'text/html');
      const nodes = $generateNodesFromDOM(editor, dom);
      $insertNodes(nodes);
    });
  }, [editor, initialValue, isFirstRender]);

  return (
    <OnChangePlugin
      onChange={editorState => {
        editorState.read(() => {
          onChange($generateHtmlFromNodes(editor));
        });
      }}
    />
  );
};
