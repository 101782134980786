import { Box, Flex } from '@chakra-ui/core';
import React, { useContext, useEffect, useRef, useState } from 'react';
import JSONInput from 'react-json-editor-ajrm';
//@ts-ignore
import locale from 'react-json-editor-ajrm/locale/en';
import {
  RequestParamsContext,
  RequestArrayProps,
} from '../../../contexts/request-contexts/params.context';

interface Prop {
  n: RequestArrayProps;
  requestArray: RequestArrayProps[];
}

const RequestBody = (props: Prop) => {
  const [focus, setFocus] = useState(false);
  const { requestBody } = useContext(RequestParamsContext);
  const focusRef = useRef<any>();

  function useOutsideAlerter(ref: any) {
    useEffect(() => {
      function handleClickOutside(e: any) {
        if (ref.current && !ref.current.contains(e.target)) {
          setFocus(false);
        } else if (ref.current && ref.current.contains(e.target)) setFocus(true);
      }
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  }
  const changeHandler = (e: any) => {
    setFocus(true);
    requestBody[props.n?.id] = e.jsObject;
  };
  useOutsideAlerter(focusRef);

  return (
    <>
      <Box marginBottom="0.5rem" marginTop="0.2rem" className="custom-app--request-body-title">
        Please enter or paste your request body here.
      </Box>
      <Flex>
        <Box
          w="50.7rem"
          h="100%"
          ref={focusRef}
          className="custom-app--request-container"
          border={focus ? '1px solid #026ae8BB' : '1px solid #A5ABB3'}
        >
          <JSONInput
            theme="light_mitsuketa_tribute"
            placeholder={requestBody[props.n?.id]}
            locale={locale}
            width="100%"
            height="400px"
            confirmGood={false}
            style={{
              body: {
                fontSize: '12px',
              },
            }}
            colors={{
              keys: '#AB1010',
              default: '#6D7580',
              number: '#36B37E',
              string: '#026ae8CC',
              primitive: '#211791',
            }}
            onChange={(e: any) => changeHandler(e)}
          />
        </Box>
      </Flex>
    </>
  );
};

export default RequestBody;
