import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'root';
import { listLogs, singleLog, resendSMS } from './log.service';

const initialState = {
  sms_reports: [] as any,
  sms_report: {} as any,
  meta: {} as any,
  loading: 'idle' as string,
  loadMore: 'idle' as string,
  loadSingleLog: 'idle' as string,
};

export const fetchSmsLogs = createAsyncThunk('logs/fetchLogs', async (params: any) => {
  const response = await listLogs(params);
  return response;
});

export const fetchMoreLogs = createAsyncThunk('logs/fetchMoreLogs', async ({ ...params }: any) => {
  const response = await listLogs(params);
  return response;
});

export const fetchSingleLog = createAsyncThunk('logs/updateLog', async (id: string) => {
  const response = await singleLog(id);
  return response;
});

export const sendSMS = createAsyncThunk('log/resendSMS', async (data: any) => {
  const response = await resendSMS(data);
  return response;
});

const smsLogsSlice = createSlice({
  name: 'sms_logs',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchSmsLogs.pending, (state, action) => {
        state.loading = 'pending';
      })
      .addCase(fetchSmsLogs.fulfilled, (state, action) => {
        state.loading = 'complete';
        const { data, meta } = action.payload;
        state.sms_reports = data;
        state.meta = meta;
      })
      .addCase(fetchMoreLogs.pending, (state, action) => {
        state.loadMore = 'pending';
      })
      .addCase(fetchMoreLogs.fulfilled, (state, action) => {
        state.loadMore = 'complete';
        const { data, meta } = action.payload;
        state.sms_reports = [...state.sms_reports, ...data];
        state.meta = meta;
      })
      .addCase(fetchSingleLog.pending, (state, action) => {
        state.loadSingleLog = 'pending';
      })
      .addCase(fetchSingleLog.fulfilled, (state, action) => {
        state.loadSingleLog = 'complete';
        const { data } = action.payload;
        state.sms_report = data;
      });
  },
});

export const smsLogsReducer = smsLogsSlice.reducer;
export const selectFromSmsLogs = (state: RootState) => state.smsLogs;
