import { IconButton } from '@chakra-ui/core';
import { toggleAutomationPanel } from 'app/authenticated-app/lists/lists.reducer';
import { selectAutomationPanelActiveSection } from 'app/authenticated-app/lists/lists.selectors';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ListSidePanelWrapper as Wrapper } from './index.styles';
import { AirtimeTopup, AutomationScreen, EnrichmentScreen, LandingScreen } from './screens';
import { DuplicateCheck } from './screens/DuplicateCheck';

interface Props {
  isOpen: boolean;
  updateEnrichmentSettings: Function;
  queueEnrichment: Function;
  createAutomation: Function;
  updateAutomation: Function;
  fetchAutomationActions: Function;
  addAutomationAction: Function;
  updateAutomationAction: Function;
  deleteAutomation: Function;
  deleteAutomationAction: Function;
  is_smart_list?: boolean;
}

export const ListSidePanel = (props: Props) => {
  const dispatch = useDispatch();
  const automationPanelActiveSection = useSelector(selectAutomationPanelActiveSection);

  const [activeSection, setActiveSection] = useState<
    'enrichment' | 'automation' | 'airtime-topup' | 'duplicate-check' | null
  >(automationPanelActiveSection ?? null);
  const [selectedAutomation, setSelectedAutomation] = useState<string | null>(null);

  const selectAutomation = (automationID: string) => {
    props.fetchAutomationActions(automationID);
    setSelectedAutomation(automationID);
    setActiveSection('automation');
  };

  const handleCloseSidePanel = () => {
    dispatch(toggleAutomationPanel({ automationPanelOpen: false }));
  };

  return (
    <Wrapper
      height="100%"
      display="flex"
      overflowY="auto"
      alignItems="center"
      position="relative"
      flexDirection="column"
    >
      <IconButton
        size="xs"
        icon="close"
        top="0.05rem"
        right="0.5rem"
        variant="ghost"
        position="absolute"
        aria-label="Close side panel"
        onClick={handleCloseSidePanel}
      />
      {!activeSection && (
        <LandingScreen
          setActiveSection={setActiveSection}
          createAutomation={props.createAutomation}
          selectAutomation={selectAutomation}
          deleteAutomation={props.deleteAutomation}
        />
      )}
      {activeSection === 'enrichment' && (
        <EnrichmentScreen
          setActiveSection={setActiveSection}
          updateEnrichmentSettings={props.updateEnrichmentSettings}
          queueEnrichment={props.queueEnrichment}
        />
      )}
      {activeSection === 'automation' && selectedAutomation && (
        <AutomationScreen
          setActiveSection={setActiveSection}
          selectedAutomation={selectedAutomation}
          updateAutomation={props.updateAutomation}
          addAutomationAction={props.addAutomationAction}
          updateAutomationAction={props.updateAutomationAction}
          deleteAutomation={props.deleteAutomation}
          deleteAutomationAction={props.deleteAutomationAction}
        />
      )}
      {activeSection === 'airtime-topup' && (
        <AirtimeTopup is_smart_list={props.is_smart_list} setActiveSection={setActiveSection} />
      )}
      {activeSection === 'duplicate-check' && (
        <DuplicateCheck setActiveSection={setActiveSection} />
      )}
    </Wrapper>
  );
};
