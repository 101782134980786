import { Box, Flex, SimpleGrid, Skeleton, Stack } from '@chakra-ui/core';
import { ListSearch } from 'app/authenticated-app/lists/components/search';
import { AnimatedTab, AnimatedTabList, Button, EmptyState } from 'app/components';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'root';
import usePermission from 'utils/usePermission';
import { TemplateData } from '../templates.types';
import { TemplateListItem } from './template-list-item';

type Props = {
  isLoading?: boolean;
  tableActions: any[];
  selectedTab: string;
  searchQuery?: string;
  categories: string[];
  lists_by_id?: string[];
  selectedCategory: string;
  templates: TemplateData[];
  onSearch(query: string): void;
  sampleTemplates: TemplateData[];
  onTabChange: (tab: string) => void;
  onOpenCreateTemplateModal: () => void;
  handleCategoryClick(category: string): void;
  stateOptions: { label: string; value: string }[];
  onTemplateItemClick: (data: TemplateData) => void;
};

export const TemplateContent = (props: Props) => {
  const {
    onSearch,
    isLoading,
    templates,
    categories,
    selectedTab,
    onTabChange,
    lists_by_id,
    searchQuery,
    tableActions,
    stateOptions,
    sampleTemplates,
    selectedCategory,
    handleCategoryClick,
    onTemplateItemClick,
    onOpenCreateTemplateModal,
  } = props;
  const { profile } = useSelector((state: RootState) => state.auth);

  const permissions = profile && profile.permissions;

  const requiresPermission = usePermission('template.*', permissions || []);

  return (
    <div>
      <Box className="section-title">
        <Flex w="100%" alignItems="center" justifyContent="space-between">
          <Box>
            <AnimatedTabList height="40px" w="fit-content">
              {stateOptions.map(item => (
                <AnimatedTab
                  px="4"
                  width="fit-content"
                  fontSize="0.875rem"
                  indicatorHeight="32px"
                  isActive={selectedTab === item.value}
                  onClick={() => onTabChange(item.value)}
                >
                  {item.label}
                </AnimatedTab>
              ))}
            </AnimatedTabList>
          </Box>
          <Stack isInline alignItems="center">
            {selectedTab === 'my-templates' && (
              <ListSearch
                height="36px"
                focusWidth="50%"
                search_query={searchQuery ?? ''}
                updateSearchQuery={onSearch}
              />
            )}
            {requiresPermission && (
              <Button size="sm" variantColor="blue" onClick={onOpenCreateTemplateModal}>
                Create template
              </Button>
            )}
          </Stack>
        </Flex>
      </Box>
      {selectedTab === 'my-templates' ? (
        isLoading ? (
          <Stack>
            {Array.from({ length: 15 }, (v, i) => (
              <Flex
                px="1rem"
                pt="1rem"
                alignItems="center"
                borderTopWidth="1px"
                justifyContent="space-between"
                key={`${i.toString()}-${new Date().getTime()}`}
              >
                <Box width={['calc(33.33% - 1rem)', 'calc(50% - 1rem)', 'calc(33.33% - 1rem)']}>
                  <Skeleton height="10px" width="80%" my="10px" />
                  <Skeleton height="10px" width="50%" my="10px" />
                  <Skeleton height="10px" width="25%" my="10px" />
                </Box>
                <Flex
                  justifyContent="flex-end"
                  width={['calc(33.33% - 1rem)', 'calc(50% - 1rem)', 'calc(33.33% - 1rem)']}
                >
                  <Skeleton height="10px" width="60px" my="10px" />
                </Flex>
              </Flex>
            ))}
          </Stack>
        ) : (
          <Box>
            {!templates.length ? (
              <EmptyState
                py="100px"
                imageSize="120px"
                heading="No saved templates"
                image="/images/templates-empty.svg"
                contentContainerProps={{ mt: '1rem' }}
                subheading="A template is a saved message that can be reused"
              />
            ) : (
              <SimpleGrid columns={[1, 2, 3]} spacingX="6" spacingY="10">
                {templates.map(template => (
                  <TemplateListItem
                    key={template.id}
                    template={template}
                    tableActions={tableActions}
                    onClick={onTemplateItemClick}
                    hasActiveTable={
                      !template.table_id ||
                      (!!template.table_id && !!lists_by_id?.includes(template.table_id))
                    }
                  />
                ))}
              </SimpleGrid>
            )}
          </Box>
        )
      ) : (
        <Box>
          {!sampleTemplates.length ? (
            <EmptyState
              py="100px"
              imageSize="120px"
              heading="No sample templates"
              image="/images/templates-empty.svg"
              contentContainerProps={{ mt: '1rem' }}
              subheading="A template is a saved message that can be reused"
            />
          ) : (
            <>
              <Stack isInline alignItems="center" overflowY="auto" pb="15px">
                {categories?.map((category, index) => (
                  <Button
                    size="sm"
                    minW="unset"
                    fontWeight={500}
                    fontSize="0.75rem"
                    key={index.toString()}
                    textTransform="capitalize"
                    _focus={{ boxShadow: 'none' }}
                    _active={{ boxShadow: 'none' }}
                    onClick={() => handleCategoryClick(category)}
                    color={selectedCategory === category ? 'white' : 'gray.900'}
                    variantColor={selectedCategory === category ? 'blue' : 'gray'}
                  >
                    {category}
                  </Button>
                ))}
              </Stack>
              <SimpleGrid columns={[1, 2, 3]} spacingX="6" spacingY="10">
                {sampleTemplates.map(template => (
                  <TemplateListItem
                    key={template.id}
                    template={template}
                    showActionBtns={false}
                    tableActions={tableActions}
                  />
                ))}
              </SimpleGrid>
            </>
          )}
        </Box>
      )}
    </div>
  );
};
