import { buildNotificationURL, buildPaymentURL, client } from 'utils';
import { CompanyProfilePayload } from '../component/company-profile';
import { EmailSenderProfilePayload } from '../component/sender-profiles/email-sender-profile-modal';
import { SMSSenderProfilePayload } from '../component/sender-profiles/sms-sender-profile-modal';
import { NotificationSettingsPayload, ResellerSettingsType } from '../settings.types';

export const getCompanyProfile = async () => {
  const { data } = await client('organisations/profile');
  return data.organisation_profile;
};

export const getOrganisation = async () => {
  const { data } = await client('organisations/view');
  return data.organisation;
};

export const saveCompanyProfile = async (payload: CompanyProfilePayload) => {
  const { data } = await client('organisations/profile/save', {
    data: payload,
    method: 'POST',
  });
  return data.organisation_profile;
};

export const updateCompanyProfile = async (payload: Partial<CompanyProfilePayload>) => {
  const { data } = await client('organisations/profile/save', {
    data: payload,
    method: 'PATCH',
  });
  return data.organisation_profile;
};

export const listSMSSenderProfiles = async () => {
  const { data } = await client('sender-id/sms');
  return data.sender_ids;
};

export const saveSMSSenderProfile = async (payload: Pick<SMSSenderProfilePayload, 'name'>) => {
  const { data } = await client('sender-id/sms/save', {
    data: payload,
    method: 'POST',
  });
  return data.sender_id;
};

export const updateSMSSenderProfile = async (payload: Partial<SMSSenderProfilePayload>) => {
  const { data } = await client('sender-id/sms/save', {
    data: payload,
    method: 'PATCH',
  });
  return data.sender_id;
};

export const smsSenderProfileAsDefault = async (payload: Pick<EmailSenderProfilePayload, 'id'>) => {
  const { data } = await client('sender-id/sms/save-default', {
    data: payload,
    method: 'PATCH',
  });
  return data;
};

export const deleteSMSSenderProfile = async (payload: Pick<SMSSenderProfilePayload, 'id'>) => {
  const { data } = await client('sender-id/sms/save', {
    data: payload,
    method: 'DELETE',
  });
  return data;
};

export const listEmailSenderProfiles = async () => {
  const { data } = await client('sender-id/emails');
  return data.sender_ids;
};

export const saveEmailSenderProfile = async (payload: EmailSenderProfilePayload) => {
  const { data } = await client('sender-id/email/save', {
    data: payload,
    method: 'POST',
  });
  return data.sender_id;
};

export const updateEmailSenderProfile = async (payload: Partial<EmailSenderProfilePayload>) => {
  const { data } = await client('sender-id/email/save', {
    data: payload,
    method: 'PATCH',
  });
  return data.sender_id;
};

export const emailSenderProfileAsDefault = async (
  payload: Pick<EmailSenderProfilePayload, 'id'>,
) => {
  const { data } = await client('sender-id/email/save-default', {
    data: payload,
    method: 'PATCH',
  });
  return data;
};

export const deleteEmailSenderProfile = async (payload: Pick<EmailSenderProfilePayload, 'id'>) => {
  const { data } = await client('sender-id/email/save', {
    data: payload,
    method: 'DELETE',
  });
  return data;
};

export const getNotificationSettings = async () => {
  const { data } = await client('', {
    url: buildNotificationURL(`/settings`),
  });
  return data;
};

export const updateNotificationSettings = async (
  payload: Omit<NotificationSettingsPayload, 'id' | 'profile_id'>,
) => {
  const { data } = await client('', {
    method: 'PATCH',
    data: payload,
    url: buildNotificationURL(`/settings`),
  });
  return data;
};

export const updateCampaignCreditThreshold = async (payload: any) => {
  const { data } = await client('', {
    method: 'PATCH',
    data: payload,
    url: buildPaymentURL(`/wallet`),
  });
  return data;
};

export const getResellerSubaccounts = async () => {
  const { data } = await client('reseller/sub-accounts');
  return data.sub_accounts;
};

export const createResellerSubaccount = async (payload: { name: string }) => {
  const { data } = await client('reseller/sub-account/create', {
    method: 'POST',
    data: payload,
  });
  return data.sub_account;
};

export const updateResellerSubaccount = async (payload: { id: string; name: string }) => {
  const { data } = await client('reseller/sub-account/update', {
    method: 'PATCH',
    data: payload,
  });
  return data.sub_account;
};

export const getResellerSubaccountInvites = async (sub_account_id: string) => {
  const { data } = await client(`reseller/sub-account/${sub_account_id}/invites`);
  return data.invites;
};

export const sendResellerSubaccountInvite = async (
  sub_account_id: string,
  payload: { email: string; link: string },
) => {
  const { data } = await client(`reseller/sub-account/${sub_account_id}/invites`, {
    method: 'POST',
    data: payload,
  });
  return data.invite;
};

export const deleteResellerSubaccountInvite = async (
  sub_account_id: string,
  payload: { id: string },
) => {
  const { data } = await client(`reseller/sub-account/${sub_account_id}/invites`, {
    method: 'DELETE',
    data: payload,
  });
  return data;
};

export const getResellerSubaccountMembers = async (sub_account_id: string) => {
  const { data } = await client(`reseller/sub-account/${sub_account_id}/members`);
  return data;
};

export const getResellerSubaccountPricing = async (sub_account_id: string) => {
  const { data } = await client(`reseller/sub-account/${sub_account_id}/pricing`);
  return data.pricing;
};

export const updateResellerSubaccountPricing = async (sub_account_id: string, payload: any) => {
  const { data } = await client(`reseller/sub-account/${sub_account_id}/pricing`, {
    method: 'PATCH',
    data: payload,
  });
  return data.pricing;
};

export const getResellerSettings = async () => {
  const { data } = await client(`reseller/settings`);
  return data.settings;
};

export const getResellerSettingsByOrigin = async () => {
  const { data } = await client(`reseller`);
  return data.settings;
};

export const saveResellerSettings = async (payload: ResellerSettingsType) => {
  const { data } = await client(`reseller/settings`, {
    method: 'PATCH',
    data: payload,
  });
  return data.settings;
};

export const fileUploadAPI = async (data: FormData) => {
  const response = await client(`file/upload/image`, {
    data,
    method: 'POST',
  });
  return response.data.url;
};

export const fundResellerSubAccount = async (payload: {
  amount: number;
  organisation_id: string;
}) => {
  const { data } = await client('', {
    method: 'POST',
    data: payload,
    url: buildPaymentURL(`/credits/transfer`),
  });
  return data;
};
