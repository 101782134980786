import Cookie from 'js-cookie';
import { buildNotificationURL, clearState, client, loadState, saveState } from 'utils';
import { version } from '../../../../../package.json';
import {
  ForgotPasswordInitialValuesProps,
  LoginFormInitialValuesProps,
  RegisterFormInitialValuesProps,
} from '../components';
import { User, UserProfile } from './types';

export const getToken = () => {
  if (loadState()) {
    const { token } = loadState();
    return token;
  }
  return null;
};

export const getUser = () => {
  if (loadState()) {
    const { user } = loadState();
    return user;
  }
  return null;
};

export const login = async (values: LoginFormInitialValuesProps) => {
  const response = await client('auth/login', { data: values, method: 'POST' });
  const { token, auth: user, organisations } = response.data;
  saveState({ token, user, organisations });
  return { token, user, organisations };
};

export const register = async (values: RegisterFormInitialValuesProps) => {
  const response = await client('auth/create', { data: values, method: 'POST' });
  const { token, auth: user, organisations } = response.data;
  saveState({ token, user, organisations });
  return { token, user, organisations };
};

export const verifyEmail = async (values: { token: string }) => {
  const response = await client('auth/verify', { data: values, method: 'POST' });
  return response.data;
};

export const getProfile = async (organizationID: string, headers: any = {}) => {
  const response = await client(`auth/view/${organizationID}`, { headers });
  const { user, profile } = response.data;
  const previouslocalStorageState = loadState();
  saveState({ ...previouslocalStorageState, user, profile });
  return { ...previouslocalStorageState, user, profile };
};

export const updateProfile = async (
  values: Partial<Pick<UserProfile, 'first_name' | 'last_name' | 'organisation_id'>> & {
    user_id: User['id'];
  },
) => {
  const response = await client(`profile/save`, {
    method: 'PATCH',
    data: values,
  });
  const { profile } = response.data;
  const previouslocalStorageState = loadState();
  saveState({ ...previouslocalStorageState, profile });
  return { ...previouslocalStorageState, profile };
};

export const forgotPassword = async (
  values: ForgotPasswordInitialValuesProps & { link: string },
) => {
  const response = await client('auth/send_password_reset', {
    data: values,
    method: 'POST',
  });
  return response.data;
};

export const resetPassword = async (values: { password: string; token: string }) => {
  const response = await client('auth/reset_password', {
    data: values,
    method: 'POST',
  });
  return response.data;
};

export const changePassword = async (values: { old_password: string; new_password: string }) => {
  const response = await client(`auth/change-password`, {
    data: values,
    method: 'PATCH',
    validateStatus: status => status >= 200 && status <= 401,
  });
  if (response.status === 401) throw new Error('Your old password is incorrect');
  return response.data;
};

export const saveSettings = async (data: {
  notification: {
    email: string;
    desktop: string;
  };
  profile_id: string;
}) => {
  const response = await client(`profile/settings/save`, {
    data,
    method: 'PATCH',
  });
  return response.data.settings;
};

export const saveUserProfileImage = async (data: FormData) => {
  const response = await client(`profile/save_image`, {
    data,
    method: 'POST',
  });
  return response.data.profile;
};

export const getSettings = async (profile_id: string) => {
  const response = await client(`profile/settings/${profile_id}`);
  return response.data.settings;
};

export const registerDeviceForNotifications = async (
  payload: {
    os?: string | null;
    name?: string | null;
    imei?: string | null;
    model?: string | null;
    brand?: string | null;
    fcm_token?: string | null;
    os_version?: string | null;
    os_api_level?: string | null;
  },
  authPayload: { token: string },
) => {
  const data = {
    os: null,
    name: null,
    imei: null,
    model: null,
    brand: null,
    fcm_token: null,
    os_version: null,
    os_api_level: null,
    ...payload,
  };
  const { token } = authPayload;
  const response = await client(
    '',
    {
      data,
      method: 'POST',
      url: buildNotificationURL(`/device`),
      headers: { 'content-type': 'application/json', Authorization: token },
    },
    false,
  );
  return response.data;
};

export const removeDeviceForNotification = async (device_id: string) => {
  const response = await client('', {
    data: { device_id },
    method: 'DELETE',
    url: buildNotificationURL(`/device`),
  });
  return response.data;
};

export const logout = async (device_id: string) => {
  device_id && (await removeDeviceForNotification(device_id));
  Cookie.remove('touched');
  Cookie.remove('show_connect_channel_modal');
  clearState();
  localStorage.setItem('simpu.version', version);
  return Promise.resolve({});
};

export const getDeleteAccountRequest = async () => {
  const response = await client(`profile/get-delete-account-request`);
  return response.data.account_delete_request;
};

export const deleteAccountRequest = async (data: { reason: string }) => {
  const response = await client(`profile/delete-account-request`, {
    data,
    method: 'POST',
  });
  return response;
};

export const cancelDeleteAccountRequest = async () => {
  const response = await client(`profile/cancel-delete-account-request`, {
    method: 'POST',
  });
  return response;
};

export const assignOrganisationOwnership = async (data: { profile_id: string }) => {
  const response = await client(`profile/assign-ownership`, {
    data,
    method: 'POST',
  });
  return response;
};

export const saveOrganisationImage = async (data: FormData) => {
  const response = await client(`organisations/save_image`, {
    data,
    method: 'POST',
  });
  return response.data.organisation;
};

export const updateOrganisation = async (payload: any) => {
  const { data } = await client('organisations/update', {
    data: payload,
    method: 'PATCH',
  });
  return data.organisation;
};

export const getDeleteOrganisationRequest = async () => {
  const response = await client(`organisations/get-delete-account-request`);
  return response.data.account_delete_request;
};

export const deleteOrganisationRequest = async (data: { reason: string }) => {
  const response = await client(`organisations/delete-account-request`, {
    data,
    method: 'POST',
  });
  return response;
};

export const cancelDeleteOrganisationRequest = async () => {
  const response = await client(`organisations/cancel-delete-account-request`, {
    method: 'POST',
  });
  return response;
};

export const listOrganisations = async () => {
  const { data } = await client('organisations');

  return data.organisations;
};
