import { AppThunk } from 'root';
import {
  setAutomationsData,
  setAutomationsLoading,
  resetAutomations,
  resetActions,
  setActionsLoading,
  setActionsData,
} from '../lists.reducer';
import { client } from 'utils';

export const fetchAutomations = (listID: string): AppThunk => async dispatch => {
  dispatch(resetAutomations());
  dispatch(setAutomationsLoading(true));

  const response = await client(`lists/automation/${listID}`, {
    method: 'GET',
  });

  const data: { [key: string]: any } = {};
  const by_id: string[] = [];

  response.data.forEach((i: any) => {
    data[i.id] = i;
    by_id.push(i.id);
  });

  dispatch(setAutomationsData({ data, by_id }));
  dispatch(setAutomationsLoading(false));
  return response.data;
};

export const createAutomation = (): AppThunk => async (dispatch, getState) => {
  const {
    lists: { selected_list: listID },
    automations: { data, by_id },
  } = getState();

  const payload = { list_id: listID };

  const response = await client(`lists/automation`, {
    method: 'POST',
    data: payload,
  });

  const automationID = response.data.id;

  await client(`lists/automation/action`, {
    method: 'POST',
    data: {
      automation_id: automationID,
    },
  });

  const updated_data = { ...data, [automationID]: response.data };
  const updated_by_id = [...by_id, automationID];

  dispatch(setAutomationsData({ data: updated_data, by_id: updated_by_id }));
  return response.data;
};

export const updateAutomation = (automationID: string, payload: any): AppThunk => async (
  dispatch,
  getState,
) => {
  const {
    automations: { data, by_id },
  } = getState();

  const response = await client(`lists/automation/${automationID}`, {
    method: 'PATCH',
    data: payload,
  });

  const updated_data = { ...data, [response.data.id]: response.data };

  dispatch(setAutomationsData({ data: updated_data, by_id }));
  return response.data;
};

export const deleteAutomation = (automationID: string, payload: any): AppThunk => async (
  dispatch,
  getState,
) => {
  const {
    automations: { data, by_id },
  } = getState();

  const response = await client(`lists/automation/${automationID}`, {
    method: 'DELETE',
  });

  const updatedByID = by_id.filter((i: string) => i !== automationID);
  const updatedData: any = {};
  updatedByID.forEach((i: string) => {
    // @ts-ignore
    updatedData[i] = data[i];
  });

  dispatch(setAutomationsData({ data: updatedData, by_id: updatedByID }));
  return response.data;
};

export const fetchAutomationActions = (automationID: string): AppThunk => async dispatch => {
  dispatch(resetActions());
  dispatch(setActionsLoading(true));

  const response = await client(`lists/automation/action/${automationID}`, {
    method: 'GET',
  });

  const data: { [key: string]: any } = {};
  const by_id: string[] = [];

  response.data.forEach((i: any) => {
    data[i.id] = i;
    by_id.push(i.id);
  });

  dispatch(setActionsData({ data, by_id }));
  dispatch(setActionsLoading(false));
  return response.data;
};

export const addAutomationAction = (automationID: string): AppThunk => async (
  dispatch,
  getState,
) => {
  const {
    automations: {
      actions: { data, by_id },
    },
  } = getState();

  dispatch(setActionsLoading(true));
  const payload = {
    automation_id: automationID,
  };
  const response = await client(`lists/automation/action`, {
    method: 'POST',
    data: payload,
  });

  const updatedData = {
    ...data,
    [response.data['id']]: response.data,
  };
  const updatedByID = [...by_id, response.data['id']];
  dispatch(setActionsData({ data: updatedData, by_id: updatedByID }));
  dispatch(setActionsLoading(false));

  return response.data;
};

export const updateAutomationAction = (automationID: string, payload: any): AppThunk => async (
  dispatch,
  getState,
) => {
  const {
    automations: {
      actions: { data, by_id },
    },
  } = getState();

  const updatedAutomation = {
    // @ts-ignore
    ...data[automationID],
    ...payload,
  };
  let updatedData = {
    ...data,
    [automationID]: updatedAutomation,
  };
  dispatch(setActionsData({ data: updatedData, by_id }));

  try {
    const response = await client(`lists/automation/action/${automationID}`, {
      method: 'PATCH',
      data: payload,
    });

    updatedData = {
      ...data,
      [automationID]: response.data,
    };
    dispatch(setActionsData({ data: updatedData, by_id }));

    return response.data;
  } catch (e) {
    return null;
  }
};

export const deleteAutomationAction = (actionID: string): AppThunk => async (
  dispatch,
  getState,
) => {
  const {
    automations: {
      actions: { data, by_id },
    },
  } = getState();

  const updatedByID = by_id.filter((i: string) => i !== actionID);
  const updatedData: any = {};
  updatedByID.forEach((i: string) => {
    // @ts-ignore
    updatedData[i] = data[i];
  });

  dispatch(setActionsData({ data: updatedData, by_id: updatedByID }));

  try {
    const response = await client(`lists/automation/action/${actionID}`, {
      method: 'DELETE',
    });

    return response.data;
  } catch (e) {
    return null;
  }
};
