import { Flex, PseudoBox, SlideIn, Stack } from '@chakra-ui/core';
import * as React from 'react';
import { useNetworkState } from 'react-use';
import { BodyText } from '../Typography';

export function NetworkStatusIndicator() {
  const { online, effectiveType } = useNetworkState();

  const t = () => {
    if (!online) {
      return {
        title: "It seems that you're offline",
        label: 'Please check your network connection',
      };
    }
    if (effectiveType === '2g') {
      return {
        title: "It seems you're connection is slow",
        label: 'Please check your network connection',
      };
    }
    if (effectiveType === 'slow-2g') {
      return {
        title: "It seems you're connection is really slow",
        label: 'Please check your network connection',
      };
    }
    return { title: "It seems that you're offline", label: 'Please check your network connection' };
  };

  const getStyle = () => {
    if (!online) {
      return { bg: 'red.500', color: 'white' };
    }
    if (effectiveType === '2g' || effectiveType === 'slow-2g') {
      return { bg: 'yellow.400', color: 'gray.900' };
    }
    return { bg: 'green.500', color: 'white' };
  };

  const { title, label } = t();

  return (
    <SlideIn in={!online || effectiveType === '2g' || effectiveType === 'slow-2g'}>
      {styles => (
        <Flex
          width="100%"
          py="0.25rem"
          alignItems="center"
          justifyContent="center"
          {...getStyle()}
          {...styles}
        >
          <Stack isInline alignItems="center">
            <BodyText fontWeight="500">
              {title}.{' '}
              <PseudoBox fontWeight="400" as="span">
                {label}
              </PseudoBox>
            </BodyText>
          </Stack>
        </Flex>
      )}
    </SlideIn>
  );
}
