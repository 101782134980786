import React from 'react';
import { Box } from '@chakra-ui/core/dist';

export const HeaderNew = (props: any) => {
  return (
    <Box cursor={props.isDisabled ? 'not-allowed' : 'pointer'} id='col_new' color='#8c8c8c' fontWeight='500'>
      +
    </Box>
  );
};
