import { Box, Flex, Heading, ModalBody, ModalFooter, PseudoBox, Stack } from '@chakra-ui/core';
import { Button, ModalContainer, SmallText, useResellerSettings } from 'app/components';
import React, { useState } from 'react';

interface ActionItemProps {
  label: string;
  icon?: string;
  caption: string;
  isActive?: boolean;
  onClick: () => void;
  onHover: () => void;
}

const ActionItem = (props: ActionItemProps) => {
  const { label, caption, isActive, onHover, onClick } = props;

  const activeStyle = isActive
    ? {
        bg: 'gray.100',
        rounded: '4px',
        borderWidth: '1px',
      }
    : {};

  return (
    <PseudoBox
      p="0.5rem"
      display="flex"
      cursor="pointer"
      onClick={onClick}
      onMouseEnter={onHover}
      alignItems="flex-start"
      transition="all 0.2s ease-in"
      _hover={{
        bg: 'gray.50',
        rounded: '4px',
        borderWidth: '1px',
      }}
      {...activeStyle}
    >
      <Box>
        <Heading size="xs">{label}</Heading>
        {<SmallText>{caption}</SmallText>}
      </Box>
    </PseudoBox>
  );
};

interface Props {
  isOpen: boolean;
  onClose: () => void;
  handleQuickAction: (action: 'enrichment' | 'automation' | 'form') => void;
}

export const ListQuickActivityModal = ({ isOpen, onClose, handleQuickAction }: Props) => {
  const [activeItem, setActiveItem] = useState(1);

  const { name } = useResellerSettings();

  const images: { [key: string]: string } = {
    // 0: 'https://cdn.dribbble.com/users/1176997/screenshots/15391076/media/fa9e44f07ced216c1e0d5111cd6d36be.png?compress=1&resize=1600x1200&vertical=top',
    1: 'https://res.cloudinary.com/simpu-inc/image/upload/v1670009632/Xnapper-2022-12-02-20.30.10_jbsuo2.png',
    2: 'https://res.cloudinary.com/simpu-inc/image/upload/v1670009633/Xnapper-2022-12-02-20.31.29_riirfl.png',
    3: 'https://res.cloudinary.com/simpu-inc/image/upload/v1670009632/Xnapper-2022-12-02-20.30.57_qm8n2a.png',
  };

  return (
    <ModalContainer showCloseButton size="4xl" isOpen={isOpen} onClose={onClose}>
      <ModalBody py="2rem">
        <Heading pb="2rem" size="lg">
          Do more with your list.
        </Heading>
        <Flex justifyContent="space-between">
          <Box width="28%">
            <Stack spacing="1rem">
              {/* <Box>
                <ActionItem
                  isActive={activeItem === 0}
                  caption="Detect and remove duplicated data."
                  label="Data cleanup"
                  onHover={() => setActiveItem(0)}
                  onClick={() => handleQuickAction('duplicate')}
                />
              </Box> */}
              <Box>
                <ActionItem
                  label="Automations"
                  isActive={activeItem === 1}
                  caption="Setup automations to handle quick tasks."
                  onClick={() => {
                    handleQuickAction('automation');
                  }}
                  onHover={() => setActiveItem(1)}
                />
              </Box>
              <Box>
                <ActionItem
                  label="Forms"
                  isActive={activeItem === 2}
                  caption="Setup a form to collect important data."
                  onClick={() => {
                    handleQuickAction('form');
                  }}
                  onHover={() => setActiveItem(2)}
                />
              </Box>
              <Box>
                <ActionItem
                  label="Data enrichment"
                  isActive={activeItem === 3}
                  onClick={() => {
                    handleQuickAction('enrichment');
                  }}
                  onHover={() => setActiveItem(3)}
                  caption="Enrich your data with useful public information."
                />
              </Box>
            </Stack>
          </Box>
          <Box width="68%">
            <Box
              width="100%"
              rounded="8px"
              height="400px"
              bgPos="right"
              bgSize="cover"
              backgroundRepeat="no-repeat"
              backgroundImage={`url(${images[activeItem]})`}
            />
          </Box>
        </Flex>
      </ModalBody>
      <ModalFooter borderTopWidth="1px">
        <Flex width="100%" alignItems="center" justifyContent="space-between">
          <Button
            size="xs"
            rightIcon="external-link"
            onClick={() =>
              window.open('https://support.simpu.co/en/collections/3580014-marketing', '_blank')
            }
          >
            Learn more at {name} help center
          </Button>
          <Button size="sm" onClick={onClose} variantColor="blue">
            Got it
          </Button>
        </Flex>
      </ModalFooter>
    </ModalContainer>
  );
};
