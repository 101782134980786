import { Box, Divider, Flex, Grid, Heading, Icon, Stack, Text } from '@chakra-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import AppParamsContextProvider, {
  AppParamsContext,
} from '../contexts/app-contexts/params.context';
import AppHeadersContextProvider, {
  AppHeadersContext,
} from '../contexts/app-contexts/headers.context';
import RequestParamsContextProvider, {
  RequestParamsContext,
} from '../contexts/request-contexts/params.context';
import RequestHeadersContextProvider, {
  RequestHeadersContext,
} from '../contexts/request-contexts/headers.context';
import AppPaginationContextProvider, {
  AppPaginationContext,
} from '../contexts/app-contexts/pagination.context';
import Apps from './stages/apps';
import Requests from './stages/requests';
import Webhooks from './stages/webhooks';
import WebhooksParamsContextProvider, {
  WebhooksParamsContext,
} from '../contexts/webhooks-contexts/params.context';
import WebhooksHeadersContextProvider, {
  WebhooksHeadersContext,
} from '../contexts/webhooks-contexts/headers.context';
import { useDispatch } from 'react-redux';
import { fetchCustomAppUpdateData } from 'app/authenticated-app/lists/thunks/resources';
import { useParams } from 'react-router';
import { FullPageSpinner, useResellerSettings } from 'app/components';
import { RequestPaginationContext } from '../contexts/request-contexts/pagination.context';

interface Props {
  searchValue: string;
  refreshCustomApps: Function;
  updateData?: any;
  appId?: any;
}

export interface FlowStepProps {
  label: string;
  count: number;
  isActive?: boolean;
  isComplete?: boolean;
}

export const FlowStep = ({ count, label, isActive, isComplete }: FlowStepProps) => {
  const style = isActive
    ? {
        bg: 'primary',
        color: 'white',
      }
    : {
        bg: 'transparent',
        color: 'primary',
      };
  return (
    <Stack isInline alignItems="center">
      {isComplete ? (
        <Icon name="check" color="primary" size="1rem" />
      ) : (
        <Flex
          w="20px"
          height="20px"
          fontWeight={600}
          borderWidth="1px"
          borderRadius="50%"
          fontSize="0.75rem"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          borderColor="primary"
          {...style}
        >
          {count}
        </Flex>
      )}
      <Text fontWeight={500} fontSize="0.875rem">
        {label}
      </Text>
    </Stack>
  );
};

export const FlowSteps = ({
  sections,
  activeSection,
}: {
  sections?: {
    count: number;
    label: string;
  }[];
  activeSection: number;
}) => {
  sections = sections ?? [
    { count: 0, label: 'App' },
    { count: 1, label: 'Requests' },
    { count: 2, label: 'Webhooks' },
  ];
  return (
    <Stack isInline alignItems="center">
      {sections.map(({ count, label }, index) => {
        const sectionsLength = sections?.length ? sections.length - 1 : 0;
        return (
          <Stack isInline alignItems="center" key={`${label}-${index}`}>
            <Box>
              <FlowStep
                label={label}
                count={count + 1}
                key={`${label}-${index}`}
                isActive={activeSection === count}
                isComplete={activeSection > count}
              />
            </Box>
            {index < sectionsLength && <Divider width="100px" />}
          </Stack>
        );
      })}
    </Stack>
  );
};

export const CreateCustomApps = (props: Props) => {
  const [section, setSection] = React.useState(0);
  const routerHistory = useHistory();
  const navCreateApp = () => routerHistory.push('/s/lists/custom-apps');

  const dispatch = useDispatch();
  //@ts-ignore
  const { appId } = useParams();
  const [loadingUpdateData, setLoadingUpdateData] = useState(true);

  const {
    setUrlText: appParamsSetUrlText,
    setRowId: appParamsSetRowId,
    setRadioImageValue: appParamsSetRadioImageValue,
    setUserText: appParamsSetUserText,
    setResponseType: appParamsSetResponseType,
    setParamsState: appParamsSetParamsState,
    setBasicAuthPassword: appParamsSetBasicAuthPassword,
    setBasicAuthUserName: appParamsSetBasicAuthUserName,
    setAppName: appParamsSetAppName,
    setAuthenticationType: appParamsSetAuthenticationType,
    setNextPage: appParamsSetNextPage,
    setSelectedTitle: appParamsSetSelectedTitle,
    setTotalText: appParamsSetTotalText,
  } = useContext(AppParamsContext);

  const {
    setUrlText: appHeadersSetUrlText,
    setRowId: appHeadersSetRowId,
    setTotalText: appHeadersSetTotalText,
    setUserText: appHeadersSetUserText,
    setParamsState: appHeadersSetParamsState,
  } = useContext(AppHeadersContext);

  const { setRowId: appPaginationSetRowId, setParamsState: appPaginationSetParamsState } =
    useContext(AppPaginationContext);

  const {
    setRowId: requestParamsSetRowId,
    setUserText: requestParamsSetUserText,
    setParamsState: requestParamsSetParamsState,
    setRequestArray: requestParamsSetRequestArray,
    setUrlText: requestParamsSetUrlText,
    setTotalText: requestParamsSetTotalText,
    setRequestName: requestParamsSetRequestName,
    setExpandedState: requestParamsSetExpandedState,
    setRequestMethod: requestParamsSetRequestMethod,
    setResponseBody: requestParamsSetResponseBody,
    setRequestBody: requestParamsSetRequestBody,
    setSettingsUniqueIdentifier: requestParamsSetSettingsUniqueIdentifier,
    setNextPage: requestParamsSetNextPage,
    setSelectedTitle: requestParamsSetSelectedTitle,
    setArrayKey: requestParamsSetArrayKey,
    setTestResult: requestParamsSetTestResult,
    setIsJsonAvailable: requestParamsSetIsJsonAvailable,
    setJsonResponse: requestParamsSetJsonResponse,
    setResponseBodyEnabled,
    setSelectedTitle,
    setCurrentCell: requestParamsSetCurrentCell,
    setDropDown: requestParamsSetDropDown,
    setTestResultResponse,
    setIsJsonAvailableResponse,
    setIsResponseSelectorValid,
    setSchemaResponse,
  } = useContext(RequestParamsContext);

  const { setParamsState: requestHeadersSetParamsState } = useContext(RequestHeadersContext);

  const { setParamsState: requestPaginationSetParamsState } = useContext(RequestPaginationContext);

  const {
    setRowId: webhooksParamsSetRowId,
    setUserText: webhooksParamsSetUserText,
    setParamsState: webhooksParamsSetParamsState,
    setRequestArray: webhooksParamsSetRequestArray,
    setUrlText: webhooksParamsSetUrlText,
    setTotalText: webhooksParamsSetTotalText,
    setRequestName: webhooksParamsSetRequestName,
    setExpandedState: webhooksParamsSetExpandedState,
    setRequestMethod: webhooksParamsSetRequestMethod,
    setResponseBody: webhooksParamsSetResponseBody,
    setRequestBody: webhooksParamsSetRequestBody,
    setSettingsUniqueIdentifier: webhooksParamsSetSettingsUniqueIdentifier,
    setRequestNameSelection: webhooksParamsSetRequestNameSelection,
    setGeneratedURL,
  } = useContext(WebhooksParamsContext);

  const { setParamsState: webhooksHeadersSetParamsState } = useContext(WebhooksHeadersContext);

  useEffect(() => {
    const updateCustomApp = async (id: string) => {
      const updateData = (await dispatch(fetchCustomAppUpdateData(id))) as any;

      const appHeaders = updateData['app_data']['app_headers'];
      const appPagination = updateData['app_data']['app_pagination'];
      const appParams = updateData['app_data']['app_params'];

      const requestsHeaders = updateData['requests_data']['requests_headers'];
      const requestsParams = updateData['requests_data']['requests_params'];
      const requestsPagination = updateData['requests_data']['requests_pagination'];

      const webhooksHeaders = updateData['webhooks_data']['webhooks_headers'];
      const webhooksParams = updateData['webhooks_data']['webhooks_params'];

      appParamsSetAppName(appParams['appName']);
      appHeadersSetParamsState(appHeaders['paramsState']);
      appHeadersSetRowId(appHeaders['rowId']);
      appHeadersSetUserText(appHeaders['userText']);
      appHeadersSetUrlText(appHeaders['urlText']);
      appHeadersSetTotalText(appHeaders['totalText']);

      appPaginationSetParamsState(appPagination['paramsState']);
      appPaginationSetRowId(appPagination['rowId']);

      appParamsSetRadioImageValue(appParams['RadioImageValue']);
      appParamsSetAuthenticationType(appParams['authenticationType']);
      appParamsSetBasicAuthPassword(appParams['basicAuthPassword']);
      appParamsSetBasicAuthUserName(appParams['basicAuthUserName']);
      appParamsSetNextPage(appParams['nextPageState']);
      appParamsSetParamsState(appParams['paramsState']);
      appParamsSetResponseType(appParams['responseType']);
      appParamsSetRowId(appParams['rowId']);
      appParamsSetSelectedTitle(appParams['selectedTitle']);
      appParamsSetTotalText(appParams['totalText']);
      appParamsSetUrlText(appParams['urlText']);
      appParamsSetUserText(appParams['userText']);

      requestHeadersSetParamsState(requestsHeaders['paramsState']);
      requestPaginationSetParamsState(requestsPagination['paramsState']);

      requestParamsSetArrayKey(requestsParams['arrayKey']);
      requestParamsSetExpandedState(requestsParams['expandedState']);
      requestParamsSetNextPage(requestsParams['nextPageState']);
      requestParamsSetParamsState(requestsParams['paramsState']);
      requestParamsSetRequestArray(requestsParams['requestArray']);
      requestParamsSetRequestBody(requestsParams['requestBody']);
      requestParamsSetRequestMethod(requestsParams['requestMethod']);
      requestParamsSetRequestName(requestsParams['requestName']);
      requestParamsSetResponseBody(requestsParams['responseBody']);
      requestParamsSetRowId(requestsParams['rowId']);
      requestParamsSetSelectedTitle(requestsParams['selectedTitle']);
      requestParamsSetSettingsUniqueIdentifier(requestsParams['settingsUniqueIdentifier']);
      requestParamsSetTotalText(requestsParams['totalText']);
      requestParamsSetUrlText(requestsParams['urlText']);
      requestParamsSetUserText(requestsParams['userText']);
      requestParamsSetTestResult(requestsParams['testResult']);
      requestParamsSetIsJsonAvailable(requestsParams['isJsonAvailable']);
      requestParamsSetJsonResponse(requestsParams['jsonResponse']);
      setResponseBodyEnabled(requestsParams['responseBodyEnabled']);
      setSelectedTitle(requestsParams['selectedTitle']);
      requestParamsSetCurrentCell(requestsParams['currentCell']);
      requestParamsSetDropDown(requestsParams['dropDown']);
      setTestResultResponse(requestsParams['testResultResponse']);
      setIsJsonAvailableResponse(requestsParams['isJsonAvailableResponse']);
      setIsResponseSelectorValid(requestsParams['isResponseSelectorValid']);
      setSchemaResponse(requestsParams['schemaResponse']);

      webhooksHeadersSetParamsState(webhooksHeaders['paramsState']);

      webhooksParamsSetExpandedState(webhooksParams['expandedState']);
      webhooksParamsSetParamsState(webhooksParams['paramsState']);
      webhooksParamsSetRequestArray(webhooksParams['requestArray']);
      webhooksParamsSetRequestBody(webhooksParams['requestBody']);
      webhooksParamsSetRequestMethod(webhooksParams['requestMethod']);
      webhooksParamsSetRequestName(webhooksParams['requestName']);
      webhooksParamsSetRequestNameSelection(webhooksParams['requestNameSelection']);
      webhooksParamsSetResponseBody(webhooksParams['responseBody']);
      webhooksParamsSetRowId(webhooksParams['rowId']);
      webhooksParamsSetSettingsUniqueIdentifier(webhooksParams['settingsUniqueIdentifier']);
      webhooksParamsSetTotalText(webhooksParams['totalText']);
      webhooksParamsSetUrlText(webhooksParams['urlText']);
      webhooksParamsSetUserText(webhooksParams['userText']);
      setGeneratedURL(webhooksParams['generatedURL']);
      setLoadingUpdateData(false);
    };

    if (props.updateData) {
      updateCustomApp(appId);
    }
  }, [
    props.appId,
    props.updateData,
    appId,
    dispatch,
    appHeadersSetParamsState,
    appHeadersSetRowId,
    appHeadersSetUserText,
    appHeadersSetUrlText,
    appHeadersSetTotalText,
    appPaginationSetParamsState,
    appPaginationSetRowId,
    appParamsSetRadioImageValue,
    appParamsSetAppName,
    appParamsSetAuthenticationType,
    appParamsSetBasicAuthPassword,
    appParamsSetBasicAuthUserName,
    appParamsSetNextPage,
    appParamsSetParamsState,
    appParamsSetResponseType,
    appParamsSetRowId,
    appParamsSetSelectedTitle,
    appParamsSetTotalText,
    appParamsSetUrlText,
    appParamsSetUserText,
    requestHeadersSetParamsState,
    requestParamsSetArrayKey,
    requestParamsSetExpandedState,
    requestParamsSetNextPage,
    requestParamsSetParamsState,
    requestParamsSetRequestArray,
    requestParamsSetRequestBody,
    requestParamsSetRequestMethod,
    requestParamsSetRequestName,
    requestParamsSetResponseBody,
    requestParamsSetRowId,
    requestParamsSetSelectedTitle,
    requestParamsSetSettingsUniqueIdentifier,
    requestParamsSetTotalText,
    requestParamsSetUrlText,
    requestParamsSetUserText,
    webhooksHeadersSetParamsState,
    webhooksParamsSetExpandedState,
    webhooksParamsSetParamsState,
    webhooksParamsSetRequestArray,
    webhooksParamsSetRequestBody,
    webhooksParamsSetRequestMethod,
    webhooksParamsSetRequestName,
    webhooksParamsSetRequestNameSelection,
    webhooksParamsSetResponseBody,
    webhooksParamsSetRowId,
    webhooksParamsSetSettingsUniqueIdentifier,
    webhooksParamsSetTotalText,
    webhooksParamsSetUrlText,
    webhooksParamsSetUserText,
    requestParamsSetTestResult,
    requestParamsSetIsJsonAvailable,
    requestParamsSetJsonResponse,
    setResponseBodyEnabled,
    setSelectedTitle,
    requestParamsSetCurrentCell,
    requestParamsSetDropDown,
    setTestResultResponse,
    setIsJsonAvailableResponse,
    setIsResponseSelectorValid,
    setSchemaResponse,
    setGeneratedURL,
    requestPaginationSetParamsState,
  ]);

  const { name } = useResellerSettings();

  return (
    <>
      <Box className="content">
        <Grid templateColumns="repeat(1, 1fr 100fr)" gap={5}>
          <Box
            className="customBackIcon"
            onClick={section > 0 ? () => setSection(section - 1) : () => navCreateApp()}
            height={'2rem'}
          >
            <Icon name="inbox-chevron-left" size="1rem" color="blue.500" />
          </Box>

          <Box>
            <Box>
              <Heading as="h4" fontSize="1.2rem" fontWeight={500} color="#2B3A4B">
                {section === 0 && `Integrate your app with ${name}`}
                {section === 1 && 'Add requests to your app'}
                {section === 2 && 'Add webhooks to your app'}
              </Heading>

              <Text maxW="500px" color="#A5ABB3" fontSize="0.875rem">
                Custom API
              </Text>
            </Box>

            <Box mt={6} color="black" mb={'2.3rem'}>
              <FlowSteps activeSection={section} />
            </Box>

            {props.updateData ? (
              <Box>
                {loadingUpdateData ? (
                  <FullPageSpinner marginTop={'-12rem'} />
                ) : (
                  <Box>
                    {section === 0 && <Apps setSection={setSection} />}
                    {section === 1 && <Requests setSection={setSection} updateData={true} />}
                    {section === 2 && (
                      <Webhooks
                        setSection={setSection}
                        refreshCustomApps={props.refreshCustomApps}
                        updateData={true}
                        appId={appId}
                      />
                    )}
                  </Box>
                )}
              </Box>
            ) : (
              <Box>
                <AppParamsContextProvider>
                  <AppHeadersContextProvider>
                    <RequestParamsContextProvider>
                      <RequestHeadersContextProvider>
                        <AppPaginationContextProvider>
                          <WebhooksParamsContextProvider>
                            <WebhooksHeadersContextProvider>
                              {section === 0 && <Apps setSection={setSection} />}
                              {section === 1 && <Requests setSection={setSection} />}
                              {section === 2 && (
                                <Webhooks
                                  setSection={setSection}
                                  refreshCustomApps={props.refreshCustomApps}
                                />
                              )}
                            </WebhooksHeadersContextProvider>
                          </WebhooksParamsContextProvider>
                        </AppPaginationContextProvider>
                      </RequestHeadersContextProvider>
                    </RequestParamsContextProvider>
                  </AppHeadersContextProvider>
                </AppParamsContextProvider>
              </Box>
            )}
          </Box>
        </Grid>
      </Box>
    </>
  );
};
