import { Box, Flex, IconButton, Image, Stack, Text, Tooltip, useDisclosure } from '@chakra-ui/core';
import { BodyText, Button, ContentWrapper, FullPageSpinner, Subtitle } from 'app/components';
import { format } from 'date-fns';
import { icons } from 'feather-icons';
import * as React from 'react';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { CampaignAnalyticsUIProps } from './analytics.types';
import { OneTimeCampaignAnalytics } from './components';
import { ShareCampaignAnalyticsModal } from './components/analytics/ShareCampaignAnalyticsModal';

export const SMSCampaginAnalyticsUI = (props: CampaignAnalyticsUIProps) => {
  const { history, campaign, fetchCampaign } = props;
  const { id: routeID } = useParams<{ id: string }>();

  const { name, created_datetime } = campaign;

  const createdTime = created_datetime && format(new Date(created_datetime), 'MMMM dd yyyy');

  const [isLoading, setIsLoading] = useState(false);

  const {
    isOpen: isShareAnalyticsModalOpen,
    onClose: onCloseShareAnalyticsModal,
    onOpen: onOpenShareAnalyticsModal,
  } = useDisclosure();

  React.useEffect(() => {
    let mounted = true;

    if (routeID) {
      const fetchData = async () => {
        try {
          setIsLoading(true);
          await fetchCampaign(routeID, true);
          if (mounted) {
            setIsLoading(false);
          }
        } catch (error) {
          setIsLoading(false);
        }
      };
      fetchData();
    }
    return () => {
      mounted = false;
    };
  }, [routeID, fetchCampaign]);

  React.useEffect(() => {
    let mounted = true;

    if (routeID) {
      const fetchStatsData = async () => {
        try {
          setIsLoading(true);

          if (mounted) {
            setIsLoading(false);
          }
        } catch (error) {
          setIsLoading(false);
        }
      };
      fetchStatsData();
    }

    return () => {
      mounted = false;
    };
  }, [routeID]);

  function handleCreateOneTimeCampaign() {
    history.push('/s/marketing/sms-campaigns/new/one-time-campaign');
  }

  if (isLoading) {
    return <FullPageSpinner />;
  }

  return (
    <ContentWrapper>
      <Box maxW="1200px" mx="auto" paddingX="1.5rem">
        <Flex pt="0.8rem" pb="2rem" alignItems="center" justifyContent="space-between">
          <Stack isInline spacing="1rem" alignItems="center">
            <Box>
              <IconButton
                size="sm"
                rounded="8px"
                variant="ghost"
                aria-label="Back"
                icon="arrow-back"
                borderWidth="1px"
                onClick={() => history.goBack()}
              />
            </Box>
            <Box>
              <Subtitle color="blue.500">{name}</Subtitle>
            </Box>
            <Box>
              <Text color="#595e8a" fontSize="1rem">
                {createdTime}
              </Text>
            </Box>
          </Stack>
          <Stack isInline alignItems="center">
            <Box>
              <Tooltip aria-label="Share report" label={<BodyText>Share report</BodyText>}>
                <Button
                  size="sm"
                  variant="outline"
                  aria-label="share"
                  onClick={onOpenShareAnalyticsModal}
                >
                  <Image
                    mr="0.5rem"
                    alt="list-icon"
                    src={`data:image/svg+xml;utf8,${icons['share-2'].toSvg({
                      color: 'gray',
                      width: '1rem',
                      height: '1rem',
                    })}`}
                  />
                  Share
                </Button>
              </Tooltip>
            </Box>

            <Button size="sm" variantColor="blue" onClick={handleCreateOneTimeCampaign}>
              Create campaign
            </Button>
          </Stack>
        </Flex>

        <OneTimeCampaignAnalytics campaign={campaign} />
      </Box>
      {isShareAnalyticsModalOpen && (
        <ShareCampaignAnalyticsModal
          campaign={campaign}
          isOpen={isShareAnalyticsModalOpen}
          onClose={onCloseShareAnalyticsModal}
        >
          <OneTimeCampaignAnalytics isGeneratingImage={true} campaign={campaign} />
        </ShareCampaignAnalyticsModal>
      )}
    </ContentWrapper>
  );
};
