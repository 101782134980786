import { $patchStyleText } from '@lexical/selection';
import { $getSelection, $isRangeSelection, LexicalEditor } from 'lexical';
import React, { useCallback } from 'react';
import { dropDownActiveClass } from '../utils/add-dropdown-item-class';
import { Box, Popover, PopoverBody, PopoverContent, PopoverTrigger } from '@chakra-ui/core';

export function FontDropDown({
  editor,
  value,
  style,
  disabled = false,
}: {
  editor: LexicalEditor;
  value: string;
  style: string;
  disabled?: boolean;
}): JSX.Element {
  const handleClick = useCallback(
    (option: string) => {
      editor.update(() => {
        const selection = $getSelection();
        if ($isRangeSelection(selection)) {
          $patchStyleText(selection, {
            [style]: option,
          });
        }
      });
    },
    [editor, style],
  );

  const buttonAriaLabel =
    style === 'font-family'
      ? 'Formatting options for font family'
      : 'Formatting options for font size';

  return (
    <Popover placement="top-start">
      <PopoverTrigger>
        <button
          type="button"
          disabled={disabled}
          className={'toolbar-item ' + style}
          aria-label={buttonAriaLabel || value}
        >
          <span className={style === 'font-family' ? 'icon block-type font-family' : ''} />
          {value && <span className="text dropdown-button-text">{value}</span>}
          <i className="chevron-down" />
        </button>
      </PopoverTrigger>

      <PopoverContent
        maxW="max-content"
        borderWidth="0"
        boxShadow="0 12px 28px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.1), inset 0 0 0 1px rgba(255, 255, 255, 0.5)"
        _focus={{
          boxShadow:
            '0 12px 28px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.1), inset 0 0 0 1px rgba(255, 255, 255, 0.5)',
        }}
      >
        <PopoverBody p="0">
          <Box className="lexical-rich-text-dropdown">
            {(style === 'font-family' ? FONT_FAMILY_OPTIONS : FONT_SIZE_OPTIONS).map(
              ([option, text]) => (
                <button
                  key={option}
                  type="button"
                  onClick={() => handleClick(option)}
                  className={`item ${dropDownActiveClass(value === option)} ${
                    style === 'font-size' ? 'fontsize-item' : ''
                  }`}
                >
                  <span className="text">{text}</span>
                </button>
              ),
            )}
          </Box>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
}

const FONT_FAMILY_OPTIONS: [string, string][] = [
  ['Arial', 'Arial'],
  ['Courier New', 'Courier New'],
  ['Georgia', 'Georgia'],
  ['Times New Roman', 'Times New Roman'],
  ['Trebuchet MS', 'Trebuchet MS'],
  ['Verdana', 'Verdana'],
];

const FONT_SIZE_OPTIONS: [string, string][] = [
  ['10px', '10px'],
  ['11px', '11px'],
  ['12px', '12px'],
  ['13px', '13px'],
  ['14px', '14px'],
  ['15px', '15px'],
  ['16px', '16px'],
  ['17px', '17px'],
  ['18px', '18px'],
  ['19px', '19px'],
  ['20px', '20px'],
];
