import React, { useEffect, useState } from 'react';
import { Route, Switch } from 'react-router';
import { ListCustomApps } from './list-custom-apps';
import { CreateCustomApps } from './create-custom-apps';
import { deleteCustomApp, fetchCustomApps } from 'app/authenticated-app/lists/thunks/resources';
import { useDispatch } from 'react-redux';
import { useToast } from '@chakra-ui/core';
import { ToastBox } from 'app/components';
import { useHistory } from 'react-router-dom';

interface Props {
  searchValue: string;
}

export const Component = (props: Props) => {
  const toast = useToast();
  const dispatch = useDispatch();
  const routerHistory = useHistory();
  const [customApps, setCustomApps] = useState([]);
  const [showMenu, setShowMenu] = useState<Array<boolean | string | number>>([]);

  useEffect(() => {
    let isMounted = true;

    const fetch = async () => {
      try {
        const custom = (await dispatch(fetchCustomApps())) as any;
        if (isMounted) {
          setCustomApps(custom);
        }
      } catch (e) {
        if (isMounted) {
          setCustomApps([]);
        }
        toast({
          position: 'bottom-left',
          render: ({ onClose }) => (
            <ToastBox message="Unable to fetch custom apps, please try again" onClose={onClose} />
          ),
        });
      }
    };
    fetch();
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deletionHandler = async (id: string) => {
    await dispatch(deleteCustomApp(id));
    const custom = (await dispatch(fetchCustomApps())) as any;
    setCustomApps(custom);
  };

  const refreshCustomApps = async () => {
    const custom = (await dispatch(fetchCustomApps())) as any;
    setCustomApps(custom);
  };

  const updateCustomApp = async (id: string) => {
    routerHistory.push(`/s/lists/custom-apps/update/${id}`);
  };

  return (
    <Switch>
      <Route exact path="/s/lists/custom-apps">
        <ListCustomApps
          {...props}
          customApps={customApps}
          showMenu={showMenu}
          setShowMenu={setShowMenu}
          deletionHandler={deletionHandler}
          updateCustomApp={updateCustomApp}
        />
      </Route>
      <Route exact path="/s/lists/custom-apps/new">
        <CreateCustomApps searchValue={props.searchValue} refreshCustomApps={refreshCustomApps} />
      </Route>
      <Route exact path="/s/lists/custom-apps/update/:appId" component={CreateCustomApps}>
        <CreateCustomApps
          searchValue={props.searchValue}
          refreshCustomApps={refreshCustomApps}
          updateData={true}
        />
      </Route>
    </Switch>
  );
};
