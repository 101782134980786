import { Box, Flex, Select, Skeleton, Stack, Text } from '@chakra-ui/core';
import { ListSearch } from 'app/authenticated-app/lists/components/search';
import React from 'react';
import { EmptyState } from '../../../../components';
import emptyClipImage from '../assets/search-user.svg';
import { CAMPAIGN_LIST_LIMIT } from '../campaigns.service';
import { CampaignData, CampaignStates, CampaignTypes } from '../campaigns.types';
import { CampaignListItem } from './campaign-list-item';
import { CampaignTablePagination } from './campaign-table-pagination';

export type CampaignContentProps = {
  end: number;
  page: number;
  start: number;
  total_count: number;
  isLoading?: boolean;
  searchQuery?: string;
  selectedTab: CampaignTypes;
  isDeletingCampaign?: boolean;
  campaignsList: CampaignData[];
  selectedState: CampaignStates;
  onSearch(query: string): void;
  isDuplicatingCampaign?: boolean;
  onPagination(page: number): void;
  onTabChange: (tab: CampaignTypes) => void;
  onStateChange: (tab: CampaignStates) => void;
  onDeleteCampaign?(campaign: CampaignData): void;
  onDuplicateCampaign?(campaign: CampaignData): void;
  typeOptions: { label: string; value: CampaignTypes }[];
  stateOptions: { label: string; value: CampaignStates }[];
};

export const CampaignContent = (props: CampaignContentProps) => {
  const {
    end,
    page,
    start,
    onSearch,
    isLoading,
    searchQuery,
    onTabChange,
    selectedTab,
    total_count,
    typeOptions,
    stateOptions,
    onPagination,
    campaignsList,
    selectedState,
    onStateChange,
    onDeleteCampaign,
    isDeletingCampaign,
    onDuplicateCampaign,
    isDuplicatingCampaign,
  } = props;
  return (
    <div>
      <Box className="section-title">
        <Stack isInline alignItems="center">
          <Stack spacing="0" isInline rounded="4px" borderWidth="1px" alignItems="center">
            {typeOptions.map((item, index) => {
              const isFirst = index === 0;
              const isLast = index === typeOptions.length - 1;

              let borderRadiusStyles = {};

              if (isFirst) {
                borderRadiusStyles = {
                  roundedTopLeft: '4px',
                  roundedBottomLeft: '4px',
                };
              }
              if (isLast) {
                borderRadiusStyles = {
                  roundedTopRight: '4px',
                  roundedBottomRight: '4px',
                };
              }

              return (
                <Flex
                  px="1rem"
                  height="2rem"
                  fontWeight="500"
                  cursor="pointer"
                  alignItems="center"
                  fontSize="0.875rem"
                  justifyContent="center"
                  key={`${item.value}-${index}`}
                  borderRightWidth={isLast ? '0' : '1px'}
                  onClick={() => onTabChange(item.value)}
                  color={selectedTab === item.value ? 'white' : 'black'}
                  backgroundColor={selectedTab === item.value ? 'blue.500' : 'transparent'}
                  {...borderRadiusStyles}
                >
                  {item.label}
                </Flex>
              );
            })}
          </Stack>
          <Select
            size="sm"
            value={selectedState}
            onChange={e => {
              const value = e.target.value as CampaignStates;
              onStateChange(value);
            }}
          >
            {stateOptions.map((item, index) => (
              <option key={`${item.value}-${index}`} value={item.value}>
                {item.label}
              </option>
            ))}
          </Select>

          <ListSearch
            height="36px"
            focusWidth="150px"
            search_query={searchQuery ?? ''}
            updateSearchQuery={onSearch}
          />
        </Stack>
        <Stack isInline alignItems="center">
          <Stack isInline alignItems="center">
            <Text fontSize="0.8rem" fontWeight={500} color="gray.900">
              Viewing {start}-{total_count < end ? total_count : end} of {total_count}
            </Text>
            <CampaignTablePagination
              page={page}
              onClick={onPagination}
              metaData={{
                pageCount: CAMPAIGN_LIST_LIMIT,
                noOfPages: Math.ceil(total_count / CAMPAIGN_LIST_LIMIT),
              }}
            />
          </Stack>
        </Stack>
      </Box>
      {isLoading ? (
        <Stack>
          {Array.from({ length: 15 }, (v, i) => (
            <Stack
              isInline
              px="1rem"
              pt="1rem"
              borderTopWidth="1px"
              key={`${i.toString()}-${new Date().getTime()}`}
            >
              <Box width={['calc(25% - 1rem)', 'calc(50% - 1rem)', 'calc(25% - 1rem)']}>
                <Skeleton height="10px" width="80%" my="10px" />
                <Skeleton height="10px" width="50%" my="10px" />
                <Skeleton height="10px" width="25%" my="10px" />
              </Box>
              <Flex
                justifyContent="flex-end"
                width={['calc(25% - 1rem)', 'calc(50% - 1rem)', 'calc(25% - 1rem)']}
              >
                <Skeleton height="10px" width="60px" my="10px" />
              </Flex>
              <Flex
                justifyContent="flex-end"
                width={['calc(25% - 1rem)', 'calc(50% - 1rem)', 'calc(25% - 1rem)']}
              >
                <Skeleton height="10px" width="60px" my="10px" />
              </Flex>
              <Flex
                justifyContent="flex-end"
                width={['calc(25% - 1rem)', 'calc(50% - 1rem)', 'calc(25% - 1rem)']}
              >
                <Skeleton height="10px" width="60px" my="10px" />
              </Flex>
            </Stack>
          ))}
        </Stack>
      ) : (
        <Box>
          {!campaignsList?.length ? (
            <EmptyState
              py="100px"
              imageSize="120px"
              image={emptyClipImage}
              heading="No campaigns created yet"
              contentContainerProps={{ mt: '1rem' }}
              subheading="Send campaigns to your lists, segments or new audiences."
            />
          ) : (
            campaignsList?.map(campaign => (
              <CampaignListItem
                key={campaign.id}
                campaign={campaign}
                isLoading={isLoading}
                onDeleteCampaign={onDeleteCampaign}
                isDeletingCampaign={isDeletingCampaign}
                onDuplicateCampaign={onDuplicateCampaign}
                isDuplicatingCampaign={isDuplicatingCampaign}
              />
            ))
          )}
        </Box>
      )}
    </div>
  );
};
