import { Text } from '@chakra-ui/core/dist';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { LoginForm, LoginFormInitialValuesProps } from '../../components/forms';
import { AuthLayout } from '../../components/layout';
import { AuthProps } from '../../types';

export function LoginComponent(props: AuthProps<LoginFormInitialValuesProps>) {
  const { onSubmit, isLoading } = props;

  return (
    <AuthLayout
      heading="Sign in to your account"
      footing={
        <Text>
          Don't have an account?{' '}
          <Link style={{ color: '#2034c5' }} to="/register">
            Sign up
          </Link>
        </Text>
      }
    >
      <LoginForm isLoading={isLoading} onSubmit={values => onSubmit(values)} />
    </AuthLayout>
  );
}
