import { Spinner, Stack } from '@chakra-ui/core';
import { BodyText } from 'app/components';
import React from 'react';

export const ListImportStatusIndicator = (props: {
  status: 'UPLOADED' | 'PROCESSING' | 'DONE';
}) => {
  const { status } = props;

  const statusRegister: { [key: string]: { color: string; label: string } } = {
    DONE: { color: 'green.500', label: 'Import completed' },
    UPLOADED: { color: 'yellow.500', label: 'File uploaded' },
    PROCESSING: { color: 'yellow.500', label: 'Import processing' },
  };

  const { color, label } = statusRegister[status];

  return (
    <Stack position="relative" isInline alignItems="center">
      {status.toLowerCase() !== 'done' && (
        <Spinner
          size="sm"
          speed="0.65s"
          color={color}
          thickness="2px"
          position="relative"
          emptyColor="gray.200"
        />
      )}
      <BodyText fontWeight="500" color={color}>
        {label}
      </BodyText>
    </Stack>
  );
};
