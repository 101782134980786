import { setSessionExpiryFlag } from 'app/authenticated-app/globals';
import Axios, { AxiosError, AxiosResponse } from 'axios';
import React, { ReactNode, useEffect } from 'react';
import { useDispatch } from 'react-redux';

export const AxiosInterceptor: React.FC<{ children: ReactNode }> = ({ children }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const interceptor = Axios.interceptors.response.use(
      function (response: AxiosResponse) {
        return response;
      },
      function (error: AxiosError) {
        if (error.response && (error.response.status === 401 || error.response.status === 410)) {
          dispatch(setSessionExpiryFlag(true));
        }
        if (error.response && error.response.data) {
          if (
            error.response.data.conversationErrorPayload ||
            error.response.data?.['error_payload']
          ) {
            return Promise.reject(error.response.data);
          }
          return Promise.reject(error.response.data.message);
        }
        return Promise.reject(error.message);
      },
    );
    return () => Axios.interceptors.response.eject(interceptor);
  }, [dispatch]);

  return <>{children}</>;
};
