import { Action, combineReducers } from 'redux';
import { globalsReducer } from '../app/authenticated-app/globals';
import {
  automationsReducer,
  dataModelsReducer,
  listsReducer,
  resourcesReducer,
  segmentsReducer,
} from '../app/authenticated-app/lists/lists.reducer';
import { campaignReducer } from '../app/authenticated-app/marketing/campaigns/campaigns.reducer';
import { templatesReducer } from '../app/authenticated-app/marketing/templates';
import {
  bankAccountsReducer,
  cardsReducer,
  managedAccountReducer,
  paymentRequestsReducer,
  paymentSetupReducer,
  walletReducer,
} from '../app/authenticated-app/payments/reducers';
import {
  billingReducer,
  developersReducer,
  paymentReducer,
  teamsReducer,
} from '../app/authenticated-app/settings';
import { groupsReducer, rowsReducer, tablesReducer } from '../app/authenticated-app/tables';
import {
  authReducer,
  getToken,
  getUser,
  logUserOut,
} from '../app/unauthenticated-app/authentication';
import {
  smsLogsReducer,
  emailLogsReducer,
  otpLogsReducer,
  whatsappLogsReducer,
} from 'app/authenticated-app/settings/component/developers/redux';
import { permissionsReducer } from 'app/authenticated-app/settings/component/people/redux/permissions.slice';
import { airtimeLogsReducer } from 'app/authenticated-app/settings/component/developers/redux/airtime.slice';

const getPreloadedState = () => {
  try {
    const user = getUser();
    const token = getToken();

    if (!user && !token) {
      return {};
    }
    return {
      auth: {
        user,
        token,
      },
    };
  } catch (e) {
    return {};
  }
};

export const preloadedState = getPreloadedState();

export const appReducers = combineReducers({
  auth: authReducer,
  rows: rowsReducer,
  teams: teamsReducer,
  billing: billingReducer,
  tables: tablesReducer,
  lists: listsReducer,
  automations: automationsReducer,
  dataModels: dataModelsReducer,
  segments: segmentsReducer,
  resources: resourcesReducer,
  groups: groupsReducer,
  payment: paymentReducer,
  payments: combineReducers({
    payment_setup: paymentSetupReducer,
    payment_requests: paymentRequestsReducer,
    cards: cardsReducer,
    bank_accounts: bankAccountsReducer,
    managed_account: managedAccountReducer,
    wallet: walletReducer,
  }),
  globals: globalsReducer,
  campaigns: campaignReducer,
  templates: templatesReducer,
  developers: developersReducer,
  smsLogs: smsLogsReducer,
  emailLogs: emailLogsReducer,
  otpLogs: otpLogsReducer,
  whatsappLogs: whatsappLogsReducer,
  permissions: permissionsReducer,
  airtimeLogs: airtimeLogsReducer,
});

export const rootReducer = (state: any, action: Action) => {
  let appState = state;
  if (action.type === logUserOut.toString()) {
    appState = undefined;
  }
  return appReducers(appState, action);
};
