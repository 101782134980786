import { FullPageSpinner } from 'app/components';
import { useQuery } from 'hooks';
import React, { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { clearState, loadState, saveState } from 'utils';
import * as segment from 'utils/segment';
import { fetchProfile, listOrganisations, Organization, saveUser } from '../../service';

const connector = connect(null, { saveUser, fetchProfile });

type PropsFromRedux = ConnectedProps<typeof connector>;

const DirectAuthComp = (
  props: {
    token: string;
  } & PropsFromRedux,
) => {
  const [isLoading, setIsLoading] = useState(false);

  const query = useQuery();
  const history = useHistory();

  const login = async () => {
    setIsLoading(true);
    try {
      const token = query.get('token');
      saveState({ ...(loadState() ?? {}), token });

      const organisations: Organization[] = await listOrganisations();

      const { profile, user } = await props.fetchProfile(organisations[0].id, {
        organisationID: organisations[0].id,
      });
      saveState({ token, profile, user, organisations });
      props.saveUser({ token, user, profile, organisations });

      segment.identify(user?.id ?? '', {
        ...profile,
        app: 'marketing',
        email: user.email,
      });

      segment.group(profile.organisation_id, {
        name: organisations?.find(
          (organisation: any) => organisation.id === profile.organisation_id,
        )?.name,
        app: 'marketing',
      });

      segment.track('Signed In', { ...profile, email: user.email });
      setIsLoading(false);

      history.push('/s/lists/lists');
    } catch (e) {
      setIsLoading(false);
      clearState();
      history.push('/login');
    }
  };

  useEffect(() => {
    login();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return <FullPageSpinner />;
  }

  return null;
};

export const DirectAuth = connector(DirectAuthComp);
