import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { Box, IconButton, Tooltip, useDisclosure } from '@chakra-ui/core';
import { ListAutomation } from 'app/authenticated-app/lists/lists.types';
import { ConfirmModal, Switch, Textarea, ToastBox } from 'app/components';
import { useToast } from '@chakra-ui/core/dist';
import { ListTitleEditable } from '../../../../../ListTitleEditable';
import _ from 'lodash';

interface Props {
  automation: ListAutomation | null;
  goBack: () => null;
  handleAutomationUpdate: Function;
  deleteAutomation: Function;
}

export const AutomationHeaderSection = (props: Props) => {
  const { automation, goBack } = props;
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [tempDescription, setTempDescription] = useState('');

  const {
    isOpen: deleteDialogOpen,
    onClose: closeDeleteDialog,
    onOpen: openDeleteDialog,
  } = useDisclosure(false);

  const handleActiveSwitchChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.checked;
    const payload = {
      is_active: value,
    };
    props.handleAutomationUpdate(payload);
  };

  const toast = useToast();

  const handleDelete = async () => {
    setDeleteLoading(true);
    try {
      await props.deleteAutomation(automation?.id);
      toast({
        position: 'bottom-left',
        render: () => <ToastBox status="success" message="Automation deleted successfully" />,
      });
      setDeleteLoading(false);
      goBack();
    } catch (e) {
      toast({
        position: 'bottom-left',
        render: () => (
          <ToastBox status="success" message="Unable to delete automation, please try again" />
        ),
      });
    }
  };

  // @ts-ignore
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedCall = useCallback(_.debounce(props.handleAutomationUpdate, 1000), [automation]);

  const handleDescriptionUpdate = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setTempDescription(value);
    const payload: any = {
      description: value,
    };
    debouncedCall(payload);
  };

  useEffect(() => {
    if (automation) {
      setTempDescription(automation.description);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [automation?.description]);

  return (
    <>
      <Box
        paddingTop="0.2rem"
        paddingBottom="1rem"
        paddingX="5px"
        display="flex"
        flexDirection="row"
        alignItems="center"
        borderBottom=" 1px solid #EBEEF2"
        width="100%"
        justifyContent="space-between"
      >
        <Box display="flex" alignItems="center">
          <IconButton
            variant="ghost"
            aria-label="button"
            icon="chevron-left"
            onClick={() => props.goBack()}
            fontSize="18px"
            mr="1rem"
            size="xs"
            isRound
          />
          <ListTitleEditable
            isInitial={false}
            isEditing={false}
            value={automation?.name || ''}
            onChange={(value: string) => {
              props.handleAutomationUpdate({
                name: value,
              });
            }}
            inputProps={{
              width: '320px',
              marginRight: '0px',
              borderWidth: '1px',
            }}
          />
        </Box>

        <Box display="flex" flexDirection="row">
          <Tooltip aria-label="toggle status" label="Toggle Automation Status" hasArrow>
            <Box>
              <Switch
                size="sm"
                marginX="10px"
                isChecked={!!automation?.is_active}
                onChange={handleActiveSwitchChange}
              />
            </Box>
          </Tooltip>
          <Tooltip ml="0.5rem" aria-label="delete automation" label="Delete Automation" hasArrow>
            <IconButton
              size="xs"
              variantColor="red"
              variant="outline"
              onClick={openDeleteDialog}
              aria-label="delete automation"
              // @ts-ignore
              icon="trash"
            />
          </Tooltip>
        </Box>
      </Box>
      <Box paddingX="2px" marginY="1.5rem">
        <Textarea
          size="sm"
          label="Description"
          placeholder="Enter a description for this automation"
          value={tempDescription || ''}
          onChange={handleDescriptionUpdate}
        />
      </Box>
      <ConfirmModal
        title="Delete Automation"
        isOpen={deleteDialogOpen}
        isLoading={deleteLoading}
        onConfirm={handleDelete}
        onClose={closeDeleteDialog}
      />
    </>
  );
};
