import { Box, Flex, IconButton, Stack, useDisclosure, useToast } from '@chakra-ui/core';
import {
  selectListsById,
  selectSegments,
  selectSmartLists,
  selectSmartListsById,
} from 'app/authenticated-app/lists';
import { selectLists } from 'app/authenticated-app/lists/lists.slice';
import { selectCreditBalance } from 'app/authenticated-app/payments/selectors';
import { listEmailSenderProfiles, saveEmailSenderProfile } from 'app/authenticated-app/settings';
import { EmailSenderProfilePayload } from 'app/authenticated-app/settings/component/sender-profiles/email-sender-profile-modal';
import { BodyText, Button, ConfirmModal, FullPageSpinner, ToastBox } from 'app/components';
import { AxiosError } from 'axios';
import format from 'date-fns/format';
import { useFormik } from 'formik';
import { useQuery as useSearchQuery } from 'hooks/use-query';
import isEmpty from 'lodash/isEmpty';
import snakeCase from 'lodash/snakeCase';
import React, { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import * as yup from 'yup';
import { getEmailTemplates } from '../..';
import { saveEmailTemplates } from '../../campaigns.service';
import { CampaignPayload, EmailTemplate } from '../../campaigns.types';
import { EmailCampaignSetupContent } from './content';
import { EmailCampaignSetupReview } from './review';
import { EmailCampaignSetupSelectTemplate } from './select-template';
import { SetupWidgetHeaderLeftSlot } from './setup-widget-header-left-slot';

type EmailCampaignSetupWidgetProps = {
  isSavingDraft?: boolean;
  isCreatingCampaign?: boolean;
  isDeletingCampaign?: boolean;
  onDeleteCampaign: () => void;
  campaign?: Partial<CampaignPayload>;
  type: 'new' | 'edit';
  onSubmit?: (payload: Partial<CampaignPayload>) => void;
  onSaveDraft?: (payload: Partial<CampaignPayload>) => void;
};

export const EmailCampaignSetupWidget = (props: EmailCampaignSetupWidgetProps) => {
  const {
    campaign,
    onSubmit,
    onSaveDraft,
    isSavingDraft,
    onDeleteCampaign,
    isCreatingCampaign,
    isDeletingCampaign,
  } = props;

  const sectionOneValidationSchema = yup.object().shape({
    name: yup.string().required('Campaign name is required'),
  });

  const sectionTwoValidationSchema = yup.object().shape({
    subject: yup.string().nullable().required('Campaign subject is required'),
    campaign_id: yup.string().nullable().required('Select campaign recipients'),
    sender_id: yup.string().email('Invalid email format').required('Sender ID is required'),
  });

  const validationSchemaRegister: { [key: number]: any } = {
    0: sectionOneValidationSchema,
    1: sectionTwoValidationSchema,
  };

  const toast = useToast();
  const { push } = useHistory();
  const queryClient = useQueryClient();

  const {
    isOpen: sendCampaignIsOpen,
    onOpen: onOpenSendCampaignModal,
    onClose: onCloseSendCampaignModal,
  } = useDisclosure();
  const {
    isOpen: deleteCampaignModalIsOpen,
    onOpen: onOpenDeleteCampaignModal,
    onClose: onCloseDeleteCampaignModal,
  } = useDisclosure();
  const addEmailSenderProfileModalProps = useDisclosure();

  const [emailEditorIsLoaded, setEmailEditorIsLoaded] = useState(false);

  const emailEditorRef = useRef<any>(null);

  const segments = useSelector(selectSegments);
  const credit_balance = useSelector(selectCreditBalance);
  const lists_by_id: string[] = useSelector(selectListsById);
  const lists: { [key: string]: any } = useSelector(selectLists);
  const smart_lists_by_id: string[] = useSelector(selectSmartListsById);
  const smart_lists: { [key: string]: any } = useSelector(selectSmartLists);

  const { data: templates, isLoading: isLoadingTemplates } = useQuery<EmailTemplate[]>(
    'email-templates',
    getEmailTemplates,
  );

  const { data: emailSenderProfiles } = useQuery<EmailSenderProfilePayload[]>(
    ['email-sender-profiles'],
    listEmailSenderProfiles,
  );

  const [activeSection, setActiveSection] = useState(0);

  const { mutate: createEmailSenderProfileMutate, isLoading: isCreatingEmailSenderProfile } =
    useMutation<any, AxiosError, any, any>(
      (options: { payload: EmailSenderProfilePayload }) => saveEmailSenderProfile(options.payload),
      {
        onSuccess: (data, variables) => {
          const { helpers } = variables;
          queryClient.invalidateQueries(['email-sender-profiles']);
          helpers.resetForm();
          addEmailSenderProfileModalProps.onClose();
          setFieldValue?.('from_name', data.from_name);
          setFieldValue?.('from_email', data.from_email);
        },
        onError: error => {
          toast({
            position: 'bottom-left',
            render: ({ onClose }) => <ToastBox onClose={onClose} message={error.message} />,
          });
        },
      },
    );

  const { mutate: saveTemplateMutate, isLoading: isSavingTemplate } = useMutation<
    any,
    AxiosError,
    any,
    any
  >((payload: { name?: string; json?: any; html?: string }) => saveEmailTemplates(payload), {
    onError: (err, newThread, context) => {
      toast({
        position: 'bottom-left',
        render: ({ onClose }) => <ToastBox onClose={onClose} message={err.message} />,
      });
    },
    onSuccess: () => {
      toast({
        position: 'bottom-left',
        render: ({ onClose }) => (
          <ToastBox
            status="success"
            onClose={onClose}
            message="Email template saved successfully"
          />
        ),
      });
    },
  });

  const handleSectionSubmit = () => {
    if ((activeSection === 0 || activeSection === 1) && values.emailTemplateType === 'template') {
      handleExportEditorHtml();
      setTimeout(() => {
        onLinkClick(activeSection + 1);
      }, 100);
    } else {
      onLinkClick(activeSection + 1);
    }
  };

  const { values, errors, touched, submitForm, handleChange, handleSubmit, setFieldValue } =
    useFormik({
      enableReinitialize: false,
      onSubmit: handleSectionSubmit,
      validationSchema: validationSchemaRegister[activeSection],
      initialValues: !isEmpty(campaign)
        ? {
            ...campaign,
            campaign_id:
              campaign?.smart_list_id ||
              campaign?.table_id ||
              campaign?.import_id ||
              campaign?.segment_id ||
              campaign?.audience_id,
            emailTemplateType: 'template',
            smart_sending: campaign?.is_smart_send ? '1' : '0',
            from_name:
              campaign?.from_name ?? emailSenderProfiles?.find(item => item.is_default)?.from_name,
            sender_id:
              campaign?.sender_id ??
              emailSenderProfiles?.find(item => item.is_default)?.from_email ??
              '',
            reply_to:
              campaign?.reply_to ??
              emailSenderProfiles?.find(item => item.is_default)?.reply_to ??
              '',
          }
        : {
            name: '',
            subject: '',
            content: '',
            table_id: null,
            import_id: null,
            campaign_id: '',
            segment_id: null,
            audience_id: null,
            smart_sending: '1',
            emailTemplateType: 'template',
            from_name: emailSenderProfiles?.find(item => item.is_default)?.from_name,
            sender_id: emailSenderProfiles?.find(item => item.is_default)?.from_email ?? '',
            reply_to: emailSenderProfiles?.find(item => item.is_default)?.reply_to ?? '',
          },
    });

  const listOptions = lists_by_id
    //@ts-ignore
    .map((id: string) => lists[id])
    .map(({ name, id }) => ({ label: name, value: id }));

  const smartListOptions = smart_lists_by_id
    //@ts-ignore
    .map((id: string) => smart_lists[id])
    .map(({ name, id }) => ({ label: name, value: `${id}-smartList` }));

  const segmentOptions = segments.map(({ name, id }) => ({
    label: name,
    value: `${id}-segment`,
  }));

  const allLists = [
    { label: 'Lists', options: listOptions, showBadge: true },
    { label: 'Smart lists', options: smartListOptions, showBadge: true },
    { label: 'Segments', options: segmentOptions, showBadge: true },
  ];

  const onLinkClick = (section: number) => {
    setActiveSection(section);
  };

  const handleExportEditorHtml = useCallback(() => {
    if (emailEditorRef.current) {
      emailEditorRef.current.editor.exportHtml((data: any) => {
        const { design: json, html } = data;
        setFieldValue?.('content', html);
        setFieldValue?.('json', json);
      });
    }
  }, [setFieldValue]);

  const handleBack = useCallback(() => {
    if (activeSection === 0) {
      push('/s/marketing/campaigns');
    } else {
      setActiveSection(activeSection - 1);
    }
  }, [activeSection, push]);

  const handleCampaignSubmit = (data: CampaignPayload) => {
    const { cta, logo, footer, ...rest } = data;

    onSubmit?.({
      ...rest,
      via: 'email',
      type: 'email',
      external_template_data: {
        cta,
        logo,
        footer,
      },
    });

    push('/s/marketing/campaigns');
  };

  const handleSaveDraft = (values: Partial<CampaignPayload>) => {
    const { cta, logo, footer, ...rest } = values;
    onSaveDraft?.({
      ...rest,
      via: 'email',
      type: 'email',
      external_template_data: {
        cta,
        logo,
        footer,
      },
    });
  };

  const handleSaveDesignedTemplate = useCallback(() => {
    if (emailEditorRef?.current) {
      const { name } = values;
      emailEditorRef.current.editor.exportHtml((data: any) => {
        const { design: json, html } = data;
        saveTemplateMutate?.({ name, json, html });
      });
    }
  }, [values, saveTemplateMutate]);

  const handleSaveCodeTemplate = useCallback(() => {
    const { content, name } = values;
    saveTemplateMutate?.({ name, html: content });
  }, [saveTemplateMutate, values]);

  const handleSaveTemplate = useCallback(() => {
    if (values.emailTemplateType === 'template') {
      handleSaveDesignedTemplate();
    } else {
      handleSaveCodeTemplate();
    }
  }, [handleSaveDesignedTemplate, handleSaveCodeTemplate, values.emailTemplateType]);

  const getListProperties = useCallback(
    (list_id: string) => {
      if (list_id && lists_by_id?.includes(list_id)) {
        return lists[list_id].columns.map(({ name, label }: { name: string; label: string }) => ({
          label,
          value: name,
        }));
      }
    },
    [lists, lists_by_id],
  );

  const sectionsHeaderProps: {
    [key: number]: { rightSlot: ReactNode; leftSlot: ReactNode; validationSchema?: any };
  } = useMemo(
    () => ({
      0: {
        rightSlot: (
          <Stack isInline alignItems="center">
            {isCreatingCampaign && <BodyText color="gray.50">Saving draft...</BodyText>}
            <Button
              size="sm"
              variantColor="blue"
              onClick={handleSubmit}
              isDisabled={isCreatingCampaign}
            >
              Next
            </Button>
            <IconButton
              size="sm"
              icon="delete"
              variant="outline"
              aria-label="Back"
              variantColor="red"
              onClick={onOpenDeleteCampaignModal}
            />
          </Stack>
        ),
        leftSlot: (
          <SetupWidgetHeaderLeftSlot
            onClick={handleBack}
            value={values.name ?? ''}
            state={values.state ?? 'draft'}
            className="campaign-name-input"
            errorMessage={errors.name ?? ''}
            isInvalid={!!touched.name && !!values.name}
            onChange={value => setFieldValue('name', value)}
          />
        ),
      },
      1: {
        leftSlot: (
          <SetupWidgetHeaderLeftSlot
            onClick={handleBack}
            value={values.name ?? ''}
            state={values.state ?? 'draft'}
            errorMessage={errors.name ?? ''}
            className="campaign-name-input"
            isInvalid={!!touched.name && !!values.name}
            onChange={value => setFieldValue('name', value)}
          />
        ),
        rightSlot: (
          <Stack isInline alignItems="center">
            {isCreatingCampaign && <BodyText color="gray.500">Saving draft...</BodyText>}
            <Button
              size="sm"
              variant="outline"
              variantColor="blue"
              isLoading={isSavingTemplate}
              onClick={handleSaveTemplate}
              isDisabled={values.emailTemplateType === 'template' && !emailEditorIsLoaded}
            >
              Save template
            </Button>
            <Button
              size="sm"
              variantColor="blue"
              onClick={handleSubmit}
              isDisabled={
                (values.emailTemplateType === 'template' && !emailEditorIsLoaded) ||
                (!values.content && values.emailTemplateType === 'code')
              }
            >
              Review
            </Button>
            <IconButton
              size="sm"
              icon="delete"
              variant="outline"
              aria-label="Back"
              variantColor="red"
              onClick={onOpenDeleteCampaignModal}
            />
          </Stack>
        ),
      },
      2: {
        rightSlot: (
          <Stack isInline alignItems="center">
            <Button
              size="sm"
              onClick={() => {
                setFieldValue('schedule_start', format(new Date(), 'yyyy-MM-dd hh:mm a'));
                onOpenSendCampaignModal();
              }}
            >
              Schedule
            </Button>
            <Button
              size="sm"
              variantColor="blue"
              onClick={() => {
                setFieldValue('schedule_start', undefined);
                onOpenSendCampaignModal();
              }}
            >
              Send Now
            </Button>
            <IconButton
              size="sm"
              icon="delete"
              variant="outline"
              aria-label="Back"
              variantColor="red"
              onClick={onOpenDeleteCampaignModal}
            />
          </Stack>
        ),
        leftSlot: (
          <SetupWidgetHeaderLeftSlot
            onClick={handleBack}
            value={values.name ?? ''}
            className="campaign-name-input"
            state={values.state ?? 'draft'}
            errorMessage={errors.name ?? ''}
            isInvalid={!!touched.name && !!values.name}
            onChange={value => setFieldValue('name', value)}
          />
        ),
      },
    }),
    [
      handleBack,
      handleSubmit,
      setFieldValue,
      values.content,
      values.name,
      onOpenSendCampaignModal,
      values.emailTemplateType,
      isSavingTemplate,
      handleSaveTemplate,
      emailEditorIsLoaded,
      errors.name,
      isCreatingCampaign,
      touched.name,
      values.state,
      onOpenDeleteCampaignModal,
    ],
  );

  const { leftSlot, rightSlot } = sectionsHeaderProps[activeSection];

  const query = useSearchQuery();
  useEffect(() => {
    if (query.get('duplicate') === 'true' || values.state === 'draft') {
      setFieldValue?.('content', values.content);
      setFieldValue?.('json', values.json);
      setActiveSection(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (values?.table_id) {
      const properties = getListProperties(values.table_id);
      const obj: { [key: string]: { name: string; value: string; empty?: boolean } } = {};

      properties?.forEach((tag: any) => {
        obj[snakeCase(tag.label)] = {
          name: tag.label,
          empty: !tag.label,
          value: `{{${tag.label}}}`,
        };
      });

      emailEditorRef?.current?.editor?.setMergeTags?.(obj);
    }
    if (values?.smart_list_id) {
      const smartList = smart_lists[values.smart_list_id];
      const properties = getListProperties(smartList.list_id);
      const obj: { [key: string]: { name: string; value: string; empty?: boolean } } = {};

      properties?.forEach((tag: any) => {
        obj[snakeCase(tag.label)] = {
          name: tag.label,
          empty: !tag.label,
          value: `{{${tag.label}}}`,
        };
      });

      emailEditorRef?.current?.editor?.setMergeTags?.(obj);
    }
  }, [getListProperties, smart_lists, values]);

  // useEffect(() => {
  //   emailEditorRef?.current?.editor?.addEventListener('design:updated', function () {
  //     emailEditorRef.current.editor.exportHtml((data: any) => {
  //       const { design: json, html } = data;
  //       setFieldValue?.('content', html);
  //       setFieldValue?.('json', json);
  //     });
  //   });
  // }, [setFieldValue]);

  if (isLoadingTemplates) {
    return <FullPageSpinner />;
  }

  return (
    <Flex height="100%" flexDirection="column">
      <Box
        bg="white"
        width="100%"
        px="1.875rem"
        height="56px"
        borderBottom="1px"
        borderBottomColor="gray.200"
        py={activeSection === 0 ? '1rem' : '0'}
      >
        <Flex height="100%" alignItems="center" justifyContent="space-between">
          <Box>{leftSlot}</Box>
          <Box>{rightSlot}</Box>
        </Flex>
      </Box>
      <Box width="100%" flex={1} overflowY={activeSection !== 0 ? 'auto' : 'hidden'}>
        {activeSection === 0 && (
          <EmailCampaignSetupSelectTemplate
            values={values}
            onCancel={handleBack}
            onSubmit={submitForm}
            templates={templates ?? []}
            setFieldValue={setFieldValue}
          />
        )}
        {activeSection === 1 && (
          <EmailCampaignSetupContent
            values={values}
            errors={errors}
            lists={allLists}
            touched={touched}
            handleChange={handleChange}
            setFieldValue={setFieldValue}
            emailEditorRef={emailEditorRef}
            emailEditorIsLoaded={emailEditorIsLoaded}
            setEmailEditorIsLoaded={setEmailEditorIsLoaded}
            isCreatingEmailSenderProfile={isCreatingEmailSenderProfile}
            onCreateEmailSenderProfile={createEmailSenderProfileMutate}
            addEmailSenderProfileModalProps={addEmailSenderProfileModalProps}
          />
        )}
        {activeSection === 2 && (
          <EmailCampaignSetupReview
            campaign={values}
            onGoBack={() => {}}
            onGoToSection={onLinkClick}
            isSavingDraft={isSavingDraft}
            isSaving={isCreatingCampaign}
            onSaveDraft={handleSaveDraft}
            onSubmit={handleCampaignSubmit}
            credit_balance={credit_balance}
            sendCampaignIsOpen={sendCampaignIsOpen}
            onCloseSendCampaignModal={onCloseSendCampaignModal}
          />
        )}
      </Box>
      {deleteCampaignModalIsOpen && (
        <ConfirmModal
          title="Delete campaign"
          isLoading={isDeletingCampaign}
          onConfirm={onDeleteCampaign}
          isOpen={deleteCampaignModalIsOpen}
          onClose={onCloseDeleteCampaignModal}
        />
      )}
    </Flex>
  );
};
