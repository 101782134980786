import { createSlice } from '@reduxjs/toolkit';

const initialMeta = {
  count_total: 0,
  page: 1,
  per_page: 20,
  next_page: true,
  prev_page: false,
};

const initialState = {
  data: {},
  actions: {
    by_id: [],
    data: {},
    loading: false,
  },
  by_id: [],
  meta: initialMeta,
  loading: false,
};

export const automationsSlice = createSlice({
  name: 'automations',
  initialState,
  reducers: {
    setAutomationsData: (state, action) => {
      const { data, by_id } = action.payload;
      state.data = data;
      state.by_id = by_id;
    },
    setAutomationsLoading: (state, action) => {
      const loading = action.payload;
      state.loading = loading;
    },
    resetAutomations: state => {
      state.data = {};
      state.by_id = [];
      state.loading = false;
      state.actions.data = {};
      state.actions.by_id = [];
      state.actions.loading = false;
    },
    setActionsData: (state, action) => {
      const { data, by_id } = action.payload;
      state.actions.data = data;
      state.actions.by_id = by_id;
    },
    setActionsLoading: (state, action) => {
      const loading = action.payload;
      state.actions.loading = loading;
    },
    resetActions: state => {
      state.actions.data = {};
      state.actions.by_id = [];
      state.actions.loading = false;
    },
  },
});
