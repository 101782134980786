import React, { useEffect } from 'react';
import { CustomCellWrapper as Wrapper } from '../../grid/custom-cell/index.styles';
import { Box } from '@chakra-ui/core';

export const NumberCellComponent = (props: any) => {
  useEffect(() => {
    if (
      props.search_query !== '' &&
      props.value &&
      props.value?.toString().toLowerCase().includes(props.search_query.toLowerCase())
    ) {
      props.eGridCell.style.backgroundColor = '#fff3d4';
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const customization = props._column?.customization;
  const format = customization?.number_format || 'integer';
  let decimal_places = customization?.decimal_places || 0;

  if (format === 'integer') {
    decimal_places = 0;
  }

  return (
    <Wrapper>
      <Box className="number-cell">
        <Box>{!!props.value && Number(props.value).toFixed(decimal_places)}</Box>
      </Box>
    </Wrapper>
  );
};
