import React from 'react';
import { Box, Divider } from '@chakra-ui/core/dist';
import { NumberFormatSelect } from './number-format-select';
import { DecimalFormatSelect } from './decimal-format-select';

export const NumberCustomizationMenu = (props: any) => {
  const column = props.column;
  const customization = column?.customization || {};

  return (
    <>
      <Divider />
      <Box className="property-type-label" marginTop="10px">
        NUMBER FORMAT
      </Box>
      <NumberFormatSelect {...props} />

      {customization?.number_format !== 'integer' && (
        <>
          <Box className="property-type-label" marginTop="5px">
            DECIMAL FORMAT
          </Box>
          <DecimalFormatSelect {...props} />
        </>
      )}
    </>
  );
};
