import { Avatar, Box, Flex, Heading, Stack } from '@chakra-ui/core';
import { EmptyState, Input, Table } from 'app/components';
import format from 'date-fns/format';
import capitalize from 'lodash/capitalize';
import React, { useMemo, useState } from 'react';
import noPeople from '../../../assets/no-people.svg';
import { InviteModal } from './invite-modal';

export const SubAccountMembers = (props: { people?: any[]; sub_account_id: string }) => {
  const { people, sub_account_id } = props;

  const [searchQuery, setSearchQuery] = useState<string>('');

  const rows = people?.filter((i: any) =>
    JSON.stringify(i).toLowerCase().includes(searchQuery.toLowerCase()),
  );

  const PeopleTableColumns = () => [
    {
      Header: 'Name',
      width: 225,
      accessor: '',
      Cell: ({ row: { original } }: any) => {
        const name = `${capitalize(original.first_name)} ${capitalize(original.last_name)}`;
        return (
          <Box display="flex" height="100%" alignItems="center">
            <Avatar size="sm" name={name} color="white" marginRight="15px" src={original.image} />
            <Box>{name}</Box>
          </Box>
        );
      },
    },
    {
      Header: 'Email',
      width: 375,
      accessor: '',
      Cell: ({ row: { original } }: any) => {
        return (
          <Box color="#757575" height="100%" display="flex" alignItems="center">
            {original.email}
          </Box>
        );
      },
    },
    {
      Header: 'Role',
      width: 225,
      accessor: '',
      Cell: ({ row: { original } }: any) => {
        const { permission_id, name, page_access, permissions: p } = original;
        const permission = {
          name,
          page_access,
          permissions: p,
          id: permission_id,
          created_datetime: '',
          updated_datetime: '',
        };

        return (
          <Flex height="100%" align="center">
            {permission.name}
          </Flex>
        );
      },
    },
    {
      Header: 'Last Login',
      accessor: '',
      Cell: ({ row: { original } }: any) => {
        return (
          <Box color="#757575" height="100%" display="flex" alignItems="center">
            {original.updated_datetime && format(new Date(original.last_login), 'MMM do')}
          </Box>
        );
      },
    },
  ];

  const columns = useMemo(
    () => PeopleTableColumns(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <div>
      <Stack isInline alignItems="center" marginBottom="1.5rem">
        <Heading size="sm" color="#333333" fontWeight="semibold">
          Members
        </Heading>
        <Box fontWeight="400" color="#757575" fontSize="14px">
          - {people?.length} member(s)
        </Box>
      </Stack>
      <Stack isInline alignItems="center" spacing="0" justifyContent="space-between">
        <Input
          size="sm"
          autoFocus
          value={searchQuery}
          containerWidth="50%"
          placeholder="Search by name or email"
          onChange={(e: any) => setSearchQuery(e.target.value)}
        />
        <InviteModal
          sub_account_id={sub_account_id}
          buttonProps={{
            size: 'sm',
            variantColor: 'blue',
            children: 'Invite people',
          }}
        />
      </Stack>

      <Box marginTop="25px">
        {!!rows?.length && (
          <Table
            // @ts-ignore
            columns={columns}
            data={rows}
            onRowClick={() => {}}
          />
        )}
        {!rows?.length && (
          <EmptyState
            marginY="10vh"
            image={noPeople}
            subheading="No members found"
            subheadingProps={{ marginTop: '25px', fontWeight: '500' }}
          >
            <InviteModal
              sub_account_id={sub_account_id}
              buttonProps={{
                size: 'sm',
                variantColor: 'blue',
                children: 'Invite people',
              }}
            />
          </EmptyState>
        )}
      </Box>
    </div>
  );
};
