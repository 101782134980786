// @ts-nocheck
import React from 'react';
import { Box, Icon } from '@chakra-ui/core';
import { ColumnSelect } from './column-select';
import { OperatorSelect } from './operator-select';
import { ValueInput } from './value-input';
import { ConjunctionSelect } from './conjunction-select';
import { SubOperatorSelect } from './suboperator-select';
import { DepthSelector } from './depth-select';

export const FilterItem = (props: any) => {
  const {
    index,
    columns,
    columns_by_id,
    columnID,
    updateFilter,
    uid: filter_id,
    operator: selected_operator,
    value,
    deleteFilter,
    conjunction: selected_conjunction,
    subOperator,
    allow_conjunction_select,
    debounceUpdate = true,
    depth,
  } = props;

  const handleColumnChange = (columnID: string) => {
    updateFilter(filter_id, { columnID: columnID });
  };

  const handleOperatorChange = (operator: string) => {
    updateFilter(filter_id, { operator });
  };

  const handleValueChange = (value: string) => {
    updateFilter(filter_id, { value });
  };

  const handleConjunctionChange = (conjunction: string) => {
    updateFilter(filter_id, { conjunction });
  };

  const handleSubOperatorChange = (_subOperator: string) => {
    updateFilter(filter_id, { subOperator: _subOperator });
  };

  const handleDepthChange = (value: { [key: string]: boolean }) => {
    updateFilter(filter_id, { depth: value });
  };

  const column = columns[columnID];

  return (
    <Box className="filter-item" justifyContent="space-between">
      {allow_conjunction_select ? (
        <ConjunctionSelect
          {...{
            selected_conjunction,
            onChange: handleConjunctionChange,
            allow_select: index === 1,
          }}
        />
      ) : (
        <div className="conjunction">Where</div>
      )}

      <ColumnSelect
        {...{
          selected_column: columnID,
          columns,
          columns_by_id,
          onChange: handleColumnChange,
        }}
      />
      <Box
        display="flex"
        maxWidth="205px"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        className="operator-select-container"
      >
        <OperatorSelect
          {...{
            selected_operator,
            onChange: handleOperatorChange,
            column,
          }}
        />
        {['CREATED AT', 'UPDATED AT', 'DATE'].includes(column['type']) && (
          <SubOperatorSelect
            column={column}
            operator={selected_operator}
            onChange={handleSubOperatorChange}
            subOperator={subOperator}
          />
        )}
        {['CREATED AT', 'UPDATED AT', 'DATE'].includes(column['type']) && (
          <DepthSelector
            onChange={handleDepthChange}
            value={depth ?? { year: true, month: true, day: true }}
          />
        )}
      </Box>

      <ValueInput
        column={columns[columnID]}
        selected_operator={selected_operator}
        subOperator={subOperator}
        value={value}
        onChange={handleValueChange}
        debounceUpdate={debounceUpdate}
      />

      <Box className="icon-menu">
        <Icon name="trash" onClick={() => deleteFilter(filter_id)} />
      </Box>
    </Box>
  );
};
