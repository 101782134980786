import { Avatar, Box, BoxProps, Flex, Icon, IconButton, Stack, Text } from '@chakra-ui/core';
import { html2Text } from 'app/authenticated-app/marketing/templates/templates.utils';
import React, { ReactNode } from 'react';
import { SmallText, XSmallText } from '..';

type ToastOptions = {
  bg?: string;
  color?: string;
  icon?: string;
};

type ToastStatus = 'success' | 'error' | 'info';

export type ToastBoxProps = {
  onClose?(): void;
  status?: ToastStatus;
  message?: string | ReactNode;
} & BoxProps;

export function ToastBox({ message, onClose, status = 'error', ...rest }: ToastBoxProps) {
  const toastOptions = {
    error: {
      bg: '#FAE7E7',
      color: '#DA1414',
      icon: 'alert-error',
    },
    success: {
      bg: '#ECEAF9',
      color: '#6155EB',
      icon: 'alert-success',
    },
    info: {
      bg: '#ECEAF9',
      color: '#6155EB',
      icon: 'alert-info',
    },
  } as { [key in ToastStatus]: ToastOptions };

  const { bg, color, icon } = toastOptions[status];

  return (
    <Flex
      m={3}
      p={3}
      bg={bg}
      rounded="8px"
      alignItems="center"
      justifyContent="space-between"
      {...rest}
    >
      <Stack isInline alignItems="flex-start">
        <Icon size="1.5rem" name={icon} color={color} />
        {typeof message == 'string' ? (
          <Text color={color}>{message}</Text>
        ) : (
          <Box color={color}>{message}</Box>
        )}
      </Stack>
      <IconButton
        ml="4rem"
        size="xs"
        icon="close"
        onClick={onClose}
        variant="unstyled"
        aria-label="close toast"
        _focus={{ boxShadow: 'none' }}
      />
    </Flex>
  );
}

export const NotificationToastBox = (props: { data: any; onClose?(): void }) => {
  const {
    onClose,
    data: { data },
  } = props;
  const payload = data?.payload ? JSON.parse(data?.payload) : {};

  const { contents, sender, thread } = payload ?? {};
  const [{ entity } = { entity: {} }] = contents ?? [];

  const { name = '', imageUrl = '', channelName } = sender ?? thread?.sender ?? {};

  const { content, hasAttachment } = entity ?? {};

  const { body } = content ?? {};

  return (
    <Stack
      m={3}
      p={3}
      isInline
      rounded="8px"
      width="300px"
      spacing="1rem"
      bg="brandBlack"
      position="relative"
      boxShadow="rgba(0, 0, 0, 0.16) 0px 5px 40px"
    >
      <Avatar size="sm" src={imageUrl} name={name} color="white" marginRight="7px" />
      <Box>
        <XSmallText color="gray.500" textTransform="capitalize">
          {channelName}
        </XSmallText>
        <SmallText pb="0.25rem" fontWeight="bold" color="white">
          {name ?? ''}
        </SmallText>
        {body && (
          <SmallText
            color="white"
            width="250px"
            overflow="hidden"
            whiteSpace="nowrap"
            wordBreak="break-word"
            style={{ textOverflow: 'ellipsis' }}
          >
            {html2Text(body)}
          </SmallText>
        )}
        {hasAttachment && (
          <Stack mt="0.25rem" isInline alignItems="center">
            <Icon color="gray.500" name="notification-attachment" />
            <SmallText color="gray.500">Attachment</SmallText>
          </Stack>
        )}
      </Box>
      <IconButton
        size="xs"
        top="4px"
        right="4px"
        icon="close"
        onClick={onClose}
        variant="unstyled"
        position="absolute"
        aria-label="close toast"
        _focus={{ boxShadow: 'none' }}
      />
    </Stack>
  );
};
