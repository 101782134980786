import { Alert, AlertDescription, AlertIcon, CloseButton, Text } from '@chakra-ui/core';
import * as React from 'react';
import { ForgotPasswordForm, ForgotPasswordInitialValuesProps } from '../../components/forms';
import { AuthLayout } from '../../components/layout';
import { AuthProps } from '../../types';

export function ForgotPassword(
  props: AuthProps<ForgotPasswordInitialValuesProps> & {
    isSuccess: boolean;
    closeAlert(): void;
  },
) {
  const { onSubmit, isSuccess, isLoading, closeAlert } = props;

  return (
    <AuthLayout
      subheading="Enter the email address associated with your account and we'll send you a link to reset your password"
      footing={
        <Text>
          Don't have an account? We'll be out of private beta soon{' '}
          <span role="img" aria-label="smiley">
            😊
          </span>
          {/*<Link style={{ color: '#2034c5' }} to="/register">*/}
          {/*  Sign up*/}
          {/*</Link>*/}
        </Text>
      }
    >
      {isSuccess && (
        <Alert status="success" marginBottom="2rem">
          <AlertIcon />
          <AlertDescription>
            An email with a link to reset your password has been sent to you.
          </AlertDescription>
          <CloseButton onClick={closeAlert} position="absolute" right="8px" top="8px" />
        </Alert>
      )}
      <ForgotPasswordForm isLoading={isLoading} onSubmit={values => onSubmit(values)} />
    </AuthLayout>
  );
}
